import React, { useEffect, useMemo, useState } from 'react'

import styles from './PlacingFormMassModal.module.scss'
import { Modal, TableWithCopy } from 'src/components'
import { ITableRow, ModalProps } from 'src/interfaces'
import { Button } from '@shared/ui/btns/Button'
import cx from 'classnames'
import { IRequestPlaceProduct } from '@/hooks/placement/usePlaceProducts'
import { ComboboxCellItem, ComboboxCells } from '@entities/Cell'
import { generateColumns, generateRows } from './utils'
import { ChangeFormArgType, PlacingFormData } from './types'

interface IProps extends ModalProps {
  onSubmit: (products: IRequestPlaceProduct[]) => void
  planRows: ITableRow[]
  loading: boolean
}


const PlacingFormMassModal = (props: IProps) => {
  const {
    isOpen,
    onClose,
    onSubmit,
    planRows = [],
    loading
  } = props

  const [cell, setCell] = useState<ComboboxCellItem | null>()
  const [formData, setFormData] = useState<PlacingFormData>({})
  const handleClickSubmit = () => {
    const resData: IRequestPlaceProduct[] = Object.values(formData).map((dataItem) => {
      return {
        cell_barcode: cell?.dto?.barcode,
        quantity: dataItem.quantity,
        sku_batch_id: dataItem.sku_batch_id,
        sku_id: dataItem.sku_id
      }
    })
    onSubmit(resData)
  }

  const convertFormDataToStateFormat = (data: ChangeFormArgType) => {
    const { quantity, planQuantity, sku_batch_id, sku_id } = data
    return {
      [`${sku_id}${sku_batch_id||''}`]: {
        quantity, planQuantity, sku_batch_id, sku_id
      }
    }
  }

  useEffect(() => {
    /** Задаем начальный стейт для формы */
    setFormData(prev => {
      const defaultState = planRows.reduce((acc, row) => {
        const itemData = convertFormDataToStateFormat({
          quantity: row.quantity,
          planQuantity: row.quantity,
          sku_id: row.id,
          sku_batch_id: row.sku_batch_id,
        })
        return { ...acc, ...itemData }
      }, {} as PlacingFormData)
      return { ...prev, ...defaultState }
    })
  }, [planRows])

  const handleChangeQuantity = (data: ChangeFormArgType) => {
    const newItemData = convertFormDataToStateFormat(data)
    setFormData(prev => {
    return { ...prev, ...newItemData }})
  }

  const rows = useMemo(() =>
      generateRows(planRows, formData, handleChangeQuantity),
    [planRows,  formData, setFormData]
  )
  const columns = useMemo(() => generateColumns(), [])

  const formHasError = useMemo(() => {
    const tableFormHasError = Object.values(formData).some(data => {
      return data.quantity > data.planQuantity
    })
    return tableFormHasError || !cell
  }, [formData, cell])

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.modal}
      size="xl"
      headerTitle={'Размещение товара'}
    >
      <div className={styles.formWrapper}>
        <ComboboxCells
          value={cell}
          onChange={setCell}
        />
        <TableWithCopy
          withCopy={false}
          rows={rows}
          columns={columns}
          title={`План на размещение ${!planRows.length ? '(Пусто)' : ''}`}
          tableWrapperClassName={cx(styles.tableWrap)}
          maxHeightBody={445}
        />
      </div>

      <div className={styles.footer}>
        <Button
          label="Отмена"
          view={'ghost'}
          onClick={onClose}
          loading={loading}
        />
        <Button
          label="Переместить"
          view={'primary'}
          onClick={handleClickSubmit}
          disabled={formHasError}
          loading={loading}
        />
      </div>
    </Modal>
  )
}

export default PlacingFormMassModal
