import api from '@shared/api'
import { ApiReqResult, IDeliveryItem } from '@/interfaces'
import { useQuery } from 'react-query'

export const getDeliveriesList = async () => {
  const result = await api.get(`/enum/delivery-code`)
  if (!result.data?.success) {
    throw new Error(result.data.message)
  }
  return result.data
}

interface IResult extends ApiReqResult {
  values: IDeliveryItem[]
}

const useGetDeliveriesList = (
  callback?: (data: IDeliveryItem[]) => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useQuery<IResult>([`getDeliveriesList`], getDeliveriesList, {
    keepPreviousData: false,
    onSuccess: ({ success, values }) => {
      if (success) {
        if (callback)
        callback?.(values)
      } else {
        error?.()
      }
    },
    onError: () => error?.(),
    onSettled: () => settled?.(),
  })
}

export default useGetDeliveriesList
