import { useMutation } from 'react-query'
import api from '@shared/api'

import { ApiReqResult, IDimensionsObj, ISku, OrderPackingV2, PackagePostDto, StickerWithCopies } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'


interface Params {
  orderId: string
  data: {
    dimensions?: IDimensionsObj
    weight?: number
    packaging_id?: string
  }
}
const transportPlaceComplete = async (params: Params) => {
  const result = await api.post(`/packing-v2/order/${params.orderId}/transport-place/complete`, params.data)
  if (!result.data?.success) {
    throw result
  }
  return result.data
}

interface Result extends ApiReqResult {
  order: OrderPackingV2
  skus: ISku[]
  files_for_print: StickerWithCopies[]
}

const useTransportPlaceComplete = (
  options?: UseMutationOptions<Result, any, any>,
) => {
  return useMutation((params: Params) => transportPlaceComplete(params), options)
}

export default useTransportPlaceComplete
