import React, { FC } from 'react'
import { useParams } from 'react-router-dom'
import type { ISection } from '../../types'
import { IRouteParams } from '@pages/DirectoriesPage/types'
import List from './pages/list/list'
import Item from './pages/Item/Item'
import { PageLayout } from '@pages/DirectoriesPage/components/PageLayout/PageLayout'

interface Props extends ISection {}

const Instructions: FC<Props> = (props) => {
  const { title = '' } = props
  const { type = 'list' } = useParams<IRouteParams>()

  return (
    <PageLayout
      title={title}
      listPage={<List />}
      formPage={<Item />}
    />
  )
}

export default Instructions
