import React from 'react'
import { Button } from '@shared/ui/btns/Button'
import { IconEdit } from '@consta/icons/IconEdit'
import { IconRevert } from '@consta/icons/IconRevert'
import { Flex } from '@shared/ui/Flex'
import { IconTrash } from '@consta/icons/IconTrash'
import { BoolConditionalProps } from '@shared/types/helpers/condition'

type EditRemoveButtonsProps = {
  isLoading?: boolean
  }
  & BoolConditionalProps<'withEdit', { onEditClick: () => void }>
  & BoolConditionalProps<'withRemove', {
      withRevert?: boolean
      deleted?: boolean
      onRemoveClick: () => void
  }>

export const EditRemoveButtons = (props: EditRemoveButtonsProps) => {
  const {
    isLoading,
    onEditClick,
    withEdit = true,
    withRemove = true,
    withRevert = true,
    deleted,
    onRemoveClick,
  } = props

	return (
		<Flex gap={'s'} justifyContent={'center'} fullWidth>
      {
        withEdit ? (
          <Button
            onlyIcon
            view={'ghost'}
            size={'xs'}
            iconSize={'s'}
            iconLeft={IconEdit}
            onClick={onEditClick}
            loading={isLoading}
          />
        ) : null
      }
      {
        withRemove ? (
          <Button
            onlyIcon
            view={'ghost'}
            size={'xs'}
            iconSize={'s'}
            theme={deleted ? 'success' : 'danger'}
            iconLeft={deleted && withRevert ? IconRevert : IconTrash}
            onClick={onRemoveClick}
            loading={isLoading}
          />
        ) : null
      }
    </Flex>
	);
};
