import { useQuery, UseQueryOptions } from 'react-query'

import api from '@shared/api'
import { ApiReqResult, IWorkspace, PaginationResult } from 'src/interfaces'
import { convertObjectToURLParams } from '@shared/helpers'

export interface IParams {
  search: string | null
}

interface GetJobsListResult extends ApiReqResult, PaginationResult {
  workspaces: IWorkspace[]
}
// http://wms-test.kak2c.ru/warehouse-api/warehouse/workspace
const useGetJobsList = (params: IParams, options?: UseQueryOptions<any>) => {
  return useQuery<GetJobsListResult>(
    [`getJobsList`, params],
    async () => {
      const paramsStr = convertObjectToURLParams(params)

      const result = await api.get(`/warehouse/workspace${paramsStr}`)
      return result.data as GetJobsListResult
    },
    options,
  )
}
export default useGetJobsList
