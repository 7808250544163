import api from '@shared/api'

import { ApiReqResult, IContractorPostDto } from 'src/interfaces'
import { useMutation } from 'react-query'
import { UseMutationOptions } from 'react-query/types/react/types'

export interface IResult extends ApiReqResult {
  id: string
}
interface IParams {
  contractorId?: string
  data: IContractorPostDto
}
const createEditContractor = async (params: IParams) => {
  const result = await api.post(`/contractor${params.contractorId ? `/${params.contractorId}` : ''}`, params.data)
  if (!result?.data?.success) throw result
  return result.data
}

const useCreateEditContractor = (options: UseMutationOptions<IResult, unknown, IParams> = {}) =>
  useMutation<IResult, unknown, IParams>(
    async (params: IParams) => await createEditContractor(params),
    options
  )

export default useCreateEditContractor