import React, { FC, useEffect, useMemo, useState } from 'react'

import type { ISection } from '../../types'
import { useGetVehiclesList } from '@/hooks'
import { useFilterState } from '@shared/hooks'
import { generateFilterParams } from './utils/filters'
import { generateTableData } from './utils/table'
import { ConfirmActionModal, CreateVehicleModal } from '@/components'
import { useNotifications } from '@shared/providers/NotificationProvider'
import useCreateUpdateVehicle from '../../../../../hooks/carrierVehicle/useCreateUpdateVehicle'
import { DirectoriesLayout } from '@pages/DirectoriesPage/components/DirectoriesLayout/DirectoriesLayout'

interface Props extends ISection {}

const Vehicles: FC<Props> = (props) => {
  const { title = '' } = props

  const notification = useNotifications()

   const {
    filterStateDebounced,
    filterState,
    handleChangeFilter
  }  = useFilterState({withoutHistory: true, onChangeFilterState: () => {
    setPaginationPage(0)
  }})

  const [paginationPage, setPaginationPage] = useState(0)
  const [createEditModalVisible, setCreateEditModalVisible] = useState(false)
  const [vehicleEditId, setVehicleEditId] = useState<string | null>(null)

  const [deleteVehicleEditId, setDeletedVehicleEditId] = useState<string | null>(null)


  const createUpdateVehicle = useCreateUpdateVehicle({
    vehicleId: deleteVehicleEditId || '',
  })

  const {
    isFetching: isFetchingVehicle,
    refetch: refetchVehicles,
    data: vehiclesData,
  } = useGetVehiclesList(() => {}, {
      ...generateFilterParams({ ...filterState } || {}),
      with_deleted: true, page: paginationPage
    },
  )

  useEffect(() => {
    if (isFetchingVehicle) {
      return
    }

    refetchVehicles()
  }, [filterStateDebounced, paginationPage])

  const handleEdit = (id: string) => {
    setVehicleEditId(id)
    setCreateEditModalVisible(true)
  }
  const handleConfirm = (id: string, isDelete: boolean) => {
    if (!isDelete) {
      handleDeleteRevert(id, isDelete)
      return
    }
    setDeletedVehicleEditId(id)
  }
  const handleDeleteRevert = async (id: string, isDelete: boolean) => {
    if (!id) {
      return
    }
    const vehicle = vehiclesData?.vehicles?.find((carrierItem) => carrierItem.id === id)
    if (!vehicle) {
      notification?.show('alert', 'Перевозчик не найден')
      return
    }
    await createUpdateVehicle.mutateAsync({ ...vehicle, deleted: isDelete })
    setDeletedVehicleEditId(null)
    refetchVehicles()
  }

  const { rows, columns } = useMemo(() => {
    return generateTableData(
      vehiclesData?.vehicles || [],
      handleEdit,
      handleConfirm,
    )
  }, [vehiclesData?.vehicles])



  return (
    <DirectoriesLayout
      withTable
      withCreate
      withFilter
      withSearch

      title={title}

      // filtersConfig={filtersConfig}
      filterState={filterState}
      handleChangeFilter={handleChangeFilter}

      onCreateBtnClick={() => setCreateEditModalVisible(true)}

      isLoading={isFetchingVehicle}
      columns={columns}
      rows={rows}
      pagination={vehiclesData?.pagination}
      onChangePage={setPaginationPage}
    >
      {createEditModalVisible ? (
         <CreateVehicleModal
           isOpen={true}
           vehicleEditId={vehicleEditId}
           vehiclesList={vehiclesData?.vehicles || []}
           onClose={() => setCreateEditModalVisible(false)}
           onSubmit={() => {
             refetchVehicles()
             setCreateEditModalVisible(false)
           }}
         />
      ) : null}

      {deleteVehicleEditId  ? (
        <ConfirmActionModal
          isOpen={true}
          type="warning"
          withPreview={false}
          title={'Вы уверены, что хотите удалить перевозчика?'}
          size={'min'}
          confirmWithScan={false}
          onClose={() => setDeletedVehicleEditId(null)}
          onSubmit={() => handleDeleteRevert(deleteVehicleEditId, true)}
          confirmLoading={createUpdateVehicle?.isLoading}
        />
      ) : null}
    {/*</div>*/}
    </DirectoriesLayout>
  )
}

export default Vehicles
