import React, { useMemo } from 'react'
import { ContextMenu } from '@consta/uikit/ContextMenu'
import {
  ContextMenuGroupDefault,
  ContextMenuItemDefault,
} from '@consta/uikit/__internal__/src/components/ContextMenu/types'
import { Table } from '@tanstack/react-table'
import { IconCopy } from '@consta/icons/IconCopy'
import { IconShape } from '@consta/icons/IconShape'
import { IconTable2 } from '@consta/icons/IconTable2'
import { IconUnsortCenter } from '@consta/icons/IconUnsortCenter'
import { IconSortDown } from '@consta/icons/IconSortDown'
import { IconSortUp } from '@consta/icons/IconSortUp'
import { IconUnsort } from '@consta/icons/IconUnsort'
import { IconEyeClose } from '@consta/icons/IconEyeClose'
import { IconOpenInNew } from '@consta/icons/IconOpenInNew'
import { IconReply } from '@consta/icons/IconReply'
import { IconDown } from '@consta/icons/IconDown'
import { SortEnum } from '@shared/types/sort'
import styles from './TableContextMenu.module.scss'
import { IconEye } from '@consta/icons/IconEye'
import { range } from '@consta/uikit/__internal__/src/utils/array'
import { useHistory } from 'react-router-dom'
import cx from 'classnames'

enum GroupKeyEnum {
  sort,
  expand,
  copy
}

const groups: ContextMenuGroupDefault[] = [
  {
    label: 'Сортировка',
    id: GroupKeyEnum.sort,
  },
  {
    label: 'Раскрыть',
    id: GroupKeyEnum.expand,
  },
  {
    label: 'Скопировать',
    id: GroupKeyEnum.copy,
  },
]

interface IProps<TRowData> {
  table: Table<TRowData>
}



const TableContextMenu = <TRowData, >(props: IProps<TRowData>) => {
  const {
    table,
  } = props

  const history = useHistory()

  //
  // useGlobalKeys({
  //   Escape: (e: KeyboardEvent) => console.log('Пользователь нажал Escape', e),
  //   ArrowUp: (e: KeyboardEvent) =>
  //     console.log('Пользователь нажал стрелку вверх', e),
  // });

  const tableState = table.getState()
  const {
    contextMenu,
    tableRowsMaxDepth,
    copyState
  } = tableState
  const position = contextMenu?.position
  const row = contextMenu.selectedRowId ? table.getRow(contextMenu.selectedRowId) : null
  const column = contextMenu.selectedColumnId ? table.getColumn(contextMenu.selectedColumnId) : null
  const cellLinkPath = row ? column?.columnDef?.meta?.generateLink?.(row, column) : ''

  const items: ContextMenuItemDefault[] = useMemo(() => {
    // todo better
    const isColumnOnly = column && !row
    const isRowOnly = column && row

    const tableCanExpand = table.getCanSomeRowsExpand()
    const tableIsAllRowsExpanded = table.getIsAllRowsExpanded()
    const columnIsVisible = column?.getIsVisible()
    const columnCanSort = column?.getCanCustomSort()
    const columnIsSortSorted = column?.getIsCustomSorted()
    const columnCanHide = column?.getCanHide()

    const copyIsEmpty = table.copyStateIsEmpty()


    const copyItem = {
      label: 'Скопировать',
      groupId: GroupKeyEnum.copy,
      leftIcon: IconCopy,
      subMenu: [
        {
          leftIcon: IconShape,
          label: 'Выделенное',
          disabled: copyIsEmpty,
          onClick: table.copySelected
        },
        {
          leftIcon: IconTable2,
          label: 'Всю таблицу',
          onClick: table.copyAllTableWithNested
        },
        // {
        //   leftIcon: IconTable,
        //   label: 'Всю таблицу (без вложенности)',
        //   onClick: table.copyAllTable
        // },
        // {
        //   leftIcon: IconPanelRight,
        //   label: 'Весь столбец',
        //   onClick: table.copyColumn
        // },
        // {
        //   leftIcon: IconPanelRight,
        //   label: 'Всю строку',
        //   onClick: table.copyRow
        // },
        // {
        //   leftIcon: IconPanelRight,
        //   label: 'Всю строку (с вложенностями)'
        // },
      ],
    }

    const sortItem = {
      label: 'Сортировка',
      groupId: GroupKeyEnum.sort,
      leftIcon: IconUnsortCenter,
      subMenu: [
        columnIsSortSorted ? {
          leftIcon: IconUnsort,
          label: 'Убрать сортировку',
          onClick: () => column.clearCustomSorting(),
        } : null,
        columnIsSortSorted !== SortEnum.ASC ? {
          leftIcon: IconSortUp,
          label: 'По возрастанию',
          onClick: () => column.setCustomSorting(SortEnum.ASC),
        } : null,
        columnIsSortSorted !== SortEnum.DESC ? {
          leftIcon: IconSortDown,
          label: 'По убыванию',
          onClick: () => column.setCustomSorting(SortEnum.DESC),
        } : null,
      ].filter(Boolean),
    }

    const expandRange = range(tableRowsMaxDepth)
    const expandItem = {
      label: 'Скрыть/Раскрыть',
      groupId: GroupKeyEnum.expand,
      leftIcon: IconDown,
      subMenu: [
        { label: `${tableIsAllRowsExpanded ? 'Скрыть' : 'Раскрыть'} всю таблицу`, onClick: () => table.toggleAllRowsExpanded() },
        ...expandRange.map(el => {
          const depth = el+1
          return (
            { label: `Раскрыть до уровня ${depth}`, onClick: () => table.setExpandedAllRowsByDepth(depth) }
          )
        }),
      ],
    }

    const openNewPage = {
      label: 'Перейти по ссылке',
      leftIcon: IconReply,
      onClick: () => cellLinkPath ? history.push(cellLinkPath) : undefined
    }
    const openNewPageNewPage = {
      label: 'Открыть в новом окне',
      leftIcon: IconOpenInNew,
      onClick: () => cellLinkPath ? window.open(cellLinkPath, '_blank')?.focus() : undefined
    }

    const hideColumn = {
      label: `${columnIsVisible ? 'Скрыть' : 'Раскрыть'} столбец`,
      leftIcon: columnIsVisible ? IconEyeClose : IconEye,
      onClick: column?.getToggleVisibilityHandler()
    }

    return [
      ...(isRowOnly && cellLinkPath ? [
        openNewPage,
        openNewPageNewPage
      ] : [null]),
      isColumnOnly && columnCanHide ? hideColumn : null,
      copyItem,
      isColumnOnly && columnCanSort ? sortItem : null,
      tableCanExpand ? expandItem : null,
    ].filter(Boolean)
  }, [row, column, tableRowsMaxDepth, table, copyState])

  return (
    <>
      <ContextMenu
        id={'TableContextMenu'}
        isOpen={true}
        offset={10}
        groups={groups}
        items={items}
        onClickOutside={() => table.closeContextMenu()}
        onItemClick={(data) => {
          if (data.subMenu?.length) return
          table.closeContextMenu()
        }}
        getItemLabel={(item) => item.label}
        direction={'downRight'}
        size={'s'}
        position={position}
        className={cx(styles.contextMenu)}
      />
    </>
  )
}
export default TableContextMenu
