import React, { FC, useState } from 'react'

import { IGetCellStockByCellIdResult, useGetItems, useGetItemsM, useGetItemsStock } from 'src/hooks'
import { IAcceptanceSku } from 'src/interfaces'
import { ComboboxWrapper, ComboboxWrapperWithApi, InputWrapper } from 'src/components'
import { ComboboxItemDefault } from '@consta/uikit/Combobox'
import { Button } from '@shared/ui/btns/Button'
import cx from 'classnames'
import styles from './modal-item.module.scss'
import { SelectBatchModal } from '@widgets/modals/SelectBatchModal'
import { getBatchNameValue } from '@pages/OperationsAndDocumentsPage/helpers/common'
import { IReplaceChangeItem } from '../types'

interface ModalItemProps {
  className?: string
  uuid: string
  onDelete: (uuid: string) => void
  error: string
  onChange: (
    uuid: string,
    key: keyof IReplaceChangeItem,
    value: string | number | IAcceptanceSku,
  ) => void
  merchant_ids: string[]
}

enum ItemTypeEnum {
  EXPENDABLE_MATERIAL = 'EXPENDABLE_MATERIAL',
  SKU = 'SKU',
  PHYSICAL_SET = 'PHYSICAL_SET',
  SERVICE = 'SERVICE',
  AD_ATTACHMENT = 'AD_ATTACHMENT',
}

const ModalItem: FC<ModalItemProps> = ({
  className = '',
  uuid,
  onDelete,
  onChange,
  merchant_ids,
  error,
}) => {
  const [cellsList, setCellsList] = useState<ComboboxItemDefault[]>([])
  const [cell, setCell] = useState<ComboboxItemDefault | null>()

  const [oldSku, setOldSku] = useState<ComboboxItemDefault | null>()
  const [oldSkuBatch, setOldSkuBatch] = useState<ComboboxItemDefault | null>()
  const [oldSkuBatchList, setOldSkuBatchList] = useState<ComboboxItemDefault[]>()
  const [openOldModalBatch, setOpenOldModalBatch] = useState(false)

  const [originalNewSku, setOriginalNewSku] = useState<IAcceptanceSku>()
  const [originalOldSku, setOriginalOldSku] = useState<IAcceptanceSku>()

  const [newSkuBatch, setNewSkuBatch] = useState<ComboboxItemDefault | null>()
  const [newSkuBatchList, setNewSkuBatchList] = useState<ComboboxItemDefault[]>()
  const [newSku, setNewSku] = useState<ComboboxItemDefault | null>()
  const [openNewModalBatch, setOpenNewModalBatch] = useState(false)

  const [quantity, setQuantity] = useState<number | string>(0)

  const { mutate: getItems } = useGetItemsM()
  const getItemsStock = useGetItemsStock(
    {
      skus: oldSku
        ? [
            {
              sku_id: String(oldSku.id),
              sku_batch_id: oldSkuBatch ? String(oldSkuBatch.id) : undefined,
            },
          ]
        : [],
    },
    {
      onSuccess: (data: IGetCellStockByCellIdResult) => {
        setCellsList(
          data?.places?.map((cell) => ({
            label: cell.title,
            id: cell.barcode,
          })),
        )
      },
    },
  )

  const handleSearchNewSku = (skuId: string, batchDescription: string) => {
    getItems(
      { search: skuId, merchant_ids: merchant_ids },
      {
        onSuccess: (data) => {
          if (batchDescription === 'old') {
            setOldSkuBatchList(
              data?.skus[0]?.batches?.map((el) => ({
                label: getBatchNameValue(el),
                id: el.id,
              })),
            )
          } else {
            setNewSkuBatchList(
              data?.skus[0]?.batches?.map((el) => ({
                label: getBatchNameValue(el),
                id: el.id,
              })),
            )
          }
        },
      },
    )
  }

  const handleUpdateBatchLists = (skuId: string, skuDescription: string) => {
    getItems(
      { search: skuId, merchant_ids: merchant_ids },
      {
        onSuccess: (data) => {
          if (skuDescription === 'oldSku') {
            if (data?.skus[0]?.batch_accounting) {
              setOriginalOldSku(data?.skus[0])
              onChange(uuid, 'originalOldSku', data?.skus[0])
              setOldSkuBatchList(
                data?.skus[0]?.batches?.map((el) => ({
                  label: getBatchNameValue(el),
                  id: el.id,
                })),
              )
            } else {
              setOriginalOldSku(data?.skus[0])
              onChange(uuid, 'originalOldSku', data?.skus[0])
              setOldSkuBatchList([])
              setOldSkuBatch(null)
              onChange(uuid, 'sku_batch_id', null)
            }
          } else if (skuDescription === 'newSku') {
            if (data?.skus[0]?.batch_accounting) {
              setOriginalNewSku(data?.skus[0])
              onChange(uuid, 'originalNewSku', data?.skus[0])
              setNewSkuBatchList(
                data?.skus[0]?.batches?.map((el) => ({
                  label: getBatchNameValue(el),
                  id: el.id,
                })),
              )
            } else {
              setOriginalNewSku(data?.skus[0])
              onChange(uuid, 'originalNewSku', data?.skus[0])
              setNewSkuBatchList([])
              setNewSkuBatch(null)
              onChange(uuid, 'new_sku_batch_id', null)
            }
          }
        },
      },
    )
  }

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.inputsRow}>
        <div className={styles.inputCell}>
          <ComboboxWrapperWithApi
            value={oldSku}
            label={'Старая номенклатура'}
            size={'s'}
            placeholder={'начните печатать для поиска'}
            useGetHookSearch={useGetItems}
            onChange={(value) => {
              setOldSku(value)
              onChange(uuid, 'sku_id', value?.id || '')
              handleUpdateBatchLists(String(value?.id), 'oldSku')
              setCell(null)
              onChange(uuid, 'cell_barcode', null)
            }}
            convertHookParams={(params) => {
              return {
                search: params.search,
                merchant_ids: merchant_ids,
                size: 50,
              }
            }}
            convertHookData={(data) => {
              return data.skus.map((el) => ({ label: el.title, id: el.id }))
            }}
          />
          {!oldSku && error ? (
            <div className={styles.errorMessage}>{error}</div>
          ) : null}
          {oldSku && originalOldSku?.batch_accounting ? (
            <ComboboxWrapper
              id="batch"
              label=""
              /* @ts-ignore */
              items={oldSkuBatchList}
              /* @ts-ignore */
              value={oldSkuBatch || null}
              size="s"
              placeholder="Выберите партию "
              searchFunction={(item, searchValue) => {
                const value = searchValue?.toUpperCase()
                const label = item?.labelTxt?.toUpperCase()
                return label?.includes(value)
              }}
              onChange={(value) => {
                setOldSkuBatch(value)
                onChange(uuid, 'sku_batch_id', value?.id)
              }}
              className={styles.batchSelect}
            />
          ) : null}
          {originalOldSku?.batch_accounting && !oldSkuBatch && error ? (
            <div className={styles.errorMessage}>{error}</div>
          ) : null}
        </div>

        {openOldModalBatch ? (
          <SelectBatchModal
            isOpen={true}
            onlyCreate={true}
            expdate={originalOldSku?.batch_expdate_mandatory}
            item={originalOldSku}
            onSubmit={(batch, isNewBatch) => {
              handleSearchNewSku(originalOldSku?.id, 'old')
              setOpenOldModalBatch(false)
            }}
            onClose={() => setOpenOldModalBatch(false)}
          />
        ) : null}
        {openNewModalBatch ? (
          <SelectBatchModal
            isOpen={true}
            onlyCreate={true}
            expdate={originalNewSku?.batch_expdate_mandatory}
            item={originalNewSku}
            onSubmit={(batch, isNewBatch) => {
              handleSearchNewSku(originalNewSku?.id, 'new')
              setOpenNewModalBatch(false)
            }}
            onClose={() => setOpenNewModalBatch(false)}
          />
        ) : null}

        <div className={styles.inputCell}>
          <ComboboxWrapperWithApi
            value={newSku}
            label={'Новая номенклатура'}
            size={'s'}
            placeholder={'начните печатать для поиска'}
            useGetHookSearch={useGetItems}
            onChange={(value) => {
              setNewSku(value)
              onChange(uuid, 'new_sku_id', value?.id || '')
              handleUpdateBatchLists(String(value?.id), 'newSku')
            }}
            convertHookParams={(params) => {
              return {
                search: params.search,
                merchant_ids: merchant_ids,
                size: 50,
              }
            }}
            convertHookData={(data) => {
              return data.skus.map((el) => ({ label: el.title, id: el.id }))
            }}
          />
          {!newSku && error ? (
            <div className={styles.errorMessage}>{error}</div>
          ) : null}
          {newSku && originalNewSku?.batch_accounting ? (
            <ComboboxWrapper
              withCreateItem
              id="batchNew"
              label=""
              /* @ts-ignore */
              items={newSkuBatchList}
              /* @ts-ignore */
              value={newSkuBatch || null}
              size="s"
              placeholder="Выберите партию "
              searchFunction={(item, searchValue) => {
                const value = searchValue?.toUpperCase()
                const label = item?.labelTxt?.toUpperCase()
                return label?.includes(value)
              }}
              onCreate={() => setOpenNewModalBatch(true)}
              onChange={(value ) => {
                setNewSkuBatch(value)
                onChange(uuid, 'new_sku_batch_id', value?.id)
              }}
              className={styles.batchSelect}
            />
          ) : null}
          {originalNewSku?.batch_accounting && !newSkuBatch && error ? (
            <div className={styles.errorMessage}>{error}</div>
          ) : null}
        </div>
      </div>

      <div className={styles.inputsRow}>
        <div className={styles.inputCell}>
          <ComboboxWrapper
            className={styles.inputCell}
            items={cellsList}
            value={cell}
            label={'Ячейка'}
            size={'s'}
            placeholder={'выберете ячейку'}
            onChange={(value) => {
              setCell(value)
              onChange(uuid, 'cell_barcode', value?.id)
            }}
          />
          {!cell && error ? (
            <div className={styles.errorMessage}>{error}</div>
          ) : null}

        </div>

        <InputWrapper
          label={'Кол-во'}
          value={`${quantity}`}
          size={'s'}
          isNumber
          handleChange={(value) => {
            setQuantity(value || '')
            onChange(uuid, 'quantity', value || '')
          }}
        />
      </div>

      <div className={styles.buttonsContainer}>
        <Button label={'Удалить'} size={'s'} onClick={() => onDelete(uuid)} />
      </div>
    </div>
  )
}

ModalItem.displayName = 'ModalItem'

export default ModalItem
