import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './GatesSection.module.scss'
import type { ISection } from '../../types'
import { TableColumn } from '@consta/uikit/Table'
import { IGatesRender, IGatesRes } from './types'
import { Button, Modal } from '@/components'
import { IconAdd } from '@consta/icons/IconAdd'
import { useCreateGate, useDeleteGate, useEditGate, useGetWarehouseGatesList } from '@/hooks'
import DeleteGateModal from '../../components/DeleteGateModal/DeleteGateModal'
import { IProps as GateCreateReqProps } from '@/hooks/warehouse/gate/useCreateGate'

import GateModal from '../../components/GateModal/GateModal'
import { IconTriangle } from '@shared/assets/icons/IconTriangle'
import { DirectoriesLayout } from '@pages/DirectoriesPage/components/DirectoriesLayout/DirectoriesLayout'
import { useFilterState } from '@shared/hooks'
import { IconEdit } from '@consta/icons/IconEdit'
import { IconTrash } from '@consta/icons/IconTrash'

const GatesSection: FC<ISection> = ({
                                      className = '',
                                      title = '',
                                    }) => {
  const [gates, setGates] = useState<IGatesRender[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [edit, setEdit] = useState(false)
  const [gate, setGate] = useState<IGatesRes | null>(null)
  const [filteredGates, setFilteredGates] = useState<IGatesRender[]>([])
  const { mutate: createGate, isLoading: isGateCreate } = useCreateGate()
  const { mutate: editGate, isLoading: isGateEditLoading } = useEditGate()
  const { mutate: deleteGate, isLoading: isGateDeleteLoading } = useDeleteGate()


  const {
    filterStateDebounced,
    filterState,
    handleChangeFilter,
  } = useFilterState({
    withoutHistory: true,
  })

  useEffect(() => {
    const query = filterState['search']?.toLowerCase() || ''
    setFilteredGates(
      gates.filter((gate) => {
        const name = gate.title.toLowerCase()
        if (!query || name.includes(query)) {
          return gate
        }
        return false
      }),
    )
  }, [filterStateDebounced])


  const {
    isLoading: isLoadingGates,
    data: gatesData,
    refetch,
    isSuccess: isGatesDataSuccess,
    isFetching: isGatesDataFetching,
  } = useGetWarehouseGatesList(false)

  const data = useMemo(() => gatesData?.gates || [], [gatesData?.gates])

  const onSubmitEdit = useCallback((gate: IGatesRes) => {
    setEdit(true)
    setGate(gate)
    setIsModalOpen(true)
  }, [
    setEdit,
    setGate,
    setIsModalOpen
  ])
  const onSubmitDelete = useCallback((gate: IGatesRes) => {
    setGate(gate)
    setIsDeleteModal(true)
  }, [
    setGate,
    setIsDeleteModal
  ])

  const columns: TableColumn<any>[] = useMemo(() => [
    {
      title: 'Наименование',
      accessor: 'nameEl',
      width: 1000,
    },
    {
      title: '',
      accessor: 'editEl__action',
      width: 10,
      renderCell: (row: any) => {
        return (
          <Button
            onlyIcon
            view={'ghost'}
            size={'xs'}
            iconSize={'s'}
            iconLeft={IconEdit}
            onClick={() => onSubmitEdit(row.original.gate)}
            loading={isGateEditLoading}
          />
        )
      },
    },
    {
      title: '',
      accessor: 'deleteEl__action',
      width: 10,
      renderCell: (row: any) => {
        return (
          <Button
            onlyIcon
            view={'ghost'}
            size={'xs'}
            iconSize={'s'}
            theme={'danger'}
            iconLeft={IconTrash}
            onClick={() => onSubmitDelete(row.original.gate)}
            loading={isGateDeleteLoading}
          />
        )
      },
    },
  ], [
    onSubmitEdit,
    onSubmitDelete,
    isGateEditLoading,
    isGateDeleteLoading
  ])

  const onGateCreate = (gateData: GateCreateReqProps) => {
    createGate(gateData, { onSuccess: () => refetch() })
  }

  const onGateEdit = (gateData: GateCreateReqProps) => {
    editGate(gateData, { onSuccess: () => refetch() })
  }
  const onGateDelete = (gateData: string) => {
    deleteGate(gateData, { onSuccess: () => refetch() })
  }

  const renderModalCreate = () => {
    if (edit) {
      return (
        <GateModal
          title={'Редактирование ворот'}
          gate={gate}
          onSubmit={onGateEdit}
          onClose={() => setIsModalOpen(false)}
        />
      )
    } else {
      return (
        <GateModal
          title={'Создание ворот'}
          onSubmit={onGateCreate}
          onClose={() => setIsModalOpen(false)}
        />
      )
    }
  }
  const renderModalDelete = () => {
    return (
      <DeleteGateModal
        gate={gate}
        onSubmit={onGateDelete}
        onClose={() => setIsDeleteModal(false)}
      />
    )
  }

  useEffect(() => {
    if (!data || !data.length) {
      return
    }
    const gatesSet: IGatesRender[] = data.map((gate: IGatesRes) => {
      return {
        ...gate,
        gate,
        nameEl: gate.title,
        editEl: null,
        deleteEl: null,
      }
    })

    setGates(gatesSet)
    setFilteredGates(gatesSet)
  }, [data])
  return (
    <>
      <DirectoriesLayout
        withTable
        withCopy={false}
        withFilter
        withSearch
        withCreate
        createBtnNode={
          <Button
            label={'Добавить ворота'}
            iconLeft={IconAdd}
            size={'s'}
            iconSize={'s'}
            loading={isGateCreate || isGateEditLoading}
            onClick={() => {
              setEdit(false)
              setIsModalOpen(true)
            }}
          />
        }

        title={title}

        filterState={filterState}
        handleChangeFilter={handleChangeFilter}

        isLoading={isGateCreate || isGateEditLoading || isLoadingGates}
        columns={columns}
        rows={filteredGates}
      />

      <Modal
        isOpen={isModalOpen}
        hasOverlay
        onClickOutside={() => {
          setEdit(false)
          setIsModalOpen(false)
        }}
      >
        {renderModalCreate()}
      </Modal>
      <Modal
        isOpen={isDeleteModal}
        hasOverlay
        Icon={() => <IconTriangle theme={'red'} />}
        subtitle={`Ворота ${gate?.title} точно удалить?`}
        imgClass={styles.img}
        onClickOutside={() => setIsDeleteModal(false)}
      >
        {renderModalDelete()}
      </Modal>
    </>
  )
}
export default GatesSection
