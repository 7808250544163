import { BarcodesCell, BatchCell, ImageCell } from 'src/components'
import { boolSelectItems, FieldType, GeneratorField } from '@widgets/GeneratorFields'
import style from './generatorStyles.module.scss'
import { LinkCell, SkuLinkCell } from 'src/components/Table/cells/NameCell'
import React from 'react'
import { NomenclatureCell } from 'src/components/Table/cells/NomenclatureCell'
import {
  CellInfoType,
  TemplateCellAdditionalData,
  TemplateFieldType,
  TemplateTableRow,
} from '@shared/types/tableGenerator/generatorTypes'
import {
  DocumentTypeEnum,
  IBarcode,
  IBatch,
  ICellPlace,
  IContainer,
  IContractorDto,
  IMerchant,
  ISku,
} from 'src/interfaces'
import { ARTICLE_POSTFIX, COPY_VALUE_POSTFIX, IMAGE_POSTFIX, SKU_BARCODE_POSTFIX } from '@shared/helpers/consts'
import { getCalendarConstaFieldType } from '@shared/helpers/calendar'
import { TextFieldPropValue } from '@consta/uikit/__internal__/src/components/TextField/types'
import cx from 'classnames'
import days from 'dayjs'
import { DATE_TIME_VIEW } from '@shared/const/date'
import { replaceTextInAngleBrackets } from '@shared/helpers/string'
import { generatePath } from 'react-router-dom'
import { PATHS } from '@shared/routing'
import { ComboboxItemDefault } from '@consta/uikit/Combobox'
import { Text } from '@shared/ui/Text'
import { DangerouslyHTML } from '@shared/ui/DangerouslyHTML'
import { Img } from '@shared/ui/Img'
import { Flex } from '@/newSrc/shared/ui/Flex'
import { generateFormDocumentPath } from '@/newSrc/shared/routing/config/paths'


type Row = Record<string, any>

type ComboboxValueType<Dto> = { id: string, label: string, dto: Dto }
type CommonPropsType<OnChangeVal> = {
  onChange: (val: OnChangeVal) => void
  fieldId: string
  link?: string
  editMode?: boolean
  isRequired?: boolean
  disabled?: boolean
  documentType?: DocumentTypeEnum
  extraFieldProps?: Record<string, any>
} & Row

/**
 *  Поле, которое упрощает добавление повторяющихся полей
 */
const GeneratorFieldWrapper = <T, >(props: CommonPropsType<T> & { type: FieldType }) => {
  const {
    previewLabel,
    type,
    readOnly,
    editMode,
    onChange,
    fieldId,
    value,
    link,
    disabled,

    extraFieldProps,
    filterState = {
      [fieldId]: value,
    },
  } = props

  if (editMode && !readOnly) {
    return (
      <GeneratorField
        type={type as any} // todo поправить тип
        disabled={disabled}
        fieldProps={{
          id: fieldId,
          label: '',
          className: cx(style.editCell, { [style.required]: props.isRequired }),
          viewDescription: '',
          ...extraFieldProps,
        }}
        valuesState={filterState}
        onChange={({ value }) => onChange(value)}
      />
    )
  }
  if (!value) return null
  if (previewLabel) return previewLabel
  return (
    <>
      {link
        ? <LinkCell link={link} withNewTab={true} title={value.label || value} />
        : value.label
      }
    </>
  )
}

export const CellField = (props: CommonPropsType<ComboboxValueType<ICellPlace>>) => {
  return (
    <GeneratorFieldWrapper
      {...props}
      type={FieldType.CELL}
    />
  )
}

export const ContainerField = (props: CommonPropsType<ComboboxValueType<IContainer>>) => {
  return (
    <GeneratorFieldWrapper
      {...props}
      type={FieldType.CONTAINER}
    />
  )
}

export const PlaceField = (props: CommonPropsType<ComboboxValueType<ICellPlace>>) => {
  return (
    <GeneratorFieldWrapper
      {...props}
      type={FieldType.PLACE}
    />
  )
}
export const MerchantField = (props: CommonPropsType<ComboboxValueType<IMerchant>>) => {
  return (
    <GeneratorFieldWrapper
      {...props}
      type={FieldType.MERCHANTS}
    />
  )
}
export const ContractorField = (props: CommonPropsType<ComboboxValueType<IContractorDto>>) => {
  return (
    <GeneratorFieldWrapper
      {...props}
      type={FieldType.CONTRACTOR}
    />
  )
}
export const BooleanField = (props: CommonPropsType<ComboboxValueType<any>>) => {
  return (
    <GeneratorFieldWrapper
      {...props}
      type={FieldType.BOOLEAN}
      previewLabel={
        <Text
          size={'s'}
          children={boolSelectItems.find(option => option.id === props.value)?.label || ''}
        />
      }
    />
  )
}
export const DocumentField = (props: CommonPropsType<ComboboxValueType<IMerchant>>) => {
  const { merchantId, documentType, link } = props
  return (
    <GeneratorFieldWrapper
      {...props}
      type={FieldType.DOCUMENT}
      link={link}
      extraFieldProps={{
        merchantId: merchantId ? [merchantId] : undefined,
        documentType: documentType,
      }}
    />
  )
}

type SkuFieldPropsType = CommonPropsType<ComboboxValueType<ISku>> & { merchantId?: string }
export const SkuField = (props: SkuFieldPropsType) => {
  const { value, merchantId } = props
  return (
    <GeneratorFieldWrapper
      {...props}
      type={FieldType.SKU}
      previewLabel={
        <SkuLinkCell
          title={`${value?.label || ''}`}
          itemId={value?.id || ''}
        />
      }
      extraFieldProps={{
        merchantId: merchantId ? [merchantId] : undefined,
      }}
    />
  )
}

type SkuBatchFieldPropsType = (
  CommonPropsType<IBatch>
  & { sku?: ISku, items: (ComboboxItemDefault & { dto?: IBatch })[], disabled?: boolean }
  )
export const SkuBatchField = (props: SkuBatchFieldPropsType) => {
  const { sku, value, items } = props
  return (
    <GeneratorFieldWrapper
      {...props}
      type={FieldType.SKU_BATCH}
      previewLabel={
        <BatchCell num={value?.dto?.num} date={value?.dto?.exp_date} />
      }
      extraFieldProps={{
        sku, items,
        updateSkuBatches: (newBatch) => {
          sku.batches = [...sku.batches, newBatch.dto]
        },
      }}
    />
  )
}

type InputFieldProps = CommonPropsType<TextFieldPropValue>
export const InputField = (props: InputFieldProps) => {
  return (
    <GeneratorFieldWrapper
      {...props}
      type={FieldType.input}
      previewLabel={
        props.value
          ? (
            <DangerouslyHTML
              withEscaping
              htmlStr={
                replaceTextInAngleBrackets(props.value,
                  (splitUrl) => generateFormDocumentPath({ docType: `${splitUrl[0]}`, docId: `${splitUrl[1]}` }))
              }
            />
          ) : null
      }
    />
  )
}

type UrlFieldProps = CommonPropsType<TextFieldPropValue>
export const UrlField = (props: UrlFieldProps) => {
  return (
    <GeneratorFieldWrapper
      {...props}
      type={FieldType.URL}
      previewLabel={
        props.value
          ? (
            <Flex justifyContent={'center'}>
              <Img className={style.previewImg} modal yandex src={props.value} />
            </Flex>
          ) : null
      }
    />
  )
}

export const SelectField = (props: CommonPropsType<ComboboxValueType<unknown>>) => {
  const options = props.selectOptions || []
  return (
    <GeneratorFieldWrapper
      {...props}
      type={FieldType.combobox}
      extraFieldProps={{
        multiple: false,
        items: options,
      }}
    />
  )
}

export const CalendarField = (props: CommonPropsType<string | null>) => {
  const { value, type, fieldId } = props
  const calendarType = getCalendarConstaFieldType(type)
  return (
    <GeneratorFieldWrapper
      {...props}
      type={FieldType.calendar}
      previewLabel={
        value
          ? <span>{days(value).format(DATE_TIME_VIEW)}</span>
          : null
      }
      extraFieldProps={{
        type: calendarType,
      }}
      filterState={{
        [fieldId]: value ? new Date(value) : null,
      }}
    />
  )
}


type TableFieldProps =
  CommonPropsType<{
    value: string,
    id: number,
    additional_data: { rows: TemplateTableRow[] }
  }>
  & TemplateCellAdditionalData
  & { handleAdditionalModalOpen?: (status: boolean) => void }

export const TableField = (props: TableFieldProps) => {
  const {
    fieldId,
    creationAvailable,
    deletionAvailable,
    id,
    rows,
    columns,
    handleAdditionalModalOpen,
    value,
    onChange,
    editMode,
  } = props

  return (
    <GeneratorField
      type={FieldType.TABLE}
      fieldProps={{
        id: fieldId,
        label: '',
        className: cx(style.editCell, { [style.required]: props.isRequired }),
        viewDescription: '',
        buttonDescription: value,
        // buttonDisabled: value,
        creationAvailable,
        deletionAvailable,
        dataId: id,
        rows,
        columns,
        editMode,
        handleAdditionalModalOpen,
      }}
      valuesState={{
        [fieldId]: value,
      }}
      onChange={({ value }) => onChange(value)}
    />
  )
}


/*
  Иногда нам требцется добавлять екстра столбцы для некоторых типов столбцов, тут это место,
  где можно их добавить
*/
type ResultType = Record<string/* ключ столбца в строке */, any /* значение для столбца */>
export const getExtraFieldsForColumns = (columnId: string, cellInfo: CellInfoType, columnType: TemplateFieldType): ResultType => {
  let res = {}
  const cellDto = cellInfo?.cell?.meta?.dto
  if ((columnType === TemplateFieldType.SKU) && cellDto) {
    res = {
      ...res,
      [`${columnId}${SKU_BARCODE_POSTFIX}`]: <BarcodesCell barcodes={cellDto.barcodes} shortMode />,
      [`${columnId}${SKU_BARCODE_POSTFIX}${COPY_VALUE_POSTFIX}`]: cellDto.barcodes.map((barcode: IBarcode) => barcode.barcode).join(', '),
      [`${columnId}${ARTICLE_POSTFIX}`]: <NomenclatureCell style={{ fontSize: '0.8em' }}
                                                           article={`${cellDto.article}`} />,
      [`${columnId}${ARTICLE_POSTFIX}${COPY_VALUE_POSTFIX}`]: cellDto.article,
      [`${columnId}${IMAGE_POSTFIX}`]: <ImageCell image={cellDto.image} />,
      [`${columnId}${IMAGE_POSTFIX}${COPY_VALUE_POSTFIX}`]: cellDto.image,
    }
  }
  return res
}
