import {
  Cell,
  Column, functionalUpdate,
  Header,
  makeStateUpdater,
  Row,
  RowData,
  Table,
  TableFeature,
  Updater,
} from '@tanstack/react-table'
import { CommonExtensionOptions, CommonExtensionTableState } from './types'
import { ContextMenuState } from '@/components/Table/TanStackTable/lib/features/contextMenu/types'

export const getContextMenuInitialState = (): CommonExtensionTableState => ({
  isFullScreenOn: false
})

export const CommonExtensionFeature: TableFeature<any> = {

  getInitialState: (state): CommonExtensionTableState => {
    const newState = getContextMenuInitialState()
    return {
      ...newState,
      ...state,
    }
  },

  getDefaultOptions: <TData extends RowData>(
    table: Table<TData>,
  ): CommonExtensionOptions => {
    return {
      onFullScreenChange: makeStateUpdater('isFullScreenOn', table),
    } as CommonExtensionOptions
  },

  createTable: <TData extends RowData>(table: Table<TData>): void => {
    table.setFullScreen = updater => {
      const safeUpdater: Updater<boolean> = old => functionalUpdate(updater, old)
      return table.options.onFullScreenChange?.(safeUpdater)
    }
    table.getIsFullScreen = () => {
      return Boolean(table.getState()?.isFullScreenOn)
    }
    table.toggleFullScreen = () => table.setFullScreen(!table.getIsFullScreen())
  },
  createHeader: <TData extends RowData>(header: Header<TData, unknown>, table: Table<TData>): void => {
  },
  createColumn: <TData extends RowData>(column: Column<TData, unknown>): void => {
  },
  createRow: <TData extends RowData>(Row: Row<TData>): void => {
  },
  createCell: <TData extends RowData>(cell: Cell<TData, unknown>, column: Column<TData>, row: Row<TData>): void => {
  },
}