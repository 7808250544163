import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHotkeyCopy, useKeyDownEscape } from '@shared/hooks'
import { useNotifications } from '@shared/providers/NotificationProvider'
import { Cell, Column, Row, Table } from '@tanstack/react-table'
import { useClickOutside } from '@consta/uikit/useClickOutside'


type PropsType<TRowData, TRowValue> = {
  table: Table<TRowData>
  tableContainerRef: Readonly<RefObject<HTMLElement>>,
}

/* Хук для помощи копирования таблиц */
export const useTableCopy = <TRowData, TRowValue>(props: PropsType<TRowData, TRowValue>) => {
  const {
    table,
    tableContainerRef,
  } = props

  const notification = useNotifications()

  const {
    copyState: {
      startForCopyID,
      endForCopyID
    },
    columnSizingInfo: {
      isResizingColumn
    }
  } = table.getState()

  /* Включаем/выключаем копирование */
  const copyEnabled = table.options.enableCopy && !Boolean(isResizingColumn)

  const [startCopyRegime, setStartCopyRegime] = useState(false)

  /**
   *  Рассчитываем выделенные столбцы/строки на основе диапазонов стейта
   */
  const {
    selectedColumnsIds,
    selectedRowsIds,
    rows: selectedRows,
    columns: selectedColumns,
  } = useMemo(table.getCopySelectedRowsColumns, [table, startForCopyID, endForCopyID])


  /**
   *  Сброс стейта
   */
  const resetCopyState = useCallback(() => {
    table.resetCopyState()
    setStartCopyRegime(false)
  }, [table])



  /**
   *  Вычисляем клик внутри таблицы, чтобы избавиться от лишнего перерендера
   */
  const isLeftBtn = (e) => {
    return e.button === 0
  }
  const isMouseInBound = (e) => {
    const eleBounds = tableContainerRef.current.getBoundingClientRect()
    return (
      (e.clientX >= eleBounds.left && e.clientX <= eleBounds.right) // inside X
      && (e.clientY >= eleBounds.top && e.clientY <= eleBounds.bottom) // inside Y
    )
  }


  /**
   *  Выделение всей таблицы
   */
  const selectAll = useCallback(table.selectAllTableToCopy, [])

  /**
   *  Копирование выделенных элементов таблицы
   */
  const clickCopy = () => {
    if (!copyEnabled) return
    let columnsToCopy = selectedColumns
    let rowsToCopy = selectedRows
    if (table.isSelectAll()) {
      columnsToCopy = table.getAllVisibleColumnsToCopy()
      rowsToCopy = table.getRowModel().flatRows
    }
    table.copyTableData(columnsToCopy, rowsToCopy)
  }


  /**
   *  Метод выделения ячеек при отпускании клавиши
   */
  const handleMouseOverRowById = useCallback((columnId: string, rowId: string, e) => {
    if (!isLeftBtn(e)) return
    table.handleCopyMouseOverRowById(columnId, rowId)
  }, [startForCopyID, endForCopyID])

  /**
   *  Метод выделения ячеек при нажатии клавиши
   */
  const handleMouseDownRowById = useCallback((columnId: string, rowId: string, e) => {
    if (!isLeftBtn(e)) return
    table.handleCopyMouseDownRowById(columnId, rowId)
  }, [startForCopyID, endForCopyID])

  /**
   * проверяем выделение ячейки столбца
   */
  const handleCheckColumnSelection = useCallback(<TData,>(column: Column<TData>): boolean => {
    return column.getColumnIsSelectionToCopy(selectedColumnsIds) && column.getCanCopy()
  }, [selectedColumnsIds, table])

  const handleCheckCellSelection = useCallback(<TData>(cell: Cell<TData, unknown>): boolean => {
    return cell.getCellIsSelectionToCopy(selectedColumnsIds, selectedRowsIds) && cell.column.getCanCopy()
  }, [selectedColumnsIds, selectedRowsIds])

  const handleClickOutside = (e: MouseEvent) => {
    /* Костыль, который блокирует сброс */
    const target = e.target as HTMLElement
    const closestMenu = target?.closest('#TableContextMenu')
    if (closestMenu) return

    resetCopyState()
  }

  /**
   *  Сброс стейта на клик вне таблицы
   */
  useClickOutside({
    isActive: !startCopyRegime,
    ignoreClicksInsideRefs: [tableContainerRef],
    handler: handleClickOutside,
  })

  /**
   *  Копируем на ctrl+C
   */
  useHotkeyCopy({
    isActive: copyEnabled,
    copyActive: Boolean(startForCopyID[0]),
    selectAllActive: Boolean(startForCopyID[0]),
    copyHandler: clickCopy,
    selectAllHandler: selectAll,
  }, [startForCopyID, endForCopyID])


  /**
   *  Сбрасываем стейт копирования при нажатии ескейп
   */
  // useKeyDownEscape({
  //   isActive: copyEnabled,
  //   handler: resetCopyState,
  // }, [startForCopyID, endForCopyID])


  /**
   *  Сброс стейта если отключено копирование, надо для динамического сброса
   */
  useEffect(() => {
    if (!copyEnabled) {
      resetCopyState()
    }
  }, [resetCopyState, copyEnabled])


  /**
   *  Обработка нажатий
   */
  useEffect(() => {
    if (!copyEnabled) return

    /* На нажатие клавиши мышки/тачпаде активируем режим копирования */
    const onMouseDown = (e) => {
      if (isMouseInBound(e) && isLeftBtn(e)) {
        setStartCopyRegime(true)
      }
    }

    /* При отпускании клавиши мышки/тачпаде отключаем режим копирования */
    const onMouseUp = (e) => {
      if (isLeftBtn(e)) {
        setStartCopyRegime(false)
      }
    }

    document.addEventListener('mousedown', onMouseDown)
    document.addEventListener('mouseup', onMouseUp)

    return () => {
      document.removeEventListener('mousedown', onMouseDown)
      document.removeEventListener('mouseup', onMouseUp)
    }
  }, [copyEnabled])


  /* Пропсы для ячеек с копированием */
  const getTableCellCopyEventsProps = useCallback((columnId: string, rowId: string) => ({
    onMouseDown: (e) => {
      if (!copyEnabled) return
      handleMouseDownRowById(columnId, rowId, e)
    },
    onMouseOver: (e) => {
      if (!startCopyRegime || !copyEnabled) return
      handleMouseOverRowById(columnId, rowId, e)
    },
  }), [handleMouseDownRowById, startCopyRegime, handleMouseOverRowById, copyEnabled]);

  return {
    resetCopyState,
    handleCheckCellSelection,
    handleCheckColumnSelection,
    getTableCellCopyEventsProps,
  }
}