import { IconCalendar } from '@consta/icons/IconCalendar'
import { FieldRow, FieldType } from '@widgets/GeneratorFields'
import { BILLING_STATUSES, IMerchant } from 'src/interfaces'
import { generateStatusesOptions } from '@shared/helpers'


export const generateFilters = (
  merchants: IMerchant[],
): FieldRow[] => [
  {
    hidden: true,
    fields: [
      {
        type: 'input',
        fieldProps: {
          id: 'search',
          viewDescription: 'Поиск',
        },
      },
    ],
  },
  {
    fields: [
      {
        type: 'calendar',
        fieldProps: {
          id: 'year_month',
          label: 'Месяц',
          placeholder: '',
          // form: 'defaultBrick',
          rightSide: IconCalendar,
          viewDescription: 'Месяц',
          type: 'month',
        },
      },
      {
        type: FieldType.MERCHANTS,
        fieldProps: {
          id: 'merchant',
          isMultiple: true,
          label: 'Организации',
          viewDescription: 'Организации',
        }
      },
      {
        type: 'combobox',
        fieldProps: {
          id: 'statuses',
          isMultiple: true,
          label: 'Статус',
          items: generateStatusesOptions(BILLING_STATUSES),
          viewDescription: 'Статус',
        },
      },
    ],
  },
]