
/**
 * Складу надо начинать нумерацию с единицы
 */
export const getRowIdForNumeric = (rowId: string, currentPage: number = 0) =>
  rowId
    .split('.')
    .map((el, i) => {
      const isFirstEl = !i
      let idNumber = Number(el) + 1 // для отображения не с нуля (складу нули не нравятся)
      if (currentPage && isFirstEl) { // для корректировки по номеру стр.
        idNumber += currentPage
      }
      return idNumber
    }).join('.')