import React from 'react'
import { ComboboxWrapperWithApi } from '@/components'
import { IDeliveryCompanyProduct } from '@/interfaces'
import { CommonFieldProps } from '@shared/types/fields'
import useGetDeliveryCompanyProduct from '../../../../../hooks/enums/useGetDeliveryCompanyProduct'

interface IProps extends CommonFieldProps  {
  deliveryCompanyId?: string
  convertHookData?: (data: { products: IDeliveryCompanyProduct[] }) => any
}

const ComboboxDeliveryCompanyProduct = (props: IProps) => {
  const {
    label = 'Код службы доставки',
    placeholder = 'Код службы доставки',
    convertHookData = (data) => {
      return data.products.map((el) => ({
        label: el.title,
        id: el.code,
        dto: el,
      }))
    },
    convertHookParams = (params) => {
      return {
        delivery_company: props.deliveryCompanyId,
      }
    },
    ...restProps
  } = props

  return (
    <ComboboxWrapperWithApi
      withApiSearch={false}
      label={label}
      placeholder={placeholder}
      useGetHookSearch={useGetDeliveryCompanyProduct}
      convertHookData={convertHookData}
      convertHookParams={convertHookParams}
      {...restProps}
    />
  )
}
export default ComboboxDeliveryCompanyProduct
