import React, { FC, useCallback, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { IRouteParams, ISection } from '@pages/DirectoriesPage/types'
import { useFilterState, useToggle } from '@shared/hooks'
import { generateFilterParams } from './utils/filters'
import { generateTableData } from './utils/table'
import { DirectoriesLayout } from '@pages/DirectoriesPage/components/DirectoriesLayout/DirectoriesLayout'
import { useDeleteContractor, useGetContractorsList } from '@/hooks'
import { ConfirmActionModal } from '@/components'
import { generateDirectoriesPath } from '@shared/routing/config/paths'

interface CategoriesProps extends ISection {
}

export const ContractorsList: FC<CategoriesProps> = ({ title }) => {
  const { page = '', section } = useParams<IRouteParams>()

  const history = useHistory()

  const [pagination, setPagination] = useState(0)
  const [contractorId, setContractorId] = useState<string | null>(null)

  const {
    value: confirmModalVisible,
    turnOn: showConfirmModal,
    turnOff: hideConfirmModal,
  } = useToggle()

  const {
    filterStateDebounced,
    filterState,
    handleChangeFilter,
  } = useFilterState({
    withoutHistory: true, onChangeFilterState: () => {
      setPagination(0)
    },
  })

  const {
    isFetching: isFetchingContractor,
    refetch: refetchContractors,
    data: contractorsData,
  } = useGetContractorsList(
    { ...generateFilterParams({ ...filterState, page: pagination }), warehouse_merchant: true },
  )
  const { mutateAsync: deleteDoc } = useDeleteContractor()


  const moveToForm = useCallback((contractorId?: string) => {
    history.push(generateDirectoriesPath({ page, section, type: 'item', id: contractorId }))
  }, [history])

  const handleEdit = useCallback((contractorId: string) => {
    moveToForm(contractorId)
  }, [moveToForm])

  const handleShowConfirmModal = useCallback((contractorId: string) => {
    setContractorId(contractorId)
    showConfirmModal()
  }, [])

  const handleDelete = useCallback(() => {
    if (!contractorId) {
      console.error('Нет ID контрагента')
      return
    }
    deleteDoc({ contractorId })
      .then((data) => {
        refetchContractors()
      })
      .catch(() => {
      })
      .finally(() => setContractorId(null))
  }, [contractorId, refetchContractors, refetchContractors])

  const { rows, columns } = useMemo(() => {
    return generateTableData(
      contractorsData?.contractors,
      filterStateDebounced?.search,
      handleEdit,
      handleShowConfirmModal,
      page,
      section,
    )
  }, [
    contractorsData?.contractors,
    filterStateDebounced?.search,
    handleEdit,
    handleShowConfirmModal,
    page,
    section,
  ])

  const closeConfirmModal = useCallback(() => {
    setContractorId(null)
    hideConfirmModal()
  }, [])

  const handleClickCreate = useCallback(() => moveToForm(), [moveToForm]);


  return (
    <DirectoriesLayout
      withTable
      withCreate
      withFilter
      withSearch

      title={title}

      filterState={filterState}
      handleChangeFilter={handleChangeFilter}

      onCreateBtnClick={handleClickCreate}

      isLoading={isFetchingContractor}
      columns={columns}
      rows={rows}
      pagination={contractorsData?.pagination}
      onChangePage={(page) => handleChangeFilter('page', page)}
    >
      <ConfirmActionModal
        isOpen={Boolean(confirmModalVisible && contractorId)}
        type='warning'
        withPreview={false}
        title={'Вы уверены, что хотите удалить контрагента?'}
        size={'min'}
        confirmWithScan={false}
        onClose={closeConfirmModal}
        onSubmit={handleDelete}
      />
    </DirectoriesLayout>
  )
}
