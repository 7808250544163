import React, { useCallback } from 'react'
import cx from 'classnames'
import styles from './TreeSelect.module.scss'
import DropdownTreeSelect, { DropdownTreeSelectProps, Mode, TreeNode } from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'
import { Text } from '@shared/ui/Text'
import { PropSize } from '@consta/uikit/__internal__/src/components/SelectComponents/types'
import { TextPropSize } from '@consta/uikit/__internal__/src/components/Text/Text'


export type TreeOptionType = {
  label: string
  value: string
  children?: TreeOptionType[]
  [key: string]: any
}

export type TreeSelectProps = {
  mode?: Mode
  id?: string
  label?: string
  size?: PropSize
  textSize?: TextPropSize
  options?: TreeOptionType[]
  wrapperClassName?: string
  onChange: (currentNode: TreeNode, selectedNodes: TreeNode[]) => void
} & Partial<DropdownTreeSelectProps>


export const TreeSelect = (props: TreeSelectProps) => {
  const {
    id,
    label,
    options = [],
    onChange,
    size = 'm',
    textSize = size,
    wrapperClassName,
    mode = 'multiSelect',
    ...otherProps
  } = props

  const onChangeTree = useCallback((currentNode: TreeNode, selectedNodes: TreeNode[]) => {
    onChange(currentNode, selectedNodes)
  }, [onChange])

  return (
    <div className={cx(styles.wrapper, {[styles[size]]: size}, wrapperClassName)}>
      {label ? <Text view={'secondary'} children={label} size={textSize} className={styles.label}/> : null}
      <DropdownTreeSelect
        mode={mode}
        texts={{ placeholder: " " }}
        data={options}
        onChange={onChangeTree}
        className={cx(styles.treeSelect)}

        {...otherProps}
      />
    </div>
  )
}

