import React, { useEffect, useMemo, useRef } from 'react'
import styles from './TableSettingsMenu.module.scss'
import cx from 'classnames'
import { Column, Table } from '@tanstack/react-table'
import { Flex } from '@shared/ui/Flex'
import { Text } from '@shared/ui/Text'
import { Checkbox } from '@consta/uikit/Checkbox'
import { useClickOutside } from '@consta/uikit/useClickOutside'
import { MenuWrapper } from '@/components/Table/TanStackTable/components/MenuWrapper/MenuWrapper'
import { DensityState } from '@/components/Table/TanStackTable/lib/features/density/types'
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup'
import { useDefaultSettings } from '@shared/hooks/settings'
import { useForceUpdate } from '@shared/hooks/useForceUpdate'
import { Divider } from '@shared/ui/Divider'


export type SettingsConfig = {
  columnsHiding: boolean
  density: boolean
}

type saveLSProps = {
  tableId: string,
  pageId?: string,
}

interface TableSettingsProps<TRowData> extends saveLSProps {
  isOpen: boolean
  table: Table<TRowData>
  btnRef: React.RefObject<HTMLElement>
  config: SettingsConfig
}

const HiddenWrapper = (props: {children: React.ReactNode}) => <div className={styles.sectionContent} children={props.children}/>

export const TableSettingsMenu = <TRowData, >(props: TableSettingsProps<TRowData>) => {
  const {
    tableId,
    pageId,
    isOpen,
    table,
    btnRef,
    config,
  } = props

  const wrapperRef = useRef()
  const currentDensity = table.getState().density

  useClickOutside({
    isActive: isOpen,
    ignoreClicksInsideRefs: [wrapperRef, btnRef],
    handler: () => table.setTableSettingsVisible(false),
  })

  const Section = useMemo(() => ({ title, children }: { title: string, children: React.ReactNode }) => (
    <Flex direction={'column'} gap={'s'} className={styles.section}>
      <Text size={'s'} weight={'bold'} children={title} />
      {children}
    </Flex>
  ), [])

  return (
    <MenuWrapper
      wrapperRef={wrapperRef}
      className={cx(styles.TableSettingsWrapper, { [styles.open]: isOpen })}
    >
      <Flex direction={'column'} gap={'m'}>
        {
          config.density ? (
            <Section title={'Размер таблицы'}>
              <DensitySettingsSettings tableId={tableId} pageId={pageId} table={table} currentDensity={currentDensity} />
            </Section>
          ) : null
        }
        {
          config.columnsHiding ? (
            <>
              <Divider />
              <Section title={'Настройка отображения столбцов'}>
                <ColumnVisibleSettings<TRowData>
                  tableId={tableId}
                  table={table}
                  columns={table.getAllLeafColumns()}
                />
              </Section>
            </>
          ) : null
        }
      </Flex>
    </MenuWrapper>
  )
}

type CheckboxKeys = 'byTable' | 'byPage'
type SaveBtnsProps = {
  saveTableChecked: boolean
  savePageChecked: boolean
  onChange: (key: CheckboxKeys, checkedValue: boolean) => void
}

const SaveByPageCheckbox = (props:{ checked: boolean, onChange: SaveBtnsProps['onChange'] }) => (
  <Checkbox
    checked={props.checked}
    onChange={(e) => props.onChange('byPage', e.target.checked)}
    label={'Применить для страницы'}
    size={'xs'}
  />
)
const SaveByTableCheckbox = (props:{ disabled: boolean, checked: boolean, onChange: SaveBtnsProps['onChange'] }) => (
  <Checkbox
    disabled={props.disabled}
    checked={props.checked}
    onChange={(e) => props.onChange('byTable', e.target.checked)}
    label={'Применить для таблицы'}
    size={'xs'}
  />
)

type DensitySettingsProps<TRowData> = saveLSProps & {
  table: Table<TRowData>,
  currentDensity: DensityState
}
const DensitySettingsSettings = <TRowData, >(props: DensitySettingsProps<TRowData>) => {
  const {
    tableId,
    pageId,
    table,
    currentDensity,
  } = props

  const densities: DensityState[] = [
    'xs', 's', 'm', 'l', 'xl',
  ]

  const {
    getTableDefaultDensityByPage,
    setTableDefaultDensityByPage,
    setTableDefaultDensityByTable,
  } = useDefaultSettings()

  const savedPageDensity = getTableDefaultDensityByPage(pageId)
  const forceUpdate = useForceUpdate()

  const handleUpdateDensity = (density) => {
    setTableDefaultDensityByTable(tableId, density)
    table.setDensity(density)
  }

  return (
    <Flex
      direction={'column'}
      gap={'xs'}
    >
      <SaveByPageCheckbox
        checked={savedPageDensity === currentDensity}
        onChange={(key, checkedValue) => {
          setTableDefaultDensityByTable(tableId, null)
          setTableDefaultDensityByPage(pageId, checkedValue ? currentDensity : null)
          forceUpdate()
        }}
      />
      <ChoiceGroup
        value={currentDensity}
        onChange={handleUpdateDensity}
        items={densities}
        getItemLabel={(item) => item}
        multiple={false}
        name='densityChoices'
        size={'xs'}
      />
    </Flex>
  )
}


type ColumnVisibleSettingsProps<TRowData> = {
  table: Table<TRowData>
  columns: Column<TRowData, unknown>[]
} & saveLSProps
const ColumnVisibleSettings = <TRowData, >(props: ColumnVisibleSettingsProps<TRowData>) => {
  const {
    tableId,
    table,
    columns,
  } = props

  const {
    columnVisibility
  } = table.getState()

  const {
    getTableDefaultHiddenColumns,
    setTableDefaultHiddenColumns
  } = useDefaultSettings()

  const hiddenColumnsIdsList = useMemo(() => {
    return Object
        .entries(columnVisibility)
        .filter(el => !el[1])
        .map(el => el[0])
  }, [columnVisibility])

  useEffect(() => {
    setTableDefaultHiddenColumns(tableId, hiddenColumnsIdsList)
  }, [hiddenColumnsIdsList])

  // const forceUpdate = useForceUpdate()
  // const defaultHiddenColumns = getTableDefaultHiddenColumns(tableId)
  // const saveByTableCheckboxValue = Boolean(defaultHiddenColumns?.length)

  return (
    <Flex direction={'column'} gap={'xs'}>
      {/*<SaveByTableCheckbox*/}
      {/*  disabled={!hiddenColumnsIdsList?.length}*/}
      {/*  checked={saveByTableCheckboxValue}*/}
      {/*  onChange={(key, checkedValue) => {*/}
      {/*    setTableDefaultHiddenColumns(tableId, checkedValue ? hiddenColumnsIdsList : null)*/}
      {/*    forceUpdate()*/}
      {/*  }}*/}
      {/*/>*/}
      <HiddenWrapper>
        <Flex direction={'column'} gap={'2xs'} className={styles.columnsVisibleSettings}>
          <Checkbox
            size={'s'}
            key={'-1'}
            label={'Скрыть/открыть все столбцы'}
            checked={table.getIsAllColumnsVisible()}
            onChange={table.getToggleAllColumnsVisibilityHandler()}
          />
          <Divider />
          {columns.map(column => {
            if (!column.getCanHide()) return null
            return (
              <Checkbox
                size={'s'}
                key={column.id}
                label={column.columnDef.meta?.headerText}
                checked={!hiddenColumnsIdsList.includes(column.id)}
                onChange={column.getToggleVisibilityHandler()}
              />
            )
          })}
        </Flex>
      </HiddenWrapper>
    </Flex>
  )
}
