import React, { FC, useState } from 'react'
import styles from './add-new-item-modal.module.scss'
import { InputWrapper, Modal, Select } from 'src/components'
import { ISelectOption, ModalProps } from '@/interfaces'
import { Button } from '@shared/ui/btns/Button'
import { ACCEPTANCE_TYPES } from './types'
import { useGetItemsM } from '@/hooks'

interface AddNewItemModalProps extends ModalProps {
  onSubmit: (item: any) => void
}

const AddNewItemModal: FC<AddNewItemModalProps> = (props) => {
  const { isOpen, onClose, onSubmit } = props

  const [barcode, setBarcode] = useState<string>('')
  const [acceptanceOption, setAcceptanceOption] =
    useState<ISelectOption | null>(null)
  const [multiplicity, setMultiplicity] = useState<string | null>('1')
  const [quantity, setQuantity] = useState<string | null>('1')
  const [skuId, setSkuId] = useState<string | null | undefined>(null)
  const { mutate: getItems } = useGetItemsM()

  const handleSubmit = () => {
    getItems(
      { search: barcode },
      {
        onSuccess: (data) => {
          const newItem = {
            barcode_used: barcode,
            acceptance_type: acceptanceOption?.value,
            multiplicity: multiplicity,
            quantity: quantity,
            sku_id: data?.skus[0]?.id,
          }
          onSubmit(newItem)
        }
      },
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      title={'Новый товар'}
      titleAlign={'left'}
      onOverlayClick={(): boolean => false}
      className={styles.modal}
      size="l"
    >
      <div className={styles.inputWrapper}>
        <InputWrapper
          label="Штрихкод"
          value={String(barcode)}
          handleChange={(value) => setBarcode(String(value))}
        />
        <Select
          label="Тип приемки"
          options={ACCEPTANCE_TYPES}
          value={acceptanceOption}
          onChange={(value) => setAcceptanceOption(value)}
          className={styles.select}
        />
        <InputWrapper
          label="Кратность"
          isInteger
          disabled={
            acceptanceOption?.value === 'PIECE' ||
            acceptanceOption === null
          }
          value={String(multiplicity)}
          handleChange={(value) => setMultiplicity(value)}
        />
        <InputWrapper
          label="Количество"
          isInteger
          value={String(quantity)}
          handleChange={(value) => setQuantity(value)}
        />
      </div>
      <div className={styles.buttonWrapper}>
        <Button view="ghost" label="Отменить" onClick={onClose} />
        <Button
          label="Сохранить"
          disabled={
            barcode === null || barcode === '' || acceptanceOption === null
          }
          onClick={handleSubmit}
        />
      </div>
    </Modal>
  )
}

export default AddNewItemModal
