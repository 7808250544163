import { useMutation } from 'react-query'
import api from '@shared/api'

import { ApiReqResult } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'
import { CreateUserCompanyData } from '@entities/UserCompany'

interface Result extends ApiReqResult {
  id: string
}

interface Params {
  companyId: string,
  data: CreateUserCompanyData
}


const useCreateEditUserCompany = (
  options?: UseMutationOptions<Result, any, Params>,
) => {
  return useMutation(
    async (params: Params) => {
      const path = `/warehouse/user-company${params.companyId ? `/${params.companyId}` : ''}`
      const result = await api.post(path, params.data)

      if (!result?.data?.success) {
        throw result
      }
      return result.data
    },
    options,
  )
}

export default useCreateEditUserCompany
