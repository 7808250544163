import { createIcon } from '@consta/icons/Icon'
import { SVGProps } from 'react'

const IconSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path fillRule='evenodd' clipRule='evenodd'
          d='M2 1C1.44772 1 1 1.44772 1 2V14C1 14.5523 1.44772 15 2 15H14C14.5523 15 15 14.5523 15 14V2C15 1.44772 14.5523 1 14 1H9V4C9 4.55228 8.55229 5 8 5C7.44772 5 7 4.55228 7 4V1H2ZM10 11C10 10.4477 10.4477 10 11 10H12C12.5523 10 13 10.4477 13 11V12C13 12.5523 12.5523 13 12 13H11C10.4477 13 10 12.5523 10 12V11Z'
    />
  </svg>
)

export const IconBox = createIcon({
  l: IconSizeM,
  m: IconSizeM,
  s: IconSizeM,
  xs: IconSizeM,
  name: 'IconBox',
});