import type { FC, ReactNode } from 'react'
import React from 'react'

import cx from 'classnames'
import styles from './info-block.module.scss'

import { Text } from '@shared/ui/Text'
import { Flex } from '@/newSrc/shared/ui/Flex'

interface IInfoBlock {
  className?: string
  children: ReactNode
  title: string
  /** Вывод красной звёздочки у заголовка */
  isRequired?: boolean
}

const InfoBlock: FC<IInfoBlock> = ({
  className = '',
  title,
  children,
  isRequired = false,
}) => {
  const titleClassNames = cx(
    styles.title,
    isRequired ? styles.title_required : null,
    `${className}__title`,
  )

  return (
    <Flex
      as={'section'}
      className={`${className}__wrapper`}
      direction={'column'}
      gap={'s'}
    >
      <Text as={'h2'} className={titleClassNames}>
        {title}

        {isRequired && <span className={styles.required}> *</span>}
      </Text>

      {children}
    </Flex>
  )
}

InfoBlock.displayName = 'InfoBlock'

export default InfoBlock
