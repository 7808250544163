import React, { FC, useEffect, useState } from 'react'
import cx from 'classnames'
import styles from './supply-form-content.module.scss'
import { PATHS } from '@shared/routing'
import { Tabs } from '@consta/uikit/Tabs'
import { TabsItem } from '../../helpers/documentTabs'
import { ITab } from 'src/interfaces'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { OperAndDocumPagesType } from '../../pagesType'

interface TabsContentWrapperProps {
  loading?: boolean
  tabs: TabsItem[]
  children?: React.ReactNode
}

const TabsContentWrapper: FC<TabsContentWrapperProps> = (props) => {
  const {tabs} = props
  const history = useHistory()
  const { page, id: supplyId, tabId } = useParams<{ page: OperAndDocumPagesType, id: string, tabId: string }>()
  const correctTab = tabs.find(tab => tab.value === tabId)

  const [selectedTab, selectTab] = useState<ITab | null>(null)

  useEffect(() => {
    const tab = correctTab || tabs[0]
    if (tab?.value === selectedTab?.value) {
      return
    }
    selectTab(tab)
  }, [tabId])

  useEffect(() => {
    if (selectedTab?.value) {
      const search = window.location.search
      const params = { page: page, id: supplyId, tabId: selectedTab.value }
      history.push(`${generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, params)}${search}`)
    }
  }, [selectedTab])

  return (
    <div className={cx(styles.supplyFormContent)}>
      <Tabs
        value={selectedTab}
        onChange={(value) => selectTab(value)}
        items={tabs}
        getItemLabel={(tab) => tab.label}
        view='clear'
        className={styles.tabs}
      />
      {props.children}
    </div>
  )
}

export default TabsContentWrapper
