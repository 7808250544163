import { TemplateFieldType } from '../types/tableGenerator/generatorTypes'
import { DatePickerPropType } from '@consta/uikit/DatePicker'
import { DATE_REQUEST_FORMAT, DATE_MONTH_FORMAT, DATETIME_REQUEST_FORMAT, TIME_FORMAT } from '@shared/const/date'
import days from 'dayjs'


/**
 * Получение формата данных для компонента календаря по типу поля с бека
 */
export const getCalendarConstaFieldType = (templateType: TemplateFieldType): DatePickerPropType => {
  switch (templateType) {
    case TemplateFieldType.DATETIME:
      return 'date-time'
    case TemplateFieldType.DATE:
      return 'date'
    case TemplateFieldType.YEARMONTH:
      return "month"
    case TemplateFieldType.TIME:
      return "time"
    default:
      return "date"
  }
}
/**
 * Получение формата данных для запросов по типу поля
 */
export const getCalendarValueFormatByFieldType = (templateType: TemplateFieldType, value: Date): string | null => {
  let format = DATETIME_REQUEST_FORMAT
  switch (templateType) {
    case TemplateFieldType.DATETIME:
      format = DATETIME_REQUEST_FORMAT
      break;
    case TemplateFieldType.DATE:
      format = DATE_REQUEST_FORMAT
      break;
    case TemplateFieldType.YEARMONTH:
      format = DATE_MONTH_FORMAT
      break;
    case TemplateFieldType.TIME:
      format = TIME_FORMAT
      break;
  }
 return value ? days(value).format(format) : null
}