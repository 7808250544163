import React, { useEffect, useRef } from 'react'
import cx from 'classnames'
import { useNotifications } from '../../lib/useNotifications'

import styles from './notifications.module.scss'

import { Informer } from '@consta/uikit/Informer'

import { NOTIFICATIONS_TIMEOUT } from '@shared/const/common'

import { Button } from '@/components'
import { IconClose } from '@consta/icons/IconClose'
import { INotification } from '../../config/schema'
import { IconShape } from '@consta/icons/IconShape'
import { Flex } from '@shared/ui/Flex'
import isMobile from 'ismobilejs'
import { useCollapseText } from '@shared/hooks/useCollapseText'

type NotificationProps = INotification & {
  id: string
  showNotifDetail?: (notif: INotification) => void
}

const Notification = (props: NotificationProps) => {
  const { id, type, content, options, allErrors, showNotifDetail } = props

  const initialLabel = typeof content === 'string' ? content : ''
  const initialChildren = typeof content === 'object' ? content : null

  const {
    text: label,
  } = useCollapseText({ text: initialLabel, symbols: 800 })

  const context = useNotifications()
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()

  const isMobileAny = isMobile().any;

  const clickableNotif = options?.clickable && !isMobileAny
  const closableNotif = options?.withClose && !isMobileAny

  const closeNotif = () => context?.clear(id)

  useEffect(() => {
    /* Дефолтный таймер закрытия */
    context?.playSound(type || '', options)
    if (!options?.persistent && timeoutRef) {
      timeoutRef.current = setTimeout(() => {
        closeNotif()
      }, options?.timeout ?? NOTIFICATIONS_TIMEOUT)
    }
  }, [timeoutRef, options?.persistent])

  const handleClickOnWrapper = () => {
    /* Сбрасываем таймер, чтобы было время на открытие деталки */
    if (!clickableNotif) return
    clearTimeout(timeoutRef.current)
  }

  const handleClickModalBtn = () => {
    /* Открываем модалку сдетализацией, если есть список ошибок */
    if (!allErrors?.length) {
      alert('Нет данных об ошибке')
      return
    }
    showNotifDetail?.(props)
  }

  return (
    <>
      <div
        className={cx(
          styles.notifWrapper,
          styles[type],
          { [styles.clickable]: clickableNotif },
        )}
        onClick={clickableNotif ? handleClickOnWrapper : undefined}
      >
        <Informer
          className={cx(styles.wrap, options?.allWidth && styles.allWidth)}
          title={undefined}
          status={type}
          label={label}
          children={initialChildren}
          icon={options?.icon}
        />
        <Flex
          gap={'m'}
          className={cx(styles.btnsWrapper, styles[type])}
        >
          {
            clickableNotif ? (
              <Button
                onlyIcon
                size={'xs'}
                view={'secondary'}
                theme={'white'}
                iconLeft={IconShape}
                className={styles.openDetailModalBtn}
                onClick={options?.onClick || handleClickModalBtn}
              />
            ) : null
          }
          {
            closableNotif ? (
              <Button
                onlyIcon
                size={'xs'}
                view={'secondary'}
                theme={'white'}
                iconLeft={IconClose}
                iconSize={'s'}
                className={styles.allWidthNotifCloseBtn}
                onClick={options?.onClose || closeNotif}
              />
            ) : null
          }
        </Flex>
      </div>
    </>
  )
}

export default Notification
