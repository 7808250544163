import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import type { ISection } from '../../types'
import { TableColumn } from '@consta/uikit/Table'
import { Button, ConfirmActionModal } from '@/components'
import { IconPrinter } from '@shared/assets/icons/IconPrinter'
import { IconAdd } from '@consta/icons/IconAdd'
import { IWorkspacesRender, IWorkspacesRes } from './types'
import WorkspacesModal from '../../components/WorkspacesModal/WorkspacesModal'
import { useDeleteJobs, useGetJobsExcel, useGetWarehouseWorkspacesList } from '@/hooks/warehouse/jobs'
import { IWorkspace } from '@/interfaces'
import { saveBase64AsPdf } from '@shared/helpers'
import { EditRemoveButtons } from '@pages/DirectoriesPage/components/EditRemoveButtons/EditRemoveButtons'
import { DirectoriesLayout } from '@pages/DirectoriesPage/components/DirectoriesLayout/DirectoriesLayout'
import { useFilterState } from '@shared/hooks'
import { IconEdit } from '@consta/icons/IconEdit'
import { IconTrash } from '@consta/icons/IconTrash'

const Workspaces: FC<ISection> = ({
  className = '',
  title = '',
}) => {
  const [workspaces, setWorkspaces] = useState<IWorkspacesRender[]>([])
  const [filteredWorkspaces, setFilteredWorkspaces] = useState<IWorkspacesRender[]>([])
  const [openModal, setOpenModal] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [workspace, setWorkspace] = useState<IWorkspace | null>(null)
  const { fetch: getJobsExcel } = useGetJobsExcel()
  const { mutateAsync: deleteWorkspace } = useDeleteJobs()

  const {
    filterStateDebounced,
    filterState,
    handleChangeFilter,
  } = useFilterState({
    withoutHistory: true,
  })

  useEffect(() => {
    const query = filterState['search']?.toLowerCase() || ''
    setFilteredWorkspaces(workspaces.filter((workspace) => {
      const name = workspace.title.toLowerCase()
      if (!query || name.includes(query)) {
        return workspace
      }
      return false
    }))
  }, [filterStateDebounced])


  const {
    isLoading: isLoadingWorkspaces,
    data: workspacesData,
    refetch,
    isSuccess: isWorkspacesDataSuccess,
    isFetching: isWorkspacesDataFetching,
  } = useGetWarehouseWorkspacesList(false)

  const data = useMemo(() => workspacesData?.workspaces || [], [workspacesData?.workspaces])

  const onSubmitEdit = useCallback(() => {
    setWorkspace(workspace)
    setOpenModal(true)
  }, [setWorkspace, setOpenModal])

  const onSubmitDelete = useCallback(() => {
    setWorkspace(workspace)
    setOpenConfirmModal(true)
  }, [setWorkspace, setOpenConfirmModal])

  const columns: TableColumn<(typeof workspaces)[number]>[] = useMemo(() => [
    {
      title: 'Наименование',
      accessor: 'title',
      width: 400,
    },
    {
      title: 'Штрихкод',
      accessor: 'barcode',
      sortable: false,
      width: 400,
    },
    {
      title: '',
      accessor: 'edit__action',
      width: 1,
      renderCell: (row: any) => {
        return (
          <Button
            onlyIcon
            view={'ghost'}
            size={'xs'}
            iconSize={'s'}
            iconLeft={IconEdit}
            onClick={onSubmitEdit}
          />
        )
      },
    },
    {
      title: '',
      accessor: 'delete__action',
      width: 1,
      renderCell: (row: any) => {
        return (
          <Button
            onlyIcon
            view={'ghost'}
            size={'xs'}
            iconSize={'s'}
            theme={'danger'}
            iconLeft={IconTrash}
            onClick={onSubmitDelete}
          />
        )
      },
    },
    {
      title: '',
      accessor: 'excel__action',
      sortable: false,
      width: 1,
      renderCell: (row: any) => {
        return (
          <Button
            onlyIcon
            view={'ghost'}
            size={'xs'}
            iconSize={'s'}
            iconLeft={IconPrinter}
            onClick={() => generateJobsExcel(row.original.barcode)}
          />
        )
      }
    },
  ], [
    onSubmitEdit,
    onSubmitDelete,
  ])

  const handleDelite = (barcode: string) => {
    const data = [barcode]
    deleteWorkspace({ data })
      .then((data) => {
        refetch()
      })
      .catch(() => {})
  }

  useEffect(() => {
    if (!data || !data.length) {
      return
    }
    const workspacesSet: IWorkspacesRender[] = data.map(
      (workspace: IWorkspacesRes) => {
        const Delete = (
           <EditRemoveButtons
            withEdit
            withRemove
            onEditClick={() => {
              setWorkspace(workspace)
              setOpenModal(true)
            }}
            onRemoveClick={() => {
              setWorkspace(workspace)
              setOpenConfirmModal(true)
            }}
          />
        )
        const Excel = (
          <Button
            onlyIcon
            view={'ghost'}
            size={'xs'}
            iconSize={'s'}
            iconLeft={IconPrinter}
            onClick={() => generateJobsExcel(workspace.barcode)}
          />
        )

        return {
          ...workspace,
          title: workspace.title,
          barcode: workspace.barcode,
        }
      },
    )
    setWorkspaces(workspacesSet)
    setFilteredWorkspaces(workspacesSet)
  }, [data])

  const generateJobsExcel = async (barcode: string) => {
    const response = {
      data: [barcode],
    }

    const data = await getJobsExcel(response)
    if (data) {
      saveBase64AsPdf({
        content: data.content,
        fileName: `${barcode}.pdf`,
      })
    }
  }

  return (
    <>
      <DirectoriesLayout
        withTable
        withCopy={false}
        withFilter
        withSearch
        withCreate
        createBtnNode={
          <Button
            label={'Добавить'}
            iconLeft={IconAdd}
            size={'s'}
            iconSize={'s'}
            onClick={() => {
              setWorkspace(null)
              setOpenModal(true)
            }}
          />
        }

        title={title}

        filterState={filterState}
        handleChangeFilter={handleChangeFilter}

        isLoading={isLoadingWorkspaces}
        columns={columns}
        rows={filteredWorkspaces}
      />
      {/*<div className={cx(styles.container, className)}>*/}
      {/*  <SectionTitle title={title} />*/}

      {/*  <div className={styles.searchRow}>*/}
      {/*    <InputWrapper*/}
      {/*      className={styles.searchInput}*/}
      {/*      leftSide={IconSearchStroked}*/}
      {/*      placeholder={'Введите наименование'}*/}
      {/*      value={search}*/}
      {/*      handleChange={(value) => handleSearch(value)}*/}
      {/*    />*/}
      {/*    */}
      {/*  </div>*/}

      {/*  <Card className={styles.card}>*/}
      {/*    <Table*/}
      {/*      className={styles.table}*/}
      {/*      columns={columns}*/}
      {/*      rows={filteredWorkspaces}*/}
      {/*      borderBetweenColumns*/}
      {/*      borderBetweenRows*/}
      {/*    />*/}
      {/*  </Card>*/}
        {openModal ? (
          <WorkspacesModal
            onClose={() => {
              setWorkspace(null)
              setOpenModal(false)
            }}
            isOpen={openModal}
            item={workspace}
            refetch={refetch}
          />
        ) : null}
        {openConfirmModal ? (
          <ConfirmActionModal
            isOpen={true}
            type="warning"
            withPreview={false}
            title={'Вы уверены, что хотите удалить рабочее место?'}
            size={'min'}
            confirmWithScan={false}
            onClose={() => {
              setWorkspace(null)
              setOpenConfirmModal(false)
            }}
            onSubmit={() => {
              if (!workspace) return
              handleDelite(workspace?.barcode)
              setOpenConfirmModal(false)
              setWorkspace(null)
            }}
          />
        ) : null}
    </>
  )
}
export default Workspaces
