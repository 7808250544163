import { Column, makeStateUpdater, RowData, Table, TableFeature } from '@tanstack/react-table'
import { CustomSortingOptions, CustomSortingTableState } from './types'
import { SortEnum } from '@shared/types/sort'


export const CustomSortingFeature: TableFeature<any> = {

  getInitialState: (state): CustomSortingTableState => {
    return {
      customSorting: {},
      ...state,
    }
  },

  getDefaultOptions: <TData extends RowData>(
    table: Table<TData>,
  ): CustomSortingOptions => {
    return {
      enableCustomSorting: true,
      onCustomSortingChange: makeStateUpdater('customSorting', table),
    } as CustomSortingOptions
  },

  createTable: <TData extends RowData>(table: Table<TData>): void => {
    table.setCustomSorting = updater => table.options.onCustomSortingChange?.(updater)
    table.toggleCustomSorting = () => table.setTableSettingsVisible(!table.getState()?.tableSettingsVisible)
  },
  createColumn: <TData extends RowData>(column: Column<TData>, table: Table<TData>): void => {
    column.getCanCustomSort = () => {
      return (
        (column.columnDef.enableSorting) &&
        (table.options.enableCustomSorting) &&
        !!column.accessorFn
      )
    }

    column.getIsCustomSorted = () => table.getState().customSorting?.[column.id] || false

    column.clearCustomSorting = () => {
      //clear sorting for just 1 column
      table.setCustomSorting((old) => {
        return old ? { ...old, [column.id]: undefined } : {}
      })
    }
    column.getToggleCustomSortingHandler = () => {
      const canSort = column.getCanCustomSort()
      return (e: unknown) => {
        if (!canSort) return
          ;
        (e as any).persist?.()
        column.toggleCustomSorting?.()
      }
    }
    column.setCustomSorting = (newSort: SortEnum) => {
      table.setCustomSorting(old => {
        return { ...old, [column.id]: newSort }
      })
    }
    column.toggleCustomSorting = () => {
      let newSort: SortEnum = SortEnum.DESC
      const sort = column.getIsCustomSorted()
      if (sort === SortEnum.DESC) {
        newSort = SortEnum.ASC
      }
      if (sort === SortEnum.ASC) {
        newSort = undefined
      }
      column.setCustomSorting(newSort)
    }
  },
}