import { Modal, TSDTableWrapper } from '../../../index'
import styles from './select-batch-modal.module.scss'
import React from 'react'
import { ICellSku } from '@/interfaces'

type stockData = { sku_id: string; sku_batch_id?: string; [key: string]: any }

interface ISelectBatchModal {
  onSubmit: (stock: stockData) => void
  onClose: () => void
  skus: ICellSku[]
  stockWithBatches: stockData[]
  isOpen: boolean
}

// todo устаревшая версия, удалить и там, где используется переделать в TSDSelectBatchModalV2
//  на данный момент не стал переделывать, так как есть проблема с пропсами, их надо аккуратно разрулить
const TSDSelectBatchModal = ({
  onSubmit,
  skus,
  stockWithBatches,
  onClose,
  isOpen,
}: ISelectBatchModal) => {
  return (
    <Modal
      withClose
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      onClose={onClose}
      title={'Выбор партии'}
      subtitle={''}
      className={styles.modal}
    >
      <TSDTableWrapper
        header={<></>}
        tableClassName={styles.tableWrapper}
        maxTableHeight={180}
      >
        {stockWithBatches.map((stock, i) => {
          const sku = skus.find((sku) => sku.id === stock.sku_id)
          const batch = sku?.batches?.find((b) => b.id === stock.sku_batch_id)
          const batchStr = `${batch?.exp_date || ''}`
          const batchNum = `${batch?.num || ''}`
          return (
            <div
              key={batch?.id || i}
              className={styles.batchItem}
              onClick={() => onSubmit(stock)}
            >
              Партия: {batchStr} {batchNum}
            </div>
          )
        })}
      </TSDTableWrapper>
    </Modal>
  )
}
export default TSDSelectBatchModal
