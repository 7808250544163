import React, { useCallback, useEffect, useState } from 'react'
import { ISelectOption } from '@/interfaces'
import { Button, InputWrapper } from '@/components'
import { Grid, GridItem } from '@consta/uikit/Grid'
import Address from '@pages/DirectoriesPage/components/Address'
import useGetCountriesList from '@/hooks/enums/useGetCountriesList'
import { IFormAddress, IRouteParams } from '@pages/DirectoriesPage/types'
import { Flex } from '@/newSrc/shared/ui/Flex'
import { Text } from '@/newSrc/shared/ui/Text'
import useCreateEditContractor from '../../../../../../../hooks/contractor/useCreateEditContractor'
import { useHistory, useParams } from 'react-router-dom'
import { useGetContractor } from '@/hooks'
import { useNotifications } from '@shared/providers/NotificationProvider'
import { generateDirectoriesPath } from '@shared/routing/config/paths'

type FormType = {
  title?: string
  inn?: string
  kpp?: string
  ogrn?: string
}

const mainInputs = [
  { id: 'title', label: 'Название', isRequired: true },
  { id: 'inn', label: 'ИНН', isInteger: true },
  { id: 'kpp', label: 'КПП', isInteger: true },
  { id: 'ogrn', label: 'ОГРН', isInteger: true },
]


export const ContractorForm = () => {
  const { page, section, ...params } = useParams<IRouteParams>()

  const history = useHistory()
  const notification = useNotifications()

  const [mainFormData, setMainFormData] = useState<FormType>({})
  const [legalAddress, setLegalAddress] = useState<IFormAddress>({})
  const [legalCountry, setLegalCountry] = useState<ISelectOption | null>(null)

  const [countriesList, setCountriesList] = useState<ISelectOption[]>([])

  const { refetch: getContractor, ...getContractorReq } = useGetContractor(
    {contractorId: params.id},
    {
      enabled: false,
      onSuccess: (data) => {
        const contractor = data?.contractor
        if (!data?.success || !contractor) return
        const legalAddress = contractor.legal_address
        setMainFormData({
          inn: contractor.inn,
          kpp: contractor.kpp,
          ogrn: contractor.ogrn,
          title: contractor.title
        })
        setLegalAddress({
          area: legalAddress?.area,
          block: legalAddress?.block,
          building: legalAddress?.building,
          city: legalAddress?.city,
          flat: legalAddress?.flat,
          floor: legalAddress?.floor,
          full_address: legalAddress?.full_address,
          house: legalAddress?.house,
          region: legalAddress?.region,
          settlement: legalAddress?.settlement,
          street: legalAddress?.street,
          zip: legalAddress?.zip,
        })
        const country = countriesList?.find(country => country.value === legalAddress?.country)
        setLegalCountry(country || null)
      }
  })
  useEffect(() => {
    if (params.id) {
      getContractor()
        .catch(console.log)
    }
  }, [params.id, countriesList])

  const { mutateAsync: createEditContractor, ...createEditContractorReq } = useCreateEditContractor()

  useGetCountriesList({
    onSuccess: (data) =>
      setCountriesList(data.values.map(country => ({
        label: country.title,
        value: country.value,
      }))),
  })

  const handleResetState = useCallback(() => {
    setMainFormData({})
    setLegalAddress({})
    setLegalCountry(null)
  }, [
    setMainFormData,
    setLegalAddress,
    setLegalCountry,
  ])

  const handleChangeState = useCallback(<T, >(key: string, value: T) => {
    setMainFormData(prev => ({ ...prev, [key]: value }))
  }, [setMainFormData])

  const handleChangeLegalCountry = useCallback((value: ISelectOption) => {
    setLegalCountry(value)
  }, [setLegalCountry])

  const handleChangeLegalAddress = useCallback((value: IFormAddress) => {
    setLegalAddress(prev => ({ ...prev, ...value }))
  }, [setLegalAddress])

  const handleSumit = useCallback((e) => {
    e.preventDefault()
    createEditContractor({
      data: {
        // external_id: ,
        inn: mainFormData?.inn,
        kpp: mainFormData?.kpp,
        ogrn: mainFormData?.ogrn,
        title: mainFormData?.title,
        legal_address: {
          area: legalAddress?.area,
          block: legalAddress?.block,
          building: legalAddress?.building,
          city: legalAddress?.city,
          country: legalCountry?.value ? `${legalCountry?.value}` : undefined,
          flat: legalAddress?.flat,
          floor: legalAddress?.floor ? Number(legalAddress.floor) : undefined,
          full_address: legalAddress?.full_address,
          house: legalAddress?.house,
          region: legalAddress?.region,
          settlement: legalAddress?.settlement,
          street: legalAddress?.street,
          zip: legalAddress?.zip,
        },
      },
      contractorId: params.id
    })
      .then((data) => {
        notification?.show('success', `Контрагент успешно ${params.id ? 'изменен' : 'создан'}`)
        if (data.id && !params.id) {
          history.push(generateDirectoriesPath({ page, section, type: 'item', id: data.id }))
        }
      })
      .catch(console.error)
  }, [legalAddress, mainFormData, legalCountry, params.id])

  const saveBtnDisabled = !mainFormData['title']
  return (
    <div>
      <form onSubmit={handleSumit}>
        <Grid cols={12} gap={'s'}>
          {
            mainInputs.map((input, i) => (
              <GridItem col={3} key={input.id}>
                <InputWrapper
                  id={input.id}
                  isInteger={input.isInteger}
                  isRequired={input.isRequired}
                  size={'s'}
                  label={input.label}
                  withDelay={false}
                  placeholder={`Введите ${input.label.toLowerCase()}`}
                  value={mainFormData?.[input.id] || ''}
                  handleChange={(val) => handleChangeState(input.id, val)}
                />
              </GridItem>
            ))
          }
          <GridItem col={12} />
          <GridItem col={12}>
            <Flex gap={'s'} direction={'column'}>
              <Text size={'m'} weight={'bold'} children={'Юридический адрес'} />
              <Flex gap={'s'} direction={'column'}>
                <Address
                  country={legalCountry}
                  address={legalAddress}
                  countriesList={countriesList}
                  onChangeCountry={handleChangeLegalCountry}
                  onChangeAddress={handleChangeLegalAddress}
                />
              </Flex>
            </Flex>
          </GridItem>
          <GridItem col={12}/>
          <GridItem col={12}/>
          <GridItem col={4}>
            <Flex gap={'m'} justifyContent={'start'}>
              {/*<Button*/}
              {/*  size={'s'}*/}
              {/*  label={'Сбросить'}*/}
              {/*  view={'ghost'}*/}
              {/*  onClick={handleResetState}*/}
              {/*/>*/}
              <Button
                size={'s'}
                label={'Сохранить'}
                type={'submit'}
                disabled={saveBtnDisabled}
                loading={getContractorReq?.isLoading || createEditContractorReq?.isLoading}
              />
            </Flex>
          </GridItem>
        </Grid>
      </form>
    </div>
  )
}
