import {
  CellInfoType,
  TemplateFieldType,
  TemplateTableColumn,
} from '@shared/types/tableGenerator/generatorTypes'
import {
  isCalendar,
  isCell, isContainer,
  isDocument,
  isInput,
  isMerchant,
  isSelect,
  isSku,
  isSkuBatch,
  isTable,
  isUrl,
  isWarehousePlace,
} from './fieldTypes'
import { ARTICLE_POSTFIX, COPY_VALUE_POSTFIX, IMAGE_POSTFIX, SKU_BARCODE_POSTFIX } from '@shared/helpers/consts'
import { ITableColumn } from 'src/interfaces'


/**
 * Обработка доп столбцов, например для SKU
 * Дополнительные столбцы требуются для копирования, так как с бека приходит 1 стоблец для номенклатуры,
 *  а склад хочет копировать разные данные например для номенклатуры (артикул и название)
 */

export const getCorrectColumnsData = (tableColumns: TemplateTableColumn[], editMode?: boolean): ITableColumn[] => {
  let resultExtraColumns: ITableColumn[] = []
  tableColumns.forEach((column, skuColumnIndex) => {
    if (column.type === TemplateFieldType.SKU) {
      const skuColumn = tableColumns[skuColumnIndex]
      if (skuColumnIndex >= 0 && (!editMode || skuColumn?.read_only)) {
        resultExtraColumns.push({
          key: `${skuColumn.id}${IMAGE_POSTFIX}`,
          initialColumnId: `${skuColumn.id}`, // id столбца для поиска нужного места
          copy_key: `${skuColumn.id}${IMAGE_POSTFIX}${COPY_VALUE_POSTFIX}`,
          title: column.title+' (Изображение)',
          title_txt: column.title+' (Изображение)',
          templateType: TemplateFieldType.IMAGE // описание для понимания какой столбец
        })
        resultExtraColumns.push({
          key: `${skuColumn.id}${ARTICLE_POSTFIX}`,
          initialColumnId: `${skuColumn.id}`, // id столбца для поиска нужного места
          copy_key: `${skuColumn.id}${ARTICLE_POSTFIX}${COPY_VALUE_POSTFIX}`,
          title: column.title+' (Артикул)',
          title_txt: column.title+' (Артикул)',
          templateType: TemplateFieldType.ARTICLE // описание для понимания какой столбец
        })
        resultExtraColumns.push({
          key: `${skuColumn.id}${SKU_BARCODE_POSTFIX}`,
          initialColumnId: `${skuColumn.id}`, // id столбца для поиска нужного места
          copy_key: `${skuColumn.id}${SKU_BARCODE_POSTFIX}${COPY_VALUE_POSTFIX}`,
          title: column.title+' (ШК)',
          title_txt: column.title+' (ШК)',
          templateType: TemplateFieldType.SKU_BARCODES // описание для понимания какой столбец
        })
      }
    }
  })
  return resultExtraColumns
}


/** Идея в том, чтобы по типу поля подставлять нужные значения для копирования */
export const getCellCopyValueByType = (cellInfo: CellInfoType, type: TemplateFieldType) => {
  const cell = cellInfo?.cell
  const value = cell?.value
  const dto = cell?.meta?.dto

  switch (true) {
    case isUrl(type):
    case isCalendar(type):
    case isDocument(type):
    case isInput(type):
      return value || ''
    case isSelect(type):
      const selectedOption = cellInfo.column?.values?.find(val => val.id === value)
      return selectedOption?.label || ''
    case isSkuBatch(type):
      return `${dto?.num || ''} ${dto?.exp_date || ''}`
    case isSku(type):
    case isCell(type):
    case isMerchant(type):
    case isWarehousePlace(type):
    case isContainer(type):
      return dto?.title || ''
    case isTable(type):
      return ''
    default:
      return '-'
  }
}