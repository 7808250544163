import React, { SVGProps } from 'react'
import { createIcon } from '@consta/icons/Icon'

const IconSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path fillRule='evenodd' clipRule='evenodd' d='M4 6.5L7 1.5L10 6.5H8V13.5H12V15.5H6V6.5H4Z' />
  </svg>
)

export const IconArrowIn = createIcon({
  l: IconSizeM,
  m: IconSizeM,
  s: IconSizeM,
  xs: IconSizeM,
  name: 'IconArrowIn',
})
