import { ISideTab } from './types'

import WarehouseSection from './pages/WarehouseSection'
import Workspaces from './pages/Workspaces'
import ZonesSection from './pages/ZonesSection'
import UserCompanies from '@pages/DirectoriesPage/pages/UserCompanies'
import UsersSection from './pages/UsersSection'
import GatesSection from './pages/GatesSection'

import Nomenclature from './pages/Nomenclature'
import RoleSection from './pages/RoleSection'
import Merchants from './pages/Merchants'
import DeliveryContracts from './pages/DeliveryContracts'
import Instructions from './pages/Instructions'
import PaymentMethods from './pages/PaymentMethods'
import DocumentStatuses from './pages/DocumentStatuses'
import Categories from './pages/Categories'
import Vehicles from './pages/Vehicles'
import CarriersCompany from './pages/CarriersCompany'
import Carriers from './pages/Carriers'
import SerialNumbers from './pages/SerialNumbers'
import Contractors from './pages/Contractors'
import PackagingsSettings from './pages/PackagingsSettings'


export const DIRECTORIES_PAGES_SETTINGS = {
  warehouse: { label: 'Склад', value: 'warehouse', Component: WarehouseSection },
  users: { label: 'Пользователи', value: 'users', Component: UsersSection },
  gates: { label: 'Ворота', value: 'gates', Component: GatesSection },
  workspaces: { label: 'Рабочие места', value: 'workspaces', Component: Workspaces },
  zones: { label: 'Зоны', value: 'zones', Component: ZonesSection},
  packagingsSettings: { label: 'Упаковочные материалы', value: 'packagingsSettings', Component: PackagingsSettings },
}
export const DIRECTORIES_PAGES_GUIDES = {
  nomenclature: { label: 'Номенклатура', value: 'nomenclature', Component: Nomenclature },
  roles: { label: 'Роли', value: 'roles', Component: RoleSection },
  merchants: { label: 'Организации', value: 'merchants', Component: Merchants },
  delivery: { label: 'Договоры доставки', value: 'delivery', Component: DeliveryContracts },
  instructions: { label: 'Инструкции', value: 'instructions', Component: Instructions },
  paymentMethods: { label: 'Способы оплаты', value: 'paymentMethods', Component: PaymentMethods },
  documentStatuses: { label: 'Статусы документа', value: 'documentStatuses', Component: DocumentStatuses },
  categories: { label: 'Категории', value: 'categories', Component: Categories },
  vehicles: { label: 'Транспортные средства', value: 'vehicles', Component: Vehicles },
  carriersCompany: { label: 'Компании перевозчиков', value: 'carriersCompany', Component: CarriersCompany },
  usersCompanies: { label: 'Компании пользователей', value: 'usersCompanies', Component: UserCompanies },
  carriers: { label: 'Перевозчики  (ИП, ТК)', value: 'carriers', Component: Carriers },
  serialNumbers: { label: 'Уникальные номера', value: 'serialNumbers', Component: SerialNumbers },
  contractors: { label: 'Контрагенты', value: 'contractors', Component: Contractors },
}

export const SECTIONS: Record<string, ISideTab> = { ...DIRECTORIES_PAGES_SETTINGS, ...DIRECTORIES_PAGES_GUIDES }

export const tabs = {
  settings: {
    label: 'Настройки',
    value: 'settings',
    sideTabs: DIRECTORIES_PAGES_SETTINGS,
  },
  guides: {
    label: 'Справочники',
    value: 'guides',
    sideTabs: DIRECTORIES_PAGES_GUIDES,
  },
}
