export function checkSameElements(arr: string[]) {
  if (arr.length === 0) {
    return true
  }

  const firstElement = arr[0]

  for (let i = 1; i < arr.length; i++) {
    if (arr[i] !== firstElement) {
      return false
    }
  }

  return true
}
