import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import InputMask from 'react-input-mask'

import styles from './input-wrapper.module.scss'

import { TextField, TextFieldPropValue } from '@consta/uikit/TextField'
import { IconClose } from '@consta/icons/IconClose'
import { useNotifications } from '@shared/providers/NotificationProvider'
import { TextFieldPropName, TextFieldPropSize } from '@consta/uikit/__internal__/src/components/TextField/TextField'
import { AutoCompete } from '@consta/uikit/__internal__/src/utils/types/AutoComplete'
import { IconComponent } from '@consta/icons/Icon'
import { IconOpenInNew } from '@consta/icons/IconOpenInNew'
import { Text } from '@shared/ui/Text'
import { Flex } from '@shared/ui/Flex'
import { Button } from '@shared/ui/btns/Button'


export type FormType =
  | 'default'
  | 'defaultClear'
  | 'defaultBrick'
  | 'brick'
  | 'brickDefault'
  | 'brickClear'
  | 'brickRound'
  | 'round'
  | 'roundClear'
  | 'roundBrick'
  | 'clearRound'
  | 'clearDefault'
  | 'clearBrick'
  | 'clearClear'

export interface InputWrapperProps {
  id?: string
  link?: string
  label?: string
  type?: string
  placeholder?: string
  size?: TextFieldPropSize
  disabled?: boolean
  maxLength?: number
  onFocus?: React.FocusEventHandler<HTMLElement>
  onBlur?: React.FocusEventHandler<HTMLElement>
  onEnter?: () => void
  error?: string
  value?: string | null
  handleChange?: (value: TextFieldPropValue, type: string) => void
  readOnly?: boolean
  autoFocus?: boolean
  withDelay?: boolean
  delay?: number
  withDelayAndManual?: boolean
  className?: any
  rightSide?: string | IconComponent
  leftSide?: string | IconComponent
  clearable?: boolean
  onClear?: () => void
  rows?: number
  minRows?: number
  maxRows?: number
  inputRef?: React.Ref<HTMLTextAreaElement | HTMLInputElement>
  mask?: string
  isInteger?: boolean
  isNumber?: boolean
  isRequired?: boolean
  isWeight?: boolean
  isDimensions?: boolean
  form?: FormType
  // autoComplete?: TextFieldPropAutoComplete
  autoComplete?: AutoCompete
  name?: TextFieldPropName
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
}

type states = {
  alert: 'alert'
  success: 'success'
  warning: 'warning'
}

const InputWrapper = (props: InputWrapperProps) => {
  const {
    type = 'text',
    className,
    id,
    label,
    error,
    value,
    handleChange = () => false,
    onEnter,
    readOnly = false,
    autoFocus = false,
    withDelay,
    delay = 200,
    withDelayAndManual,
    placeholder,
    rightSide,
    size,
    leftSide,
    clearable = false,
    onClear = () => false,
    inputRef,
    mask,
    isInteger = false,
    isNumber = false,
    isRequired = false,
    isDimensions = false, // при вводе габариты
    isWeight = false, // при вводе веса
    disabled,
    onBlur,
    form,
    name,
    autoComplete,
    inputMode,
    rows,
    minRows,
    maxRows,
    onFocus = () => false,
    link,
  } = props

  const notification = useNotifications()

  const [started, setStarted] = useState(false)
  const [localValue, setLocalValue] = useState<string>('')
  const prevValue = useRef<string | null | undefined>('')
  const timer = useRef<number | null>(null)
  const curValue = useRef<string | null | undefined>('')

  useEffect(() => {
    if (started) {
      if (isInteger && localValue && !checkIsInteger(localValue)) {
        setLocalValue('')
        return
      }
      if (withDelayAndManual) {
        checkWithDelayAndManualTimer(localValue)
      }
      if (withDelay) {
        if (window.timeoutId) {
          window.clearTimeout(window.timeoutId)
        }
        window.timeoutId = window.setTimeout(() => {
          if (localValue) {
            handleChange(localValue, 'withDelay')
            setLocalValue('')
          }
        }, delay)
      }
    } else {
      setStarted(true)
    }
    return () => {
      if (window.timeoutId) {
        window.clearTimeout(window.timeoutId)
      }
    }
  }, [localValue])

  const toggleCapsLockNotif = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.getModifierState('CapsLock')) {
      notification?.showCapsLockNotification()
    } else {
      if (typeof notification?.state?.capsIsOn !== 'undefined') {
        notification?.closeCapsLockNotification()
      }
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    toggleCapsLockNotif(e)
    if (value && onEnter) {
      if (e.key === 'Enter') {
        onEnter()
      }
    }
  }

  const checkWithDelayAndManualTimer = (localValue: string) => {
    curValue.current = localValue
    handleChange(localValue, 'withManual')
    if (window.timeoutId !== timer.current) {
      prevValue.current = localValue
      handleChange(localValue, 'withManual')
      window.timeoutId = window.setTimeout(() => {
        const prev = prevValue.current
        prevValue.current = curValue.current
        const cur = curValue.current ?? ''
        if (prev ? cur.length - prev.length > 4 : cur.length > 4) {
          handleChange(cur, 'withDelay')
          setLocalValue('')
        } else {
          handleChange(cur, 'withManual')
        }
      }, 300)
      timer.current = window.timeoutId
      return
    }
    handleChange(localValue, 'withManual')
  }

  const checkIsInteger = (value: TextFieldPropValue) => {
    if (value) {
      return /^[0-9]+$/.test(value)
    }
    return true
  }

  const checkIsNumber = (value: TextFieldPropValue) => {
    if (value) {
      return /^([+-]?(\d+([.]\d*)?|[.]\d)|([+-])+)$/.test(value)
    }
    return true
  }

  const checkIsDimensions = (value: TextFieldPropValue) => {
    if (value) {
      return (/^(\d+\.?(\d{1,1})?)$/).test(value)
    }
    return true
  }
  const checkIsWeight = (value: TextFieldPropValue) => {
    if (value) {
      return (/^(\d+\.?(\d{1,3})?)$/).test(value)
    }
    return true
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    toggleCapsLockNotif(e)
  }

  const getState = (): keyof states | undefined => {
    if (error) {
      return 'alert'
    }
    return undefined
  }

  const handleChangeInput = (value: TextFieldPropValue, type: string) => {
    let corrValue = value
    if (isDimensions || isWeight || isNumber) {
      corrValue = (value || '').replaceAll(/ю|б|&|,|\^$/g, '.')
    }
    if (isInteger && !checkIsInteger(corrValue)) {
      return
    }
    if (isNumber && !checkIsNumber(corrValue)) {
      return
    }
    if (isWeight && !checkIsWeight(corrValue)) {
      return
    }
    if (isDimensions && !checkIsDimensions(corrValue)) {
      return
    }
    handleChange(corrValue, type)
  }

  const primaryHandleChange = (value: TextFieldPropValue) => {
    if (!withDelay && !withDelayAndManual) {
      if (!mask) {
        handleChangeInput(value ?? '', 'noDelay')
      } else {
        handleChange(value !== null ? value : '', 'noDelay')
      }
    } else {
      if (!mask) {
        setLocalValue(String(value ?? ''))
      } else {
        setLocalValue(String(value !== null ? value : ''))
      }
    }
  }
  const hasLink = Boolean(value && link && (readOnly || disabled))
  const getRightSide = () => (
    clearable ? () => (
        <Flex
          gap={'s'}
          align={'center'}
          children={
            <>
              {typeof rightSide === 'string' ? rightSide : rightSide?.({})}
              <Button
                onlyIcon
                view={'clear'}
                iconLeft={IconClose}
                size={'xs'}
                onClick={onClear}
              />
            </>
          } />
      )
      : rightSide
  )
  return (
    <div
      className={cx(
        styles.input,
        { [styles.cursorPointer]: hasLink },
        className,
      )}
      onClick={() => {
        if (hasLink) {
          window.open(link, '_blank')
        }
      }}
    >
      {label &&
        <Text as={'label'} size={size} className={cx({ [styles.requiredLabel]: isRequired })}>
          {label} {hasLink ? <IconOpenInNew size={size} view={'link'} /> : null}
        </Text>
      }
      <div>
        {
          !mask ? (
            <TextField
              required={isRequired}
              readOnly={readOnly}
              type={type}
              id={id}
              state={getState()}
              status={error ? 'alert' : undefined}
              value={!withDelay ? value : localValue}
              onChange={primaryHandleChange}
              onKeyDown={handleKeyDown}
              onKeyUp={handleKeyUp}
              autoFocus={autoFocus}
              placeholder={placeholder}
              rightSide={getRightSide()}
              size={size}
              leftSide={leftSide}
              inputRef={inputRef as React.Ref<HTMLInputElement>}
              disabled={disabled}
              onBlur={onBlur}
              form={form}
              autoComplete={autoComplete}
              name={name}
              inputMode={inputMode}
              // @ts-ignore
              rows={rows}
              // @ts-ignore
              minRows={minRows}
              // @ts-ignore
              maxRows={maxRows}
              onFocus={onFocus}
            />
          ) : (
            // @ts-ignore
            <InputMask
              mask={mask}
              readOnly={readOnly}
              type={type}
              id={id}
              state={getState()}
              value={!withDelay ? value : localValue}
              // @ts-ignore
              onChange={primaryHandleChange}
              onKeyDown={(e) => handleKeyDown(e)}
              onKeyUp={(e) => handleKeyUp(e)}
              autoFocus={autoFocus}
              placeholder={placeholder}
              rightSide={getRightSide()}
              size={size}
              leftSide={leftSide}
              inputRef={inputRef as React.Ref<HTMLTextAreaElement>}
              form={form}
              inputMode={inputMode}
            >
              {
                ({ isRequired, ...inputProps }) => <TextField {...inputProps} required={isRequired} />
              }
            </InputMask>
          )
        }
      </div>
      {error && <Text size={'s'} view={'alert'} children={error} />}
    </div>
  )
}

export default InputWrapper
