import { BadgePropStatus } from '@consta/uikit/Badge'
import { BatchStatusesEnum, palletStatuses } from '@/interfaces'


export const PALLET_STATUSES_DESCRIPTION: Record<BatchStatusesEnum | string, string> = {
  [palletStatuses.new]: 'Новый',
  [palletStatuses.packing]: 'На упаковке',
  [palletStatuses.packed]: 'Упакован',
}

export const PALLET_STATUS_BADGE_TYPE: Record<BatchStatusesEnum | string, BadgePropStatus> = {
  [palletStatuses.new]: 'system',
  [palletStatuses.packing]: 'normal',
  [palletStatuses.packed]: 'success',
}


export const PALLET_STATUSES: {
  value: palletStatuses,
  label: string,
  style: BadgePropStatus
}[] = Object.keys(PALLET_STATUSES_DESCRIPTION).map(key => ({
  value: key as palletStatuses,
  label: PALLET_STATUSES_DESCRIPTION[key as keyof object],
  style: PALLET_STATUS_BADGE_TYPE[key as keyof object]
}))
