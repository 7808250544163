import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import type { IFormAddress, IFormLegalEntity, ISection } from '../../types'
import type { ICountry, IUser, IWHData } from './types'

import { ISelectOption } from '@/interfaces'
import { useGetWarehouseSettings, useUpdateWarehouse } from '@/hooks'

import { getTrimmed } from '../../utils'

import { Button } from '@shared/ui/btns/Button'

import { InputWrapper, Select } from '@/components'

import Card from '../../components/Card/Card'
import InfoBlock from '../../components/InfoBlock/InfoBlock'
import Address from '../../components/Address/Address'
import SectionTitle from '../../components/SectionTitle/SectionTitle'

import { companyData } from './constants'

import cx from 'classnames'
import styles from './warehouse-section.module.scss'
import { useNotifications } from '@shared/providers/NotificationProvider'
import { Flex } from '@shared/ui/Flex'

/**
 * Страница настройки склада
 */
const WarehouseSection: FC<ISection> = ({
                                          className = '',
                                          title = '',
                                        }) => {
  const [warehouseData, setWarehouseData] = useState<IWHData>({})
  const [address, setAddress] = useState<IFormAddress>({})
  const [legalAddress, setLegalAddress] = useState<IFormAddress>({})
  const [legalInfo, setLegalInfo] = useState<IFormLegalEntity>({})

  const [countriesList, setCountriesList] = useState<ISelectOption[]>([])
  const [country, setCountry] = useState<ISelectOption | null>(null)
  const [legalCountry, setLegalCountry] = useState<ISelectOption | null>(null)

  const [usersList, setUsers] = useState<ISelectOption[]>([])
  const [approvedUser, setApprovedUser] = useState<ISelectOption | null>(null)

  const { mutateAsync, isLoading } = useUpdateWarehouse()

  const notification = useNotifications()

  const { data } = useGetWarehouseSettings()

  const handleChangeWHData = (newData: IWHData) =>
    setWarehouseData((prevState) => ({
      ...prevState,
      ...newData,
    }))

  const onChangeAddress = (newData: IFormAddress) =>
    setAddress((prevState) => ({
      ...prevState,
      ...newData,
    }))

  const onChangeLegalAddress = (newData: IFormAddress) =>
    setLegalAddress((prevState) => ({
      ...prevState,
      ...newData,
    }))

  const handleChangeLegalInfo = (newData: IFormLegalEntity) =>
    setLegalInfo((prevState) => ({
      ...prevState,
      ...newData,
    }))

  const handleChangeEmployee = (option: ISelectOption | null) => {
    if (!option) {
      return
    }

    handleChangeWHData({
      responsible_for_document_approval_user_id: option.value,
    })

    setApprovedUser(option)
  }

  const onChangeCountry = (option: ISelectOption) => {
    onChangeAddress({ country: option.value.toString() })
    setCountry(option)
  }

  const onChangeLegalCountry = (option: ISelectOption) => {
    onChangeLegalAddress({ country: option.value.toString() })
    setLegalCountry(option)
  }

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault()

    const warehouseAddress = { ...address }
    const legal_address = { ...legalAddress }
    const legal_entity = {
      ...legalInfo,
      legal_address,
    }

    const postData = {
      ...warehouseData,
      address: warehouseAddress,
      legal_entity,
    }

    mutateAsync(postData).then((data) => {
      notification?.show('success', 'Форма успешно сохранена')
    })
  }

  const createUsersList = (usersList: []) => {
    return usersList.map((user: IUser) => ({
      label: user.name,
      value: user.id,
    }))
  }

  const createCountriesList = (countriesList: []) => {
    return countriesList.map((country: ICountry) => ({
      label: country.title,
      value: country.value,
    }))
  }

  useEffect(() => {
    if (!data || !data?.length) {
      return
    }
    let warehouse, usersData, countryData
    try {
      warehouse = data?.[0]?.warehouse
      usersData = data[1]
      countryData = data[2]
    } catch (e) {
      console.error(e)
    }

    // set address
    setWarehouseData(warehouse)
    setAddress(warehouse?.address || {})
    setLegalInfo(warehouse?.legal_entity || {})
    setLegalAddress(warehouse?.legal_entity?.legal_address || {})

    // set users
    const users = usersData?.users || []

    setUsers(createUsersList(users))

    const approvedUserId = warehouse?.responsible_for_document_approval_user_id

    if (approvedUserId) {
      const approvedUser = users.find(
        (user: IUser) => approvedUserId === user.id,
      )

      if (approvedUser) {
        setApprovedUser({
          label: approvedUser.name,
          value: approvedUser.id,
        })
      }
    } else {
      setApprovedUser(null)
    }

    // set country
    const countryId = warehouse?.address?.country
    const legalCountryId = warehouse?.legal_entity?.legal_address?.country

    const countriesList = createCountriesList(countryData?.values || [])

    setCountriesList(countriesList)

    const currentCountry = countriesList.find(
      (country) => countryId === country.value,
    )

    setCountry(currentCountry || null)

    const currentLegalCountry = countriesList.find(
      (country) => legalCountryId === country.value,
    )

    setLegalCountry(currentLegalCountry || null)
  }, [data])

  return (
    <div className={cx(styles.container, className)}>
      <form onSubmit={handleSubmit}>
        <Flex direction={'column'} gap={'s'}>
          <SectionTitle title={title}>
            <Button label='Сохранить' size={'s'} loading={isLoading} type='submit' />
          </SectionTitle>

          <Card className={styles.card}>
            <div className={styles.wrapper}>
              <InfoBlock title={'Данные организации'}>
                <div className={styles.row}>
                  <InputWrapper
                    size={'s'}
                    isRequired
                    label='Наименование организации'
                    value={legalInfo?.title || ''}
                    handleChange={(val) =>
                      handleChangeLegalInfo({
                        title: getTrimmed(val),
                      })
                    }
                  />

                  <InputWrapper
                    size={'s'}
                    isRequired
                    label='Телефон'
                    type='tel'
                    value={warehouseData?.phone}
                    handleChange={(val) =>
                      handleChangeWHData({
                        phone: getTrimmed(val),
                      })
                    }
                  />

                  <Select
                    size={'s'}
                    label='Материально-ответственное лицо'
                    placeholder='Выберите сотрудника'
                    options={usersList}
                    value={approvedUser}
                    onChange={(value) => handleChangeEmployee(value)}
                  />
                </div>

                <div className={styles.row}>
                  {companyData.map((item) => (
                    <InputWrapper
                      size={'s'}
                      key={item.key}
                      isRequired={!!item.isRequired}
                      label={item.label}
                      value={legalInfo[item.key] || ''}
                      handleChange={(val) =>
                        handleChangeLegalInfo({ [item.key]: getTrimmed(val) })
                      }
                    />
                  ))}
                </div>
              </InfoBlock>

              <InfoBlock title={'Фактический адрес'} isRequired>
                <Address
                  country={country}
                  address={address}
                  countriesList={countriesList}
                  onChangeCountry={onChangeCountry}
                  onChangeAddress={onChangeAddress}
                />
              </InfoBlock>

              <InfoBlock title={'Юридический адрес'} isRequired>
                <Address
                  country={legalCountry}
                  address={legalAddress}
                  countriesList={countriesList}
                  onChangeCountry={onChangeLegalCountry}
                  onChangeAddress={onChangeLegalAddress}
                />
              </InfoBlock>

              <InfoBlock title={'Полный адрес'}>
                <InputWrapper
                  size={'s'}
                  placeholder='Введите полный адрес, если текущий не подходит для отображения в документах'
                  value={address?.full_address || ''}
                  handleChange={(val) =>
                    onChangeAddress({ full_address: getTrimmed(val) })
                  }
                />
              </InfoBlock>
            </div>
          </Card>
        </Flex>
      </form>
    </div>
  )
}

WarehouseSection.displayName = 'WarehouseSection'

export default WarehouseSection
