import React from 'react'
import { Text } from '@shared/ui/Text'

interface IVerifiedInfoProps {
  value?: string
}
const JoinCodeInfo = (props: IVerifiedInfoProps) => {
  const { value } = props
  if (!value) return null
  return (
    <Text as={'p'}>
      Код входа: <b>{value}</b>
    </Text>
  )
}
export default JoinCodeInfo
