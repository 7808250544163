import { ISelectOrderOption } from '@/interfaces'
import { orderItemsEnum, orderSubtypeEnum } from './types'

export const SELECT_SKUS_ITEMS: ISelectOrderOption[] = [
  {
    label: 'Номенклатура - ШК',
    value: 'nameBarcode',
    type: orderItemsEnum.nomenclature,
    subtype: orderSubtypeEnum.barcodes
  },
  {
    label: 'Номенклатура - ID',
    value: 'nameId',
    type: orderItemsEnum.nomenclature,
    subtype: orderSubtypeEnum.ids
  },
  {
    label: 'Номенклатура - наименование',
    value: 'nameTitle',
    type: orderItemsEnum.nomenclature,
    subtype: orderSubtypeEnum.titles
  },
]
export const SELECT_CELLS_ITEMS: ISelectOrderOption[] = [
  {
    label: 'Ячейка - наименование',
    value: 'cellTitle',
    type: orderItemsEnum.cell,
    subtype: orderSubtypeEnum.titles
  },
  {
    label: 'Ячейка - ШК',
    value: 'cellBarcode',
    type: orderItemsEnum.cell,
    subtype: orderSubtypeEnum.barcodes
  },
]
export const SELECT_ORDERS_ITEMS: ISelectOrderOption[] = [
  {
    label: 'По номеру ЗК',
    value: 'orderOrders',
    type: orderItemsEnum.order,
    subtype: orderSubtypeEnum.ids
  },
  {
    label: 'По ШК Груз места',
    value: 'orderBarcodes',
    type: orderItemsEnum.order,
    subtype: orderSubtypeEnum.place_barcodes
  },
  {
    label: 'По номеру КС (трек-номер)',
    value: 'orderServices',
    type: orderItemsEnum.order,
    subtype: orderSubtypeEnum.tracking_nums
  },
  {
    label: 'По Номеру клиента (Номер заказа без префикса)',
    value: 'orderExternalIds',
    type: orderItemsEnum.order,
    subtype: orderSubtypeEnum.external_ids
  },
]