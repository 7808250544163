import React, { useRef, useState } from 'react'
import styles from './ButonsForPrint.module.scss'
import { Button, PrintPreviewCheckbox } from '@/components'
import { IconPrinter } from '@shared/assets/icons/IconPrinter'
import { PrintTypeEnum } from '@/interfaces'
import { IconLock } from '@consta/icons/IconLock'
import { IconTrash } from '@consta/icons/IconTrash'
import { ContextMenu } from '@consta/uikit/ContextMenu'
import { useToggle } from '@shared/hooks'


interface IPropsForPrint {
  handlePrintStickers: (
    zoneId: string,
    printType: string,
    cellId?: string,
  ) => void
  handleBlockCells: (zoneId: string, actionType: 'block' | 'unblock') => void
  handleDeleteCells: (zoneId: string) => void
  zoneId: string
  isLoadingStickers: boolean
}

const ButtonsForPrint = (props: IPropsForPrint) => {
  const {
    handlePrintStickers,
    handleBlockCells,
    handleDeleteCells,
    zoneId,
    isLoadingStickers,
  } = props

  const menuRef = useRef(null)
  const {
    value: menuVisible,
    setValue: setMenuVisible
  } = useToggle()
  const [printType, setPrintType] = useState<PrintTypeEnum>(PrintTypeEnum.print)

  return (
    <div className={styles.actionButtons}>
      <PrintPreviewCheckbox
        onChange={setPrintType}
        className={styles.checkboxPrintType}
      />
      <Button
        size={'s'}
        view={'secondary'}
        label="Напечатать стикеры"
        loading={isLoadingStickers}
        iconLeft={IconPrinter}
        iconSize={'m'}
        onClick={(e) => {
          e.stopPropagation()
          handlePrintStickers(zoneId, '', printType)
        }}
      />
      <div>
        <Button
          size={'s'}
          view={'secondary'}
          label="Блокировка"
          iconLeft={IconLock}
          onClick={(e) => {
            e.stopPropagation()
            setMenuVisible(prev => !prev)
            // handleBlockCells(zoneId)
          }}
          ref={menuRef}
        />
        <ContextMenu
          direction={'downCenter'}
          items={[
            {id: 'block', label: 'Заблокировать' },
            {id: 'unblock', label: 'Разблокировать' },
          ]}
          className={styles.contextSearch}
          isOpen={menuVisible}
          size="m"
          onClickOutside={() => setMenuVisible(false)}
          onItemClick={(item, { e }) => {
            e.stopPropagation()
            handleBlockCells(zoneId, item.id as 'block' | 'unblock')
            setMenuVisible(false)
          }}
          anchorRef={menuRef}
        />
      </div>

      <Button
        size={'s'}
        view={'ghost'}
        label="Удалить"
        iconLeft={IconTrash}
        onClick={(e) => {
          e.stopPropagation()
          handleDeleteCells(zoneId)
        }}
      />
    </div>
  )
}
export default ButtonsForPrint
