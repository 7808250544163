import { TableColumnType } from '@/components/Table/TanStackTable'
import { Button } from '@shared/ui/btns/Button'
import { IconRemove } from '@consta/icons/IconRemove'
import { IconAdd } from '@consta/icons/IconAdd'
import React from 'react'
import { COLUMN_EXPANDER_ID, COLUMN_NUMERIC_ID, COLUMN_SELECTION_ID } from '@/components/Table/TanStackTable/lib/const'
import { IndeterminateCheckbox } from '../components/IndeterminateCheckbox/IndeterminateCheckbox'
import { getRowIdForNumeric } from '@/components/Table/TanStackTable/lib/helpers'

const getExpandColumn = <TRowData, TRowValue>(tableHasSubItems: boolean): TableColumnType<TRowData, TRowValue> => {
  return {
    id: COLUMN_EXPANDER_ID,
    accessorKey: COLUMN_EXPANDER_ID,
    minSize: 50,
    size: 150,
    meta: {
      headerText: '',
      exclude: !tableHasSubItems,
      canCopy: false
    },
    enableSorting: false,
    enableHiding: false,
    header: ({ table }) => {
      if (!table.getCanSomeRowsExpand()) return null
      return (
        <Button
          onlyIcon
          view={'clear'}
          size={'xs'}
          iconSize={'s'}
          iconLeft={table.getIsAllRowsExpanded() ? IconRemove : IconAdd}
          onClick={() => table.toggleAllRowsExpanded()}
        />
      )
    },
    cell: ({ row }) => {
      if (!row.getCanExpand()) return null
      return (
        <Button
          onlyIcon
          view={'clear'}
          size={'xs'}
          iconSize={'s'}
          iconLeft={row.getIsExpanded() ? IconRemove : IconAdd}
          style={{
            /* Отступ для вложеностей */
            marginLeft: `${row.depth / 1.5}rem`,
          }}
        />
      )
    },
  }
}

const getRowSelectColumn = <TRowData, TRowValue>(withRowSelection: boolean): TableColumnType<TRowData, TRowValue> => {
  return {
    id: COLUMN_SELECTION_ID,
    accessorKey: COLUMN_SELECTION_ID,
    minSize: 40,
    maxSize: 40,
    meta: {
      headerText: '',
      exclude: !withRowSelection,
      canCopy: false
    },
    enableResizing: false,
    enableSorting: false,
    enableHiding: false,
    header: ({ table }) => (
      <IndeterminateCheckbox
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) => (
      <IndeterminateCheckbox
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        indeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    )
  }
}

const getNumerableColumn = <TRowData, TRowValue>(withNumerableRows: boolean, currentPage: number): TableColumnType<TRowData, TRowValue> => {
  return {
    id: COLUMN_NUMERIC_ID,
    accessorKey: COLUMN_NUMERIC_ID,
    minSize: 40,
    size: 60,
    header: '№',
    meta: {
      headerText: '№',
      exclude: !withNumerableRows,
    },
    enableResizing: true,
    enableSorting: false,
    enableHiding: false,
    cell: ({ row }) => getRowIdForNumeric(row.id, currentPage),
  }
}

type ArgsType<TRowData, TRowValue> = {
  columns: TableColumnType<TRowData, TRowValue>[]
  tableHasSubItems: boolean
  withRowSelection: boolean
  withNumerableRows: boolean
  currentPage?: number
}

/* Добавляем все стандартные столбцы (например столбец для вложенностей или чекбоксов и т.п.) */
export const generateExtraColumns = <TRowData, TRowValue>(args: ArgsType<TRowData, TRowValue>): TableColumnType<TRowData, TRowValue>[] => {
  const {
    withNumerableRows,
    withRowSelection,
    columns,
    tableHasSubItems,
    currentPage,
  } = args


  return [
    getRowSelectColumn<TRowData, TRowValue>(withRowSelection),
    getExpandColumn<TRowData, TRowValue>(tableHasSubItems),
    getNumerableColumn<TRowData, TRowValue>(withNumerableRows, currentPage),
    ...columns,
  ].filter(col => !col.meta?.exclude)
}