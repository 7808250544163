import React, { MouseEvent } from 'react'
import styles from './sidebar-nav.module.scss'
import { Tabs } from '@consta/uikit/Tabs'
import { ITab } from 'src/interfaces'


interface ISidebarNav {
  selectedTab: ITab | null
  items: ITab[]
  onClickTab?: (e: MouseEvent<HTMLDivElement>) => void
  onChangeTab?: (value: ITab) => void
}

const SidebarNav = (props: ISidebarNav) => {
  const {
    items,
    selectedTab,
    onClickTab,
    onChangeTab
  } = props

  return (
    <div className={styles.main}>
      <div className={styles.navList}>
        <Tabs
          value={selectedTab}
          onClick={(e) => onClickTab?.(e)}
          onChange={(value) => onChangeTab?.(value)}
          items={items}
          getItemLabel={(tab) => tab.label}
          view="clear"
          linePosition="right"
          className={styles.tabs}
        />
      </div>
    </div>
  )
}

export default SidebarNav
