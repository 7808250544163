import React, { JSX } from 'react'

import styles from './GeneratorFields.module.scss'
import cx from 'classnames'
import {
  FieldRow,
  FieldType,
  GenerateFieldType,
  isBase64,
  isBool,
  isCalendar,
  isCell,
  isCombineCells,
  isCombobox,
  isContractors,
  isDocument,
  isGroup,
  isInput,
  isJson,
  isMerchants,
  isSku,
  isSkuBatch,
  isTable,
  isUrl,
  isWarehouseContainer,
  isWarehousePlace,
} from './types'
import { CalendarInput, ComboboxWrapper, InputWrapper } from '@/components' // todo переделать на shared
import { GeneratorValuesPreview } from '@widgets/GeneratorValuesPreview'
import { convertValuesToView } from './utils'
import { ComboboxMerchants } from '@entities/Merchant'
import { ComboboxCells } from '@entities/Cell'
import { ComboboxSkuBatch, ComboboxSkus } from '@entities/Sku'
import { GroupField } from '@shared/ui/fields/GroupField/GroupFields'
import { ComboboxItemDefault } from '@consta/uikit/Combobox'
import { TableField } from '@shared/ui/fields/TableField/TableField'
import { JsonField } from '@shared/ui/fields/JsonField/JsonField'
import { ComboboxDocumentsSearchApi } from '@entities/Document'
import { ComboboxContractors } from '@entities/Contractor'
import { ComboboxWarehousePlace } from '@entities/WarehousePlace'
import { ComboboxWarehouseContainer } from '@entities/WarehouseContainer'
import { boolSelectItems } from '@widgets/GeneratorFields/commonData'
import CombineCellsComponent from '@shared/ui/fields/CombineCellsComponent/CombineCellsComponent'
import { Flex } from '@shared/ui/Flex'
import { EXCLUDE_FIELD_PREFIX } from '@shared/helpers'
import { FileField } from '@shared/ui/fields/FileField/FileField'
import { TextFieldPropSize } from '@consta/uikit/__internal__/src/components/TextField/TextField'
import { GridPropGap } from '@consta/uikit/Grid'


type ComboboxFieldType = {
  Component: (props: any) => JSX.Element
  extraProps?: Record<string, any>
  conditionFn: (data: GenerateFieldType) => boolean
}

type valueType = any
export type GeneratorChangeType = {
  fieldId: string
  value?: valueType
  exclude?: boolean
  filedType?: FieldType
}
export type onChangeType = { onChange: (data: GeneratorChangeType) => void }
export type GeneratorStateType = Record<string, valueType>

type GeneratorFieldDataType = GenerateFieldType & {
  valuesState: GeneratorStateType
  disabled?: boolean
} & onChangeType & sizeProps

type sizeProps = { size?: TextFieldPropSize, gapSize?: GridPropGap }
interface GeneratorFieldsProps extends sizeProps {
  visible: boolean
  disabled?: boolean
  /** Поля фильтров */
  fieldsConfig: FieldRow[]
  /** Стейт фильтров */
  valuesState: GeneratorStateType
  /**
   * Стейт черновика фильтров
   * Если требуется отображать превью фильтров (теги) не сразу,
   * а по какому-то действию (например после нажатия кнопки Поиск), то
   * filterStateDraft является стейтом для начальных данных, а filterState
   * для конечных данных
   */
  valuesStateDraft?: GeneratorStateType
  /** Обновить основной стейт */
  onChangeField: (fieldId: string, value?: valueType, exclude?: boolean) => void
  /** Обновить стейт черновика */
  onChangeFieldDraft?: (fieldId: string, value: any, type: FieldType, exclude?: boolean) => void
  wrapperClassName?: string
  excludeFields?: string[]
  visibleValuesPreview?: boolean
  withAutoGrid?: boolean
}

type GeneratorRowFieldsType = {
  valuesState: GeneratorStateType;
  disabled: boolean
  withAutoGrid?: boolean
} & onChangeType & FieldRow & sizeProps

const GeneratorFields = (props: GeneratorFieldsProps) => {
  const {
    size = 's',
    gapSize = '2xs',
    withAutoGrid = false,
    visible,
    disabled = false,
    fieldsConfig,
    wrapperClassName,
    valuesState,
    valuesStateDraft,
    excludeFields,
    onChangeField,
    onChangeFieldDraft,
    visibleValuesPreview = true,
  } = props

  const handleChange = (data: GeneratorChangeType) => {
    if (onChangeFieldDraft) {
      onChangeFieldDraft(data.fieldId, data.value, data.filedType, data.exclude)
      return
    }
    onChangeField(data.fieldId, data.value, data.exclude)
  }

  const valuesView = convertValuesToView(
    valuesState,
    fieldsConfig,
    excludeFields,
  )
  if (!visible && !valuesView.length) return null
  return (
    <Flex
      direction="column"
      gap={gapSize}
      className={cx(wrapperClassName)}
    >
      {visible
        ? fieldsConfig
          .filter((field) => !field.hidden)
          .map((filterRow, i) => (
            <GeneratorRowFields
              key={i}
              size={size}
              {...filterRow}
              valuesState={
                valuesStateDraft !== undefined
                  ? valuesStateDraft
                  : valuesState
              }
              withAutoGrid={withAutoGrid}
              onChange={handleChange}
              disabled={disabled}
            />
          ))
        : null}

      {visibleValuesPreview && valuesView.length ? (
        <GeneratorValuesPreview
          size={size}
          withResetAll={false}
          onResetValue={(fieldId) => onChangeField(fieldId, undefined)}
          valuesPreview={valuesView}
        />
      ) : null}
    </Flex>
  )
}
const GeneratorRowFields = (data: GeneratorRowFieldsType) => (
  /** Строка полей */
  <div
    className={cx(
      styles.filterRow,
      {[styles.spaceBetween]: data.flexSpaceBetween},
      data.extraClassName,
    )}
  >
    {data.fields.map(
      ({ fieldWrapperClassName, jointFields, ...fieldProps }, i) => (
        <React.Fragment key={i}>
          {jointFields ? (
            /** Слитые поля вместе, без раздедений */
            <GeneratorRowFields
              valuesState={data.valuesState}
              fields={jointFields}
              extraClassName={styles.jointFilterRow}
              onChange={data.onChange}
              disabled={data.disabled}
            />
          ) : (
            /** Выбранное поле */
            <div className={cx(styles.fieldWrapper, {[styles.autogrid]: data.withAutoGrid}, data.extraClassName)}>
              <GeneratorField
                size={data.size}
                {...fieldProps}
                valuesState={data.valuesState}
                onChange={data.onChange}
                disabled={data.disabled}
              />
            </div>
          )}
        </React.Fragment>
      ),
    )}
  </div>
)

const GeneratorField = (data: GeneratorFieldDataType) => {
  const {
    size
  } = data
  /** Выбор нужного компонента по типу */

  if (!data.fieldProps || !data.type) {
    return null
  }
  const value = data.valuesState[data.fieldProps.id]
  const commonProps = {
    value,
    disabled: data.disabled || data.fieldProps.readOnly,
    readOnly: data.disabled || data.fieldProps.readOnly,
    isRequired: data.fieldProps.isRequired,
    className: cx(styles.fieldClassName, data.fieldProps.className),
    excludeValue: data.valuesState[`${EXCLUDE_FIELD_PREFIX}${data.fieldProps.id}`],
    size: size
  }

  const onChangeCombobox = (valueObj: ComboboxItemDefault | null, exclude?: boolean) => {
    if (!data.fieldProps) {
      console.error('Ошибка в поле изменения, нет пропсов поля')
      return
    }
    data.fieldProps.onChange?.(valueObj, exclude)
    data.onChange({
      value: valueObj,
      exclude: exclude,
      filedType: data.type,
      fieldId: data.fieldProps.id,
    })
  }

  /* Комбобоксы с api search */
  const comboboxFields: ComboboxFieldType[] = [
    {
      Component: ComboboxWrapper,
      extraProps: { onChange: (value, exclude) => onChangeCombobox(value, exclude)},
      conditionFn: isCombobox,
    },
    { Component: ComboboxSkuBatch, conditionFn: isSkuBatch },
    // Api search
    { Component: ComboboxContractors, conditionFn: isContractors },
    { Component: ComboboxMerchants, conditionFn: isMerchants },
    { Component: ComboboxSkus, conditionFn: isSku },
    { Component: ComboboxCells, conditionFn: isCell },
    { Component: ComboboxWarehousePlace, conditionFn: isWarehousePlace },
    { Component: ComboboxWarehouseContainer, conditionFn: isWarehouseContainer },
    { Component: ComboboxDocumentsSearchApi, conditionFn: isDocument },
  ]
  const comboboxField = comboboxFields.find(comboboxEl => comboboxEl.conditionFn(data))
  if (comboboxField) {
    return (
      <comboboxField.Component
        {...data.fieldProps}
        {...commonProps}
        className={''} // костыль, чтобы обойти дефолтный пропс
        wrapperClassName={cx(styles.fieldClassName, data.fieldProps.className)}
        onChange={onChangeCombobox}
        {...comboboxField.extraProps}
      />
    )
  }
  if (isInput(data)) {
    return (
      <InputWrapper
        {...data.fieldProps}
        {...commonProps}
        handleChange={(value, type) => {
          if (!data.fieldProps) {
            console.error('Ошибка в поле изменения, нет пропсов поля')
            return
          }
          data.fieldProps.onChange?.(value, type)
          data.onChange({
            value,
            filedType: data.type,
            fieldId: data.fieldProps.id,
          })
        }}
      />
    )
  }
  if (isUrl(data)) {
    return (
      <FileField
        id={'test'}
        {...data.fieldProps}
        {...commonProps}
        accept={{ 'image/*': ['.png', '.jpg', '.jpeg'] }}
        previewType={'image'}
        handleChange={(base64WithFileName) => {
          if (!data.fieldProps) {
            console.error('Ошибка в поле изменения, нет пропсов поля')
            return
          }
          data.fieldProps.onChange?.(base64WithFileName)
          data.onChange({
            value: base64WithFileName,
            filedType: data.type,
            fieldId: data.fieldProps.id,
          })
        }}
      />
    )
  }
  if (isBase64(data)) {
    return (
      <FileField
        id={'test'}
        {...data.fieldProps}
        {...commonProps}
        previewType={'file'}
        handleChange={(base64WithFileName) => {
          if (!data.fieldProps) {
            console.error('Ошибка в поле изменения, нет пропсов поля')
            return
          }
          data.fieldProps.onChange?.(base64WithFileName)
          data.onChange({
            value: base64WithFileName,
            filedType: data.type,
            fieldId: data.fieldProps.id,
          })
        }}
      />
    )
  }
  if (isCalendar(data)) {
    return (
      <CalendarInput
        {...data.fieldProps}
        {...commonProps}
        value={value ? new Date(value) : null}
        handleChange={(value) => {
          if (!data.fieldProps) {
            console.error('Ошибка в поле изменения, нет пропсов поля')
            return
          }
          data.fieldProps.onChange?.(value)
          data.onChange({
            value,
            filedType: data.type,
            fieldId: data.fieldProps.id,
          })
        }}
      />
    )
  }
  if (isBool(data)) {
    const valueOption = boolSelectItems.find(option => option?.id === value)
    return (
      <ComboboxWrapper
        {...data.fieldProps}
        {...commonProps}
        value={valueOption}
        items={boolSelectItems}
        onChange={(value) => {
          data.fieldProps.onChange?.(value?.id)
          data.onChange({
            value: value?.id,
            filedType: data.type,
            fieldId: data.fieldProps.id,
          })
        }}
      />
    )
  }
  if (isGroup(data)) {
    return (
      <GroupField
        {...data.fieldProps}
        {...commonProps}
        onSubmit={(valeArr) => {
          data.fieldProps.onChange?.(valeArr)
          data.onChange({
            value: valeArr,
            filedType: data.type,
            fieldId: data.fieldProps.id,
          })
        }}
      />
    )
  }
  if (isTable(data)) {
    return (
      <TableField
        {...data.fieldProps}
        {...commonProps}
        onSubmit={(value) => {
          data.fieldProps.onChange?.(value)
          data.onChange({
            value: value,
            filedType: data.type,
            fieldId: data.fieldProps.id,
          })
        }}
      />
    )
  }
  if (isJson(data)) {
    return (
      <JsonField
        {...data.fieldProps}
        {...commonProps}
        onSubmit={(vale) => {
          data.fieldProps.onChange?.(vale)
          data.onChange({
            value: vale,
            filedType: data.type,
            fieldId: data.fieldProps.id,
          })
        }}
      />
    )
  }
  if (isCombineCells(data)) {
    return (
      <CombineCellsComponent
        {...data.fieldProps}
        {...commonProps}
        handleChange={(vale) => {
          data.fieldProps.onChange?.(vale)
          data.onChange({
            value: vale,
            filedType: data.type,
            fieldId: data.fieldProps.id,
          })
        }}
      />
    )
  }
  return null
}

export { GeneratorFields, GeneratorField }
