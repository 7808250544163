import React, { useMemo } from 'react'
import { ResponseError } from '@shared/api/types'
import { Modal, TableWithCopy } from '@/components'
import { ITableColumn, ITableRow } from '@/interfaces'
import { INotification } from '@shared/providers/NotificationProvider/config/schema'
import styles from './DetailsModal.module.scss'
import cx from 'classnames'

interface DetailsModalProps {
  type: INotification['type']
  onClose?: () => void
  allErrors?: ResponseError[]
}

const MessageDescr: Record<keyof ResponseError, string> = {
  "code": 'Код',
  "message": 'Текст',
} as const

export const DetailsModal = (props: DetailsModalProps) => {
  const {
    onClose,
    allErrors,
    type
  } = props

  const { rows, columns } = useMemo(() => {
    const columns: ITableColumn[] = Object.keys(allErrors[0]).map((key) => {
      const columnName = MessageDescr[key] || key
      return {
        key: key,
        title: columnName,
        title_txt: columnName
      }
    })

    const rows: ITableRow[] = allErrors.map((error, i) => {
      return ({
        id: `${i}`,
        ...error,
      })
    })
    return { rows, columns }
  }, [allErrors])

  const typeLabel: Record<INotification['type'], string> = {
    'alert': 'ошибок',
    'warning': 'предупреждений',
    'success': 'успеха',
    'system': 'инфо',
  }

  return (
    <Modal
      isOpen={true}
      withClose
      onClose={onClose}
      onOverlayClick={() => false}
      title={`Детализация ${typeLabel[type]}`}
      subtitle={' '}
      size='m'
      className={cx(styles.modal, styles[type])}
    >
      <TableWithCopy
        columns={columns}
        rows={rows}
      />
    </Modal>
  )
}
