import { ISupplyReceipt, TrustBox } from 'src/interfaces'
import { IReceiptFormDataState } from '../interfaces/receipt'


export const SET_RECEIPT_RAW = 'SET_RECEIPT_RAW'
export const setReceiptRawAction = (receiptRaw: ISupplyReceipt | null) => {
  return {
    type: SET_RECEIPT_RAW,
    payload: receiptRaw,
  }
}

export const SET_RECEIPT_FORM_STATE_DRAFT = 'SET_RECEIPT_FORM_STATE_DRAFT'
export const setReceiptFormDraftAction = (formState: IReceiptFormDataState) => {
  return {
    type: SET_RECEIPT_FORM_STATE_DRAFT,
    payload: formState,
  }
}
export const SET_RECEIPT_FORM_STATE_RAW = 'SET_RECEIPT_FORM_STATE_RAW'
export const setReceiptFormRawAction = (formState: IReceiptFormDataState) => {
  return {
    type: SET_RECEIPT_FORM_STATE_RAW,
    payload: formState,
  }
}

export const SET_TRUST_ACCEPTANCE_BOXES = 'SET_TRUST_ACCEPTANCE_BOXES'
export const setTrustAcceptanceBoxesAction = (trustBoxes: TrustBox[]) => {
  return {
    type: SET_TRUST_ACCEPTANCE_BOXES,
    payload: trustBoxes,
  }
}

