import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'

import { appLocalStorage } from '@shared/lib/storage'

import { AdvanceModeModal } from '@features/advanceMode'

import { useGetDatabases, useRemoveAdvancedMode } from '@/hooks'

import packageInfo from '../../../../../../package.json'

import { ICurrentUser, IDatabaseSelectOption, IInformer } from '@/interfaces'
import { Informer } from '@/components'
import { DocumentsType, generateMenuList, HeaderTypeEnum, IMenuList } from '@shared/ui/NavigationMenu/routes'
import { useGetReportsTypeList } from '@/hooks/report/useGetReportsTypeList'
import { useGetDocumentTypeList } from '@/hooks/document/useGetDocumentTypes'
import { AppContext } from '../config/context'
import { useDefaultSettings } from '@shared/hooks/settings'
import { Themes, ThemesEnum } from '../../ThemeProvider/config/themes'
import { defineType } from '@pages/Documents/DocumentsList/helpers/utils'


export const AppContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props

  const removeAdvanceModeMutation = useRemoveAdvancedMode()

  // переписать
  const {
    defaultTheme
  } = useDefaultSettings()

  const [theme, setTheme] = useState<ThemesEnum>(defaultTheme || Themes.light)



  const [databases, setDatabases] = useState<IDatabaseSelectOption[] | null>(null)
  const [authorized, setAuthorized] = useState(false)
  const [currentUser, setCurrentUser] = useState<ICurrentUser>({})
  const [globalLoading, setGlobalLoading] = useState(true)

  const [informer, setInformer] = useState<IInformer | null>(null)

  const [advanceMode, setAdvanceMode] = useState<boolean>(false)
  const [showAdvanceModeModal, setShowAdvanceModeModal] =
    useState<boolean>(false)
  const [advanceModeDeferredAction, setAdvanceModeDeferredAction] = useState<(() => void) | null>(
    null,
  )

  const [mainMenuList, setMainMenuList] = useState<IMenuList[]>([])
  const [reportsTypeList, setReportsTypeList] = useState<DocumentsType[]>([])
  const [documentsTypeList, setDocumentsTypeList] = useState<DocumentsType[]>([])

  useEffect(() => {
    if (!authorized) return
    const menuValue = generateMenuList({ reportsTypeList, documentsTypeList })
    setMainMenuList(menuValue.filter(menu => menu.items.length))
  }, [mainMenuList?.length, reportsTypeList?.length, documentsTypeList?.length, authorized])

  useGetReportsTypeList({
    enabled: !reportsTypeList?.length && authorized,
    onSuccess: (data) => setReportsTypeList(data?.values)
  })
  useGetDocumentTypeList({
    enabled: !documentsTypeList?.length && authorized,
    onSuccess: (data) => setDocumentsTypeList(data?.values)
  })


  const logout = () => {
    console.log(logout)
    appLocalStorage.clear()
    setAuthorized(false)
  }

  const getDatabases = useGetDatabases()

  useEffect(() => {
    if (databases) {
      // базы уже добавлены
      return
    }
    getDatabases
      .fetch()
      .then((data) => {
        setDatabases(data)
      })
  }, [databases])

  const performWithAdvance = useCallback((action: () => () => void) => {
    if (advanceMode) {
      action()()
    } else {
      setAdvanceModeDeferredAction(action)
      setShowAdvanceModeModal(true)
    }
  }, [advanceMode])

  const removeAdvanceMode = async () => {
    await removeAdvanceModeMutation.mutateAsync().then(({ data }) => {
      if (data?.success === true) {
        setAdvanceMode(false)
      }
    })
  }

  const getDocMenuItemByDocType = (docType: string) => {
    const menu = mainMenuList?.find((item) => item.type === defineType(docType, mainMenuList))
    const menuItem = menu?.items.find((item) => item.documentType === docType && item.headerType === HeaderTypeEnum.documents)
    return {menu, menuItem}
  }

  return (
    <AppContext.Provider
      value={{
        mainMenuList,
        documentsTypeList,
        getDocMenuItemByDocType,

        databases: databases || [],
        setDatabases,
        globalLoading,
        setGlobalLoading,
        authorized,
        setAuthorized,
        currentUser,
        setCurrentUser: (user: ICurrentUser) => {
          appLocalStorage.currentUser = user
          setCurrentUser(user)
        },

        advanceModeDeferredAction,
        setAdvanceModeDeferredAction,
        advanceMode,
        setAdvanceMode,
        removeAdvanceMode,
        removeAdvanceModeIsLoading: removeAdvanceModeMutation.isLoading,
        performWithAdvance,
        showAdvanceModeModal,
        setShowAdvanceModeModal,
        requestAdvanceMode: () => setShowAdvanceModeModal(true),

        theme,
        setTheme,

        logout,

        informer,
        setInformer
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
