import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { generateColumns, generateFilters, generateRows } from './helpers'
import { DocumentsListWrapper } from '../../../components'
import styles from './ReturnList.module.scss'
import { useQueryReturnList, useUpdateReturnStatus } from 'src/hooks'
import { generateFilterParams } from '../../../helpers/filters'
import { Button } from '@shared/ui/btns/Button'
import { ReturnStatusEnum } from 'src/interfaces'
import { useNotifications } from '@shared/providers/NotificationProvider'
import { useFilter } from '../../../hooks/useFilter'

const ReturnList = () => {
  const notification = useNotifications()

  /** Стейт для поиска и отображения данных фильтров */
  const { filterState, setFilterState } = useFilter()
  const [editMode, setEditMode] = useState<boolean>(false)
  const [editStatusState, setEditStatusState] = useState<Record<string, ReturnStatusEnum>>({})

  const updateReturnStatus = useUpdateReturnStatus()
  const {
    isFetching: returnListLoading,
    data: returnListData,
    refetch: refetchReturnList,
  } = useQueryReturnList(generateFilterParams(filterState), {enabled: false})

  useEffect(() => {
    refetchReturnList()
  }, [filterState])

  const rows = useMemo(
    () => generateRows(returnListData?.returns || [], editStatusState, editMode),
    [editStatusState, editMode, returnListData?.returns],
  )
  const columns = useMemo(
    () => generateColumns(editStatusState, editMode, setEditStatusState),
    [editStatusState, editMode, setEditStatusState]
  )
  const filtersConfig = generateFilters()


  const handleClickCancelEditMode = useCallback(() => {
    setEditMode(false)
    setEditStatusState({})
  }, [setEditMode])

  const handleClickEditMode = useCallback(() => {
    setEditMode(true)
  }, [setEditMode])

  const handleClickSaveEdits = useCallback(() => {
    const requestValue = Object
      .keys(editStatusState)
      .map(key => ({ id: key, status: editStatusState[key] }))

    updateReturnStatus
      .mutateAsync(requestValue)
      .then(() => {
        notification?.show('success', 'Документы успешно сохранен', {
          soundName: 'PACKING_COMPLETED',
        })
        setEditMode(false)
        setEditStatusState({})
      })
      .finally(refetchReturnList)
  }, [updateReturnStatus, editStatusState, refetchReturnList, notification])

  return (
    <DocumentsListWrapper
      withCopy={!editMode}
      title={'Возврат заказов'}
      rows={rows}
      columns={columns}
      filterState={filterState}
      setFilterState={setFilterState}
      filtersConfig={filtersConfig}
      loading={returnListLoading}
      pagination={returnListData?.pagination}
      tableExtraHeaderRight={
        !editMode
          ? (
            <Button
              label='Редактировать'
              onClick={handleClickEditMode}
              loading={updateReturnStatus.isLoading}
            />
          )
          : (
            <div className={styles.btns}>
              <Button
                label='Отмена'
                view={'ghost'}
                onClick={handleClickCancelEditMode}
                loading={updateReturnStatus.isLoading}
              />
              <Button
                label='Сохранить'
                onClick={handleClickSaveEdits}
                loading={updateReturnStatus.isLoading}
              />
            </div>
          )
      }
    />
  )
}

export default ReturnList
