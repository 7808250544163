import React from 'react';
import cx from 'classnames'
import styles from "./HoverTooltip.module.scss"
import { FCC } from '../../helpers';
import { Flex } from '@shared/ui/Flex'
import { Text } from '@shared/ui/Text'

interface HoverTooltipProps {
  text: string
  onClick?: (e: React.MouseEvent) => void
}

export const HoverTooltip: FCC<HoverTooltipProps> = (props) => {
  const {
    text,
    children,
    onClick
  } = props
	return (
		<Flex className={cx(styles.tooltip)}>
      {children}
      <Text
        as={'span'}
        size={'xs'}
        className={styles.tooltiptext}
        children={text}
        onClick={onClick}
      />
		</Flex>
	);
};
