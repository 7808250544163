import { IBarcode, IBatch, IOrder, IOrderItem } from '@/interfaces'
import dayjs from 'dayjs'
import { DATE_VIEW } from '@shared/const/date'
import { ADVANCED_BARCODE, COMMON_BARCODE_TYPE } from '@shared/const/barcodes'
import React from 'react'
import { DefaultChzRegExp } from '@shared/helpers/chz'
import { ComboboxItemDefault } from '@consta/uikit/Combobox'

export const sequenceRunner = ([first, ...tail]: (() => Promise<any>)[]) => {
  /** Последовательное выполнение промисов */
  if (first === undefined) return Promise.resolve()
  try {
    return tail.reduce((p, deed) => p.then(deed), first())
  } catch (e) {
    return Promise.reject(e)
  }
}

export const getItemsQuantity = (items?: { quantity: number }[]) =>
  typeof items !== 'undefined'
    ? items.reduce((a: number, c) => {
        a = a + Number(c.quantity)
        return a
      }, 0)
    : null

export const isPositiveNumber = (value: string) => {
  return /^([0]{1}\.{1}[0-9]+|[1-9]{1}[0-9]*\.{1}[0-9]+|[0-9]+|0)$/.test(value)
}
export const checkWeight = (value: string) => {
  return Number(value) > 0 && isPositiveNumber(value)
}

export const isNumber = (value: string) => {
  return /^[0-9]+$/.test(value)
}

export const checkIsTare = (barcode: string) => {
  return /^TARA/.test(barcode)
}

export const getBatchStr = (batch: IBatch) => {
  if (!batch) return ''
  return `${
    batch.num ?? ''
  }${
    batch.num && batch.exp_date ? ', ' : ''
  }${
    batch.exp_date
      ? 'годен до ' + dayjs(batch.exp_date).format(DATE_VIEW)
      : ''
  }`
}

export const getPlacesString = (count: number) => {
  let resultStr = count + ' '
  const strLength = String(count).length
  if ([11, 12, 13, 14].indexOf(Number(count)) !== -1) {
    resultStr += 'товаров'
  } else {
    const symbol = strLength > 1 ? String(count)[strLength - 1] : count
    switch (Number(symbol)) {
      case 1:
        resultStr += 'место'
        break
      case 2:
      case 3:
      case 4:
        resultStr += 'места'
        break
      default:
        resultStr += 'мест'
    }
  }
  return resultStr
}

export const hideSymbols = (openSymbolsCount: number = 1, str: string = '', trimHiddenSymbols?: number) => {
  const lastSymbols = str.slice(
    str.length - openSymbolsCount >= 0 ? str.length - openSymbolsCount : 0,
    str.length,
  )
  const startSymbols =
    lastSymbols.length < str.length
      ? str.slice(0, str.length - openSymbolsCount)
      : ''
  const hideStartSymbols = [...Array(startSymbols.length)]
    .map((sym) => '*')
    .join('')
  const trimmedHiddenSymbols = hideStartSymbols.slice(0, trimHiddenSymbols || hideStartSymbols.length)
  return trimmedHiddenSymbols + lastSymbols
}

export const getPathWithoutLastPart = (pathname: string) => {
  return pathname.slice(0, pathname.lastIndexOf('/'))
}

export const getFormattedOrderSimilarItems = (items: IOrderItem[]) =>
  items
    ? items.reduce((accum: IOrderItem[], orderItem: IOrderItem) => {
        const findCurrentItem = accum.find(
          (accOrderItem: IOrderItem) =>
            accOrderItem.id === orderItem.id &&
            accOrderItem.article === orderItem.article &&
            accOrderItem.marketplace_sku_id === orderItem.marketplace_sku_id,
        )
        if (findCurrentItem) {
          findCurrentItem.quantity =
            findCurrentItem.quantity + orderItem.quantity
        } else {
          accum.push({ ...orderItem })
        }

        return accum
      }, [] as IOrderItem[])
    : []


export const getOrderItemByBarcode = (barcode: string, arr: IOrderItem[]) =>
  arr.find((item: IOrderItem) =>
    item.barcodes.find((b) => b.barcode === barcode),
  )

export const barcodeTypeIsPreferred = (
  barcode: string,
  preferredBarcodeType: string | undefined,
  itemBarcodes: IBarcode[],
) => {
  /*
    true - печатаем только если есть доп упаковка
    false - печатаем и с доп упаковкой и без нее
   */
  /** Проверка совпадает ли тип отсканированного ШК с предпочтительным ШК */

  /* Не передан предпочтительный тип, значит нам подходит любой шк, оставляем текущий  */
  if (!preferredBarcodeType) {
    return true
  }

  /* Тип текущего стикера предпочтительный, оставляем текущий */
  const currentBarcode = itemBarcodes.find((b) => b.barcode === barcode)
  const currentBarcodeIsPreferred = currentBarcode?.type === preferredBarcodeType
  if (currentBarcodeIsPreferred) {
    return true
  }

  /* Нашли ШК с предпочтительным типом, от бека ожидаем его */
  const preferredBarcode = itemBarcodes.find((b) => b.type === preferredBarcodeType)
  if (preferredBarcode) {
    return false
  }

  /* Не нашли предпочтительный ШК, значит печатаем "Общий" (COMMON) */
  const commonBarcode = itemBarcodes.find((b) => b.type === COMMON_BARCODE_TYPE)
  if (!commonBarcode) {
    return true
  }

  /* Текущий тип ШК НЕ "Общий", значит запрашиваем от сервера стикер */
  if (currentBarcode?.type !== COMMON_BARCODE_TYPE) {
    return false
  }

  return true
}


export const blurOrFocusInput = (
  inputRef: React.RefObject<HTMLTextAreaElement | HTMLInputElement | null> | null,
  type: 'focus' | 'blur',
): void => {
  if (!inputRef?.current) {
    return
  }
  type === 'focus' ? inputRef.current.focus() : inputRef.current.blur()
}

export const checkSerialNumberItem = (item: IOrderItem): boolean => {
  const itemHasSerialNumbers = item?.scan_serial_numbers?.length
  if (!itemHasSerialNumbers) {
    /* если нет серийных номеров */
    return true
  }
  return item?.scan_serial_numbers?.every((serialNumber: any) =>
    checkTemplateOfSerialNumberForCorrectness(serialNumber.template),
  )
}
export const getItemsWithProblemsSerialNumbersFromOrders = (orders: IOrder[]) =>
  /** Проверка серийных номеров */
  orders?.reduce((accum: any[], order: any) => {
    order?.items?.forEach((item: any) => {
      const alreadyExist = accum.find((accumItem) => accumItem.id === item.id)
      if (alreadyExist) {
        return accum
      }
      const itemCorrect = checkSerialNumberItem(item)
      if (!itemCorrect) {
        accum.push(item)
      }
    })
    return accum
  }, []) || []

export const checkTemplateOfSerialNumberForCorrectness = (
  template: string,
): boolean => {
  /** Проверка тимплейта серийных номеров на валидность */
  if (!template?.trim()) {
    console.log('template пустой')
    return false
  }
  const isLengthTemplate = template.split('').every((l) => l === '?')
  const defaultRegularExpression = template?.toUpperCase() === '{УИТ}'
  const anyString = template === '*'
  if (!isLengthTemplate && !defaultRegularExpression && !anyString) {
    try {
      new RegExp(template)
      return true
    } catch (e) {
      return false
    }
  }
  return true
}

export const checkSerialNumberForCorrectness = (
  value: string,
  template: string,
): { correct: boolean; errorText: string } => {
  if (value?.trim() === ADVANCED_BARCODE) {
    const errorText = `Просканирован ШК расширения прав`
    return { correct: false, errorText: errorText }
  }

  if (template === '*') {
    const correct = Boolean(value?.trim()?.length)
    return { correct, errorText: correct ? '' : `Введен некорректный номер` }
  }

  // Проверка на кириллицу
  if (/[а-яА-Я]/i.test(value)) {
    const errorText = `Обнаружена кириллица в серийном номере. Измените раскладку клавиатуры.`
    return { correct: false, errorText: errorText }
  }

  /*
   Если тимплейт состоит исключительно из вопросительных знаков,
   то валидация происходит по длине тимплейта
  */
  const isLengthValidation = template.split('').every((l) => l === '?')
  if (isLengthValidation) {
    const errTxt = 'Введенный номер не соответствует шаблону длины'
    const correct = template.trim().length === value.trim().length
    return { correct, errorText: correct ? '' : errTxt }
  }

  /*
    Валидация по встроенному regex
  */
  if (template.toUpperCase() === '{УИТ}') {
    const correct = new RegExp(DefaultChzRegExp).test(value)
    const errorTxt = `Введенный номер не соответствует УИТ шаблону.`
    return { correct: correct, errorText: correct ? '' : errorTxt }
  }

  /*
    Валидация по переданному regex
  */
  try {
    const errTxt = 'Введенный номер не соответствует шаблону.'
    const correct = new RegExp(template).test(value)
    return { correct: correct, errorText: correct ? '' : errTxt }
  } catch (err) {
    const errTxt = 'Ошибка регулярного выражения (от клиента)'
    return { correct: false, errorText: errTxt }
  }
}
/* конвертируем массив в объект */
export const convertArrToObject = <T>(arr: T[], key: keyof T, callback?: (item: T) => string /* key of obj */): Record<string, T> => {
    return arr.reduce((obj, el) => ({...obj, [el?.[key] as string]: el}), {})
}
export const convertObjectToURLParams = (paramsObj?: object): string => {
  if (!paramsObj) {
    return ''
  }
  /*
   * Получает на вход объект, возвращает строку в формате ?{key}={value}&....
   */
  const correctObject = Object.keys(paramsObj).reduce((accum, key) => {
    const value = paramsObj[key as keyof object]
    if (value !== undefined && value !== null && value !== '') {
      accum[key as keyof object] = value
    }
    return accum
  }, {})
  const resultString = new URLSearchParams(correctObject).toString()
  return resultString ? `?${resultString}` : ''
}

export const cloneDeep = <T>(obj: T): T => {
  if (!obj) {
    return obj
  }
  return JSON.parse(JSON.stringify(obj))
}

/*
 * Очищает объект от undefined полей
 */
export const copyNoUndefined = (obj: { [key: string]: any }) =>
  Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== undefined) {
      return { ...acc, [key]: obj[key] }
    }

    return acc
  }, {})

export const getCurrencySymbol = (currency: string, locale?: string) => {
  /**
   * получает символ волюты
   * пример: currency: RUB
   * вернет: ₽
   */
  return (0)
    .toLocaleString(locale || 'ru-RU', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim()
}

type GetNameType = {
  name?: string
  surname?: string
  patronymic?: string
}
export const getShortPersonName = ({
  name,
  surname,
  patronymic,
}: GetNameType) => {
  if (!surname && !name && !patronymic) {
    return ''
  }
  return `${surname} ${name?.[0] || ''} ${patronymic?.[0] || ''}`
}
export const getFullPersonName = ({ name, surname, patronymic }: GetNameType) =>
  `${surname || ''} ${name || ''} ${patronymic || ''}`

export const joinMultiselectLabel = <T extends {label: string},>(items: T[] = []) =>
  items?.map((item) => item.label).join(', ') || ''

/* Проверка на пустую упаковку */
export const checkWithoutPackaging = (packageId?: string | null) => {
  const packId = packageId || ''
  return (
    ['noAddPackage', 'no_packaging'].includes(packId) ||
    packId.includes('no_packaging')
  )
}

export const sumArr = (array: number[]) => array.reduce((a, b) => a + b, 0)


export const boolToText = (val: boolean) => val ? 'Да' : 'Нет'