import { IAcceptanceSku, ITableColumn, ITableRow } from 'src/interfaces'
import React from 'react'
import styles from '../unprocessed.module.scss'
import days from 'dayjs'
import { IReplaceItem } from 'src/interfaces/replace'
import { BatchCell, ImageCell, QuantityCell } from 'src/components'
import { DATE_VIEW } from '@shared/const/date'


export const generateTableColumns = (printMode: boolean): ITableColumn[] => {
  const res = [
    {
      key: 'img',
      title: 'Фото',
      title_txt: 'Фото',
      gridCellWidth: '90px',
      renderCell: ({ row }) => <ImageCell image={String(row.img)} />,
    },
    {
      key: 'name',
      title: 'Номенклатура',
      title_txt: 'Номенклатура',
      gridCellWidth: '5fr',
    },
    {
      key: 'quantity',
      title: 'Кол-во',
      title_txt: 'Кол-во',
      gridCellWidth: '150px',
      align: 'right',
    },
    {
      key: 'action',
      title: '',
      title_txt: '',
      gridCellWidth: '1fr',
      isAction: true,
    },
  ] as ITableColumn[]
  return res.filter((column) =>
    printMode ? !['measure', 'party'].includes(column.key) : true,
  )
}

export const generateTableRows = (args: {
  originalSkus: IAcceptanceSku[]
  documentReplace: IReplaceItem[]
  advanceMode?: boolean
  handleAddItem: (itemId: string) => void
  printMode: boolean
  handleSetPrintQuantity?: (itemId: string, value?: number) => void
  printItemsValues?: Record<string, number | undefined>
}): ITableRow[] => {
  const {
    originalSkus,
    documentReplace,
    advanceMode,
    handleAddItem,
    printMode,
    handleSetPrintQuantity,
    printItemsValues,
  } = args
  console.log(originalSkus)

  return documentReplace.map((doc) => {
    const originalSku = originalSkus.find((item) => item.id === doc.sku_id)
    const batch = originalSku?.batches?.find(
      (batch) => batch.id === doc.sku_batch_id,
    )
    const batchDate = batch?.exp_date
      ? days(batch.exp_date).format(DATE_VIEW)
      : ''

    const id = documentReplace[0].sku_id
    const skuId = documentReplace[0].sku_id
    const quantity = documentReplace[0].quantity
    return {
      id: id,
      skuId: skuId,
      img: originalSku?.image,
      // img: originalSku?.image,
      article: originalSku?.article,
      name: (
        <>
          <p>{originalSku?.title}</p>
          <span className={styles.lightTxt}>{originalSku?.article}</span>
        </>
      ),
      barcodes: originalSku?.barcodes,
      quantity: documentReplace[0].quantity,
      batches: originalSku?.batches,
      measure: 'шт',
      party: batch ? (
        <BatchCell date={batchDate} num={batch.num || ''} />
      ) : null,
      action: (
        <div className={styles.actionCell}>
          <QuantityCell
            id={skuId}
            skuQuantity={Number(quantity)}
            quantityValue={printItemsValues?.[skuId]}
            handleSetPrintQuantity={handleSetPrintQuantity}
          />
        </div>
      ),
    }
  })
}
