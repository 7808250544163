import React, { useEffect, useMemo, useState } from 'react'
import styles from './DocumentsParamsFields.module.scss'
import { Button, ChoiceTablePrintModal, InputWrapper } from 'src/components'
import { IconPrinter } from '@shared/assets/icons/IconPrinter'
import { useParams } from 'react-router-dom'
import { OperAndDocumPagesType } from '../../../../../pagesType'
import { generateColumns, generateRows, generateRowsDiscrepancy } from './utils'
import { DocumentsParams } from '../../../../../components'
import { useInventoryFormContext } from '@pages/OperationsAndDocumentsPage/context/InventoryContext'
import days from 'dayjs'

import AddModalZeroCells from '../AddModalZeroCells/AddModalZeroCells'
import { usePrintInventoryDocs } from '@shared/hooks/print/usePrintInventoryINF'
import {
  useAddResultInventory,
  useGetInventoryDocument,
  useGetInventoryExcel,
  useStartInventory,
} from 'src/hooks/warehouse/inventory'
import { IconSave } from '@consta/icons/IconSave'
import { INVENTORY_CONDITION, INVENTORY_STATUSES } from 'src/interfaces'
import { saveBase64File } from '@shared/helpers'
import { DATE_TIME_VIEW } from '@shared/const/date'

interface InventoryFormContentProps {
  view?: string
}
type editModeType = 'accept' | 'reset' | 'start'

const DocumentsParamsFields = (props: InventoryFormContentProps) => {
  const { view } = props
  const { page, id: indentoryId } = useParams<{
    page: OperAndDocumPagesType
    id: string
    tabId: string
  }>()
  const { mutateAsync: startInventory, isLoading: isInventoryLoading } =
    useStartInventory()
  const { refetch: refetchInventory, data: inventoryData } =
    useGetInventoryDocument({
      invenoryId: indentoryId,
    })
  const { fetch: getInventoryExcel } = useGetInventoryExcel()

  const [editCellId, setEditCellId] = useState<string | null>(null)
  const [draftCellItemData, setDraftCellItemData] = useState<Record<
    string,
    string | null
  > | null>(null)
  const [openZeroCells, setOpenZeroCells] = useState(false)
  const [openPrintModal, setOpenPrintModal] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)

  const { state, dispatch } = useInventoryFormContext()
  const { printInventoryDocs } = usePrintInventoryDocs()
  const { isLoading: addLoading, mutateAsync: addResultInventory } =
    useAddResultInventory({})

  const handleEditCellItemQuantity = (
    cellItemId: string,
    value: string | null,
  ) => {
    if (!draftCellItemData) {
      return
    }

    setDraftCellItemData((prev) => ({
      ...prev,
      [cellItemId]: value,
    }))
  }
  const handleClickCellEdit = async (
    factId: string,
    editMode: editModeType,
  ) => {
    switch (editMode) {
      case 'start':
        const fact = state.documentRaw?.facts?.find((el) => el.id === factId)
        if (!fact) {
          return
        }
        setDraftCellItemData(
          fact.items.reduce(
            (acc, item) => ({ ...acc, [item.sku_id]: String(item.quantity) }),
            {},
          ),
        )
        setEditCellId(factId)
        break

      case 'accept':
        if (!draftCellItemData) {
          console.error('Данных о редактировании нет!')
          return
        }
        const factItem = state.documentRaw?.facts?.find(
          (el) => el.id === editCellId,
        )
        const result = {
          factId: editCellId || '',
          docId: indentoryId,
          data: {
            cell_barcode: factItem?.cell_barcode || '',
            items: [...(factItem?.items || [])].map((el) => ({
              ...el,
              quantity: Number(draftCellItemData[el.sku_id]),
            })),
          },
        }

        await addResultInventory(result).then((res) => refetchInventory())

        setEditCellId(null)
        break
      case 'reset':
        setEditCellId(null)
        break
    }
  }

  const rows = useMemo(
    () =>
      generateRows(
        state.documentRaw,
        state.originalSkus,
        state.cellRaw,
        editCellId,
        draftCellItemData,
        handleEditCellItemQuantity,
        handleClickCellEdit,
      ),
    [
      state.documentRaw,
      state.originalSkus,
      state.cellRaw,
      editCellId,
      draftCellItemData,
    ],
  )
  const rowsDiscrepancy = useMemo(
    () =>
      generateRowsDiscrepancy(
        state.documentRaw,
        state.originalSkus,
        state.cellRaw,
      ).filter((el) => el?.subItems && el.subItems.length),
    [state.documentRaw, state.originalSkus, state.cellRaw],
  )
  const columns = useMemo(() => generateColumns(), [])

  const handleClick = () => {
    if (state.documentRaw?.status === 'SCHEDULED') {
      startInventory({
        inventory_id: indentoryId,
        hook: true,
      }).then(async () => {
        refetchInventory()
      })
    } else {
      startInventory({
        inventory_id: indentoryId,
        hook: false,
      }).then(async () => {
        refetchInventory()
      })
    }
  }

  useEffect(() => {
    if (!inventoryData?.document.status) {
      return
    }
  }, [inventoryData?.document.status])

  const generateInventoryExcel = async () => {
    setLoading(true)
    try {
      if (indentoryId) {
        const data = await getInventoryExcel({
          indentoryId: indentoryId,
        })
        if (data) {
          saveBase64File(data.content)
        }
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <DocumentsParams
        rows={view ? rowsDiscrepancy : rows}
        columns={columns}
        tableExtraHeader={
          <div className={styles['header-container']}>
            <Button
              label="Скачать INF-3 Excel"
              iconLeft={IconSave}
              view="secondary"
              size={'s'}
              loading={loading}
              onClick={() => generateInventoryExcel()}
            />
            <Button
              label="Печать документов"
              iconLeft={IconPrinter}
              iconSize={'m'}
              size={'s'}
              onClick={() => setOpenPrintModal(true)}
            />
            <Button
              label={'Вставить нулевые ячейки'}
              view="secondary"
              size={'s'}
              onClick={() => setOpenZeroCells(true)}
            />
            <Button
              label={
                inventoryData?.document.status === 'SCHEDULED'
                  ? 'Начать инвентаризацию'
                  : 'Завершить инвентаризацию'
              }
              size={'s'}
              disabled={
                !(
                  inventoryData?.document.status === 'SCHEDULED' ||
                  inventoryData?.document.status === 'PROCESSING'
                )
              }
              onClick={handleClick}
            />
          </div>
        }
      >
        <div className={styles.fieldsWrapper}>
          <InputWrapper
            className={styles.fieldType25}
            value={days(state.documentRaw?.create_ts || null).format(DATE_TIME_VIEW)}
            id={'create_ts'}
            label={'Дата'}
            disabled
          />
          <InputWrapper
            className={styles.fieldType25}
            value={state.documentRaw?.id || null}
            id={'id'}
            label={'Номер'}
            disabled
          />
          <InputWrapper
            className={styles.fieldType25}
            value={state.documentRaw?.merchant?.title || null}
            id={'org'}
            label={'Организация'}
            disabled
          />
          <InputWrapper
            className={styles.fieldType25}
            value={INVENTORY_STATUSES[state.documentRaw?.status || '']}
            id={'status'}
            label={'Статус документа'}
            disabled
          />
          <InputWrapper
            className={styles.fieldType25}
            value={INVENTORY_CONDITION[state.documentRaw?.sku_condition || '']}
            id={'status'}
            label={'Качество товара'}
            disabled
          />

          {/* <DocumentStatusField
          value={state.formStateDraft?.status}
          onChange={(optionValue) =>
            dispatch(
              updateFormInventoryStateDraftAction('status', optionValue?.value),
            )
          }
          documentType={page as DocumentTypeEnum}
        /> */}

          <InputWrapper
            className={styles.fieldType25}
            value={
              state.documentRaw?.scheduled_from
                ? days(state.documentRaw?.scheduled_from || null).format(DATE_TIME_VIEW)
                : ''
            }
            id={'dataFrom'}
            label={'С'}
            disabled
          />
          <InputWrapper
            className={styles.fieldType25}
            value={
              state.documentRaw?.scheduled_to
                ? days(state.documentRaw?.scheduled_to || null).format(DATE_TIME_VIEW)
                : ''
            }
            id={'dataTo'}
            label={'По'}
            disabled
          />
        </div>
      </DocumentsParams>
      {openZeroCells ? (
        <AddModalZeroCells
          isOpen={openZeroCells}
          onClose={() => setOpenZeroCells(false)}
          cells={state.cellRaw}
          plans={state.documentRaw?.plans}
          docId={state.documentRaw?.id}
          refetch={refetchInventory}
        />
      ) : null}
      {openPrintModal ? (
        <ChoiceTablePrintModal
          isOpen={true}
          onClose={() => setOpenPrintModal(false)}
          onSubmit={async (args, isPreview) => {
            setOpenPrintModal(false)
            try {
              if (args[0]?.id === 'INF-3') {
                await printInventoryDocs(indentoryId, isPreview)
              }
            } catch (err) {
              console.error(err)
            }
          }}
          title={'Печать документов'}
          submitBtnLabel={'Напечатать'}
          rows={[
            {
              id: 'INF-3',
              title: 'INF-3',
              defaultChecked: true,
            },
          ]}
          columns={[
            {
              key: 'title',
              title: 'Название',
              title_txt: 'Название',
            },
          ]}
        />
      ) : null}
    </>
  )
}
export default DocumentsParamsFields
