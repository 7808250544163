import React, { FC, useEffect, useState } from 'react'
import { IServicesDocuments, ModalProps, Service } from 'src/interfaces'
import { v4 } from 'uuid'
import { Modal } from 'src/components'
import ItemsModal from './ItemsModal/ItemsModal'
import { ComboboxItemDefault } from '@consta/uikit/Combobox'
import { Button } from '@shared/ui/btns/Button'
import { IFormField, IItemsService } from './types'
import styles from './ServicesRendereModal.module.scss'
import useCreateServicesRendered from 'src/hooks/servicesRendered/useCreateServiceRendered'
import useEditServicesRendered from 'src/hooks/servicesRendered/useEditServiceRendered'
import { TextField } from '@consta/uikit/TextField'
import { ComboboxMerchants } from '@entities/Merchant'

interface ServiceModalProps extends ModalProps {
  services?: Service[]
  servicesTotalCount?: number
  document?: IServicesDocuments | null
  refetch?: () => void
  setServicesSearch?: (val: string) => void
}

const ServicesRenderedModal: FC<ServiceModalProps> = ({
  isOpen,
  onClose,
  servicesTotalCount = 0,
  services = [],
  document,
  refetch = () => {},
  setServicesSearch,
}) => {
  const [selectedOriginalServices, setSelectedOriginalServices] = useState<
    Service[]
  >([])
  const [servicesList, setServicesList] = useState<ComboboxItemDefault[]>([])
  const [originServicesList, setOriginServicesList] = useState<ComboboxItemDefault[]>(
    [],
  )

  const [fieldValues, setFieldValues] = useState<IFormField>({
    comment: '',
    items: [],
    merchant_id: null,
  })

  const [error, setErorr] = useState(false)
  const [id, setId] = useState('')
  const { mutateAsync: createUpdateServices } = useCreateServicesRendered()
  const { mutateAsync: editUpdateServices } = useEditServicesRendered({ id })

  useEffect(() => {
    if (document) {
      setId(document.id)
      const merchant_id = {
        label: document.merchant?.title || '',
        id: document.merchant?.id || '',
      }
      const items = document.items.map((doc) => {
        const service = services?.find((el) => el.id === doc.service_id)

        setSelectedOriginalServices((prev) => {
          if (service) {
            prev.push(service)
          }
          return prev
        })

        return {
          service_id: {
            id: v4(),
            label: service?.title || '',
            groupId: doc.service_id,
          },
          quantity: doc.quantity,
        }
      })

      setFieldValues((prev) => {
        return {
          ...prev,
          comment: document?.comment || '',
          merchant_id: merchant_id,
          items: items,
        }
      })
    }
    if (!services) {
      return
    }
    setServicesList(
      services.map((service) => ({
        label: service.title,
        id: service.id,
      })),
    )
    setOriginServicesList(
      services.map((service) => ({
        label: service.title,
        id: service.id,
      })),
    )
  }, [services, document])

  useEffect(() => {
    if (!fieldValues.items && !servicesList) {
      return
    }
    setServicesList(
      originServicesList.filter((item) => {
        return !fieldValues.items.some(
          (item2) => item.id === item2.service_id.groupId,
        )
      }),
    )
  }, [fieldValues.items])

  const onDelete = (uuid: string) => {
    const newItems = fieldValues.items.filter(
      (item) => item.service_id.id !== uuid,
    )

    setFieldValues((prev) => {
      return { ...prev, items: newItems }
    })
  }

  const onModalChange = (
    uuid: string,
    key: keyof IItemsService,
    value: ComboboxItemDefault | number | string,
  ) => {
    const items = [...fieldValues.items]

    if (key === 'service_id') {
      /* сохраняем выбранные сервисы для отображения меры измерений */
      const origService = services.find(
        (el) => el.id === (value as ComboboxItemDefault)?.groupId,
      )
      setSelectedOriginalServices((prev) => {
        if (origService) {
          prev.push(origService)
        }
        return prev
      })
      /* сбрасываем поиск сервисов для выбора в других полях */
      setServicesSearch('')
    }

    for (let i = 0; i <= items.length; i++) {
      if (items[i].service_id.id === uuid) {
        ;(items[i] as Record<typeof key, string | number | ComboboxItemDefault>)[key] =
          value
        setFieldValues((prev) => {
          return { ...prev, items: items }
        })
        break
      }
    }
  }
  const canSubmit = () => {
    const isError = [...fieldValues.items]

    for (let i = 0; i < isError.length; i++) {
      const el = isError[i]
      if (!el.service_id.label || !el.quantity) {
        return false
      }
    }
    return true
  }
  const handleSubmit = () => {
    const isValid = canSubmit()
    if (!isValid) {
      setErorr(true)
      return
    }
    const items = fieldValues.items.map((el) => {
      return {
        quantity: Number(el.quantity),
        service_id: el.service_id.groupId,
      }
    })

    const createDoc = {
      warehouse_comment: fieldValues.comment,
      merchant_id: fieldValues.merchant_id?.id,
      items: items,
    }

    if (document) {
      editUpdateServices(createDoc)
        .then(async () => {
          refetch()
        })
        .catch(() => {})
    } else {
      createUpdateServices(createDoc)
        .then(async () => {
          refetch()
        })
        .catch(() => {})
    }

    onClose()
  }
  return (
    <Modal isOpen={isOpen} hasOverlay onOverlayClick={onClose}>
      <ComboboxMerchants
        className={styles.merchantRow}
        value={fieldValues.merchant_id}
        onChange={(value) =>
          setFieldValues((prev) => ({ ...prev, merchant_id: value }))
        }
      />

      <div>
        <div className={styles.addRow}>
          <p>{`Услуга (${fieldValues.items.length} из ${servicesTotalCount}):`}</p>

          <Button
            label={'Добавить'}
            size={'s'}
            disabled={
              !fieldValues.merchant_id
              // ||
              // fieldValues.items.length === services?.length
            }
            onClick={() => {
              const list: IItemsService[] = [
                {
                  service_id: { label: '', id: v4(), groupId: '' },
                  quantity: '',
                },
                ...fieldValues.items,
              ]

              setFieldValues((prev) => {
                return { ...prev, items: list }
              })
            }}
          />
        </div>

        <div className={styles.itemsContainer}>
          {fieldValues.items.map((item, index) => {
            const originService = selectedOriginalServices.find(
              (el) => el.id === item?.service_id.groupId,
            )
            return (
              <ItemsModal
                className={styles.item}
                services={servicesList}
                originService={originService}
                key={item.service_id.id}
                error={error}
                setError={setErorr}
                uuid={String(item.service_id.id)}
                service={item}
                onDelete={onDelete}
                onChange={onModalChange}
                handleSearchServices={(val) => setServicesSearch(val || '')}
              />
            )
          })}
        </div>
        <TextField
          className={styles.textarea}
          label="Коментарий"
          value={fieldValues.comment}
          type="textarea"
          rows={4}
          onChange={(value) => {
            setFieldValues((prev) => {
              return {
                ...prev,
                comment: value || '',
              }
            })
          }}
        />
        <Button
          label={'Отправить'}
          disabled={!(fieldValues.items.length > 0)}
          onClick={handleSubmit}
        />
      </div>
    </Modal>
  )
}

ServicesRenderedModal.displayName = 'ReplaceModal'

export default ServicesRenderedModal
