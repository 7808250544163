import React, { useEffect } from 'react'
import days from 'dayjs'

import styles from './check-registry-modal.module.scss'

import img from './assets/danger.svg'

import { Button } from '@shared/ui/btns/Button'

import { Modal } from '@/components'

import { IRegister, ModalProps } from '@/interfaces'
import { DATE_TIME_VIEW } from '@shared/const/date'

interface ICheckRegistryModalProps extends ModalProps {
  registry: IRegister
  handleConfirm: (registry: IRegister) => void
}

const CheckRegistryModal = (props: ICheckRegistryModalProps) => {
  const { isOpen, onClose, registry, onMount, handleConfirm } = props

  useEffect(() => {
    if (onMount) {
      onMount()
    }
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.check}
      title="Подтвердите реестр"
      img={img}
      imgClass={styles.img}
      size="s"
    >
      <div className={styles.body}>
        <div className={styles.block}>
          <h6>Реестр</h6>
          <span className={styles.bold}>{registry.number}</span>
        </div>
        {registry.cell_number ? (
          <div className={styles.block}>
            <h6>Корзина</h6>
            <span>{registry.cell_number}</span>
          </div>
        ) : null}
        {registry.ship_date ? (
          <div className={styles.block}>
            <h6>Дата отгрузки</h6>
            <span>{days(registry.ship_date).format(DATE_TIME_VIEW)}</span>
          </div>
        ) : null}
        {registry.NameOrganization ? (
          <div className={styles.block}>
            <h6>Контрагент</h6>
            <span>{registry.NameOrganization}</span>
          </div>
        ) : null}
        {registry.courier_id ? (
          <div className={styles.block}>
            <h6>Служба доставки</h6>
            <span>{registry.courier_id}</span>
          </div>
        ) : null}
        {registry.comment ? (
          <div className={styles.comment}>
            <h6>Комментарий</h6>
            <span>{registry.comment}</span>
          </div>
        ) : null}
      </div>
      <div className={styles.btns}>
        <Button
          label="Отмена"
          view="ghost"
          onClick={() => onClose()}
          size="m"
        />
        <Button
          label="Подтвердить"
          view="primary"
          className={styles.primary}
          onClick={() => handleConfirm(registry)}
          size="m"
        />
      </div>
    </Modal>
  )
}

export default CheckRegistryModal
