import React, { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IRouteParams, ISection } from '@pages/DirectoriesPage/types'
import { ICategory } from '@/interfaces'
import { useFilterState } from '@shared/hooks'
import { useDeleteCategory, useGetCategoriesList } from '@/hooks/warehouse/categories'
import { SECTIONS } from '@pages/DirectoriesPage/constants'
import { ConfirmActionModal } from '@/components'
import { generateFilterParams, generateFilters } from './utils/filters'
import { generateTableData } from './utils/table'
import CategoryModal from '../../components/CategoryModal/CategoryModal'
import { DirectoriesLayout } from '@pages/DirectoriesPage/components/DirectoriesLayout/DirectoriesLayout'

interface CategoriesProps extends ISection {
}

const CategoriesList: FC<CategoriesProps> = ({ title }) => {
  const { page = '', section = SECTIONS.categories.value } =
    useParams<IRouteParams>()


  const {
    filterStateDebounced,
    filterState,
    handleChangeFilter,
  } = useFilterState({
    withoutHistory: true, onChangeFilterState: () => {
      setPagination(0)
    },
  })
  const [categories, setCategories] = useState<ICategory[]>([])
  const [pagination, setPagination] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [code, setCode] = useState('')

  const {
    isFetching: isFetchingCategory,
    refetch: refetchCategory,
    data: categoriesData,
  } = useGetCategoriesList(
    generateFilterParams({ page: pagination } || {}),
  )
  const { mutateAsync: deleteDoc } = useDeleteCategory()

  useEffect(() => {
    if (!categoriesData) {
      return
    }

    setCategories(categoriesData.categories)
  }, [categoriesData])

  const handleEdit = (code: string) => {
    setCode(code)
    setOpenModal(true)
  }
  const handleConfirmModal = (code: string) => {
    setOpenConfirmModal(true)
    setCode(code)
  }
  const handleDelete = (code: string) => {
    if (!code) {
      return
    }
    deleteDoc({ code })
      .then((data) => {
        refetchCategory()
      })
      .catch(() => {
      })
      .finally(() => setCode(''))
  }

  const filtersConfig = generateFilters()

  const { rows, columns } = useMemo(() => {
    return generateTableData(
      categories,
      filterStateDebounced?.search,
      handleEdit,
      handleConfirmModal,
    )
  }, [categories, filterStateDebounced?.search])

  return (
    <DirectoriesLayout
      withTable
      withCreate
      withFilter
      withSearch

      title={title}

      // filtersConfig={filtersConfig}
      filterState={filterState}
      handleChangeFilter={handleChangeFilter}

      onCreateBtnClick={() => {
        setCode('')
        setOpenModal(true)
      }}

      isLoading={isFetchingCategory}
      columns={columns}
      rows={rows}
      pagination={categoriesData?.pagination}
      onChangePage={setPagination}
    >
      {openModal ? (
        <CategoryModal
          onClose={() => {
            setCode('')
            setOpenModal(false)
          }}
          isOpen={openModal}
          refetch={refetchCategory}
          categories={categoriesData?.categories || []}
          code={code}
        />
      ) : null}
      {openConfirmModal ? (
        <ConfirmActionModal
          isOpen={true}
          type='warning'
          withPreview={false}
          title={'Вы уверены, что хотите удалить категорию?'}
          size={'min'}
          confirmWithScan={false}
          onClose={() => {
            setCode('')
            setOpenConfirmModal(false)
          }}
          onSubmit={() => {
            if (!code) return
            handleDelete(code)
            setOpenConfirmModal(false)
            setCode('')
          }}
        />
      ) : null}
    </DirectoriesLayout>
  )
}
export default CategoriesList
