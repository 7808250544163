import React, { Ref } from 'react'
import cx from 'classnames'
import styles from './Button.module.scss'
import { Button as ConstaButton, Props } from '@consta/uikit/Button'
import { IconComponent } from '@consta/icons/Icon'

export type ButtonTheme = 'default' | 'white' | 'success' | 'warning' | 'danger'
export type ButtonPropSize = "xs" | "s" | "m" | "l";
export type IconPropSize = "xs" | "s" | "m" | "l";
export type ButtonPropView = "primary" | "clear" | "ghost" | "secondary";
export type ButtonPropForm = "default" | "brick" | "round" | "brickRound" | "roundBrick" | "brickDefault" | "defaultBrick";

export interface ButtonProps extends React.ComponentProps<typeof ConstaButton> {
  theme?: ButtonTheme
  className?: string
  size?: ButtonPropSize;
  view?: ButtonPropView;
  form?: ButtonPropForm;
  disabled?: boolean;
  loading?: boolean;
  label?: string | number;
  onClick?: React.EventHandler<React.MouseEvent>;
  iconLeft?: IconComponent;
  iconRight?: IconComponent;
  onlyIcon?: boolean;
  autoFocus?: boolean;
  type?: "submit" | "reset" | "button" | undefined;
  iconSize?: IconPropSize;
}

export const Button = React.forwardRef((props: ButtonProps, ref: Ref<any>) => {
  const {
    className,
    theme = 'default',
    ...restProps
  } = props
  return (
    <ConstaButton
      {...restProps}
      className={cx(styles.Button, styles[`theme-${theme}`], className)}
      ref={ref}
    />
  )
})

