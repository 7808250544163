import type { FC } from 'react'
import React, { useEffect, useState } from 'react'

import { DeleteGateModalProps } from './types'
import { Button } from '@shared/ui/btns/Button'

import styles from './DeleteGateModal.module.scss'

const DeleteGateModal: FC<DeleteGateModalProps> = ({
  gate,
  onClose,
  onSubmit = () => {},
}) => {
  const [id, setId] = useState('')

  useEffect(() => {
    if (!gate) {
      return
    }

    setId(gate.id)
  }, [gate])
  const handleSubmit = () => {
    onSubmit(id)

    onClose()
  }
  return (
    <div className={styles.container}>
      <div className={styles.buttonsRow}>
        <Button
          label={'Отмена'}
          view={'ghost'}
          type={'button'}
          onClick={onClose}
        />

        <Button label={'Удалить'} type={'submit'} onClick={handleSubmit} />
      </div>
    </div>
  )
}
export default DeleteGateModal
