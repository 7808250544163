import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import styles from './SelectBatchModal.module.scss'
import { Button } from '@shared/ui/btns/Button'
import { InputWithDateMask, InputWrapper, Modal, SelectList } from '@/components'
import { SkuItemPreview } from '@entities/Sku/ui/SkuItemPreview'
import { useCreateBatch } from '@/hooks'
import { IBatch, ISelectListOption, ExpDateTypesEnum, useCreateBatchResult } from '@/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { IconCalendar } from '@consta/icons/IconCalendar'
import { DATE_REQUEST_FORMAT, DATE_VIEW } from '@shared/const/date'
import { Flex } from '@shared/ui/Flex'
import { Text } from '@shared/ui/Text'
import { SelectBatchModalProps } from '../model/types'
import { useNotifications } from '@shared/providers/NotificationProvider'
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup'
import { useExpDate, dateTypesOptions } from '@shared/hooks/useExpDate'


export const SelectBatchModal = (props: SelectBatchModalProps) => {
  const {
    remainingClientBatches = {},
    addBatchesWithoutBackend = [],
    onlyCreate,
    isOpen,
    onSubmit,
    expdate,
    item,
    onClose,
    defaultSelectedBatchId,
  } = props

  const notification = useNotifications()

  const createBatchMutation = useCreateBatch({ itemId: item?.id ?? '' })

  const [selectedBatch, setSelectedBatch] = useState<ISelectListOption | null>(
    null,
  )
  const [batchNum, setBatchNum] = useState<TextFieldPropValue>(null)
  const [batchDate, setBatchDate] = useState<TextFieldPropValue>(null)

  const [batchesOptions, setBatchesOptions] = useState<ISelectListOption[]>([])


  const {
    isExpirationDate,
    isDateOfManufacture,
    dateType,
    setDateType,
    dateErrorText,
    setDateErrorText,
    checkDateIsValid,
    checkDateIsCorrect,
    getExpDateAndManufactureDateByDate,
    getDateOfManufactureByExpDate,
    getExpDateByDateOfManufacture
  } = useExpDate()



  useEffect(() => {
    if (batchDate) {
      const expDate = getExpDateByDateOfManufacture({ date: batchDate, expiryMonths: item?.expiration_months })
      let correctBatchDate = isDateOfManufacture
        ? checkDateIsValid(expDate) ? expDate.format(DATE_VIEW) : '-'
        : batchDate
      setBatchNum(`${item?.external_id} ${correctBatchDate}`)
    }
  }, [batchDate, dateType])

  useEffect(() => {
    if (item) {
      const skuRemainingBatchesIds = remainingClientBatches[item.id]
      const options =
        item?.batches
          ?.filter((batch) => {
            if (skuRemainingBatchesIds) {
              return skuRemainingBatchesIds.includes(batch.id) || addBatchesWithoutBackend.includes(batch.id)
            }
            return true
          })
          .map((b) => {
            return {
              label: (
                <span className={styles.batchSelect}>
                  {dayjs(b.exp_date).format(DATE_VIEW)} <span>{b?.num}</span>
                </span>
              ),
              id: b.id,
              value: b.id,
            }
          }) ?? []
      setBatchesOptions(options)

      /**
       * Если у товара есть плановая партия от клиента и она осталась одна,
       * то выбираем ее
       */
      const optionIncludesPlanBatch = skuRemainingBatchesIds?.includes(
        options[0]?.id,
      )
      if (options.length === 1 && optionIncludesPlanBatch) {
        handleChoiceBatch(options[0])
        return
      }

      if (!options.length) {
        return
      }
      /** Дефолтно выбранный батч */
      const defaultBatchOption = options.find((option) => option.id === defaultSelectedBatchId)
      if (defaultBatchOption) {
        setSelectedBatch(defaultBatchOption)
        return
      }
      setSelectedBatch(options[0])
    }
  }, [])

  const handleChoiceBatch = (selectedBatch: ISelectListOption | null) => {
    const findBatch = item?.batches?.find(
      (b: IBatch) => b.id === selectedBatch?.value,
    )

    if (findBatch) {
      onSubmit(findBatch)
    } else {
      console.error('Какая ошибка, которую не предусмотрели')
    }
  }

  const handleAddNewBatch = async () => {
    const newBatch = {
      num: batchNum ? String(batchNum) : undefined,
      exp_date: batchDate
        ? isDateOfManufacture
          ? getExpDateByDateOfManufacture({ date: batchDate, expiryMonths: item.expiration_months }).format(DATE_REQUEST_FORMAT)
          : dayjs(batchDate, DATE_VIEW).format(DATE_REQUEST_FORMAT)
        : undefined
    }
    /* Если нашли партию у товара, но пользователь пытается ее добавить */
    const foundBatch = item.batches.find(b => b.num === batchNum)
    if (foundBatch && (foundBatch?.exp_date !== newBatch.exp_date)) {
      notification?.show('alert', `
        Ошибка сроков годности, для этого номера партии ожидается годен до - ${foundBatch?.exp_date || 'другая'}
      `)
      return
    }
    if (foundBatch) {
      onSubmit(foundBatch, true, true)
      return
    }

    createBatchMutation
      .mutateAsync({
        batch: newBatch,
      })
      .then((result: useCreateBatchResult) => {
        onSubmit({ id: result.id, ...newBatch }, true)
      })
      .catch((error: any) => {
      })
  }
  const SectionTitle = (props: {text: string}) =>
    <Text
      as={'h3'}
      size={'m'}
      view={'secondary'}
      children={props.text}
    />
  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.selectBatch}
      withClose
      onClose={onClose}
    >
      <Flex>
        {item && (
          <SkuItemPreview
            image={item.image}
            title={item.title}
            barcode={item.barcode_used || ''}
          />
        )}
      </Flex>

      <div className={styles.body}>
        {!onlyCreate ? (
          <div className={styles.left}>
            <SectionTitle text={'Выберите партию из списка'}/>
            <SelectList
              options={batchesOptions}
              value={selectedBatch}
              onChange={(value) => setSelectedBatch(value)}
              className={styles.select}
            />
            <Button
              label='Выбрать'
              className={styles.btn}
              disabled={!selectedBatch}
              onClick={() => handleChoiceBatch(selectedBatch)}
            />
          </div>
        ) : null}
        <div className={styles.right}>
          <SectionTitle text={'Новая партия'} />
          <div className={styles.newBatch}>
            {expdate ? (
              <InputWithDateMask
                value={batchDate}
                id={'dateTo'}
                handleChange={(value: TextFieldPropValue) => {
                  setBatchDate(value)
                }}
                className={styles.itemInput}
                placeholder={`${dateType.placeholder} ${DATE_VIEW}`}
                rightSide={IconCalendar}
                label={'Годен до'}
                size={'s'}
                labelSlot={
                  <ChoiceGroup
                    value={dateType}
                    items={dateTypesOptions}
                    getItemLabel={(optionItem) => optionItem.label}
                    getItemDisabled={(optionItem) => (
                      (optionItem.value === ExpDateTypesEnum.dateOfManufacture)
                      && (item.expiration_months === undefined)
                    )}
                    onChange={(value) => setDateType(value)}
                    name={'dateTypes'}
                    multiple={false}
                    size={'xs'}
                    width={'full'}
                  />
                }
              />
            ) : null}

            <InputWrapper
              value={batchNum}
              id={'batchNum'}
              size={'s'}
              handleChange={(value: TextFieldPropValue) =>
                setBatchNum(String(value))
              }
              className={styles.itemInput}
              placeholder={'№ партии'}
              label={'Номер партии'}
            />
          </div>
          <Button
            label='Добавить партию'
            className={styles.btn}
            disabled={expdate ? !batchNum || !batchDate : !batchNum}
            onClick={handleAddNewBatch}
          />
        </div>
      </div>
    </Modal>
  )
}