import { createIcon } from '@consta/icons/Icon'
import { SVGProps } from 'react'

const IconSizeL = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M22 9.5L12 2.5L2 9.5V21.5C2 22.0523 2.44772 22.5 3 22.5H6V11.5C6 10.9477 6.44772 10.5 7 10.5H17C17.5523 10.5 18 10.9477 18 11.5V22.5H21C21.5523 22.5 22 22.0523 22 21.5V9.5Z" fillOpacity="0.6"/>
    <path d="M16 22.5V20.5H8V22.5H16Z" fillOpacity="0.6"/>
    <path d="M8 14.5V12.5H16V14.5H8Z" fillOpacity="0.6"/>
    <path d="M8 16.5V18.5H16V16.5H8Z" fillOpacity="0.6"/>
  </svg>
)

export const IconWarehouse = createIcon({
  l: IconSizeL,
  m: IconSizeL,
  s: IconSizeL,
  xs: IconSizeL,
  name: "IconWarehouse",
})