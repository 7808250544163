import { useQuery } from 'react-query'
import api from '@shared/api'

import { ApiReqResult, ISku } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'
import { convertObjectToURLParams } from '@shared/helpers'
import { PackagingSettingsDto, PackagingSettingsFormParams, PackagingSettingsOverrideDto } from '@/interfaces/packaging'

interface Params extends PackagingSettingsFormParams {
}
const getPackagingSettings = async (params: Params) => {
  const paramsStr = convertObjectToURLParams(params)
  const result = await api.get(`/packaging/setting${paramsStr}`)
  if (!result.data?.success) {
    throw result
  }
  return result.data
}

interface Result extends ApiReqResult {
  skus: ISku[]
  settings: PackagingSettingsDto[]
  settings_override: PackagingSettingsOverrideDto[]
}

const useGetPackagingsSettings = (
  params: Params,
  options?: UseQueryOptions<Params, any, Result>,
) => {
  return useQuery<Params, any, Result>(
    [`getPackagingSettings`, params],
    () => getPackagingSettings(params),
      options
  )
}

export default useGetPackagingsSettings
