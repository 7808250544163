import { Column, makeStateUpdater, Row, RowData, Table, TableFeature } from '@tanstack/react-table'
import { CopyOptions, CopyState, CopyTableState, CopyTupleType, SYMBOL_ALL_ITEMS } from './types'
import {
  ACTION_ID,
  COLUMN_EXPANDER_ID,
  COLUMN_NUMERIC_ID,
  COLUMN_SELECTION_ID,
} from '@/components/Table/TanStackTable/lib/const'
import { Cell } from '@tanstack/table-core/src/types'
import { getRowIdForNumeric } from '@/components/Table/TanStackTable/lib/helpers'
import { useMemo } from 'react'


const getInitialState = (): CopyTupleType => ['', '']
export const getDefaultCopyState = (): CopyState => ({
  startForCopyID: getInitialState(), // [columnStartId, rowStartId]
  endForCopyID: getInitialState(), // [columnEndId, rowEndId]
})

export const CopyFeature: TableFeature<any> = {

  getInitialState: (state): CopyTableState => {
    return {
      copyState: getDefaultCopyState(),
      copyShowCopyComplete: false,
      ...state,
    }
  },

  getDefaultOptions: <TData extends RowData>(
    table: Table<TData>,
  ): CopyOptions => {
    return {
      enableCopy: true,
      onCopyStateChange: makeStateUpdater('copyState', table),
      onCopyShowCopyCompleteChange: makeStateUpdater('copyShowCopyComplete', table),
    } as CopyOptions
  },

  createTable: <TData extends RowData>(table: Table<TData>): void => {

    table.setCopyState = (updater) => table.options.onCopyStateChange?.(updater)
    table.setCopyShowCopyComplete = (updater) => table.options.onCopyShowCopyCompleteChange?.(updater)

    table.toggleShowCopyComplete = () => new Promise((resolve, reject) => {
      table.setCopyShowCopyComplete(true)
      setTimeout(() => {
        table.setCopyShowCopyComplete(false)
        resolve(true)
      }, 300)
    })

    table._notification = (type: string, text: string) => {
      window?.showNotification(type, text)
    }
    table._copyToClipboard = (values: string) => {
      /* запись в клипборд */
      navigator.clipboard.writeText(values)
    }

    table._getCopyStateColumnHasSpecialSymbols = () => {
      const {
        copyState: {
          startForCopyID,
          endForCopyID,
        },
      } = table.getState()
      return [startForCopyID[1], endForCopyID[1]].includes(SYMBOL_ALL_ITEMS)
    }

    table._getCopyPrepareData = () => {
      const {
        copyState: {
          startForCopyID: [columnStartId, rowStartId],
          endForCopyID: [columnEndId, rowEndId],
        },
      } = table.getState()


      const { rows } = table.getRowModel()

      const columns = table.getAllColumns()

      return {
        columnStartId,
        rowStartId,
        columnEndId,
        rowEndId,
        rows,
        columns,
      }
    }

    table.copyStateIsEmpty = () => {
      const {
        copyState: {startForCopyID, endForCopyID}
      } = table.getState()
      return ![startForCopyID, endForCopyID].flat().filter(Boolean)?.length
    }
    table.isSelectAll = () => {
      const {
        copyState: {startForCopyID, endForCopyID}
      } = table.getState()
      return [...startForCopyID, ...endForCopyID].every(item => item === SYMBOL_ALL_ITEMS)
    }

    table.getCopySelectedColumns = () => {
      const {
        columnStartId,
        columnEndId,
        columns,
      } = table._getCopyPrepareData()

      const selectedColumns = getSelectedItemsByIdsRange(columns, columnStartId, columnEndId)
      const selectedColumnsCanCopy = selectedColumns.filter(column => column.getCanCopy())
      const selectedColumnsIds = selectedColumnsCanCopy.map(el => el.id)

      return {
        columns: selectedColumnsCanCopy,
        selectedColumnsIds: selectedColumnsIds,
      }
    }

    table.getCopySelectedRows = () => {
      const {
        rowStartId,
        rowEndId,
        rows,
      } = table._getCopyPrepareData()

      // Получение индексов диапазона для строк
      const allRowsSelected = rowStartId === SYMBOL_ALL_ITEMS && rowEndId === SYMBOL_ALL_ITEMS
      const correctRowStarId = allRowsSelected || rowStartId === SYMBOL_ALL_ITEMS ? '0' : rowStartId
      const correctRowEndId = allRowsSelected ? rows[rows.length - 1]?.id : rowEndId === SYMBOL_ALL_ITEMS ? '0' : rowEndId

      const selectedRows = getSelectedItemsByIdsRange(rows, correctRowStarId, correctRowEndId)
      const selectedRowsIds = selectedRows.map(el => el.id)
      return {
        rows: selectedRows,
        selectedRowsIds: selectedRowsIds,
      }
    }

    table.getCopySelectedRowsColumns = () => {
      return {
        ...table.getCopySelectedColumns(),
        ...table.getCopySelectedRows(),
      }
    }

    table.resetCopyState = () => {
      table.setCopyState(getDefaultCopyState())
    }

    table.copyAllTableWithNested = () => {
      table.selectAllTableToCopy()
      table.copyTableData(table.getAllVisibleColumnsToCopy(), table.getRowModel().flatRows)
    }
    /* без вложенности */
    // table.copyAllTable = () => {
    //   table.selectAllTableToCopy()
    //   table.copyTableData(table.getAllVisibleColumnsToCopy(), table.getRowModel().rows)
    // }

    // table.copyСolumn = () => {
    //
    // }
    // table.copyRow = () => {
    //
    // }

    table.copySelected = () => {
      const {
        rows: selectedRows,
        columns: selectedColumns,
      } = table.getCopySelectedRowsColumns()
      setTimeout(() => {
        table.copyTableData(selectedColumns, selectedRows)
      }, 100)
    }

    table.selectAllTableToCopy = () => {
      table.setCopyState({
        startForCopyID: [SYMBOL_ALL_ITEMS, SYMBOL_ALL_ITEMS],
        endForCopyID: [SYMBOL_ALL_ITEMS, SYMBOL_ALL_ITEMS],
      })
    }

    table.getAllVisibleColumnsToCopy = () => {
      return table.getVisibleLeafColumns().filter(column => column.getCanCopy())
    }

    table.copyTableData = (columnsToCopy: Column<TData>[], rowsToCopy: Row<TData>[]) => {
      if ((!columnsToCopy.length && !rowsToCopy.length) || !table.options.enableCopy) {
        return
      }

      const selectedColumns: Column<TData>[] = columnsToCopy
      const selectedColumnsKeys: string[] = selectedColumns
        .map(column => column.columnDef.meta?.copyKey ?? column.id)

      /* Получаем значения для строк */
      let resultRows = getRowsForCopy(rowsToCopy, selectedColumnsKeys)

      /* Если есть выделенные хедеры столбцов, то подставляем их для копирования */
      const columnIsSelected: boolean = table._getCopyStateColumnHasSpecialSymbols()
      if (columnIsSelected) {
        const columnKeysStr = selectedColumns.map(col => col.columnDef.meta?.headerText ?? col.columnDef.header ?? '').join('\t')
        resultRows = [columnKeysStr, ...resultRows]
      }
      table._copyToClipboard(resultRows.join('\n'))
      table.toggleShowCopyComplete()
        .then(() => {
          table._notification('success', 'Скопировано')
          table.resetCopyState()
        })
    }


    table.handleCopyMouseOverRowById = (columnId: string, rowId: string) => {
      if (!table.options.enableCopy) return
      const copyState = table.getState().copyState
      table.setCopyState({ ...copyState, endForCopyID: [columnId, rowId] })
    }
    table.handleCopyMouseDownRowById = (columnId: string, rowId: string) => {
      if (!table.options.enableCopy) return
      const copyState = table.getState().copyState
      const newState: CopyState = {
        ...copyState,
        endForCopyID: [columnId, rowId],
        startForCopyID: [columnId, rowId],
      }
      table.setCopyState(newState)
    }
  },

  createColumn: <TData extends RowData>(column: Column<TData>, table: Table<TData>): void => {
    column.checkActionColumn = () => {
      const columnsId = column.id
      return ({
        isActionColumn: columnsId.includes(ACTION_ID),
        isExpanderColumn: columnsId.includes(COLUMN_EXPANDER_ID),
        isSelectionColumn: columnsId.includes(COLUMN_SELECTION_ID),
      })
    }

    column.getCanCopy = () => {
      const { isActionColumn } = column.checkActionColumn()
      return !isActionColumn
    }

    column.selectColumnToCopy = () => {
      table.setCopyState({
        startForCopyID: [column.id, SYMBOL_ALL_ITEMS],
        endForCopyID: [column.id, SYMBOL_ALL_ITEMS],
      })
    }

    column.getColumnIsSelectionToCopy = (selectedColumnsIds, fromHeader = true) => {
      if (!column.getCanCopy()) return false
      if (table.isSelectAll()) return true
      /* Для определения в строке */
      const columnSelected = selectedColumnsIds.includes(column.id)
      if (!fromHeader) return columnSelected

      /* Для определения в столбце */
      const anyRowRangeIdHasSpecialSymbol = table._getCopyStateColumnHasSpecialSymbols()
      return columnSelected && anyRowRangeIdHasSpecialSymbol
    }
  },

  createRow: <TData extends RowData>(row: Row<TData>, table: Table<TData>): void => {
    row.selectRowToCopy = () => {
      table.setCopyState({
        startForCopyID: [SYMBOL_ALL_ITEMS, row.id],
        endForCopyID: [SYMBOL_ALL_ITEMS, row.id],
      })
    }

    row.selectRowWithDepthToCopy = () => {
      /* todo нужно проверять глубину строки */
      // table.setCopyState({
      //   startForCopyID: [column.id, SYMBOL_ALL_ITEMS],
      //   endForCopyID: [column.id, SYMBOL_ALL_ITEMS],
      // })
    }
  },

  createCell: <TData extends RowData>(
    cell: Cell<TData, unknown>,
    column: Column<TData>,
    row: Row<TData>,
    table: Table<TData>,
  ): void => {
    /* @ts-ignore */ // todo понять почему не находит метод
    cell.getCellIsSelectionToCopy = (selectedColumnsIds: string[], selectedRowsIds: string[]) => {
      if (!column.getCanCopy()) return false
      if (table.isSelectAll()) return true
      return (column.getColumnIsSelectionToCopy(selectedColumnsIds, false) && selectedRowsIds.includes(row.id))
    }
  },
}


/* Вычисляем список строк или столбцов по id начала и конца */
export const getSelectedItemsByIdsRange = <T extends { id: string }>(items: T[], startId: string, endId: string) => {
  const { startIndex, endIndex } = getStartEndIndexesByIds(items, startId, endId)
  const { min, max } = getMinMax(startIndex, endIndex)
  return items.slice(min, max + 1)
}


/*
* Вычисляем минимальное и максимальное значение индексов
* чтобы можно было выделять с любой позиции
*/
export const getMinMax = (firstIndex: number, secondIndex: number) => {
  return {
    min: Math.min(firstIndex, secondIndex),
    max: Math.max(firstIndex, secondIndex),
  }
}

/* Получаем минимальный и максимальный индекс по Id за один проход по массиву */
export const getStartEndIndexesByIds = <T extends { id: string }>(items: T[], startId: string, endId: string) => {
  let startItemIndex: number = -1
  let endItemIndex: number = -1
  for (let i: number = 0; i < items.length; i++) {
    const item = items[i]
    if ((startItemIndex !== -1) && (endItemIndex !== -1)) break
    if (item?.id === startId) {
      startItemIndex = i
    }
    if (item?.id === endId) {
      endItemIndex = i
    }
  }
  return {
    startIndex: startItemIndex,
    endIndex: endItemIndex,
  }
}


/**
 *  Получаем значения выделенных ячеек строки для копирования
 */
export const generateRowCopyValue = <TRowData>(row: Row<TRowData>, columnsKeys: string[]) => {
  return columnsKeys
    .map(columnKey => {
      if (columnKey === COLUMN_NUMERIC_ID) return ` ${getRowIdForNumeric(row.id)}`
      return row.original[columnKey] ?? ' '
    })
    .join(' \t ')
}
/**
 *  Получаем значения строк для копирования
 */
export const getRowsForCopy = <TRowData>(rows: Row<TRowData>[], selectedColumnsKeys: string[]): string[] => {
  return rows
    .map((row) => generateRowCopyValue(row, selectedColumnsKeys))
}