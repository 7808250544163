import React from 'react'
import { ICellSkuStock, IDefectType, IMovingItem, ISku, ITableColumn, ITableRow } from 'src/interfaces'
import { ComboboxWrapper, InputWrapper } from 'src/components'
import { IItemsMoving, StockType } from './types'
import { v4 } from 'uuid'
import { Sku } from 'src/hooks/placement/useGetProductRemains'
import { getBatchNameValue } from '@pages/OperationsAndDocumentsPage/helpers/common'
import styles from './MovingModalNew.module.scss'
import { getBatchById } from '@entities/Sku/lib/batch'

export const generateColumns = (cellIsDefect: boolean): ITableColumn[] => {
  const columns = [
    {
      key: 'title',
      title: 'Наименование',
      title_txt: 'Наименование',
      gridCellWidth: '6fr'
    },
    {
      key: 'sku_batch_id',
      title: 'Партия',
      title_txt: 'Партия',
      gridCellWidth: '2fr'
    },
    {
      key: 'quantityFact',
      title: 'Кол-во',
      title_txt: 'Кол-во',
    },

    {
      key: 'quantity',
      title: 'Значение',
      title_txt: 'Значение',
    },
  ]
  if (cellIsDefect) {
    columns.push({
      key: 'defectType',
      title: 'Тип брака',
      title_txt: 'Тип брака',
    })
  }

  return columns
}

export const generateRows = (
  items: IItemsMoving[],
  skus: Sku[],
  handleChange: (formId: string, id: string, key: string, value: string | null) => void,
  formId: string,
  defectTypes: IDefectType[],
): ITableRow[] => {

  const defectTypesOptions = defectTypes.map((type) => ({label: type.title, id: type.id}))
  return items.map((movingItem) => {
    const sku = skus.find((s) => s.id === movingItem.sku.sku_id)
    const batch = getBatchById(sku?.batches, movingItem.sku.sku_batch_id)

    return {
      id: movingItem.id,
      sku_id: movingItem.sku.sku_id || '',
      sku_batch_id: !batch ? '-' : getBatchNameValue(batch, false),
      title: movingItem.sku.title || '',
      skuBarcodes: sku?.barcodes || [],
      skuArticle: sku?.article || '',
      quantityFact: String(+movingItem.sku.quantityFact - +movingItem.sku.reserved),
      defectType: defectTypes?.length ? (
        <ComboboxWrapper
          items={defectTypesOptions}
          value={defectTypesOptions.find((type) => type.id === movingItem.defect_type_id)}
          multiple={false}
          className={styles.cells}
          size={'m'}
          onChange={(value) => {
            handleChange(formId, movingItem.id, 'defect_type_id', value?.id as string | null)
          }}
        />
      ) : null,
      quantity: (
        <InputWrapper
          value={String(movingItem.quantity)}
          isNumber
          id={movingItem.id}
          size='s'
          handleChange={(value) => {
            if (value?.length === 6) {
              return
            }

            handleChange(formId, movingItem.id, 'quantity', value || '')
          }}
        />
      ),
      editMode: movingItem.editMode,
      disable: movingItem.disable,
    }
  })
}

export const generateStateItems = (data: StockType) => {
  const originSkus = data.skus
    .map((sku: ISku) => {
      const skuHasBatches = sku.batches?.length
      const batches = sku.batches
      if (!skuHasBatches) {
        const id = v4()
        const quantity = data.stock.find(
          (el: ICellSkuStock) => el.sku_id === sku.id,
        )
        return {
          id: id,
          sku: {
            sku_id: sku.id || '',
            sku_batch_id: '',
            title: sku.title || '',
            quantityFact: quantity?.quantity || '0',
            reserved: quantity?.reserved || '0',
          },
          quantity: '0',
          editMode: false,
          disable: false,
        }
      } else {
        return batches.map((batch) => {
          const idBatch = v4()
          const quantity = data.stock.find(
            (el: ICellSkuStock) => (el.sku_batch_id === batch.id) && (el.sku_id === sku.id),
          )
          return {
            id: idBatch,
            sku: {
              sku_id: sku.id || '',
              sku_batch_id: batch.id || '',
              title: sku.title || '',
              quantityFact: quantity?.quantity || '0',
              reserved: quantity?.reserved || '0',
            },
            quantity: '0',
            editMode: false,
            disable: false,
          }
        })
      }
    })
    .flat()
    .filter((item) => +item.sku.quantityFact - +item.sku.reserved !== 0)
  return originSkus
}
export const generateAddStateItems = (
  data: StockType,
  addMoveItems: IMovingItem[],
  cellsData?: { from: string, to: string }
) => {
  const originSkus = data.skus
    .map((sku: ISku) => {
      if (!sku.batch_accounting) {
        const id = v4()
        const addItem = addMoveItems.find((el) =>
          (el.sku_id === sku.id)
          && (el.from_cell_barcode === cellsData.from)
          && (el.to_cell_barcode === cellsData.to)
        )
        const quantity = data.stock.find(
          (el: ICellSkuStock) => el.sku_id === sku.id,
        )
        return {
          id: id,
          sku: {
            sku_id: sku.id || '',
            sku_batch_id: '',
            title: sku.title || '',
            quantityFact: quantity?.quantity || '0',
            reserved: quantity?.reserved || '0',
          },
          quantity: addItem ? String(addItem.quantity) : '0',
          editMode: false,
          disable: false,
        }
      } else {
        const batches = sku.batches
        return batches.map((batch) => {
          const idBatch = v4()
          const quantity = data.stock.find(
            (el: ICellSkuStock) => el.sku_batch_id === batch.id,
          )
          const addItem = addMoveItems.find(
            (el) =>
              (el.sku_id === sku.id)
              && (el.sku_batch_id === batch?.id)
              && (el.from_cell_barcode === cellsData.from)
              && (el.to_cell_barcode === cellsData.to),
          )

          return {
            id: idBatch,
            sku: {
              sku_id: sku.id || '',
              sku_batch_id: batch.id || '',
              title: sku.title || '',
              quantityFact: quantity?.quantity || '0',
              reserved: quantity?.reserved || '0',
            },
            quantity: addItem ? String(addItem.quantity) : '0',
            editMode: false,
            disable: false,
          }
        })
      }
    })
    .flat()
    .filter((item) => +item.sku.quantityFact - +item.sku.reserved !== 0)
  return originSkus
}
