import { useMemo } from 'react'
import { generateExtraColumns } from '@/components/Table/TanStackTable/lib/columns'
import { TableRowType, TanStackTableProps } from '@/components/Table/TanStackTable'
import { range } from '@shared/helpers/arr'


export const usePreManageTable = <TRowData extends TableRowType, TRowValue>(props: TanStackTableProps<TRowData, TRowValue>) => {
  const {
    withNumerableRows,
    withRowSelection,
    data,
    columns,
    totalRows,
    pagination
  } = props

  const currentPage = (pagination?.size || 0) * (pagination?.page || 0)

  /* Закрепленные строки с итогами  */
  const pinnedTotalRows: TRowData[] = useMemo(() =>
      (totalRows || []).map(row => ({ ...row, enableRowPinning: true })),
    [totalRows],
  )
  const pinnedTotalRowsIndexes: string[] = useMemo(() =>
      range(pinnedTotalRows.length, 0).map(pinnedIndex => `${pinnedIndex + data.length}`),
    [pinnedTotalRows],
  )

  /* Все строки включая итоговые */
  const allInitialRows: TRowData[] = useMemo(() => [...data, ...pinnedTotalRows], [data, pinnedTotalRows])

  /* проверяем наличие подстрок для столбца */
  const tableHasSubItems: boolean = useMemo(() =>
      Boolean(allInitialRows.find(row => row.subItems)),
    [allInitialRows],
  )

  /* Обработка встроенных столбцов */
  const columnsWithExtra = useMemo(() =>
      generateExtraColumns({ columns, tableHasSubItems, withRowSelection, withNumerableRows, currentPage }),
    [columns, tableHasSubItems, withRowSelection, withNumerableRows, currentPage],
  )

  /* Не отображаемые столбцы по умолчанию */
  const columnsDefaultHidden = useMemo(() =>
      columnsWithExtra.reduce((acc, column, i) => {
        if (column.meta?.defaultHidden) return { ...acc, [column.id]: false }
        return acc
      }, {}),
    [columnsWithExtra],
  )

  return {
    pinnedTotalRows,
    pinnedTotalRowsIndexes,
    allInitialRows,
    tableHasSubItems,
    columnsWithExtra,
    columnsDefaultHidden
  }
}