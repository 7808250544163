import { ICommonDocument } from '../documents'
import { IAddress, IOrderCustomer, IPriceData, IShippingFormItem, OrderStatusEnum } from '../order'
import { IUserData } from '../user'
import { IMerchant } from '../acceptance'
import { CommonSerialNumberFilled, IDimensionsObj, ISerialNumber } from '../other'
import { RelabelingTypes, ShippingOptionsType } from '../enums'
import { palletStatuses } from '../pallet'
import { IWorkspace } from '../warehouse/warehouse'

export type Dimensions = {
  max_place_weight: number,
  max_transport_place_dimensions: {
    depth: number,
    height: number,
    width: number
  },
  max_transport_place_weight: number
}


export interface OrderPackingV2 extends ICommonDocument {
  packing_limits?: Dimensions
  common_options: OrderCommonOptionsV2
  ad_attachments: IAdAttachmentV2[]
  cancel_requested: boolean
  customer: IOrderCustomer
  delivery: DeliveryV2
  items: OrderItemPackingV2[]
  operative_comment: string
  crossdocking_options: CrossdockingOptions
  packing_options: OrderPackingOptionsV2
  packing_status: PackingStatusEnum
  shipping_form: IShippingFormItem[],
  payment_type: PaymentTypeEnum
  places: OrderPlaceV2[]
  relabeling_options: RelabelingOptions
  sorting_number: number
  tracking_number: string
  transport_places: TransportPlaceV2[]
  pallet_id: string
  join_code: string
  status: OrderStatusEnum
}

export enum PaymentTypeEnum {
  COD_CASH = "COD_CASH",
  COD_CARD = "COD_CARD",
  ONLINE = "ONLINE",
  NO_COD = "NO_COD",
}
export enum PackingStatusEnum {
  AWAITING = "AWAITING",
  PACKING_ORDER_PLACES = "PACKING_ORDER_PLACES",
  PACKING_ORDER_PLACES_COMPLETION = "PACKING_ORDER_PLACES_COMPLETION",
  EMPTY_TRANSPORT_PLACES_CREATION = "EMPTY_TRANSPORT_PLACES_CREATION",
  PACKING_TRANSPORT_PLACES_COMPLETION = "PACKING_TRANSPORT_PLACES_COMPLETION",
  PACKING_TRANSPORT_PLACES = "PACKING_TRANSPORT_PLACES",
  RELABELING = "RELABELING",
  FINISHED = "FINISHED",
  PAUSED = "PAUSED",
}

export interface IAdAttachmentV2 {
  comment?: string
  quantity: number
  sku_id: string
}

export interface DeliveryV2 {
  address: IAddress
  delivery_contract: DeliveryContractV2
  shipping_date_plan: string
}

export interface DeliveryContractV2 {
  code: string
  delivery_company: DeliveryCompanyV2
  delivery_company_product: DeliveryCompanyProductV2
  dlv_id: string
  first_mile_transfer_type: "PICKUP" | "DROP_OFF"
  min_days_before_expiration_date: number
  title: string
}

export interface DeliveryCompanyV2 {
  code: string
  logo: string
  is_fbo: boolean
  is_fbs: boolean
  preferred_barcode_type: string
  title: string
}

export interface DeliveryCompanyProductV2 {
  code: string
  title: string
}

export interface OrderItemPackingV2 {
  highlight?: boolean
  insurance: number
  marketplace_sku_id: string
  packing_options: OrderItemPackingOptionsV2
  price_data: IPriceData
  quantity: number
  sku_id: string
}

export interface OrderCommonOptionsV2 {
  auto_exit: boolean
  require_print_docs: boolean
}

export interface OrderItemPackingOptionsV2 {
  dimensions_and_weight: OptionsDimensionsAndWeightV2
  instruction: OptionsInstructionV2
  packaging: OptionsPackagingV2
  serial_numbers?: OptionsSerialNumberV2[]
  sku_batch: OptionsSkuBatchV2
}

export interface OptionsDimensionsAndWeightV2 {
  dimensions_required: boolean
  weight_required: boolean
}

export interface OptionsInstructionV2 {
  id: string
  required: boolean
}


export interface OptionsPackagingPackagigns {
  id: string,
  quantity: number
}
export interface OptionsPackagingV2 {
  ask: boolean
  required: boolean
  packagings?: OptionsPackagingPackagigns[]
}

export interface OptionsSerialNumberV2 {
  required: boolean
  template: ISerialNumber
}

export interface OptionsSkuBatchV2 {
  exp_date_required: boolean
}

export interface CrossdockingOptions {
  supply_id?: string
}
export interface OrderPackingOptionsV2 {
  instruction: OrderPackingOptionsInstructionV2

  packing_type: ShippingOptionsType

  require_place_weight: boolean
  completion_packing_available: boolean
  additional_sku_sticker_quantity: number
}

export interface OrderPackingOptionsInstructionV2 {
  html: string
  required: boolean
  sound_url: string
}

export enum OrderPlaceStatusEnum {
  PACKING = 'PACKING',
  PACKED = 'PACKED',
}
export interface OrderPlaceV2 {
  activated: boolean
  barcode: string
  comment: string
  create_ts: string
  date: string
  dimensions: IDimensionsObj
  executor: IUserData
  external_id: string
  id: string
  items: OrderPlaceItemV2[]
  merchant: IMerchant
  packaging_id: string
  pallet_id: string
  status: OrderPlaceStatusEnum
  update_ts: string
  uuid: string
  warehouse_comment: string
  weight: number
  index: number
  workspace: IWorkspace
  relabeled: boolean
}

export interface OrderPlaceItemV2 {
  id: string
  highlight?: boolean
  barcode_used: string
  packaging_id?: string
  packed_at: string
  quantity: number
  serial_numbers: CommonSerialNumberFilled[]
  sku_id: string
  status: string
  expiration_date?: string
}

export interface RelabelingOptions {
  relabeling_type: RelabelingTypes
}

export interface TransportPlaceV2 {
  activated: boolean
  barcode: string
  comment: string
  create_ts: string
  date: string
  dimensions: IDimensionsObj
  executor: IUserData
  external_id: string
  id: string
  merchant: IMerchant
  pallet_id: string
  places: string[]
  status: palletStatuses
  update_ts: string
  uuid: string
  warehouse_comment: string
  weight: number
  index: number
  workspace: IWorkspace
  relabeled: boolean
}

export interface TransportPlaceV2WithPlaces extends Omit<TransportPlaceV2, 'places'> {
  places: OrderPlaceV2[]
}
