import { ICombobox } from '@/interfaces'


export const generateStatusesOptions = (statuses: Record<string, string>): ICombobox[] => {
  return Object.keys(statuses).map(statusKey => ({
    label: statuses[statusKey],
    id: statusKey,
    value: statusKey,
  }))
}

// todo не придумал как это сделать генерацию структуры как у Славы
//  стоит подумать лучше
// export const generateStatusesInfoList = <T>(
//   statusesEnum: T,
//   description: Record<keyof T, string>,
//   badgeStyles: Record<keyof T, BadgePropStatus>
// ) => {
//   return Object.keys(statusesEnum).map(statusKey => ({
//     label: description[statusKey],
//     style: badgeStyles[statusKey],
//     value: statusKey,
//   }))
// }