import api from '@shared/api'
import { ApiReqResult } from '@/interfaces'
import { useQuery } from 'react-query'
import { IReportTemplate } from '@/interfaces/report'

const getReportTemplates = async () => {
  const result = await api.get(`/report/template`)
  if (!result.data?.success) {
    throw result
  }
  return result.data
}

interface IResult extends ApiReqResult {
  templates: IReportTemplate[]
}

const useGetReportTemplates = (
  callback?: (data: IReportTemplate[]) => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useQuery<IResult>([`getReportTemplates`], getReportTemplates, {
    keepPreviousData: false,
    onSuccess: ({ success, templates }) => {
      if (success) {
        callback?.(templates)
      } else {
        error?.()
      }
    },
    onError: () => error?.(),
    onSettled: () => settled?.(),
  })
}

export default useGetReportTemplates
