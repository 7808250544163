import React, { useEffect, useState } from 'react'
import { Timer as ConstaTimer, TimerProps, TimerPropsSize } from '@consta/uikit/Timer'

interface Props extends TimerProps {
  size?: TimerPropsSize;
  seconds?: number;
  delta?: number;
  animation?: boolean;
  handleComplete: () => void;
}
/* Таймер на после которого отправляется запрос выполнения */
export const Timer = (props: Props) => {
  const {
    size,
    seconds = 5,
    delta = 20,
    animation,
    handleComplete,
  } = props

   const [timeCount, setTimeCount] = useState<number>(seconds)

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeCount((state) => {
        let result = state - 1
        if (result <= 0) return 0
        return result
      })
    }, 1000)
    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    if (timeCount === 0) {
      handleComplete()
    }
  }, [timeCount])

	return (
		<ConstaTimer
      size={size}
      seconds={timeCount}
      progress={timeCount * delta}
      animation={animation}
      placeholder={''}
      onPointerEnterCapture
      onPointerLeaveCapture
    />
	);
};
