import api from '@shared/api'
import { convertObjectToURLParams } from '@shared/helpers'

import { ApiReqResult, IContractorDto, IPaginationFilter, PaginationResult } from 'src/interfaces'
import { useQuery } from 'react-query'
import { UseQueryOptions } from 'react-query/types/react/types'

export interface IResult extends PaginationResult, ApiReqResult {
  contractors: IContractorDto[]
}
interface IParams extends IPaginationFilter {
  search?: string
  warehouse_merchant?: boolean
}
const getContractorsList = async (params: IParams) => {
  const paramsStr = convertObjectToURLParams(params)
  const result = await api.get(`/contractor${paramsStr}`)
  return result.data as IResult
}

const useGetContractorsList = (params: IParams, options: UseQueryOptions<any> = {}) =>
  useQuery<IResult>(
    [`getContractorsList`, params],
    async () => await getContractorsList(params),
    {
      ...options
  })

export default useGetContractorsList