import React, { CSSProperties, FC, HTMLAttributeAnchorTarget } from 'react'
import { Link } from 'react-router-dom'
import { SECTIONS, tabs } from '@pages/DirectoriesPage/constants'
import { generateDirectoriesPath } from '@shared/routing/config/paths'

interface NomenclatureCellProps {
  title: React.ReactNode
  itemId: string
  link?: string
  target?: HTMLAttributeAnchorTarget
  withNewTab?: boolean
  style?: CSSProperties
}

const NomenclatureCell: FC<NomenclatureCellProps> = ({
  link,
  title,
  itemId,
  withNewTab,
  target,
  style
}) => {
  const linkPath = link ? link : generateDirectoriesPath({
    page: tabs.guides.value,
    section: SECTIONS.nomenclature.value,
    type: 'item',
    id: itemId || '-',
  })
  return (
    <Link
      to={linkPath}
      target={target}
      style={style}
      onClick={(e) => {
        if (withNewTab) {
          e.preventDefault();
          window.open(linkPath, "_blank")?.focus();
        }
      }}
    >
      {title}
    </Link>
  )
}

NomenclatureCell.displayName = 'NomenclatureCell'

export default NomenclatureCell
