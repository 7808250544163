import React, { Dispatch, SetStateAction } from 'react'

import { IRefund } from 'src/interfaces/refund/refund'

import { RETURN_STATUS, RETURN_STATUS_BADGE_TYPE, RETURN_STATUSES, ReturnStatusEnum } from '@/interfaces/statuses'

import { OperAndDocumPages } from '../../../../pagesType'

import { TableColumn } from '@consta/uikit/Table'
import { Badge } from '@consta/uikit/Badge'
import { DateCell, Select } from 'src/components'
import { DocumentNumCell } from '../../../../components'
import days from 'dayjs'
import { DATE_TIME_VIEW } from '@shared/const/date'
import { ITableRow } from '@/interfaces'


export const generateRows = (
  refunds: IRefund[],
  state: Record<string, ReturnStatusEnum>,
  editMode: boolean,
): ITableRow[] => {
  return refunds.map(refund => {
    const rowTheme = editMode && state[refund.id] ? 'blue' : 'white'
    return ({
      id: refund.id || '',
      date: refund.create_ts,
      date_value: days(refund.create_ts).format(DATE_TIME_VIEW),
      documentNum: refund.id,
      deliveryCompany: refund.delivery_company,
      status: refund.status,
      status_value: RETURN_STATUSES[refund.status || ''],
      theme: rowTheme
    })
  })
}

export const generateColumns = (
  state: Record<string, ReturnStatusEnum>,
  editMode: boolean,
  setEditStatusState: Dispatch<SetStateAction<Record<string, ReturnStatusEnum>>>
): TableColumn<any>[] => {
  const statusesOptions = RETURN_STATUS
  return [
    {
      title: 'Дата',
      sortable: true,
      accessor: 'date_value',
      renderCell: ({row}) => {
        return <DateCell date={row.date} style={{minWidth: '120px'}}/>
      }
    },
    {
      title: 'Номер документа',
      sortable: true,
      accessor: 'documentNum',
      renderCell: ({row}) => {
        return <DocumentNumCell page={OperAndDocumPages.returnForm} documentNum={row.documentNum} />
      }
    },
    {
      title: 'Способ доставки',
      accessor: 'deliveryCompany',
    },
    {
      title: 'Статус',
      accessor: 'status_value',
      sortable: true,
      renderCell: ({row}) => {
        if (editMode) {
          const statusValue = statusesOptions.find(option => {
            const statusValue = state[row.id] || row.status
            return option.value === statusValue
          })
          return (
            <Select
              options={statusesOptions}
              value={statusValue || null}
              onChange={(value) => {
                if (value.value === row.status) return
                setEditStatusState(prev => ({
                  ...prev,
                  [row.id]: value.value
                }))
              }}
            />
          )
        }
        return (
          <Badge
            status={RETURN_STATUS_BADGE_TYPE[row.status]}
            label={RETURN_STATUSES[row.status]}
          />
        )
      }
    },
  ]
}


