import { defaultSettingsLS } from '@shared/lib/storage'
import { Themes, ThemesEnum } from '@shared/providers/ThemeProvider/config/themes'
import { CommonTableSettingsType } from '@shared/lib/storage/DefaultSettingsLS'


export const useDefaultSettings = () => {
  /*
    Arm (рабочая стр.)
    Стр. по умолчанию после авторизации (например арм упаквоки)
  */
  const getDefaultArm = () => defaultSettingsLS.defaultArm
  const setDefaultArm = (value: string) => {
    defaultSettingsLS.defaultArm = value
  }
  const clearDefaultArm = () => {
    defaultSettingsLS.defaultArm = ''
  }

  /*
   Workplace
   ШК рабочего места по умолчанию
  */
  const getDefaultWorkplace = () => defaultSettingsLS.defaultWorkplace
  const setDefaultWorkplace = (value: string) => {
    defaultSettingsLS.defaultWorkplace = value
  }
  const clearDefaultWorkplace = () => {
    defaultSettingsLS.defaultWorkplace = ''
  }

  /*
   DB
   База по умолчанию
  */
  const getDefaultBase = () => defaultSettingsLS.defaultBase
  const setDefaultBase = (defaultBaseValue: string) => {
    defaultSettingsLS.defaultBase = defaultBaseValue
  }
  const clearDefaultBase = () => {
    defaultSettingsLS.defaultBase = ''
  }

  /*
  * Сохранение/управление localStorage для нового дизайна
  */

  const getNewDesign = () => !defaultSettingsLS.newDesign
  const setNewDesign = (value: boolean) => {
    defaultSettingsLS.newDesign = value
  }
  const clearNewDesign = () => setNewDesign(false)


  /*
  * Сохранение/управление localStorage для нового дизайна
  */

  const getDefaultTheme = (): ThemesEnum => defaultSettingsLS.theme
  const setDefaultTheme = (theme: ThemesEnum) => defaultSettingsLS.theme = theme
  const clearDefaultTheme = () => defaultSettingsLS.theme = Themes.light
  const isDarkTheme = ([Themes.dark, Themes.accent] as ThemesEnum[]).includes(getDefaultTheme())



  /*
  * Настройки таблиц по умолчанию, для всех сразу
  */
  const getDefaultCommonTableSettings = () => defaultSettingsLS.commonTableSettings || {}
  const setDefaultCommonTableSettings = (settings: CommonTableSettingsType) => defaultSettingsLS.commonTableSettings = settings
  const clearDefaultCommonTableSettings = () => defaultSettingsLS.commonTableSettings = {}
  const _updateDensity = (settingsFieldKey: string, value: string | null, densityKey: keyof CommonTableSettingsType['density']) => {
    const commonTableSettings = defaultSettingsLS.commonTableSettings
    const settingsDensity: CommonTableSettingsType['density'] = commonTableSettings.density || {}
    defaultSettingsLS.commonTableSettings = {
      ...commonTableSettings,
      density: {
        ...settingsDensity,
        [densityKey]: { ...(settingsDensity[densityKey] as any || {}), [settingsFieldKey]: value || '' }
      },
    }
  }
  const setTableDefaultDensityByPage = (pageId: string, density: string | null) => {
    _updateDensity(pageId, density, 'byPage')
  }
  const setTableDefaultDensityByTable = (tableId: string, density: string | null) => {
    _updateDensity(tableId, density, 'byTable')
  }
  const defaultCommonTableSettings = getDefaultCommonTableSettings()


  const _updateHiddenColumns = (tableId: string, hiddenColumns: string[]) => {
    const commonTableSettings = defaultSettingsLS.commonTableSettings
    const settingsHiddenColumns = commonTableSettings.hiddenColumns || {}
    defaultSettingsLS.commonTableSettings = {
      ...commonTableSettings,
      hiddenColumns: {
        ...settingsHiddenColumns,
        [tableId]: hiddenColumns
      },
    }
  }

  const getTableDefaultHiddenColumns = (tableId: string) => {
    return defaultCommonTableSettings.hiddenColumns?.[tableId] || []
  }
  const setTableDefaultHiddenColumns = (tableId: string, hiddenColumns: string[]) => {
    _updateHiddenColumns(tableId, hiddenColumns)
  }


  return {
    defaultArm: getDefaultArm(),
    getDefaultArm,
    setDefaultArm,
    clearDefaultArm,

    defaultWorkplace: getDefaultWorkplace(),
    getDefaultWorkplace,
    setDefaultWorkplace,
    clearDefaultWorkplace,

    defaultBase: getDefaultBase(),
    getDefaultBase,
    setDefaultBase,
    clearDefaultBase,

    defaultNewDesign: getNewDesign(),
    getNewDesign,
    setNewDesign,
    clearNewDesign,

    defaultTheme: getDefaultTheme(),
    getDefaultTheme,
    setDefaultTheme,
    clearDefaultTheme,
    isDarkTheme,

    defaultCommonTableSettings: defaultCommonTableSettings,
    getDefaultCommonTableSettings,
    setDefaultCommonTableSettings,
    clearDefaultCommonTableSettings,

    getTableDefaultDensityByPage: (pageId?: string) => {
      if (!pageId) return
      return defaultCommonTableSettings?.density?.byPage?.[pageId]
    },
    setTableDefaultDensityByPage,
    getTableDefaultDensityByTable: (tableId?: string) => {
      if (!tableId) return
      return defaultCommonTableSettings?.density?.byTable?.[tableId]
    },
    setTableDefaultDensityByTable,

    getTableDefaultHiddenColumns,
    setTableDefaultHiddenColumns
  }
}
