import { Column, OnChangeFn, Row, Updater } from '@tanstack/react-table'
import { RowData } from '@tanstack/table-core/src/types'
import { ACTION_ID, COLUMN_EXPANDER_ID, COLUMN_SELECTION_ID } from '@/components/Table/TanStackTable/lib/const'

export const SYMBOL_ALL_ITEMS = '-1' // для копирования
export type CopyTupleType = [string, string]

export type CopyState = {
  startForCopyID: CopyTupleType,
  endForCopyID: CopyTupleType,
}

export interface CopyTableState {
  copyState: CopyState
  copyShowCopyComplete: boolean
}

// define types for our new feature's table options
export interface CopyOptions {
  enableCopy?: boolean
  onCopyStateChange?: OnChangeFn<CopyState>
  onCopyShowCopyCompleteChange?: OnChangeFn<boolean>
}

// Define types for our new feature's table APIs
export interface CopyInstance<TData> {
  setCopyState: (updater: Updater<CopyState>) => void
  setCopyShowCopyComplete: (updater: Updater<boolean>) => void

  toggleShowCopyComplete: () => Promise<unknown>

  /* Нотификейшен таблицы */
  _notification: (type: string, text: string) => void

  /** Копируем строку в клипборд */
  _copyToClipboard: (values: string) => void

  /**
   * Проверяем присутствует ли выделение столбцовой части таблицы (если хотя бы один столце имеет занчение SYMBOL_ALL_ITEMS)
   * то считаем, что столбец выделен вместе с
   */
  _getCopyStateColumnHasSpecialSymbols: () => boolean

  /**
   * Возвращает подготовленные данные для расчетов выделенных строк и столбцов
   */
  _getCopyPrepareData: () => {
    columnStartId: string, rowStartId: string,
    columnEndId: string, rowEndId: string,
    columns: Column<TData>[],
    rows: Row<TData>[],
  }

  isSelectAll: () => boolean
  copyStateIsEmpty: () => boolean

  handleCopyMouseOverRowById: (columnId: string, rowId: string) => void

  handleCopyMouseDownRowById: (columnId: string, rowId: string) => void

  getAllVisibleColumnsToCopy: () => Column<TData>[]

  /**
   * Возвращает выделенные строки и столбцы по стейту копирования
   */
  getCopySelectedRowsColumns: () => ({
    columns: Column<TData>[],
    rows: Row<TData>[],
    selectedColumnsIds: string[],
    selectedRowsIds: string[],
  })
  /**
   * Возвращает выделенные столбцы по стейту с ID
   */
  getCopySelectedColumns: () => ({
    columns: Column<TData>[],
    selectedColumnsIds: string[],
  })
  /**
   * Возвращает выделенные строки по стейту с ID
   */
  getCopySelectedRows: () => ({
    rows: Row<TData>[],
    selectedRowsIds: string[],
  })
  /**
   * Сбрасываем стейт с копированием
   */
  resetCopyState: () => void

  /**
   * Выделяем всю таблицу для копирования
   */
  selectAllTableToCopy: () => void

  /**
   * Записываем в клипборд данных по стейту
   */
  copyTableData: (selectedColumns: Column<TData>[], selectedRows: Row<TData>[]) => void
  copySelected: () => void
  // copyAllTable: () => void
  // copyColumn: () => void
  copyAllTableWithNested: () => void
}

export interface CopyColumn<TData extends RowData> {
  getCanCopy: () => boolean

  /**
   * Выделяем весь столбец для копирования
   */
  selectColumnToCopy: () => void

  /**
   * Проверяем выделен ли столбец для копирования
   */
  getColumnIsSelectionToCopy: (selectedColumnsIds: string[], fromHeader?: boolean) => boolean

  /**
   * Проверяем столбец на определенные параметры
   */
  checkActionColumn: () => {
    isActionColumn: boolean,
    isExpanderColumn: boolean,
    isSelectionColumn: boolean,
  }
}
export interface CopyRow  {
  /**
  *  Выделяем всю строку поверхностно
  */
  selectRowToCopy: () => void

  /**
  * Выделяем всю строку с вложенностью
  */
  selectRowWithDepthToCopy: () => void

}

export interface CopyCell {
  /**
   * Проверяем выделена ли ячейка
   */
  getCellIsSelectionToCopy: (selectedColumnsIds: string[], selectedRowsIds: string[]) => boolean
}

export interface CopyColumnMeta {
    canCopy?: boolean
    /*
      Специальный ключ для копирования
      Если потребуется копировать из спец позиции строки
    */
    copyKey?: string
}