import { useMutation } from 'react-query'

import api from '@shared/api'

import { ApiReqResult, ICreateWorkspace } from 'src/interfaces'

// http://wms-test.kak2c.ru/warehouse-api/warehouse/workspace

interface Result extends ApiReqResult {
  id: string
}
interface CreateJobsParams {
  data: ICreateWorkspace[]
}

const useCreateJobs = () => {
  return useMutation(async ({ data }: CreateJobsParams) => {
    const path = `/warehouse/workspace`
    const result = await api.post(path, data)

    if (!result?.data?.success) {
      throw result
    }
    return result.data as Result
  })
}

export default useCreateJobs
