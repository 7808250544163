import { useQuery, UseQueryOptions } from 'react-query'

import api from '@shared/api'

import { ApiReqResult, IPaginationFilter, PaginationResult } from 'src/interfaces'
import { IInstruction } from 'src/interfaces/instructions'

//wms-test.kak2c.ru/warehouse-api/instructions/{instruction_id}

interface Result extends ApiReqResult, PaginationResult {
  instruction: IInstruction
}

interface Params extends IPaginationFilter {
  instruction_id: string
}

export const getInstruction = async (params: Params) => {
  const result = await api.get(`/instructions/${params.instruction_id}`)
  return result.data
}
const useGetInstruction = (
  params: Params,
  options?: UseQueryOptions<Params, unknown, Result>,
) => {
  return useQuery<Params, unknown, Result>(
    [`getInstruction`, params],
    () => getInstruction(params),
    {
      ...options,
    },
  )
}

export default useGetInstruction
