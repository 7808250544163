import { TableColumn } from '@consta/uikit/Table'
import { ICategory } from '@/interfaces'
import { EditRemoveButtons } from '@pages/DirectoriesPage/components/EditRemoveButtons/EditRemoveButtons'
import React from 'react'

export const generateTableData = (
  categories: ICategory[],
  search: string,
  handleConfirmModal: (code: string) => void,
  handleEdit: (code: string) => void,
) => {
  let rows = categories.map((item) => {
    return {
      id: item.code,
      title: item.title,
      edit: '',
    }
  })

  if (search) {
    rows = rows.filter(row => row.id.includes(search) || row.title.includes(search))
  }

  const columns: TableColumn<(typeof rows)[number]>[] = [
    {
      title: 'Код',
      accessor: 'id',
      width: 400,
    },
    {
      title: 'Наименование',
      accessor: 'title',
      width: 400,
    },
    {
      title: '',
      /* @ts-ignore */
      accessor: 'edit__action',
      align: 'right',
      width: 200,
      renderCell: (row: any) => {
        return (
          <EditRemoveButtons
            withEdit
            withRemove
            onEditClick={() => handleConfirmModal(row.original.id)}
            onRemoveClick={() => handleEdit(row.original.id)}
          />
        )
      },
    },
  ]
  return { rows, columns }
}
