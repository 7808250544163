import { TableColumn } from '@consta/uikit/Table'
import { IPaymentMethods } from '@/interfaces/paymentMethods'

export const generateTableData = (values: IPaymentMethods[], search: string) => {
  let rows = values.map((item) => {
    return {
      id: item.value,
      title: item.title,
    }
  })

  if (search) {
    rows = rows.filter(row => row.title.includes(search))
  }

  const columns: TableColumn<(typeof rows)[number]>[] = [
    {
      title: 'Наименование',
      accessor: 'title',
    },
  ]
  return { rows, columns }
}
