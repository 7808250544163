import React, { FC } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { IDocumentStatuses } from '@/interfaces/documentStatuses'
import { IRouteParams } from '@pages/DirectoriesPage/types'
import { InputWrapper } from '@/components'
import styles from './Item.module.scss'
import { Loader } from '@consta/uikit/Loader'
import { useGetDocumentStatuses } from '@/hooks'

interface ItemProps {
}

const Item: FC<ItemProps> = () => {
  const { id = '' } = useParams<IRouteParams>()
  const test = useParams<IRouteParams>()
  const history = useHistory()
  const {
    isFetching: isFetchingStatuses,
    data: statusesData,
    refetch: refetchStatuses,
  } = useGetDocumentStatuses({
    params: {
      type: id,
    },
  })
  if (!statusesData || isFetchingStatuses) {
    return (
      <div>
        <Loader /> Loading...
      </div>
    )
  }

  const statuses: IDocumentStatuses[] | undefined = statusesData?.values
  const info = statuses.map((el) => {
    return {
      label: '',
      value: el.title,
    }
  })

  return (
    <div className={styles.wrapper}>
      {info.map((item, i) => (
        <InputWrapper
          key={i}
          label={item.label}
          value={item.value}
          disabled
        />
      ))}
    </div>
  )
}
export default Item
