import useGetZoneSettings from '@/hooks/warehouse/useGetZoneSettings'
import { ICellPlace, ITreeLvlItem } from '@/interfaces'
import { TreeOptionType, TreeSelect } from '@shared/ui/fields/TreeSelect'
import React, { useCallback, useMemo } from 'react'
import { PropSize } from '@consta/uikit/__internal__/src/components/SelectComponents/types'
import { Mode, TreeNode } from 'react-dropdown-tree-select'

// const exampleData: TreeOptionType[] = [
//   {
//     label: 'test 1',
//     value: 'hello',
//     children: [
//       {
//         label: 'Hi',
//         value: 'Hi',
//       },
//     ],
//   },
//   {
//     label: 'test 2',
//     value: 'Hey',
//     children: [],
//   },
// ]

interface Props {
  mode?: Mode
  selectedZonesTopology?: string[],
  selectedCells?: ICellPlace[],
  label: string
  size?: PropSize,
  onChange: (zonesTopology: string[], selectedNodes: TreeNode[], selectedLabels: string[]) => void
}

const TreeSelectCell = React.memo((props: Props) => {
  const {
    mode,
    size,
    selectedZonesTopology,
    selectedCells,
    label,
    onChange,
  } = props

  const { data, refetch } = useGetZoneSettings({})

  const zones = data?.zones

  const selectedCellsIds = useMemo(() => selectedCells?.map(cell => cell.id), [selectedCells])
  const convertToSelect = useCallback((
    treeLvlItem: ITreeLvlItem & { topology?: { tier: string }, type?: string, id?: string },
    prevValue: string,
    zoneId: string,
  ): TreeOptionType => {
    const {
      items,
      title,
      ...restProps
    } = treeLvlItem

    const isCell = treeLvlItem.type === 'CELL'
    const currValue = `${prevValue}|${treeLvlItem.index ?? treeLvlItem.topology.tier ?? ''}`

    const checked = (
      (selectedCellsIds?.includes(treeLvlItem.id) && isCell)
      || selectedZonesTopology?.includes(currValue)
    )

    const children = items?.map(item => convertToSelect(item, currValue, zoneId))

    return {
      label: title,
      value: currValue,
      zone_id: zoneId,
      checked,
      children,
      ...restProps,
    }
  }, [selectedCellsIds, selectedZonesTopology])

  const dataItems: TreeOptionType[] = useMemo(() =>
    zones
      ?.sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
      ?.map((zone) => {
        const children = zone.items.map((item) => convertToSelect(item, zone.id, zone.id))

        return {
          label: zone.title,
          value: zone.id,
          zone_id: zone.id,
          children,
          ...zone,
        }
      }), [zones, selectedCells, convertToSelect])

  const fillValues = useCallback((val: string[]) => {
    if (val.length < 4) {
      val.push('')
      return fillValues(val)
    }
    return val
  }, [])


  const handleChangeTreeSelect = useCallback((currentNode: TreeNode, selectedNodes: TreeNode[]) => {
    let resultValues = []
    let resultLabels = []
    selectedNodes.forEach((node) => {
      const splitVal = node.value?.split('|')
      resultValues.push(fillValues(splitVal).join('|'))
      resultLabels.push(node.label)
    })
    onChange(resultValues, selectedNodes, resultLabels)
  }, [onChange, fillValues])


  const handleSearchPredicate = useCallback((currentNode: TreeNode, searchTerm: string) => {
      const search = searchTerm.toLowerCase()
      const currentZone = dataItems.find(item => {
        return item.id === currentNode.zone_id
      })
      const searchByLabelAndBarcode = (
        currentNode.label?.toLowerCase().includes(search)
        || currentNode.barcode?.toLowerCase().includes(search)
      )
      // Если это заона OUT_OF_STOCK то отображаем только ячейки, без узлов топологии
      if (currentZone?.type === 'OUT_OF_STOCK') {
        return searchByLabelAndBarcode && (currentNode.type === 'CELL')
      }
      return searchByLabelAndBarcode
    },
    [dataItems],
  )

  return (
    <TreeSelect
      mode={mode}
      label={label}
      options={dataItems}
      size={size}
      onChange={handleChangeTreeSelect}
      searchPredicate={handleSearchPredicate}
    />
  )
})
export default TreeSelectCell