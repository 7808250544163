import React from 'react'

import styles from './documents-params.module.scss'

import {
  CommonSerialNumberFilled,
  IAcceptanceSku,
  IDefectType,
  ISupplyReceipt,
  ISupplyReceiptItem,
  ITableColumn,
  ITableRow,
  RelatedDocument,
  TrustBox,
  TrustBoxItem,
} from 'src/interfaces'
import { NomenclatureCell, TitleCell } from '../../../../../components'
import { ImageCell, InputWrapper, Select } from 'src/components'
import { getNomenclatureNameValue } from '../../../../../helpers/common'
import { ACCEPTANCE_TYPES_DROPDOWN_OPTIONS } from './DocumentsParamsFields'

interface IType {
  [key: string]: string | undefined
}

const ACCEPTANCE_TYPE: IType = {
  PIECE: 'Поштучно',
  BOX: 'По коробам',
  PALLET: 'По паллетам',
}

export const generateColumns = (
  handleOpenModal: (sn: CommonSerialNumberFilled[]) => void,
): ITableColumn[] => {
  return [
    {
      key: 'img',
      title: 'Фото',
      title_txt: 'Фото',
      gridCellWidth: '90px',
      renderCell: ({ row }) => <ImageCell image={String(row.img)} />,
    },
    {
      key: 'name_value',
      title: 'Номенклатура',
      title_txt: 'Номенклатура',
      renderCell: ({ row }) => (
        <NomenclatureCell title={row.name} itemId={row.id} />
      ),
    },
    {
      key: 'acceptance_type',
      title: 'Тип приемки',
      title_txt: 'Тип приемки',
    },
    {
      key: 'un',
      title: 'Уникальные номера',
      title_txt: 'Уникальные номера',
      renderCell: ({ row }) => {
        return (
          <div
            className={styles.unicWrapper}
            onClick={(e) => {
              e.stopPropagation()
              const sn = row.serial_numbers as CommonSerialNumberFilled[]
              handleOpenModal(sn)
            }}
          >
            {row.serial_numbers?.map((serial) => (
              <div key={serial.value} className={styles.serialItem}>
                <b>{serial.title}</b>
                <span>{serial.value}</span>
              </div>
            ))}
          </div>
        )
      },
    },
    {
      key: 'multiplicity',
      title: 'Крастность в одной упаковке',
      title_txt: 'Крастность в одной упаковке',
    },
    {
      key: 'quantity',
      title: 'Кол-во упаковок',
      title_txt: 'Кол-во упаковок',
    },
    {
      key: 'barcode',
      title: 'Штрихкод',
      title_txt: 'Штрихкод',
    },
    {
      key: 'defect',
      title: 'Причина брака',
      title_txt: 'Причина брака',
    },
    {
      key: 'total',
      title: 'Итого',
      title_txt: 'Итого',
    },
  ]
}

export const convertTrustBoxToReceiptItems = (receiptItems: ISupplyReceiptItem[], trustBoxes: TrustBox[]): ISupplyReceiptItem[] => {
  return receiptItems.reduce((acc, tareItem) => {
    if (tareItem.trust_acceptance_box_barcode) {
      const trustBoxItems = trustBoxes.find(trustBox => trustBox.barcode === tareItem.trust_acceptance_box_barcode)?.items || []
      return [...acc, ...trustBoxItems.map((item: TrustBoxItem) => {
        return ({
          ...item,
          acceptance_type: tareItem.acceptance_type,
          multiplicity: 1,
          trust_acceptance_box_barcode: tareItem.trust_acceptance_box_barcode,
        })
      })]
    }
    return [...acc, tareItem]
  }, [] as any[])
}

export const generateRows = (
  receipt: ISupplyReceipt | null | undefined,
  skus: IAcceptanceSku[],
  trustBoxes: TrustBox[],
  defectTypes: IDefectType[],
  document: RelatedDocument | undefined,
  draftItems: ISupplyReceiptItem[] | null | undefined,
  handleChangeDraftItem: (id: string, value: string | null, key: string) => void,
  acceptanceOption,
  handleAcceptanceType,
): ITableRow[] => {
  if (!receipt) {
    return []
  }

  const receiptItems = convertTrustBoxToReceiptItems(receipt.items, trustBoxes)

  const skusFilled = skus.filter(Boolean)
  return receiptItems.map((tareItem, index) => {
    const defect = defectTypes.find((el) => el.id === tareItem.defect_type_id)
    const originalSku = skusFilled.find((sku) => sku.id === tareItem.sku_id)
    const barcode = originalSku?.barcodes?.[0]
    const quantity = String(
      draftItems ? draftItems[index].quantity || '' : tareItem.quantity,
    )
    const multiplicity = String(
      draftItems ? draftItems[index].multiplicity || '' : tareItem.multiplicity,
    )
    const total = tareItem.multiplicity
      ? tareItem.quantity * tareItem?.multiplicity
      : tareItem.quantity
    const un = new Set()

    tareItem.serial_numbers?.forEach((sn) => {
      un.add(sn.title ? `${sn.title}:\n${sn.value}` : sn.code)
    })
    const originalAcceptanceType = {
      value: ACCEPTANCE_TYPE[tareItem.acceptance_type as keyof IType],
      label: ACCEPTANCE_TYPE[tareItem.acceptance_type as keyof IType],
    }
    const acceptanceType = acceptanceOption || originalAcceptanceType
    const itemIsTrustBox = Boolean(tareItem.trust_acceptance_box_barcode)
    return {
      id: tareItem.sku_id,
      img: originalSku?.image,
      name_value: getNomenclatureNameValue(originalSku),
      name: (
        <TitleCell
          title={originalSku?.title || ''}
          article={originalSku?.article || ''}
        />
      ),
      acceptance_type: itemIsTrustBox ?
        acceptanceType.label
        : (
          <Select
            options={ACCEPTANCE_TYPES_DROPDOWN_OPTIONS}
            value={acceptanceType}
            onChange={(value) => {
              if (value.value === 'PIECE') {
                handleChangeDraftItem(tareItem.sku_id, '1', 'multiplicity')
              }
              handleAcceptanceType(tareItem.sku_id, value)
            }}
            className={styles.selectInput}
          />),
      multiplicity: itemIsTrustBox ?
        multiplicity
        : (
          <InputWrapper
            disabled={acceptanceType.value === 'PIECE'}
            isInteger
            id={'multiplicity'}
            label={''}
            placeholder={''}
            handleChange={(value) => {
              handleChangeDraftItem(tareItem.sku_id, value, 'multiplicity')
            }}
            value={multiplicity ?? null}
          />),
      quantity: itemIsTrustBox ?
        quantity
        : (
          <InputWrapper
            isInteger
            id={'quantity'}
            label={''}
            placeholder={''}
            handleChange={(value) => {
              handleChangeDraftItem(tareItem.sku_id, value, 'quantity')
            }}
            value={quantity ?? null}
          />),
      barcode: tareItem.trust_acceptance_box_barcode || barcode?.barcode,
      theme: 'white',
      defect: defect?.title || '-',
      defaultRowView: true,
      defaultIsExpanded: true,
      total: total,
      serial_numbers: tareItem.serial_numbers,
    }
  })
}
