import React, { ComponentProps, useState } from 'react'
import { CommonFieldProps } from '@shared/types/fields'
import styles from './FileFiled.module.scss'
import { DragNDropField } from '@consta/uikit/DragNDropFieldCanary'
import { Button } from '@shared/ui/btns/Button'
import { Flex } from '@shared/ui/Flex'
import { Text } from '@shared/ui/Text'
import noImage from '@shared/assets/images/noImage.png'
import { useToggle } from '@/newSrc/shared/hooks'
import { Modal } from '@/components'
import { convertFileToBase64 } from '@shared/helpers'
import { IconPicture } from '@consta/icons/IconPicture'
import cx from 'classnames'
import { IconAdd } from '@consta/icons/IconAdd'
import { IconEye } from '@consta/icons/IconEye'
import WebcamModal from '../../../../../components/Webcam/WebcamModal'
import { IconCamera } from '@consta/icons/IconCamera'
import { HoverTooltip } from '@shared/ui/HoverTooltip/HoverTooltip'
import { IconTrash } from '@consta/icons/IconTrash'
import { IconEdit } from '@consta/icons/IconEdit'


type FileFieldProps = {
  id?: string,
  value: string // url | base64
  handleChange: (value: string, title?: string) => void
  loading?: boolean
  accept?: ComponentProps<typeof DragNDropField>['accept']
  previewType?: 'image' | 'file'
} & Omit<CommonFieldProps, 'onChange' | 'value'>

export const FileField = (props: FileFieldProps) => {
  const {
    accept,
    value,
    loading,
    readOnly,
    isRequired,
    isMultiple,
    size,
    handleChange,
    previewType = 'image',
  } = props

  const [selectedFiles, setSelectedFiles] = useState<File[]>([])

  const {
    value: imgPreviewVisible,
    turnOn: showImgPreview,
    turnOff: hideImgPreview,
  } = useToggle()

  const {
    value: webcamModalVisible,
    turnOn: showWebcamModal,
    turnOff: hideWebcamModal,
  } = useToggle()

  return (
    <>
      <Flex justifyContent={'center'}>
        <DragNDropField
          className={styles.FileField}
          accept={accept}
          onDropAccepted={(filesAccepted) => {
            // isMultiple // todo добавить множественный выбор
            const file = filesAccepted[0]
            setSelectedFiles(filesAccepted)
            convertFileToBase64(file, previewType !== 'file')
              .then((fileBase64) => {
                handleChange(fileBase64, file.name)
              })
              .catch(console.error)
          }}
        >
          {
            ({ openFileDialog }) => (
              previewType === 'file' ? (
                <PreviewFile
                  value={value}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  openFileDialog={openFileDialog}
                />
              ) : (
                <PreviewImg
                  value={value}
                  showImgPreview={showImgPreview}
                  showWebcamModal={showWebcamModal}
                  imgPreviewVisible={imgPreviewVisible}
                  hideImgPreview={hideImgPreview}
                  openFileDialog={openFileDialog}
                />
              )
            )
          }
        </DragNDropField>
      </Flex>
      <WebcamModal
        isOpen={webcamModalVisible}
        handleNewScreenshot={(fileBase64, title) => {
          handleChange(fileBase64, title)
          hideWebcamModal()
        }}
        onClose={hideWebcamModal}
      />
    </>
  )
}

type PreviewFileProps = {
  value: string | null
  selectedFiles: File[]
  setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>
  openFileDialog: () => void,
}
const PreviewFile = (props: PreviewFileProps) => {
  const {
    value,
    selectedFiles,
    setSelectedFiles,
    openFileDialog,
  } = props

  const handleRemoveAddedItem = (newIndex: number) => {
    setSelectedFiles(prev => prev.filter((file, prevIndex) => prevIndex !== newIndex))
  }
  return (
    <>
      {
        selectedFiles?.length ? (
          <Flex gap={'l'}>
            <Flex direction={'column'} gap={'s'} className={styles.previewFileList}>
              {selectedFiles.map((file, i) => (
                <Flex gap={'s'} className={styles.previewFileListItem}>
                  <Text size={'s'} children={file.name} />
                  <Button
                    onlyIcon
                    theme={'danger'}
                    view={'ghost'}
                    iconLeft={IconTrash}
                    size={'xs'}
                    onClick={() => handleRemoveAddedItem(i)}
                  />
                </Flex>
              ))}
            </Flex>
            <HoverTooltip
              text={'Выбрать другой файл'}
              onClick={openFileDialog}
              children={
                <Button
                  onlyIcon
                  view={'ghost'}
                  iconLeft={IconEdit}
                  size={'xs'}
                  onClick={openFileDialog}
                />
              } />
          </Flex>
        ) : (
          <Flex>
            <Button
              label={'Загрузить файл'}
              onClick={openFileDialog}
            />
          </Flex>
        )
      }
    </>
  )
}


type PreviewImgProps = {
  imgPreviewVisible: boolean
  showImgPreview: () => void
  showWebcamModal: () => void
  hideImgPreview: () => void
  value: string | null
  openFileDialog: () => void,
}
const PreviewImg = (props: PreviewImgProps) => {
  const {
    value,
    showImgPreview,
    showWebcamModal,
    imgPreviewVisible,
    hideImgPreview,
    openFileDialog,
  } = props

  const btnProps: Pick<
    React.ComponentProps<typeof Button>,
    'onlyIcon' | 'theme' | 'size' | 'view' | 'className'
  > = {
    onlyIcon: true,
    size: 'xs',
    view: 'ghost',
    className: styles.btn,
  }
  const imageControls = [
    { tooltip: 'Загрузить картинку/фото', onClick: openFileDialog, icon: IconPicture },
    { tooltip: 'Сфотографировать', onClick: showWebcamModal, icon: IconCamera },
    { tooltip: 'Предпросмотр', onClick: showImgPreview, icon: IconEye },
  ]
  return (
    <>
      {
        value ? (
          <Flex align={'center'} gap={'s'} className={styles.previewFileWrapper}>
            <img
              src={value || noImage}
              alt={'img'}
              className={cx(styles.previewFile, styles.previewFileSize)}
              onClick={showImgPreview}
            />
            <Flex gap={'s'} align={'center'}>
              {imageControls.map((control, i) => (
                <HoverTooltip
                  key={i}
                  text={control.tooltip}
                  onClick={control.onClick}
                  children={
                    <Button
                      iconLeft={control.icon}
                      onClick={control.onClick}
                      {...btnProps}
                    />
                  }
                />
              ))}
            </Flex>
          </Flex>
        ) : (
          <Flex gap={'s'}>
            <AddFileBtn onClick={openFileDialog} />
            <WebcamScreenBtn onClick={showWebcamModal} />
          </Flex>
        )
      }

      <PreviewImageModal
        showWebcamModal={showWebcamModal}
        imgPreviewVisible={imgPreviewVisible}
        hideImgPreview={hideImgPreview}
        value={value}
      />
    </>
  )
}

const AddFileBtn = ({ onClick }: { onClick: () => void }) => (
  <Flex
    align={'center'}
    justifyContent={'center'}
    direction={'column'}
    gap={'2xs'}
    onClick={onClick}
    className={cx(styles.createFileBtn, styles.previewFileSize)}
  >
    <IconAdd view={'ghost'} size={'s'} />
    <Text view={'ghost'} align={'center'} size={'xs'} children={'Загрузить'} />
  </Flex>
)
const WebcamScreenBtn = ({ onClick }: { onClick: () => void }) => (
  <Flex
    align={'center'}
    justifyContent={'center'}
    direction={'column'}
    gap={'2xs'}
    onClick={onClick}
    className={cx(styles.createFileBtn, styles.previewFileSize)}
  >
    <IconCamera view={'ghost'} size={'m'} />
    <Text view={'ghost'} align={'center'} size={'xs'} children={'Сделать фото'} />
  </Flex>
)

const PreviewImageModal = (props: Omit<PreviewImgProps, 'showImgPreview' | 'openFileDialog'>) => {
  const {
    imgPreviewVisible,
    hideImgPreview,
    value,
  } = props

  return (
    <Modal
      isOpen={imgPreviewVisible}
      withClose
      hasOverlay
      onClose={hideImgPreview}
      onOverlayClick={hideImgPreview}
      size='m'
    >
      <Flex
        direction={'column'}
        align={'center'}
        gap={'xl'}
      >
        <img
          src={value || noImage}
          alt={'img'}
          className={cx(styles.previewFile__fullWidth)}
        />
      </Flex>
    </Modal>
  )
}