import React, { useEffect, useMemo, useState } from 'react'
import styles from './ChangingOrderModal.module.scss'

import { Button, ComboboxWrapper, ErrorBoundary, InputWrapper, Modal } from '@/components'
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup'
import { ComboboxSkus } from '@entities/Sku'

import { ModalProps } from '@/interfaces'
import { useGetItemsStock, useGetQueryPickings, useUpdateAdjustingDocument } from '@/hooks'
import { WorkModeEnum } from './enums'
import { ComboboxItemDefault } from '@consta/uikit/Combobox'
import { ValidateErrors } from '@pages/DirectoriesPage/types'
import { useNotifications } from '@shared/providers/NotificationProvider'

import { generateSelectItemsAdd, generateSelectItemsDelete } from './helpers/selectItems'
import useGetPackingById from '../../../../../hooks/packing/useGetPackingById'
import { LoaderWrapper } from '@shared/ui/LoaderWrapper'
import { Flex } from '@/newSrc/shared/ui/Flex'
import useGetVATEnum from '@/hooks/other/useGetVATEnum'

const workModeSwitcherTypes: { label: string; value: WorkModeEnum }[] = [
  { label: 'Удаление позиции', value: WorkModeEnum.delete },
  { label: 'Добавление позиции', value: WorkModeEnum.add },
]

export interface IChangingOrderModalProps extends ModalProps {
  orderId: string
  refetch?: () => void
}

export const ChangingOrderModal = (props: IChangingOrderModalProps) => {
  const { isOpen, onClose, refetch, orderId } = props

  const {
    data: documentData,
    isFetching: isDocumentLoading,
  } = useGetPackingById({ orderId, type: 'docs' })
  const orderRaw = documentData?.order

  const notification = useNotifications()

  const [workMode, setWorkMode] = useState<WorkModeEnum>(WorkModeEnum.delete)
  const [barcodePlaceList, setBarcodePlaceList] = useState<ComboboxItemDefault[]>([])

  const isDelete = workMode === WorkModeEnum.delete
  const isAdd = workMode === WorkModeEnum.add

  const [requiredValues, setRequiredValues] = useState({
    skus: null,
    quantity: '',
    price: '',
  })
  const [fieldValues, setFieldValues] = useState({
    barcodePlace: null,
    cells: null,
    batch: null,
    vat: null,
  })

  const [fieldErrors, setFieldErrors] = useState({
    skus: '',
    quantity: '',
    batch: '',
    price: '',
  })

  const { data: pickings } = useGetQueryPickings({
    parent_id: orderRaw?.batch_uuid,
  })
  const { data: vatsEnum } = useGetVATEnum()
  const { mutateAsync: createUpdateItem } = useUpdateAdjustingDocument()
  const { isLoading: stockLoading, data: cellsDataRaw } = useGetItemsStock({
    skus: requiredValues.skus
      ? [{ sku_id: String(requiredValues?.skus.id) }]
      : [],
  })
  const { data: stockForCells } = useGetItemsStock({
    skus:
      requiredValues.skus && fieldValues.batch
        ? [
          {
            sku_id: String(requiredValues?.skus.id),
            sku_batch_id: String(fieldValues?.batch?.id),
          },
        ]
        : [],
  })

  useEffect(() => {
    setBarcodePlaceList(
      orderRaw?.places.map((el) => ({ label: el.id, id: el.id })),
    )
  }, [orderRaw])

  const onSwitchWorkMode = (value: WorkModeEnum) => {
    setWorkMode(value)
    setRequiredValues({
      skus: null,
      quantity: '',
      price: '',
    })
    setFieldValues({
      barcodePlace: null,
      cells: null,
      batch: null,
      vat: null,
    })
  }
  const selectItemsDelete = useMemo(
    () =>
      generateSelectItemsDelete(
        workMode,
        pickings,
        orderRaw?.places || [],
        requiredValues.skus,
        fieldValues.batch,
      ),
    [
      workMode,
      pickings,
      orderRaw?.places,
      requiredValues.skus,
      fieldValues.batch,
    ],
  )
  const selectItemsAdd = useMemo(
    () =>
      generateSelectItemsAdd(
        workMode,
        requiredValues.skus,
        cellsDataRaw,
        fieldValues.batch,
        stockForCells,
      ),
    [workMode, requiredValues, cellsDataRaw, fieldValues.batch, stockForCells],
  )

  const canSubmit = (): boolean => {
    const emptyFieldKey = Object.keys(requiredValues).find((key) => {
      if (key === 'price' && isDelete) return false

      const value = requiredValues[key as keyof typeof requiredValues]

      return !value
    })

    if (emptyFieldKey) {
      setFieldErrors((prevState) => ({
        ...prevState,
        [emptyFieldKey]: ValidateErrors.isEmpty,
      }))
    }

    return !emptyFieldKey
  }

  const handleSubmit = () => {
    const isValid = canSubmit()
    if (!isValid) {
      return
    }
    if (
      (selectItemsAdd.batch.length ||
        selectItemsDelete.batch.length) && !fieldValues.batch
    ) {
      setFieldErrors((prevState) => ({
        ...prevState,
        batch: ValidateErrors.isEmpty,
      }))
      return
    }
    const orderId = orderRaw?.id
    const data = {
      items: [
        {
          change:
            isDelete
              ? -Number(requiredValues.quantity)
              : Number(requiredValues.quantity),
          id: String(requiredValues.skus?.id),
          place_barcode: fieldValues?.barcodePlace
            ? String(fieldValues.barcodePlace?.id)
            : '',
          price_data:
            isAdd
              ? {
              price: Number(requiredValues.price),
              vat: fieldValues?.vat?.id
                }
              : undefined,
          cell_barcode: fieldValues?.cells ? String(fieldValues.cells?.id) : '',
          sku_batch_id: fieldValues?.batch ? String(fieldValues.batch?.id) : undefined,
        },
      ],
    }

    createUpdateItem({ orderId, data })
      .then(async () => {
        notification?.show('success', 'Документ успешно сохранен', {
          soundName: 'PACKING_COMPLETED',
        })
        onClose()
        refetch()
      })
      .catch(() => {
      })
  }

  const resetErrors = () => {
    setFieldErrors({
      skus: '',
      quantity: '',
      batch: '',
      price: '',
    })
  }

  const handleChangeSku = (value: ComboboxItemDefault | null) => {
    setRequiredValues((prev) => ({
      ...prev,
      skus: value,
      price: '',
    }))
    setFieldValues({
      ...fieldValues,
      cells: null,
      barcodePlace: null,
      batch: null,
    })
    resetErrors()
  }

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      withClose
      onClose={onClose}
      onOverlayClick={onClose}
      className={styles.modal}
      size='m'
      headerTitle={'Редактирование цепочки'}
    >
      <ErrorBoundary>
        <LoaderWrapper isLoading={isDocumentLoading}>
          <Flex direction={'column'} gap={'m'}>
            <div>
              <ChoiceGroup
                className={styles.choiceGroup}
                value={
                  workModeSwitcherTypes.find((t) => t.value === workMode) ?? null
                }
                items={workModeSwitcherTypes}
                getItemLabel={(item) => item.label}
                onChange={(value) => onSwitchWorkMode(value?.value)}
                name={'workMode'}
                multiple={false}
                width={'full'}
              />
              <div className={styles.formWrapper}>
                <div className={styles.inputsRow}>
                  {isDelete ? (
                    <div className={styles.input}>
                      <ComboboxWrapper
                        isRequired
                        label={'Номенклатура'}
                        items={orderRaw?.items.map((el) => ({
                          label: el.title,
                          id: el.id,
                        })) || []}
                        value={requiredValues.skus}
                        placeholder='Выберите номенклатуру '
                        onChange={(value) => handleChangeSku(value)}
                      />
                      { fieldErrors.skus ? <div className={styles.errorMessage}>{fieldErrors.skus}</div> : null}
                    </div>
                  ) : (
                    <div className={styles.input}>
                      <ComboboxSkus
                        isRequired
                        label={'Номенклатура'}
                        value={requiredValues.skus}
                        placeholder='Выберите номенклатуру '
                        onChange={handleChangeSku}
                      />
                      { fieldErrors.skus ? <div className={styles.errorMessage}>{fieldErrors.skus}</div> : null}
                    </div>
                  )}

                  {selectItemsDelete?.batch.length || selectItemsAdd?.batch.length ? (
                    <div className={styles.input}>
                      <ComboboxWrapper
                        label={'Партия товара'}
                        items={
                          isDelete
                            ? selectItemsDelete?.batch
                            : selectItemsAdd.batch
                        }
                        value={fieldValues.batch}
                        placeholder='Выберите партию товара '
                        onChange={(value) => {
                          setFieldValues({ ...fieldValues, batch: value })
                          resetErrors()
                        }}
                      />
                      { fieldErrors.batch ? <div className={styles.errorMessage}>{fieldErrors.batch}</div> : null}
                    </div>
                  ) : null}

                  <div className={styles.quantity}>
                    <InputWrapper
                      isInteger
                      isRequired
                      label={'Кол-во'}
                      value={requiredValues.quantity}
                      handleChange={(value) => {
                        setRequiredValues((prev) => ({
                          ...prev,
                          quantity: value,
                        }))
                        resetErrors()
                      }}
                    />
                    { fieldErrors.quantity ? <div className={styles.errorMessage}>{fieldErrors.quantity}</div> : null}
                  </div>
                </div>
                {
                  isAdd ? (
                    <div className={styles.inputsRow}>
                      <div className={styles.input}>
                        <InputWrapper
                          isNumber
                          isRequired
                          label={'Цена'}
                          value={requiredValues.price}
                          handleChange={(value) => {
                            setRequiredValues((prev) => ({ ...prev, price: value }))
                            resetErrors()
                          }}
                        />
                        { fieldErrors.price ? <div className={styles.errorMessage}>{fieldErrors.price}</div> : null}
                      </div>
                      <div className={styles.input}>
                        <ComboboxWrapper
                          items={vatsEnum?.values.map((vat) => ({ id: vat.value, label: vat.title })) || []}
                          value={fieldValues.vat}
                          label={'НДС'}
                          onChange={(value) => {
                            setFieldValues({ ...fieldValues, vat: value })
                          }}
                        />
                      </div>
                    </div>
                  ) : null
                }
                <div className={styles.inputsRow}>
                  <div className={styles.input}>
                    <ComboboxWrapper
                      items={
                        isDelete
                          ? selectItemsDelete?.places
                          : barcodePlaceList
                      }
                      value={fieldValues.barcodePlace}
                      label={'ШК грузового места'}
                      placeholder='Выберите ШК грузового места '
                      onChange={(value) => {
                        setFieldValues({ ...fieldValues, barcodePlace: value })
                      }}
                    />
                  </div>
                  <div className={styles.input}>
                    <ComboboxWrapper
                      items={
                        isDelete
                          ? selectItemsDelete?.cells
                          : selectItemsAdd?.cells
                      }
                      value={fieldValues.cells}
                      label={'Ячейка'}
                      placeholder='Выберите ячейку '
                      onChange={(value) => {
                        setFieldValues({ ...fieldValues, cells: value })
                      }}
                    />
                    {
                      isAdd
                      && requiredValues.skus
                      && !selectItemsAdd.cells.length ? (
                        <div className={styles.infoMessage}>
                          Нет остатков по ячейкам!
                        </div>
                      ) : null
                    }
                  </div>
                </div>
              </div>
            </div>
            <Flex
              // className={styles.footer}
              justifyContent={'between'}
            >
              <Button label='Отмена' view={'ghost'} onClick={onClose} />
              <Button label='Сохранить' view={'primary'} onClick={handleSubmit} />
            </Flex>
          </Flex>
        </LoaderWrapper>
      </ErrorBoundary>
    </Modal>
  )
}
