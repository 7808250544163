import { useQuery } from 'react-query'
import api from '@shared/api'

import { ApiReqResult, IPackageDto, PackageSkuDto } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'
import { convertObjectToURLParams } from '@shared/helpers'

interface GetOrderPackingParams {
  documentId: string
}
const getPackagings = async (params: GetOrderPackingParams) => {
  const paramsStr = convertObjectToURLParams({document_id: params.documentId.trim()})
  const result = await api.get(`/packing-v2/packagings${paramsStr}`)
  if (!result.data?.success) {
    throw result
  }
  return result.data
}

interface IResult extends ApiReqResult {
  skus: PackageSkuDto[]
}

const useGetPackagingsV2 = (
  params: GetOrderPackingParams,
  options?: UseQueryOptions<IResult>,
) => {
  return useQuery<IResult>(
    [`getPackagings`, params],
    () => getPackagings(params),
      options
  )
}

export default useGetPackagingsV2
