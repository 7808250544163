import React, { FC } from 'react'

import type { ISection } from '../../types'
import { IRouteParams } from '../../types'
import { ContractorsList } from './pages/ContractorsList/ContractorsList'
import { ContractorForm } from './pages/ContractorForm/ContractorForm'
import { PageLayout } from '@pages/DirectoriesPage/components/PageLayout/PageLayout'
import { generateDirectoriesPath } from '@shared/routing/config/paths'
import { useParams } from 'react-router-dom'


interface Props extends ISection {
}

const Contractors: FC<Props> = (props) => {
  const { title = '' } = props
  const { page, section } = useParams<IRouteParams>()

  return (
    <PageLayout
      title={title}
      listPathBack={generateDirectoriesPath({ page, section, type: 'list' })}
      formPage={<ContractorForm />}
      listPage={<ContractorsList />}
    />
  )
}

export default Contractors
