import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios'
import { appLocalStorage } from '@shared/lib/storage'

import { apiErrorLog, apiResErrorLog } from './logger'

import { API_BASE_URL } from '@shared/const/common'
import { ResponseError, ResponseWarning } from './types'
import { loginPath } from '@/hooks/auth/useLogin'

declare module 'axios' {
  export interface AxiosRequestConfig {
    source?: string
    errorCallback?: (error: string) => string
    notShowErrors?:boolean
  }
}


const token = appLocalStorage.token

const api = axios.create({
  baseURL: `https://${API_BASE_URL}`,
  headers: {
    Authorization: token && `Bearer ${token}`,
  },
})

const UNKNOWN_ERROR_TEXT = 'Непредвиденная ошибка работы с сервером. Обратитесь к администратору.'
const unknownError = () => {
  window.showNotification('alert', UNKNOWN_ERROR_TEXT)
}

api.interceptors.response.use(
  (res: AxiosResponse) => {
    const errors: ResponseError[] = res?.data?.errors
    const warnings: ResponseWarning[] = res?.data?.warnings
    const success: boolean = res?.data?.success

    // запрос за базами предусматривает ответ в виде массива,
    // если убрать проверку, падает фронт
    const isArrayResponse = Array.isArray(res?.data)

    if (warnings?.length) {
      showWarnings(warnings)
    }
    if (success === false && errors) {
      apiResErrorLog(res)
      /** На старой WMS ошибка с сообщением приходит через 200 ОК */
      showErrors(res.data.results?.map(err => err.errors).flat() || [])
      showErrors(errors, res.config.errorCallback)
    }
    /* Лог и неизвестная ошибка */
    if (!success && !errors && !isArrayResponse) {
      apiResErrorLog(res)
      unknownError()
    }
    return res
  },
  async (error: AxiosError) => {
    const status: number = error?.response?.status
    const data = error?.response?.data
    const errorsFromServer: ResponseError[] = data?.errors
    const warnings: ResponseWarning[] = data?.warnings
    const config = error?.response?.config

    console.log('error',error)
    if (status === 401) {
      showErrorsByCondition(errorsFromServer, config)
      if (resetAuthAndReload(error)) {
        console.error(error)
        appLocalStorage.clear()
        window.location.reload()
      }
      return
    }

    if (warnings?.length) {
      showWarnings(warnings)
    }
    /** На новой WMS ошибка с сообщением приходит НЕ через 200 ОК, а обычно */
    showErrorsByCondition(errorsFromServer, config)
    if (errorsFromServer?.length) {
      return errorsFromServer
    }

    apiErrorLog(error)

    if (!config?.notShowErrors) {
      unknownError()
      return UNKNOWN_ERROR_TEXT
    }
  },
)

function resetAuthAndReload(error: AxiosError) {
  const config = error?.response?.config
  return config?.url !== loginPath // todo better
}

function showErrorsByCondition(errors: ResponseError[], config: AxiosRequestConfig) {
  console.log(errors)
  if (!errors?.length || config?.notShowErrors) return
  showErrors(errors, config?.errorCallback)
}


function showNotification(args: {
  notifType: 'alert' | 'warning',
  notifItem: ResponseWarning | ResponseError,
  listNotifs: (ResponseWarning | ResponseError)[],
  callback?: (message: string) => void
}) {
  const {
    notifType,
    notifItem,
    listNotifs,
    callback
  } = args
  const notifMessage = notifItem.message || '*** Неизвестная ошибка ***'
  window.showNotification(
    notifType,
    callback ? callback(notifMessage) : notifMessage,
    {
      withClose: true,
      clickable: true,
    },
    listNotifs
  )
}

function showErrors(
  errors: ResponseError[],
  errorCallback?: (error: string) => string,
) {
  errors.forEach((e, i) => {
    if (e?.errorsticker?.content) {
      const { content, printer } = e?.errorsticker
      window.printSticker(content, { qzOptions: { printer } })
    }
    showNotification({
      notifType: 'alert',
      notifItem: e,
      listNotifs: errors,
      callback: errorCallback,
    })
  })
}
function showWarnings(
  warnings: ResponseWarning[],
  callback?: (error: string) => string,
) {
  warnings.forEach((warning) => {
    showNotification({
      notifType: 'warning',
      notifItem: warning,
      listNotifs: warnings,
      callback: callback,
    })
  })
}

export default api
