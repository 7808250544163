import { useContext } from 'react'
import { useMutation } from 'react-query'
import { appLocalStorage } from '@shared/lib/storage'
import api from '@shared/api'

import { AppContext } from '@shared/providers/AppContextProvider'


interface ILoginData {
  auth_key?: string
  work_table: string
  login?: string
  password?: string
  username?: string
}

export const loginPath = '/auth/login'

const useLogin = () => {
  const { setAuthorized } = useContext(AppContext)

  return useMutation(
    async (data: ILoginData) => api.post(loginPath, data),
    {
      onSuccess: (result) => {
        if (!result?.data) return
        appLocalStorage.token = result.data.access_token
        api.defaults.headers.Authorization = `Bearer ${result.data.access_token}`
        setAuthorized(true)
      },
    },
  )
}

export default useLogin
