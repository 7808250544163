import React, { useEffect, useState } from 'react'

import styles from './InventoryModal.module.scss'
import { CalendarInput, ComboboxWrapper, Modal } from 'src/components'
import { ModalProps } from 'src/interfaces'
import { Button } from '@shared/ui/btns/Button'
import { ComboboxItemDefault } from '@consta/uikit/Combobox'
import days from 'dayjs'
import { useGetMerchantList } from 'src/hooks'
import { ComboboxMerchants } from '@entities/Merchant'
import { IFields } from './types'
import { useCreateInventory } from 'src/hooks/warehouse/inventory'
import { IconCalendar } from '@consta/icons/IconCalendar'
import { ValidateErrors } from '@pages/DirectoriesPage/types'
import { DATETIME_REQUEST_FORMAT } from '@shared/const/date'

interface IProps extends ModalProps {
  refetch: () => void
}

const InventoryModal = (props: IProps) => {
  const { isOpen, onClose, refetch = () => {} } = props

  const [merchantsList, setMerchantsList] = useState<ComboboxItemDefault[]>([])
  const { data: merchantData } = useGetMerchantList({})
  const skuConditionList = [
    { label: 'Хорошее', id: 'GOOD' },
    { label: 'Дефект', id: 'DEFECT' },
  ]
  const [fieldValues, setFieldValues] = useState<IFields>({
    merchant: null,
    condition: null,
    dateFrom: null,
    dateTo: null,
  })
  const [fieldErrors, setFieldErrors] = useState({
    merchant: '',
    condition: '',
    dateFrom: '',
    dateTo: '',
  })
  const canSubmit = (): boolean => {
    const emptyFieldKey = Object.keys(fieldValues).find((key) => {
      const value = fieldValues[key as keyof typeof fieldValues]

      return !value
    })

    if (emptyFieldKey) {
      setFieldErrors((prevState) => ({
        ...prevState,
        [emptyFieldKey]: ValidateErrors.isEmpty,
      }))
    }

    return !emptyFieldKey
  }
  const { mutateAsync: createInventory, isLoading: isInventoryCreate } =
    useCreateInventory()

  useEffect(() => {
    if (!merchantData) {
      return
    }
    setMerchantsList(
      merchantData.merchants.map((el) => {
        return {
          label: el.title,
          id: el.id,
        }
      }),
    )
  }, [merchantData])

  const handleSubmit = () => {
    const isValid = canSubmit()

    if (!isValid) {
      return
    }

    const data = {
      merchant_id: String(fieldValues.merchant?.id),
      scheduled_from:
        days(fieldValues.dateFrom).format(DATETIME_REQUEST_FORMAT) || '',
      scheduled_to:
        days(fieldValues.dateTo).format(DATETIME_REQUEST_FORMAT) || '',
      sku_condition: String(fieldValues.condition?.id),
    }

    createInventory(data)
      .then(async () => {
        refetch()
      })
      .catch(() => {})
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={onClose}
      className={styles.modal}
      size="m"
      headerTitle={'Создание документа'}
    >
      <div className={styles.formWrapper}>
        <div className={styles.inputsRow}>
          <div className={styles.inputCell}>
            <ComboboxMerchants
              className={styles.merchantRow}
              value={fieldValues.merchant}
              onChange={(value) =>
                setFieldValues((prev) => ({ ...prev, merchant: value }))
              }
            />
            <div className={styles.errorMessage}>{fieldErrors.merchant}</div>
          </div>
          <div className={styles.inputCell}>
            <ComboboxWrapper
              className={styles.merchantRow}
              items={skuConditionList}
              value={fieldValues.condition}
              label={'Состояние'}
              placeholder="Выберите состояние "
              onChange={(value) => {
                setFieldValues({ ...fieldValues, condition: value })
              }}
            />
            <div className={styles.errorMessage}>{fieldErrors.condition}</div>
          </div>
        </div>
        <div className={styles.inputsRow}>
          <div className={styles.inputCell}>
            <CalendarInput
              label="Дата/время"
              id={'dateFrom'}
              placeholder={'C'}
              handleChange={(value) =>
                setFieldValues({ ...fieldValues, dateFrom: value })
              }
              type={'date-time'}
              value={fieldValues.dateFrom}
              size="m"
              className={styles.dateInput}
              rightSide={IconCalendar}
              backspaceReset
            />
            <div className={styles.errorMessage}>{fieldErrors.dateFrom}</div>
          </div>
          <div className={styles.inputCell}>
            <CalendarInput
              label="Дата/время"
              id={'dateTo'}
              placeholder={'C'}
              handleChange={(value) =>
                setFieldValues({ ...fieldValues, dateTo: value })
              }
              type={'date-time'}
              value={fieldValues.dateTo}
              size="m"
              className={styles.dateInput}
              rightSide={IconCalendar}
              backspaceReset
            />
            <div className={styles.errorMessage}>{fieldErrors.dateTo}</div>
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <Button label="Отмена" view={'ghost'} onClick={onClose} />
        <Button label="Сохранить" view={'primary'} onClick={handleSubmit} />
      </div>
    </Modal>
  )
}

export default InventoryModal
