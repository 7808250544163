import { useQuery } from 'react-query'

import api from '@shared/api'

import { ApiReqResult, IPaginationFilter, PaginationResult } from 'src/interfaces'
import { IInstruction } from 'src/interfaces/instructions'
import { convertObjectToURLParams } from '@shared/helpers'

/*
 // http://wms-test.kak2c.ru/warehouse-api/instructions
 */

interface instructionListResult extends ApiReqResult, PaginationResult {
  instructions: IInstruction[]
}
interface getInstructionListParams extends IPaginationFilter {
  search?: string
  merchant_ids?: string[]
  order_types?: string[]
  delivery_companies?: string[]
  delivery_company_products?: string[]
  sku_categories?: string[]
}

const useGetInstructionsList = (paramsData: {
  params?: getInstructionListParams
  handleSuccess?: (data: instructionListResult) => void
  handleSettled?: () => void
  handleError?: () => void
}) => {
  const { params, handleSuccess, handleSettled, handleError } = paramsData

  return useQuery(
    [`getInstructionsList`],
    async () => {
      const searchParams = convertObjectToURLParams({ size: 300, ...params })
      const result = await api.get(`/instructions${searchParams}`)
      return result.data
    },
    {
      onSuccess: (data: instructionListResult) => {
        if (data?.success) {
          return handleSuccess?.(data)
        }
        handleError?.()
      },
      onSettled: handleSettled,
      onError: handleError,
    },
  )
}

export default useGetInstructionsList
