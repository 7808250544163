import { FieldType } from '@widgets/GeneratorFields'
import { TemplateFieldType, TemplateTableCell } from '@shared/types/tableGenerator/generatorTypes'
import { IField, IFieldValue } from '@shared/types/fields'


/* Конвертируем значения для стейта в зависимости от типа (обратный конвертор) */
export const getFieldsStateValueByType = (fieldId: number, value, type: FieldType): TemplateTableCell => {
  switch (true) {
    case type === FieldType.DOCUMENT:
    case type === FieldType.SKU:
    case type === FieldType.CELL:
    case type === FieldType.CONTAINER:
    case type === FieldType.PLACE:
    case type === FieldType.CONTRACTOR:
    case type === FieldType.MERCHANTS:
    case type === FieldType.SKU_BATCH: {
      if (!value) {
        return {
          id: fieldId,
          meta: { dto: null },
          value: null,
        }
      }
      return {
        id: fieldId,
        meta: { dto: value.dto },
        value: value.dto?.id,
      }
    }

    case type === FieldType.calendar:
    case type === FieldType.input:
      return {
        id: fieldId,
        value: value || null,
      }

    case type === FieldType.combobox:
      return {
        id: fieldId,
        value: value?.id || null,
      }

    case type === FieldType.TABLE:
      return {
        id: fieldId,
        additional_data: value.additional_data,
        value: value.value,
      }

    case type === FieldType.BOOLEAN:
    case type === FieldType.GROUP:
    case type === FieldType.JSON:
      return {
        id: fieldId,
        value: value,
      }
  }
}

/*
* Генерируем начальные значения для стейта при фетче
* - Обработка всяких исключений, данных, которые не получится хранить в серверном формате
*/

const getFieldValue = (field: IField, fieldValues: IFieldValue[] = []) => {
  const value = fieldValues.find(fieldVal => `${fieldVal.id}` === `${field.id}`)
  if (field.type === TemplateFieldType.TIME) {
    return { id: value.id, value: value.value ? `2024-01-01T${value.value}` : undefined }
  }
  return value
}
export const generateFieldValues = (fields: IField[] = [], fieldValues: IFieldValue[] = []) => {
  return fields.map(field => {
    if (field.type === TemplateFieldType.GROUP) {
      return {id: field.id, value: field.fields.map(subField => getFieldValue(subField, fieldValues))}
    }
    return getFieldValue(field, fieldValues)
  }).filter(Boolean)
}