import { BadgePropStatus } from '@consta/uikit/Badge'

export enum ExpDateTypesEnum {
  expirationDate = 'expirationDate',
  dateOfManufacture = 'dateOfManufacture',
}

export enum ShippingOptionsType {
  MIX_PALLETS = 'MIX_PALLETS',
  MIX_PACKINGS = 'MIX_PACKINGS',
  MONO_PACKINGS = 'MONO_PACKINGS',
  MONO_PALLETS = 'MONO_PALLETS',
}

export const ShippingOptionsDescription = {
  [ShippingOptionsType.MIX_PACKINGS]: 'МИКСКОРОБ',
  [ShippingOptionsType.MONO_PACKINGS]: 'МОНОКОРОБ',
  [ShippingOptionsType.MONO_PALLETS]: 'МОНОПАЛЛЕТА',
}

export enum packType {
  packInPlaces = 'packInPlaces',
  packInPallets = 'packInPallets',
}

export enum OperationTypes {
  PACKAGING_B2B = 'PACKAGING_B2B',
  PALLET_FORMATION_B2B = 'PALLET_FORMATION_B2B',
  PACKAGING_B2C = 'PACKAGING_B2C',
  RELABELING = 'RELABELING',
}

export enum RelabelingTypes {
  BOXES = 'BOXES',
  PALLETS = 'PALLETS',
  BOXES_AND_PALLETS = 'BOXES_AND_PALLETS',
}

export enum ShipmentTypes {
  BOXES = 'BOXES',
  PALLETS = 'PALLETS',
}

export enum BadgeTypeEnum {
  partOfSet = 'partOfSet',
  documents = 'documents',
  fragile = 'fragile',
  surplus = 'surplus',
  defect = 'defect',
  shortages = 'shortages',
}

export enum ConditionEnum {
  GOOD = 'GOOD',
  DEFECT = 'DEFECT',
  EXPIRED = 'EXPIRED',
  HIDDEN_GOOD = "HIDDEN_GOOD",
  HIDDEN_DEFECT = "HIDDEN_DEFECT",
  HIDDEN_WRONG_SERIAL_NUMBER = "HIDDEN_WRONG_SERIAL_NUMBER",
}

export const CONDITION_DESCRIPTION: Record<ConditionEnum, string> = {
  [ConditionEnum.GOOD]: 'Годный',
  [ConditionEnum.DEFECT]: 'Брак',
  [ConditionEnum.EXPIRED]: '?',
  [ConditionEnum.HIDDEN_GOOD]: 'Излишки',
  [ConditionEnum.HIDDEN_DEFECT]: 'Брак',
  [ConditionEnum.HIDDEN_WRONG_SERIAL_NUMBER]: 'Излишки с ЧЗ',
}

export const CONDITION_BADGE_TYPE: Record<ConditionEnum, BadgePropStatus> = {
  [ConditionEnum.GOOD]: 'normal',
  [ConditionEnum.DEFECT]: 'error',
  [ConditionEnum.EXPIRED]: 'system',
  [ConditionEnum.HIDDEN_GOOD]: 'normal',
  [ConditionEnum.HIDDEN_DEFECT]: 'error',
  [ConditionEnum.HIDDEN_WRONG_SERIAL_NUMBER]: 'normal',
}
