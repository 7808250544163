import { useQuery } from 'react-query'
import api from '@shared/api'
import { ApiReqResult, IDeliveryCompanyProduct, PaginationResult } from 'src/interfaces'
import { convertObjectToURLParams } from '@shared/helpers'
import { UseQueryOptions } from 'react-query/types/react/types'

// http://wms-test.kak2c.ru/warehouse-api/delivery-company-product

interface IParams {
  delivery_company?: string
}

interface IResult extends ApiReqResult, PaginationResult {
  products: IDeliveryCompanyProduct[]
}

export const getDeliveryCompanyProduct = async (paramsObj: IParams = {}) => {
  const searchParams = convertObjectToURLParams(paramsObj)
  const result = await api.get(`/delivery-company-product${searchParams}`)

  return result.data
}
const useGetDeliveryCompanyProduct = (
  params: IParams,
  options?: UseQueryOptions<IParams, any, IResult>,
) => {
  return useQuery<IParams, any, IResult>(
    [`getDiliveryCompanyProduct`, params],
    () => getDeliveryCompanyProduct(params),
    {
      keepPreviousData: true,
      ...options,
    },
  )
}
export default useGetDeliveryCompanyProduct
