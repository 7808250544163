import { TableColumn } from '@consta/uikit/Table'
import React from 'react'
import { IDeliveryContract } from '@/interfaces'

export const generateTableData = (
  deliveryContracts: IDeliveryContract[],
) => {
  const rows = deliveryContracts.map(
    (contract, i) => {
      return {
        id: `${contract.id || contract.dlv_id || i}${contract.code}`,
        nameEl: contract.title,
        deliveryEl: contract.delivery_company.title,
        productEl: contract.delivery_company_product.title,
      }
    },
  )

  const columns: TableColumn<(typeof rows)[number]>[] = [
    {
      title: "Наименование",
      accessor: 'nameEl',
      sortable: true,
      width: 400
    },
    {
      title: "Способ доставки",
      accessor: 'deliveryEl',
      sortable: true,
      width: 400
    },
    {
      title: "Продукт",
      accessor: 'productEl',
      sortable: true,
      width: 400
    },
  ]
  return { rows, columns }
}
