import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { IZoneCreate } from './types'
import { PopupZoneType, ValidateErrors } from '../../types'

import { TextFieldPropValue } from '@consta/uikit/TextField'

import { Button } from '@shared/ui/btns/Button'
import { InputWrapper } from '@/components'
import ZonePart from '../ZonePart/ZonePart'

import styles from './zone-create.module.scss'
import { IconFolders } from '@consta/icons/IconFolders'
import { IconBox } from '@shared/assets/icons/IconBox'

/** Контент попа создания/редактирования зоны */
const ZoneCreate: FC<IZoneCreate> = ({
  popupType = PopupZoneType.create,
  zoneData,
  onClose,
  onSubmit = () => {},
  onUpdate = () => {},
  onGenerateCells = () => {},
}) => {
  const [levelsChanged, setLevelsChanged] = useState(false)
  const [levelCount, setLevelCount] = useState({
    row: 1,
    bay: 1,
    tier: 1,
  })

  const [fieldValues, setFieldValues] = useState({
    name: '',
    fromRow: '01',
    toRow: '01',
    fromBay: '01',
    toBay: '01',
    fromTier: '01',
    toTier: '01',
  })

  const [fieldErrors, setFieldErrors] = useState({
    name: '',
    fromRow: '',
    toRow: '',
    fromBay: '',
    toBay: '',
    fromTier: '',
    toTier: '',
  })

  const validateLevelFields = (): boolean => {
    let hasErrors = false

    if (parseInt(fieldValues.toRow) - parseInt(fieldValues.fromRow) < 0) {
      setFieldErrors((prevState) => ({
        ...prevState,
        toRow: ValidateErrors.isTooMuch,
      }))

      hasErrors = true
    }

    if (parseInt(fieldValues.toBay) - parseInt(fieldValues.fromBay) < 0) {
      setFieldErrors((prevState) => ({
        ...prevState,
        toBay: ValidateErrors.isTooMuch,
      }))

      hasErrors = true
    }

    if (
      isNaN(parseInt(fieldValues.fromTier)) !==
      isNaN(parseInt(fieldValues.toTier))
    ) {
      setFieldErrors((prevState) => ({
        ...prevState,
        toTier: ValidateErrors.notMatch,
      }))

      hasErrors = true
    } else {
      const isTooMuchNum =
        !isNaN(parseInt(fieldValues.fromTier)) &&
        parseInt(fieldValues.toTier) - parseInt(fieldValues.fromTier) < 0
      const isTooMuchChar =
        isNaN(parseInt(fieldValues.fromTier)) &&
        fieldValues.toTier.charCodeAt(0) - fieldValues.fromTier.charCodeAt(0) <
          0

      if (isTooMuchNum || isTooMuchChar) {
        setFieldErrors((prevState) => ({
          ...prevState,
          toTier: ValidateErrors.isTooMuch,
        }))

        hasErrors = true
      }
    }

    return !hasErrors
  }

  const handleClear = (key: keyof typeof fieldValues) => {
    setFieldErrors((prevState) => ({
      ...prevState,
      [key]: ValidateErrors.isEmpty,
    }))

    setFieldValues((prevState) => ({
      ...prevState,
      [key]: '',
    }))
  }

  const handleFieldChange = (value: string, key: keyof typeof fieldValues) => {
    setFieldErrors((prevState) => ({
      ...prevState,
      [key]: !value.length ? ValidateErrors.isEmpty : '',
    }))

    setFieldValues((prevState) => ({
      ...prevState,
      [key]: value as string,
    }))
  }

  const handleLevelChange = (value: string, key: keyof typeof fieldValues) => {
    setFieldErrors((prevState) => ({
      ...prevState,
      [key]: !value.length ? ValidateErrors.isEmpty : '',
    }))

    let newFieldValues = {
      ...fieldValues,
      [key]: value as string,
    }

    let rowCount =
      parseInt(newFieldValues.toRow) - parseInt(newFieldValues.fromRow) + 1
    let bayCount =
      parseInt(newFieldValues.toBay) - parseInt(newFieldValues.fromBay) + 1
    let tierCount = 0

    if (rowCount < 0) {
      rowCount = 0
    }

    if (bayCount < 0) {
      bayCount = 0
    }

    if (
      isNaN(parseInt(newFieldValues.fromTier)) &&
      isNaN(parseInt(newFieldValues.toTier))
    ) {
      // Подсчёт, если буквы
      tierCount =
        newFieldValues.toTier.charCodeAt(0) -
        newFieldValues.fromTier.charCodeAt(0) +
        1
    } else if (
      !isNaN(parseInt(newFieldValues.fromTier)) &&
      !isNaN(parseInt(newFieldValues.toTier))
    ) {
      // Подсчёт, если цифры
      tierCount =
        parseInt(newFieldValues.toTier) - parseInt(newFieldValues.fromTier) + 1
    }

    if (tierCount < 0) {
      tierCount = 0
    }

    bayCount = bayCount * rowCount
    tierCount = tierCount * bayCount

    setLevelCount({
      row: rowCount,
      bay: bayCount,
      tier: tierCount,
    })

    setFieldValues(newFieldValues)
    setLevelsChanged(true)
  }

  const checkInput = (value: TextFieldPropValue): string => {
    if (!value || !value.match(/^[0-9]+$/)) {
      return ''
    }

    return value.toUpperCase()
  }

  const checkTierInput = (value: TextFieldPropValue): string => {
    if (!value || !value.match(/^[a-zA-Z0-9]+$/)) {
      value = ''
    }

    value = value.toUpperCase()

    const first = value[0]
    const last = value[value.length - 1] || ''

    const parsedFirst = parseInt(first as string)
    const parsedLast = parseInt(last as string)

    if (
      (isNaN(parsedFirst) && !isNaN(parsedLast)) ||
      (!isNaN(parsedFirst) && isNaN(parsedLast)) ||
      isNaN(parsedFirst)
    ) {
      value = last
    }

    return `${value}`
  }

  useEffect(() => {
    if (!zoneData) {
      return
    }
    console.log(zoneData.countByLevels)
    const [
      { valueFrom: fromRow, valueTo: toRow, amount: rowAmount },
      { valueFrom: fromBay, valueTo: toBay, amount: bayAmount },
      { valueFrom: fromTier, valueTo: toTier },
    ] = zoneData.countByLevels

    setFieldValues({
      name: zoneData?.title,
      fromRow,
      toRow,
      fromBay,
      toBay,
      fromTier,
      toTier,
    })

    setLevelCount({
      row: rowAmount,
      bay: bayAmount,
      tier: zoneData?.totalCells,
    })
  }, [zoneData])

  const canBeSubmitted = (): boolean => {
    const emptyFieldKey = Object.keys(fieldValues).find(
      (key: string) =>
        fieldValues[key as keyof typeof fieldValues].length === 0,
    )

    if (emptyFieldKey) {
      setFieldErrors((prevState) => ({
        ...prevState,
        [emptyFieldKey]: ValidateErrors.isEmpty,
      }))
    }

    const isValidity = validateLevelFields()

    return !emptyFieldKey && isValidity
  }

  const handleGenerateCells = () => {
    const canSubmit = canBeSubmitted()

    if (!canSubmit) {
      return
    }

    const cellsData = {
      access_level: 0,
      bay_end: parseInt(fieldValues.toBay),
      bay_start: parseInt(fieldValues.fromBay),
      condition: 'GOOD',
      row_end: parseInt(fieldValues.toRow),
      row_start: parseInt(fieldValues.fromRow),
      tier_end: fieldValues.toTier,
      tier_start: fieldValues.fromTier,
      type: 'CELL',
      zone_id: zoneData?.id || '',
    }

    onGenerateCells(cellsData)
  }

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault()

    const canSubmit = canBeSubmitted()

    if (!canSubmit) {
      return
    }

    if (popupType === PopupZoneType.create) {
      const zoneData = {
        title: fieldValues.name,
        type: 'STORAGE',
      }

      const cellsData = {
        access_level: 0,
        bay_end: parseInt(fieldValues.toBay),
        bay_start: parseInt(fieldValues.fromBay),
        condition: 'GOOD',
        row_end: parseInt(fieldValues.toRow),
        row_start: parseInt(fieldValues.fromRow),
        tier_end: fieldValues.toTier,
        tier_start: fieldValues.fromTier,
        type: 'CELL',
        zone_id: '',
      }

      onSubmit(event, zoneData, cellsData)
      return
    }

    const updateData = {
      id: zoneData?.id || '',
      title: fieldValues.name,
      type: 'STORAGE',
    }

    onUpdate(updateData)
  }

  return (
    <form className={styles.container}>
      <div className={styles.titleRow}>
        <h1 className={styles.title}>
          {popupType === PopupZoneType.create
            ? 'Создание зоны'
            : 'Настройки зоны'}
        </h1>

        {popupType === PopupZoneType.create && (
          <span className={styles.total}>Кол-во ячеек: {levelCount.tier}</span>
        )}
      </div>

      <div className={styles.nameRow}>
        <InputWrapper
          className={styles.name}
          label={'Название зоны'}
          value={fieldValues.name}
          error={fieldErrors.name}
          handleChange={(value) => handleFieldChange(
            value ? value?.toUpperCase() : '',
            'name'
          )}
        />

        {popupType === PopupZoneType.edit && (
          <Button
            className={styles.genButton}
            view={'ghost'}
            label={'Сгенерировать новые ячейки'}
            type={'button'}
            disabled={!levelsChanged}
            onClick={handleGenerateCells}
          />
        )}
      </div>

      <div className={styles.partsRow}>
        <ZonePart
          title={'Ряд'}
          iconNode={<IconFolders view={'ghost'}/>}
          total={levelCount.row}
          valueFrom={fieldValues.fromRow}
          valueTo={fieldValues.toRow}
          fromError={fieldErrors.fromRow}
          toError={fieldErrors.toRow}
          onFromChange={(value) =>
            handleLevelChange(checkInput(value), 'fromRow')
          }
          onToChange={(value) => handleLevelChange(checkInput(value), 'toRow')}
          onClearFrom={() => handleClear('fromRow')}
          onClearTo={() => handleClear('toRow')}
        />

        <ZonePart
          title={'Место'}
          iconNode={<IconFolders view={'ghost'}/>}
          total={levelCount.bay}
          valueFrom={fieldValues.fromBay}
          valueTo={fieldValues.toBay}
          fromError={fieldErrors.fromBay}
          toError={fieldErrors.toBay}
          onFromChange={(value) =>
            handleLevelChange(checkInput(value), 'fromBay')
          }
          onToChange={(value) => handleLevelChange(checkInput(value), 'toBay')}
          onClearFrom={() => handleClear('fromBay')}
          onClearTo={() => handleClear('toBay')}
        />

        <ZonePart
          title={'Позиция'}
          iconNode={
            // <img className={styles.partIcon} src={IconBox} alt="" />
            <IconBox view={'ghost'}/>
          }
          total={levelCount.tier}
          valueFrom={fieldValues.fromTier}
          valueTo={fieldValues.toTier}
          fromError={fieldErrors.fromTier}
          toError={fieldErrors.toTier}
          onFromChange={(value) =>
            handleLevelChange(checkTierInput(value), 'fromTier')
          }
          onToChange={(value) =>
            handleLevelChange(checkTierInput(value), 'toTier')
          }
          onClearFrom={() => handleClear('fromTier')}
          onClearTo={() => handleClear('toTier')}
        />
      </div>

      <div className={styles.buttonsRow}>
        <Button
          label={'Отмена'}
          view={'ghost'}
          type={'button'}
          onClick={() => onClose()}
        />

        <Button
          label={popupType === PopupZoneType.create ? 'Создать' : 'Сохранить'}
          type={'submit'}
          onClick={handleSubmit}
        />
      </div>
    </form>
  )
}

ZoneCreate.displayName = 'ZoneCreate'

export default ZoneCreate
