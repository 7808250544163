import React from 'react'
import { ConfirmActionModal } from '@/components'
import usePackingOrderReset from '@/hooks/orderPackingV2/usePackingOrderReset'
import { useNotifications } from '@shared/providers/NotificationProvider'


interface ResetOrderModalProps {
  documentId: string,
  onClose: () => void,
  refetch: () => void,
}

export const ResetOrderModal = (props: ResetOrderModalProps) => {
  const {
    documentId,
    onClose,
    refetch,
  } = props

  const notification = useNotifications()

  const { mutateAsync: packingOrderReset, isLoading: packingOrderResetLoading } = usePackingOrderReset()

  const handleResetPackingOrder = async () => {
    await packingOrderReset({ orderId: documentId })
      .then(async () => {
        await refetch()
        notification?.show('success', 'Упаковка заказа сброшена')
        onClose()
      })
      .catch(() => {
      })
  }

  return (
    <ConfirmActionModal
      isOpen={true}
      type='warning'
      withPreview={false}
      title={`Вы уверены, что хотите сбросить упаковку заказа ${documentId}?`}
      size={'s'}
      confirmLoading={packingOrderResetLoading}
      confirmWithScan={false}
      onClose={onClose}
      onSubmit={handleResetPackingOrder}
    />
  )
}
