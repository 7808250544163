import React, { FC, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { IconBackward } from '@consta/icons/IconBackward'
import { IconSave } from '@consta/icons/IconSave'
import { Button } from '@shared/ui/btns/Button'

import { DocumentItem, Service } from 'src/interfaces/billing'
import styles from './details.module.scss'
import { saveBase64File } from '@shared/helpers/files'
import useGetBillingAsExcel from 'src/hooks/billing/useGetBillingAsExcel'
import { TableWithCopy } from 'src/components'
import { ITableColumn } from '@/interfaces'
import { replaceTextInAngleBrackets } from '@shared/helpers/string'
import { DangerouslyHTML } from '@shared/ui/DangerouslyHTML'

interface DetailTabProps {
  items?: DocumentItem[]
  services?: Service[]
}

interface IParams {
  page?: string
  id?: string
  tabId?: string
  serviceId?: string
}

const Details: FC<DetailTabProps> = ({ items = [], services = [] }) => {
  const { serviceId } = useParams<{ serviceId: string }>()
  const history = useHistory()
  const params = useParams<IParams>()
  const { fetch: getBillingAsExcel } = useGetBillingAsExcel()
  const [loading, setLoading] = useState<boolean>(false)

  const details =
    items.find((item) => item.service_id === serviceId)?.details || []
  const service = services.find((serv) => serv.id === serviceId)
  const itemId = items?.find((item) => item.service_id === serviceId)?.id

  const rows = details.map((detail) => ({
    id: detail.description,
    description: detail.description,
    quantity: detail.quantity,
    descriptionLink: replaceTextInAngleBrackets(detail.description),
  }))

  const columns: ITableColumn[] = [
    {
      title: 'Документ',
      title_txt: 'Документ',
      key: 'description',
      renderCell: ({ row }) => (
        row.descriptionLink
          ? <DangerouslyHTML withEscaping htmlStr={row.descriptionLink} />
          : row.description
      ),
    },
    {
      title: 'Количество',
      title_txt: 'Количество',
      key: 'quantity',
    },
  ]

  const generateDetailBillingExcel = async () => {
    setLoading(true)
    try {
      if (params.id && itemId) {
        const report = await getBillingAsExcel({
          docId: params.id,
          itemId: itemId,
        })
        if (report) {
          saveBase64File(report.content, report.title)
        }
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <Button
        label={'Вернуться'}
        iconLeft={IconBackward}
        size={'s'}
        onClick={() => history.goBack()}
        className={styles.goBack}
      />
      <TableWithCopy
        columns={columns}
        rows={rows}
        title={service?.title || ''}
        extraHeader={
          <div className={styles.extraHeader}>
            <Button
              label='Сохранить как excel-файл'
              loading={loading}
              iconLeft={IconSave}
              size={'s'}
              onClick={() => generateDetailBillingExcel()}
            />
          </div>
        }
      />
    </div>
  )
}

Details.displayName = 'Details'

export default Details
