import type { FC } from 'react'
import React, { useMemo } from 'react'
import styles from './DirectoriesTable.module.scss'
import { Table, TableProps } from '@consta/uikit/Table'
import { IPagination, ITableColumn } from '@/interfaces'
import { SnackBar } from '@consta/uikit/SnackBar'
import { SnackBarPropItemAction } from '@consta/uikit/__internal__/src/components/SnackBar/types'
import { TableColumnType, TableRowType, TanStackTable } from '@/components/Table/TanStackTable'
import { useParams } from 'react-router-dom'
import { TablePagination, TableWithCopy } from '@/components'
import { LoaderWrapper } from '@/newSrc/shared/ui/LoaderWrapper'
import cx from 'classnames'


interface Props extends TableProps<any> {
  snackBarActions?: SnackBarPropItemAction[]
  withNumeric?: boolean
  newDesign?: boolean
  withCreate?: boolean
  withCopy?: boolean
  tableCopy?: boolean
  isLoading?: boolean
  pagination?: IPagination
  extraHeader?: React.ReactNode
  onRowCreate?: () => void
  onChangePage?: (page: number) => void
  maxHeight?: number

  withRowSelection?: boolean
  setSelectedRows?: React.Dispatch<React.SetStateAction<TableRowType[]>>,
}

const DirectoriesTable: FC<Props> = (props) => {
  const {
    withNumeric,
    snackBarActions,
    withCopy = false,
    tableCopy = false,
    newDesign,
    isLoading,
    pagination,
    onChangePage = () => {
    },
    onRowCreate,
    maxHeight,
    extraHeader,

    withRowSelection,
    setSelectedRows,

    ...restProps
  } = props

  const {
    id, page, section, type,
  } = useParams<{ id: string, section: string, type: string, page: string }>()

  const memoRows = useMemo(() => {
    return restProps.rows
  }, [restProps.rows])

  const memoColumns = useMemo(() => {
    if (newDesign) {
      return restProps.columns.map((col: any) => {
        const isAction = col.accessor.includes('__action')
        return ({
          id: col.accessor,
          accessorKey: col.accessor,
          header: col.title,
          size: col.width ? col.width : isAction ? 100 : undefined,
          enableHiding: col.enableHiding !== undefined ? col.enableHiding : !isAction,
          enableResizing: !isAction,
          meta: {
            headerText: col.title,
            generateLink: (row, column) => row.original.__custom_hrefLink,
          },
          cell: ({ row }) => col.renderCell ? col.renderCell(row) : row.getValue(col.accessor),
        })
      }) as TableColumnType[]
    }
  }, [restProps.columns])

  return (
    <div className={styles.tableContainer}>
      <LoaderWrapper isLoading={isLoading}>
        {
          newDesign ? (
            <TanStackTable
              withRowSelection={withRowSelection ? true : undefined}
              onRowSelectionChange={setSelectedRows}
              withHeader={extraHeader ? true : undefined}
              extraHeader={extraHeader}
              withNumerableRows={withNumeric}
              id={`directoriesTable-${section}-${id}`}
              pageId={'derictories'}
              maxHeight={'800px'}
              data={memoRows}
              columns={memoColumns}
              withPagination
              withCopy={withCopy}
              isLoading={isLoading}
              pagination={pagination}
              onChangePage={onChangePage}
            />
          ) : (
            <>
              {
                !withCopy && !tableCopy ? (
                  <div style={{ height: maxHeight }}>
                    <Table
                      className={cx(styles.table, restProps.className)}
                      stickyHeader
                      borderBetweenColumns
                      borderBetweenRows
                      onRowCreate={onRowCreate}
                      {...restProps}
                    />
                  </div>
                ) : (
                  <TableWithCopy
                    extraHeader={extraHeader}
                    withCopy={withCopy}
                    withVirtualScroll={true}
                    rows={restProps.rows}
                    columns={restProps.columns.map((col: any) => ({
                      key: col.accessor,
                      title: col.title,
                      title_txt: col.title,
                      isAction: col.isAction,
                      withHeaderAction: col.withHeaderAction,
                      sortable: col.sortable,
                      sortKey: col.sortKey,
                      gridCellWidth: col.gridCellWidth,
                      renderCell: col.renderCell,
                    })) as ITableColumn[]}
                    maxHeightBody={maxHeight}
                    withCreate={Boolean(onRowCreate)}
                    onCreateItem={onRowCreate}
                  />
                )
              }
              {
                pagination ? (
                  <TablePagination
                    currentPage={pagination?.page}
                    totalPages={pagination?.total_pages}
                    onChangePage={onChangePage}
                  />
                ) : null
              }
            </>
          )
        }
      </LoaderWrapper>
      {
        snackBarActions?.length ? (
          <SnackBar
            className={styles.snackBar}
            items={[
              {
                key: 1,
                message: 'Что сделать с выбранными элементами?',
                status: 'system',
                actions: snackBarActions,
              },
            ]}
          />
        ) : null
      }
    </div>
  )
}

export default DirectoriesTable
