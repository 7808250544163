import React from 'react'

import styles from './table-header.module.scss'
import cx from 'classnames'
import { IconSelect } from '@consta/icons/IconSelect'
import { Text } from '@shared/ui/Text'
import { Flex } from '@/newSrc/shared/ui/Flex'
import { BoolConditionalProps } from '@shared/types/helpers/condition'


type ToggleVisibleProps = BoolConditionalProps<'withVisibleToggle', { onVisibleToggle: () => void, tableVisible: boolean }>

type IProps = {
  componentRef?: React.RefObject<HTMLDivElement>
  title?: React.ReactNode
  subtitle?: React.ReactNode
  titleClassName?: string
  extraHeader?: React.ReactNode
} & ToggleVisibleProps

const TableHeader = React.memo((props: IProps) => {
  const {
    componentRef,
    title,
    subtitle,
    extraHeader,
    titleClassName,
    withVisibleToggle,
    onVisibleToggle,
    tableVisible
  } = props
  return (
    <Flex
      ref={componentRef}
      className={styles.header}
      align={'center'}
      justifyContent={'between'}
      gap={'s'}
    >
      <Flex direction={'column'} gap={'2xs'}>
        {title ? (
          <Flex gap={'2xs'}>
            <Text
              as={'h4'}
              className={cx(styles.title, { [styles.pointer]: withVisibleToggle }, titleClassName)}
              onClick={onVisibleToggle}
              children={title}
            />
            {withVisibleToggle ? (
              <IconSelect className={cx(styles.toggleIcon, { [styles.tableVisible]: tableVisible })} />
            ) : null}
          </Flex>
        ) : null}
        {subtitle ? (
          <Text as={"h5"} size={'s'} className={cx(styles.subtitle)}>{subtitle}</Text>
        ) : null}
      </Flex>
      {extraHeader ? extraHeader : null}
    </Flex>
  )
})
export default TableHeader
