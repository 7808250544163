import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { RoleCreateProps } from './types'
import { IPrivileges } from '../../pages/RoleSection/types'

import { Checkbox } from '@consta/uikit/Checkbox'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { Button } from '@shared/ui/btns/Button'
import { InputWrapper } from '@/components'

import styles from './role-create.module.scss'

const RoleCreate: FC<RoleCreateProps> = ({
  privilegesList,
  onClose,
  roleId,
  rolesData,
  onSubmit = () => {},
}) => {
  const [roleName, setRoleName] = useState<TextFieldPropValue>('')
  const [privileges, setPrivileges] = useState<IPrivileges>({})
  const [error, setError] = useState('')

  const handleChange = (value: TextFieldPropValue) => {
    setError('')
    setRoleName(value)
  }

  const handleChangePrivilege = (checked: boolean, value: string) => {
    setError('')

    setPrivileges((prevState) => ({
      ...prevState,
      [value]: {
        ...prevState[value],
        checked,
      },
    }))
  }

  const handleSubmit = () => {
    if (!roleName || !roleName.length) {
      return setError('Поле не может быть пустым')
    }

    const checkedPrivileges = Object.values(privileges).reduce(
      (acc: string[], privilege) => {
        if (privilege.checked) {
          acc.push(privilege.value)
        }

        return acc
      },
      [],
    )

    if (!checkedPrivileges.length) {
      return setError('Не выбраны привилегии роли')
    }

    const roleData = {
      title: roleName,
      privileges: checkedPrivileges,
    }

    onSubmit({ roleId, roleData })
  }

  useEffect(() => {
    setPrivileges(privilegesList)

    if (!roleId) return
    const item = rolesData.find((el) => el.id === roleId)

    if (!item) return
    setRoleName(item?.title)

    const editPrivelegesList = { ...privilegesList }
    item.privileges.forEach((el) => {
      if (editPrivelegesList[el]) {
        editPrivelegesList[el].checked = true
      }
    })
    setPrivileges(editPrivelegesList)
  }, [privilegesList])

  return (
    <div className={styles.container}>
      <div>
        <h2 className={styles.title}>Добавление новой роли</h2>
        <div className={styles.subtitle}>
          Выберите список привилегий для новой роли
        </div>
      </div>

      <div className={styles.nameRow}>
        <div className={styles.name}>
          <InputWrapper
            size={'s'}
            label={'Название роли'}
            value={roleName}
            error={error}
            isRequired
            handleChange={(value) => handleChange(value)}
          />
        </div>
      </div>

      <div className={styles.privileges}>
        {Object.values(privilegesList).map((privilege) => (
          <Checkbox
            size={'s'}
            className={styles.privilege}
            key={privilege.value}
            label={privilege.title}
            checked={privileges[privilege.value]?.checked}
            onChange={(e) =>
              handleChangePrivilege(e.target.checked, privilege.value)
            }
          />
        ))}
      </div>

      <div className={styles.buttonsRow}>
        <Button
          size={'s'}
          label={'Отмена'}
          view={'ghost'}
          type={'button'}
          onClick={onClose}
        />

        <Button
          size={'s'}
          label={'Сохранить'}
          type={'submit'}
          onClick={handleSubmit}
        />
      </div>
    </div>
  )
}

RoleCreate.displayName = 'RoleCreate'

export default RoleCreate
