import { useQuery } from 'react-query'

import api from '@shared/api'
import { ApiReqResult, IEnumValue } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'

interface IGetDocumentStatusesEnumResult extends ApiReqResult {
  /** Значения  */
  values: IEnumValue[]
}

const useGetVATEnum = (options?: UseQueryOptions<any>) => {
  return useQuery<IGetDocumentStatusesEnumResult>(
    [`getGetVATEnum`],
    async () => {
      const result = await api.get(`/enum/vat`)
      return result.data
    },
    options,
  )
}

export default useGetVATEnum
