import styles from './NameCell.module.scss'
import React, { CSSProperties, FC } from 'react'
import cx from 'classnames'
import { generatePath, Link } from 'react-router-dom'
import { PATHS } from '@shared/routing'
import { Text } from '@shared/ui/Text'
import { SECTIONS, tabs } from '@pages/DirectoriesPage/constants'
import { generateFormDocumentPath } from '@shared/routing/config/paths'

export const NameCell = (props: {
  title: string
  className?: string,
  style?: CSSProperties
}) => {
  const {
    className,
    title,
    style
  } = props
  return (
    <div className={cx(styles.nomenclature, className)}>
      <Text as={'p'} style={style}>{title}</Text>
    </div>
  )
}

interface BaseLinkInterface {
  title: React.ReactNode
  withNewTab?: boolean
}

interface SkuCellProps extends BaseLinkInterface {
  itemId: string
}

export const SkuLinkCell: FC<SkuCellProps> = (props) => {
  const {
    title,
    itemId,
    withNewTab,
  } = props
  const linkPath = generatePath(PATHS.DIRECTORIES, {
    page: tabs.guides.value,
    section: SECTIONS.nomenclature.value,
    type: 'item',
    id: itemId || '-',
  })
  return (
    <LinkCell
      link={linkPath}
      title={title}
      withNewTab={withNewTab}
    />
  )
}
interface DocumentLinkCellProps extends BaseLinkInterface {
  docId: string
  docType: string
}
export const DocumentLinkCell: FC<DocumentLinkCellProps> = (props) => {
  const {
    title,
    docId,
    docType,
    withNewTab,
  } = props
  const linkPath = generateFormDocumentPath({
    docType: docId,
    docId: docType,
  })
  return (
    <LinkCell
      link={linkPath}
      title={title}
      withNewTab={withNewTab}
    />
  )
}


interface LinkCellProps extends BaseLinkInterface {
  link: string
}


export const LinkCell: FC<LinkCellProps> = (props) => {
  const {
    title,
    link,
    withNewTab,
  } = props
  return (
    <Link
      to={link}
      onClick={(e) => {
        if (withNewTab) {
          e.preventDefault()
          window.open(link, '_blank')?.focus()
        }
      }}
    >
      {title}
    </Link>
  )
}