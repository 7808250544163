import React from 'react';
import cx from 'classnames'
import styles from "./Divider.module.scss"


interface DividerProps {

}

export const Divider = (props: DividerProps) => {
	return (
		<div className={cx(styles.Divider)} />
	);
};
