import React, { FC, useCallback, useEffect, useState } from 'react'

import { IPositionRes, IRole, IRoleRes, IUserRender, IUserRes } from './types'

import type { ISection } from '../../types'

import { IProps as UserCreateReqProps } from '@/hooks/warehouse/useCreateUser'
import { IPropsEdit as UserEditReqProps } from '@/hooks/warehouse/useEditUser'

import { useCreateUser, useEditUser, useGetQrCode, useGetWHUsers, useSendUserSignature } from '@/hooks'
import { useFilterState, usePreviewBase64Pdf, usePrint } from '@shared/hooks'
import { IconAdd } from '@consta/icons/IconAdd'
import { IconEdit } from '@consta/icons/IconEdit'
import { Button } from '@shared/ui/btns/Button'
import { Badge } from '@consta/uikit/Badge'
import { Modal, PrintPreviewCheckbox } from '@/components'
import UserModal from '../../components/UserModal/UserModal'
import styles from './users-section.module.scss'
import { IPaginationFilter, PrintTypeEnum } from '@/interfaces'
import { useNotifications } from '@shared/providers/NotificationProvider'
import { getFullPersonName } from '@shared/helpers'
import { getUsersTableColumns } from './tableData'
import { DirectoriesLayout } from '@pages/DirectoriesPage/components/DirectoriesLayout/DirectoriesLayout'
import { Flex } from '@shared/ui/Flex'

const UsersSection: FC<ISection> = ({
  className = '',
  title = '',
}) => {
  const notification = useNotifications()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [users, setUsers] = useState<IUserRender[]>([])
  const [roles, setRoles] = useState([])
  const [positions, setPositions] = useState([])
  const [user, setUser] = useState<IUserRes | null>(null)
  const [usersParams, setUsersParams] = useState<IPaginationFilter>({})
  const { printSticker } = usePrint({})

  const [printType, setPrintType] = useState<PrintTypeEnum>(PrintTypeEnum.print)
  const { previewBase64Pdf } = usePreviewBase64Pdf()


  const {
    filterStateDebounced,
    filterState,
    handleChangeFilter,
  } = useFilterState({
    withoutHistory: true, onChangeFilterState: () => {
       setUsersParams({ page: 0 })
    },
  })


  const {
    data,
    refetch: refetchUsersData,
  } = useGetWHUsers({ reqParams: { usersParams: { ...usersParams, search: filterState['search'], size: 50 } } })

  const { mutate: createUser, isLoading: isUserCreating } = useCreateUser()
  const { mutate: editUser, isLoading: isUserEdit } = useEditUser()
  const { mutate: sendSignature, isLoading: isSignatureSending } = useSendUserSignature()
  const getQrCode = useGetQrCode();


  const handleQrCode = (id: string) => {
    getQrCode.mutateAsync({id: id}, {
      onSuccess: (data) => {
        if (!data) {
          return
        }
        if (printType === PrintTypeEnum.preview) {
          previewBase64Pdf(data.content, 'QR пользователя')
          return
        }
        print(data.printer, data.content)
      }
    })
  }

  const columns = getUsersTableColumns(users, handleQrCode, getQrCode.isLoading)

  const onUserCreate = (
    userData: UserCreateReqProps,
    signatureFile: string | null,
    fileName: string,
  ) => {
    createUser(userData, {
      onSuccess: (data) => {
        if (signatureFile) {
          sendSignature({ id: data?.data?.id, data: { data: signatureFile, title: fileName} })
        }
        refetchUsersData()
      },
    })
  }
  const onUserEdit = (
    userData: UserEditReqProps,
    signatureFile: string | null,
    fileName: string,
  ) => {
    editUser(userData, {
      onSuccess: (data) => {
        if (signatureFile) {
          sendSignature({ id: data?.data?.id, data: { data: signatureFile, title: fileName} })
        }
        refetchUsersData()
      },
    })
  }

  const print = (printer: string, content: string) => {
    notification?.show(
      'success',
      `Стикеры получены и отправлены на печать`,
    )

    printSticker(printer, content)
  }


  const renderCreateUserModalContent = () => {
    return (
      <UserModal
        positions={positions}
        roles={roles}
        onSubmit={onUserCreate}
        onClose={() => setIsModalOpen(false)}
      />
    )
  }
  const renderEditUserModalContent = () => {
    return (
      <UserModal
        editMode
        positions={positions}
        roles={roles}
        user={user}
        onSubmit={onUserEdit}
        onClose={() => setIsModalEditOpen(false)}
      />
    )
  }
  const onSubmitEdit = (user: IUserRes) => {
    setUser(user)

    setIsModalEditOpen(true)
  }

  useEffect(() => {
    if (!data || !data.length) {
      return
    }

    const [usersData, rolesData, positionsData] = data

    const usersSet: IUserRender[] = usersData.users.map((user: IUserRes) => {
      let fio =  getFullPersonName({
        surname: user.surname, name: user.name, patronymic: user.patronymic
      })

      const Fio = <div className={styles.cell}>{fio}</div>
      const Login = <div className={styles.cell}> {user.login || ''}</div>
      const Password = (
        <div className={styles.cell}>{user.password || '******'}</div>
      )
      const Roles = (
        <div className={styles.badgeContainer}>
          {user.roles.map((roleId) => {
            const role = rolesData?.roles?.find(
              (roleItem: IRole) => roleItem.id === roleId,
            )
            return (
              <Badge
                size={'s'}
                key={roleId}
                label={role?.title}
                view={'filled'}
                status={'system'}
              />
            )
          })}
        </div>
      )

      const positionTitle =
        positionsData.values.find(
          (positionItem: IPositionRes) => positionItem.value === user.position,
        )?.title || ''
      const Position = <div className={styles.cell}>{positionTitle}</div>
      const Edit = (
        <Button
          onlyIcon
          size={'xs'}
          iconSize={'s'}
          view={'ghost'}
          iconLeft={IconEdit}
          loading={isUserEdit || isSignatureSending}
          onClick={() => onSubmitEdit(user)}
        />
      )
      return {
        ...user,
        fio,
        fioEl: Fio,
        loginEl: Login,
        companyEl: user?.company?.title,
        passwordEl: Password,
        rolesEl: Roles,
        id__action: user.id,
        positionEl__action: Position,
        buttonEl__action: Edit,
      }
    })

    const mappedRoles =
      rolesData?.roles?.map((role: IRoleRes) => ({
        ...role,
        label: role.title,
      })) || []

    const mappedPositions = positionsData.values.map(
      (position: IPositionRes) => ({
        ...position,
        label: position.title,
      }),
    )

    setUsers(usersSet)
    setRoles(mappedRoles)
    setPositions(mappedPositions)
  }, [data])

  const onCreateBtnClick = useCallback(
    () => setIsModalOpen(true),
    [setIsModalOpen],
  );

  return (
    <>
      <DirectoriesLayout
        withTable
        withFilter
        withSearch
        withCreate
        createBtnNode={
          <Button
            label={'Добавить пользователя'}
            iconLeft={IconAdd}
            size={'s'}
            iconSize={'s'}
            loading={isUserCreating || isSignatureSending}
            onClick={onCreateBtnClick}
          />
        }

        title={title}

        extraHeader={
          <PrintPreviewCheckbox
            onChange={setPrintType}
          />
        }

        filterState={filterState}
        handleChangeFilter={handleChangeFilter}

        isLoading={isUserCreating || isSignatureSending}
        columns={columns}
        rows={users}
        pagination={data?.[0]?.pagination}
        onChangePage={(page) => setUsersParams({ page })}
      />

      <Modal
        isOpen={isModalOpen}
        hasOverlay
        size={'m'}
        onClickOutside={() => setIsModalOpen(false)}
      >
        <Flex justifyContent={'center'}>
          {renderCreateUserModalContent()}
        </Flex>
      </Modal>
      <Modal
        isOpen={isModalEditOpen}
        hasOverlay
        size={'m'}
        onClickOutside={() => setIsModalEditOpen(false)}
      >
        <Flex justifyContent={'center'}>
          {renderEditUserModalContent()}
        </Flex>
      </Modal>
    </>
  )
}

UsersSection.displayName = 'UsersSection'

export default UsersSection
