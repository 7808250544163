import api from '@shared/api'

import { ApiReqResult } from 'src/interfaces'
import { useMutation } from 'react-query'
import { UseMutationOptions } from 'react-query/types/react/types'

export interface IResult extends ApiReqResult {
}
interface IParams {
  contractorId: string
}
const deleteContractor = async (params: IParams) => {
  const result = await api.delete(`/contractor${params.contractorId}`)
  if (!result?.data?.success) throw result
  return result.data
}

const useDeleteContractor = (options: UseMutationOptions<IResult, unknown, IParams> = {}) =>
  useMutation<IResult, unknown, IParams>(
    async (params: IParams) => await deleteContractor(params),
    options
  )

export default useDeleteContractor