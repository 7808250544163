import api from '@shared/api'
import { orderItemsEnum } from '@widgets/modals/DefineMultipleParamsModal'

interface IPropsItems {
  type: string,
  subtype: string
  data: string[]
}

const getItemsByFilters = async (props: IPropsItems) => {
  const { type, subtype, data } = props
  const definePath = (type: string | undefined) => {
    if (type === orderItemsEnum.nomenclature) {
      return `/items/list`
    } else if (type === orderItemsEnum.cell) {
      return `/warehouse/cell/list`
    } else if (type === orderItemsEnum.order) {
      return `/order/list`
    }
  }

  // @ts-ignore type не может быть undefined
  const result = await api.post(definePath(type), {
    filter: {
      [subtype]: data
    },
    without_pagination: true
  })

  if (!result.data?.success) {
    throw result
  }
  return result.data || []
}

export const useFetchItemsByFilters = () => ({
  fetch: (items: IPropsItems) => getItemsByFilters(items)
})
