import { useState } from 'react'
import { VisibilityState } from '@tanstack/table-core/src/features/ColumnVisibility'
import { useToggle } from '@shared/hooks'
import { CustomSortingState } from '@/components/Table/TanStackTable/lib/features/customSorting/types'
import { RowSelectionState } from '@tanstack/table-core/src/features/RowSelection'
import { ContextMenuState } from '@/components/Table/TanStackTable/lib/features/contextMenu/types'
import {
  getContextMenuInitialState,
} from '@/components/Table/TanStackTable/lib/features/contextMenu/ContextMenuFeature'
import { CopyState } from '@/components/Table/TanStackTable/lib/features/copyFeature/types'
import { getDefaultCopyState } from '@/components/Table/TanStackTable/lib/features/copyFeature/CopyFeature'
import { DensityState } from '../lib/features/density/types'
import { useDefaultSettings } from '@shared/hooks/settings'

type Props = {
  tableId?: string,
  pageId?: string,
  extraDensity?: string | DensityState
  columnsVisibleHiddenDefault?: VisibilityState
  onCheckboxChange?: (state: RowSelectionState) => void
  onSortingChange?: (state: CustomSortingState) => void
}

const contextMenuDefault = getContextMenuInitialState()
export const useTableState = (props: Props) => {
  const {
    tableId,
    pageId,
    extraDensity,
    columnsVisibleHiddenDefault,
  } = props

  const {
    defaultCommonTableSettings,
    getTableDefaultDensityByTable,
    getTableDefaultDensityByPage,
    getTableDefaultHiddenColumns,
  } = useDefaultSettings()

  /** Размер ячеек */
  const [density, setDensity] = useState<DensityState>((
    extraDensity
    || getTableDefaultDensityByTable(tableId)
    || getTableDefaultDensityByPage(pageId)
    || (defaultCommonTableSettings?.density?.forAllTables)
    || 'm'
  ) as DensityState)

  /** Стейт копирования */
  const [copyState, setCopyState] = useState<CopyState>(getDefaultCopyState())

  /** Выбранные элементы таблицы */
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  /** Стейт кеширования максимальной глубины */
  const [tableRowsMaxDepth, setTableRowsMaxDepth] = useState<number>(0)

  /** Стейт контекстного меню */
  const [contextMenu, setContextMenu] = useState<ContextMenuState>(contextMenuDefault)

  /** Скрытие/открытие столбцов таблицы */
  const defaultHiddenColumnsLS = getTableDefaultHiddenColumns(tableId)
    ?.reduce((acc, columnId) => ({ ...acc, [columnId]: false}), {})
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    ...(columnsVisibleHiddenDefault || {}),
    ...defaultHiddenColumnsLS,
  })

  /** Открытие менющки таблицы */
  const {
    value: visibleTableSettings,
    setValue: setTableSettingsVisible,
  } = useToggle()

  const {
    value: isFullScreenOn,
    setValue: setFullScreen
  } = useToggle()


  return {
    densityState: { density, setDensity },
    copyTableState: { copyState, setCopyState },
    rowSelectionState: { rowSelection, setRowSelection },
    tableRowsMaxDepthState: { tableRowsMaxDepth, setTableRowsMaxDepth },
    contextMenuState: { contextMenu, setContextMenu },
    columnsVisibilityState: { columnVisibility, setColumnVisibility },
    tableSettingsMenu: { visibleTableSettings, setTableSettingsVisible },
    fullScreenState: {isFullScreenOn, setFullScreen}
  }
}