import { SUPPLY_STATUSES } from '@/interfaces/statuses'
import { IconCalendar } from '@consta/icons/IconCalendar'
import { FieldRow, FieldType } from '@widgets/GeneratorFields'
import { generateStatusesOptions } from '@shared/helpers'


export const generateFilters = (): FieldRow[] => [
  {
    hidden: true,
    fields: [
      {
        type: 'input',
        fieldProps: {
          id: 'search',
          viewDescription: 'Поиск',
        },
      },
    ],
  },
  {
    fields: [
      {
        jointFields: [
          {
            type: 'calendar',
            fieldProps: {
              id: 'from',
              label: 'Дата',
              placeholder: 'C',
              form: 'defaultBrick',
              rightSide: IconCalendar,
              viewDescription: 'С',
            },
          },
          {
            type: 'calendar',
            fieldProps: {
              id: 'to',
              placeholder: 'По',
              form: 'clearDefault',
              rightSide: IconCalendar,
              viewDescription: 'По',
            },
          },
        ],
      },
      {
        type: FieldType.MERCHANTS,
        fieldProps: {
          id: 'merchant',
          isMultiple: true,
          label: 'Организация',
          viewDescription: 'Организация',
        },
      },
      {
        type: 'combobox',
        fieldProps: {
          id: 'status',
          isMultiple: true,
          label: 'Статус док-та',
          items: generateStatusesOptions(SUPPLY_STATUSES),
          viewDescription: 'Статус док-та',
        },
      },
    ],
  },
]
