import { ISku } from '@/interfaces'
import { TableColumn } from '@consta/uikit/Table'
import { Link } from 'react-router-dom'
import React from 'react'
import { ImageCell } from '@/components'
import { Text } from '@shared/ui/Text'
import { generateDirectoriesPath } from '@shared/routing/config/paths'

export const generateTableData = (
  skuItems: ISku[],
  page: string,
  section: string
) => {
   const rows = skuItems.map((item) => {
    const org = item.merchant?.title || ''

    return ({
      id: item.id,
      image: item.image,
      title: item.title,
      org,
      barcode: item.barcodes[0]?.barcode || '',
      __custom_hrefLink: generateDirectoriesPath({page, section, type: 'item', id: item.id})
    })
  })

  const columns: TableColumn<typeof rows[number]>[] = [
    {
      title: 'Изображение',
      accessor: 'image',
      width: 150,
      renderCell: (row: any) => {
        return (
          <ImageCell size={row.getTableDensity()} image={row.image} />
        )
      }
    },
    {
      title: 'ШК',
      accessor: 'barcode',
      width: 200,
    },
    {
      title: 'Название',
      accessor: 'title',
      width: 500,
      renderCell: (row: any) => (
        <Link to={row.original.__custom_hrefLink}>
          <Text size={row.getTableDensity()} view={'link'} children={row.original.title} />
        </Link>
      )
    },
    {
      title: 'Организация',
      accessor: 'org',
      width: 400,
    }
  ]
  return {rows, columns}
}