import React from 'react'

import { IconUnlock } from '@consta/icons/IconUnlock'
import { IconLock } from '@consta/icons/IconLock'
import { Button } from '@shared/ui/btns/Button'
import { IconPropSize, ButtonPropSize, ButtonPropView } from '@/newSrc/shared/ui/btns/Button'

interface AdvanceModeLockProps {
  size?: ButtonPropSize
  view?: ButtonPropView
  iconSize?: IconPropSize
  locked?: boolean
  className?: string
  loading?: boolean;
  onClick?: React.EventHandler<React.MouseEvent>;
}

export const LockButton = (props: AdvanceModeLockProps) => {
  const {
    view = 'clear',
    size = 's',
    iconSize = 'm',
    className,
    locked,
    onClick,
    loading
  } = props


  return (
    <Button
      onlyIcon
      iconLeft={locked ? IconLock : IconUnlock }
      view={view}
      size={size}
      iconSize={iconSize}
      className={className}
      onClick={onClick}
      loading={loading}
    />
  )
}