import { useQuery } from 'react-query'

import api from '@shared/api'

import { ApiReqResult, IPaginationBase, PaginationResult } from 'src/interfaces'
import { convertObjectToURLParams } from '@shared/helpers'
import { UseQueryOptions } from 'react-query/types/react/types'
import { UserCompanyDto } from '@entities/UserCompany'


interface GetUserCompanyListParams extends Partial<IPaginationBase> {
  search?: string
}

interface IResult extends ApiReqResult, PaginationResult {
  companies: UserCompanyDto[]
}

const getUserCompanyList = async (params: GetUserCompanyListParams = {}): Promise<IResult> => {
  const searchParams = convertObjectToURLParams(params)
  const result = await api.get(`/warehouse/user-company${searchParams}`)
  if (!result?.data?.success) {
    throw result
  }
  return result.data
}


const useGetUserCompanyList = (
  params?: GetUserCompanyListParams,
  options?: UseQueryOptions<any>
) => {
  return useQuery<IResult>(
    [`getUserCompanyList`, params],
    async () => getUserCompanyList(params),
    {
      keepPreviousData: true,
      ...options,
    },
  )
}

export default useGetUserCompanyList
