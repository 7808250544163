import { useMutation } from 'react-query'
import api from '@shared/api'

import { ApiReqResult, StickerWithCopies } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'


interface Params {
  orderId: string
  data: {
    additional_sku_sticker_quantity?: number
  }
}
const editPackingOptions = async (params: Params) => {
  const result = await api.post(`/packing-v2/order/${params.orderId}/options`, params.data)
  if (!result.data?.success) {
    throw result
  }
  return result.data
}

interface Result extends ApiReqResult {
  files_for_print: StickerWithCopies[]
}

const useEditPackingOptions = (
  options?: UseMutationOptions<Result, any, any>,
) => {
  return useMutation((params: Params) => editPackingOptions(params), options)
}

export default useEditPackingOptions
