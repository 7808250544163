import { BadgePropStatus } from '@consta/uikit/Badge'
import { BatchStatusesEnum, fbsBatchStatuses } from 'src/interfaces'


export const FBS_BATCH_STATUSES_DESCRIPTION: Record<BatchStatusesEnum | string, string> = {
  [fbsBatchStatuses.in_preparation]: 'На подготовке',
  [fbsBatchStatuses.prepared]: 'Подготовлен',
  [fbsBatchStatuses.in_selection]: 'На подборе',
  [fbsBatchStatuses.selected]: 'Подобран',
  [fbsBatchStatuses.in_sorting]: 'На сортировке',
  [fbsBatchStatuses.sorted]: 'Отсортирован',
  [fbsBatchStatuses.in_packaging]: 'На упаковке',
  [fbsBatchStatuses.packaged]: 'Упакован',
  [fbsBatchStatuses.in_shipment]: 'Готов к доставке',
  [fbsBatchStatuses.shipped]: 'Доставлен',
}

export const FBS_BATCH_STATUS_BADGE_TYPE: Record<BatchStatusesEnum | string, BadgePropStatus> = {
  [fbsBatchStatuses.prepared]: 'system',
  [fbsBatchStatuses.in_selection]: 'normal',
  [fbsBatchStatuses.selected]: 'normal',
  [fbsBatchStatuses.in_sorting]: 'normal',
  [fbsBatchStatuses.sorted]: 'normal',
  [fbsBatchStatuses.in_packaging]: 'success',
  [fbsBatchStatuses.packaged]: 'normal',
  [fbsBatchStatuses.in_shipment]: 'normal',
  [fbsBatchStatuses.shipped]: 'normal',
  [fbsBatchStatuses.in_preparation]: 'normal',
}

export const FBS_BATCH_STATUSES: {
  value: BatchStatusesEnum
  label: string
  style: BadgePropStatus
}[] = Object.keys(FBS_BATCH_STATUSES_DESCRIPTION).map(key => ({
  value: key as BatchStatusesEnum,
  label: FBS_BATCH_STATUSES_DESCRIPTION[key as keyof object],
  style: FBS_BATCH_STATUS_BADGE_TYPE[key as keyof object]
}))

