import React, { useEffect, useMemo, useState } from 'react'
import styles from './documents-params.module.scss'
import { InputWrapper } from 'src/components'
import { useParams } from 'react-router-dom'
import { OperAndDocumPagesType } from '../../../../../pagesType'
import { convertTrustBoxToReceiptItems, generateColumns, generateRows } from './utils'
import {
  CommonSerialNumberFilled,
  DocumentTypeEnum,
  IDefectType,
  ISelectOption,
  ISupplyConditionDescription,
  ISupplyReceiptItem,
  RelatedDocument,
} from 'src/interfaces'
import { DocumentsParams, DocumentStatusField, SerialNumbersModal } from '../../../../../components'
import { updateFormStateDraftAction } from '../../../../../store/actions'
import { useReceiptFormContext } from '@pages/OperationsAndDocumentsPage/context/ReceiptFormContext'
import { useAddSupplyPartItemToTare, useGetDefectType } from 'src/hooks'
import { Button } from '@shared/ui/btns/Button'
// import { Button } from '@consta/uikit/Button'
import AddNewItemModal from '../AddNewItemModal'

interface SupplyFormContentProps {
  document: RelatedDocument | undefined
  refetch: () => void
}


export const ACCEPTANCE_TYPES_DROPDOWN_OPTIONS: ISelectOption[] = [
  {
    value: 'PIECE',
    label: 'Поштучно',
  },
  {
    value: 'BOX',
    label: 'По коробам',
  },
  {
    value: 'PALLET',
    label: 'По паллетам',
  },
]


const DocumentsParamsFields = (props: SupplyFormContentProps) => {
  const { page, id: receiptId } = useParams<{
    page: OperAndDocumPagesType
    id: string
    tabId: string
  }>()
  const [defectTypes, setDefectTypes] = useState<IDefectType[]>([])
  const { state, dispatch } = useReceiptFormContext()
  const { fetch: fetchDefectTypes } = useGetDefectType()
  const { document, refetch } = props
  const [draftItems, setDraftItems] = useState<
    ISupplyReceiptItem[] | null | undefined
  >(state?.documentRaw?.items)
  const [changedIds, setChangedIds] = useState<string[]>([])

  const addSupplyPartItemToTare = useAddSupplyPartItemToTare({
    supplyId: document?.related_documents[0]?.id,
  })
  const [isModalShown, setIsModalShown] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState(false)
  const [serialNumbers, setSerialNumbers] = useState<CommonSerialNumberFilled[]>([])
  const [acceptanceOption, setAcceptanceOption] = useState<ISelectOption | null>(null)

  useEffect(() => {
    ;(async function() {
      const types = await fetchDefectTypes()
      setDefectTypes(types)
    })()
  }, [])

  useEffect(() => {
    if (!state?.documentRaw?.items) return
    const receiptItems = convertTrustBoxToReceiptItems(state?.documentRaw?.items, state?.trustBoxes)
    setDraftItems(receiptItems)
  }, [state.documentRaw])

  const handleOpenModal = (sn: CommonSerialNumberFilled[]) => {
    setSerialNumbers(sn)
    setOpenModal(true)
  }
  const handleCloseModal = () => {
    setOpenModal(false)
    setSerialNumbers([])
  }

  const handleUpdateItem = async (newItems: string[]) => {
    if (changedIds.length === 0) return false

    const updatingItems = draftItems?.filter((item) =>
      newItems.includes(item.sku_id),
    )

    if (updatingItems?.length && document?.related_documents[0].id) {
      Promise.all(updatingItems.map(async (item, i) => {
        return await addSupplyPartItemToTare.mutateAsync({
          items: [updatingItems[i]],
          receiptId: receiptId,
        })
      }))
        .then(() => {
          refetch()
        })
        .catch(() => {
        })
    }
  }

  const handleAddItem = async (newItem: any) => {
    await addSupplyPartItemToTare.mutate({
      items: [newItem],
      receiptId: receiptId,
    })
  }

  const handleChangeDraftItem = (
    id: string,
    value: string | null,
    key: string,
  ) => {
    const newValue = draftItems?.map((item) =>
      item.sku_id === id
        ? { ...item, [key]: value ? parseInt(value) : null }
        : item,
    )
    setDraftItems(newValue)
    setChangedIds([...changedIds, id])
  }

  const handleAcceptanceType = (
    id: string,
    value: ISelectOption,
  ) => {
    const newValue = draftItems?.map((item) =>
      item.sku_id === id
        ? { ...item, acceptance_type: value ? value.value : null }
        : item,
    )
    // @ts-ignore
    setDraftItems(newValue)
    setAcceptanceOption(value as ISelectOption)
    setChangedIds([...changedIds, id])
  }

  const rows = useMemo(
    () =>
      generateRows(
        state.documentRaw,
        state.originalSkus,
        state.trustBoxes,
        defectTypes,
        document,
        draftItems,
        handleChangeDraftItem,
        acceptanceOption,
        handleAcceptanceType,
      ),
    [
      state.documentRaw,
      state.originalSkus,
      state.trustBoxes,
      document,
      draftItems,
      handleChangeDraftItem,
      acceptanceOption,
      handleAcceptanceType,
    ],
  )
  const columns = useMemo(() => generateColumns(handleOpenModal), [])

  return (
    <>
      <DocumentsParams
        rows={rows}
        columns={columns}
        tableExtraHeader={
          <Button
            type='button'
            label='Сохранить изменения'
            onClick={() => handleUpdateItem(changedIds)}
            className={styles.saveTableBtn}
          />
        }
      >
        <DocumentStatusField
          value={state.formStateDraft?.status}
          onChange={(optionValue) =>
            dispatch(updateFormStateDraftAction('status', optionValue?.value))
          }
          documentType={page as DocumentTypeEnum}
        />
        <InputWrapper
          id={'merchant'}
          label={'Организация'}
          value={state.documentRaw?.merchant?.title || null}
          className={styles.itemField}
          disabled={true}
        />
        <InputWrapper
          value={state.documentRaw?.id || receiptId}
          id={'supplyId'}
          label={'Номер документа'}
          className={styles.itemField}
          disabled={true}
        />
        <InputWrapper
          value={
            state.documentRaw?.condition
              ? ISupplyConditionDescription[state.documentRaw?.condition]
              : '-'
          }
          id={'condition'}
          label={'Качество товара'}
          className={styles.itemField}
          disabled={true}
        />
        {isModalShown && (
          <AddNewItemModal
            isOpen={true}
            onClose={() => setIsModalShown(false)}
            onSubmit={handleAddItem}
          />
        )}
      </DocumentsParams>
      {openModal ? (
        <SerialNumbersModal
          isOpen={openModal}
          serialNumbers={serialNumbers}
          onClose={handleCloseModal}
        />
      ) : null}
    </>
  )
}
export default DocumentsParamsFields
