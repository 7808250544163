import React from 'react'
import { useHistory } from 'react-router-dom'

import cx from 'classnames'
import styles from './scan-header.module.scss'

import { IconArrowDown } from '@consta/icons/IconArrowDown'
import { IconSettings } from '@consta/icons/IconSettings'
import { Badge } from '@consta/uikit/Badge'

import { UserBlock } from '@entities/User/ui/UserBlock'

import { AdvanceModeButton } from '@features/advanceMode'
import { useAppContext } from '@shared/providers/AppContextProvider'

import { PATHS } from '@shared/routing'
import { Flex } from '@shared/ui/Flex'
import { Text } from '@shared/ui/Text'
import { Button } from '@shared/ui/btns/Button'


interface ScanFormHeaderProps {
  className?: string
  lightStyle?: boolean
  title?: string
  subtitle?: string | React.ReactNode
  onExit?: () => void
  withoutGoToMainMenu?: boolean
}

const ScanFormHeader = (props: ScanFormHeaderProps) => {
  const {
    className = '',
    title,
    subtitle,
    lightStyle,
    onExit = () => {
    },
    withoutGoToMainMenu,
  } = props

  const { advanceMode } = useAppContext()

  const history = useHistory()

  const openSettings = () => {
    history.push(PATHS.SETTINGS)
  }

  return (
    <Flex
      justifyContent={'between'}
      className={cx(styles.wrapper, className)}
    >
      <div className={cx({ [styles.titleLightCard]: lightStyle })}>
        <Text as={'h3'} className={cx(styles.title, { [styles.light]: lightStyle })}>{title}</Text>
        <Text as={'h5'} className={cx(styles.subtitle, { [styles.light]: lightStyle })}>{subtitle}</Text>
      </div>

      <Flex
        align={'center'}
        className={cx(styles.right)}
      >
        <Flex
          align={'center'}
          gap={'s'}
          className={cx(styles.infoWrapper, { [styles.lightCard]: lightStyle && advanceMode })}
        >
          {advanceMode ? (
            <>
              <Badge
                form='round'
                status='normal'
                label='Расширенный'
                className={styles.badge}
              />
              <Button
                onlyIcon
                className={styles.settings}
                size={'s'}
                view='clear'
                iconSize={'m'}
                iconLeft={IconSettings}
                onClick={openSettings}
              />
            </>
          ) : null}

          <Flex
            gap={'l'}
            align="center"
            className={cx(styles.userCard, { [styles.lightCard]: lightStyle })}
          >
            <AdvanceModeButton />
            <UserBlock
              withMenu
              iconRight={IconArrowDown}
              size='l'
              defaultMenuSettings={{
                withoutGoToMainMenu: withoutGoToMainMenu,
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ScanFormHeader
