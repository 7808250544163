import { HistoryChangesDescrType, IHistoryChanges } from 'src/interfaces'
import React from 'react'
import { DateCell, UserCell } from 'src/components'
import { convertHistoryChangesToChangesList, getFullPersonName } from '@shared/helpers'
import { TableColumnType, TableRowType } from '@/components/Table/TanStackTable'

export const generateTableData = (changes: IHistoryChanges[], grouped: boolean): {rows: TableRowType[], columns: TableColumnType<TableRowType>[] } => {
  let rows: TableRowType[] = []
  if (!grouped) {
    const resultChanges = convertHistoryChangesToChangesList(changes)
    rows = resultChanges.map((change, i) => {
      const actionDescr = HistoryChangesDescrType[change.action as keyof typeof HistoryChangesDescrType]
      return {
        id: `${i}`,
        date: change.date,
        user: change.user,
        user_copy_txt: `${change.user?.login || ''} ${getFullPersonName(change.user || {})}`,
        workTable: change.workspace,
        valueType: `${actionDescr} - ${change.field}`,
        was: change.before,
        became: change.after,
      }
    })
  } else {
    rows = changes.map((change, i) => {
      return {
        id: `${i}`,
        date: change.date,
        user: change.user,
        user_copy_txt: `${change.user?.login || ''} ${getFullPersonName(change.user || {})}`,
        workTable: change.workspace,
        valueType: ``,
        was: '',
        became: '',
        subItems: change.items.map(item => {
          const actionDescr = HistoryChangesDescrType[item.action as keyof typeof HistoryChangesDescrType]
          return {
              id: `${i}`,
              date: change.date,
              user: change.user,
              user_copy_txt: `${change.user?.login || ''} ${getFullPersonName(change.user || {})}`,
              workTable: change.workspace,
              valueType: actionDescr,
              was: '',
              became: '',
              subItems: item.changes.map(itemChange => {
                return {
                  id: `${i}`,
                  date: change.date,
                  user: change.user,
                  user_copy_txt: `${change.user?.login || ''} ${getFullPersonName(change.user || {})}`,
                  workTable: change.workspace,
                  valueType: actionDescr,
                  was: itemChange.before,
                  became:  itemChange.after,
                }
              })
          }
        })
      }
    })
  }



  const columns: TableColumnType<typeof rows[0]>[] = [
  {
    id: 'date',
    accessorKey: 'date',
    header: 'Дата',
    meta: {
      headerText: 'Дата',
    },
    cell: ({ row, table }) => {
      const original = row.original
      const size = table.getDensity()
      return (
        <DateCell
          dateSize={size}
          timeSize={size}
          date={original.date}
        />
      )
    },
  },
  {
    id: 'user',
    accessorKey: 'user',
    header: 'Пользователь',
    meta: {
      headerText: 'Пользователь',
      copyKey: 'user_copy_txt',
    },
    cell: ({ row: { original } }) => {
      return <UserCell user={original.user} />
    },
  },
  {
    id: 'workTable',
    accessorKey: 'workTable',
    header: 'Рабочее место',
    meta: {
      headerText: 'Рабочее место',
    },
  },
  {
    id: 'valueType',
    accessorKey: 'valueType',
    header: 'Значение',
    meta: {
      headerText: 'Значение',
    },
    cell: ({ row: { original } }) => {
      return <b>{original.valueType}</b>
    },
  },
  {
    id: 'was',
    accessorKey: 'was',
    header: 'Было',
    meta: {
      headerText: 'Было',
    },
  },
  {
    id: 'became',
    accessorKey: 'became',
    header: 'Стало',
    meta: {
      headerText: 'Стало',
    },
  }
]

  return {
    rows,
    columns
  }
}
