import React, { useMemo } from 'react'
import cx from 'classnames'
import styles from './relative-docs.module.scss'
import { Link, useHistory, useParams } from 'react-router-dom'
import { OperAndDocumPages } from '../../../pagesType'
import useGetDocumentRelated from 'src/hooks/document/useGetDocumentRelated'
import { IconRestart } from '@consta/icons/IconRestart'
import { Button } from 'src/components'
import { DocumentsDescription, DocumentTypeEnum, RelatedDocument, RelatedDocumentEnum } from 'src/interfaces'
import { IconDocBlank } from '@consta/icons/IconDocBlank'
import days from 'dayjs'
import { Loader } from '@consta/uikit/Loader'
import { useHistoryPush } from '../../../hooks/useHistoryPush'
import { DocumentsType } from '@shared/ui/NavigationMenu/routes'
import { useAppContext } from '@shared/providers/AppContextProvider'
import { DATE_TIME_VIEW } from '@shared/const/date'
import { useDefaultSettings } from '@shared/hooks/settings'
import { Text } from '@shared/ui/Text'
import { IconArrowIn } from '@shared/assets/icons/IconArrowIn'
interface RelativeDocsProps {
  documentId: string
  documentType?: DocumentTypeEnum
  linkById?: boolean
}

const RelativeDocs = (props: RelativeDocsProps) => {
  const { documentId, linkById, documentType } = props

  const { documentsTypeList } = useAppContext()

  const { isFetching, isLoading, data, refetch } = useGetDocumentRelated({
    params: { doc_id: documentId, doc_type: documentType },
  })
  const document = data?.document

  return (
    <div className={cx(styles.main)}>
      <div className={cx(styles.head)}>
        <Text as={'h2'} size={'l'}>Дерево документов</Text>
        <Button
          label="Обновить"
          view="ghost"
          iconLeft={IconRestart}
          onClick={() => refetch()}
          loading={isFetching}
        />
      </div>
      <div className={cx(styles.nestedWrapper)}>
        {isLoading ? <Loader className={cx(styles.loader)} /> : null}
        {document ? (
          <NestedItem
            key={document.id}
            level={0}
            isFirst={true}
            linkById={linkById}
            document={document}
            documentId={documentId}
            documentsTypes={documentsTypeList}
            type={document.type}
            barcode={document?.barcode || ''}
          />
        ) : null}
      </div>
    </div>
  )
}

interface NestedItemProps {
  isFirst?: boolean
  document: RelatedDocument
  documentId: string
  documentsTypes: DocumentsType[]
  level: number
  type: DocumentTypeEnum
  barcode: string
  linkById?: boolean
}

const NestedItem = (props: NestedItemProps) => {
  const {
    isFirst,
    documentId,
    document,
    documentsTypes,
    level,
    type,
    barcode,
    linkById
  } = props

  const { id: pageId } = useParams<{ id: string }>()
  const { historyPush, getLinkPath, getNewLinkPath } = useHistoryPush()
  const history = useHistory()

  const { defaultNewDesign } = useDefaultSettings()

  const correctPageKey = Object.keys(OperAndDocumPages).find(
    (key) => OperAndDocumPages[key as keyof object] === type,
  )

  const getId = () => {
    if (linkById) return document.id

    if (type === DocumentTypeEnum.ORDER_PLACE)
      return barcode
    if (type === DocumentTypeEnum.RETURN_PLACE)
      return `${document.id}*${pageId}`
    if (type === DocumentTypeEnum.TRANSPORT_PLACE)
      return barcode ? barcode : document.id

    return document.id
  }
  const documentTitle = useMemo(() => documentsTypes?.find(docType => docType.value === type)?.title, [type, documentsTypes])
  const title = `${documentTitle || DocumentsDescription[document.type]} ${document.num??''} ${
    document.id
  } от ${days(document.create_ts).format(DATE_TIME_VIEW)}`
  const paddingLeft = 60
  const isCurrentForm = document.id === documentId



  const documentStatusClassName = !document.activated
    ? styles.gray
    : document.status === RelatedDocumentEnum.PROCESSING
      ? styles.orange
      : styles.green

  const link = useMemo(() => {
    /* для текущей формы */
    if (isCurrentForm) return ''
    /* в новом дизайне */
    if ((Boolean(defaultNewDesign) || (OperAndDocumPages[correctPageKey as keyof object] === undefined)))
      return getNewLinkPath(type, getId())
    /* в старом дизайне */
    return getLinkPath(OperAndDocumPages[correctPageKey as keyof object], getId())
  }, [isCurrentForm,defaultNewDesign,correctPageKey]);


  return (
    <>
      <div
        className={cx(styles.nestedItem, { [styles.nestedItemFirst]: isFirst })}
        style={{ paddingLeft: `${level * paddingLeft}px` }}
      >
        {!isFirst ? <IconArrowIn className={styles.iconArrow} /> : null}
        <IconDocBlank
          className={cx(styles.docBlanc, documentStatusClassName)}
        />
        <Link
          to={link}
          className={cx({ [styles.bold]: isCurrentForm })}
          onClick={(e) => {
            e.preventDefault()
            window.open(link, '_blank')?.focus()
          }}
        >
          {title}
        </Link>
      </div>
      <div>
        {document.related_documents?.map((doc) => (
          <NestedItem
            key={doc.id}
            level={level + 1}
            document={doc}
            documentsTypes={documentsTypes}
            linkById={linkById}
            documentId={documentId}
            type={doc.type}
            barcode={doc.barcode}
          />
        ))}
      </div>
    </>
  )
}

export default RelativeDocs
