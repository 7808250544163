import React, { FC } from 'react'

import type { ISection } from '../../types'
import Item from './pages/Item/Item'
import List from './pages/List/List'
import { PageLayout } from '@pages/DirectoriesPage/components/PageLayout/PageLayout'


interface NomenclatureProps extends ISection {}

const Nomenclature: FC<NomenclatureProps> = ({
  title = ''
}) => {
  return (
    <PageLayout
      title={title}
      listPage={<List />}
      formPage={<Item />}
    />
  )
}

Nomenclature.displayName = 'Nomenclature'

export default Nomenclature
