import React from 'react'
import { ComboboxWrapperWithApi } from '@/components'
import { CommonFieldProps } from '@shared/types/fields'
import { UserCompanyDto } from '../../model/types'
import { useGetUserCompanyList } from '@entities/UserCompany'

interface IComboboxUserCompanies extends CommonFieldProps<ComboboxUserCompanyItemType> {
  convertHookData?: (data: { companies: UserCompanyDto[] }) => any
}

export type ComboboxUserCompanyItemType = {
  label: string
  id: string
  dto: UserCompanyDto
}

const ComboboxUserCompanies = (props: IComboboxUserCompanies) => {
  const {
    label = 'Компании пользователей',
    placeholder = 'Выберите компанию',
    convertHookData = (data) => data.companies.map((company) => ({ id: company.id, label: company.title })),
    convertHookParams = (params) => ({
      search: params.search,
    }),
    ...restProps
  } = props

  return (
    <ComboboxWrapperWithApi
      label={label}
      withApiSearch={!Boolean(restProps.items?.length)}
      placeholder={placeholder}
      useGetHookSearch={useGetUserCompanyList}
      convertHookData={convertHookData}
      convertHookParams={convertHookParams}
      {...restProps}
    />
  )
}
export default ComboboxUserCompanies
