import { createContext } from 'react'
import { DocumentsType, IMenuList, IRoute } from '@shared/ui/NavigationMenu/routes'
import { ICurrentUser, IDatabaseSelectOption, IInformer } from '@/interfaces'
import packageInfo from '../../../../../../package.json'
import { Themes, ThemesEnum } from '@shared/providers/ThemeProvider/config/themes'

export const appContextDefaultValue = {
  theme: Themes.light as ThemesEnum,
  setTheme: (theme: ThemesEnum) => void theme as void,

  documentsTypeList: [] as DocumentsType[],
  mainMenuList: [] as IMenuList[],
  getDocMenuItemByDocType: (docType: string) => ({menu: undefined, menuItem: undefined}) as {
    menu?: IMenuList,
    menuItem?: IRoute
  },

  globalLoading: true,
  removeAdvanceModeIsLoading: false,
  setGlobalLoading: (globalLoading: boolean) => void globalLoading as void,
  authorized: false,
  setAuthorized: (authorized: boolean) => void authorized as void,
  currentUser: {} as ICurrentUser,
  setCurrentUser: (currentUser: ICurrentUser) => void currentUser as void,
  advanceMode: false,
  advanceModeDeferredAction: () => void false as void | null,
  setAdvanceModeDeferredAction: (fn: () => void | null) => void fn as void,
  setAdvanceMode: (value: boolean) => void value as void,
  removeAdvanceMode: () => void false as void,
  requestAdvanceMode: () => void false as void,
  showAdvanceModeModal: false as boolean,
  setShowAdvanceModeModal: (value: boolean) => void value as void,
  performWithAdvance: (action: () => () => void) => void action as void,

  databases: [] as IDatabaseSelectOption[],
  setDatabases: (databases: IDatabaseSelectOption[]) => void databases as void,

  logout: () => void false as void,

  informer: null as IInformer | null,
  setInformer: (informer: IInformer | null) => void informer as void
}

export const AppContext = createContext(appContextDefaultValue)