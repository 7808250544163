import { ISku, ITableColumn, ITableRow } from 'src/interfaces'
import React, { useEffect, useReducer } from 'react'
import styles from './generatorStyles.module.scss'
import {
  CellInfoType,
  OnChangeFieldType,
  TemplateFieldType,
  TemplateTable,
  TemplateTableColumn,
  TemplateTableData,
  TemplateTableRow,
} from '@shared/types/tableGenerator/generatorTypes'
import {
  isBoolean,
  isCalendar,
  isCell,
  isContainer,
  isContractor,
  isDocument,
  isInput,
  isMerchant,
  isSelect,
  isSku,
  isSkuBatch,
  isTable,
  isUrl,
  isWarehousePlace,
} from './fieldTypes'
import {
  BooleanField,
  CalendarField,
  CellField,
  ContainerField,
  ContractorField,
  DocumentField,
  getExtraFieldsForColumns,
  InputField,
  MerchantField,
  PlaceField,
  SelectField,
  SkuBatchField,
  SkuField,
  TableField,
  UrlField,
} from './fields'
import { COMPONENT_POSTFIX, COPY_VALUE_POSTFIX, SELECT_PREFIX, VALUE_SEPARATOR } from '../../../shared/helpers/consts'
import { getCellCopyValueByType, getCorrectColumnsData } from './utils'
import { CheckboxCell, TrashCell } from 'src/components'
import { generatePath } from 'react-router-dom'
import { PATHS } from '@shared/routing'
import { boolSelectItems } from '@widgets/GeneratorFields'
import { generateFormDocumentPath } from '@/newSrc/shared/routing/config/paths'


/**
 * Тут мы генерируем ячейки опираясь на тип столбца
 */
type cellComponentArgsType = {
  table: TemplateTable,
  type: TemplateFieldType,
  editMode: boolean,
  isTotalRow: boolean,
  required?: boolean,
  onChangeFieldData?: OnChangeFieldType
  handleAdditionalModalOpen?: (status: boolean) => void
  localStateHelper: any
  merchantId?: string
}

/* Переменная для записи всех функций перерендера компонентов */
let cellsForceUpdate: Record<string, { forceUpdate: React.DispatchWithoutAction }> = {}

let rowsCheckboxForceUpdate: Record<string, { forceUpdate: React.DispatchWithoutAction }> = {}
let rowsCheckboxState: string[] = []

type CheckboxComponentCellProps = {
  isHeader: boolean
  rowId: string
  rowsIds: string[]
  checkedItemsIds: string[]
  setCheckedItemsIds: React.Dispatch<React.SetStateAction<string[]>>
}
const CheckboxComponentCell = (props: CheckboxComponentCellProps) => {
  const {
    isHeader,
    rowId,
    rowsIds,
    checkedItemsIds,
    setCheckedItemsIds,
  } = props

  const [, forceUpdate] = useReducer(x => x + 1, 0)

  useEffect(() => {
    rowsCheckboxState = checkedItemsIds
  }, [checkedItemsIds])


  useEffect(() => {
    const rowIdStr = isHeader ? `header` : rowId
    rowsCheckboxForceUpdate[rowIdStr] = { forceUpdate }
    forceUpdate()
  }, [])

  return (
    <CheckboxCell
      isHeaderCheckbox={isHeader}
      fieldId={rowId}
      fieldsIds={rowsIds}
      checkedItemsIds={rowsCheckboxState}
      setCheckedItemsIds={(checkedItems: string[]) => {
        rowsCheckboxState = checkedItems
        setCheckedItemsIds(checkedItems)
        if (isHeader) {
          Object.values(rowsCheckboxForceUpdate).forEach(item => {
            item.forceUpdate()
          })
          return
        }
        forceUpdate()
        rowsCheckboxForceUpdate[`header`]?.forceUpdate()
      }}
    />
  )
}

/**
 * Идея Обновления данных в столбцах и решение проблемы ререндера таблицы:
 *  1) Мы сохраняем эту функцию forceUpdate (ререндер компонента через обновлеине стейта)
 *     в специальной глобальной переменной, к которой у нас будет доступ у всех ячеек
 *  2) При изменении значения, мы отправляем данные в два места:
 *     – первое - Наш основной стейт
 *     – второе - Наша глобальная переменная с значениями, для доступа к нему других полей и + не вызывать перерендер самой
 *                таблицы
 *     После чего дергаем функуцию forceUpdate для обновления данных текущего компонента
 *  3) Если у столбца есть связь с другим столбцом (текущий зависит от другого related_column_id),
 *     добавляем обратную связь (от текущего, зависит другой inverse_related_column_id)
 *     todo тут (inverse_related_column_id) может быть нужен массив, так как может быть появится связь нескольких столбцов к одному
 *     чтобы иметь возможность обновления зависимого поля
 */
const cellComponentByType = (args: cellComponentArgsType) => {

  const {
    type, editMode, onChangeFieldData,
    handleAdditionalModalOpen, table,
    localStateHelper,
    isTotalRow,
    required,
    merchantId,
  } = args

  return ({ columnId, column, ...props }) => {

    /**
     Функция перерендера компонента, с помощью которой мы обновляем нужные нам компоненты столбцов
     */
    const [, forceUpdate] = useReducer(x => x + 1, 0)

    const rowId = props.id

    /** Данные к текущему столбцу columnId */
    const currentCellInfo: CellInfoType = props[columnId]
    const currentCell = currentCellInfo?.cell
    const currentColumn = currentCellInfo?.column
    const currentCellMeta = currentCell?.meta
    const currentCellDto = currentCellMeta?.dto
    const fieldId = `${columnId}${VALUE_SEPARATOR}${rowId}` // id для поля в стейте

    /**
     Данные с прямой связью к столбцу
     Например:
     столбец Партии номенклатуры (текущий столбец columnId) к столбцу с Номенклатурой (связный столбец related_column_id)
     */
    const relatedColumnId = currentCellInfo?.column?.related_column_id // id столбца, от которого зависит текущий столбец
    const relatedCellInfo: CellInfoType = props[relatedColumnId]
    const relatedCellDto = relatedCellInfo?.cell?.meta?.dto
    const relatedFieldId = `${relatedColumnId}${VALUE_SEPARATOR}${rowId}`

    /**
     Данные с обратной связью к столбцу
     Например:
     столбец Номенклатуры (текущий столбец columnId) к столбцу с Партией номенклатуры (связный столбец inverse_related_column_id)
     */
    const inverseRelatedColumnId = currentCellInfo?.column?.inverse_related_column_id  // id столбца, который зависит от текущего столбца
    const inverseRelatedColumn: TemplateTableColumn = currentCellInfo?.column?.inverse_related_column
    const inverseFieldId = `${inverseRelatedColumnId}${VALUE_SEPARATOR}${rowId}`

    const serversideOptions = currentCellInfo?.column?.values || []

    const localStateItem = localStateHelper[fieldId]
    const localStateValue = localStateItem?.value
    const localStateValueIsNull = localStateValue === null || localStateValue === ''

    const getNullOrValue = (localStateValue: any, currentCellValue: any) => localStateValueIsNull ? null : (localStateValue || currentCellValue)

    const onChangeField = (value) => {
      onChangeFieldData?.({
        columnId: Number(columnId),
        inverseRelatedColumn: inverseRelatedColumn
          ? inverseRelatedColumn
          : null,
        rowId: props.id,
        fieldType: type,
        value: value,
      })
      forceUpdate()
      localStateHelper[fieldId] = { value }
      if (inverseRelatedColumnId) {
        /**
         Если есть столбец обратной зависимости (зависит от значения текущего столбца),
         то дергаем его обновление
         */
        cellsForceUpdate[inverseFieldId]?.forceUpdate()
      }
    }

    /**
     Задаем каждому полю функцию forceUpdate, для того, чтобы можно было дернуть ее ререндер
     без перерендера самой таблицы
     */
    useEffect(() => {
      cellsForceUpdate[fieldId] = { forceUpdate }
    }, [])


    const commonProps = {
      readOnly: props.readOnly,
      isRequired: required,
      fieldId: fieldId,
      editMode: editMode,
      onChange: onChangeField,
    }

    // Если это финальные значения, то отображаем только значнение
    if (isTotalRow) {
      return (
        <InputField
          {...commonProps}
          value={getNullOrValue(localStateValue, currentCell?.value)}
        />
      )
    }

    // вспомогательные переменные, чтобы вынести часть значений, для облегчение кода
    //  охватывают определенный тип полей
    const currentCellDtoComboboxValue = currentCellMeta
      ? {
        id: currentCellDto?.id || currentCell.value,
        label: currentCellMeta.dto?.title || currentCellMeta?.title || currentCell.value,
        dto: currentCellDto,
      }
      : null
    const currentCellComboboxValue = getNullOrValue(localStateValue, currentCellDtoComboboxValue)

    const apiSearchFields = [
      { Component: DocumentField, conditionFn: isDocument },
      { Component: SkuField, conditionFn: isSku },
      { Component: CellField, conditionFn: isCell },
      { Component: ContainerField, conditionFn: isContainer },
      { Component: PlaceField, conditionFn: isWarehousePlace },
      { Component: ContractorField, conditionFn: isContractor },
      { Component: MerchantField, conditionFn: isMerchant },
      // { Component: BooleanField, conditionFn: isBoolean },
    ]
    /* Поля с api search */
    const apiSearchField = apiSearchFields.find(field => field.conditionFn(type))
    if (apiSearchField) {
      const isDocumentType = isDocument(type)
      const value = currentCellComboboxValue
      localStateHelper[fieldId] = { value }

      return (
        <apiSearchField.Component
          {...commonProps}
          merchantId={merchantId} // для поиска sku, если есть организация
          value={value}
          documentType={isDocumentType ? currentColumn?.document_type : undefined}
          link={isDocumentType ? generateFormDocumentPath({
            docType: currentColumn?.document_type,
            docId: value?.dto?.id,
          }) : undefined}
        />
      )
    }

    if (isSkuBatch(type)) {
      const newRelatedCellDto = localStateHelper[relatedFieldId]?.value?.dto || relatedCellDto
      const batchOptions = (
        (newRelatedCellDto as ISku)
          ?.batches
          .map(batch => ({ id: batch.id, label: `${batch.exp_date || ''} ${batch.num}`, dto: batch }))
        || []
      )
      const valueBatchId = getNullOrValue(localStateValue?.dto?.id, currentCellDto?.id)
      const batchValue = batchOptions.find(option => option.id === valueBatchId)
      localStateHelper[fieldId] = { value: batchValue }
      return (
        <SkuBatchField
          sku={newRelatedCellDto as ISku} // нужен для создания новой партии
          {...commonProps}
          disabled={!newRelatedCellDto?.batch_accounting}
          items={batchOptions}
          value={batchValue}
        />
      )
    }
    if (isBoolean(type)) {
      const value = getNullOrValue(localStateValue, currentCell?.value)
      localStateHelper[fieldId] = { value }
      return (
        <BooleanField
          {...commonProps}
          value={value}
        />
      )
    }
    if (isSelect(type)) {
      const valueOptionId = getNullOrValue(localStateValue?.id, currentCell?.value)
      const value = serversideOptions.find(option => option?.id === valueOptionId)
      localStateHelper[fieldId] = { value }
      return (
        <SelectField
          {...commonProps}
          selectOptions={serversideOptions}
          value={value}
        />
      )
    }
    if (isInput(type)) {
      const value = getNullOrValue(localStateValue, currentCell?.value)
      localStateHelper[fieldId] = { value }
      return (
        <InputField
          {...commonProps}
          value={value}
        />
      )
    }
    if (isUrl(type)) {
      const value = getNullOrValue(localStateValue, currentCell?.value)
      localStateHelper[fieldId] = { value }
      return (
        <UrlField
          {...commonProps}
          value={value}
        />
      )
    }
    if (isCalendar(type)) {
      const value = getNullOrValue(localStateValue, currentCell?.value)
      return (
        <CalendarField
          {...commonProps}
          type={type}
          value={value}
        />
      )
    }
    if (isTable(type)) {
      const valueData = getNullOrValue(localStateValue, currentCell)
      const additionalData = currentColumn?.additional_data
      const columns = additionalData?.columns || []
      localStateHelper[fieldId] = { value: valueData }
      return (
        <TableField
          {...commonProps}
          rows={valueData.additional_data?.rows || []}
          value={valueData.value}
          id={valueData.id}
          columns={columns}
          creationAvailable={additionalData.creation_available}
          deletionAvailable={additionalData.deletion_available}

          handleAdditionalModalOpen={handleAdditionalModalOpen}
        />
      )
    }

    return (
      <InputField
        {...commonProps}
        value={getNullOrValue(localStateValue, currentCell?.value)}
      />
    )
  }
}


/**
 * Генерируем таблицу на осонове темплейта
 */
type generateTableArgs = {
  tableData: TemplateTableData,
  table: TemplateTable,
  withRowsSelection?: boolean,
  editMode?: boolean,
  deletionAvailable?: boolean,
  handleChangeTableItemDraftValue?: OnChangeFieldType
  handleDeleteItem?: (rowId: string) => void
  handleSelectItems?: (rowIds: string[]) => void
  handleAdditionalModalOpen?: (status: boolean) => void
  localStateHelper: any
  localCheckboxStateHelper: string[]
  merchantId?: string
}

const generateRows = (rows: TemplateTableRow[], additionalArgs: generateTableArgs, isTotal?: boolean): ITableRow[] => {
  const {
    merchantId,
    table,
    editMode,
    handleChangeTableItemDraftValue,
    handleAdditionalModalOpen,
    localStateHelper,
  } = additionalArgs

  return rows.map((row, i) => {
    // Id строки (генерим на фронте для удобства работы)
    let res: ITableRow = {
      id: row.id,
      isTotal: isTotal,
      subItems: row.rows ? generateRows(row.rows, additionalArgs) : undefined,
    }
    row.cells.forEach(cell => {
      const columnIndex = table.columns.findIndex(column => column.id === cell.id)
      const column = table.columns[columnIndex]
      const selectOptions = column.values?.map(val => ({ id: val.value, label: val.title }))

      /* Ищем свзяный с текущим столбец, потребуется для перереденра */
      const relatedColumn = table.columns.find(relatedColumn => relatedColumn.related_column_id === column.id)

      // Значение ячейки, все что с ней связано (типы, начальные значения и т.п. )
      const cellInfo: CellInfoType = {
        cell,
        column: {
          ...column,
          inverse_related_column_id: relatedColumn?.id,
          inverse_related_column: relatedColumn,
          values: selectOptions,
        },
      }

      // Значение ячейки для копирования пир режиме чтения
      const copyValue = getCellCopyValueByType(cellInfo, column.type)

      // Компонент ячейки
      const CellComponent = cellComponentByType({
        localStateHelper,
        table,
        type: column.type,
        isTotalRow: isTotal,
        editMode,
        required: column.required,
        onChangeFieldData: handleChangeTableItemDraftValue,
        handleAdditionalModalOpen,
        merchantId,
      })

      const cellData = {
        ...res,

        [column.id]: cellInfo,
        [`${column.id}${COPY_VALUE_POSTFIX}`]: copyValue,
        readOnly: column.read_only || isTotal,
      }

      res = ({
        ...cellData,
        ...getExtraFieldsForColumns(`${column.id}`, cellInfo, column.type), // добавляем екстра поля для екстра столбцов
        [`${column.id}${COMPONENT_POSTFIX}`]: <CellComponent {...cellData} column={column} columnId={column.id} />,
      })
    })
    return res
  })
}

export const generateTableData = (args: generateTableArgs): { columns: ITableColumn[], rows: ITableRow[] } => {
  const {
    withRowsSelection,
    tableData,
    table,
    editMode,
    deletionAvailable,
    handleDeleteItem,
    handleSelectItems,
    localCheckboxStateHelper
  } = args

  /** Получаем все столбцы, в том числе и вспомогательные */
  const extraTableColumns = getCorrectColumnsData(table.columns, editMode)
  let columns: ITableColumn[] = table.columns.map((column, i) => {
    const columnId = `${column.id}`
    return ({
      columnId: columnId,
      key: `${columnId}${COMPONENT_POSTFIX}`,
      copy_key: `${columnId}${COPY_VALUE_POSTFIX}`,
      title: column.title as string,
      title_txt: column.title as string,
      gridCellWidth: '90px',
    })
  })

  /* Добавляем доп. столбцы (напирмер ариткул и изображение для номенклатуры) */
  extraTableColumns.forEach((extraColumn) => {
    const correctPositionIndex = columns.findIndex(column => column.columnId === extraColumn.initialColumnId)
    columns.splice(correctPositionIndex, 0, extraColumn)
  })

  if (withRowsSelection) {
    /* Добавляем возможность выбора элементов из таблицы */
    columns.splice(0, 0, {
      key: `${SELECT_PREFIX}${COMPONENT_POSTFIX}`,
      title: '',
      title_txt: '',
      tdClassName: styles.actionCell,
      isAction: true,
      withHeaderAction: true,
      gridCellWidth: '50px',
      align: 'center',
      renderCell: ({ row, isHeader, index }: any) => {
        if (row.isTotal) {
          return null
        }
        return (
          <CheckboxComponentCell
            isHeader={isHeader}
            rowId={row.id}
            rowsIds={rows.map(row => row.id)}
            // checkedItemsIds={selections}
            // setCheckedItemsIds={setSelections}
            checkedItemsIds={localCheckboxStateHelper}
            setCheckedItemsIds={(checkedRows: string[]) => {
              handleSelectItems(checkedRows)
            }}
          />
        )
      },
    })
  }

  /* Добавляем возможность удаления элементов из таблицы */
  if (deletionAvailable && editMode) {
    columns.push({
      key: `action__delete`,
      title: '',
      title_txt: '',
      tdClassName: styles.actionCell,
      renderCell: ({ row, isSubItem }) => {
        if (isSubItem || row.isTotal) {
          return null
        }
        return (
          <TrashCell
            theme={'danger'}
            onClick={() => {
              handleDeleteItem(row.id)
            }}
          />
        )
      },
    })
  }

  let rows: ITableRow[] = generateRows(tableData.rows, args)
  if (tableData.total_row) {
    rows = [...rows, ...generateRows([tableData.total_row], args, true)]
  }
  return { columns, rows: rows }
}

