import React, { Dispatch, SetStateAction, useMemo, useState } from 'react'

import styles from './MovingModalForm.module.scss'

import { Button, InputWrapper, TableV2 } from 'src/components'
import { TextField, TextFieldPropValue } from '@consta/uikit/TextField'
import { ConditionEnum, IDefectType } from 'src/interfaces'
import { generateColumns, generateRows } from '../table'
import { useDebounce } from '@shared/hooks'
import { CellDefaultType, CellsObjType, ISubmitPropsData, ISubmitPropsItemData, StockType } from '../types'
import { ComboboxCells } from '@entities/Cell'
import { IconAdd } from '@consta/icons/IconAdd'
import { IconTrash } from '@consta/icons/IconTrash'
import Collapse from 'src/components/Collapse'
import cx from 'classnames'

interface IProps {
  onAddNewForm: () => void
  onRemoveForm: (formId: string) => void
  cellsObj: CellsObjType
  setErrorObj: Dispatch<SetStateAction<Record<string, string>>>
  setCellsObj: Dispatch<SetStateAction<CellsObjType>>
  onChangeTableItems: (formId: string, id: string, key: string, value: string | null) => void
  defectTypes: IDefectType[]
  comment: TextFieldPropValue
  setComment: Dispatch<SetStateAction<TextFieldPropValue>>
  errorObj: Record<string, string>
  resultState: ISubmitPropsData
  setResultState: Dispatch<SetStateAction<ISubmitPropsData>>
  onChangeCell: (formId: string, id: keyof ISubmitPropsData, value: CellDefaultType | null) => void
}


const MovingModalForm = (props: IProps) => {
  const {
    resultState,
    onChangeTableItems,
    defectTypes,
    comment,
    setComment,
    errorObj,
    setErrorObj,
    cellsObj,
    setCellsObj,
    setResultState,
    onChangeCell,
    onAddNewForm,
    onRemoveForm,
  } = props

  const cellsFormsKeys = Object.keys(cellsObj)

  return (
    <div className={styles.formWrapper}>
      <Button
        label={'Добавить перемещение'}
        iconLeft={IconAdd}
        className={styles.addButton}
        size={'s'}
        onClick={onAddNewForm}
      />
      <div className={styles.formBody}>
        {
          cellsFormsKeys.map((formId, i) => {
            const cellObj = cellsObj[formId]
            const isOnlyOneElement = cellsFormsKeys.length === 1
            return (
              <CellFormItem
                key={formId}
                isOnlyOneElement={isOnlyOneElement}
                formId={formId}
                cellFrom={cellObj.from}
                cellTo={cellObj.to}
                stockList={cellObj.stock}
                resultStateForm={resultState[formId]}
                setResultState={setResultState}
                handleChangeCell={onChangeCell}
                handleChangeTableItems={onChangeTableItems}
                defectTypes={defectTypes}
                error={errorObj[formId]}
                resetError={() => setErrorObj(prev => ({ ...prev, [formId]: undefined }))}
                onRemoveForm={onRemoveForm}
              />
            )
          })
        }
      </div>
      <TextField
        className={styles.textarea}
        label='Коментарий'
        value={comment}
        type='textarea'
        rows={4}
        onChange={(value) => {
          setComment(value)
        }}
      />
    </div>
  )
}

type CellFormItemProps = {
  isOnlyOneElement: boolean
  formId: string
  stockList: StockType
  cellFrom: CellDefaultType | null
  cellTo: CellDefaultType | null
  resultStateForm?: ISubmitPropsItemData
  setResultState: Dispatch<SetStateAction<ISubmitPropsData>>
  handleChangeCell: (formId: string, id: keyof ISubmitPropsData, value: CellDefaultType | null) => void
  handleChangeTableItems: (formId: string, id: string, key: string, value: string | null) => void
  defectTypes: IDefectType[]
  error: string
  resetError: () => void
  onRemoveForm: (formId: string) => void
}
const CellFormItem = (props: CellFormItemProps) => {
  const {
    isOnlyOneElement,
    formId,
    cellFrom,
    cellTo,
    resultStateForm,
    setResultState,
    handleChangeCell,
    handleChangeTableItems,
    defectTypes,
    error,
    resetError,
    stockList,
    onRemoveForm,
  } = props
  // const smartCollapseInitialState = Boolean(isOnlyOneElement || (!cellFrom || !cellTo))
  const [isOpen, setIsOpen] = useState(true)
  const [searchDebounced, search, setSearch] = useDebounce<TextFieldPropValue>(null)

  const rows = useMemo(
    () => generateRows(resultStateForm?.items || [], stockList?.skus || [], handleChangeTableItems, formId, defectTypes),
    [resultStateForm?.items, stockList?.skus, formId],
  )
  const rowsFiltered = useMemo(
    () => {
      return rows.filter(row => {
        if (searchDebounced) {
          return (
            row.skuBarcodes.find((barcodeItem => barcodeItem.barcode.includes(searchDebounced)))
            || row.skuArticle.toLowerCase().includes(searchDebounced.toLowerCase()))
        }
        return true
      })
    },
    [rows, searchDebounced],
  )

  const columns = useMemo(() => {
    const cellToIsDefect = [ConditionEnum.DEFECT, ConditionEnum.HIDDEN_DEFECT].includes(cellTo?.dto?.condition)
    return generateColumns(cellToIsDefect)
  }, [cellTo])

  const handleResetAllValues = () => {
    const result = resultStateForm?.items.map((movingItem) => ({ ...movingItem, quantity: '0' }))
    setResultState((prev) => ({
      ...prev,
      [formId]: { ...prev[formId], items: result },
    }))
    resetError()
  }
  const handleInputAllValues = () => {
    const result =
      resultStateForm?.items.map((movingItem) => {
        const foundSearchItem = rowsFiltered.find(row => row.sku_id === movingItem.sku.sku_id)
        return ({
          ...movingItem,
          quantity: foundSearchItem
            ? String(+movingItem.sku.quantityFact - +movingItem.sku.reserved)
            : '0',
        })
      })

    setResultState((prev) => ({
      ...prev,
      [formId]: {
        ...prev[formId],
        items: result,
      },
    }))
    resetError()
  }


  return (
    <Collapse
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      labelClassName={cx(
        styles.collapseClassName,
        { [styles.collapseError]: Boolean(error) },
      )}
      label={
        <div className={styles.movingCollapse}>
          <span>Форма перемещения из <b>{cellFrom?.label || '-'}</b> в <b>{cellTo?.label || '-'}</b></span>

          {
            !isOnlyOneElement ? (
              <Button
                onlyIcon
                size={'s'}
                theme={'danger'}
                iconLeft={IconTrash}
                className={styles.removeBtn}
                onClick={() => onRemoveForm(formId)}
              />
            ) : null
          }
        </div>
      }
    >
      <div className={styles.formItemWrapper}>
        <div className={styles.inputsRow}>
          <div className={styles.inputCell}>
            <ComboboxCells
              value={cellFrom}
              label={'Из ячейки'}
              className={styles.cells}
              size={'s'}
              placeholder={'Начните печатать для поиска'}
              onChange={(value) => handleChangeCell(formId, 'cell_barcode_from', value as CellDefaultType)}
            />
          </div>
          <div className={styles.inputCell}>
            <ComboboxCells
              value={cellTo}
              label={'В ячейку'}
              className={styles.cells}
              size={'s'}
              placeholder={'Начните печатать для поиска'}
              onChange={(value) => handleChangeCell(formId, 'cell_barcode_to', value as CellDefaultType)}
            />
          </div>
        </div>
        {resultStateForm?.cell_barcode_from && resultStateForm?.cell_barcode_to ? (
          <div className={styles.tableWrapper}>
            <TableV2
              rows={rowsFiltered}
              columns={columns}
              title={'Описание'}
              extraHeader={
                <div className={styles.extraHeader}>
                  <InputWrapper
                    id={'searchArt'}
                    handleChange={setSearch}
                    className={styles.itemInput}
                    placeholder={'Поиск по ариткулу или ШК'}
                    value={search}
                    size={'s'}
                  />
                  <Button
                    label={'Вставить всё'}
                    disabled={rowsFiltered?.length < 1}
                    onClick={handleInputAllValues}
                    size={'s'}
                  />
                  <Button
                    label={'Сбросить всё'}
                    onClick={handleResetAllValues}
                    size={'s'}
                  />
                </div>
              }
              maxHeightBody={300}
            />
          </div>
        ) : null}
        <div className={styles.error}>{error}</div>
      </div>
    </Collapse>
  )
}

export default MovingModalForm
