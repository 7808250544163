import { ITableColumn, ITableRow } from 'src/interfaces'
import React from 'react'
import { CheckboxCell } from 'src/components'
import { IconPDF } from '@shared/assets/icons/IconPDF'

export const generateColumns = (
  checkedFiles: string[],
  setCheckedFiles: React.Dispatch<React.SetStateAction<string[]>>,
  rows: ITableRow[]
): ITableColumn[] => [
  {
    key: 'actions',
    title: '',
    title_txt: '',
    isAction: true,
    withHeaderAction: true,
    gridCellWidth: '50px',
    renderCell: ({ row, isHeader, index }: any) => (
      <CheckboxCell
        isHeaderCheckbox={isHeader}
        fieldId={row.id}
        fieldsIds={rows.map(row => row.id)}
        checkedItemsIds={checkedFiles}
        setCheckedItemsIds={setCheckedFiles}
      />
    )
  },
  {
    key: 'img',
    title: '',
    title_txt: '',
    gridCellWidth: '60px',
    renderCell: ({ row }: any) => {
      return <IconPDF />
    },
  },
  {
    key: 'name',
    title: 'Имя документа',
    title_txt: 'Имя документа',
    gridCellWidth: '200px',
  },
]

export const generateRows = (): ITableRow[] => {
  return [
    {
      id: 'mx-1',
      name: 'Акт MX-1',
    },
    {
      id: 'torg-2',
      name: 'Акт ТОРГ-2',
    },
  ]
}
