import { BadgePropStatus } from '@consta/uikit/Badge'
import { DeliveryStatusEnum } from '../acceptance'

export const DELIVERY_STATUSES: Record<DeliveryStatusEnum | string, string> = {
  [DeliveryStatusEnum.pickup]: 'Отгрузка силами КС',
  [DeliveryStatusEnum.drop_off]: 'Отгрузка силами Кактуса',
}

export const DELIVERY_STATUS_BADGE_TYPE: Record<
  DeliveryStatusEnum | string,
  BadgePropStatus
> = {
  [DeliveryStatusEnum.pickup]: 'system',
  [DeliveryStatusEnum.drop_off]: 'success',
}
