import React, { FC, useMemo, useState } from 'react'

import type { ISection } from '../../types'
import { useCreateUpdateCarrierCompany, useDeleteCarrierCompany, useGetCarriersCompaniesList } from '@/hooks'
import { useFilterState } from '@shared/hooks'
import { generateFilterParams } from './utils/filters'
import { generateTableData } from './utils/table'
import { ConfirmActionModal, CreateCarrierCompanyModal } from '@/components'
import { useNotifications } from '@shared/providers/NotificationProvider'
import { ICarrierCompany } from '@/interfaces'
import { DirectoriesLayout } from '../../components/DirectoriesLayout/DirectoriesLayout'

interface Props extends ISection {}

const CarriersCompany: FC<Props> = (props) => {
  const { title = '' } = props

  const notification = useNotifications()

  const {
    filterStateDebounced,
    filterState,
    handleChangeFilter
  }  = useFilterState({withoutHistory: true, onChangeFilterState: () => {
    setPaginationPage(0)
  }})
  const [paginationPage, setPaginationPage] = useState(0)
  const [createEditModalVisible, setCreateEditModalVisible] = useState(false)
  const [companyForEdit, setCompanyForEdit] = useState<ICarrierCompany | null>(null)

  const [deleteCompanyId, setDeletedCompanyId] = useState<string | null>(null)


  const {
    mutateAsync: createUpdateCompany,
    isLoading: createUpdateCompanyIsLoading
  } = useCreateUpdateCarrierCompany()
  const { mutateAsync: deleteCompany } = useDeleteCarrierCompany()
  const {
    isFetching: isFetchingCompanies,
    data: companiesData,
    refetch: refetchCompanies
  } = useGetCarriersCompaniesList(
    {...generateFilterParams({ ...filterStateDebounced } || {}), page: paginationPage}
  )

  const handleEdit = (company: ICarrierCompany) => {
    setCompanyForEdit(company)
    setCreateEditModalVisible(true)
  }
  const handleConfirm = (companyId: string, isDelete: boolean) => {
    setDeletedCompanyId(companyId)
  }
  const handleDelete = async (companyId: string, isDelete: boolean) => {
    deleteCompany({ companyId })
      .then((data) => {
        refetchCompanies()
        setDeletedCompanyId(null)
      })
      .catch(() => {})
  }

  const { rows, columns } = useMemo(() => {
    return generateTableData(
      companiesData?.companies || [],
      handleEdit,
      handleConfirm,
    )
  }, [companiesData?.companies])



  return (
    <DirectoriesLayout
      withTable
      withCreate
      withFilter
      withSearch

      title={title}

      // filtersConfig={filtersConfig}
      filterState={filterState}
      handleChangeFilter={handleChangeFilter}

      onCreateBtnClick={() => setCreateEditModalVisible(true)}

      isLoading={isFetchingCompanies}
      columns={columns}
      rows={rows}
      pagination={companiesData?.pagination}
      onChangePage={setPaginationPage}
    >
      {
        createEditModalVisible ? (
          <CreateCarrierCompanyModal
            isOpen={true}
            company={companyForEdit}
            onSubmit={({ legal_address_full, ...restData }) => {
              createUpdateCompany({
                companyId: restData.id,
                data: {
                  ...restData,
                  legal_address: {
                    full_address: legal_address_full,
                  }
                }})
                .then(async () => {
                  setCreateEditModalVisible(false)
                  setCompanyForEdit(null)
                  refetchCompanies();
                })
                .catch(() => {})
            }}
            onClose={() => {
              setCreateEditModalVisible(false)
              setCompanyForEdit(null)
            }}
          />
        ) : null
      }

      {deleteCompanyId ? (
        <ConfirmActionModal
          isOpen={true}
          type="warning"
          withPreview={false}
          title={'Вы уверены, что хотите удалить компании перевозчиков?'}
          size={'min'}
          confirmWithScan={false}
          onClose={() => setDeletedCompanyId(null)}
          onSubmit={() => handleDelete(deleteCompanyId, true)}
          confirmLoading={createUpdateCompanyIsLoading}
        />
      ) : null}
    </DirectoriesLayout>
  )
}

export default CarriersCompany
