import { FieldRow } from '@widgets/GeneratorFields'
import { IconSearchStroked } from '@consta/icons/IconSearchStroked'


export const getFiltersConfig = (fields: FieldRow[] = [], withSearch?: boolean): FieldRow[] => {
  return [
    withSearch ? {
      fields: [
        {
          width: 200,
          type: 'input',
          fieldProps: {
            id: 'search',
            label: 'Поиск',
            viewDescription: 'Поиск',
            placeholder: 'Поиск',
            leftSide: IconSearchStroked
          },
        },
      ],
    } : undefined,
    ...fields
  ].filter(Boolean) as FieldRow[]
}