import React, { useEffect, useState } from 'react'
import styles from './material-services-modal.module.scss'
import { InputWrapper, Modal, TableV2 } from 'src/components'
import { ModalProps, Service } from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { Button } from '@shared/ui/btns/Button'
import { generateColumns, generateRows } from './utils'
import { useDebounce } from '@shared/hooks'

interface MaterialServicesModalProps extends ModalProps {
  services: Service[]
  selectedServices: Service[]
  hasOverlay?: boolean
  handleSubmit: (services: Service[]) => void
}

const MaterialServicesModal = (props: MaterialServicesModalProps) => {
  const {
    services,
    hasOverlay,
    selectedServices,
    onClose,
    handleSubmit,
  } = props

  const [checkedMaterials, setCheckedMaterials] = useState<string[]>([])
  const [servicesList, setServicesList] = useState<Service[]>([])
  const [searchDebounced, search, setSearch] =
    useDebounce<TextFieldPropValue | null>(null)

  useEffect(() => {
    setServicesList(services)
  }, [services])

  useEffect(() => {
    setCheckedMaterials(selectedServices.map(serv => serv.id))
  }, [selectedServices])

  useEffect(() => {
    const searchedServices = services.filter(service => {
      const searchedLower = (searchDebounced || '').toLowerCase()
      const titleLower = service.title.toLowerCase()
      return titleLower.includes(searchedLower)
    })
    setServicesList(searchedServices)
  }, [searchDebounced, services])


  const onSubmit = () => {
    const resultServices = checkedMaterials
      .map(checkedMaterial =>
        services.find(service => service.id === checkedMaterial))
      .filter(Boolean) as Service[]
    handleSubmit(resultServices)
  }

  const rows = generateRows(servicesList)
  const columns = generateColumns(checkedMaterials, setCheckedMaterials, rows)

  return (
    <Modal
      isOpen={true}
      hasOverlay={hasOverlay}
      titleAlign={'left'}
      onOverlayClick={(): boolean => false}
      className={styles.modal}
      title={'Список материалов'}
    >
      <div className={styles.content}>
        <InputWrapper
          value={search}
          id={'search'}
          handleChange={setSearch}
          className={styles.itemInput}
          label={'Выберите материалы которые необходимо добавить'}
          placeholder={'Введите название материала'}
        />
        <div className={styles.tableWrap}>
          <TableV2
            fullHeight
            theme={'gray'}
            rows={rows}
            columns={columns}
            maxHeightBody={340}
          />
        </div>
        <div className={styles.footer}>
          <Button
            label={'Отмена'}
            view={'ghost'}
            onClick={onClose}
            className={styles.orderCancelBtn}
          />
          <Button
            label={'Добавить'}
            view={'primary'}
            onClick={onSubmit}
            className={styles.orderCancelBtn}
          />
        </div>
      </div>
    </Modal>
  )
}

export default MaterialServicesModal
