import React from 'react'

import { useAppContext } from '@shared/providers/AppContextProvider'
import { LockButton } from '@shared/ui/btns/LockButton/LockButton'
import { AdvanceModeModal } from '../..'
import { useToggle } from '@shared/hooks'

export const AdvanceModeButton = () => {
  const { advanceMode, setAdvanceMode, removeAdvanceModeIsLoading, removeAdvanceMode } = useAppContext()

  const {
    value: advanceModeModalVisible,
    turnOn: showAdvanceModeModal,
    turnOff: closeAdvanceModeModal,
  } = useToggle()

  const handleAdvanceMode = () => {
    if (!advanceMode) {
      showAdvanceModeModal()
    } else {
      removeAdvanceMode()
    }
  }

  return (
    <>
      <LockButton
        locked={!advanceMode}
        onClick={handleAdvanceMode}
        loading={removeAdvanceModeIsLoading}
      />
      {
        advanceModeModalVisible ? (
          <AdvanceModeModal
            isOpen={true}
            setAdvanceMode={setAdvanceMode}
            onClose={closeAdvanceModeModal}
          />
        ) : null
      }
    </>
  )
}
