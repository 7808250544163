import { usePrint } from './print'
import { usePreviewBase64Pdf } from '../usePreviewBase64Pdf'
import useGetSerialNumbersSticker from '@/hooks/items/useGetSerialNumbersSticker'
import { DocumentTypeEnum } from '@/interfaces'
import { useState } from 'react'


/*
  Если нет value и нет reprinе то
  document_id - обязательные
  document_type - обязательно
  sku_id - обязательно

  если есть value
  document_id - необязательное
  document_type - необязательное
  sku_id - необязательное
 */

interface Result {
  loading: boolean
  printSerialNumber: (args: {
    serial_number_type: string, // "chestniy_znak" | "imei" | "unique_number"
    sku_id?: string,
    document_id?: string,
    document_type?: DocumentTypeEnum,
    disable_billing?: boolean,
    reprint?: boolean,
    withPreview?: boolean,
    copies?: number,
    value?: string
  }) => Promise<void>
}

export const usePrintSerialNumber = (): Result => {
  const { printSticker } = usePrint({})
  const getSerialNumbersSticker = useGetSerialNumbersSticker()
  const { previewBase64Pdf } = usePreviewBase64Pdf()

  const [loading, setLoading] = useState(false)

  const printSerialNumber = async (args) => {
    const {
      copies,
      withPreview,
      ...restData
    } = args
    setLoading(true)
    await getSerialNumbersSticker
      .fetch(restData)
      .then(async (data) => {
        try {
          if (withPreview) {
            previewBase64Pdf(data.sticker.content)
          } else {
            await printSticker(data.sticker.printer, data.sticker.content, {
              copies,
              stickerExists: data.stickerExists,
            })
          }
        } catch (e) {
          console.error(e)
        }
      })
      .catch((e) => {
        throw e
      })
      .finally(() => setLoading(false))
  }
  return {
    loading,
    printSerialNumber,
  }
}
