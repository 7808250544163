import React, { FC, useMemo, useState } from 'react'

import type { ISection } from '../../types'
import { useCreateUpdateCarrier, useGetCarriersCompaniesList, useGetCarriersList } from '@/hooks'
import { useFilterState } from '@shared/hooks'
import { generateFilterParams } from './utils/filters'
import { generateTableData } from './utils/table'
import { ConfirmActionModal, CreateCarrierModal } from '@/components'
import { useNotifications } from '@shared/providers/NotificationProvider'
import { DirectoriesLayout } from '@pages/DirectoriesPage/components/DirectoriesLayout/DirectoriesLayout'

interface Props extends ISection {}

const Carriers: FC<Props> = (props) => {
  const { title = '' } = props

  const notification = useNotifications()

  const {
    filterStateDebounced,
    filterState,
    handleChangeFilter
  }  = useFilterState({withoutHistory: true, onChangeFilterState: () => {
    setPaginationPage(0)
  }})
  const [paginationPage, setPaginationPage] = useState(0)
  const [createEditModalVisible, setCreateEditModalVisible] = useState(false)
  const [carrierEditId, setCarrierEditId] = useState<string | null>(null)

  const [deleteCarrierEditId, setDeletedCarrierEditId] = useState<string | null>(null)


  const createUpdateCarrier = useCreateUpdateCarrier({
    carrierId: deleteCarrierEditId || '',
  })

  const {
    isFetching: isFetchingCarrier,
    refetch: refetchCarriers,
    data: carriersData,
  } = useGetCarriersList(() => {}, {
      ...generateFilterParams({ ...filterStateDebounced } || {}),
      with_deleted: true, page: paginationPage
    },
  )

  const { data: companiesData } = useGetCarriersCompaniesList({ search: '' })

  const handleEdit = (id: string) => {
    setCarrierEditId(id)
    setCreateEditModalVisible(true)
  }
  const handleConfirm = (id: string, isDelete: boolean) => {
    if (!isDelete) {
      handleDeleteRevert(id, isDelete)
      return
    }
    setDeletedCarrierEditId(id)
  }
  const handleDeleteRevert = async (id: string, isDelete: boolean) => {
    if (!id) {
      return
    }
    const carrier = carriersData?.carriers?.find((carrierItem) => carrierItem.id === id)
    if (!carrier) {
      notification?.show('alert', 'Перевозчик не найден')
      return
    }
    await createUpdateCarrier.mutateAsync({ ...carrier, deleted: isDelete })
    setDeletedCarrierEditId(null)
    refetchCarriers()
  }

  const { rows, columns } = useMemo(() => {
    return generateTableData(
      carriersData?.carriers || [],
      handleEdit,
      handleConfirm,
    )
  }, [carriersData?.carriers])



  return (
    <DirectoriesLayout
      withTable
      withCreate
      withFilter
      withSearch

      title={title}

      // filtersConfig={filtersConfig}
      filterState={filterState}
      handleChangeFilter={handleChangeFilter}

      onCreateBtnClick={() => setCreateEditModalVisible(true)}

      isLoading={isFetchingCarrier}
      columns={columns}
      rows={rows}
      pagination={carriersData?.pagination}
      onChangePage={setPaginationPage}
    >
      {createEditModalVisible ? (
         <CreateCarrierModal
           carrierEditId={carrierEditId}
           carriersList={carriersData?.carriers || []}
           isOpen={true}
           onClose={() => setCreateEditModalVisible(false)}
           carriersCompanyList={companiesData?.companies || []}
           onSubmit={() => {
             refetchCarriers()
             setCreateEditModalVisible(false)
           }}
         />
      ) : null}

      {deleteCarrierEditId ? (
        <ConfirmActionModal
          isOpen={true}
          type="warning"
          withPreview={false}
          title={'Вы уверены, что хотите удалить перевозчика?'}
          size={'min'}
          confirmWithScan={false}
          onClose={() => setDeletedCarrierEditId(null)}
          onSubmit={() => handleDeleteRevert(deleteCarrierEditId, true)}
          confirmLoading={createUpdateCarrier?.isLoading}
        />
      ) : null}
    </DirectoriesLayout>
  )
}

export default Carriers
