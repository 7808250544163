import React from 'react'
import cx from 'classnames'

import styles from './select.module.scss'

import { Select } from '@consta/uikit/Select'

import { ISelectOption } from 'src/interfaces'
import { Text } from '@shared/ui/Text'

interface SelectProps {
  id?: string
  label?: string
  options: ISelectOption[]
  value?: ISelectOption | null
  className?: any
  onChange: (value: ISelectOption | null, eventObj: { e: React.SyntheticEvent<Element, Event> }) => void
  size?: 'xs' | 's' | 'm' | 'l'
  form?:
    | 'default'
    | 'round'
    | 'brick'
    | 'brickDefault'
    | 'defaultBrick'
    | 'clearRound'
    | 'roundClear'
    | 'clearDefault'
    | 'defaultClear'
    | 'brickClear'
    | 'clearBrick'
    | 'clearClear'
  disabled?: boolean
  placeholder?: string
  isRequired?: boolean
  status?: 'alert' | 'warning' | 'success'
}

const SelectWrapper = (props: SelectProps) => {
  const {
    id,
    label,
    options,
    value,
    onChange,
    className,
    status,
    form,
    size,
    placeholder,
    isRequired = false,
    disabled,
  } = props

  return (
    <div
      className={cx(
        styles.wrapper,
        isRequired ? styles.wrapper_required : null,
        className,
      )}
    >
      {label ? <Text size={size} as={'label'}>{label}</Text> : null}
      <Select
        id={id}
        items={options}
        value={value}
        getItemKey={(o: ISelectOption) => o.value}
        onChange={onChange}
        form={form}
        size={size}
        placeholder={placeholder}
        disabled={disabled}
        status={status}
        dropdownClassName={styles.dropdown}
      />
    </div>
  )
}

export default SelectWrapper
