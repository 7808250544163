import React, { SVGProps } from 'react'
import { createIcon } from '@consta/icons/Icon'

const IconSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M7 5H17V8H7V5Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 15V19H17V15H19V10C19 9.44772 18.5523 9 18 9H6C5.44772 9 5 9.44772 5 10V15H7ZM15 14H9V17H15V14ZM16 10.5C16 10.2239 16.2239 10 16.5 10H17.5C17.7761 10 18 10.2239 18 10.5C18 10.7761 17.7761 11 17.5 11H16.5C16.2239 11 16 10.7761 16 10.5Z"
    />
  </svg>
)

export const IconPrinter = createIcon({
  l: IconSizeM,
  m: IconSizeM,
  s: IconSizeM,
  xs: IconSizeM,
  name: 'IconPrinter',
  renderType: {m: 'default'}
})
