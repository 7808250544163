import React from 'react'
import { ICellPlace, ITreeLvlItem, IZoneTreeNode } from '@/interfaces'

export type ModalType =
  | ''
  | 'createZone'
  | 'editZone'
  | 'levelSettings'
  | 'cellSettings'

export enum LevelType {
  level = 'level',
  cell = 'cell',
}

export enum CheckboxStateType {
  unchecked,
  checked,
  intermediate = 'intermediate',
}

export interface ICheckbox {
  barcode: string
  /** Состояние чекбокса - с галочкой, без или промежуточное */
  state: CheckboxStateType
  title: string
  __parent_special_id: string
  childrenChecked: number
  childrenBlocked: number
  childrenNum: number
  levelType: LevelType
  is_blocked: boolean
  access_level: string | number
  differentAccess: Set<string | number>
  zone_id: string
}

export interface ICheckboxes {
  [key: string]: ICheckbox
}

export interface IZone {
  id: string
  title: string
  type: string
}

export type ZoneType =
  | 'STORAGE'
  | 'RECEIVING'
  | 'PACKING'
  | 'CORRECTION'
  | 'SHIPMENT'

export interface ILvlItem extends ITreeLvlItem {
}

export interface IZoneTree extends IZoneTreeNode {
  __special_id?: string
}

export interface IZoneLvlCounts {
  /** Стартовый номер элемента уровня */
  valueFrom: string
  /** Конечный номер элемента уровня  */
  valueTo: string
  /** Общее кол-во элементов уровня */
  amount: number
}

export interface IChangedSettings {
  isBlocked: boolean
  condition: string | number | undefined
  cellType: string | number | undefined
  accessLevel: string | number
}

export interface SubmitCellSettingsProps {
  event: React.SyntheticEvent
  changes: IChangedSettings
  cell: ICellPlace
}

export interface SubmitLevelSettingsProps {
  event: React.SyntheticEvent
  changes: IChangedSettings
  levelTitle: string
  levelZoneId: string
}
