import { useQuery } from 'react-query'
import { appLocalStorage } from '@shared/lib/storage'
import api from '@shared/api'

const useGetWarehouseSqu = () => {
  return useQuery(
    [`getWarehousesSqu`],
    async () => {
      const result = await api.get(`/warehouse-api/sku`, {
        baseURL: 'https://new-wms.kak2c.ru',
        headers: {
          Authorization: `Bearer ${appLocalStorage.token_warehouse}`,
        },
      })
      if (result?.status === 200) {
        return result.data.skus
      }
    },
    {
      refetchOnWindowFocus: false, //TODO basic
      //   enabled: false,
    },
  )
}

export default useGetWarehouseSqu
