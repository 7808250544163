import { TableColumn } from '@consta/uikit/Table'
import { ICarrierCompany } from '@/interfaces'
import { getFullAddress } from '@shared/helpers'
import React from 'react'
import { ActivationBadge } from '@shared/ui/Badges/ActivationBadge'
import { EditRemoveButtons } from '@pages/DirectoriesPage/components/EditRemoveButtons/EditRemoveButtons'

export const generateTableData = (
  companies: ICarrierCompany[],
  handleEdit: (company: ICarrierCompany) => void,
  handleDeleteRevert: (companyId: string, isDelete: boolean) => void,
) => {
  const rows = companies.map(company => ({
    id: company?.id?.toString() || '',
    title: company?.title?.toString() || '',
    phone: company?.phone?.toString() || '',
    address: getFullAddress(company?.legal_address || {}, []),
    inn: company?.inn?.toString() || '',
    kpp: company?.kpp?.toString() || '',
    ogrn: company?.ogrn?.toString() || '',
    company: company,
    deleted: company.deleted,
    actions: ''
  }))

  const columns: TableColumn<(typeof rows)[number]>[] = [
    {
      title: 'Название',
      sortable: true,
      accessor: 'title',
      width: 200,
    },
    {
      title: 'Тел.',
      accessor: 'phone',
      width: 200,
    },
    {
      title: 'Адрес',
      accessor: 'address',
      width: 200,
    },
    {
      title: 'ИНН',
      accessor: 'inn',
      width: 200,
    },

    {
      title: 'Статус',
      accessor: 'deleted',
      renderCell: (row: any) => {
        return (
          <ActivationBadge size={row.getTableDensity()} active={!row.original.deleted} />
        )
      }
    },
    {
      title: '',
      width: 150,
      /* @ts-ignore */
      accessor: 'actions__action',
      renderCell: (row: any) => {
        return (
          <EditRemoveButtons
            withEdit
            withRemove
            onEditClick={() => handleEdit(row?.original.company)}
            onRemoveClick={() => handleDeleteRevert(row.original.id, !row.original.deleted)}
          />
        )
      },
    },
  ]
  return { rows, columns }
}
