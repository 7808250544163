import React from 'react'
import { ComboboxWrapperWithApi } from '@/components'
import { useGetMerchantList } from '@/hooks'
import { IMerchant } from '@/interfaces'
import { CommonFieldProps } from '@shared/types/fields'

interface IProps extends CommonFieldProps  {
  enabled?: boolean
  convertHookData?: (data: { merchants: IMerchant[] }) => any
}

const ComboboxMerchants = (props: IProps) => {
  const {
    enabled = true,
    label = 'Организация',
    placeholder = 'Выберите организацию/продавца',
    convertHookData = (data) => {
      return data.merchants.map((el) => ({
        label: el.title,
        id: el.id,
        value: el.id,
        dto: el,
      }))
    },
    convertHookParams = (params) => ({ params: { search: params.search, enabled: enabled }, size: 50 }),
    ...restProps
  } = props

  return (
    <ComboboxWrapperWithApi
      label={label}
      placeholder={placeholder}
      useGetHookSearch={useGetMerchantList}
      convertHookParams={convertHookParams}
      convertHookData={convertHookData}
      {...restProps}
    />
  )
}
export default ComboboxMerchants
