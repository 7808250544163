import React, { FC } from 'react'
import cx from 'classnames'
import styles from './dashboard-layout-wrapper.module.scss'
import { UserBlock } from '@entities/User/ui/UserBlock'
import { IDropdownItem } from 'src/interfaces'
import { IconArrowDown } from '@consta/icons/IconArrowDown'
import { Header } from '@widgets/Header'
import { Text } from '@shared/ui/Text'


interface MainLayoutProps {
  headerExtraNode?: React.ReactNode
  sidebarNode?: React.ReactNode
  sidebarClassName?: string
  headerTitle?: string
  userItems?: IDropdownItem[]
  contentClassName?: string
  children?: React.ReactNode
  newDesignHeader?: boolean
  withoutSidebar?: boolean
}

const DashboardLayoutWrapper: FC<MainLayoutProps> = (props) => {
  const {
    withoutSidebar,
    newDesignHeader,
    headerExtraNode,
    sidebarNode,
    children,
    headerTitle = '',
    userItems = [],
    contentClassName,
    sidebarClassName
  } = props

  return (
    <div className={cx(styles.main)}>
      {
        newDesignHeader ? (
          <Header />
        ) : (
          <div className={cx(styles.header)}>
            <Text as={'h3'} className={cx(styles.headerTitle)}>{headerTitle}</Text>

            {headerExtraNode ? headerExtraNode : null}

            <UserBlock
              withMenu
              iconRight={IconArrowDown}
              size='l'
              items={userItems}
            />
          </div>
        )
      }

      <div className={cx(styles.contentWrapper, {[styles.withoutSidebar]: withoutSidebar})}>
        {!withoutSidebar &&
          <div className={cx(styles.sidebar, sidebarClassName)}>
            {sidebarNode}
          </div>
        }

        <div className={cx(styles.content, contentClassName)}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default DashboardLayoutWrapper
