import { useQuery } from 'react-query'
import api from '@shared/api'

import { ApiReqResult, ISku, StickerWithCopies } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'
import { SupplySortingDto, SupplySortingPlanDto } from '@/interfaces/supplySortingDto'

interface Params {
  supplyId: string
}
const getSortingSupplyPlan = async (params: Params): Promise<Result> => {
  const result = await api.get(`/crossdocking-sorting/supply/${params.supplyId}/plan`)
  if (!result?.data?.success) {
    throw result
  }
  return result.data
}

interface Result extends ApiReqResult {
  skus: ISku[]
  supply: SupplySortingPlanDto
}


export const useGetSortingSupplyPlan = (
  params: Params,
  options?: UseQueryOptions<Promise<Result>, any, Result, [string, Params]>,
) => {
  return useQuery<Promise<Result>, any, Result, [string, Params]>(
    [`getSortingSupplyPlan`, params],
    () => getSortingSupplyPlan(params),
      options
  )
}
