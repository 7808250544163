import React, { FC, useState } from 'react'

import { IAcceptanceSku, ModalProps } from 'src/interfaces'
import { IReplaceItem } from 'src/interfaces/replace'
import { IReplaceChangeItem } from '../types'

import { useCreateReplace, useGetItemsM, useGetMerchantList } from 'src/hooks'
import { v4 } from 'uuid'

import { Modal } from 'src/components'
import { ComboboxMerchants } from '@entities/Merchant'
import ModalItem from '../ModalItem/ModalItem'
import { ComboboxItemDefault } from '@consta/uikit/Combobox'
import { Button } from '@shared/ui/btns/Button'

import styles from './replace-modal.module.scss'
import { convertSkuToState } from '../utils'
import { ValidateErrors } from '@pages/DirectoriesPage/types'
import { useNotifications } from '@shared/providers/NotificationProvider'

interface ReplaceModalProps extends ModalProps {
  type: 'add' | 'edit'
  refetch: () => void
}

const ReplaceModal: FC<ReplaceModalProps> = ({ isOpen, onClose, refetch }) => {
  const [merchant, setMerchant] = useState<ComboboxItemDefault | null>()
  const notification = useNotifications()
  const [oldSkuList, setOldSkuList] = useState<ComboboxItemDefault[]>([])

  const [itemsToChange, setItemsToChange] = useState<IReplaceChangeItem[]>([])
  const [error, setError] = useState('')
  const { data } = useGetMerchantList({})
  const { mutate: getItems } = useGetItemsM()
  const { mutateAsync: createReplace } = useCreateReplace()

  const getMappedItems = (skus: IAcceptanceSku[] = []) =>
    convertSkuToState(skus)

  const handleChangeMerchant = (merchant: ComboboxItemDefault | null) => {
    if (!merchant) {
      return
    }
    setItemsToChange([])
    getItems(
      { merchant_ids: [`${merchant.id}`] },
      {
        onSuccess: (data) => setOldSkuList(getMappedItems(data?.skus || [])),
      },
    )

    setItemsToChange([])
  }

  const onDelete = (uuid: string) => {
    const newItems = itemsToChange.filter((item) => item.replaceId !== uuid)

    setItemsToChange(newItems)
  }

  const onModalChange = (
    uuid: string,
    key: keyof IReplaceItem,
    value: string | number | IAcceptanceSku,
  ) => {
    const items = [...itemsToChange]
    setError('')

    for (let i = 0; i <= items.length; i++) {
      if (items[i].replaceId === uuid) {
        ;(items[i] as Record<typeof key, string | number | IAcceptanceSku>)[
          key
        ] = value

        setItemsToChange(items)
        break
      }
    }
  }

  const canSubmit = () => {
    const isError = [...itemsToChange]

    for (let i = 0; i < isError.length; i++) {
      const element = isError[i]
      if (!element.cell_barcode || !element.new_sku_id || !element.sku_id) {
        setError(ValidateErrors.isEmpty)
        return
      }
      if (
        (element.originalOldSku?.batch_accounting && !element.sku_batch_id) ||
        (element.originalNewSku?.batch_accounting && !element.new_sku_batch_id)
      ) {
        setError(ValidateErrors.isEmpty)
        return
      }
    }

    return true
  }

  const handleSubmit = () => {
    const isValid = canSubmit()
    if (!isValid) return
    const data = itemsToChange.map((el) => ({
      cell_barcode: el.cell_barcode,
      new_sku_batch_id: el.new_sku_batch_id,
      new_sku_id: el.new_sku_id,
      quantity: el.quantity,
      sku_batch_id: el.sku_batch_id,
      sku_id: el.sku_id,
    }))

    createReplace({ items: data })
      .then((res) => {
        if (res) {
          notification?.show('success', 'Документ успешно сохранен', {
            soundName: 'PACKING_COMPLETED',
          })
          refetch()
        }
      })
      .catch(() => {})
      .finally(() => {
        onClose()
      })
  }

  const getAddTitle = () => {
    if (!merchant) {
      return 'Сначала выберите организацию/продавца'
    }

    if (!oldSkuList.length) {
      return 'Нет товаров'
    }

    if (itemsToChange.length === oldSkuList.length) {
      return 'Все товары добавлены'
    }

    return ''
  }

  return (
    <Modal isOpen={isOpen} hasOverlay onOverlayClick={onClose}>
      <ComboboxMerchants
        className={styles.merchantRow}
        value={merchant}
        onChange={(value) => {
          setMerchant(value)
          handleChangeMerchant(value)
        }}
      />

      <div>
        <div className={styles.addRow}>
          <p>{`Товары на замену (${itemsToChange.length} из ${oldSkuList.length}):`}</p>

          <Button
            label={'Добавить'}
            title={getAddTitle()}
            size={'s'}
            disabled={itemsToChange.length >= oldSkuList.length}
            onClick={() => {
              const list: IReplaceChangeItem[] = [
                {
                  replaceId: v4(),
                  sku_id: '',
                  new_sku_id: '',
                  cell_barcode: '',
                  quantity: 0,
                  originalOldSku: null,
                  originalNewSku: null,
                },
                ...itemsToChange,
              ]

              setItemsToChange(list)
            }}
          />
        </div>

        <div className={styles.itemsContainer}>
          {itemsToChange.map((item) => {
            return (
              <ModalItem
                className={styles.item}
                merchant_ids={[`${merchant.id}`]}
                error={error}
                key={item.replaceId}
                uuid={item.replaceId}
                onDelete={onDelete}
                onChange={onModalChange}
              />
            )
          })}
        </div>

        <Button
          label={'Отправить'}
          disabled={!(itemsToChange.length > 0)}
          onClick={handleSubmit}
        />
      </div>
    </Modal>
  )
}

ReplaceModal.displayName = 'ReplaceModal'

export default ReplaceModal
