import React, { CSSProperties } from 'react'
import days from 'dayjs'
import styles from './DateCell.module.scss'
import cx from 'classnames'
import { DATE_VIEW, TIME_FORMAT } from '@shared/const/date'
import { Flex } from '@shared/ui/Flex'
import { Text, TextPropSizeType } from '@shared/ui/Text'
import { GridPropGap } from '@consta/uikit/Grid'

interface DateCellProps {
	date: string,
  dateFormat?: string
  withTime?: boolean
  timeFormat?: string
  className?: string
  style?: CSSProperties,
  gap?: GridPropGap
  dateSize?: TextPropSizeType,
  timeSize?: TextPropSizeType,
}

export const DateCell = (props: DateCellProps) => {
  const {
    date,
    dateFormat = DATE_VIEW,
    timeFormat = TIME_FORMAT,
    withTime = true,
    className,
    style,
    gap,
    dateSize = 's',
    timeSize = 's',
  } = props

  return (
    <Flex
      direction={'column'}
      gap={gap}
      className={cx(styles.date, className)}
      style={style}
    >
      <Text as={'p'} size={dateSize} children={days(date).format(dateFormat)}/>
      {withTime ? <Text as={'span'} size={timeSize} children={days(date).format(timeFormat)}/> : null}
    </Flex>
  )
};
