import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'

import styles from './exp-date-modal.module.scss'

import { Text } from '@shared/ui/Text'
import { Button } from '@shared/ui/btns/Button'
import { IconCalendar } from '@consta/icons/IconCalendar'
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup'

import { EnterHandler, InputWithDateMask, Modal } from 'src/components'
import { SkuItemPreview } from '@entities/Sku/ui/SkuItemPreview'
import { useNotifications } from '@shared/providers/NotificationProvider'

import { ModalProps, ExpDateTypesEnum } from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { DATE_REQUEST_FORMAT, DATE_VIEW, DATE_TIME_VIEW } from '@shared/const/date'
import { useExpDate, dateTypesOptions } from '@shared/hooks/useExpDate'


interface ExpDateItem {
  id: string | number
  image: string
  title: string
  barcode?: string
  expiryMonths?: number
  expiryDaysLimitShipments?: number
  expirationDate?: string,
  dateOfManufacture?: string
}

interface ItemCountProps extends ModalProps {
  item: ExpDateItem | null
  onDateExpAdding: (newItems: ExpDateItem[]) => void
  newItems: ExpDateItem[]
  externalError?: string
}

const ExpDateModal = (props: ItemCountProps) => {
  const { item, externalError, onDateExpAdding,onClose, newItems } = props

  const [date, setDate] = useState<TextFieldPropValue>('')
  const [curItems, setCurItems] = useState<ExpDateItem[]>([])
  const [curItemIndex, setCurItemIndex] = useState<number>(0)

  const {
    isExpirationDate,
    dateType,
    setDateType,
    dateErrorText,
    setDateErrorText,
    checkDateIsCorrect,
    getExpDateAndManufactureDateByDate,
  } = useExpDate({
    defaultStateType: item?.expiryMonths !== undefined ? ExpDateTypesEnum.dateOfManufacture : ExpDateTypesEnum.expirationDate
  })

  useEffect(() => {
    setDateErrorText(externalError || '')
  }, [externalError])

  const handleAddExpDate = () => {
    if (!item || !date) return
    if (!checkDateIsCorrect({ date, expiryMonths: item.expiryMonths })) return

    const {
      expirationDate,
      dateOfManufacture
    } = getExpDateAndManufactureDateByDate({ date, expiryMonths: item.expiryMonths })

    const newCurItems = [
      ...curItems,
      { ...item, expirationDate, dateOfManufacture },
    ]

    if (curItemIndex === newItems.length - 1) {
      onDateExpAdding(newCurItems)
    }

    setCurItems(newCurItems)
    setCurItemIndex((prev) => prev + 1)

    setDateErrorText('')
    setDate('')
  }

  const handleChange = (value: TextFieldPropValue) => {
    setDateErrorText('')
    setDate(value)
  }

  return (
    <>
      {newItems.map((newItem, i) => (
        <React.Fragment key={newItem.id}>
          {curItemIndex === i ? (
            <EnterHandler onEnter={handleAddExpDate}>
              <Modal
                isOpen={true}
                hasOverlay
                withClose
                onClose={onClose}
                className={styles.expDate}
                size="s"
              >
                {item ? (
                  <SkuItemPreview
                    image={item.image}
                    title={item.title}
                    barcode={item.barcode || ''}
                  />
                ) : null}

                <ChoiceGroup
                  className={styles.choiceGroup}
                  value={dateType ?? null}
                  items={dateTypesOptions}
                  getItemLabel={(optionItem) => optionItem.label}
                  getItemDisabled={(optionItem) => (
                    (optionItem.value === ExpDateTypesEnum.dateOfManufacture)
                    && (item.expiryMonths === undefined)
                  )}
                  onChange={(value) => setDateType(value)}
                  name={'dateTypes'}
                  multiple={false}
                  width={'full'}
                />

                {newItems.length > 1 ? (
                  <div className={styles.itemsCount}>
                    {i + 1} из {newItems.length}
                  </div>
                ) : null}
                <div>
                  <div className={styles.input} key={`newItem-${i}`}>
                    <InputWithDateMask
                      label={
                        isExpirationDate
                          ? 'Годен до'
                          : 'Введите дату изготовления'
                      }
                      value={date}
                      id={'date'}
                      handleChange={(value: TextFieldPropValue) =>
                        handleChange(value)
                      }
                      className={styles.itemInput}
                      size="l"
                      placeholder={DATE_TIME_VIEW}
                      rightSide={IconCalendar}
                      autofocus
                      form="defaultClear"
                    />
                    <Button
                      form="brickDefault"
                      size="l"
                      label="Подтвердить"
                      className={styles.btn}
                      disabled={!date || !!dateErrorText}
                      onClick={handleAddExpDate}
                    />
                  </div>
                  <Text view={'alert'} size={'xs'}>{dateErrorText}</Text>
                </div>
              </Modal>
            </EnterHandler>
          ) : null}
        </React.Fragment>
      ))}
    </>
  )
}

export default ExpDateModal
