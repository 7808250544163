import { CSSProperties } from 'react'
import { VirtualItem, Virtualizer } from '@tanstack/react-virtual'
import { Column, Row, Table } from '@tanstack/react-table'
import { TableRowType } from '@/components/Table/TanStackTable'


const getCommonColumnPinningStyles = <TRowData,>(column: Column<TRowData>): CSSProperties => {
  const isPinned = column.getIsPinned()
  const isLastLeftPinnedColumn =
    isPinned === 'left' && column.getIsLastColumn('left')
  const isFirstRightPinnedColumn =
    isPinned === 'right' && column.getIsFirstColumn('right')

  return {
    boxShadow: isLastLeftPinnedColumn
      ? '-4px 0 4px -4px gray inset' // todo переделать цвет под темы
      : isFirstRightPinnedColumn
        ? '4px 0 4px -4px gray inset'
        : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
  }
}

/** Стили tHead тега */
export const getTHeadStyles = (): CSSProperties => {
  return {}
}

/** Стили tHead tr тега */
export const getTHeadTRStyles = (): CSSProperties => {
  return {}
}

/** Стили tHead tr th тега */
export const getTHStyles = <TRowData, >(column: Column<TRowData>): CSSProperties => {
  const isPinned = column.getIsPinned()
  return {
    justifyContent: 'space-between',
    gap: '8px',
    position: isPinned ? 'sticky' : 'relative',
    ...getCommonColumnPinningStyles<TRowData>(column), //IMPORTANT: This is where the magic happens!
  }
}

/** Стили tBody тега */
export const getTBodyStyles = (rowVirtualizer: Virtualizer<HTMLDivElement, Element>): CSSProperties => {
  return {
    height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
  }
}

/** Стили tBody tr тега */
export const getTBodyTRStyles = <TRowData extends TableRowType, >(args: { row: Row < TRowData >, virtualRow? : VirtualItem, table ? : Table<TRowData> }): CSSProperties => {
  const {row, virtualRow, table} = args
  const rowIsPinnedBottom = row.getIsPinned() === 'bottom'
  return {
    transform: !rowIsPinnedBottom && virtualRow ? `translateY(${virtualRow.start}px)` : undefined, //this should always be a `style` as it changes on scroll
    position: rowIsPinnedBottom ? 'sticky' : 'absolute',
    bottom:
      rowIsPinnedBottom && table
        ? `${
          (table.getBottomRows().length - 1 - row.getPinnedIndex()) * 26
        }px`
        : undefined,
  }
}

/** Стили tBody tr td тега */
export const getTDStyles = <TRowData extends TableRowType, >(column: Column<TRowData>, row: Row<TRowData>, isActionColumn: boolean, isSelected?: boolean): CSSProperties => {
  const isPinned = column.getIsPinned() || row.getIsPinned() === 'bottom'
  const rowMeta = row.original.meta
  const rowIsActive = rowMeta?.active !== undefined ? row.original.meta?.active || isActionColumn : true
  return {
    cursor: row.getCanExpand() ? 'pointer' : 'initial',
    ...getCommonColumnPinningStyles<TRowData>(column),
    position: isPinned ? 'sticky' : 'relative',
    opacity: rowIsActive || isSelected ? 1 : 0.5,
  }
}

/** Стили tFoot тега */
export const getTFootStyles = (): CSSProperties => {
  return {}
}

