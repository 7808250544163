import React, { FC } from 'react'
import cx from 'classnames'
import { generatePath, Link } from 'react-router-dom'
import { PATHS } from '@shared/routing'
import { SECTIONS, tabs } from '@pages/DirectoriesPage/constants'
import { Flex } from '@shared/ui/Flex'
import { Text } from '@shared/ui/Text'
import styles from './NomenclatureNameCell.module.scss'


type Props = {
  title: string,
  article?: string,
  className?: string
  titleSettings?: Partial<React.ComponentProps<typeof Text>>
  articleSettings?: Partial<React.ComponentProps<typeof Text>>
}
export const NomenclatureNameCell = ({ className, title='', article='', titleSettings = {}, articleSettings = {} }: Props) => {
  return (
    <Flex direction={'column'} gap={'2xs'} className={cx(styles.nomenclatureCell, className)}>
      <Text size='s' as={'p'} {...titleSettings} children={title}/>
      {article ? (
        <Text size='s' as={'span'} view={'secondary'} {...articleSettings} children={article}/>
      ) : null}
    </Flex>
  )
}

interface NomenclatureCellProps {
  title: React.ReactNode
  itemId: string
  withNewTab?: boolean
}

export const NomenclatureLinkCell: FC<NomenclatureCellProps> = ({
  title,
  itemId,
  withNewTab
}) => {
  const linkPath = generatePath(PATHS.DIRECTORIES, {
    page: tabs.guides.value,
    section: SECTIONS.nomenclature.value,
    type: 'item',
    id: itemId || '-',
  })
  return (
    <Link
      to={linkPath}
      onClick={(e) => {
        if (withNewTab) {
          e.preventDefault();
          window.open(linkPath, "_blank")?.focus();
        }
      }}
    >
      {title}
    </Link>
  )
}
