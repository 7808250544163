import React, { useEffect, useRef, useState } from 'react'
import { Button, ErrorBoundary, InputWrapper } from '../../../../components'
import { UserBlock } from '@entities/User/ui/UserBlock'
import { IconArrowDown } from '@consta/icons/IconArrowDown'
import { Link, useHistory } from 'react-router-dom'
import cactusLogo from '@shared/assets/icons/LogoIcon/IconCactusLogo.svg'
import styles from './Header.module.scss'
import { IconSearchStroked } from '@consta/icons/IconSearchStroked'
import { useDebounce } from '@shared/hooks'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { NavigationMenu } from '@shared/ui/NavigationMenu/NavigationMenu'
import cx from 'classnames'
import { Badge } from '@consta/uikit/Badge'
import { IconSettings } from '@consta/icons/IconSettings'
import { useAppContext } from '@shared/providers/AppContextProvider'
import { PATHS } from '@shared/routing'
import { ContextMenu, ContextMenuStatus } from '@consta/uikit/ContextMenu'
import { IconComponent } from '@consta/icons/Icon'
import { IconCalculator } from '@consta/icons/IconCalculator'
import { IconDocFilled } from '@consta/icons/IconDocFilled'
import { IconCards } from '@consta/icons/IconCards'
import { HEADER_ID } from '@shared/helpers/consts'
import { AdvanceModeButton } from '@features/advanceMode'
import { Flex } from '@shared/ui/Flex'
import { LogoLink } from '@shared/ui/LogoLink/LogoLink'
import { useDefaultSettings } from '@shared/hooks/settings'
import { ThemesEnum } from '@shared/providers/ThemeProvider/config/themes'


const IconTitle = {
  Операции: IconCards,
  Документы: IconDocFilled,
  Отчеты: IconCalculator,
}

export type ContextMenuItemDefault = {
  label: string | number
  key?: string
  rightSide?: React.ReactNode
  rightIcon?: IconComponent
  leftSide?: React.ReactNode
  leftIcon?: IconComponent
  subMenu?: ContextMenuItemDefault[]
  status?: ContextMenuStatus
  disabled?: boolean
  groupId?: number | string
  onClick?: React.MouseEventHandler
}
type Group = {
  name: string
  key: string
}


type HeaderProps = {
  hardTheme?: ThemesEnum
}

/* Todo мб передалть хедер в layout и слоты для: лого, поиска, меню и пользователя? */

export const Header = (props: HeaderProps) => {
  const {
    hardTheme
  } = props

  const [searchTxtDebounced, searchTxt, setSearchTxt] =
    useDebounce<TextFieldPropValue>(null)

  const ref = useRef(null)
  const { mainMenuList, advanceMode } = useAppContext()
  const history = useHistory()

  const {
    defaultTheme
  } = useDefaultSettings()

  const [list, setList] = useState<ContextMenuItemDefault[]>([])
  const [groupList, setGroupList] = useState<Group[]>([])
  const [filteredList, setFilteredList] = useState<ContextMenuItemDefault[]>([])
  const [visible, setVisible] = useState(false)


  useEffect(() => {
    setGroupList(mainMenuList.map((el) => ({ name: el.label, key: el.label })))
    const list = mainMenuList.map((menu) =>
      menu.items.map((el) => ({
        label: el.name,
        key: el.route,
        groupId: menu.label,
        rightIcon: IconTitle[el.headerType],
      })),
    ).flat()
    setList(list)
    setFilteredList(list)
  }, [])

  const handleSearch = (value: string) => {
    if (!value) {
      setFilteredList(list)
      return
    }

    const results = list.filter((item) =>
      String(item.label).toLowerCase().includes(value.toLowerCase()),
    )

    setFilteredList(results)
    setVisible(true)
  }
  useEffect(() => {
    handleSearch(searchTxtDebounced)
  }, [searchTxtDebounced])

  const openSettings = () => {
    history.push(PATHS.SETTINGS)
  }

  return (
    <section id={HEADER_ID} className={styles.headerSection}>
      <LogoLink
        to="/"
        className={styles.logo}
        theme={hardTheme || defaultTheme}
      />

      {/* todo вынести этот компонент в фичу */}
      <Flex direction="column">
        <InputWrapper
          id="search"
          autoFocus
          placeholder="Я ищу"
          handleChange={(value) => {
            setVisible(false)
            setSearchTxt(value)
          }}
          inputRef={ref}
          value={searchTxt}
          className={styles.searchInput}
          leftSide={IconSearchStroked}
        />
        {filteredList.length ? (
          <ContextMenu
            items={filteredList}
            className={styles.contextSearch}
            isOpen={visible}
            size="m"
            anchorRef={ref}
            groups={groupList}
            getGroupLabel={(group) => group.name}
            onClickOutside={() => setVisible(false)}
            getGroupId={(group) => group.key}
            onItemClick={(item) => {
              history.push(item.key)
            }}
          />
        ) : null}
      </Flex>

      <div className={styles.menuWrapper}>
        <ErrorBoundary>
          <NavigationMenu
            mainMenuList={mainMenuList}
          />
        </ErrorBoundary>
      </div>

      <Flex
        gap={'xs'}
        align="center"
      >
        {advanceMode ? (
          <>
            <Badge
              form="round"
              status="normal"
              label="Расширенный"
              size={'s'}
              className={styles.badge}
            />
            <Button
              onlyIcon
              iconLeft={IconSettings}
              view="clear"
              size="s"
              iconSize="m"
              onClick={openSettings}
            />
          </>
        ) : null}
        <Flex
          gap={'m'}
          align="center"
          className={cx(styles.userCard, { [styles.lightCard]: true })}
        >
          <AdvanceModeButton />
          <UserBlock
            withMenu
            iconRight={IconArrowDown}
            size="l"
            defaultMenuSettings={{
              withoutGoToMainMenu: false,
            }}
          />
        </Flex>
      </Flex>
    </section>
  )
}
