import React from 'react'
import { Checkbox } from '@consta/uikit/Checkbox'

type Props = {
  indeterminate?: boolean
} & React.ComponentProps<typeof Checkbox>

export const IndeterminateCheckbox = (props: Props) => {
  const {
    indeterminate,
    checked,
    onChange,
    ...restProps
  } = props
  return (
    <Checkbox
      label=''
      size={'s'}
      checked={checked}
      onChange={onChange}
      {...restProps}
    />
  )
}