import React, { useEffect, useMemo, useState } from 'react'
import { Button, Modal, Select } from '@/components'
import { ISelectOrderOption } from '@/interfaces'
import { TextField } from '@consta/uikit/TextField'
import { useFetchItemsByFilters } from '@/hooks'
import { setModalCells, setModalOrders, setModalSkus } from '@pages/Reports/store/actions'
import { useReportsContext } from '@pages/Reports/context/ReportsContext'
import { IReportParam } from '@/interfaces/report'
import { SELECT_CELLS_ITEMS, SELECT_ORDERS_ITEMS, SELECT_SKUS_ITEMS } from './data'
import { orderItemsEnum } from './types'
import { Flex } from '@shared/ui/Flex'
import { Text } from '@shared/ui/Text'
import { Grid, GridItem } from '@consta/uikit/Grid'


export const SELECT_ITEMS: ISelectOrderOption[] = [
  ...SELECT_SKUS_ITEMS,
  ...SELECT_CELLS_ITEMS,
  ...SELECT_ORDERS_ITEMS,
]

interface IDefineParamsModal {
  isOpen: boolean;
  onClose: () => void;
  setParamValue: (value: IReportParam) => void
  type: orderItemsEnum
  fieldId?: number | string
}

const DefineMultipleParamsModal: React.FC<IDefineParamsModal> = (props) => {
  const { isOpen, onClose, type, setParamValue, fieldId }  = props;

  const optionsForSelect: ISelectOrderOption[] = useMemo(() => SELECT_ITEMS.filter((item) => item.type === type), [type])

  const [selectedType, setSelectedType] = useState<ISelectOrderOption | null>(null);
  const [inputState, setInputState] = useState<string | null>("");
  const { dispatch } = useReportsContext()

  useEffect(() => {
    setSelectedType(optionsForSelect[0])
  }, [optionsForSelect?.length])

  const resetState = () => {
    setSelectedType(null)
    setInputState('')
  }
  const submitData = () => {
    if (inputState && selectedType) {
      getItemsByFilter(
        {type: selectedType.type, subtype: selectedType.subtype, data: inputState?.split(/\r?\n/)?.filter(Boolean)}
      ).then((data) => {
        if (data.skus) {
          dispatch(setModalSkus(data.skus));
          setParamValue({
            id: fieldId,
            values: data.skus.map(sku => ({ id: sku.id, label: sku.title, dto: sku }))
          })
          handleClose()
        }
        if (data.cells) {
          dispatch(setModalCells(data.cells));
          const nodeTopology = data.cells.map(cell => `${cell.zone_id}|${cell.topology.row}|${cell.topology.bay}|${cell.topology.tier}`)
          setParamValue({
            id: fieldId,
            values: nodeTopology, meta: { cells: data.cells }
          })
          handleClose()
        }
        if (data.orders) {
          dispatch(setModalOrders(data.orders));
          setParamValue({
            id: fieldId,
            values: data.orders.map(order => ({ id: order.id, label: order.id, dto: order }))
          })
          handleClose()
        }
      })
    }
  }

  const { fetch: getItemsByFilter } = useFetchItemsByFilters()

  const handleClose = () => {
    resetState()
    onClose()
  }


  return (
    <Modal
      withClose
      onClose={handleClose}
      isOpen={isOpen}
      size="xl"
    >
      <Grid
        gap={'xl'}
        cols={3}
      >
        <GridItem col={3}>
          <Text
            align="center"
            as={'h3'}
            children={'Добавление данных'}
            size={'2xl'}
            weight={'bold'}
          />
        </GridItem>
        <GridItem col={3}>
          <Grid
            cols={6}
            gap={'l'}
          >
            <GridItem col={2}>
              <Select
                label={'Выберите тип параметра'}
                options={optionsForSelect}
                value={selectedType}
                onChange={(value) => setSelectedType(value as ISelectOrderOption)}
              />
            </GridItem>
            <GridItem col={4}>
              <TextField
                label={'  '}
                value={inputState}
                type="textarea"
                rows={10}
                placeholder="Каждое наименование должно начинаться с новой строки"
                onChange={(value) => {
                  setInputState(value || '')
                }}
              />
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem col={3}/>
        <GridItem col={3}>
          <Flex justifyContent={'center'}>
            <Button label="Сохранить данные" onClick={submitData} disabled={!selectedType}/>
          </Flex>
        </GridItem>
      </Grid>
    </Modal>
  )
}

export default DefineMultipleParamsModal
