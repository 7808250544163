import React, { useState } from 'react'
import { Theme } from '@consta/uikit/Theme'
import { Themes, ThemesEnum, ThemesMapper } from '@shared/providers/ThemeProvider/config/themes'
import { useAppContext } from '@shared/providers/AppContextProvider'

interface Props {
  themeVariant?: ThemesEnum
}

export const ThemeProvider = (props: React.PropsWithChildren<Props>) => {
  const {
    themeVariant,
    children
  } = props

  const { theme } = useAppContext()

  let themePreset = ThemesMapper[themeVariant || theme]

  return (
    <Theme preset={themePreset}>{children}</Theme>
  )
}
