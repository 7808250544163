import { BadgePropStatus } from '@consta/uikit/Badge'
import { ReceiptStatusEnum } from 'src/interfaces'


export const RECEIPT_STATUSES: Record<ReceiptStatusEnum | string, string> = {
  [ReceiptStatusEnum.open]: 'Открыт',
  [ReceiptStatusEnum.closed]: 'Закрыт',
  [ReceiptStatusEnum.done]: 'Размещен',
}

export const RECEIPT_STATUS_BADGE_TYPE: Record<ReceiptStatusEnum | string, BadgePropStatus> = {
  [ReceiptStatusEnum.open]: 'system',
  [ReceiptStatusEnum.closed]: 'success',
  [ReceiptStatusEnum.done]: 'error',
}