import api from '@shared/api'
import { ApiReqResult, IWorkspace } from 'src/interfaces'
import { useQuery } from 'react-query'

interface IParams {}
const getJobsList = async (params?: IParams) => {
  const result = await api.get(`/warehouse/workspace`)
  if (!result.data?.success) {
    throw new Error(result.data.message)
  }
  return result.data
}

interface IGetWorkspacesListResult extends ApiReqResult {
  workspaces: IWorkspace[]
}

const useGetWarehouseWorkspacesList = (
  params?: IParams,
  callback?: (workspaces: any[]) => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useQuery<IGetWorkspacesListResult>(
    [`getWorspacesList`, params],
    () => getJobsList(params),
    {
      onSuccess: ({ success, workspaces }) => {
        if (success) {
          callback?.(workspaces)
        } else {
          error?.()
        }
      },
      onError: error,
      onSettled: settled,
    },
  )
}
export default useGetWarehouseWorkspacesList
