import api from '@shared/api'
import { ApiReqResult, IEnumValue } from '@/interfaces'
import { useQuery } from 'react-query'
import { UseQueryOptions } from 'react-query/types/react/types'

const getCountriesList = async () => {
  const result = await api.get(`/enum/country`)
  if (!result?.data?.success) {
    throw result
  }
  return result.data
}

interface IResult extends ApiReqResult {
  values: IEnumValue[]
}

const useGetCountriesList = (
  options?: UseQueryOptions<IResult>
) => {
  return useQuery<IResult>([`getCountriesList`], getCountriesList, options)
}

export default useGetCountriesList
