import React, { createRef, useEffect, useMemo, useRef, useState } from 'react'

import styles from './JoinCodeForm.module.scss'

import { InputWrapper } from 'src/components'
import { TextFieldPropValue } from '@consta/uikit/TextField'


/**
 * Форма c вводом последовательного кода
 */

type ConfirmActionProps = {
  label?: string
  onSubmit: (value: TextFieldPropValue) => void
  codeLength?: number
  isInteger?: boolean
}

const JoinCodeForm = (props: ConfirmActionProps) => {
  const {
    label,
    codeLength = 4,
    onSubmit,
    isInteger = true,
  } = props

  const codeInputArr = useMemo(() => Array(codeLength).fill(1), [codeLength])
  const elementsRef = useRef(codeInputArr.map(() => createRef<HTMLInputElement>()))

  const [resultValue, setResultValue] = useState<TextFieldPropValue[]>(codeInputArr.map(() => null))

  const getActiveIndex = () => {
    const foundIndex = resultValue.findIndex(v => !v)
    return foundIndex === -1 ? 0 : foundIndex
  }

  const handleKeyDown = async (e: any) => {
    /* Удаляем прошлое значение при нажатии бекспейса */
    if (e.key === 'Backspace') {
      const trueResult = resultValue.filter(Boolean)
      const activeIndex = getActiveIndex()
      const notFoundPrevChar = activeIndex-1 < 0
      let lastCharIndex = activeIndex - 1
      /* Нужный индекс -1 и все значения заполнены, значит берем последний элемент для удаления */
      if (notFoundPrevChar && trueResult.length === codeLength) {
        lastCharIndex = codeLength - 1
      }
      /* Нужный индекс -1 и не все значения заполнены, значит смотрим на первый массива для удаления */
      if (notFoundPrevChar && trueResult.length !== codeLength) {
        lastCharIndex = 0
      }
      setResultValue(prev => {
        prev[lastCharIndex] = null
        return [...prev]
      })
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [resultValue])


  useEffect(() => {
    const trueResult = resultValue.filter(Boolean)
    if (trueResult.length !== codeLength) {
      const activeIndex = getActiveIndex()
      elementsRef.current[activeIndex]?.current?.focus()
      return
    }
    onSubmit(resultValue.join('').trim())
  }, [resultValue])


  const handleChange = (index: number, val: TextFieldPropValue) => {
    setResultValue(prev => {
      prev[index] = val ? val[val.length-1] :  null
      return [...prev]
    })
  }

  return (
    <div className={styles.formWrapper}>
      {label ? <p className={styles.label}>{label}</p> : null}
      <div className={styles.inputsWrapper}>
        {
          codeInputArr.map((v, i) =>
            <InputWrapper
              key={i}
              size={'l'}
              handleChange={(val) => handleChange(i, val)}
              value={resultValue[i] || null}
              isInteger={isInteger}
              className={styles.input}
              inputRef={elementsRef.current[i]}
            />,
          )
        }
      </div>
    </div>
  )
}

export default JoinCodeForm
