import TableWithCopy, { TableCopyProps } from 'src/components/Table/TableWithCopy'
import React, { useCallback, useEffect, useMemo, useReducer, useRef } from 'react'
import { getStateValueByType } from './utils'
import { OnChangeFieldType, TemplateTable, TemplateTableData } from '@shared/types/tableGenerator/generatorTypes'
import { TableRowColumnsType } from '@shared/types/tableGenerator/table'
import { generateTableData } from '../generator/tableGenerator'


type Props = {
  selectedMerchantId?: string
  maxHeightBody?: number
  withRowsSelection?: boolean
  editMode: boolean
  tableProps?: Omit<TableCopyProps, 'rows' | 'columns' | 'title'>
  tableData: TemplateTableData,
  table: TemplateTable
  handleChangeTableField: (tableData: TemplateTableData) => void
  handleCreateItem?: () => void
  handleDeleteItem?: (rowId: string) => void
  handleSelectRowsItems?: (rowsIds: string[]) => void
  handleAdditionalModalOpen?: (status: boolean) => void
  isAdditionalParamModalOpen?: boolean
}

const TableGenerator = (props: Props) => {
  const {
    withRowsSelection,
    selectedMerchantId,
    maxHeightBody,
    editMode,
    tableProps = {},
    tableData,
    table,
    handleChangeTableField,
    handleCreateItem,
    handleDeleteItem,
    handleSelectRowsItems,
    handleAdditionalModalOpen,
    isAdditionalParamModalOpen,
  } = props

  /*
   Значения полей для перерендера компонентов
   Требуется для реализации точечного перерендера ячеек без перерендера самой таблицы
  */
  let localStateHelperRef = useRef({})
  let localCheckboxStateHelperRef = useRef<string[]>([])


  /*
  * Сделали точечный ререндер ячеек, но для того нужен локальный refState, чтобы не вызывал ререндер всей таблицы
  * при изменении, после чего мы сами дергаем ререндер нужных компонентов
  * Но при сбросе значений приходится вызывать доп ререндер таблицы
  */
  const [forceUpdateState, forceUpdate] = useReducer(x => x + 1, 0)
  useEffect(() => {
    if (!editMode && localStateHelperRef) {
      localStateHelperRef.current = {}
      forceUpdate()
    }
    if (localCheckboxStateHelperRef) {
      localCheckboxStateHelperRef.current = []
      forceUpdate()
    }
  }, [editMode])


  const handleChangeTableItemDraftValue: OnChangeFieldType = useCallback((args) => {
    const {
      columnId, /* для поиска по столбцам */
      inverseRelatedColumn, /* связанные с текущим столбцом поля */
      rowId, /* для поиска по строкам */
      fieldType, /* для получения нужного формата данных стейта */
      value, /* новое начальное значение (без форматирования) */
    } = args

    /* Текущая строка */
    const currentTableRow = tableData.rows.find(row => row.id === rowId)
    /* Индекс текущей ячейки */
    const currentCellIndex = currentTableRow.cells.findIndex(cell => cell && `${cell.id}` === `${columnId}`)

    /* Связная ячейка, если она есть */
    const inverseRelatedColumnId = inverseRelatedColumn?.id
    const inverseRelatedCellIndex = currentTableRow.cells.findIndex(cell => cell && `${cell.id}` === `${inverseRelatedColumnId}`)

    if (currentCellIndex >= 0) {
      /* Новое значение в формате ячейки с бека */
      currentTableRow.cells[currentCellIndex] = getStateValueByType(value, columnId, fieldType)
      if (inverseRelatedCellIndex >= 0) {
        /* Сбрасываем значение в связной ячейки */
        currentTableRow.cells[inverseRelatedCellIndex] = getStateValueByType(undefined, inverseRelatedColumnId, inverseRelatedColumn?.type)
      }
    }

    handleChangeTableField(tableData)
  }, [handleChangeTableField, tableData])

  const handleSelectItems = useCallback((rowsIds: string[]) => {
    if (!localCheckboxStateHelperRef) return
    localCheckboxStateHelperRef.current = rowsIds
    handleSelectRowsItems?.(rowsIds)
  }, [handleSelectRowsItems])


  /** Данные основной таблицы */
  const { id, title, rows, columns }: TableRowColumnsType = useMemo(() => {
    return {
      id: `${table.id}`,
      title: table.title,
      ...generateTableData({
        merchantId: selectedMerchantId,
        localStateHelper: localStateHelperRef?.current,
        localCheckboxStateHelper: localCheckboxStateHelperRef?.current,
        tableData,
        table,
        editMode,
        deletionAvailable: table?.deletion_available,
        handleChangeTableItemDraftValue,
        handleDeleteItem,
        withRowsSelection,
        handleSelectItems,
        handleAdditionalModalOpen,
      }),
    }
  }, [
    tableData?.rows,
    table?.deletion_available,
    table,
    editMode,
    selectedMerchantId,
    forceUpdateState,
  ])

  return (
    <TableWithCopy
      maxHeightBody={maxHeightBody}
      key={id}
      {...tableProps}
      withCopy={!editMode && !isAdditionalParamModalOpen}
      withCreate={table?.creation_available && editMode}
      title={title}
      rows={rows}
      columns={columns}
      onCreateItem={handleCreateItem}
    />
  )
}

export default React.memo(TableGenerator)
