import dayjs from 'dayjs'
import { DATE_REQUEST_FORMAT, DATE_VIEW } from '@shared/const/date'
import { useState } from 'react'
import { ExpDateTypesEnum } from '@/interfaces'
import { useNotifications } from '@shared/providers/NotificationProvider'


export type DateItemType = { label: string; placeholder?: string; value: ExpDateTypesEnum }
export const dateTypesOptions: DateItemType[] = [
  {
    label: 'дата изготовления',
    placeholder: 'дата изготовления',
    value: ExpDateTypesEnum.dateOfManufacture,
  },
  {
    label: 'годен до',
    placeholder: 'годен до',
    value: ExpDateTypesEnum.expirationDate,
  },
]

type Props = {
  defaultStateType?: ExpDateTypesEnum
}

/**
 * Хук для обработки ввода дат сроков годности
 */

export const useExpDate = (props: Props = {}) => {
  const {
    defaultStateType = ExpDateTypesEnum.expirationDate,
  } = props

  const notification = useNotifications()

  const [dateType, setDateType] = useState<DateItemType>(dateTypesOptions.find(type => type.value === defaultStateType))
  const [dateErrorText, setDateErrorText] = useState<string | null>(null)

  /* годен до */
  const isExpirationDate = dateType?.value === ExpDateTypesEnum.expirationDate
  /* дата изготовления */
  const isDateOfManufacture = dateType?.value === ExpDateTypesEnum.dateOfManufacture


  const showErrorMessage = (err: string) => {
    setDateErrorText(err)
    notification?.show('alert', err)
  }

  /**
   * проверяем дату на корректность ввода
   */
  const checkDateIsValid = (currentDate: dayjs.Dayjs) => currentDate.isValid()

  /**
   * вводим дату "годен до", проверяем, дата раньше или равна сегодняшней?
   */
  const checkExpDateIsBeforeOrEqualNow = (currentDate: dayjs.Dayjs, now: dayjs.Dayjs) => (
    isExpirationDate
    && (currentDate.isBefore(now) || currentDate.isSame(now, 'day'))
  )
  /**
   * вводим дату "годен до", вычитаем месяца срока годности, чтобы получить дату изготовления,
   * проверяем, позже или равна сегодняшней?
   */
  const checkExpDateExpiryMonthsIsAfterOrEqualNow = (currentDate: dayjs.Dayjs, now: dayjs.Dayjs, expiryMonths: number = 999) => (
    isExpirationDate
    && (currentDate
        .subtract(expiryMonths, 'month')
        .isAfter(now)
      ||
      currentDate.isSame(now, 'day'))
  )

  /**
   * вводим "дату изготовления", проверяем, позже сегодняшней?
   */
  const checkDateOfManufactureIsAfterNow = (currentDate: dayjs.Dayjs, now: dayjs.Dayjs) => (
    isDateOfManufacture && currentDate.isAfter(now)
  )

  /**
   *  Проверяем введеную дату на корректность, включая вычисления по сроку годности в месяцах
   */
  const checkDateIsCorrect = (args: { date: string, expiryMonths?: number }): boolean => {
    const {
      date,
      expiryMonths,
    } = args
    if (!expiryMonths && isDateOfManufacture) {
      setDateErrorText('Нет месяцев срока годности')
      return false
    }
    const now: dayjs.Dayjs = dayjs(dayjs(), DATE_VIEW)
    const currentDate: dayjs.Dayjs = dayjs(date, DATE_VIEW)

    if (!checkDateIsValid(currentDate)) {
      setDateErrorText('Некорректная дата')
      return false
    }
    if (checkExpDateIsBeforeOrEqualNow(currentDate, now)) {
      showErrorMessage('Дата срока годности должна быть позже текущей даты')
      return false
    }
    if (checkExpDateExpiryMonthsIsAfterOrEqualNow(currentDate, now, expiryMonths)) {
      const errorText = `Некорректно введена ${date} или сроки годности ${expiryMonths} мес. в номенклатуре товара`
      showErrorMessage(errorText)
      return false
    }
    if (checkDateOfManufactureIsAfterNow(currentDate, now)) {
      const errTxt = 'Дата изготовления должна быть раньше текущей даты'
      showErrorMessage(errTxt)
      return false
    }
    return true
  }

  /**
   * Получаем дату создания по дате "годен до"
   */
  const getDateOfManufactureByExpDate = ({ date, expiryMonths }: { date: string, expiryMonths: number }) => {
    const currentDate: dayjs.Dayjs = dayjs(date, DATE_VIEW)
    return (
      isDateOfManufacture
        ? currentDate
        : currentDate
          .subtract(expiryMonths, 'month')
      )
  }

  /**
   * Получаем дату "годен до" по дате создания
   */
  const getExpDateByDateOfManufacture = (args: { date: string, expiryMonths: number }) => {
    const { date, expiryMonths } = args
    const currentDate: dayjs.Dayjs = dayjs(date, DATE_VIEW)
    return (
      isExpirationDate
        ? currentDate
        : currentDate
          .add(expiryMonths, 'month')
      )
  }

  /**
   * Получаем дату "годен до" и дату производства в зависимости от того, какую дату мы взяли за основу
   * isExpirationDate - передали дату "годен до" и рассчитываем "дату изготовления"
   * isDateOfManufacture - передали "дату изготовления" и рассчитываем дату "годен до"
   * expiryMonths - кол-во месяцев для расчета дат
   */
  const getExpDateAndManufactureDateByDate = (args: { date: string, expiryMonths?: number }) => {
    const {
      date,
      expiryMonths,
    } = args
    return {
      dateOfManufacture: getDateOfManufactureByExpDate({ date, expiryMonths }).format(DATE_REQUEST_FORMAT),
      expirationDate: getExpDateByDateOfManufacture({ date, expiryMonths }).format(DATE_REQUEST_FORMAT),
    }
  }

  return {
    isExpirationDate,
    isDateOfManufacture,

    dateType,

    dateErrorText,
    setDateErrorText,

    setDateType,

    checkDateIsValid,
    checkExpDateIsBeforeOrEqualNow,
    checkExpDateExpiryMonthsIsAfterOrEqualNow,
    checkDateOfManufactureIsAfterNow,
    checkDateIsCorrect,

    getDateOfManufactureByExpDate,
    getExpDateByDateOfManufacture,
    getExpDateAndManufactureDateByDate,
  }
}