import { useMutation } from 'react-query'
import api from '@shared/api'

interface IData {
  code: string
  title: string
}
interface IProps {
  code: string
  data: IData
}

// http://wms-test.kak2c.ru/warehouse-api/sku-category/{code}
const useUpdateCategory = () => {
  const updateCategory = async ({ data, code }: IProps) => {
    const result = await api.post(
      `/sku-category${code ? `/${code}` : ''}`,
      data,
    )

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(updateCategory)
}

export default useUpdateCategory
