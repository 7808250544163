import type { FC, ReactNode } from 'react'
import React from 'react'

import cx from 'classnames'
import styles from './card.module.scss'

interface CardPropsType {
  children: ReactNode;
  className?: string;
}

/**
 * Подложка
 */
const Card: FC<CardPropsType> = ({
  children,
  className = '',
}) => {
  const componentClassName = cx(
    styles.card,
    className,
  );

  return (
    <div className={componentClassName}>
      {children}
    </div>
  );
};

export default Card;
