import React from 'react'

import { InputWrapper } from 'src/components'

import { TextFieldPropValue } from '@consta/uikit/TextField'
import { IDimensions } from 'src/interfaces'
import { TextFieldPropSize } from '@consta/uikit/__internal__/src/components/TextField/TextField'
import { Flex } from '@shared/ui/Flex'
import { Text } from '@shared/ui/Text'

interface DimensionsInputProps {
  direction?: React.ComponentProps<typeof Flex>['direction']
  value: IDimensions
  onChange: (d: IDimensions, field: string) => void
  onFocus?: (fieldName: string) => void
  errors?: { [key: string]: string }
  floatNumbersInputs?: boolean
  autoFocus?: boolean
  size?: TextFieldPropSize
  widthRef?: React.Ref<HTMLInputElement>
  heightRef?: React.Ref<HTMLInputElement>
  depthRef?: React.Ref<HTMLInputElement>
}

const DimensionsInput = (props: DimensionsInputProps) => {
  const {
    direction = 'row',
    value,
    onChange,
    errors,
    size = 'm',
    autoFocus = false,
    widthRef,
    heightRef,
    depthRef,
    onFocus,
  } = props

  const inputs = {
    'width': { label: 'ширина', ref: widthRef },
    'height': { label: 'высота', ref: heightRef },
    'depth': { label: 'длина', ref: depthRef },
  }

  return (
    <Flex
      fullWidth
      gap={'s'}
      direction={'column'}
    >
      <Text
        as={'h4'}
        view={'secondary'}
        weight={'light'}
        children={'Введите габариты, см'}
        size={size}
      />
      <Flex
        gap={'s'}
        direction={direction}
      >
        {Object.keys(inputs).map((inputKey, i) => {
          const itemValue = inputs[inputKey]
          return (
            <InputWrapper
              key={inputKey}
              isDimensions
              value={value[inputKey]}
              id={`dimensions.${inputKey}`}
              handleChange={(inputValue: TextFieldPropValue) => onChange({ ...value, [inputKey]: inputValue }, inputKey)}
              size={size}
              rightSide={itemValue?.label}
              error={errors?.[inputKey] ?? ''}
              inputRef={itemValue?.ref}
              onFocus={() => onFocus?.(inputKey)}
              autoFocus={!i && autoFocus}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}

export default DimensionsInput
