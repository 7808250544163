import React, { FC, useMemo } from 'react'
import styles from './history-changes-table.module.scss'
import { generateTableData } from './utils'
import { IHistoryChanges } from '@/interfaces'
import { TanStackTable } from '@/components/Table/TanStackTable'
import { Button } from '@/components'
import { useToggle } from '@shared/hooks'


interface HistoryChangesTableProps {
  isLoading?: boolean
  changes?: IHistoryChanges[]
}

const HistoryChangesTable: FC<HistoryChangesTableProps> = (props) => {
  const {
    isLoading,
    changes=[]
  } = props

  const {
    value: grouped,
    toggle: groupToggle
  } = useToggle()

  const { rows, columns } = useMemo(() => generateTableData(changes, grouped), [changes, grouped])
  return (
    <div className={styles.main}>
      <TanStackTable<typeof rows[0], unknown>
        withCopy
        withNumerableRows
        withHeader
        extraHeader={
          <>
            <Button
              label={grouped ? 'Разгруппировать' : 'Сгруппировать'}
              size={'s'}
              view={'secondary'}
              onClick={groupToggle}
            />
          </>
        }
        isLoading={isLoading}
        id={`historyChangeTable`}
        pageId={'formHistoryChange'}
        data={rows}
        columns={columns}
        maxHeight={'650px'}
      />
      {/*<TableWithCopy*/}
      {/*  rows={rows}*/}
      {/*  columns={columns}*/}
      {/*  titleClassName={styles.tableTitle}*/}
      {/*  tableWrapperClassName={styles.tableWrap}*/}
      {/*  maxHeightBody={620}*/}
      {/*  isLoading={isLoading}*/}
      {/*/>*/}
    </div>
  )
}

export default HistoryChangesTable
