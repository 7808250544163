import React, { useMemo } from 'react'
// @ts-ignore
import eruda from 'eruda'

import styles from './app.module.scss'

import Main from './Main'

import { AppGlobalUi, useAppContext } from '@shared/providers/AppContextProvider'

import { Loader } from '@consta/uikit/Loader'

import { useAppInit } from '@shared/hooks/useAppInit'
import { SoundProvider } from '@shared/providers/SoundProvider'
import { NotificationProvider } from '@shared/providers/NotificationProvider'
import { ThemeProvider } from '@shared/providers/ThemeProvider'
import { IconsProvider } from '@consta/icons/IconsProvider'

const GlobalLoader = () => (
  <div className={styles.globalLoader}>
    <Loader />
  </div>
)

const App = () => {
  const {
    globalLoading,
    authorized,
  } = useAppContext()

  const {
    isReady,
  } = useAppInit()

  return useMemo(
    () => (
      <>
        {isReady ? (
          <ThemeProvider>
            <IconsProvider>
              <AppGlobalUi>
                <SoundProvider>
                  <NotificationProvider>
                    {globalLoading ? <GlobalLoader /> : null}
                    <Main authorized={authorized} />
                  </NotificationProvider>
                </SoundProvider>
              </AppGlobalUi>
            </IconsProvider>
          </ThemeProvider>
        ) : null}
      </>
    ),
    [authorized, globalLoading, isReady],
  )
}

export default App
