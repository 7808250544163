import { useMutation } from 'react-query'
import api from '@shared/api'

import { ApiReqResult, IContainer, IPhysicalSetOrderDto } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'
import { provideMock } from '../../mocks'
import { MOCK_START_PHYSICAL_SET } from '../../mocks/physicalSet'

interface IResult extends ApiReqResult {
  order: IPhysicalSetOrderDto
  containers: IContainer[]
}

interface IParams {
  orderId: string
}

const useStartPackingPhysicalSet = (
  options?: UseMutationOptions<any, IResult, any>,
) => {
  return useMutation(async (params: IParams) => {
      return provideMock<IParams, IResult>(
        async () => {
          const result = await api.post(`/physical-set-order/${params.orderId}/packing-start`)
          if (!result?.data?.success) {
            throw result
          }
          return result.data
        },
        params,
        [
          {
            key: 'orderId',
            value: 'mock',
            result: MOCK_START_PHYSICAL_SET,
            logData: 'Старт упаковки комплекта',
          },
        ],
      )()
    },
    options,
  )
}

export default useStartPackingPhysicalSet
