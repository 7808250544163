import React from 'react'
import cx from 'classnames'
import styles from './informer.module.scss'

import { Button } from '@shared/ui/btns/Button'
import { IInformer } from 'src/interfaces'
import { Timer } from '@shared/ui/Timer'
import { Flex } from '@shared/ui/Flex'
import { Text } from '@shared/ui/Text'

interface IInformerProps extends IInformer {

}

const Informer = ({ status, onClose, text, className }: IInformerProps) => {
  const isError = status === 'error'
  const isSuccess = status === 'success'
  const isInfo = status === 'info'
  return (
    <Flex
      align="center"
      justifyContent={'between'}
      className={`
        ${cx(styles.informer, className)} 
        ${isError ? styles.informerError : ''}
        ${isSuccess ? styles.informerSuccess : ''}
        ${isInfo ? styles.informerInfo : ''}
      `}
    >
      <Flex gap={'xs'}>
        <Timer size="m" handleComplete={onClose} />
        <Text weight={'semibold'} size={'2xs'} className={styles.text} children={text}/>
      </Flex>
      <Button
        className={styles.btn}
        size="s"
        onClick={onClose}
        label="Закрыть"
        view="ghost"
      />
    </Flex>
  )
}

export default Informer
