import React from 'react'
import { Checkbox } from '@consta/uikit/Checkbox'
import styles from './CheckboxCell.module.scss'

interface CellProps {
  /** Id строки таблицы */
  fieldId: string
  /** Массив id всех строк */
  fieldsIds: string[]
  /** Выбранные id строк */
  checkedItemsIds: string[]
  /** Метод стейта для выбора строк */
  setCheckedItemsIds: React.Dispatch<React.SetStateAction<string[]>>
  /** Флаг чекбокса в хедере */
  isHeaderCheckbox?: boolean
  readonly?: boolean
}

export const CheckboxCell = (props: CellProps) => {
  const {
    isHeaderCheckbox,
    fieldId,
    fieldsIds,
    checkedItemsIds,
    setCheckedItemsIds,
    readonly = false,
  } = props

  const checked = checkedItemsIds.includes(fieldId)
  const allFilesIds = isHeaderCheckbox ? fieldsIds : []
  const allFilesChecked =
    allFilesIds?.length === checkedItemsIds.length && checkedItemsIds.length > 0
  const intermediate = Boolean(
    checkedItemsIds.length && allFilesIds?.length !== checkedItemsIds.length,
  )
  return (
    <Checkbox
      className={styles.checkbox}
      checked={isHeaderCheckbox ? allFilesChecked : checked}
      intermediate={isHeaderCheckbox && intermediate}
      disabled={readonly}
      onClick={(e) => {
        e.stopPropagation()
      }}
      onChange={(checkedEvent) => {
        if (isHeaderCheckbox) {
          setCheckedItemsIds(fieldsIds.length === checkedItemsIds.length ? [] : allFilesIds)
          return
        }
        setCheckedItemsIds(checkedItemsIds.includes(fieldId)
          ? checkedItemsIds.filter((prevId) => prevId !== fieldId)
          : [...checkedItemsIds, fieldId])
      }}
    />
  )
}
