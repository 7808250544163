import { FieldRow, FieldType } from '@widgets/GeneratorFields'
import { ICombobox } from '@/interfaces'

export const generateFilterParams = (filterState: Record<string, any>) => {
  const inputs = ['barcode', 'title', 'article', 'external_id'].reduce((acc, itemKey) => ({
    ...acc,
    [itemKey]: filterState[itemKey] || ''
  }), {})
  return {
    ...inputs,
    merchant_ids: filterState['merchant_ids']?.map((merchant: ICombobox) => merchant.value),
    size: 100,
  }
}

export const generateFilters = (): FieldRow[] => {
  return [{
    fields: [
      {
        type: FieldType.input,
        fieldProps: {
          id: 'barcode',
          label: 'ШК',
          viewDescription: 'ШК',
        },
      },
      {
        type: FieldType.input,
        fieldProps: {
          id: 'article',
          label: 'Артикул',
          viewDescription: 'Артикул',
        },
      },
      {
        type: FieldType.input,
        fieldProps: {
          id: 'title',
          label: 'Наименование',
          viewDescription: 'Наименование',
        },
      },
      {
        type: FieldType.input,
        fieldProps: {
          id: 'external_id',
          label: 'Внешний ключ',
          viewDescription: 'Внешний ключ',
        },
      },
      {
        type: FieldType.MERCHANTS,
        fieldProps: {
          id: 'merchant_ids',
          isMultiple: true,
          label: 'Организация',
          viewDescription: 'Организация',
        },
      },
    ]
  }]
}