import api from '@shared/api'
import { ApiReqResult, IEnumValue } from '@/interfaces'
import { useQuery } from 'react-query'
import { UseQueryOptions } from 'react-query/types/react/types'


interface IResult extends ApiReqResult {
  values: IEnumValue[]
}

const useGetRuleType = (
  options?: UseQueryOptions<any,unknown,IResult, any>,
) => {
  return useQuery<any,unknown,IResult, any>([`getRuleType`],
    async () => {
      const result = await api.get(`/enum/rule-type`)
      if (!result.data?.success) {
        throw result
      }
      return result.data
    },
    options
  )
}

export default useGetRuleType
