import React from 'react'

import {
  ICellPlace,
  ICellSkuStock,
  IPlacing,
  ISku,
  ISupplyConditionDescription,
  ITableColumn,
  ITableRow,
} from 'src/interfaces'

import { NomenclatureCell, TitleCell } from '../../../../../components'
import { ImageCell } from 'src/components'
import { QuantityCell } from '@pages/OperationsAndDocumentsPage/components'
import { Button } from '@shared/ui/btns/Button'
import { getNomenclatureNameValue } from '../../../../../helpers/common'

export const generateColumns = ({
  withCell = true,
  deleteItem,
  editItem,
  supplyId,
  refetchData
}: {
  withCell?: boolean,
  deleteItem: (itemId: React.ReactNode | string, placementId: string) => void,
  editItem: (item: any) => void, //TODO: поправить
  supplyId: string,
  refetchData: () => void
}): ITableColumn[] => {
  const cellColumn = {
    key: 'cell',
    title: 'Ячейка',
    title_txt: 'Ячейка',
    gridCellWidth: '200px',
  }

  const placeButtonColumn: ITableColumn = {
    key: 'action__place_button',
    title: 'Разместить',
    title_txt: 'Разместить',
    renderCell: ({ row }) => (
      <Button label="Разместить" onClick={() => editItem(row)} />
    ),
  }

  const deleteButtonColumn: ITableColumn = {
    key: 'action__delete_button',
    title: 'Удалить',
    title_txt: 'Удалить',
    renderCell: ({ row }) => (
      <Button
        label="Удалить"
        onClick={() => deleteItem(row.item_id, supplyId)}
      />
    ),
  }


  const skuColumns: ITableColumn[] = [
    {
      key: 'condition',
      title: 'Годность',
      title_txt: 'Годность',
      gridCellWidth: '150px',
    },
    {
      key: 'img',
      title: 'Фото',
      title_txt: 'Фото',
      gridCellWidth: '150px',
      renderCell: ({ row }) => <ImageCell image={String(row.img)} />,
    },
    {
      key: 'name_value',
      title: 'Номенклатура',
      title_txt: 'Номенклатура',
      renderCell: ({ row }) => (
        <NomenclatureCell title={row.name} itemId={`${row.id}` || '-'} />
      ),
    },
    {
      key: 'barcode',
      title: 'Штрихкод',
      title_txt: 'Штрихкод',
    },
    {
      key: 'quantity',
      title: 'Кол-во',
      title_txt: 'Кол-во',
      gridCellWidth: '150px',
      renderCell: ({row}) => (
        row.item_id ? (
          <QuantityCell
            quantity={row.quantity}
            docId={supplyId}
            itemId={row.item_id}
            refetchData={refetchData}
          />
        ) : row.quantity
      )
    },
  ]

  return withCell
    ? [cellColumn, ...skuColumns, deleteButtonColumn]
    : [...skuColumns, placeButtonColumn]
}
export const generateStockRows = (stocks: ICellSkuStock[], skus: ISku[]): ITableRow[] => { /*Генерация плана на размещение*/
  return stocks.map(stockItem => {
    const originalSku = skus.find((sku) => sku.id === stockItem.sku_id)
    const barcode = originalSku?.barcodes?.[0]
    return ({
      id: stockItem.sku_id,
      img: originalSku?.image,
      name_value: getNomenclatureNameValue(originalSku),
      name: (
        <TitleCell
          title={originalSku?.title || ''}
          article={originalSku?.article || ''}
        />
      ),
      quantity: stockItem.quantity,
      barcode: barcode?.barcode,
      theme: 'white',
      defaultRowView: true,
      defaultIsExpanded: true,
      condition: stockItem?.condition ? ISupplyConditionDescription[stockItem?.condition] : '-',
      sku_batch_id: stockItem.sku_batch_id,
    })
  })
}
export const generateRows = (placing: IPlacing | null, cells: ICellPlace[], skus: ISku[]): ITableRow[] => {
  if (!placing) {
    return []
  }
  return placing.items.map((placingItem) => {
    const originalSku = skus.find((sku) => sku.id === placingItem.sku_id)
    const cell = cells.find(cell => cell.barcode === placingItem.cell_barcode)
    const barcode = originalSku?.barcodes?.[0]
    return {
      id: placingItem.sku_id,
      img: originalSku?.image,
      name_value: getNomenclatureNameValue(originalSku),
      name: (
        <TitleCell
          title={originalSku?.title || ''}
          article={originalSku?.article || ''}
        />
      ),
      quantity: placingItem.quantity,
      barcode: barcode?.barcode,
      theme: 'white',
      defaultRowView: true,
      defaultIsExpanded: true,
      cell: cell?.title || '-',
      condition: cell?.condition ? ISupplyConditionDescription[cell?.condition] : '-',
      item_id: placingItem.id
    }
  })
}
