const infoObj = {
  id: '',
  inn: '',
  kpp: '',
  ogrn: '',
  title: '',
}

type legalObjectKey = keyof typeof infoObj

export const companyData = [
  {
    label: 'ИНН',
    key: 'inn' as legalObjectKey,
    isRequired: true,
  },
  {
    label: 'КПП',
    key: 'kpp' as legalObjectKey,
  },
  {
    label: 'ОГРН',
    key: 'ogrn' as legalObjectKey,
  },
]
