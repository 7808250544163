import { useMutation } from 'react-query'
import api from '@shared/api'

interface IData {
  title: string
  data: string
}
interface IProps {
  instructionId?: string
  data: IData
}

// http://wms-test.kak2c.ru/warehouse-api/instructions/{instruction_id}/sound

const useUpdateSound = () => {
  const updateSound = async ({ data, instructionId }: IProps) => {
    const result = await api.post(`/instructions/${instructionId}/sound`, data)

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(updateSound)
}

export default useUpdateSound
