import React, { FC, useMemo, useState } from 'react'

import type { ISection } from '../../types'
import { useGetSerialNumbers } from '@/hooks'
import { useFilterState } from '@shared/hooks'
import { generateFilterParams, generateFilters } from './utils/filters'
import { generateTableData } from './utils/table'
import { Button } from '@/components'
import { DirectoriesLayout } from '@pages/DirectoriesPage/components/DirectoriesLayout/DirectoriesLayout'

interface Props extends ISection {
}

const SerialNumbers: FC<Props> = (props) => {
  const { title = '' } = props

  const {
    filterStateDebounced,
    filterState,
    handleChangeFilter,
  } = useFilterState({
    withoutHistory: true, onChangeFilterState: () => {
      setPaginationPage(0)
    },
  })
  const [paginationPage, setPaginationPage] = useState(0)


  const {
    isFetching,
    refetch,
    data: serialNumbersData,
  } = useGetSerialNumbers(
    {
      ...generateFilterParams({ ...filterStateDebounced } || {}),
      page: paginationPage,
      size: 50,
    },
  )

  const filtersConfig = generateFilters()

  const { rows, columns } = useMemo(() => {
    return generateTableData(
      serialNumbersData?.serial_numbers || [],
      serialNumbersData?.skus || [],
    )
  }, [serialNumbersData?.serial_numbers])


  return (
    <DirectoriesLayout
      withTable
      withFilter
      withSearch

      title={title}

      extraHeader={
        <Button
          label='Обновить данные'
          disabled={isFetching}
          size='s'
          onClick={() => refetch()}
        />
      }

      filtersConfig={filtersConfig}
      filterState={filterState}
      handleChangeFilter={handleChangeFilter}

      isLoading={isFetching}
      columns={columns}
      rows={rows}
      pagination={serialNumbersData?.pagination}
      onChangePage={setPaginationPage}
    />
  )
}

export default SerialNumbers
