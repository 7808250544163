import React from 'react'
import cx from 'classnames'
import styles from './LoaderWrapper.module.scss'
import { Loader } from '@consta/uikit/Loader'
import { FCC } from '@shared/helpers'

interface LoaderWrapperProps {
	isLoading: boolean
}

export const LoaderWrapper: FCC<LoaderWrapperProps> = (props) => {
  const {
		children,
    isLoading
  } = props

	return (
		<div className={cx(styles.LoaderWrapper)}>
			{ isLoading ? <Loader className={cx(styles.Loader)}/> : null }
			{children}
		</div>
	);
};
