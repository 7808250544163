import React, { useState } from 'react'
import cx from 'classnames'

import styles from './notifications.module.scss'

import Notification from './Notification'

import { useNotifications } from '../../lib/useNotifications'
import { INotification } from '@shared/providers/NotificationProvider/config/schema'
import { DetailsModal } from '@shared/providers/NotificationProvider/ui/DetailsModal/DetailsModal'


const Notifications = () => {
  const context = useNotifications()

  const [notif, setNotif] = useState<INotification | null>(null)

  const {allWidthNotifs, commonNotifs} = Object.values(context?.state || {})
    .reduce((acc, notif) => {
      const currentNotifStackKey = notif?.options?.allWidth ? 'allWidthNotifs' : 'commonNotifs'
      acc[currentNotifStackKey]?.push(notif)
      return acc
    }, {allWidthNotifs: [], commonNotifs: []})


  const handleClickCloseModalBtn = () => {
    /* Закрываем модалку и нотиф */
    context?.clear(notif.id)
    setNotif(null)
  }

  return (
    <>
      <div className={cx(styles.wrapperAllWidth)}>
          <div className={cx(styles.inner)}>
            {allWidthNotifs.map((notif, i) => (
              <Notification key={notif.id + i} {...notif} />
            ))}
          </div>
      </div>
      <div className={cx(styles.wrapper)}>
          <div className={cx(styles.inner)}>
            {commonNotifs.map((notif, i) => (
              <Notification key={notif.id + i} {...notif} showNotifDetail={setNotif}/>
            ))}
          </div>
      </div>

      {notif?.allErrors ? (
        <DetailsModal
          type={notif.type}
          allErrors={notif.allErrors}
          onClose={handleClickCloseModalBtn}
        />
      ): null}
      </>
  )
}

export default Notifications
