import { RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { ITableRow } from '@/interfaces'

type Props = {
  withVirtualScroll: boolean
  rows: ITableRow[],
  tableRef: Readonly<RefObject<HTMLElement>>,
  displayedItemsLen?: number // отображаемое кол-во элементов на стр.
  maxRowsLenToStartVirtualScroll?: number // число строк с которого включается виртуальный скролл
}

export const useCustomVirtualScroll = (props: Props) => {
  const {
    rows,
    tableRef,
    maxRowsLenToStartVirtualScroll = 300,
    displayedItemsLen = 100,
    withVirtualScroll
  } = props

  const scrollPoints = useRef([])

  const virtualScrollIsActive = useMemo(() =>
    (rows?.length > maxRowsLenToStartVirtualScroll) && withVirtualScroll,
    [rows?.length, withVirtualScroll])

  const [list, setList] = useState({ itemsDisplayed: displayedItemsLen, data: [] })

  const foundExcludedRows = useMemo(() => rows.filter(row => row.excludeFromVirtualScroll || row.isTotal), [rows])

  /* Добавляет строки которые требуется оставлять вне виртуального скрола,
  например итоги таблицы */
  const getRowsWithExclude = (slicedRows: ITableRow[]) => {
    // Пустые строки, нужны для копирования строк исключений, так как копирование завязано
    // на индексах строк, для получения нужных индексов мы искусственно добавляем их, но не отрисовываем
    const emptyRows = Array(rows.length - slicedRows.length - foundExcludedRows.length)
    return [...slicedRows, ...emptyRows, ...foundExcludedRows]
  }
  useEffect(() => {
    if (!virtualScrollIsActive) {
      setList({
        itemsDisplayed: displayedItemsLen,
        data: rows,
      })
      return
    }

    setList({
      itemsDisplayed: displayedItemsLen,
      data: getRowsWithExclude(rows.slice(0, displayedItemsLen)),
    })
  }, [rows, virtualScrollIsActive])

  let handleTableScroll = () => {
    if (!virtualScrollIsActive) return
    let tableEl = tableRef.current
    const addItemsCount = displayedItemsLen / 2
    const tableNeedAddPart = (tableEl.scrollTop + 1 >= (tableEl.scrollHeight - tableEl.offsetHeight))

    let newItemsLen = list.itemsDisplayed
    if (tableNeedAddPart) {
      newItemsLen = list.itemsDisplayed + addItemsCount
      scrollPoints?.current?.push(tableEl.scrollHeight - tableEl.offsetHeight)
    }

    setList({
      itemsDisplayed: newItemsLen,
      data: newItemsLen <= rows.length
        ? getRowsWithExclude(rows.slice(0, newItemsLen))
        : getRowsWithExclude(rows)
      ,
    })
  }
  return {
    virtualScrollIsActive,
    listData: list.data,
    handleTableScroll
  }
}