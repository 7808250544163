import { TemplateFieldType } from '../types/tableGenerator/generatorTypes'
import { getCalendarValueFormatByFieldType } from './calendar'
import { EXCLUDE_FIELD_PREFIX } from '@shared/helpers/consts'

export const generateFilterParamsModified = (
  valuesState: Record<string, any>,
  fields: any[],
) => {
  let result = []
  fields.forEach((field) => {
    const searchId = 'search'
    if (field.type === TemplateFieldType.SEARCH && valuesState[searchId]) {
      result.push({
        id: field.id,
        value: valuesState[searchId]?.trim(),
      })
      return
    }

    if (!valuesState[field.id]) return

    const fieldHasExclude = valuesState[`${EXCLUDE_FIELD_PREFIX}${field.id}`]

    const multiSelectsType = [
      TemplateFieldType.SKUS,
      TemplateFieldType.CELLS,
      TemplateFieldType.MERCHANTS,
      TemplateFieldType.CONTRACTORS,
      TemplateFieldType.MULTISELECT,
    ].includes(field.type)

    const singleSelectsType = [
      TemplateFieldType.SKU,
      TemplateFieldType.CELL,
      TemplateFieldType.MERCHANT,
      TemplateFieldType.CONTRACTOR,
      TemplateFieldType.SELECT,
      TemplateFieldType.WAREHOUSE_PLACE,
    ].includes(field.type)

    const dateType = [
      TemplateFieldType.TIME,
      TemplateFieldType.DATE,
      TemplateFieldType.DATETIME,
      TemplateFieldType.YEARMONTH
    ].includes(field.type)

    const textInput = [
      TemplateFieldType.TEXT,
      TemplateFieldType.TEXT_AREA,
      TemplateFieldType.NUMBER
    ].includes(field.type)

    switch(true) {
      case multiSelectsType:
        result.push({
          id: field.id,
          values: valuesState[field.id].map((item) => item.id),
          exclude: fieldHasExclude
        })
        break
      case singleSelectsType:
        result.push({
          id: field.id,
          value: valuesState[field.id].id,
          exclude: fieldHasExclude
        })
        break
      case textInput:
        result.push({
          id: field.id,
          value: field.type === TemplateFieldType.NUMBER
            ? Number(valuesState[field.id])
            : valuesState[field.id],
          exclude: fieldHasExclude
        })
        break
      case [TemplateFieldType.BOOLEAN].includes(field.type):
        const valueItem = valuesState[field.id]
        result.push({
          id: field.id,
          value: typeof valueItem === 'object' && valueItem !== null
            ? valuesState[field.id]?.id
            : valuesState[field.id],
          exclude: fieldHasExclude
        })
        break
      case TemplateFieldType.WAREHOUSE_PLACES === field.type:
        const values = valuesState[field.id]?.reduce((acc, item) => {
          if (typeof item === 'object' && item !== null) return [...acc, item.id]
          return [...acc, item]
        }, []) || []
        result.push({ id: field.id, values: values,
        exclude: fieldHasExclude})
        break
      case TemplateFieldType.BASE64 === field.type:
        result.push({ id: field.id, value: valuesState[field.id]})
        break
      case dateType:
        result.push({
          id: field.id,
          value: getCalendarValueFormatByFieldType(field.type, valuesState[field.id]),
          exclude: fieldHasExclude
        })
        break
    }
  })

  return result
}