import { PackagingSettingsFormParams } from '@/interfaces/packaging'
import { PackagingPurposes } from '@/interfaces'
import { ComboboxItemDefault } from '@consta/uikit/Combobox'

export const generateFilterParams = (filterState: Record<string, ComboboxItemDefault>): PackagingSettingsFormParams => {
  return {
    purpose: filterState?.purpose?.id as PackagingPurposes,
    merchant_id: filterState?.merchant_id ? `${filterState?.merchant_id?.id}` : undefined,
    delivery_company: filterState?.delivery_company ? `${filterState?.delivery_company?.id}` : undefined,
    delivery_company_product: filterState?.delivery_company_product ? `${filterState?.delivery_company_product?.id}` : undefined,
    sku_id: filterState?.sku_id ? `${filterState?.sku_id?.id}` : undefined,
  }
}