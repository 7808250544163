import React, { FC } from 'react'
import noImage from '@shared/assets/images/noImage.png'
import { Img } from '@shared/ui/Img'
import styles from './ImageCell.module.scss'
import cx from 'classnames'

interface ImageCellProps {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl'
  image?: string
  yandex?: boolean
}

export const ImageCell: FC<ImageCellProps> = ({size, image, yandex = true }) => {
  return (
    <Img
      className={cx(styles.img, styles[size])}
      src={typeof image === 'string' ? image : noImage}
      size={100}
      yandex={yandex}
      modal
    />
  )
}
