import React from 'react'
import { User as ConstaUser } from '@consta/uikit/User'
import {
  UserPropSize,
  UserPropStatus,
  UserPropView,
  UserPropWidth,
} from '@consta/uikit/__internal__/src/components/User/User'

type UserProps = React.ComponentProps<typeof ConstaUser> & {
  avatarUrl?: string;
  name?: string;
  size?: UserPropSize;
  view?: UserPropView;
  width?: UserPropWidth;
  status?: UserPropStatus;
  onlyAvatar?: boolean;
  info?: string;
}

export const User = (props: UserProps) => {
  return (
    <ConstaUser {...props}/>
  )
}

