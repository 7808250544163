import { createIcon } from '@consta/icons/Icon'
import { SVGProps } from 'react'

const IconSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M4.5 0H5.5V1.5C5.5 1.77614 5.72386 2 6 2C6.27614 2 6.5 1.77614 6.5 1.5V0H7.5C8.05229 0 8.5 0.447715 8.5 1V4C8.5 4.55228 8.05229 5 7.5 5H4.5C3.94772 5 3.5 4.55228 3.5 4V1C3.5 0.447715 3.94772 0 4.5 0Z' />
    <path
      d='M1.5 6H2.5V7.5C2.5 7.77614 2.72386 8 3 8C3.27614 8 3.5 7.77614 3.5 7.5V6H4.5C5.05228 6 5.5 6.44772 5.5 7V10C5.5 10.5523 5.05228 11 4.5 11H1.5C0.947715 11 0.5 10.5523 0.5 10V7C0.5 6.44772 0.947715 6 1.5 6Z' />
    <path d='M11 12C11.5523 12 12 12.4477 12 13V14H10V13H7V14H5V13H2V14H0V13C0 12.4477 0.447715 12 1 12H11Z' />
    <path
      d='M8.5 6H7.5C6.94771 6 6.5 6.44772 6.5 7V10C6.5 10.5523 6.94771 11 7.5 11H10.5C11.0523 11 11.5 10.5523 11.5 10V7C11.5 6.44772 11.0523 6 10.5 6H9.5V7.5C9.5 7.77614 9.27614 8 9 8C8.72386 8 8.5 7.77614 8.5 7.5V6Z' />
  </svg>
)

export const IconPallet = createIcon({
  l: IconSizeM,
  m: IconSizeM,
  s: IconSizeM,
  xs: IconSizeM,
  name: 'IconPallet',
})