import { ComponentClass, FunctionComponent } from 'react'

import {
  AcceptanceFormPage,
  AcceptanceScanPage,
  AcceptanceTSDWrapper,
  ArmPrintingPage,
  AssemblingPhysicalSet,
  AuthContainer,
  BatchGeneration,
  BatchSorting,
  CrossdockingSortingPage,
  DirectoriesPage,
  FormationTransportPlaces,
  FormationTransportPlacesWbFbs,
  GoodsPickingPage,
  GoodsPickingTSDPage,
  GoodsRelocation,
  InventoryTSDPage,
  MarkingTransportPlacePage,
  OperationsAndDocumentsPage,
  OrderScanFormPage,
  PackingInstructions,
  PackingInstructionsForm,
  PackingOrderPage,
  PackingV2,
  PartialInventoryTSDPage,
  ProductPlacement,
  RefundAcceptance,
  RegisterFormPage,
  RegistriesScanPage,
  RelabelFormPage,
  RelabelScanPage,
  Reports,
  SettingsPage,
  ShipmentFromWarehouseEditPage,
  ShipmentFromWarehouseLogPage,
  ShipmentFromWarehouseScanPage,
  ShipmentFromWarehouseTrafficPage,
  WarehouseCellControlPage,
  WarehouseControlScanPage,
  WarehouseSkuControlPage,
  DocumentForm,
  DashboardPage,
  AssemblyTsdPage,
  DocumentsList
} from '@pages/index'

import { PRIVATE_PATHS, PUBLIC_PATHS } from '@shared/routing'
import { TemplatesEnum } from '@shared/types/templates'


export type Routes = {
  path?: string
  component: FunctionComponent | ComponentClass
  default?: boolean
  template?: TemplatesEnum
  props?: any
}[]

export const privateRoutes: Routes = [
  {
    path: PRIVATE_PATHS.OPERATION,
    component: AuthContainer,
    props: { page: 'operation' },
    default: true,
  },
  {
    path: PRIVATE_PATHS.ORDER_SCAN,
    component: OrderScanFormPage,
    template: TemplatesEnum.scan,
  },
  {
    path: PRIVATE_PATHS.ORDER_FORM,
    component: PackingOrderPage,
    template: TemplatesEnum.single,
  },
  {
    path: PRIVATE_PATHS.ORDER_FORM_2,
    component: PackingV2,
    template: TemplatesEnum.single,
  },
  {
    path: PRIVATE_PATHS.RESUME_ORDER_FORM,
    component: PackingOrderPage,
    template: TemplatesEnum.single,
  },
  {
    path: PRIVATE_PATHS.ORDER_CLOSED,
    component: PackingOrderPage,
    template: TemplatesEnum.single,
  },
  {
    path: PRIVATE_PATHS.SETTINGS,
    component: SettingsPage,
  },
  {
    path: PRIVATE_PATHS.ACCEPTANCE_SCAN,
    component: AcceptanceScanPage,
  },
  {
    path: PRIVATE_PATHS.ACCEPTANCE_FORM,
    component: AcceptanceFormPage,
    template: TemplatesEnum.single,
  },
  {
    path: PRIVATE_PATHS.ACCEPTANCE_FORM_TSD,
    component: AcceptanceTSDWrapper,
    template: TemplatesEnum.TSD,
  },
  
  {
    path: PRIVATE_PATHS.CROSSDOCKING_SORTING,
    component: CrossdockingSortingPage,
  },

  {
    // Сборка комплектов
    path: PRIVATE_PATHS.ASSEMBLING_PHYSICAL_SET,
    component: AssemblingPhysicalSet,
  },

  {
    path: PRIVATE_PATHS.REGISTRY_SCAN,
    component: RegistriesScanPage,
    template: TemplatesEnum.TSD,
  },

  {
    path: PRIVATE_PATHS.GOODS_PICKING_TSD,
    component: GoodsPickingTSDPage,
    template: TemplatesEnum.TSD,
  },
  {
    path: PRIVATE_PATHS.INVENTORY_TSD,
    component: InventoryTSDPage,
    template: TemplatesEnum.TSD,
  },
  {
    path: PRIVATE_PATHS.PARTIAL_INVENTORY_TSD,
    component: PartialInventoryTSDPage,
    template: TemplatesEnum.TSD,
  },
  {
    path: PRIVATE_PATHS.GOODS_PICKING,
    component: GoodsPickingPage,
  },

  {
    path: PRIVATE_PATHS.REGISTRY_FORM,
    component: RegisterFormPage,
    template: TemplatesEnum.TSD,
  },

  {
    path: PRIVATE_PATHS.WAREHOUSE_CONTROL_SCAN,
    component: WarehouseControlScanPage,
    template: TemplatesEnum.TSD,
  },
  {
    path: PRIVATE_PATHS.WAREHOUSE_CELL_CONTROL_FORM,
    component: WarehouseCellControlPage,
    template: TemplatesEnum.TSD,
  },
  {
    path: PRIVATE_PATHS.WAREHOUSE_SKU_CONTROL_FORM,
    component: WarehouseSkuControlPage,
    template: TemplatesEnum.TSD,
  },

  {
    path: PRIVATE_PATHS.F_OF_PLACES_LIST,
    component: FormationTransportPlacesWbFbs,
    template: TemplatesEnum.TSD,
  },
  {
    path: PRIVATE_PATHS.PRODUCT_PLACEMENT,
    component: ProductPlacement,
    template: TemplatesEnum.TSD,
  },
  {
    path: PRIVATE_PATHS.GOODS_RELOCATION,
    component: GoodsRelocation,
    template: TemplatesEnum.TSD,
  },
  {
    path: PRIVATE_PATHS.BATCH_SORTING,
    component: BatchSorting,
    template: TemplatesEnum.TSD,
  },

  {
    path: PRIVATE_PATHS.F_OF_TRANSPORT_PLACES_LIST,
    component: FormationTransportPlaces,
  },

  {
    path: PRIVATE_PATHS.BATCH_GENERATION,
    component: BatchGeneration,
  },
  {
    path: PRIVATE_PATHS.BATCH_GENERATION_PHYSICAL_SET,
    component: BatchGeneration,
  },

  {
    path: PRIVATE_PATHS.MARKING_TRANSPORT_PLACE,
    component: MarkingTransportPlacePage,
  },
  {
    path: PRIVATE_PATHS.SHIPMENT_FROM_WAREHOUSE_SCAN,
    component: ShipmentFromWarehouseScanPage,
    // template: 'single',
  },
  {
    path: PRIVATE_PATHS.SHIPMENT_FROM_WAREHOUSE_EDIT,
    component: ShipmentFromWarehouseEditPage,
    template: TemplatesEnum.single,
  },
  {
    path: PRIVATE_PATHS.SHIPMENT_FROM_WAREHOUSE_LOG,
    component: ShipmentFromWarehouseLogPage,
    template: TemplatesEnum.single,
  },
  {
    path: PRIVATE_PATHS.SHIPMENT_FROM_WAREHOUSE_TRAFFIC,
    component: ShipmentFromWarehouseTrafficPage,
    template: TemplatesEnum.single,
  },

  {
    path: PRIVATE_PATHS.REPORTS,
    component: Reports,
  },
  {
    path: PRIVATE_PATHS.OPERATIONS_AND_DOCUMENTS,
    component: OperationsAndDocumentsPage,
  },
  {
    path: PRIVATE_PATHS.REFUND_ACCEPTANCE,
    component: RefundAcceptance,
  },
  {
    path: PRIVATE_PATHS.DIRECTORIES,
    component: DirectoriesPage,
  },

  {
    path: PRIVATE_PATHS.ARM_PRINTING,
    component: ArmPrintingPage,
  },
  {
    path: PRIVATE_PATHS.PACKING_INSTRUCTIONS,
    component: PackingInstructions,
  },
  {
    path: PRIVATE_PATHS.PACKING_INSTRUCTIONS_FORM,
    component: PackingInstructionsForm,
    template: TemplatesEnum.single,
  },
  {
    path: PRIVATE_PATHS.GOODS_RELABEL_SCAN,
    component: RelabelScanPage,
  },
  {
    path: PRIVATE_PATHS.GOODS_RELABEL_FORM,
    component: RelabelFormPage,
  },
  {
    path: PRIVATE_PATHS.DOCUMENTS_LIST,
    component: DocumentsList,
  },
  {
    path: PRIVATE_PATHS.DOCUMENT_FORM,
    component: DocumentForm,
  },
  {
    path: PRIVATE_PATHS.NEW_ANALYTICS,
    component: DashboardPage,
  },
  {
    path: PRIVATE_PATHS.NEW_ASSEMBLY_TSD,
    component: AssemblyTsdPage,
    template: TemplatesEnum.TSD,
  }
]

export const publicRoutes: Routes = [
  {
    path: PUBLIC_PATHS.AUTH,
    component: AuthContainer,
    props: { page: 'auth' },
    default: true,
  },
]
