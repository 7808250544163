import { functionalUpdate, makeStateUpdater, RowData, Table, TableFeature, Updater } from '@tanstack/react-table'
import { Row } from '@tanstack/table-core/src/types'
import {
  ContextMenuOptions, ContextMenuState,
  ContextMenuTableState,
} from '@/components/Table/TanStackTable/lib/features/contextMenu/types'
import {
  ExpandExtensionOptions,
  ExpandExtensionTableState,
} from '@/components/Table/TanStackTable/lib/features/enpandExtension/types'

// Here is all of the actual javascript code for our new feature
export const ExpandExtensionFeature: TableFeature<any> = {

  getInitialState: (state): ExpandExtensionTableState => {
    return {
      tableRowsMaxDepth: 0,
      ...state,
    }
  },

  getDefaultOptions: <TData extends RowData>(
    table: Table<TData>,
  ): ExpandExtensionOptions => {
    return {
      onTableRowsMaxDepthChange: makeStateUpdater('tableRowsMaxDepth', table),
    } as ExpandExtensionOptions
  },

  // define the new feature's table instance methods
  createTable: <TData extends RowData>(
    table: Table<TData>
  ): void => {
    table._getMaxDepth = <TRData = TData>(rows: Row<TRData>[]): number => {
      return rows.reduce(
        (count, row) =>
          Math.max(count, row.subRows?.length ? table._getMaxDepth(row.subRows) + 1 : 0),
        0
      );
    }
    table.getMaxExpandDepth = () => {
      const {flatRows} = table.getRowModel()
      return table._getMaxDepth(flatRows)
    }
    table.updateMaxExpandDepth = () => {
      return table.setTableRowsMaxDepth(table.getMaxExpandDepth())
    }
    table.setTableRowsMaxDepth = updater => {
      const safeUpdater: Updater<number> = old => functionalUpdate(updater, old)
      return table.options.onTableRowsMaxDepthChange?.(safeUpdater)
    }

    table.setExpandedAllRowsByDepth = (depthToExpand) => {
      const { rows } = table.getRowModel()
      const tableRowsMaxDepth = table.getState().tableRowsMaxDepth

      if (depthToExpand >= tableRowsMaxDepth) {
        return table.toggleAllRowsExpanded()
      }

      const expandRowByDepth = (row: Row<TData>) => {
        const rowCanExpand = row.getCanExpand()
        if (!rowCanExpand) return

        row.toggleExpanded(row.depth < depthToExpand)
        if (row.subRows) {
          row.subRows.forEach(expandRowByDepth)
        }
      }

      rows.forEach(expandRowByDepth)
    }
  }
}