import { BadgePropStatus } from '@consta/uikit/Badge'
import { SupplyStatusEnum } from 'src/interfaces'


export const SUPPLY_STATUSES: Record<SupplyStatusEnum | string, string> = {
  [SupplyStatusEnum.entered]: 'Новый',
  [SupplyStatusEnum.arrived]: 'Прибыл',
  [SupplyStatusEnum.processing]: 'В работе',
  [SupplyStatusEnum.avaitingPlacing]: 'Ждет размещения',
  [SupplyStatusEnum.placed]: 'Размещен',
  [SupplyStatusEnum.done]: 'Выполнен',
  [SupplyStatusEnum.error]: 'Ошибка',
}

export const SUPPLY_STATUSES_STYLES: Record<SupplyStatusEnum | string, BadgePropStatus> = {
  [SupplyStatusEnum.entered]: 'warning',
  [SupplyStatusEnum.arrived]: 'success',
  [SupplyStatusEnum.avaitingPlacing]: 'normal',
  [SupplyStatusEnum.processing]: 'normal',
  [SupplyStatusEnum.placed]: 'success',
  [SupplyStatusEnum.done]: 'error',
  [SupplyStatusEnum.error]: 'error',
}
