import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { IRouteParams } from '@pages/DirectoriesPage/types'
import SectionTitle from '@pages/DirectoriesPage/components/SectionTitle'
import { Flex } from '@/newSrc/shared/ui/Flex'
import { Button } from '@shared/ui/btns/Button'
import { IconArrowUndone } from '@consta/icons/IconArrowUndone'

interface PageLayoutProps {
  title: string,
  listPathBack?: string,
  listPage: React.ReactNode
  formPage?: React.ReactNode
}

export const PageLayout = (props: PageLayoutProps) => {
  const {
    title,
    listPage,
    formPage,
    listPathBack,
  } = props
  const { type = 'list' } = useParams<IRouteParams>()

  const history = useHistory()

  const handleClickGoBack = () => {
    if (listPathBack) {
      history.push(listPathBack)
      return
    }
    history.goBack();
  }

  const isList = type === 'list'
  return (
    <Flex
      gap={'2xl'}
      direction={'column'}
    >
      <Flex
        gap={'s'}
        direction={'column'}
        justifyContent={'start'}
        align={'start'}
      >
        {
          !isList && formPage ? (
            <Button
              label={'Вернуться'}
              view={'clear'}
              iconLeft={IconArrowUndone}
              size={'s'}
              onClick={handleClickGoBack}
            />
          ) : null
        }
        <SectionTitle title={title} />
      </Flex>

      { isList ? listPage : formPage }
    </Flex>
  )
}
