import styles from './tsd-header.module.scss'
import { User } from '@entities/User'
import { Button } from '@shared/ui/btns/Button'
import { IconClose } from '@consta/icons/IconClose'
import { IconExit } from '@consta/icons/IconExit'
import { IconPause } from '@consta/icons/IconPause'
import React, { memo, useState } from 'react'
import cx from 'classnames'
import { AdvanceModeButton } from '@features/advanceMode'
import ConfirmExitModal from '../modal/ConfirmExitModal'

type btnTypes = 'close' | 'exit' | 'break'
interface ITSDHeaderProps {
  avatarName?: string
  btnOnClick?: () => void
  btnType?: btnTypes
  avatarInfo?: string
  className?: string
  buttonClassName?: string
  customHeader?: React.ReactNode
  customBtn?: React.ReactNode
  withClickConfirm?: boolean
  showLock?: boolean
}

const TSDHeader = memo((props: ITSDHeaderProps) => {
  const {
    avatarName = '',
    avatarInfo = 'Мелкая ячейка',
    btnOnClick = () => undefined,
    btnType = 'close',
    className,
    buttonClassName,
    customHeader,
    customBtn,
    withClickConfirm = false,
    showLock = true
  } = props

  const [confirmVisible, setConfirmVisible] = useState(false);

  const btnSettings = {
    close: { label: 'Закрыть', iconLeft: IconClose },
    exit: { label: 'Выйти', iconLeft: IconExit },
    break: { label: 'Прервать', iconLeft: IconPause },
  }
  return (
    <>
      <div className={cx(styles.header, className)}>
        {customHeader ? (
          <>{customHeader}</>
        ) : (
          <>
            <div className={styles.userBlock}>
              <User
                size="m"
                view="clear"
                className={styles.userComp}
                name={avatarName}
                info={avatarInfo}
              />
              {
                showLock &&
                <AdvanceModeButton />
              }

            </div>
            {customBtn ? (
              <>{customBtn}</>
            ) : (
              <Button
                {...btnSettings[btnType]}
                size="s"
                view="ghost"
                className={cx(styles.button, buttonClassName)}
                onClick={() => {
                  if (withClickConfirm) {
                    setConfirmVisible(true)
                    return
                  }
                  btnOnClick()
                }}
              />
            )}
          </>
        )}
      </div>

      {
        confirmVisible ? (
          <ConfirmExitModal
            isOpen={true}
            onClose={() => {
              setConfirmVisible(false)
            }}
            onSubmit={() => {
              btnOnClick()
              setConfirmVisible(false)
            }}
          />
        ) : null
      }
    </>
  )
})

export default TSDHeader
