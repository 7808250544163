import { TableColumn } from '@consta/uikit/Table'
import { Button } from '@shared/ui/btns/Button'
import React from 'react'
import { IUserRender } from '@pages/DirectoriesPage/pages/UsersSection/types'
import { ActivationBadge } from '@shared/ui/Badges/ActivationBadge'

export const getUsersTableColumns = (
  users: IUserRender[],
  handleQrCode: (userId: string) => void,
  isLoading: boolean
): TableColumn<(typeof users)[number]>[] => [
    {
      title: 'ФИО',
      accessor: 'fioEl',
    },
    {
      title: 'Логин',
      accessor: 'loginEl',
    },
    {
      title: 'Пароль',
      accessor: 'passwordEl',
    },
    {
      title: 'Компания',
      accessor: 'companyEl',
    },
    {
      title: 'Роль',
      accessor: 'rolesEl',
      sortable: true,
      width: 300
    },
    {
      title: 'Должность',
      accessor: 'positionEl',
      sortable: true,
    },
    {
      title: 'Активность',
      /* @ts-ignore */
      accessor: 'enabled__action',
      sortable: false,
      renderCell: (row: any) => {
        return (
          <ActivationBadge size={row.getTableDensity()} active={row.original.enabled} />
        )
      }
    },
    {
      title: 'Печать QR кода',
      /* @ts-ignore */
      accessor: 'id__action',
      sortable: false,
      renderCell: (row: any) => {
        return (
          <Button
            onlyIcon
            size={'xs'}
            iconSize={'s'}
            view={'ghost'}
            label={'Печать'}
            loading={isLoading}
            onClick={() => handleQrCode(row.original.id)}
          />
        )
      }
    },
    {
      title: 'Редактировать',
      /* @ts-ignore */
      accessor: 'buttonEl__action',
      sortable: false,
    },
  ]