import { IBatch } from '@/interfaces'
import dayjs from 'dayjs'
import { DATE_REQUEST_FORMAT } from '@shared/const/date'

/** Получение create_date по партии и срока годности */
export const getCreationDateByBatch = (batch?: IBatch, expiration_months?: number) => {
  if (!batch) return
  const expDate = dayjs(batch.exp_date, DATE_REQUEST_FORMAT)
  if (!expDate.isValid()) return
  return expDate.subtract(expiration_months || 999, 'month').format(DATE_REQUEST_FORMAT)
}

/** Получение партии по id */
export const getBatchById = (batches: IBatch[] = [], batchId?: string) => batches.find(batch => batch.id === batchId)
