import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ComboboxWrapper from '../ComboboxWrapper/ComboboxWrapper'
import { ComboboxItemDefault } from '@consta/uikit/Combobox'

interface IConvertData {
  key: string
  labelValue: string
  idValue: string
}

type ComboboxWrapperProps = React.ComponentProps< typeof ComboboxWrapper>
type ComboboxWrapperSpecialProps = Pick<ComboboxWrapperProps, 'onChange' | 'placeholder' | 'size' | 'exclude' | 'excludeValue' | 'label' | 'link' | 'isRequired' | 'isMultiple' | 'readOnly'>
export interface IComboboxWrapperWithApi extends ComboboxWrapperSpecialProps {
  withApiSearch?: boolean

  value?: ComboboxItemDefault | /* ComboboxItemDefault[] */ any | null
  valueId?: string // для ID значения

  className?: string
  wrapperClassName?: string
  useGetHookSearch: any
  // useGetHookSearch -передаем хук запроса,из которого получаем список для селекта
  convertHookParams?: (params: { search: string }) => any
  // convertHookParams-функция в которую передаем search и другие параметры
  //  и конвертируем в объект который нужен будет для фильтров к запросу
  auto?: boolean
  //   Есть два способа как конвертировать полученую Data  в ComboboxItemDefault[]

  // 1 Способ
  // Если включен режим auto,то мы передаем в параметр avtoConvertData,
  // объект { key: string, labelValue: string, idValue: string} ,который в UseEffect,
  // преобразует полученую Data в ComboboxItemDefault[]
  //   Пример:
  // auto
  // autoConvertData={{
  // 	key: 'orders',
  // 	labelValue: 'id',
  // 	idValue: 'id',
  //   }}
  autoConvertData?: IConvertData
  //   Объект {key: 'orders',labelValue: 'id',idValue: 'id',} который передаем пропсом для конвертация
  // полученой Data

  //   2 Способ
  // Если выключен режим auto,мы в верхнем компоненте передаем пропс convertHookData
  // функцию,в котрой конвертируем полученную Data в ComboboxItemDefault[]
  convertHookData?: (data: any) => any
  //   Функция котороя конвертирует полученную Data в ComboboxItemDefault[]
  //   Пример:
  //   convertHookData={(data) => {
  //     return data.orders.map((el) => ({ label: el.id, id: el.id }))
  //   }}
}

const ComboboxWrapperWithApi = (props: IComboboxWrapperWithApi) => {
  const {
    exclude,
    excludeValue,
    withApiSearch = true,
    link,
    label = '',
    placeholder = '',
    value = null,
    valueId,
    className,
    useGetHookSearch,
    convertHookParams = (params) => ({ params: { search: params.search }, size: 50 }),
    autoConvertData,
    convertHookData,
    isMultiple = false,
    auto = false,
    onChange = () => false,
    readOnly = false,
    isRequired = false,
    size = 's',
    wrapperClassName
  } = props
  const [search, setSearch] = useState<string>('')
  const { data, isFetching } = useGetHookSearch(convertHookParams({ search }))
  const [list, setList] = useState<ComboboxItemDefault[]>([])

  useEffect(() => {
    if (!data) return
    if (!auto) {
      setList(convertHookData(data))
    } else {
      const { key, labelValue, idValue } = autoConvertData
      setList(
        data[key].map((el) => ({ label: el[labelValue], id: el[idValue] })),
      )
    }
  }, [data, search])

  let correctValue = useMemo(() => {
    if (valueId)
      return list.find(item => item.id === valueId) || null
    if (Array.isArray(value)) {
      return value?.length ? value : null
    }
    return value
  }, [value, valueId, list]);

  const handleSearch = useCallback((value?: string) => {
    setSearch(value || '')
  }, [setSearch])

  const handleChange = useCallback((value: any, exclude?: boolean, eventObj?: { e: React.SyntheticEvent },) => {
    onChange(value, exclude, eventObj)
  }, [onChange])

  const onDropdownOpen = useCallback((open: boolean) => {
    if (!open) {
      setSearch('')
    }
  }, [onChange])

  return (
    <ComboboxWrapper
      link={link}
      exclude={exclude}
      excludeValue={excludeValue}
      disabled={readOnly}
      withApiSearch={withApiSearch}
      searchValue={search}
      handleApiSearch={handleSearch}
      searchFunction={withApiSearch ? () => true : undefined}
      className={className}
      wrapperClassName={wrapperClassName}
      items={list}
      value={correctValue}
      label={label}
      isMultiple={isMultiple}
      placeholder={placeholder}
      isRequired={isRequired}
      size={size}
      loading={isFetching}
      onChange={handleChange}
      onDropdownOpen={onDropdownOpen}
    />
  )
}
export default ComboboxWrapperWithApi
