import { BadgePropStatus } from '@consta/uikit/Badge'
import { PhysicalSetOrderStatusEnum } from '@/interfaces'


export const PHYSICAL_SET_STATUSES: Record<PhysicalSetOrderStatusEnum | string, string> = {
  [PhysicalSetOrderStatusEnum.NEW]: "Новый",
  [PhysicalSetOrderStatusEnum.PICKING]: "Подбор",
  [PhysicalSetOrderStatusEnum.PICKED]: "Подобран",
  [PhysicalSetOrderStatusEnum.PACKING]: "На упаковке",
  [PhysicalSetOrderStatusEnum.PACKED]: "Упакован",
  [PhysicalSetOrderStatusEnum.DONE]: "Выполнен",
  [PhysicalSetOrderStatusEnum.CANCELED]: "Отменен",
}

export const PHYSICAL_SET_STATUSES_STYLES: Record<PhysicalSetOrderStatusEnum | string, BadgePropStatus> = {
  [PhysicalSetOrderStatusEnum.NEW]: 'system',
  [PhysicalSetOrderStatusEnum.PICKING]: 'normal',
  [PhysicalSetOrderStatusEnum.PICKED]: 'success',
  [PhysicalSetOrderStatusEnum.PACKING]: 'normal',
  [PhysicalSetOrderStatusEnum.PACKED]: 'success',
  [PhysicalSetOrderStatusEnum.DONE]: 'warning',
  [PhysicalSetOrderStatusEnum.CANCELED]: 'error',
}
