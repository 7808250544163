import React, { FC, MouseEventHandler } from 'react'
import styles from './LinkCell.module.scss'
import { Link } from 'react-router-dom'
import {Text} from '@shared/ui/Text'

interface LinkCellProps {
  to: string
  title: string
  target?: string
  size?: React.ComponentProps<typeof Text>['size']
  onClick?: MouseEventHandler | undefined;
}
export const LinkCell: FC<LinkCellProps> = (props) => {
  const { size, to, title, target, onClick } = props
  return (
    <Link className={styles.link} to={to} onClick={onClick} target={target}>
      <Text size={size} view={'link'} children={title}/>
    </Link>
  )
}
