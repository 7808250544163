import React from 'react';
import cactusLogo from '@shared/assets/icons/LogoIcon/IconCactusLogo.svg'
import { Link } from 'react-router-dom'
import { LogoIcon } from '@shared/assets/icons/LogoIcon/LogoIcon'
import { ThemesEnum } from '@shared/providers/ThemeProvider/config/themes'

interface LogoLinkProps {
	to?: string
	className?: string
  theme?: ThemesEnum
}

export const LogoLink = (props: LogoLinkProps) => {
  if (!props.to) {
    return <img src={cactusLogo} alt="Кактус" />
  }
	return (
    <Link to={props.to} className={props.className}>
      <LogoIcon theme={props.theme} />
    </Link>
	);
};
