import React from 'react'
import styles from './TSDTableNomenclatureCell.module.scss'

import { Text } from '@shared/ui/Text'
import cx from 'classnames'
import { IBarcode } from 'src/interfaces'
import days from 'dayjs'
import { DATE_VIEW } from '@shared/const/date'
import { useCollapseText } from '@shared/hooks/useCollapseText'

export interface TSDTableNomenclatureCellProps {
  merchantName?: string
  name: string
  article?: string
  barcodes?: IBarcode[]
  withoutCollapseName?: boolean
  batchExpDate?: string
  className?: string
}

export const TSDTableNomenclatureCell = (props: TSDTableNomenclatureCellProps) => {
  const {
    withoutCollapseName = false,
    name,
    merchantName,
    article,
    className,
    barcodes = [],
    batchExpDate
  } = props

  const {
    text: itemName,
    toggleText,
  } = useCollapseText({
    text: name,
    withoutCollapse: withoutCollapseName,
  })

  const batchExpDateStr = batchExpDate ? days(batchExpDate).format(DATE_VIEW) : null
  return (
    <div
      className={cx(styles.name, className)}
      onClick={toggleText}
    >
      {merchantName ? <p>{merchantName}</p> : null}
      <Text as={'p'} size={'s'}>{itemName}</Text>
      {article ? <Text as={'p'} size={'s'}>{article}</Text> : null}
      {
        batchExpDateStr ? (
          <Text as={'span'} view={'secondary'} size={'s'} className={styles.batch}>Партия: {batchExpDateStr}</Text>
        ) : null
      }
      {
        barcodes.length ? (
          <div className={styles.barcodes}>
            {barcodes.map((barcode, i) => (
              <Text as={'b'} weight={'bold'} view={'secondary'} size={'s'} key={barcode.barcode}>
                {barcode.barcode}
                {barcodes.length !== 1 || barcodes.length-1 < i ? ',' : ''}
              </Text>
            ))}
          </div>
        ) : null
      }
    </div>
  )
}
