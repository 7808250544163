import React, { useEffect, useState } from 'react'
import { Button, InputWrapper, Modal } from '@/components'
import styles from './WorkspacesModal.module.scss'
import { IWorkspace, ModalProps } from '@/interfaces'
import { useCreateJobs } from '@/hooks/warehouse/jobs'
import { IReqiredField } from './types'
import { ValidateErrors } from '@pages/DirectoriesPage/types'

interface IProps extends ModalProps {
  refetch: () => void
  item: IWorkspace | null
}

const WorkspacesModal = (props: IProps) => {
  const { onClose, isOpen, refetch = () => {}, item } = props
  const [requiredValue, setRequiredValue] = useState<IReqiredField>({
    barcode: '',
  })
  const [fieldValues, setFieldValues] = useState({
    title: '',
    print1: { name: '', type: 'A4' },
    print2: { name: '', type: 'STICKER_6x6' },
    print3: { name: '', type: 'STICKER_10x15' },
  })
  const [fieldErrors, setFieldErrors] = useState({
    barcode: '',
  })
  const { mutateAsync: createJobs } = useCreateJobs()

  useEffect(() => {
    if (!item) return
    setRequiredValue({ barcode: item.barcode })
    const print1 = item.printer_settings.find((el) => el.type === 'A4')
    const print2 = item.printer_settings.find((el) => el.type === 'STICKER_6x6')
    const print3 = item.printer_settings.find(
      (el) => el.type === 'STICKER_10x15',
    )

    setFieldValues({
      title: item.title,
      print1: print1 || fieldValues.print1,
      print2: print2 || fieldValues.print2,
      print3: print3 || fieldValues.print3,
    })
  }, [item])

  const canSubmit = (): boolean => {
    const emptyFieldKey = Object.keys(requiredValue).find((key) => {
      const value = requiredValue[key as keyof typeof requiredValue]

      return !value
    })

    if (emptyFieldKey) {
      setFieldErrors((prevState) => ({
        ...prevState,
        [emptyFieldKey]: ValidateErrors.isEmpty,
      }))
    }

    return !emptyFieldKey
  }

  const handleSubmit = () => {
    const isValid = canSubmit()

    if (!isValid) {
      return
    }
    const data = [
      {
        barcode: requiredValue.barcode,
        printer_settings: [
          {
            ...fieldValues.print1,
            name: fieldValues.print1.name
              ? fieldValues.print1.name
              : 'PRINTER_A4',
          },
          {
            ...fieldValues.print2,
            name: fieldValues.print2.name
              ? fieldValues.print2.name
              : 'STICKER_6x6',
          },
          {
            ...fieldValues.print3,
            name: fieldValues.print3.name
              ? fieldValues.print3.name
              : 'STICKER_10x15',
          },
        ],
        title: fieldValues.title ? fieldValues.title : requiredValue.barcode,
      },
    ]

    createJobs({ data })
      .then(async () => {
        refetch()
      })
      .catch(() => {})
    onClose()
  }
  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={onClose}
      className={styles.modal}
      size="m"
      headerTitle={`${item ? 'Редактирование' : 'Создание'} рабочего места`}
    >
      <div className={styles.formWrapper}>
        {!item ? (
          <InputWrapper
            className={styles.rowItem}
            label={'ШК'}
            value={requiredValue.barcode}
            error={fieldErrors.barcode}
            isRequired
            handleChange={(value) =>
              setRequiredValue({ barcode: value?.toString() || '' })
            }
          />
        ) : null}
        <InputWrapper
          className={styles.rowItem}
          label={'Наименование'}
          value={fieldValues.title}
          handleChange={(value) =>
            setFieldValues((prev) => ({
              ...prev,
              title: value?.toString() || '',
            }))
          }
        />

        <InputWrapper
          className={styles.rowItem}
          label={`Принтер ${fieldValues.print1.type}`}
          value={fieldValues.print1.name}
          handleChange={(value) =>
            setFieldValues((prev) => ({
              ...prev,
              print1: {
                name: value?.toString() || '',
                type: fieldValues.print1.type,
              },
            }))
          }
        />
        <InputWrapper
          className={styles.rowItem}
          label={`Принтер ${fieldValues.print2.type}`}
          value={fieldValues.print2.name}
          handleChange={(value) =>
            setFieldValues((prev) => ({
              ...prev,
              print2: {
                name: value?.toString() || '',
                type: fieldValues.print2.type,
              },
            }))
          }
        />
        <InputWrapper
          className={styles.rowItem}
          label={`Принтер ${fieldValues.print3.type}`}
          value={fieldValues.print3.name}
          handleChange={(value) =>
            setFieldValues((prev) => ({
              ...prev,
              print3: {
                name: value?.toString() || '',
                type: fieldValues.print3.type,
              },
            }))
          }
        />
      </div>
      <div className={styles.footer}>
        <Button label="Отмена" view={'ghost'} onClick={onClose} />
        <Button label="Сохранить" view={'primary'} onClick={handleSubmit} />
      </div>
    </Modal>
  )
}
export default WorkspacesModal
