import React from 'react'

import { AdvanceModeModal } from '@features/advanceMode'
import { Informer } from '@/components'
import { useAppContext } from '@shared/providers/AppContextProvider'
import { FCC } from '@shared/helpers'

/*
 все глобальные компоненты будут находиться тут, так как вне AppContext их можно привязать к теме
 Возможно надо придумать как-то более хитро
*/

export const AppGlobalUi: FCC = (props: React.PropsWithChildren<{}>) => {
  const { children } = props

  const {
    informer,

    showAdvanceModeModal,
    setShowAdvanceModeModal,
    advanceModeDeferredAction,
    setAdvanceModeDeferredAction,
    setAdvanceMode
  } = useAppContext()


  return (
    <>
      {children}

      {showAdvanceModeModal ? (
        <AdvanceModeModal
          isOpen={true}
          deferredAction={advanceModeDeferredAction}
          setAdvanceMode={setAdvanceMode}
          onClose={() => {
            setAdvanceModeDeferredAction(null)
            setShowAdvanceModeModal(false)
          }}
        />
      ) : null}
      {
        informer ?
          <Informer {...informer} />
        : null
      }
    </>
  )
}
