import React from 'react'
import { ComboboxWrapperWithApi } from '@/components'
import { IDeliveryItem } from '@/interfaces'
import { CommonFieldProps } from '@shared/types/fields'
import useGetDeliveriesList from '../../../../../hooks/enums/useGetDeliveries'

interface IProps extends CommonFieldProps  {
  convertHookData?: (data: { values: IDeliveryItem[] }) => any
}

const ComboboxDeliveryCompanyProduct = (props: IProps) => {
  const {
    label = 'Служба доставки',
    placeholder = 'Служба доставки',
    convertHookData = (data) => {
      return data.values.map((el) => ({
        label: el.title,
        id: el.value,
        dto: el,
      }))
    },
    convertHookParams = (params) => {
      return () => {}
    },
    ...restProps
  } = props

  return (
    <ComboboxWrapperWithApi
      withApiSearch={false}
      label={label}
      placeholder={placeholder}
      useGetHookSearch={useGetDeliveriesList}
      convertHookData={convertHookData}
      convertHookParams={convertHookParams}
      {...restProps}
    />
  )
}
export default ComboboxDeliveryCompanyProduct
