import React, { useEffect, useState } from 'react'
import styles from './CategoryModal.module.scss'
import { InputWrapper, Modal } from '@/components'
import { ICategory, ModalProps } from '@/interfaces'
import { Button } from '@shared/ui/btns/Button'
import { IFields } from './types'
import { useUpdateCategory } from '@/hooks/warehouse/categories'
import { ValidateErrors } from '@pages/DirectoriesPage/types'

interface IProps extends ModalProps {
  refetch: () => void
  code?: string
  categories: ICategory[]
}

const CategoryModal = (props: IProps) => {
  const {
    isOpen,
    onClose,
    refetch = () => {},
    code = '',
    categories = [],
  } = props
  const [fieldValues, setFieldValues] = useState<IFields>({
    code: '',
    title: '',
  })
  const [fieldErrors, setFieldErrors] = useState({
    code: '',
    title: '',
  })
  const { mutateAsync: updateCategory, isLoading: isUpdateCategory } =
    useUpdateCategory()

  useEffect(() => {
    if (!code) return
    const item = categories.find((el) => el.code === code)

    item && setFieldValues(item)
  }, [categories, code])

  const canSubmit = (): boolean => {
    const emptyFieldKey = Object.keys(fieldValues).find((key) => {
      const value = fieldValues[key as keyof typeof fieldValues]

      return !value
    })

    if (emptyFieldKey) {
      setFieldErrors((prevState) => ({
        ...prevState,
        [emptyFieldKey]: ValidateErrors.isEmpty,
      }))
    }

    return !emptyFieldKey
  }

  const handleSubmit = () => {
    const isValid = canSubmit()

    if (!isValid) {
      return
    }

    const data = {
      code: fieldValues.code,
      title: fieldValues.title,
    }

    updateCategory({ code, data })
      .then(async () => {
        refetch()
      })
      .catch(() => {})
    onClose()
  }
  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={onClose}
      className={styles.modal}
      size="m"
      headerTitle={`${code ? 'Редактирование' : 'Создание'} категории`}
    >
      <InputWrapper
        label={'Код'}
        value={fieldValues.code}
        error={fieldErrors.code}
        isNumber
        handleChange={(value) => {
          setFieldErrors({
            code: '',
            title: '',
          })
          setFieldValues((prev) => ({ ...prev, code: value || '' }))
        }}
      />
      <InputWrapper
        label={'Наименование'}
        value={fieldValues.title}
        error={fieldErrors.title}
        handleChange={(value) => {
          setFieldErrors({
            code: '',
            title: '',
          })
          setFieldValues((prev) => ({ ...prev, title: value || '' }))
        }}
      />
      <div className={styles.footer}>
        <Button label="Отмена" view={'ghost'} onClick={onClose} />
        <Button label="Сохранить" view={'primary'} onClick={handleSubmit} />
      </div>
    </Modal>
  )
}
export default CategoryModal
