import React, { useEffect, useState } from 'react'

import styles from './weigth-dimensions-modal.module.scss'

import { Button } from '@shared/ui/btns/Button'

import { ConfirmActionModal, DimensionsInput, EnterHandler, InputWrapper, Modal } from 'src/components'

import { useUpdateWeightAndDimensions } from 'src/hooks'
import { useRefsSwitcher, useToggle } from '@shared/hooks'

import { IDimensions, IItemWeightAndDimensions, ModalProps, updateWeightAndDimensionsRes } from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { Loader } from '@consta/uikit/Loader'
import { convertDimensionsNum, convertWeight, isDimensionsCorrect } from '@shared/helpers'
import isMobile from 'ismobilejs'
import { Flex } from '@shared/ui/Flex'
import { Text } from '@shared/ui/Text'

interface IWeightDimensionsItem {
  id: string
  weight?: number
  weight_plan?: number
  dimensions?: IDimensions
  require_weight?: boolean
  require_dimensions?: boolean
}

interface IWeightDimensionsModal extends ModalProps {
  item: IWeightDimensionsItem | null
  hardClose?: boolean
  onSubmit: (weight?: number, manualRequireWeight?: boolean) => void
  top?: React.ReactNode
  title?: string
  subtitle?: string
  img?: string
  imgClass?: string
  withClose?: boolean
  checkPlaceWeight?: (weight: number) => boolean
  addWeightToCache?: (itemId: string, weight: number) => void
  needUpdateWeightAndDimensions?: boolean
  alwaysUpdate?: boolean
  withZero?: boolean
  withEnterHandler?: boolean
}

const WeightDimensionsModal = (props: IWeightDimensionsModal) => {
  const {
    item,
    isOpen,
    hardClose,
    onClose,
    onSubmit,
    onMount,
    top,
    title,
    subtitle,
    img,
    imgClass,
    withClose,
    checkPlaceWeight,
    addWeightToCache,
    needUpdateWeightAndDimensions = true,
    alwaysUpdate = false,
    withZero = false,
    withEnterHandler = true,
  } = props

  const {
    value: weightWarningModalVisible,
    turnOn: showWeightWarningModal,
    turnOff: closeWeightWarningModal,
  } = useToggle()


  const {
    refsObj,
    next, prev,
    isDone,
    setFocusIndex,
  } = useRefsSwitcher({ refsKeys: ['weight', 'width', 'height', 'depth'] })

  useEffect(() => {
    onMount?.()
  }, [])

  const updateWeightAndDimensions = useUpdateWeightAndDimensions({
    itemId: item?.id || '',
  })

  const loading = updateWeightAndDimensions.isLoading

  const [weight, setWeight] = useState<string | null>(null)

  const [dimensions, setDimensions] = useState<IDimensions>({
    width: null,
    height: null,
    depth: null,
  })
  const [error, setError] = useState('')

  useEffect(() => {
    if (!withZero) {
      return
    }
    const initialWeight = item?.weight === undefined ? '' : String(item?.weight)
    setWeight(initialWeight)

    if (item?.dimensions) {
      setDimensions({
        width: String(item.dimensions.width) ?? '',
        height: String(item.dimensions.height) ?? '',
        depth: String(item.dimensions.depth) ?? '',
      })
    }
  }, [])

  useEffect(() => {
    if (withZero) {
      return
    }

    if (item?.weight) {
      setWeight(String(item.weight))
    }
    if (item?.dimensions) {
      setDimensions(item.dimensions)
    }
  }, [])

  const handleSubmit = (confirm?: boolean) => {
    if (!withZero && !checkDisabled()) {
      next()
      return false
    }

    if (
      withZero &&
      (!weight || !dimensions.depth || !dimensions.width || !dimensions.height)
    ) {
      setError('Заполните все поля!')
      return
    }

    const correctWeightG = convertWeight('kg', 'g', Number(weight)) ?? 0
    /**
     * Если есть плановый вес, то просим подтверждение того, что это не случайная ошибка
     */
    if (!confirm && item.weight_plan && (correctWeightG >= (item.weight_plan * 1.5))) {
      showWeightWarningModal()
      return
    }
    if (
      !withZero &&
      checkPlaceWeight &&
      correctWeightG &&
      !checkPlaceWeight(correctWeightG)
    ) {
      return false
    }

    const newItem: IItemWeightAndDimensions = {}
    if (!withZero && correctWeightG) {
      newItem.weight = correctWeightG
    }
    if ((withZero && correctWeightG) || (withZero && correctWeightG === 0)) {
      newItem.weight = correctWeightG
    }
    if (dimensions.width && dimensions.height && dimensions.depth) {
      newItem.dimensions = convertDimensionsNum('sm', 'mm', {
        width: +dimensions.width,
        height: +dimensions.height,
        depth: +dimensions.depth,
      })
    }
    if (needUpdateWeightAndDimensions) {
      updateWeightAndDimensions
        .mutateAsync({ item: newItem })
        .then(async (result: updateWeightAndDimensionsRes) => {
          const { success } = result
          if (success) {
            onSubmit(correctWeightG)
            if (addWeightToCache && item && correctWeightG) {
              addWeightToCache(item.id, correctWeightG)
            }
            if (hardClose) {
              onClose()
            }
          }
        })
    } else {
      onSubmit(correctWeightG, true)
      if (addWeightToCache && item && correctWeightG) {
        addWeightToCache(item.id, correctWeightG)
      }
    }
  }

  const checkDisabled = () => {
    if ((item?.require_weight && item?.require_dimensions) || alwaysUpdate) {
      return Number(weight) && isDimensionsCorrect(dimensions)
    }
    if (item?.require_weight) {
      return Number(weight)
    }
    if (item?.require_dimensions) {
      return isDimensionsCorrect(dimensions)
    }
    if (!item?.require_weight && !item?.weight) {
      return Number(weight)
    }
    return false
  }

  const needWeight = item?.require_weight || !item?.weight || alwaysUpdate
  const needDimensions = item?.require_dimensions || alwaysUpdate
  return (
    <>
      <EnterHandler
        onEnter={withEnterHandler && !weightWarningModalVisible ? handleSubmit : undefined}
      >
        <Modal
          isOpen={isOpen}
          hasOverlay
          onOverlayClick={(): boolean => false}
          className={styles.itemCount}
          withClose={withClose}
          onClose={onClose}
          title={title}
          subtitle={subtitle}
          img={img}
          imgClass={imgClass}
          size='s'
        >
          <Flex direction={'column'} gap={'s'}>
            {top}

            {needWeight ? (
              <InputWrapper
                label={'Введите вес товара'}
                value={weight}
                id={'weight'}
                handleChange={(value: TextFieldPropValue) => {
                  setWeight(value)
                }}
                className={styles.itemInput}
                placeholder={'Вес товара'}
                size='s'
                rightSide='кг'
                isWeight
                autoFocus
                inputRef={refsObj.weight.ref}
                onFocus={() => setFocusIndex('weight')}
              />
            ) : null}

            {needDimensions ? (
              <DimensionsInput
                size={'s'}
                value={dimensions}
                onChange={setDimensions}
                floatNumbersInputs
                direction={isMobile().any ? 'column' : 'row'}
                autoFocus={!item?.require_weight && !needWeight}
                widthRef={refsObj.width.ref}
                heightRef={refsObj.height.ref}
                depthRef={refsObj.depth.ref}
                onFocus={setFocusIndex}
              />
            ) : null}

            {error ? <Text view={'alert'} children={error} /> : null}

            {loading ? <Loader size={'s'} className={styles.loader} /> : null}

            <Flex justifyContent={'between'}>
              <Button
                label='Подтвердить'
                className={styles.btn}
                onClick={() => handleSubmit()}
                disabled={!withZero ? !checkDisabled() : false}
              />
              {withClose ? (
                <Button
                  label='Закрыть'
                  view='ghost'
                  className={styles.btn}
                  onClick={onClose}
                />
              ) : null}
            </Flex>
          </Flex>
        </Modal>
      </EnterHandler>
      {
        weightWarningModalVisible ? (
          <ConfirmActionModal
            isOpen={true}
            withPreview={false}
            confirmWithScan={false}
            type='warning'
            title={`Фактический вес сильно превышает плановый`}
            size={'min'}
            onClose={closeWeightWarningModal}
            onSubmit={() => {
              handleSubmit(true)
              closeWeightWarningModal()
            }}
          />
        ) : null
      }
    </>
  )
}

export default WeightDimensionsModal
