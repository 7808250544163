import React, { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useGetInstruction } from '@/hooks/warehouse/instructions'
import { IInstruction } from '@/interfaces/instructions'
import { IRouteParams } from '@pages/DirectoriesPage/types'
import { InputWrapper } from '@/components'
import styles from './item.module.scss'
import { Loader } from '@consta/uikit/Loader'
import { DangerouslyHTML } from '@shared/ui/DangerouslyHTML'

interface ItemProps {}

const Item: FC<ItemProps> = () => {
  const { id = '' } = useParams<IRouteParams>()
  const {
    isFetching: isFetchingInstruction,
    data: instructionData,
    refetch: refetchInstruction,
  } = useGetInstruction({ instruction_id: id })

  if (!instructionData || isFetchingInstruction) {
    return (
      <div>
        <Loader /> Loading...
      </div>
    )
  }

  const instruction: IInstruction | undefined = instructionData?.instruction

  const Info = [
    {
      label: 'Наименование',
      value: instruction?.title || '-',
    },
    {
      label: 'Служба доставки',
      value: instruction?.delivery_company || '-',
    },
  ]
  return (
    <div>
      <h3>{instruction?.title}</h3>

      <div className={styles.wrapper}>
        {Info.map((item, i) => (
          <InputWrapper
            key={i}
            label={item.label}
            value={item.value}
            disabled
          />
        ))}
      </div>
      <DangerouslyHTML
        htmlStr={instruction?.value}
      />
    </div>
  )
}
export default Item
