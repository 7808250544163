import React, { FC } from 'react'
import { NomenclatureNameCell } from 'src/components'

interface TitleCellProps extends React.ComponentProps< typeof NomenclatureNameCell> {

}

const TitleCell: FC<TitleCellProps> = (props) => {
  return (
    <NomenclatureNameCell {...props}/>
  )
}

TitleCell.displayName = 'TitleCell'

export default TitleCell
