import { useQuery, UseQueryOptions } from 'react-query'

import api from '@shared/api'
import { ApiReqResult, ICategory, PaginationResult } from 'src/interfaces'
import { convertObjectToURLParams } from '@shared/helpers'

interface IParams {}

interface IResult extends ApiReqResult, PaginationResult {
  categories: ICategory[]
}
export const getCategoriesList = async (params: IParams = {}) => {
  const paramsStr = convertObjectToURLParams(params)
  const result = await api.get(`/sku-category${paramsStr}`)
  return result.data as IResult
}

// http://wms-test.kak2c.ru/warehouse-api/sku-category
const useGetCategoriesList = (
  params: IParams,
  options?: UseQueryOptions<IParams,any,IResult>,
) => {
  return useQuery<IParams,any,IResult>(
    [`getCategoriesList`, params],
    () => getCategoriesList(params),
    options,
  )
}
export default useGetCategoriesList
