
export enum orderItemsEnum {
  nomenclature = 'nomenclature',
  cell = 'cell',
  order = 'order'
}

export enum orderSubtypeEnum {
  barcodes = 'barcodes',
  ids = 'ids',
  titles = 'titles',
  orders = 'orders',

  tracking_nums = 'tracking_nums',
  external_ids = 'external_ids',
  place_barcodes = 'place_barcodes',
}