import React, { RefObject, useState } from 'react'
import cx from 'classnames'

import styles from './dropdown.module.scss'

import { IDropdownItem } from 'src/interfaces'

interface DropdownProps {
  isOpen?: boolean
  className?: string
  menuDirection?: 'left' | 'right'
  items?: IDropdownItem[]
}

const Dropdown = (props: React.PropsWithChildren<DropdownProps>) => {
  const { isOpen, className, menuDirection='right', items, children } = props

  const [toggled, setToggled] = useState<boolean>(false)

  const handleClick = (e: React.SyntheticEvent): void => {
    setToggled(!toggled)
  }

  const handleItemClick = (
    e: React.SyntheticEvent,
    action: (e: React.SyntheticEvent<Element, Event>) => void,
  ): void => {
    e.stopPropagation()
    action(e)
  }

  return (
    <div className={cx(styles.wrapper, className)} onClick={handleClick}>
      {children}

      {items && items.length && (toggled || isOpen) && (
        <ul className={cx(styles[menuDirection])}>
          {items.map((i, indx) => {
            if (i.hidden) {
              return
            }
            return (
              <li key={indx} onClick={(e) => handleItemClick(e, i.action)}>
                {i.name}
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default Dropdown
