import { PackagingPurposes } from './order'


export type PackagingSettingsType = "INCLUDE" | "EXCLUDE" | "INHERITED_INCLUDE" | "INHERITED_EXCLUDE" | string
export const PackagingSettingsDescription: Record<PackagingSettingsType, string> = {
  "INCLUDE": "Включен",
  "EXCLUDE": "Исключен",
  "INHERITED_INCLUDE": "Включен (наследуется)",
  "INHERITED_EXCLUDE": "Исключен (наследуется)",
}
export const PackagingSettingsColors: Record<PackagingSettingsType, string> = {
  "INCLUDE": 'green',
  "EXCLUDE": "red",
  "INHERITED_INCLUDE": "",
  "INHERITED_EXCLUDE": "",
}

export type PackagingSettingsFormParams = {
  purpose?: PackagingPurposes
  merchant_id?: string
  delivery_company?: string
  delivery_company_product?: string
  sku_id?: string
}

export type RequiredFieldsToSet = {
  packaging_id: string
  type: PackagingSettingsType
}

export type SetNewPackagingSettingsFormParams = PackagingSettingsFormParams & RequiredFieldsToSet & {
}

export type PackagingSettingsDto = {
  packaging_id: string
  type: PackagingSettingsType
}

export type PackagingSettingsOverrideDto = PackagingSettingsDto & {
  merchant: {title: string, id: string},
  sku: {title: string, id: string},
  purpose: {title: string, id: string},
  delivery_company: {title: string, id: string},
  delivery_company_product: {title: string, id: string},
}