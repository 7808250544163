import { ICellPlace, ICellSkuStock, ISku } from '@/interfaces'
import { TableColumn } from '@consta/uikit/Table'
import { Badge } from '@consta/uikit/Badge'
import React from 'react'
import days from 'dayjs'
import { DATE_VIEW } from '@shared/const/date'
import { getBatchById } from '@entities/Sku/lib/batch'
import { boolToText } from '@shared/helpers'

export const getBarcodeTable = (item: ISku) => {
  const rows = item?.barcodes.map((code) => ({
    id: code.barcode,
    isActive: boolToText(code.active),
    type: code.type,
    isScanned: boolToText(code.used_in_last_supply),
  })) //|| [{id: ''}] //TODO: Ломает типизацию таблицы - воспринимает так, как будто все должно быть типа id.

  const columns: TableColumn<typeof rows[number]>[] = [
    {
      title: 'ШК',
      accessor: 'id',
    },
    {
      title: 'Активный',
      accessor: 'isActive',
    },
    {
      title: 'Тип',
      accessor: 'type',
    },
    {
      title: 'Сканировалась в поставке',
      accessor: 'isScanned',
    },
  ]

  return {
    barcodeRows: rows,
    barcodeCols: columns,
  }
}

export const getPartsTable = (item: ISku) => {
  const rows = item?.parts.map((part) => ({
    id: part.sku_id,
    quantity: part.quantity,
  })) //|| [{id: ''}] //TODO: Ломает типизацию таблицы - воспринимает так, как будто все должно быть типа id.

  const columns: TableColumn<typeof rows[number]>[] = [
    {
      title: 'Номенклатура',
      accessor: 'id',
    },
    {
      title: 'Количество',
      accessor: 'quantity',
    },
  ]

  return {
    partsRows: rows,
    partsCols: columns,
  }
}

export const getUnTable = (item: ISku) => {
  const rows = item?.scan_serial_numbers.map((serial) => ({
    id: serial.title,
    type: serial.code,
    template: serial.template,
    required: boolToText(!serial.required),
  })) //|| [{id: ''}] //TODO: Ломает типизацию таблицы - воспринимает так, как будто все должно быть типа id.

  const columns: TableColumn<typeof rows[number]>[] = [
    {
      title: 'Тип',
      accessor: 'type',
    },
    {
      title: 'Маска',
      accessor: 'template',
    },
    {
      title: 'Разрешить операции без сканирования',
      accessor: 'required',
    },
  ]

  return {
    unRows: rows,
    unCols: columns,
  }
}

export const getCellsStock = ({ sku, stock, places }: { stock: ICellSkuStock[], places: ICellPlace[], sku: ISku }) => {
  const rows = places.map((place) => {
    const stocksPlace = stock?.filter((stockItem) => stockItem.place_id === place.barcode)
    const quantity = stocksPlace.reduce((acc, stockItem) => acc + stockItem.quantity, 0)
    const reserved = stocksPlace.reduce((acc, stockItem) => acc + stockItem.reserved, 0)
    const skuBatch = stocksPlace.length === 1 && stocksPlace[0].sku_batch_id
      ? getBatchById(sku?.batches, stocksPlace[0].sku_batch_id)
      : undefined
    return ({
      id: place.id,
      cell: place.title,
      condition: place.condition,
      quantity: quantity || 0,
      reserved: reserved || 0,
      expDate: skuBatch ? days(skuBatch.exp_date).format(DATE_VIEW) : stocksPlace.length > 1 ? '...' : undefined,
      rows: stocksPlace.length > 1
        ? stocksPlace.map((stockItem,i) => {
          const skuBatch = getBatchById(sku?.batches, stockItem.sku_batch_id)
          return ({
            id: `${stockItem.sku_id}${skuBatch?.num}${i}`,
            quantity: stockItem.quantity,
            reserved: stockItem.reserved,
            expDate: skuBatch?.exp_date ? days(skuBatch.exp_date).format(DATE_VIEW) : undefined,
          })
        })
        : undefined,
    })
  })

  const columns: TableColumn<typeof rows[number]>[] = [
    {
      title: 'Ячейка',
      accessor: 'cell',
    },
    {
      title: 'Годность',
      accessor: 'condition',
      renderCell: (row) => {
        if (row.condition === 'GOOD') {
          return <Badge status='success' label='Годный' />
        }
        if (row.condition === 'DEFECT') {
          return <Badge status='error' label='Брак' />
        }
      },
    },
    {
      title: 'Срок годности',
      accessor: 'expDate',
    },
    {
      title: 'В наличии',
      accessor: 'quantity',
    },
    {
      title: 'К отбору',
      accessor: 'reserved',
    },
  ]

  return {
    stockRows: rows,
    stockCols: columns,
  }
}
