import React, { useEffect, useState } from 'react'

import styles from './BillingModal.module.scss'
import { CalendarInput, ComboboxWrapper, Modal } from 'src/components'
import { ModalProps } from 'src/interfaces'
import { Button } from '@shared/ui/btns/Button'
import { ComboboxItemDefault } from '@consta/uikit/Combobox'
import { useGetMerchantList } from 'src/hooks'
import { IconCalendar } from '@consta/icons/IconCalendar'
import { ValidateErrors } from '@pages/DirectoriesPage/types'

export interface ISubmitBillingModal {
  merchant: ComboboxItemDefault[]
  month: Date | null
}
interface IProps extends ModalProps {
  isLoading: boolean
  refetch: () => void
  onSubmit: (data: ISubmitBillingModal) => void
}

const BillingModal = (props: IProps) => {
  const { isOpen, onClose, refetch = () => {}, onSubmit = () => {} } = props
  const [merchants, setMerchants] = useState<ComboboxItemDefault[]>([])
  const [merchant, setMerchant] = useState<ComboboxItemDefault[] | null>([])
  const [month, setMonth] = useState<Date | null>(null)
  const [monthError, setMonthError] = useState('')
  const { data: merchantData } = useGetMerchantList({})

  useEffect(() => {
    if (!merchantData?.merchants) return
    setMerchants(
      merchantData.merchants.map((el) => ({ label: el.title, id: el.id })),
    )
  }, [merchantData])

  const handleSubmit = () => {
    if (!month) {
      setMonthError(ValidateErrors.isEmpty)
      return
    }
    const data = {
      merchant: merchant || [],
      month: month,
    }
    onSubmit(data)
  }
  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={onClose}
      className={styles.modal}
      size="m"
      headerTitle={'Создание документа'}
    >
      <div className={styles.formWrapper}>
        <ComboboxWrapper
          className={styles.merchantRow}
          items={merchants}
          value={merchant as any}
          label={'Организация'}
          isMultiple
          placeholder="Выберите организацию/продавца"
          onChange={(value) => {
            setMerchant(value as any)
          }}
        />
        <div className={styles.inputCell}>
          <CalendarInput
            label="Месяц/год"
            id={'dateTo'}
            placeholder={''}
            handleChange={(value) => {
              setMonthError('')
              setMonth(value)
            }}
            type={'month'}
            value={month}
            size="m"
            className={styles.dateInput}
            rightSide={IconCalendar}
            backspaceReset
          />
          <div className={styles.errorMessage}>{monthError}</div>
        </div>
      </div>
      <div className={styles.footer}>
        <Button label="Отмена" view={'ghost'} onClick={onClose} />
        <Button label="Сохранить" loading={props.isLoading} view={'primary'} onClick={handleSubmit} />
      </div>
    </Modal>
  )
}

export default BillingModal
