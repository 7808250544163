import type { FC } from 'react'
import React from 'react'

import { ISelectOption } from '@/interfaces'

import { InputWrapper, Select } from '@/components'

import { getTrimmed } from '../../utils'

import { addressData, houseData, streetData } from './constants'

import styles from './address.module.scss'
import { IFormAddress } from '@pages/DirectoriesPage/types'

interface IAddressProps {
  size?: React.ComponentProps<typeof InputWrapper>['size']
  country: ISelectOption | null
  address: IFormAddress
  countriesList: ISelectOption[]
  onChangeCountry: (value: ISelectOption) => void
  onChangeAddress: (value: IFormAddress) => void
}

const Address: FC<IAddressProps> = ({
  size = 's',
  country,
  address,
  countriesList,
  onChangeCountry,
  onChangeAddress,
}) => {
  const handleChangeCountry = (option: ISelectOption | null) => {
    if (!option) return
    onChangeCountry(option)
  }

  return (
    <>
      <div className={styles.rowAddress}>
        <Select
          size={size}
          label="Страна"
          placeholder="Выберите страну"
          options={countriesList}
          value={country}
          onChange={(value) => handleChangeCountry(value)}
        />

        {addressData.map((item) => (
          <InputWrapper
            size={size}
            key={item.key}
            label={item.label}
            value={address[item.key] || ''}
            handleChange={(val) =>
              onChangeAddress({ [item.key]: getTrimmed(val) })
            }
          />
        ))}
      </div>

      <div className={styles.rowAddress}>
        {streetData.map((item) => (
          <InputWrapper
            size={size}
            className={item.className || ''}
            key={item.key}
            label={item.label}
            value={address[item.key] || ''}
            handleChange={(val) =>
              onChangeAddress({ [item.key]: getTrimmed(val) })
            }
          />
        ))}

        <div className={styles.rowHouse}>
          {houseData.map((item) => (
            <InputWrapper
              size={size}
              key={item.key}
              form={item.form}
              label={item.label}
              value={address[item.key] || ''}
              handleChange={(val) =>
                onChangeAddress({ [item.key]: getTrimmed(val) })
              }
            />
          ))}
        </div>
      </div>
    </>
  )
}

Address.displayName = 'Address'

export default Address
