import { FieldRow } from '@widgets/GeneratorFields'
import { IconSearchStroked } from '@consta/icons/IconSearchStroked'

export const generateFilterParams = (filterState: Record<string, any>) => {
  return {
    search: filterState['title'] || '',
    page: filterState['page'] || '',
    size: 100,
  }
}

export const generateFilters = (): FieldRow[] => {
  return [{
    fields: [
      {
        type: 'input',
        fieldProps: {
          id: 'title',
          label: 'Поиск',
          viewDescription: 'Поиск',
          placeholder: 'Поиск',
          leftSide: IconSearchStroked
        },
      },
    ]
  }]
}