import  type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { LevelSettingsProps } from './types'
import { IChangedSettings, LevelType } from '../../pages/ZonesSection/types'

import { ICellPlace, ISelectOption } from '@/interfaces'

import { Checkbox } from '@consta/uikit/Checkbox'
import { Button } from '@shared/ui/btns/Button'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { InputWrapper, Select } from '@/components'
import { checkSameElements } from './utils'

import { cellTypeOptions, conditionTypeOptions } from './constants'

import { IconPrinter } from '@shared/assets/icons/IconPrinter'

import styles from './level-settings.module.scss'

import { Text } from '@shared/ui/Text'

const LevelSettings: FC<LevelSettingsProps> = ({
  editType = LevelType.level,
  levelItem,
  isPrinting = false,
  onPrintStickers,
  onSubmit,
  onClose,
  levelModalItems,
}) => {
  const [barcode, setBarcode] = useState<TextFieldPropValue>('')
  const [accessLevel, setAccessLevel] = useState<TextFieldPropValue>('0')
  const [isBlocked, setBlocked] = useState<boolean>(false)
  const [condition, setCondition] = useState<ISelectOption | null>(null)
  const [cellType, setCellType] = useState<ISelectOption | null>(null)

  useEffect(() => {
    if (!levelItem) {
      return
    }

    setBarcode((levelItem as ICellPlace)?.barcode || '')
    setAccessLevel(
      isNaN(parseInt(levelItem?.access_level as string))
        ? '0'
        : String(levelItem?.access_level),
    )
    setBlocked(!!levelItem?.is_blocked)
    if (levelModalItems) {
      const conditions = levelModalItems.map((el) => String(el.condition))
      const types = levelModalItems.map((el) => el.type)
      if (checkSameElements(conditions)) {
        const currentCondition = conditionTypeOptions.find(
          (el) => el.value === conditions[0],
        )
        setCondition(currentCondition || null)
      }
      if (checkSameElements(types)) {
        const currentCellType = cellTypeOptions.find(
          (el) => el.value === types[0],
        )

        setCellType(currentCellType || null)
      }

      return
    }

    const currentCondition = conditionTypeOptions.find(
      (option) => option.value === (levelItem as ICellPlace)?.condition,
    )
    setCondition(currentCondition || null)

    const currentCellType = cellTypeOptions.find(
      (option) => option.value === (levelItem as ICellPlace)?.type,
    )
    setCellType(currentCellType || null)
  }, [levelItem])

  const handlePrintStickers = () => {
    onPrintStickers(levelItem?.zone_id || '', levelItem?.barcode)
  }

  const handleSubmit = (event: React.SyntheticEvent) => {
    const changes: IChangedSettings = {
      isBlocked,
      condition: condition?.value,
      cellType: cellType?.value,
      accessLevel: accessLevel?.length ? `${accessLevel}` : 0,
    }

    if (editType === LevelType.cell) {
      onSubmit({ event, changes, cell: levelItem as ICellPlace })
    } else {
      onSubmit({
        event,
        changes,
        levelTitle: levelItem?.title || '',
        levelZoneId: levelItem?.zone_id || '',
      })
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleRow}>
        <div>
          <Text as={'h1'} className={styles.title}>
            {editType === LevelType.level
              ? 'Настройки уровня'
              : 'Настройки ячейки'}
          </Text>
          <Text>{levelItem?.title}</Text>
        </div>

        <Checkbox
          label={'Заблокировать'}
          checked={isBlocked}
          onChange={(e) => setBlocked(e.target.checked)}
        />
      </div>

      <div className={styles.settingsRow}>
        {editType !== LevelType.level && (
          <InputWrapper
            className={styles.wideInput}
            value={barcode}
            label={'Штрихкод'}
            disabled
            handleChange={(value) => setBarcode(value)}
          />
        )}

        <Select
          className={styles.wideInput}
          label={editType === LevelType.level ? 'Тип ячеек' : 'Тип ячейки'}
          options={conditionTypeOptions}
          value={condition}
          onChange={(value) => setCondition(value)}
        />

        <Select
          className={styles.wideInput}
          label={
            editType === LevelType.level ? 'Параметр ячеек' : 'Параметр ячейки'
          }
          options={cellTypeOptions}
          value={cellType}
          onChange={(value) => setCellType(value)}
        />

        <InputWrapper
          className={styles.access}
          value={accessLevel}
          label={'Доступность'}
          type="number"
          handleChange={(value) => setAccessLevel(value)}
        />
      </div>

      <div className={styles.buttonsRow}>
        <div className={styles.leftButtons}>
          <Button view={'ghost'} label={'Отмена'} onClick={onClose} />
        </div>

        <div className={styles.rightButtons}>
          <Button
            view={'ghost'}
            label={
              editType === LevelType.level
                ? 'Печать стикеров для уровня'
                : 'Печать стикера'
            }
            type={'button'}
            iconLeft={IconPrinter}
            iconSize={'m'}
            loading={isPrinting}
            onClick={handlePrintStickers}
          />

          <Button label={'Сохранить'} onClick={handleSubmit} />
        </div>
      </div>
    </div>
  )
}

LevelSettings.displayName = 'LevelSettings'

export default LevelSettings
