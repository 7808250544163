import { TableColumn } from '@consta/uikit/Table'
import { Link } from 'react-router-dom'
import { IInstruction } from '@/interfaces/instructions'
import { Text } from '@shared/ui/Text'
import React from 'react'
import { generateDirectoriesPath } from '@shared/routing/config/paths'

export const generateTableData = (
  instructions: IInstruction[],
  page: string,
  section: string,
) => {
  const rows = instructions.map((item) => {
    return {
      id: item.id,
      delivery_company: item.delivery_company || '-',
      title: item?.title || '',
      __custom_hrefLink: generateDirectoriesPath({
            page,
            section,
            type: 'item',
            id: item.id,
          })
    }
  })

  const columns: TableColumn<(typeof rows)[number]>[] = [
    {
      title: 'Служба доставки',
      accessor: 'delivery_company',
    },
    {
      title: 'Наименование',
      accessor: 'title',
      renderCell: (row: any) => (
        <Link to={row.original.__custom_hrefLink}>
          <Text view={'link'} children={row.original.title} />
        </Link>
      ),
    },
  ]
  return { rows, columns }
}
