import React, { CSSProperties, RefObject } from 'react'
import cx from 'classnames'
import styles from "./MenuWrapper.module.scss"
import { Flex } from '@/newSrc/shared/ui/Flex';
import { FCC } from '@/newSrc/shared/helpers';

interface MenuWrapperProps {
	wrapperRef: RefObject<any>
  className?: string
  style?: CSSProperties
}

export const MenuWrapper: FCC<MenuWrapperProps> = (props) => {
  const {
    wrapperRef,
    className,
    style,
		children
  } = props
	return (
    <Flex
			ref={wrapperRef}
			className={cx(styles.MenuWrapper, className)}
			direction={'column'}
			gap={'s'}
      style={style}
			children={children}
		/>
	);
};
