import { IMenuList } from '@shared/ui/NavigationMenu/routes'

export const defineType = (pageParam: string, mainMenuList: IMenuList[]) => {
  let result

  if (!pageParam) {
    result = null
  }

  if (pageParam) {
    for (let i = 0; i < mainMenuList.length; i++) {
      if (
        mainMenuList[i].pages.filter((item) => item === pageParam).length !== 0
      ) {
        result = mainMenuList[i].type
      }
    }
  }

  return result
}
