import React from 'react'

import { Text } from '@shared/ui/Text'
import packageInfo from 'src/../package.json'

interface Props extends Omit<React.ComponentProps<typeof Text>, 'children'> {
}

export const AppVersion = (props: Props) => {
  const {
    view = 'secondary'
  } = props
  return (
    <Text
      {...props}
      view={view}
      children={`WMS v${packageInfo.version}`}
    />
  )
}
