import React, { createContext, useContext, useEffect, useReducer, useState } from 'react'
import { initialState, reducer } from '../store/reducer'
import { IReducerAction, IReportState } from '../store/storeInterfaces'
import { useAppContext } from '@shared/providers/AppContextProvider'
import useGetReportTemplates from '../../../../hooks/report/useGetReportTemplates'
import { setTemplates } from '../store/actions'

export interface IUseReducer {
  state: IReportState
  dispatch: React.Dispatch<IReducerAction>
}

interface IContext extends IUseReducer {}

export const ReportsContext = createContext<IContext>({
  state: initialState,
  dispatch: (action: IReducerAction) => action,
})

export const ReportsProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { setGlobalLoading } = useAppContext()
  const { isLoading: isTemplatesLoading } = useGetReportTemplates(templates => dispatch(setTemplates(templates)))
  const [isLoading, setLoading] = useState<boolean>(false)

  useEffect(() => {
      setGlobalLoading(isLoading || isTemplatesLoading)
    },
    [isLoading, isTemplatesLoading])

  useEffect(() => {
    console.log(
      '====== DEBUG STATE ======\n\n',
      state,
      '\n\n==================',
    )
  }, [state])

  return (
    <ReportsContext.Provider
      value={{ state, dispatch }}
    >
      {children}
    </ReportsContext.Provider>
  )
}

export const useReportsContext = () => {
  return useContext(ReportsContext)
}
