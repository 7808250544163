import React, { useMemo } from 'react'
import { IContextMenuItems } from '@shared/ui/btns/ButtonMenu'
import { IconSortUp } from '@consta/icons/IconSortUp'
import { IconSortDown } from '@consta/icons/IconSortDown'
import { IconUnsort } from '@consta/icons/IconUnsort'
import { IconEyeClose } from '@consta/icons/IconEyeClose'
import { Flex } from '@shared/ui/Flex'
import styles from '@/components/Table/TableWithCopy/TableWithCopy.module.scss'
import { SortBtn } from '@/components/Table/settings'
import { Column, Table } from '@tanstack/react-table'
import { Button } from '@/newSrc/shared/ui/btns/Button'
import { SortEnum } from '@shared/types/sort'
import cx from 'classnames'

enum GroupKeyEnum {
  sort,
  hideColumn,
}

const groups = [
  {
    name: 'Сортировка',
    key: GroupKeyEnum.sort,
  },
  // {
  //   name: 'Скрыть столбец',
  //   key: GroupKeyEnum.hideColumn,
  // },
]

interface Props<TRowData> {
  table: Table<TRowData>
  column: Column<TRowData, unknown>
}

export const ColumnSettingsCell = <TRowData, >(props: Props<TRowData>) => {
  const {
    table,
    column,
  } = props


  const sortActive = true

  // todo сделать сортировку как ag grid
  //  + сделать обработку данных сортировки + дать возможность скрывать/раскрывать пункты от настроек
  const menuItems: IContextMenuItems[] = useMemo(() => {
    return [
      {
        leftIcon: IconSortUp,
        label: 'Возрастание',
        groupId: GroupKeyEnum.sort,
        onClick: () => {
        },
      },
      {
        leftIcon: IconSortDown,
        label: 'Убывание',
        groupId: GroupKeyEnum.sort,
        onClick: () => {
        },
      },
      {
        leftIcon: IconUnsort,
        label: 'Сбросить сортировку',
        groupId: GroupKeyEnum.sort,
        onClick: () => {
        },
      },

      {
        leftIcon: IconEyeClose,
        label: 'Скрыть',
        onClick: () => {
        },
      },
    ]
  }, [])


  const commonButtonProps: any = { // todo поправить тип
    size: 'xs',
    onMouseDown: (e) => e.stopPropagation(),
    view: 'clear',
    onlyIcon: true
  }

  return (
    <>
      <Flex
        align={'center'}
        gap={'xs'}
      >
        {column.getCanCustomSort() ? (
          <Button
            {...commonButtonProps}
            iconLeft={
              {
                [SortEnum.DESC]: IconSortDown,
                [SortEnum.ASC]: IconSortUp
              }[`${column.getIsCustomSorted()}`] ||  IconUnsort
            }
            onClick={column.getToggleCustomSortingHandler()}
          />
        ): null}
        {column.getCanHide() ? (
          <Button
            {...commonButtonProps}
            iconLeft={IconEyeClose}
            onClick={column.getToggleVisibilityHandler()}
          />
        ): null}
      </Flex>

      {/* Todo есть проблемы */}
      {/*<ButtonWithContext<GroupKeyEnum>*/}
      {/*  groups={groups}*/}
      {/*  button={{*/}
      {/*    size: 'xs',*/}
      {/*    iconSize: 's',*/}
      {/*    view: 'clear'*/}
      {/*  }}*/}
      {/*  menu={{*/}
      {/*    size: 's',*/}
      {/*    direction: 'downStartLeft'*/}
      {/*  }}*/}
      {/*  onChange={() => {}}*/}
      {/*  initialItems={menuItems}*/}
      {/*/>*/}
    </>
  )
}
