import React, { FC } from 'react'
import type { ISection } from '../../types'
import List from './pages/List/List'
import Item from './pages/Item/Item'
import { PageLayout } from '@pages/DirectoriesPage/components/PageLayout/PageLayout'

interface DocumentStatusesProps extends ISection {}

const DocumentStatuses: FC<DocumentStatusesProps> = (props) => {
  const { title = '' } = props

  return (
    <PageLayout
      title={title}
      listPage={<List />}
      formPage={<Item />}
    />
  )
}
export default DocumentStatuses
