import { ValueOf } from '@shared/helpers'
import { BadgePropStatus } from '@consta/uikit/Badge'

export const TemplatesTypeEnum = {
  shipment: 'SHIPMENT',
  movingTo: 'MOVING_TO',
  return: 'RETURN',
} as const
export type TemplatesTypeEnum = ValueOf<typeof TemplatesTypeEnum>

export const TemplatesPlaceTypeEnum = {
  box: 'BOX',
  pallet: 'PALLET',
} as const
export type TemplatesPlaceTypeEnum = ValueOf<typeof TemplatesPlaceTypeEnum>

export const TEMPLATES_STATUS: Record<TemplatesTypeEnum | string, string> = {
  [TemplatesTypeEnum.shipment]: 'Отгрузка',
  [TemplatesTypeEnum.movingTo]: 'Перемещение ',
  [TemplatesTypeEnum.return]: 'Возвращен',
}

export const TEMPLATES_STATUS_BADGE_TYPE: Record<
  TemplatesTypeEnum | string,
  BadgePropStatus
> = {
  [TemplatesTypeEnum.shipment]: 'system',
  [TemplatesTypeEnum.movingTo]: 'success',
  [TemplatesTypeEnum.return]: 'error',
}

export const TEMPLATES_PLACE_STATUS: Record<
  TemplatesPlaceTypeEnum | string,
  string
> = {
  [TemplatesPlaceTypeEnum.box]: 'Короб',
  [TemplatesPlaceTypeEnum.pallet]: 'Палета',
}
