import { BadgePropStatus } from '@consta/uikit/Badge'
import { TaskTypeEnum } from 'src/interfaces'

export const TASK_TYPE: Record<TaskTypeEnum | string, string> = {
  [TaskTypeEnum.assembling]: 'Сборка',
  [TaskTypeEnum.disassembly]: 'Разборка',
}

export const TASK_TYPE_BADGE_TYPE: Record<
  TaskTypeEnum | string,
  BadgePropStatus
> = {
  [TaskTypeEnum.assembling]: 'success',
  [TaskTypeEnum.disassembly]: 'error',
}
