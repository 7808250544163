import { FieldRow } from '@widgets/GeneratorFields'

export const generateFilterParams = (filterState: Record<string, any>) => {
  return {
    search: filterState['search'] || '',
    page: filterState['page'] || '',
    size: 100,
  }
}

export const generateFilters = (): FieldRow[] => {
  return [
    {
      fields: [
        {
          type: 'input',
          fieldProps: {
            id: 'search',
            label: 'Поиск',
            viewDescription: 'Поиск',
          },
        },
      ],
    },
  ]
}
