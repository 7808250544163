import { TableColumn } from '@consta/uikit/Table'
import { IVehicle } from '@/interfaces'
import { ActivationBadge } from '@shared/ui/Badges/ActivationBadge'
import React from 'react'
import { EditRemoveButtons } from '@pages/DirectoriesPage/components/EditRemoveButtons/EditRemoveButtons'

export const generateTableData = (
  vehicles: IVehicle[],
  handleEdit: (code: string) => void,
  handleDeleteRevert: (code: string, isDelete: boolean) => void,
) => {
  const rows = vehicles.map((item) => {
    return {
      id: item.id,
      model: item.model,
      plate_number: item.plate_number,
      deleted: item.deleted,
      edit: '',
    }
  })

  const columns: TableColumn<(typeof rows)[number]>[] = [
    {
      title: 'Модель',
      width: 300,
      accessor: 'model',
    },
    {
      title: 'Номер',
      width: 300,
      accessor: 'plate_number',
    },
    {
      title: 'Компания',
      width: 300,
      /* @ts-ignore */
      accessor: 'deleted__action',
      renderCell: (row: any) => {
        return (
          <ActivationBadge size={row.getTableDensity()} active={!row.original.deleted} />
        )
      }
    },
    {
      title: '',
      width: 200,
      /* @ts-ignore */
      accessor: 'edit__action',
      align: 'right',
      renderCell: (row: any) => {
        return (
          <EditRemoveButtons
            withEdit
            withRemove
            withRevert
            deleted={row.original.deleted}
            onEditClick={() => handleEdit(row.original.id)}
            onRemoveClick={() => handleDeleteRevert(row.original.id, !row.original.deleted)}
          />
        )
      },
    },
  ]
  return { rows, columns }
}
