import { FieldRow, FieldType } from '@widgets/GeneratorFields'
import { ORDER_STATUSES_DESCRIPTION, TASK_TYPE } from 'src/interfaces'
import { generateStatusesOptions } from '@shared/helpers'

export const generateFilters = (): FieldRow[] => [
  {
    hidden: true,
    fields: [
      {
        type: 'input',
        fieldProps: {
          id: 'search',
          viewDescription: 'Поиск',
        },
      },
    ],
  },
  {
    fields: [
      {
        type: FieldType.MERCHANTS,
        fieldProps: {
          id: 'merchant',
          isMultiple: true,
          label: 'Организация',
          viewDescription: 'Организация',
        },
      },
      {
        type: 'combobox',
        fieldProps: {
          id: 'statuses',
          label: 'Статус',
          isMultiple: true,
          items: generateStatusesOptions(ORDER_STATUSES_DESCRIPTION),
          viewDescription: 'Статус',
        },
      },
      {
        type: 'combobox',
        fieldProps: {
          id: 'set',
          label: 'Тип',
          isMultiple: true,
          items: generateStatusesOptions(TASK_TYPE),
          viewDescription: 'Тип',
        },
      },
    ],
  },
]
