import { Button, InputWithDateMask, InputWrapper, Modal, TSDTableWrapper } from '@/components'
import { SkuItemPreview } from '@entities/Sku/ui/SkuItemPreview'
import styles from './TSDSelectBatchModalV2.module.scss'
import React, { useState } from 'react'
import { IBatch, useCreateBatchResult } from '@/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { IconCalendar } from '@consta/icons/IconCalendar'
import dayjs from 'dayjs'
import { DATE_REQUEST_FORMAT, DATE_VIEW } from '@shared/const/date'
import { useCreateBatch } from '@/hooks'
import { Text } from '@shared/ui/Text'
import cx from 'classnames'
import { useCollapseText } from '@shared/hooks/useCollapseText'
import { Grid, GridItem } from '@consta/uikit/Grid'
import { Flex } from '@shared/ui/Flex'
import { getBatchById } from '@entities/Sku/lib/batch'

type ItemType = {
  id: string,
  batches: IBatch[],
  image?: string
  title?: string
  article?: string
}

interface ISelectBatchModal {
  onSubmit: (selectedBatchId: string, batch: IBatch) => void
  onClose: () => void
  sku: ItemType
  isOpen: boolean
}

const TSDSelectBatchModalV2 = (props: ISelectBatchModal) => {
  const {
    onSubmit,
    sku,
    onClose,
    isOpen,
  } = props

  const [batchId, setBatchId] = useState('')
  const [changeModal, setChangeModal] = useState(false)
  const [batchNum, setBatchNum] = useState<TextFieldPropValue>(null)
  const [batchDate, setBatchDate] = useState<TextFieldPropValue>(null)

  const createBatchMutation = useCreateBatch({ itemId: sku.id ?? '' })

  const headerTitle = !changeModal
    ? 'Проверка партии товара'
    : 'Создание новой партии'

  const headerArticle = !changeModal
    ? 'Выберите партию из списка или создайте новую'
    : 'Введите название партии и срок годности'

  const handleAddNewBatch = async () => {
    const newBatch = {
      num: batchNum ? String(batchNum) : undefined,
      exp_date: batchDate
        ? dayjs(batchDate, DATE_VIEW).format(DATE_REQUEST_FORMAT)
        : undefined,
    }

    createBatchMutation
      .mutateAsync({
        batch: newBatch,
      })
      .then((result: useCreateBatchResult) => {
        onSubmit(result.id, { id: result.id, ...newBatch })
      })
  }


  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      withClose
      onClose={() => {
        if (changeModal) {
          setChangeModal(false)
          return
        }
        onClose()
      }}
      className={styles.modal}
    >
      <Flex direction="column" gap={'2xs'}>
        <Text as={'h2'} size={'m'} children={headerTitle} />
        <Text as={'h4'} size={'s'} children={headerArticle} />
      </Flex>
      <div className={styles.top}>
        <SkuItemPreview
          image={sku.image}
          title={sku.title}
          article={sku.article}
          imgClassName={styles.img}
        />
      </div>
      {!changeModal ? (
        <div className={styles.selectBatch}>
          <TSDTableWrapper
            header={<></>}
            tableClassName={styles.tableWrapper}
            maxTableHeight={180}
          >
            {sku.batches.map((batch, i) => (
              <BatchTableItem
                key={batch.id}
                batch={batch}
                batchId={batchId}
                setBatchId={setBatchId}
              />
            ))}
          </TSDTableWrapper>
          <div className={styles.footer}>
            <Button
              label={'Подтвердить партию'}
              size={'s'}
              disabled={!batchId}
              onClick={() => {
                const batch = getBatchById(sku.batches, batchId)
                if (batch) {
                  onSubmit(batchId, batch)
                }
              }}
            />
            <Button
              view='secondary'
              size={'s'}
              label={'Создать новую партию'}
              onClick={() => setChangeModal(true)}
            />
          </div>
        </div>
      ) : (
        <div className={styles.addbatch}>
          <InputWrapper
            autoFocus
            value={batchNum}
            id={'batchNum'}
            handleChange={(value: TextFieldPropValue) =>
              setBatchNum(String(value))
            }
            className={styles.itemInput}
            placeholder={'№ партии'}
            size='s'
          />
          <InputWithDateMask
            value={batchDate}
            id={'dateTo'}
            handleChange={(value: TextFieldPropValue) => setBatchDate(value)}
            className={styles.itemInput}
            size='s'
            placeholder={DATE_VIEW}
            rightSide={IconCalendar}
          />
          <div className={styles.footer}>
            <Button
              label='Создать партию'
              size={'s'}
              className={styles.btn}
              disabled={changeModal ? !batchNum || !batchDate : !batchNum}
              onClick={handleAddNewBatch}
            />
          </div>
        </div>
      )}
    </Modal>
  )
}

const BatchTableItem = (props: { batchId: string, setBatchId: (id: string) => void, batch: IBatch }) => {
  const {
    batchId,
    setBatchId,
    batch,
  } = props

  const {
    text: batchNum,
    toggleText: toggleBatchNum,
  } = useCollapseText({ text: batch.num, symbols: 20 })

  return (
    <Grid
      cols={2}
      className={cx(styles.batchItem, { [styles.selected]: batchId === batch.id })}
      onClick={() => setBatchId(batch.id)}
    >
      <GridItem>
        <Text size={'xs'} view={'secondary'} children={'Срок годности, до'}/>
        <Text size={'xs'} children={dayjs(batch.exp_date).format(DATE_VIEW) || '-'}/>
      </GridItem>
      <GridItem onClick={toggleBatchNum}>
        <Text size={'xs'} view={'secondary'} children={'Номер партии'}/>
        <Text size={'xs'} children={batchNum} />
      </GridItem>
    </Grid>
  )
}

export default TSDSelectBatchModalV2
