import React, { useEffect, useState } from 'react'

import styles from './search-items-modal.module.scss'

import { IconSearchStroked } from '@consta/icons/IconSearchStroked'
import { Loader } from '@consta/uikit/Loader'

import { InputWrapper, Modal, PackPreview, Table } from 'src/components'
import { Img } from '@shared/ui/Img'

import { IAcceptanceSku, ModalProps } from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'

import { useFetchItems } from 'src/hooks'

interface ISearchItemsModal extends ModalProps {
  merchantId: string
}

const SearchItemsModal = (props: ISearchItemsModal) => {
  const { isOpen, onClose, merchantId } = props

  const [search, setSearch] = useState<TextFieldPropValue>('')
  const [items, setItems] = useState<IAcceptanceSku[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const {fetch: fetchItems} = useFetchItems()

  const handleSearchItems = async (barcode: TextFieldPropValue) => {
    setLoading(true)
    await fetchItems({
        barcode: barcode ?? ''
      })
      .then((result) => {
        setItems(result.skus)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (search && search?.length > 3) {
      handleSearchItems(search)
    }
  }, [search])

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={onClose}
      className={styles.searchModal}
      rootClassName={styles.wrap}
      onClose={onClose}
      withClose
      headerTitle="Поиск товара"
      size="l"
      closeClass={styles.close}
    >
      <InputWrapper
        placeholder={'Начните введите ШК для поиска...'}
        value={search}
        id={'search'}
        handleChange={setSearch}
        className={styles.itemInput}
        size="l"
        leftSide={IconSearchStroked}
        autoFocus
      />

      {!loading && items?.length ? (
        <div className={styles.tableWrap}>
          <Table stickyHeader>
            {items.slice(0, 10).map((item: any, i: number) => (
              <Table.Row key={`${item.id}-${item.pack_id}`}>
                <Table.Column title="Фото" className={styles.tdImg}>
                  <Img
                    src={item.image}
                    className={styles.img}
                    size={100}
                    yandex
                    modal
                  />
                </Table.Column>
                <Table.Column
                  title={
                    <div className={styles.thName}>
                      Номенклатура <span className={styles.art}>Артикул</span>
                    </div>
                  }
                  className={styles.tdName}
                >
                  <div className={styles.name}>
                    <span>{item.title}</span>
                    <span className={styles.art}>{item.article}</span>
                  </div>
                </Table.Column>
                <Table.Column title="Упаковка" align="right">
                  <PackPreview item={item} />
                </Table.Column>
                <Table.Column
                  title="Штрихкод"
                  className={styles.tdBarcode}
                  align="right"
                >
                  {item?.barcodes?.[0]?.barcode}
                </Table.Column>
              </Table.Row>
            ))}
          </Table>
        </div>
      ) : null}

      {loading ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : null}
    </Modal>
  )
}

export default SearchItemsModal
