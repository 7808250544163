import {
  functionalUpdate,
  makeStateUpdater,
  RowData,
  Table,
  TableFeature,
  Updater,
} from '@tanstack/react-table'
import {
  TableSettingsOptions,
  TableSettingsState,
} from './types'


export const TableSettingsFeature: TableFeature<any> = {

  getInitialState: (state): TableSettingsState => {
    return {
      tableSettingsVisible: false,
      ...state,
    }
  },

  getDefaultOptions: <TData extends RowData>(
    table: Table<TData>
  ): TableSettingsOptions => {
    return {
      tableSettings: true,
      onTableSettingsVisibleChange: makeStateUpdater('tableSettingsVisible', table),
    } as TableSettingsOptions
  },

  createTable: <TData extends RowData>(table: Table<TData>): void => {
    table.setTableSettingsVisible = updater => {
      const safeUpdater: Updater<boolean> = old => {
        return functionalUpdate(updater, old)
      }
      return table.options.onTableSettingsVisibleChange?.(safeUpdater)
    }
    table.toggleTableSettingsVisible = () => table.setTableSettingsVisible(!table.getState()?.tableSettingsVisible)
  },
}