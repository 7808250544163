import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { IFields, RoleItem, UserModalProps } from './types'
import { ValidateErrors } from '../../types'

import { ISelectOption } from '@/interfaces'

import { TextFieldPropValue } from '@consta/uikit/TextField'
import { FileField } from '@consta/uikit/FileField'
import { Button } from '@shared/ui/btns/Button'
import { Combobox } from '@consta/uikit/Combobox'

import { IconAttach } from '@consta/icons/IconAttach'
import { IconClose } from '@consta/icons/IconClose'

import { InputWrapper, Select } from '@/components'

import cx from 'classnames'
import styles from './user-modal.module.scss'
import { Checkbox } from '@consta/uikit/Checkbox'
import { Text } from '@shared/ui/Text'
import ComboboxUserCompanies from '@entities/UserCompany/ui/ComboboxUserCompanies/ComboboxUserCompanies'
import { convertFileToBase64 } from '@shared/helpers'


const initialFieldsState = {
  surname: '',
  name: '',
  patronymic: '',
  position: null,
  companyId: null,
  roles: null,
  login: '',
  password: '',
  enabled: true,
}

const UserModal: FC<UserModalProps> = (props) => {
  const {
    editMode,
    positions,
    user,
    roles,
    onClose,
    onSubmit = () => {
    },
  } = props

  const [attachedFile, setAttachedFile] = useState<File | null>(null)

  const [fieldValues, setFieldValues] = useState<IFields>(initialFieldsState)
  const [fieldErrors, setFieldErrors] = useState(initialFieldsState)

  const canSubmit = (): boolean => {
    const emptyFieldKey = Object.keys(fieldValues).find((key) => {
      let notRequiredFields = ['position', 'patronymic', 'enabled', 'companyId']
      if (editMode) {
        notRequiredFields.push('password')
      }
      if (notRequiredFields.includes(key)) {
        return false
      }
      const value = fieldValues[key as keyof typeof fieldValues]

      return !value
    })

    if (emptyFieldKey) {
      setFieldErrors((prevState) => ({
        ...prevState,
        [emptyFieldKey]: ValidateErrors.isEmpty,
      }))
    }

    return !emptyFieldKey
  }

  const handleChangeUser = (
    value: TextFieldPropValue | RoleItem[] | ISelectOption | boolean | null,
    key: keyof typeof fieldValues,
  ) => {
    const isValueObject = typeof value === 'object' && !Array.isArray(value)
    const isValueBool = typeof value === 'boolean' && value
    if (typeof value === 'string') {
      setFieldErrors((prevState) => ({
        ...prevState,
        [key]:
          isValueObject || isValueBool || ((!value || !value.length) && key !== 'patronymic')
            ? ValidateErrors.isEmpty
            : '',
      }))
    }

    setFieldValues((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const handleAddFile = (fileList: FileList | null) => {
    if (!fileList) {
      return
    }

    setAttachedFile(fileList[0])
  }

  const handleDeleteFile = () => setAttachedFile(null)

  const handleSubmit = async () => {
    const isValid = canSubmit()

    if (!isValid) {
      return
    }

    const submitData = {
      enabled: fieldValues.enabled,
      id: user?.id,
      surname: fieldValues.surname,
      name: fieldValues.name,
      patronymic: fieldValues.patronymic,
      login: fieldValues.login,
      company_id: fieldValues.companyId,
      password: fieldValues.password,
      position: String(fieldValues.position?.value || '') || null,
      roles: fieldValues?.roles?.map((role) => role.id) || [],
    }
    const fileForSend = attachedFile ? await convertFileToBase64(attachedFile) : null
    onSubmit(submitData, fileForSend, attachedFile?.name || '')
    onClose()
  }

  useEffect(() => {
    if (user) {
      const pos = positions.find((el) => el.value === user?.position)
      const rolesRes = roles.filter((role) => user?.roles.includes(role.id))

      setFieldValues((prev) => ({
        ...prev,
        roles: rolesRes,
        position: pos || null,
        surname: user?.surname,
        name: user?.name,
        companyId: user?.company?.id,
        patronymic: user?.patronymic,
        login: user?.login,
        enabled: user?.enabled,
      }))
    }
  }, [positions, roles, user])

  return (
    <div className={styles.container}>
      <div>
        <Text as={'h2'} size='xl' className={styles.title}>Изменить пользователя</Text>
        <Text view={'secondary'}>Заполните все данные для пользователя</Text>
      </div>

      <div className={cx(styles.row_name, styles.row)}>
        <InputWrapper
          size={'s'}
          className={styles.rowItem}
          label={'Фамилия'}
          value={fieldValues.surname}
          error={fieldErrors.surname}
          isRequired
          handleChange={(value) => handleChangeUser(value, 'surname')}
        />

        <InputWrapper
          size={'s'}
          className={styles.rowItem}
          label={'Имя'}
          value={fieldValues.name}
          error={fieldErrors.name}
          isRequired
          handleChange={(value) => handleChangeUser(value, 'name')}
        />

        <InputWrapper
          size={'s'}
          className={styles.rowItem}
          label={'Отчество'}
          value={fieldValues.patronymic}
          error={fieldErrors.patronymic}
          handleChange={(value) => handleChangeUser(value, 'patronymic')}
        />
      </div>

      <div className={styles.row}>
        <Select
          size={'s'}
          className={styles.rowItem}
          label={'Должность'}
          value={fieldValues.position}
          options={positions}
          onChange={(value) => {
            handleChangeUser(value, 'position')
          }}
        />

        <div className={cx(styles.rowItem, styles.roles)}>
          <label className={cx(styles.label, styles.label_required)}>
            Роль
          </label>
          <Combobox
            size={'s'}
            className={styles.rolesCombo}
            dropdownClassName={styles.rolesComboDown}
            items={roles}
            value={fieldValues.roles}
            multiple
            onChange={(value) => handleChangeUser(value, 'roles')}
          />
          <div className={styles.errorMessage}>{fieldErrors.roles}</div>
        </div>
      </div>

      <div className={styles.row}>
        <InputWrapper
          size={'s'}
          className={styles.rowItem}
          label={'Логин'}
          value={fieldValues.login}
          error={fieldErrors.login}
          isRequired
          handleChange={(value) => handleChangeUser(value, 'login')}
        />

        <InputWrapper
          size={'s'}
          className={styles.rowItem}
          label={user ? 'Новый пароль' : 'Пароль'}
          value={fieldValues.password}
          error={fieldErrors.password}
          isRequired={!editMode}
          handleChange={(value) => handleChangeUser(value, 'password')}
        />
      </div>
      <div>
        <ComboboxUserCompanies
          size={'s'}
          valueId={fieldValues.companyId}
          onChange={val => {
            handleChangeUser(val?.id || null, 'companyId')
          }}
          label={'Компания'}
          wrapperClassName={styles.rowItem}
        />
      </div>
      <div>
        <Checkbox
          label={'Активность'}
          checked={fieldValues.enabled}
          onChange={(e) => handleChangeUser(e.target.checked, 'enabled')}
          className={cx(styles.item)}
        />
      </div>

      <div className={styles.fileRow}>
        <FileField
          className={styles.fileField}
          id={'file'}
          accept={'.png'}
          onChange={(event: DragEvent | React.ChangeEvent) =>
            handleAddFile((event.target as HTMLInputElement)?.files)
          }
        >
          {(props) => (
            <Button
              {...props}
              label='Загрузить подпись'
              view={'ghost'}
              size={'s'}
              iconLeft={IconAttach}
            />
          )}
        </FileField>

        {!!attachedFile && (
          <div className={styles.fileName}>
            {attachedFile.name}

            <IconClose
              className={styles.fileDel}
              view={'alert'}
              size={'xs'}
              onClick={handleDeleteFile}
            />
          </div>
        )}
      </div>

      <div className={styles.buttonsRow}>
        <Button
          label={'Отмена'}
          view={'ghost'}
          type={'button'}
          onClick={onClose}
        />

        <Button label={'Сохранить'} type={'submit'} onClick={handleSubmit} />
      </div>
    </div>
  )
}

UserModal.displayName = 'UserModal'

export default UserModal
