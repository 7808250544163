import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { ValidateErrors } from '../../types'
import { GateModalProps, IFields } from './types'
import { Button } from '@shared/ui/btns/Button'
import { InputWrapper } from '@/components'
import cx from 'classnames'
import styles from './GateModal.module.scss'
import { v4 } from 'uuid'
import { Text } from '@shared/ui/Text'

const GateModal: FC<GateModalProps> = ({
  title,
  gate,
  onClose,
  onSubmit = () => {},
}) => {
  const [fieldValues, setFieldValues] = useState<IFields>({
    title: '',
    id: '',
  })
  const [fieldErrors, setFieldErrors] = useState({
    title: '',
  })
  const canSubmit = (): boolean => {
    const emptyFieldKey = Object.keys(fieldValues).find((key) => {
      const value = fieldValues[key as keyof typeof fieldValues]

      return !value
    })

    if (emptyFieldKey) {
      setFieldErrors((prevState) => ({
        ...prevState,
        [emptyFieldKey]: ValidateErrors.isEmpty,
      }))
    }

    return !emptyFieldKey
  }
  useEffect(() => {
    if (!gate) {
      setFieldValues((prev) => {
        return {
          ...prev,
          id: v4(),
        }
      })
      return
    }

    setFieldValues(gate)
  }, [gate])
  const handleSubmit = () => {
    const isValid = canSubmit()

    if (!isValid) {
      return
    }

    onSubmit(fieldValues)

    onClose()
  }
  return (
    <div className={styles.container}>
      <div>
        <Text as={'h2'} className={styles.title}>{title}</Text>
        <Text className={styles.subtitle}>Заполните все данные для ворот</Text>
      </div>

      <div className={cx(styles.row_name, styles.row)}>
        <InputWrapper
          className={styles.rowItem}
          label={'Наименование'}
          value={fieldValues.title}
          error={fieldErrors.title}
          isRequired
          handleChange={(value) =>
            setFieldValues((prev) => {
              return { ...prev, title: `${value}` }
            })
          }
        />
      </div>

      <div className={styles.buttonsRow}>
        <Button
          label={'Отмена'}
          view={'ghost'}
          type={'button'}
          onClick={onClose}
        />

        <Button label={'Сохранить'} type={'submit'} onClick={handleSubmit} />
      </div>
    </div>
  )
}
export default GateModal
