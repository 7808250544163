import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './CombineCellsComponent.module.scss'
import { TreeSelectCell } from '@entities/Cell'
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup'
import ComboboxWarehousePlace from '@entities/WarehousePlace/ui/ComboboxWarehousePlace/ComboboxWarehousePlace'
import { CommonFieldProps } from '@shared/types/fields'
import { Grid, GridItem } from '@consta/uikit/Grid'
import { Text } from '@shared/ui/Text'


const choicesTypes: { label: string; value: string }[] = [
  { label: 'Ячейка', value: 'cell' },
  { label: 'Все места', value: 'all_places' },
]

type Props = {
  value: string[]
  handleChange: (value: string[]) => void
  loading?: boolean
} & Omit<CommonFieldProps, 'onChange' | 'value'>

/* Компонент с древовидным селектом + обычным поиском */
const CombineCellsComponent = (props: Props) => {
  const {
    label,
    size,
    value = [],
    handleChange,
  } = props

  const [choiceValue, setChoiceValue] = useState<{ label: string; value: string }>(choicesTypes[0])

  const {
    comboboxValues,
    treeSelectValues,
  } = useMemo(() => {
    const comboboxValues = value.filter(item => typeof item === 'object')
    const treeSelectValues = value.filter(item => typeof item === 'string')
    return {
      comboboxValues,
      treeSelectValues,
    }
  }, [value])


  /* Нужен чтобы обойти проблему перерендера древовидного селекта */
  const stateRef = useRef({ comboboxValues: [], treeSelectValues: [] })
  useEffect(() => {
    if (!stateRef) return
    const comboboxValues = value.filter(item => typeof item === 'object')
    const treeSelectValues = value.filter(item => typeof item === 'string')
    stateRef.current = { comboboxValues, treeSelectValues }
  }, [value, stateRef])

  const treeSelect = useMemo(() => {
    return (
      <TreeSelectCell
        label={''}
        size={size}
        onChange={(nodeTopology: string[]) => {
          const comboboxValues = stateRef?.current?.comboboxValues || []
          handleChange([...nodeTopology, ...comboboxValues])
        }}
      />
    )
  }, [stateRef])

  const warehousePlaceSelect = useMemo(() => {
    return (
      <ComboboxWarehousePlace
        isMultiple
        placeholder={'Выберите место на складе'}
        value={comboboxValues}
        label={''}
        size={size}
        onChange={(newValue) => {
          handleChange([...treeSelectValues, ...(newValue || [])])
        }}
      />
    )
  }, [comboboxValues, treeSelectValues])

  return (
    <Grid cols={8} gap={'xs'}>
      <GridItem col={5}>
        <Text view={'secondary'} children={label} size={'m'}/>
      </GridItem>
      <GridItem col={3}>
        <ChoiceGroup
          className={styles.choiceGroup}
          value={choiceValue}
          items={choicesTypes}
          getItemLabel={(item) => item.label}
          onChange={(value) => setChoiceValue(value)}
          name={'warehousePlaces'}
          multiple={false}
          size='xs'
          view={'primary'}
          width={'full'}
        />
      </GridItem>
      <GridItem col={8}>
        <div
          hidden={choiceValue.value !== 'cell'}
          children={treeSelect}
        />
        <div
          hidden={choiceValue.value !== 'all_places'}
          children={warehousePlaceSelect}
        />
      </GridItem>
    </Grid>
  )
}

export default CombineCellsComponent
