import React, { useEffect, useState } from 'react'

import styles from './create-carrier-modal.module.scss'
import { InputWrapper, Modal, Select } from 'src/components/index'
import { ICarrier, ICarrierCompany, ICarrierCrateData, ISelectOption, ModalProps } from 'src/interfaces'
import { Button } from '@shared/ui/btns/Button'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import cx from 'classnames'
import { useCreateUpdateCarrier } from 'src/hooks'

interface IProps extends ModalProps {
  carriersCompanyList: ICarrierCompany[]
  onSubmit: (newCarrier: ICarrier) => void
  carrierEditId?: string | null
  carriersList: ICarrier[]
}

interface ICarrierForm extends Omit<ICarrier, 'company' | 'id'> {
  company?: ISelectOption
}
const CreateCarrierModal = (props: IProps) => {
  const {
    isOpen,
    onClose,
    onSubmit,
    carriersCompanyList,
    carrierEditId,
    carriersList = [],
  } = props

  const createUpdateCarrier = useCreateUpdateCarrier({
    carrierId: carrierEditId || '',
  })

  const [formState, setFormState] = useState<ICarrierForm | null>(null)

  const onCreate = () => {
    const reqData: ICarrierCrateData = {
      ...formState,
      company_id: formState?.company?.value?.toString(),
    }
    createUpdateCarrier
      .mutateAsync(reqData)
      .then((data) => {
        const company = carriersCompanyList.find(
          (company) => company.id === reqData.company_id,
        )
        onSubmit({ ...reqData, id: data.id, company })
      })
      .catch(() => {})
  }

  const onChange = (id: string, value: TextFieldPropValue | ISelectOption) => {
    setFormState((prev) => ({ ...prev, [id]: value }))
  }

  const carrierCompanies: ISelectOption[] = carriersCompanyList.map((item) => ({
    label: item.title || '-',
    value: item.id || 0,
  }))
  useEffect(() => {
    if (!carrierEditId) {
      return
    }
    const company = carriersList.find((c) => c.id === carrierEditId)

    setFormState({
      ...company,
      company: {
        label: company?.company?.title || '',
        value: company?.company?.id || '',
      },
    })
  }, [carrierEditId])

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.modal}
      size="m"
      headerTitle={'Создание нового перевозчика'}
    >
      <div className={styles.formWrapper}>
        <div className={styles.driverInfo}>
          <div className={styles.driverName}>
            <InputWrapper
              size={'s'}
              id={'surname'}
              form={'defaultClear'}
              label={'Фамилия'}
              value={formState?.surname}
              handleChange={(value: TextFieldPropValue) => {
                onChange('surname', value)
              }}
              className={cx(styles.input, styles.surname)}
            />
            <InputWrapper
              size={'s'}
              id={'name'}
              label={'Имя'}
              form={'brickClear'}
              value={formState?.name}
              handleChange={(value: TextFieldPropValue) => {
                onChange('name', value)
              }}
              className={cx(styles.input, styles.name)}
            />
            <InputWrapper
              size={'s'}
              id={'patronymic'}
              label={'Отчество'}
              form={'brickDefault'}
              value={formState?.patronymic}
              handleChange={(value: TextFieldPropValue) => {
                onChange('patronymic', value)
              }}
              className={cx(styles.input, styles.patronymic)}
            />
          </div>
        </div>
        <div className={styles.carAndGateInfo}>
          <InputWrapper
            size={'s'}
            isNumber
            id={'phone'}
            label={'Телефон водителя'}
            placeholder={'+7'}
            value={formState?.phone}
            handleChange={(value: TextFieldPropValue) => {
              onChange('phone', value)
            }}
            className={cx(styles.input, styles.driverPhone)}
          />
          <Select
            size={'s'}
            id={'company'}
            label={'Компания–перевозчика'}
            options={carrierCompanies}
            value={formState?.company}
            onChange={(value) => {
              onChange('company', value)
            }}
            className={cx(styles.select)}
          />
        </div>
      </div>

      <div className={styles.footer}>
        <Button
          size={'s'}
          label="Отмена"
          view={'ghost'}
          onClick={onClose}
        />
        <Button
          size={'s'}
          label="Сохранить"
          view={'primary'}
          onClick={onCreate}
          loading={createUpdateCarrier.isLoading}
        />
      </div>
    </Modal>
  )
}

export default CreateCarrierModal
