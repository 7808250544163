import api from '@shared/api'

import { ApiReqResult, IContractorDto } from 'src/interfaces'
import { useQuery } from 'react-query'
import { UseQueryOptions } from 'react-query/types/react/types'

export interface IResult extends ApiReqResult {
  contractor: IContractorDto
}
interface IParams {
  contractorId: string
}
const getContractor = async (params: IParams) => {
  const result = await api.get(`/contractor/${params.contractorId}`)
  return result.data as IResult
}

const useGetContractor = (params: IParams, options: UseQueryOptions<any> = {}) =>
  useQuery<IResult>(
    [`getContractor`, params],
    async () => await getContractor(params),
    {
      ...options
  })

export default useGetContractor