import React from 'react'
import { Button, ImageCell, InputWrapper } from 'src/components'
import styles from './DocumentsParamsFields.module.scss'
import { IconCheck } from '@consta/icons/IconCheck'
import { IconClose } from '@consta/icons/IconClose'
import { IconEdit } from '@consta/icons/IconEdit'

import { ICommonDocument, IDocumentsFacts, ISku, ITableColumn, ITableRow, IWarehouseCell } from 'src/interfaces'
import { NomenclatureCell } from '../../../../../components'
import { getBatchById } from '@entities/Sku/lib/batch'

export const generateColumns = (): ITableColumn[] => {
  return [
    {
      key: 'img',
      title: 'Фото',
      title_txt: 'Фото',
      gridCellWidth: '90px',
      renderCell: ({ row }) => <ImageCell image={row.img} />,
    },
    {
      key: 'nomenclature',
      title: 'Номенклатура',
      title_txt: 'Номенклатура',
      renderCell: ({ row }) => (
        <NomenclatureCell
          title={row.nomenclature}
          itemId={`${row.id}` || '-'}
        />
      ),
    },

    {
      key: 'barcode',
      title: 'Штрихкод',
      title_txt: 'Штрихкод',
    },
    {
      key: 'expirationDate',
      title: 'Срок годности',
      title_txt: 'Срок годности',
    },
    {
      key: 'quantityPlan',
      title: 'Количество/План',
      title_txt: 'Количество/План',
    },
    {
      key: 'quantityFact',
      title: 'Количество/Факт',
      title_txt: 'Количество/Факт',
    },
  ]
}

export const generateRows = (
  document: ICommonDocument | null,
  skus: ISku[],
  cells: IWarehouseCell[],
  editCellId: string | null,
  draftCellItemData: Record<string, string | null> | null,
  handleEditCellItemQuantity: (
    cellItemId: string,
    value: string | null,
  ) => void,
  handleClickCellEdit: (
    factId: string,
    editMode: 'accept' | 'reset' | 'start',
  ) => void,
): ITableRow[] => {
  if (!document) {
    return []
  }
  // console.log(document, skus, '>>>>>>>>>>full')

  return (
    document.plans?.map((plan) => {
      // console.log(plan)

      const fact =
        document &&
        document.facts &&
        document?.facts.find((el) => el.cell_barcode === plan.cell_barcode)
      const editMode = fact?.id === editCellId

      const subltems = generateDocItems({
        plan,
        skus,
        cells,
        document,
        editMode,
        handleEditCellItemQuantity,
        draftCellItemData,
      })
      const cell = cells.find((el) => el.barcode === plan.cell_barcode)

      return {
        id: plan.id,
        // hardExpanded: editCellId !== null ? editMode : undefined,
        hardExpanded: true,
        cellName: <b>{cell?.title || '-'}</b>,
        renderExtraRow: ({ row }: any) => (
          <div className={styles.cellContainer}>
            <p>Ячейка <b>{cell?.title}</b></p>
            <div className={styles.cellBtns}>
              {editMode ? (
                <>
                  <Button
                    onlyIcon
                    theme={'success'}
                    className={styles.editBtn}
                    iconLeft={IconCheck}
                    size="s"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleClickCellEdit(fact.id, 'accept')
                    }}
                  />
                  <Button
                    onlyIcon
                    theme={'danger'}
                    className={styles.editBtn}
                    iconLeft={IconClose}
                    size="s"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleClickCellEdit(fact.id, 'reset')
                    }}
                  />
                </>
              ) : !editMode && !editCellId && fact ? (
                <Button
                  onlyIcon
                  view="ghost"
                  className={styles.editBtn}
                  iconLeft={IconEdit}
                  size="s"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleClickCellEdit(fact.id, 'start')
                  }}
                />
              ) : null}
            </div>
          </div>
        ),
        subItems: subltems,
      }
    }) || []
  )
}
interface IGenerateDocItems {
  document: ICommonDocument | null
  plan?: IDocumentsFacts | null
  skus: ISku[]
  cells: IWarehouseCell[]
  fact?: IDocumentsFacts | null
  editMode?: boolean
  handleEditCellItemQuantity?: (
    cellItemId: string,
    value: string | null,
  ) => void
  draftCellItemData?: Record<string, string | null> | null
}
const generateDocItems = (args: IGenerateDocItems) => {
  const {
    plan,
    skus,
    cells,
    document,
    editMode,
    handleEditCellItemQuantity,
    draftCellItemData,
  } = args

  if (!plan) {
    return
  }
  const factCell = document?.facts?.find(
    (el) => el.cell_barcode === plan.cell_barcode,
  )
  return plan?.items.map((item) => {
    const factItem = factCell?.items.find((el) => el.sku_id === item.sku_id)
    const originalSku = skus.find((el) => el.id === item.sku_id)
    const dataTo = getBatchById(originalSku?.batches, item.sku_batch_id)
    const draftItemQuantity =
      draftCellItemData?.[factItem?.sku_id ? factItem?.sku_id : 0]
    const barcode = originalSku?.barcodes[0].barcode
    return {
      id: item.sku_id,
      img: originalSku?.image,
      nomenclature: originalSku?.title,
      barcode: barcode,
      expirationDate: dataTo?.exp_date || '-',
      quantityPlan: item.quantity,
      quantityFact: editMode ? (
        <InputWrapper
          isInteger
          id={'barcode'}
          label={''}
          placeholder={''}
          handleChange={(value) => {
            if (handleEditCellItemQuantity) {
              handleEditCellItemQuantity(item.sku_id, value)
            }
          }}
          value={String(draftItemQuantity || '')}
          className={styles.barcodeInput}
        />
      ) : factItem?.quantity || factItem?.quantity === 0 ? (
        factItem?.quantity
      ) : (
        '-'
      ),
    }
  })
}

export const generateRowsDiscrepancy = (
  document: ICommonDocument | null,
  skus: ISku[],
  cells: IWarehouseCell[],
): ITableRow[] => {
  if (!document) {
    return []
  }

  return (
    document.facts?.map((fact) => {
      const subltems = generateDocItemsDiscrepancy({
        fact,
        skus,
        cells,
        document,
      })
      const cell = cells.find((el) => el.barcode === fact.cell_barcode)

      return {
        id: fact.id,
        renderExtraRow: ({ row }: any) => (
          <div className={styles.cellContainer}>
            <p>Ячейка <b>{cell?.title}</b></p>
          </div>
        ),
        subItems: subltems,
      }
    }) || []
  )
}

const generateDocItemsDiscrepancy = (args: IGenerateDocItems) => {
  const { fact, skus, cells, document } = args

  if (!fact) {
    return
  }
  const planCell = document?.plans?.find(
    (el) => el.cell_barcode === fact.cell_barcode,
  )
  return fact?.items
    .filter((el) => {
      const elPlan = planCell?.items.find((p) => p.sku_id === el.sku_id)
      return el.quantity !== elPlan?.quantity
    })
    .map((item) => {
      const planItem = planCell?.items.find((el) => el.sku_id === item.sku_id)
      const originalSku = skus.find((el) => el.id === item.sku_id)
      const dataTo = getBatchById(originalSku?.batches, item.sku_batch_id)

      const barcode = originalSku?.barcodes[0].barcode
      return {
        id: item.sku_id,
        img: originalSku?.image,
        nomenclature: originalSku?.title,
        barcode: barcode,
        expirationDate: dataTo?.exp_date || '-',
        quantityPlan: planItem?.quantity || '-',
        quantityFact: item.quantity,
      }
    })
}
