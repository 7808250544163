import { WorkModeEnum } from '../enums'
import { IOrderPackagingPlace, IPicking, ISku, IWarehouseCell } from '@/interfaces'
import { ComboboxItemDefault } from '@consta/uikit/Combobox'
import { getBatchNameValue } from '@pages/OperationsAndDocumentsPage/helpers/common'
import { IGetCellStockByCellIdResult } from '@/hooks'
import { ComboboxSkuItemType } from '@entities/Sku/ui/ComboboxSkus/ComboboxSkus'

interface IPickings {
  skus: ISku[]
  pickings: IPicking[]
  cells: IWarehouseCell[]
}
export const generateSelectItemsDelete = (
  workMode: WorkModeEnum,
  picking: IPickings,
  places: IOrderPackagingPlace[],
  sku: ComboboxItemDefault,
  batch: ComboboxItemDefault,
) => {
  let result = {
    places: [],
    cells: [],
    batch: [],
  }
  if (workMode !== WorkModeEnum.delete || !sku) return result

  const originalSku = picking?.skus.find((el) => el.id === sku.id)
  const pickingItems = picking?.pickings[0]?.items.filter(
    (el) => el.sku_id === sku.id,
  )
  const cells = picking?.cells.filter((cell) =>
    pickingItems.map((el) => el.cell_barcode).includes(cell.barcode),
  )
  const filteredPlaces = places.filter((elPlaces) => {
    return elPlaces.items.some((elItems) => elItems.id === sku.id)
  })
  result.places = filteredPlaces.map((el) => ({ label: el.id, id: el.id }))
  result.cells = cells.map((el) => ({ label: el.title, id: el.barcode }))

  if (originalSku?.batch_accounting) {
    result.batch = originalSku.batches.map((el) => ({
      label: getBatchNameValue(el),
      id: el.id,
    }))
  }
  if (batch) {
    const pickingItems = picking?.pickings[0]?.items.filter(
      (el) => el.sku_id === sku.id && el.sku_batch_id === batch.id,
    )
    result.cells = picking?.cells
      .filter((cell) =>
        pickingItems.map((el) => el.cell_barcode).includes(cell.barcode),
      )
      .map((el) => ({ label: el.title, id: el.barcode }))
    result.places = places
      .filter((elPlaces) => {
        return elPlaces.items.some(
          (elItems) =>
            elItems.id === sku.id && elItems?.sku_batch_id === batch.id,
        )
      })
      .map((el) => ({ label: el.id, id: el.id }))
  }
  return result
}

export const generateSelectItemsAdd = (
  workMode: WorkModeEnum,
  skuComboboxItem: ComboboxSkuItemType,
  stock: IGetCellStockByCellIdResult,
  batch: ComboboxItemDefault,
  stockForCells: IGetCellStockByCellIdResult,
) => {
  let result = {
    cells: [],
    batch: [],
  }
  if (workMode !== WorkModeEnum.add || !skuComboboxItem) return result
  const originalSku = skuComboboxItem?.dto

  if (originalSku?.batch_accounting) {
    result.batch = originalSku?.batches.map((el) => ({
      label: getBatchNameValue(el),
      id: el.id,
    }))
  }

  const filteredCellsBarcode = stock?.stock
    ?.filter((el) => el.quantity - el.reserved > 0)
    ?.map((el) => el.place_id)
  if (filteredCellsBarcode?.length) {
    result.cells = stock?.places
      ?.filter((el) => filteredCellsBarcode?.includes(el.barcode))
      ?.map((el) => ({ label: el.title, id: el.barcode }))
  }
  if (batch) {
    const filteredCellsBarcode = stockForCells?.stock
      ?.filter((el) => el.quantity - el.reserved > 0)
      ?.map((el) => el.place_id)
    if (filteredCellsBarcode?.length) {
      result.cells = stockForCells?.places
        ?.filter((el) => filteredCellsBarcode?.includes(el.barcode))
        ?.map((el) => ({ label: el.title, id: el.barcode }))
    } else {
      result.cells = []
    }
  }

  return result
}
