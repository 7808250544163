import React, { FC, useCallback, useMemo, useState } from 'react'
import { ISection } from '../../types'
import SectionTitle from '../../components/SectionTitle/SectionTitle'
import { generateFilterParams } from './utils/filters'
import { generateTableData } from './utils/table'
import DirectoriesTable from '../../components/DirectoriesTable/DirectoriesTable'
import { Grid, GridItem } from '@consta/uikit/Grid'
import useGetPackagingsSettings from '@/hooks/packaging/useGetPackagingsSettings'
import { Button, ComboboxWrapper, Modal } from '@/components'
import { PackagingPurposes } from '@/interfaces'
import { ComboboxMerchants } from '@entities/Merchant'
import { ComboboxDeliveryCompany, ComboboxDeliveryCompanyProduct } from '@entities/DeliveryCompany'
import { ComboboxSkus } from '@entities/Sku'
import { IconSearchStroked } from '@consta/icons/IconSearchStroked'
import { Flex } from '@shared/ui/Flex'
import { Text } from '@shared/ui/Text'
import useSetPackagingsSettings from '@/hooks/packaging/useSetPackagingsSettings'
import {
  PackagingSettingsColors,
  PackagingSettingsDto,
  PackagingSettingsOverrideDto,
  RequiredFieldsToSet,
  SetNewPackagingSettingsFormParams,
} from '@/interfaces/packaging'
import { PackagingSettingTypeCombobox } from '@entities/PackagingSetting'
import useGetRuleType from '@/hooks/enums/useGetEnumRuleType'
import { ComboboxItemDefault } from '@consta/uikit/Combobox'
import { TableColumnType, TableRowType, TanStackTable } from '@/components/Table/TanStackTable'

interface PaymentMethodsProps extends ISection {
}

const PackagingsSettings: FC<PaymentMethodsProps> = (props) => {
  const { title = '' } = props

  /* Сетйт филтров */
  const [filterState, setFilterState] = useState<Record<string, ComboboxItemDefault>>({})
  const [filterDraftState, setFilterDraftState] = useState<Record<string, ComboboxItemDefault>>({})

  /* Данные модалки для перезаписи */
  const [overrideItems, setOverrideItems] = useState<PackagingSettingsOverrideDto[] | null>(null)

  /* стейт выбранных строк */
  const [rowsSelectionsIds, setRowsSelectionsIds] = useState<TableRowType[]>([])

  /* стейт массового выбора */
  const [massPackagingType, setMassPackagingType] = useState<string>('')

  /* стейт настроек */
  const [settingsState, setSettingsState] = useState<PackagingSettingsDto[]>([])

  /* стейт изменений */
  const [changeState, setChangeState] = useState<Record<string /* packagingId */, RequiredFieldsToSet>>({})

  const stateWasChange = useMemo(() => {
    return Boolean(Object.values(changeState).filter(item => Boolean(item.type))?.length) || Boolean(massPackagingType)
  }, [changeState, massPackagingType])

  const filterStateIsEmpty = useMemo(() => !Object.values(filterState).filter(Boolean)?.length, [filterState])

  const filterParams = useMemo(
    () => generateFilterParams(filterState),
    [filterState],
  )

  const handleResetSelectionState = useCallback(
    () => setRowsSelectionsIds([]),
    [setRowsSelectionsIds],
  )
  const handleResetMassPackagingTypeState = useCallback(
    () => setMassPackagingType(''),
    [setMassPackagingType],
  )
  const handleResetState = useCallback(
    () => setChangeState({}),
    [setChangeState],
  )

  const handleChangeDraftFilter = useCallback((fieldId: string, value?: any) =>
      setFilterDraftState((prev) => ({ ...prev, [fieldId]: value })),
    [setFilterDraftState],
  )

  const handleClickSearchByFilter = useCallback(
    () => setFilterState(filterDraftState),
    [setFilterState, filterDraftState],
  )

  const handleClickResetFilter = useCallback(() => {
    setFilterState({})
    setFilterDraftState({})
  }, [
    setFilterState,
    setFilterDraftState,
  ])

  const {
    data: ruleTypes,
  } = useGetRuleType()

  const ruleTypesObj = useMemo(() =>
      (ruleTypes?.values || []).reduce((acc, item) => ({ ...acc, [item.value]: item.title }), {}),
    [ruleTypes],
  )

  const handleGetRuleTypeTitle = useCallback((type: string) => {
    return ruleTypesObj[type]
  }, [ruleTypesObj])


  const {
    isFetching: getPackagingsIsLoading,
    refetch: refetchPackagings,
    data: packagingsData,
  } = useGetPackagingsSettings(filterParams, {
    onSuccess: (data) => {
      if (data.success) {
        setSettingsState(data.settings)
      }
    },
  })

  const {
    isLoading: saveLoading,
    mutateAsync: saveMutateAsync,
  } = useSetPackagingsSettings()


  const handleUpdateChangeState = useCallback((newSettingData: PackagingSettingsDto) => {
    setChangeState(prev => {
      const result = Object.values({
        ...prev,
        [newSettingData.packaging_id]: newSettingData,
      }).filter(item => Boolean(item.type))
      return result.reduce((acc, item) => {
        return { ...acc, [item.packaging_id]: item }
      }, {})
    })
    handleResetMassPackagingTypeState()
  }, [setChangeState, handleResetMassPackagingTypeState])

  const getDataForSave = (): SetNewPackagingSettingsFormParams[] => {
    if (rowsSelectionsIds?.length && massPackagingType) {
      return rowsSelectionsIds
        .map((row) => ({ ...filterParams, packaging_id: row.original.id, type: massPackagingType }))
    }
    return Object
      .values(changeState)
      .filter(item => Boolean(item.type))
      .map(item => ({ ...filterParams, ...item }))
  }

  const handleSaveState = () => {
    let dataForSave = getDataForSave()

    saveMutateAsync({
      data: dataForSave,
    })
      .then(async (data) => {
        if (data.success) {
          await refetchPackagings()
          handleResetState()
          handleResetSelectionState()
          handleResetMassPackagingTypeState()
        }
      })
      .catch(console.error)
  }

  const { rows, columns } = useMemo(() => {
    return generateTableData({
      settings: settingsState,
      settingsOverride: packagingsData?.settings_override,
      skus: packagingsData?.skus,
      changeState,
      handleUpdateChangeState,
      setOverrideItems,
      handleGetRuleTypeTitle,
    })
  }, [
    setOverrideItems,
    rowsSelectionsIds,
    setRowsSelectionsIds,
    settingsState,
    packagingsData,
    changeState,
    handleUpdateChangeState,
  ])

  return (
    <Grid gap={'s'}>
      <GridItem>
        <SectionTitle title={title} />
      </GridItem>

      <Grid cols={5} gap={'s'}>
        <FilterSection
          data={filterDraftState}
          handleChange={handleChangeDraftFilter}
        />
        <GridItem col={5} />
        <GridItem col={5}>
          <Flex justifyContent={'between'}>
            <Flex gap={'l'}>
              <Button
                size={'s'}
                view={'ghost'}
                label={'Сбросить'}
                onClick={handleClickResetFilter}
              />
              <Button
                size={'s'}
                label={'Искать'}
                iconLeft={IconSearchStroked}
                onClick={handleClickSearchByFilter}
              />
            </Flex>
            <Flex gap={'l'} style={{ opacity: Number(stateWasChange), transition: 'opacity .2s' }}>
              <Button
                size={'s'}
                view={'ghost'}
                label={'Отменить изменения'}
                onClick={handleResetState}
              />
              <Button
                size={'s'}
                label={'Сохранить изменения'}
                onClick={handleSaveState}
              />
            </Flex>
          </Flex>
        </GridItem>
        <GridItem col={5} />
        <GridItem col={2} hidden={filterStateIsEmpty && !rowsSelectionsIds?.length}>
          <Flex
            gap={'xs'}
            direction={'column'}
          >
            <Text as={'h4'} size={'m'} children={'Массовое переопределение'} />
            <Text as={'span'} size={'xs'} view={'secondary'}
                  children={`Выбрано ${rowsSelectionsIds.length} из ${rows.length}`} />
            <PackagingSettingTypeCombobox
              value={null}
              disabled={!rowsSelectionsIds?.length}
              valueId={massPackagingType}
              onChange={(value) => {
                setMassPackagingType(value?.id || '')
                setChangeState({})
              }}
              size={'s'}
            />
          </Flex>
        </GridItem>
      </Grid>

      <DirectoriesTable
        newDesign
        withRowSelection
        setSelectedRows={setRowsSelectionsIds}
        withCopy={false}

        isLoading={getPackagingsIsLoading || saveLoading}
        /* @ts-ignore */
        columns={columns}
        rows={rows}
        stickyHeader
        maxHeight={1000}
      />

      <OverrideSettingsModal
        overrideItems={overrideItems}
        setOverrideItems={setOverrideItems}
        handleGetRuleTypeTitle={handleGetRuleTypeTitle}
        setFilterState={setFilterState}
        setFilterDraftState={setFilterDraftState}
      />
    </Grid>
  )
}

type OverrideModalProps = {
  overrideItems: PackagingSettingsOverrideDto[] | null
  setOverrideItems: React.Dispatch<React.SetStateAction<PackagingSettingsOverrideDto[] | null>>
  handleGetRuleTypeTitle: (type: string) => string
  setFilterState: React.Dispatch<React.SetStateAction<Record<string, ComboboxItemDefault>>>
  setFilterDraftState: React.Dispatch<React.SetStateAction<Record<string, ComboboxItemDefault>>>
}
const OverrideSettingsModal = (props: OverrideModalProps) => {
  const {
    overrideItems,
    setOverrideItems,
    handleGetRuleTypeTitle,
    setFilterState,
    setFilterDraftState,
  } = props

  const rows = useMemo(() => (overrideItems || [])
    .map((item, i) => ({
      meta: {
        theme: PackagingSettingsColors[item.type] as any,
      },
      id: `${i}`,
      type: handleGetRuleTypeTitle(item.type),
      purpose: item.purpose?.title || '',
      merchant: item.merchant?.title || '',
      delivery_company: item.delivery_company?.title || '',
      delivery_company_product: item.delivery_company_product?.title || '',
      sku: item.sku?.title || '',
      item,
    })),
    [handleGetRuleTypeTitle, overrideItems]
  )
  const columnSize = 165
  const columns: TableColumnType<typeof rows[0]>[] = useMemo(() => [
    {
      id: 'type',
      accessorKey: 'type',
      enableHiding: false,
      enableResizing: false,
      header: 'Тип настройки',
      size: columnSize,
      meta: {
        headerText: 'Тип настройки',
      },
    },
    {
      id: 'purpose',
      accessorKey: 'purpose',
      enableHiding: false,
      enableResizing: false,
      header: 'Назначение',
      size: columnSize,
      meta: {
        headerText: 'Назначение',
      },
    },
    {
      id: 'merchant',
      accessorKey: 'merchant',
      enableHiding: false,
      enableResizing: false,
      header: 'Организация',
      size: columnSize,
      meta: {
        headerText: 'Организация',
      },
    },
    {
      id: 'delivery_company',
      accessorKey: 'delivery_company',
      enableHiding: false,
      enableResizing: false,
      header: 'Служба доставки',
      size: columnSize,
      meta: {
        headerText: 'Служба доставки',
      },
    },
    {
      id: 'delivery_company_product',
      accessorKey: 'delivery_company_product',
      enableHiding: false,
      enableResizing: false,
      header: 'Код службы доставки',
      size: columnSize,
      meta: {
        headerText: 'Код службы доставки',
      },
    },
    {
      id: 'sku',
      accessorKey: 'sku',
      enableHiding: false,
      enableResizing: false,
      header: 'Номенклатура',
      size: columnSize,
      meta: {
        headerText: 'Номенклатура',
      },
    },
    {
      id: 'action',
      accessorKey: 'action',
      enableHiding: false,
      enableResizing: false,
      header: '',
      size: columnSize,
      meta: {
        headerText: '',
      },
      cell: ({ row: { original: rowOriginal } }) => (
        <Button
          size={'xs'}
          view={'ghost'}
          label={'Применить фильтр'}
          onClick={() => {
            const newFilterState: Record<string, ComboboxItemDefault> = {
              purpose: rowOriginal.item?.purpose ? {
                id: rowOriginal.item?.purpose?.id,
                label: rowOriginal.item?.purpose.title,
              } : undefined,
              merchant_id: rowOriginal.item?.merchant ? {
                id: rowOriginal.item?.merchant?.id,
                label: rowOriginal.item?.merchant.title,
              } : undefined,
              delivery_company: rowOriginal.item?.delivery_company ? {
                id: rowOriginal.item?.delivery_company?.id,
                label: rowOriginal.item?.delivery_company.title,
              } : undefined,
              delivery_company_product: rowOriginal.item?.delivery_company_product ? {
                id: rowOriginal.item?.delivery_company_product?.id,
                label: rowOriginal.item?.delivery_company_product.title,
              } : undefined,
              sku_id: rowOriginal.item?.sku ? { id: rowOriginal.item?.sku?.id, label: rowOriginal.item?.sku.title } : undefined,
            }
            setFilterState(newFilterState)
            setFilterDraftState(newFilterState)
            setOverrideItems(null)
          }}
        />
      ),
    },
  ], [
    setFilterState,
    setFilterDraftState,
    setOverrideItems,
  ])

  return (
    <Modal
      withClose
      isOpen={Boolean(overrideItems)}
      onOverlayClick={() => setOverrideItems(null)}
      onClose={() => setOverrideItems(null)}
      title={'Фильтр перезаписи'}
      subtitle={' '}
      size='l'
    >
       <TanStackTable
          withHeader={false}
          withCopy={false}
          id={`overrides`}
          pageId={'derictories'}
          maxHeight={'full'}
          data={rows}
          columns={columns}
       />
    </Modal>
  )
}

const FilterSection = (props: {
  readonly?: boolean,
  gridCol?: number,
  data: Record<string, ComboboxItemDefault>,
  handleChange?: (fieldId: string, value?: any) => void,
}) => {
  const {
    gridCol = 1,
    readonly,
    data,
    handleChange = () => {
    },
  } = props

  const purposeItems = useMemo(() => [
    { id: PackagingPurposes.orderPlace, label: 'Груз. место' },
    { id: PackagingPurposes.orderPlaceItem, label: 'Позиция груз. места' },
    { id: PackagingPurposes.physicalSet, label: 'Комплект' },
    { id: PackagingPurposes.physicalSetItem, label: 'Позиция комплекта' },
    { id: PackagingPurposes.receiptItem, label: 'Позиция приходного ордера' },
    { id: PackagingPurposes.returnPlaceItem, label: 'Позиция места возврата' },
    { id: PackagingPurposes.transportPlace, label: 'Транспортное место' },
  ], [])

  const purposeValue = useMemo(() => purposeItems.find(item => item.id === data?.purpose?.id), [data?.purpose, purposeItems])

  return (
    <>
      <GridItem col={gridCol}>
        {!readonly || (readonly && purposeValue) ? (
          <ComboboxWrapper
            readOnly={readonly}
            isMultiple={false}
            label={'Назначение'}
            value={purposeValue}
            size={'s'}
            onChange={(value) => {
              handleChange('purpose', value)
            }}
            items={purposeItems}
          />
        ) : null}
      </GridItem>
      <GridItem col={gridCol}>
        {!readonly || (readonly && data?.merchant_id) ? (
          <ComboboxMerchants
            readOnly={readonly}
            isMultiple={false}
            value={data?.merchant_id}
            onChange={(value) => {
              handleChange('merchant_id', value)
            }}
            size='s'
          />
        ) : null}
      </GridItem>
      <GridItem col={gridCol}>
        {!readonly || (readonly && data?.delivery_company) ? (
          <ComboboxDeliveryCompany
            readOnly={readonly}
            isMultiple={false}
            value={data?.delivery_company}
            size={'s'}
            onChange={(value) => {
              handleChange('delivery_company', value)
              if (!value) {
                handleChange('delivery_company_product', undefined)
              }
            }}
          />
        ) : null}
      </GridItem>
      <GridItem col={gridCol}>
        {!readonly || (readonly && data?.delivery_company_product) ? (
          <ComboboxDeliveryCompanyProduct
            readOnly={!data?.delivery_company || readonly}
            isMultiple={false}
            deliveryCompanyId={data?.delivery_company ? `${data?.delivery_company?.id}` : undefined}
            value={data?.delivery_company_product}
            size={'s'}
            onChange={(value) => {
              handleChange('delivery_company_product', value)
            }}
          />
        ) : null}
      </GridItem>
      <GridItem col={gridCol}>
        {!readonly || (readonly && data?.sku_id) ? (
          <ComboboxSkus
            readOnly={readonly}
            isMultiple={false}
            label={'Номенклатура'}
            value={data?.sku_id}
            onChange={(value) => {
              handleChange('sku_id', value)
            }}
            size='s'
          />
        ) : null}
      </GridItem>
    </>
  )
}

export default PackagingsSettings
