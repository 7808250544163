import React, { useState } from 'react'
import { InputWrapper } from '@/components'
import { Flex } from '@shared/ui/Flex'
import { Button } from '@shared/ui/btns/Button'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { CreateUserCompanyData, useCreateEditUserCompany, UserCompanyDto } from '@entities/UserCompany'
import { useNotifications } from '@shared/providers/NotificationProvider'
import { useDebounce } from '@shared/hooks'


type FormStateType = CreateUserCompanyData

interface CreateUserCompanyFormProps {
	company?: UserCompanyDto
	onCancel: () => void
	onSubmit: () => void
}

export const EditCreateUserCompanyForm = (props: CreateUserCompanyFormProps) => {
	const {
		company,
		onCancel,
		onSubmit
	} = props

	const companyId = company?.id

	const notification = useNotifications()

	const [formStateDebounced, formState, setFormState] = useDebounce<FormStateType>(
		company || null
	)

	const {isLoading, mutateAsync: createEditUserCompany} = useCreateEditUserCompany()

	const handleChange = (value: TextFieldPropValue) => {
		setFormState({ title: value })
	}
	const handleSubmit = () => {
		createEditUserCompany({companyId, data: formState})
			.then(() => {
				onSubmit()
				notification?.show('success', `Компания успешно ${companyId ? 'изменена' : 'создана'}`)
			})
			.catch(console.error)
	}
	return (
		<Flex direction={'column'} gap={'l'}>
			<InputWrapper
				id={'title'}
				autoFocus
				value={formState?.title}
				label={'Название'}
				placeholder={'Введите название компании'}
				handleChange={handleChange}
				size={'s'}
			/>

			<Flex justifyContent={'between'}>
				<Button
					label={'Отменить'}
					view={'ghost'}
					onClick={onCancel}
				/>
				<Button
					label={'Сохранить'}
					onClick={handleSubmit}
					loading={isLoading}
				/>
			</Flex>
		</Flex>
	);
};
