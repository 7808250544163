import { FieldRow, FieldType } from '@widgets/GeneratorFields'
import { ICombobox } from '@/interfaces'

export const serialNumbersStatus = {
  "IN_POOL": { label: "В пуле", id: "IN_POOL" },
  "PRINTED": { label: "Напечатан", id: "PRINTED" },
  "IN_STOCK": { label: "На складе", id: "IN_STOCK" },
  "OUT_OF_STOCK": { label: "Отгружен", id: "OUT_OF_STOCK" },
  "ERROR": { label: "Ошибка", id: "ERROR" },
}

export const generateFilterParams = (filterState: Record<string, any>) => {

  return {
    value: filterState['value'] || '',
    factory: filterState['factory'] || '',
    merchant_id: filterState['merchant_id']?.id || '',
    sku_id: filterState['sku_id']?.id || '',
    serial_number_type: filterState['serial_number_type']?.id || '',
    statuses: filterState['statuses']?.map((status: ICombobox) => status.id) || [],
  }
}

export const generateFilters = (): FieldRow[] => {
  return [
    {
      fields: [
        {
          type: FieldType.BOOLEAN,
          fieldProps: {
            id: 'factory',
            label: "Заводской номер",
            align: 'top'
          },
        },
        {
          type: FieldType.input,
          fieldProps: {
            id: 'value',
            label: 'Значение',
            placeholder: 'Поиск по значению',
            viewDescription: 'Значение',
          },
        },
        {
          type: FieldType.MERCHANTS,
          fieldProps: {
            id: 'merchant_id',
            label: 'Организация',
            isMultiple: false,
            viewDescription: 'Организация',
          },
        },
        {
          type: FieldType.SKU,
          fieldProps: {
            id: 'sku_id',
            label: 'Товар',
            viewDescription: 'Товар',
          },
        },
      ],
    },
    {
      fields: [
        {
          type: FieldType.combobox,
          fieldProps: {
            id: 'serial_number_type',
            label: 'Тип серийного номера',
            viewDescription: 'Тип',
            items: [
              { label: "Честный знак", id: "chestniy_znak" },
              { label: "IMEI номер", id: "imei" },
              { label: "Уникальный номер", id: "unique_number" },
            ]
          },
        },
        {
          type: FieldType.combobox,
          fieldProps: {
            id: 'statuses',
            label: 'Статус серийного номера',
            viewDescription: 'Статус',
            isMultiple: true,
            items: Object.values(serialNumbersStatus)
          },
        },
      ],
    },
  ]
}
