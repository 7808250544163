import React, { useEffect } from 'react'
import cx from 'classnames'
import { useNotifications } from '@shared/providers/NotificationProvider'
import styles from './template-wrapper.module.scss'

import { AppVersion } from '@widgets/AppVersion'

import { TemplatesEnum } from '@shared/types/templates'
import { PrinterProvider } from '@shared/providers/PrinterProvider'

interface TemplateWrapperProps {
  template?: TemplatesEnum
}

/* Todo подумать, нужен ли вообще этот темплейт или стоит его упразднить
   Есть single, scan и tsd
   tsd - отменяет наше подключение к принтеру, так как на tsd печати нет
   single и scan - это по сути обычные страницы, которые с печатью
*/
const TemplateWrapper = (
  props: React.PropsWithChildren<TemplateWrapperProps>,
) => {
  const { children, template } = props

  const isSingleTemplate = template === TemplatesEnum.single
  const isTSDTemplate = template === TemplatesEnum.TSD

  const notification = useNotifications()

  useEffect(() => {
    /**
     * todo better
     * Скрывает оповещение если в TSD режиме
     * */
    if (notification?.state?.qzStartError && isTSDTemplate) {
      notification?.clear('qzStartError')
    }
  }, [notification?.state?.qzStartError])

  return (
    <div className={cx(styles.wrapper, styles.tsd)}>
      {
        !isTSDTemplate
        ? <PrinterProvider>{children}</PrinterProvider>
        : children
      }
      {!isSingleTemplate ? <AppVersion className={styles.version} /> : null}
    </div>
  )
}

export default TemplateWrapper
