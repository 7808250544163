import type { FC } from 'react'
import React from 'react'
import type { SectionTitleProps } from './types'
import { Text } from '@shared/ui/Text'
import { Flex } from '@/newSrc/shared/ui/Flex'

const SectionTitle: FC<SectionTitleProps> = ({ title, children }) => {
  return (
    <Flex
      as={'section'}
      justifyContent={'between'}
      align={'center'}
    >
      <Text as={'h1'} size={'xl'} children={title}/>
      {children}
    </Flex>
  )
}

export default SectionTitle
