import React, { FC } from 'react'
import { useParams } from 'react-router-dom'

import type { ISection } from '../../types'
import Item from './pages/Item/Item'
import List from './pages/List/List'

import { IRouteParams } from '@pages/DirectoriesPage/types'
import { PageLayout } from '@pages/DirectoriesPage/components/PageLayout/PageLayout'


interface Props extends ISection{}

const Merchants: FC<Props> = (props) => {
  const {
    title = ''
  } = props
  const { type = 'list' } = useParams<IRouteParams>()

  return (
    <PageLayout
      title={title}
      listPage={<List />}
      formPage={<Item />}
    />
  )
}

export default Merchants
