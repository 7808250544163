import React from 'react';
import { Badge, BadgePropSize, BadgePropStatus } from '@consta/uikit/Badge'


interface ActivationBadgeProps {
	activeLabel?: string
	activeStatus?: BadgePropStatus
	disableLabel?: string
	disableStatus?: BadgePropStatus
	size?: BadgePropSize
	active: boolean
}

export const ActivationBadge = (props: ActivationBadgeProps) => {
  const {
    size = 's',
    active,
    activeLabel = 'Активен',
    activeStatus = 'normal',
    disableLabel = 'Скрыт',
    disableStatus = 'error',

  } = props

	return (
		<Badge
      size={size}
      status={active ? activeStatus : disableStatus}
      label={active ? activeLabel : disableLabel}
    />
	);
};
