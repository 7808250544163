import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import styles from './combobox-wrapper.module.scss'

import { Combobox, ComboboxProps } from '@consta/uikit/Combobox'
import { useDebounce } from '@shared/hooks'
import { useHistory } from 'react-router-dom'
import { IconOpenInNew } from '@consta/icons/IconOpenInNew'
import { Text } from '@shared/ui/Text'
import { Flex } from '@shared/ui/Flex'
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup'
import { ComboboxItemDefault } from '@consta/uikit/__internal__/src/components/Combobox/helpers'
import { Grid, GridItem } from '@consta/uikit/Grid'


type ChoiceType = {
  label: string;
  value: string
}
const choicesTypes: ChoiceType[] = [
  { label: 'Включ', value: 'include' },
  { label: 'Исключ', value: 'exclude' },
]


export type ApiSearchProps = {
  withApiSearch?: boolean
  handleApiSearch?: (value: string | null) => void
}

// Обработка добавления элемента
export type AddItemProps = {
  withCreateItem?: boolean
  createItemLabel?: string
  onCreate?: () => void
}
export type ComboboxWrapperProps<ITEM = ComboboxItemDefault> = Omit<ComboboxProps, 'onChange'> & {
  id?: string
  link?: string
  label?: string
  isMultiple?: boolean
  value: any | ITEM | null,
  onChange: (
    value: any | ITEM | null,
    exclude?: boolean,
    eventObj?: { e: React.SyntheticEvent },
  ) => void
  searchFunction?: (item: any, searchValue: string) => boolean
  loading?: boolean
  wrapperClassName?: string
  readOnly?: boolean
  isRequired?: boolean
  error?: string
  /* Добавляет переключалку с отрицанием */
  exclude?: boolean
  /* Значение для переключателя исключения */
  excludeValue?: boolean
} & ApiSearchProps & AddItemProps

const ADD_ITEM_ID = 'addNewItem'

const ComboboxWrapper = (props: ComboboxWrapperProps) => {
  const history = useHistory()
  const {
    link,
    id,
    label,
    items,
    value,
    onChange,
    isMultiple,
    handleApiSearch,
    renderItem,
    size,
    searchFunction,
    loading,
    placeholder,
    className,
    wrapperClassName,
    withApiSearch,
    groups,
    withCreateItem,
    onCreate,
    createItemLabel = '+ Добавить',
    disabled,
    readOnly,
    isRequired,
    error,
    searchValue,
    exclude,
    excludeValue,
    onDropdownOpen,
    status,
    caption
  } = props

  const [choiceValue, setChoiceValue] = useState<ChoiceType | null>(
    excludeValue !== undefined ? choicesTypes[Number(excludeValue)] : null
  )

  const [apiSearchDeb, apiSearch, setApiSearch] = useDebounce<string | null>(
    null, 500,
  )

  useEffect(() => {
    if (!exclude || !choiceValue) return
    onChange(value, choiceValue?.value === 'exclude')
  }, [choiceValue, exclude])


  useEffect(() => {
    if (!withApiSearch) {
      return
    }
    handleApiSearch?.(apiSearchDeb)
  }, [apiSearchDeb])

  const handleChangeInput = (value: string | null) => {
    setApiSearch(value)
  }

  const handleChangeItem = (data, e) => {
    if (data === null) {
      // При сбросе значения, сбрасываем поисковый текст, для обновления списка
      setApiSearch('')
    }
    if (withCreateItem && String(data?.id) === ADD_ITEM_ID) {
      onCreate?.()
      return
    }
    onChange(data, choiceValue?.value === 'exclude', e)
  }

  const correctItems =
    withCreateItem && !isMultiple
      ? [{ label: createItemLabel, id: ADD_ITEM_ID }, ...items]
      : items

  const hasLink = Boolean(value && link && (readOnly || disabled))
  return (
    <Flex
      direction={'column'}
      gap={'xs'}
      className={cx(styles.wrapper, { [styles.cursorPointer]: hasLink }, wrapperClassName)}
      onClick={() => {
        if (hasLink) {
          window.open(link, '_blank')
        }
      }}
    >
      {label
        ?
        <Grid cols={8} gap={'xs'} yAlign={'bottom'}>
          <GridItem col={exclude ? 5 : 8}>
            <Text
              size={size}
              as={'label'}
              view={'secondary'}
              className={cx({ [styles.requiredLabel]: isRequired })}
            >
              {label} {hasLink ? <IconOpenInNew size={size} view={'link'} /> : null}
            </Text>
          </GridItem>
          {
            exclude ? (
              <GridItem col={3}>
                <ChoiceGroup
                  className={styles.choiceGroup}
                  value={choiceValue ?? choicesTypes[0]}
                  items={choicesTypes}
                  getItemLabel={(item) => item.label}
                  onChange={(value) => {
                    setChoiceValue(value)
                  }}
                  name={'warehousePlaces'}
                  multiple={false}
                  size='xs'
                  view={'primary'}
                  width={'full'}
                />
              </GridItem>
            ) : null
          }
        </Grid>
        : null
      }
      <Combobox
        items={correctItems}
        value={
          (Array.isArray(value) && !value?.length) || !value
            ? null
            : value
        }
        onChange={handleChangeItem}
        multiple={isMultiple}
        renderItem={renderItem}
        size={size}
        status={status || (error ? 'alert' : undefined)}
        caption={caption}
        className={cx(styles.comboboxClassName, className)}
        searchFunction={searchFunction}
        searchValue={searchValue}
        onSearchValueChange={handleChangeInput}
        placeholder={placeholder}
        isLoading={loading}
        groups={groups}
        labelForCreate={'Добавить'}

        onCreate={onCreate}
        disabled={disabled || readOnly}
        required={isRequired}
        onDropdownOpen={onDropdownOpen}
        dropdownClassName={cx(styles.dropdown)}
      />
      {error ? <Text size={'s'} view={'alert'} children={error} /> : null}
    </Flex>
  )
}

export default ComboboxWrapper
