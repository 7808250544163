import api from '@shared/api'
import {
  ApiReqResult,
  ConditionEnum,
  IOrder,
  IPaginationFilter,
  OrderStatusEnum,
  PaginationResult,
} from 'src/interfaces'
import { useMutation, useQuery } from 'react-query'
import { UseQueryOptions } from 'react-query/types/react/types'
import { SortByType } from '@shared/types/sort'

export interface IGetOrderListFilter {
  address?: {
    search?: string
  }
  /**Время заказа, от*/
  date_from?: string
  /**Время заказа, до*/
  date_to?: string
  /**Способы доставки*/
  delivery_companies?: string[]
  /**Коды продуктов курьерских служб*/
  delivery_company_products?: string
  /**Идентификатор исполнителя*/
  executor_id?: string
  /**Внешние идентификаторы заказов*/
  external_ids?: string[]
  /**Идентификаторы заказов*/
  ids?: string[]
  /**Заказ в батче*/
  in_batch?: boolean
  /**CAM_ID продавцов*/
  merchant_cam_ids?: string[]
  /**Идентификаторы продавцов*/
  merchant_ids?: string[]
  /**Исключить идентификаторы продавцов*/
  exclude_merchant_ids?: string[]
  /**Логины продавцов*/
  merchant_logins?: string[]
  /**Строка поиска (ищет по номеру заказа)*/
  search?: string
  /**Время отгрузки, от*/
  shipping_date_from?: string
  /**Время отгрузки, до*/
  shipping_date_to?: string
  /**Состояние товара*/
  sku_condition?: ConditionEnum
  /**Статус заказа*/
  status?: OrderStatusEnum
  /**Кол-во товаров в заказе, от*/
  total_quantity_from?: number
  /** Кол-во товаров в заказе, до*/
  total_quantity_to?: number
  /** Идентификаторы складских зон */
  warehouse_places?: string[]

  has_inventory?: boolean
  without_reserve?: boolean
  has_transport_place?: boolean
  has_unblocked_stock?: boolean
}

const getOrderList = async (filter: object, page: number, sortBy?: SortByType[], without_pagination?: boolean) => {
  const result = await api.post(`/order/list`, {
    filter,
    sort_by: sortBy,
    page: 0,
    without_pagination
  })

  if (!result.data?.success) {
    throw result.data
  }
  return result.data?.orders || ([] as IOrder[])
}

export const useGetOrderList = () => ({
  fetch: (filter: object, page?: number, sortBy: SortByType[] = []) => getOrderList(filter, page, sortBy),
})

const getOrderListM = async (filter: object, page: number, sortBy?: SortByType[], without_pagination?: boolean) => {
  const result = await api.post(`/order/list`, {
    filter,
    sort_by: sortBy,
    page: page,
    without_pagination
  })

  if (!result.data?.success) {
    throw result.data
  }
  return result.data
}

export const useGetOrderListM = () => useMutation(
  async (args: { filter: object, page: number, sortBy?: SortByType[] }) => getOrderListM(args.filter, args.page, args.sortBy)
)

export interface IGetQueryOrderListResult extends ApiReqResult, PaginationResult {
  orders: IOrder[]
}
interface IParam extends IPaginationFilter {
  filter?: IGetOrderListFilter
  sort_by?: SortByType[],
  without_pagination?: boolean
}
export const useGetQueryOrderList = (
  params: IParam,
  options?: UseQueryOptions<any>,
) => {
  return useQuery<IGetQueryOrderListResult>(
    [`getOrderList`, params],
    async () => {
      const result = await api.post(`/order/list`, params)
      return result.data
    },
    {
      keepPreviousData: true,
      ...options,
    },
  )
}
