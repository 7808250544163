import React from 'react'

import styles from './GeneratorValuesPreview.module.scss'
import { Tag } from '@consta/uikit/Tag'
import { Button } from '@shared/ui/btns/Button'
import cx from 'classnames'
import { TextFieldPropSize } from '@consta/uikit/__internal__/src/components/TextField/TextField'

export interface ValuePreview {
  key: string
  label: string
  value: string
}

type ResetAllValues = (
  {
    withResetAll?: true
    onResetAllValues?: () => void
  }
  |
  {
    withResetAll?: false
    onResetAllValues?: never
  }
)

type GeneratorValuesPreviewProps = {
  valuesPreview: ValuePreview[]
  onResetValue?: (key: string) => void
  wrapperClassName?: string
  withResetItem?: boolean
  size?: TextFieldPropSize
} & ResetAllValues

const GeneratorValuesPreview = (props: GeneratorValuesPreviewProps) => {
  const {
    valuesPreview,
    onResetValue,
    onResetAllValues,
    wrapperClassName,
    withResetAll = true,
    withResetItem = true,
    size,
  } = props

  return (
    <div className={cx(styles.filtersView, wrapperClassName)}>
      {withResetAll && valuesPreview?.length ? (
        <Button
          label={'Сбросить все'}
          view={'clear'}
          size={size}
          onClick={onResetAllValues}
          className={styles.clearFilter}
        />
      ) : null}
      {valuesPreview.map((item) =>
        withResetItem ? (
          <Tag
            key={item.key}
            label={`${item.label}: ${item.value}`}
            mode={'cancel'}
            size={size}
            onCancel={() => onResetValue?.(item.key)}
          />
        ) : (
          <Tag
            key={item.key}
            label={`${item.label}: ${item.value}`}
            mode={'info'}
            size={size}
          />
        ),
      )}
    </div>
  )
}

export default GeneratorValuesPreview
