import { SETTINGS_LOCAL_STORAGE_NAME } from '@shared/const/common'
import { LocalStorage } from './localStorage'
import { ThemesEnum } from '@shared/providers/ThemeProvider/config/themes'

export type CommonTableSettingsType = {
  density?: {
    forAllTables?: string,
    byPage?: { [pageId: string]: string /* density value */ }, // по стр. например для всех отчетов
    byTable?: { [tableId: string]: string /* density value */ }, // по стр. например для всех таблиц
  }
  hiddenColumns?: {
    [tableId: string]: string[] /* columnsIds */
  }
}


/* LS который сохраняется и после выхода из системы */
class DefaultSettingsLS extends LocalStorage {
  get defaultBase() {
    return this.getData('defaultBase')
  }

  set defaultBase(b: string) {
    this.setData('defaultBase', b)
  }

  get defaultWorkplace() {
    return this.getData('defaultWorkPlace')
  }

  set defaultWorkplace(v: string) {
    this.setData('defaultWorkPlace', v)
  }

  get defaultArm() {
    return this.getData('defaultArm')
  }

  set defaultArm(v: string) {
    this.setData('defaultArm', v)
  }

  set newDesign(newDesign: boolean) {
    this.setData('newDesign', Number(newDesign))
  }
  get newDesign() {
    return this.getData('newDesign')
  }

  set theme(theme: ThemesEnum) {
    this.setData('theme', theme)
  }
  get theme() {
    return this.getData('theme')
  }

  set commonTableSettings(commonTableSettings: CommonTableSettingsType) {
    this.setData('commonTableSettings', commonTableSettings)
  }
  get commonTableSettings() {
    return this.getData('commonTableSettings') || {}
  }
}
export const defaultSettingsLS = new DefaultSettingsLS(SETTINGS_LOCAL_STORAGE_NAME)