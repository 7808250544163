import { useMutation } from 'react-query'

import api from '@shared/api'

import { ApiReqResult, IPaginationFilter } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'


interface Result extends ApiReqResult {
}
interface Params extends IPaginationFilter {
  merchant_id?: string
  enabled: boolean
  service_group_set_id: string
}

const useUpdateMerchant = (
  options?: UseMutationOptions<Result, unknown, Params>
) => {
  return useMutation<Result, unknown, Params>(
    async ({ merchant_id, ...restData }: Params) => {
      const result = await api.post(`/merchant${merchant_id ? `/${merchant_id}` : ''}`, restData)
      if (!result?.data?.success) throw result
      return result.data
    },
    options,
  )
}

export default useUpdateMerchant
