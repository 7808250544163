import { presetGpnDark, presetGpnDefault, presetGpnDisplay } from '@consta/uikit/Theme'
import { ValueOf } from '@shared/helpers'
import { IconMoon } from '@consta/icons/IconMoon'
import { IconSun } from '@consta/icons/IconSun'
import { IconLightningBolt } from '@consta/icons/IconLightningBolt'

export const Themes = {
  light: 'light',
  dark: 'dark',
  accent: 'accent',
} as const
export type ThemesEnum = ValueOf<typeof Themes>

export const ThemesMapper = {
  [Themes.light]: presetGpnDefault,
  [Themes.dark]: presetGpnDark,
  [Themes.accent]: presetGpnDisplay
}
export const ThemesIconsMapper = {
  [Themes.light]: IconSun,
  [Themes.dark]: IconMoon,
  [Themes.accent]: IconLightningBolt,
}
export const ThemesLabelsMapper = {
  [Themes.light]: 'Светлая',
  [Themes.dark]: 'Темная',
  [Themes.accent]: 'Акцентная',
}