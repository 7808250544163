import styles from './ConfirmModal.module.scss'
import { Button } from '@shared/ui/btns/Button'
import React from 'react'

import { EnterHandler, Modal } from 'src/components'
import { ModalProps } from 'src/interfaces'
import { IconTriangle } from '@shared/assets/icons/IconTriangle'

interface IProps extends ModalProps {
  onSubmit: () => void
}

const ConfirmModal = ({ isOpen, onClose, onSubmit }: IProps) => {
  return (
    <EnterHandler onEnter={onClose}>
      <Modal
        isOpen={isOpen}
        onOverlayClick={(): boolean => false}
        className={styles.modal}
        Icon={() => <IconTriangle theme={'orange'} />}
        imgClass={styles.img}
        title={'Удаление документа'}
        subtitle={'Вы действительно хотите удалить документ?'}
        size="min"
      >
        <div className={styles.btns}>
          <Button label={'Отмена'} view={'secondary'} onClick={onClose} />
          <Button label={'Удалить'} view={'ghost'} onClick={onSubmit} />
        </div>
      </Modal>
    </EnterHandler>
  )
}

export default ConfirmModal
