import React, { ComponentProps } from 'react';
import { Text as ConstaText, TextPropSize } from '@consta/uikit/Text'


export type TextPropSizeType = TextPropSize

export const Text = (props: ComponentProps<typeof ConstaText>) => {
	return (
		<ConstaText view={'primary'} {...props}/>
	);
};
