import { useMutation } from 'react-query'

import api from '@shared/api'

import { ApiReqResult } from 'src/interfaces'

// http://wms-test.kak2c.ru/warehouse-api/instructions/{instruction_id}

interface Result extends ApiReqResult {}
interface DeleteInstructionsParams {
  instructionId: string
}

const useDeleteInstructions = () => {
  return useMutation(async ({ instructionId }: DeleteInstructionsParams) => {
    const path = `/instructions/${instructionId}`
    const result = await api.delete(path)

    if (!result?.data?.success) {
      throw result
    }
    return result.data as Result
  })
}

export default useDeleteInstructions
