import { Button } from '@shared/ui/btns/Button'
import styles from './JsonField.module.scss'
import React, { useMemo, useState } from 'react'
import { Modal } from '@/components'
import cx from 'classnames'
import { GroupFieldForm } from '../GroupField/GroupFields'
import { CommonFieldProps, IField, IFieldValue } from '@shared/types/fields'

interface JsonFieldProps extends Omit<CommonFieldProps, 'onChange' | 'value'> {
  title: string,
  value: string
  dataId: number
  fields: IField[],
  tableDescription: Record<string, string>,
  creationAvailable?: boolean
  deletionAvailable?: boolean
  editMode?: boolean
  readonly?: boolean
  onSubmit: (value: string) => void
}


export const JsonField = (props: JsonFieldProps) => {
  const {
    label,
    title,
    value,
    editMode,
    fields,
    readonly,
    onSubmit,
    isRequired,
  } = props


  const [formOpen, setFormOpen] = useState<boolean>(false)

  const handleCancel = () => {
    setFormOpen(false)
  }
  const handleSubmit = (data: IFieldValue[]) => {
    const valueObj = data.reduce((acc, item) => {
      return { ...acc, [item.id]: item.value }
    }, {})
    const resultValue = JSON.stringify(valueObj)
    onSubmit(resultValue)
    setFormOpen(false)
  }

  const fieldValues: IFieldValue[] = useMemo(() => {
    return Object
        .entries(JSON.parse(value))
        .map((item) => ({ id: Number(item[0]), value: String(item[1]) }))
    },[value])

  return (
    <>
      {label &&
        <label className={cx(styles.label, { [styles.requiredLabel]: isRequired })}>
          {label}
        </label>
      }
      <Button
        view={'ghost'}
        size={'s'}
        label={'Открыть форму'}
        onClick={() => setFormOpen(true)}
      />
      {
        formOpen ? (
          <Modal
            isOpen
            withClose
            hasOverlay
            size={'min'}
            onClickOutside={() => setFormOpen(false)}
            onClose={() => setFormOpen(false)}
            title={`Форма ${title}`}
            subtitle={' '}
          >
            <GroupFieldForm
              editMode={editMode}
              readonly={readonly}
              handleCancel={handleCancel}
              handleSubmit={handleSubmit}
              fields={fields}
              fieldValues={fieldValues}
            />
          </Modal>
        ) : null
      }
    </>
  )
}