import { TableColumn } from '@consta/uikit/Table'
import { ISku, SkuSerialNumberDto } from '@/interfaces'
import React from 'react'
import { NomenclatureCell, TitleCell } from '@pages/OperationsAndDocumentsPage/components'
import { serialNumbersStatus } from './filters'
import { SECTIONS, tabs } from '@pages/DirectoriesPage/constants'
import { generateDirectoriesPath } from '@shared/routing/config/paths'

export const generateTableData = (
  serialNumbers: SkuSerialNumberDto[],
  skus: ISku[]
) => {
  const rows = serialNumbers.map((item) => {
    const sku = skus.find((sku) => sku.id === item.sku_id)
    const status = serialNumbersStatus[item.status]

    return {
      id: item.value,
      value: item.value,
      code: item.code,
      skuName: sku.title,
      status: status?.label || '-',
      sku: sku,
      __custom_hrefLink: generateDirectoriesPath({
        page: tabs.guides.value,
        section: SECTIONS.nomenclature.value,
        type: 'item',
        id: sku.id || '',
      })
    }
  })

  const columns: TableColumn<(typeof rows)[number]>[] = [
    {
      title: 'Номенклатура',
      accessor: 'skuName',
      width: 300,
      renderCell: (row: any) => {
        return (
          <NomenclatureCell
            link={row.original.__custom_hrefLink}
            title={(
              <TitleCell
                title={row.original.sku?.title || ''}
                article={row.original.sku?.article || ''}
                titleSettings={{
                  view: 'link'
                }}
                articleSettings={{
                  view: 'link'
                }}
              />
            )}
            itemId={row.original.sku?.id || '-'}
          />
        )
      }
    },
    {
      title: 'Тип',
      accessor: 'code',
      width: 300,
    },
    {
      title: 'Статус',
      accessor: 'status',
      width: 300,
    },
    {
      title: 'Значение',
      accessor: 'value',
      width: 300,
      renderCell: (row: any) => {
        return <p style={{ maxWidth: '700px', wordWrap: 'break-word' }}>{row.original.value}</p>
      }
    },
  ]
  return { rows, columns }
}
