import React, { FC, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { IRouteParams } from '@pages/DirectoriesPage/types'
import { useGetItems } from '@/hooks'
import { useFilterState } from '@shared/hooks'
import { generateFilterParams, generateFilters } from './utils/filter'
import { SECTIONS } from '../../../../constants'
import { GeneratorFields } from '@widgets/GeneratorFields'
import { generateTableData } from './utils/table'
import DirectoriesTable from '@pages/DirectoriesPage/components/DirectoriesTable'

interface ListProps {
  
}

const List: FC<ListProps> = () => {
  const { page = '', section = SECTIONS.nomenclature.value } = useParams<IRouteParams>()

  const [pagination, setPagination] = useState(0)

  const {
    filterStateDebounced,
    filterState,
    handleChangeFilter
  } = useFilterState({
    withoutHistory: true,
    onChangeFilterState: () => setPagination(0)
  })

  const {
    isFetching: isFetchingSkus,
    data: skusData,
  } = useGetItems(
    { ...generateFilterParams({ ...filterStateDebounced } || {}), page: pagination })

  const filtersConfig = generateFilters()

   const {rows, columns} = useMemo(() => {
    return generateTableData(skusData?.skus || [], page, section)
  }, [skusData?.skus])

  return (
    <>
      <GeneratorFields
        size={'s'}
        visible
        fieldsConfig={filtersConfig}
        valuesState={filterState || {}}
        onChangeField={handleChangeFilter}
      />

      <DirectoriesTable
        newDesign
        withNumeric={true}
        withCopy
        isLoading={isFetchingSkus}
        columns={columns}
        rows={rows}
        stickyHeader
        pagination={skusData?.pagination}
        onChangePage={setPagination}
      />
    </>
  )
}

List.displayName = 'List'

export default List
