import { IconCalendar } from '@consta/icons/IconCalendar'
import { boolSelectItems, FieldRow, FieldType } from '@widgets/GeneratorFields'
import { BATCH_STATUSES_DESCRIPTION } from '@/interfaces/statuses'
import { IDeliveryItem } from 'src/interfaces'
import { generateStatusesOptions } from '@shared/helpers'

export const generateFilters = (
  deliveries: IDeliveryItem[],
): FieldRow[] => [
  {
    hidden: true,
    fields: [
      {
        type: 'input',
        fieldProps: {
          id: 'search',
          viewDescription: 'Поиск',
        },
      },
    ]
  },
  {
    fields: [
      {
        jointFields: [
          {
            type: 'calendar',
            fieldProps: {
              id: 'from',
              label: 'Дата',
              placeholder: 'C',
              form: 'defaultBrick',
              rightSide: IconCalendar,
              viewDescription: 'С',
            },
          },
          {
            type: 'calendar',
            fieldProps: {
              id: 'to',
              placeholder: 'По',
              form: 'clearDefault',
              rightSide: IconCalendar,
              viewDescription: 'По',
            },
          },
        ],
      },
      {
        type: 'combobox',
        fieldProps: {
          id: 'delivery_companies',
          isMultiple: true,
          label: 'Способы доставки',
          items: deliveries.map((delivery) => ({
            id: delivery.value,
            label: delivery.title,
            value: delivery.value,
          })),
          viewDescription: 'Способы доставки',
        },
      },
      {
        type: 'combobox',
        fieldProps: {
          id: 'status',
          label: 'Статус док-та',
          items: generateStatusesOptions(BATCH_STATUSES_DESCRIPTION),
          style: {
            maxWidth: '200px',
          },
          viewDescription: 'Статус док-та',
        },
      },
    ],
  },
  {
    fields: [
      {
        type: FieldType.MERCHANTS,
        fieldProps: {
          id: 'merchant',
          isMultiple: true,
          label: 'Организация',
          viewDescription: 'Организация',
        },
      },
      {
        jointFields: [
          {
            type: 'calendar',
            fieldProps: {
              id: 'picking_printed_from',
              type:'date-time',
              label: 'Дата печати подбора',
              placeholder: 'C',
              form: 'defaultBrick',
              rightSide: IconCalendar,
              viewDescription: 'С',
            },
          },
          {
            type: 'calendar',
            fieldProps: {
              id: 'picking_printed_to',
              type:'date-time',
              placeholder: 'По',
              form: 'clearDefault',
              rightSide: IconCalendar,
              viewDescription: 'По',
            },
          },
        ],
      },
      {
        type: FieldType.BOOLEAN,
        fieldProps: {
          id: 'picking_printed',
          label: 'Подбор распечатан',
          viewDescription: 'Подбор распечатан',
        },
      },
    ],
  },
]