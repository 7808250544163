import React, { useEffect } from 'react'

import styles from './main.module.scss'

import { renderPrivateRoutes, renderPublicRoutes } from '@shared/routing'

import { useAppContext } from '@shared/providers/AppContextProvider'
import { useNotifications } from '@shared/providers/NotificationProvider'
import packageInfo from '../../../package.json'
import FragileInformer from '../pages/OrderPacking/PackingV2/components/FragileInformer'

interface UpdateMessageProps {
  actualVersion: string
}

const UpdateMessage = (props: UpdateMessageProps) => {
  const { actualVersion } = props

  const handleUpdate = async () => {
    if ('caches' in window) {
      const cacheKeys = await window.caches.keys()
      await Promise.all(
        cacheKeys.map((key) => {
          window.caches.delete(key)
        }),
      )
      window.location.reload()
    } else {
      // todo поправить (скрыл ошибку при обновлении консты)
      //@ts-ignore
      window?.location?.reload(true)
    }
  }

  return (
    <div className={styles.updateMessage}>
      {`Доступна новая версия приложения ${actualVersion}`}
      <span className={styles.updateButton} onClick={handleUpdate}>
        Обновить
      </span>
    </div>
  )
}

interface MainProps {
  authorized: boolean
}

const Main = (props: MainProps) => {
  const { authorized } = props

  const notification = useNotifications()

  const checkVersion = () =>
    fetch(`${window.location.protocol}//${window.location.host}/version.json`, {
      cache: 'no-store',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(async (res) => {
        const data = await res.json()
        const actualVersion = data.version
        if (actualVersion) {
          if (actualVersion !== packageInfo.version) {
            notification?.show(
              'warning',
              <UpdateMessage actualVersion={actualVersion} />,
              {
                id: 'newVersionIsAvailable',
                persistent: true,
                allWidth: true,
              },
            )
          }
        }
      })
      .catch(() => {
        //TODO catch
      })

  useEffect(() => {
    let interval: any
    ;(async () => {
      await checkVersion()
      interval = setInterval(async () => {
        await checkVersion()
      }, 60000)
    })()
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  return (
    <>
      {authorized ? <>{renderPrivateRoutes()}</> : <>{renderPublicRoutes()}</>}
    </>
  )
}

export default Main
