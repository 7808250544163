import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import type { ISection } from '../../types'
import { useGetDeliveryContractsList } from '../../../../../hooks/warehouse/delivery'
import { useFilterState } from '@shared/hooks'
import { generateFilterParams } from './utils/filters'
import { generateTableData } from './utils/table'
import { DirectoriesLayout } from '@pages/DirectoriesPage/components/DirectoriesLayout/DirectoriesLayout'

const DeliveryContracts: FC<ISection> = ({
  className = '',
  title = '',
}) => {


  const {
    filterStateDebounced,
    filterState,
    handleChangeFilter
  }  = useFilterState({withoutHistory: true, onChangeFilterState: () => {
    setPagination(0)
  }})
  const [pagination, setPagination] = useState(0)

  const {
    data: deliveryData,
    isLoading: isFetchingDelivery,
  } = useGetDeliveryContractsList(
    generateFilterParams({ ...filterStateDebounced, page: pagination } || {}),
  )
  const deliveryContracts = deliveryData?.delivery_contracts

  const { rows, columns } = useMemo(() => generateTableData(deliveryContracts || []), [deliveryContracts]);


  return (
    <DirectoriesLayout
      withTable
      withFilter
      withSearch

      title={title}

      // filtersConfig={filtersConfig}
      filterState={filterState}
      handleChangeFilter={handleChangeFilter}

      isLoading={isFetchingDelivery}
      columns={columns}
      rows={rows}
      pagination={deliveryData?.pagination}
      onChangePage={setPagination}
    />
  )
}
export default DeliveryContracts
