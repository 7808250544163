import React, { useEffect, useState } from 'react'
import styles from './QubyModal.module.scss'
import { CalendarInput, ComboboxWrapper, InputWrapper, Modal } from 'src/components'
import { IOrder, IPallet, ModalProps } from 'src/interfaces'
import { Button } from '@shared/ui/btns/Button'
import { ComboboxItemDefault } from '@consta/uikit/Combobox'
import days from 'dayjs'
import { IFields, IFieldsRequired } from './types'
import { IconCalendar } from '@consta/icons/IconCalendar'
import { ValidateErrors } from '@pages/DirectoriesPage/types'
import { getDimensionStr } from '@shared/helpers'
import { TIME_FORMAT } from '@shared/const/date'

import { ICarryerQubyItem, ITransportaion } from 'src/interfaces/transportationTemplates'
import { IPacking } from 'src/interfaces/packing'
import { useUpdateTransportationPositions } from 'src/hooks/carryerQuby'
import {
  convertTimeTofullDate,
} from '@pages/OperationsAndDocumentsPage/pages/TransportationTemplates/TemplatesModal/utils'
import { ComboboxOrders } from '@entities/Order'
import { ComboboxTransportPlaces } from '@entities/TransportPlace'

interface IProps extends ModalProps {
  refetch: () => void
  templates: ITransportaion[]
  transportPlaces: IPacking[]
  orders: IOrder[]
  docId: string
  itemId: string | null
  items: ICarryerQubyItem[]
}

const QubyModal = (props: IProps) => {
  const {
    isOpen,
    onClose,
    refetch = () => {
    },
    templates = [],
    transportPlaces = [],
    orders = [],
    itemId,
    docId,
    items = [],
  } = props

  const [templatesList, setTemplatesList] = useState<ComboboxItemDefault[]>([])
  const [returned, setReturned] = useState(false)
  const [template, setTemplate] = useState<ComboboxItemDefault | null>(null)
  const [shipmentList, setShipmentList] = useState<ComboboxItemDefault[]>([])

  const [orderType, setOrderType] = useState<ComboboxItemDefault | null>(null)
  const [transportPlaceType, setTransportPlaceType] = useState<ComboboxItemDefault | null>(null)

  const [fieldValues, setFieldValues] = useState<IFields>({
    deliveryFrom: null,
    deliveryTo: null,
    adress: null,
    pickupFrom: null,
    pickupTo: null,
    phone: '',
    quantity: '',
    depth: "",
    height: "",
    width: "",
    contractorTitle: '',
    type: null,
  })

  const [fieldValuesRequired, setFieldValuesRequired] =
    useState<IFieldsRequired>({
      order_prefix: '',
      transportation_type: null,
    })
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({
    transportation_type: '',
    order_prefix: '',
  })
  const shipment = [
    { label: 'Заказ на отгрузку', id: 'ORDER' },
    { label: 'Транспортное место', id: 'TRANSPORT_PLACE' },
  ]

  const typeTransitionList = [
    { label: 'Отгрузка', id: 'SHIPMENT' },
    { label: 'Перемещение', id: 'MOVING_TO' },
    { label: 'Возврат', id: 'RETURN' },
  ]
  const typeList = [
    { label: 'Короб', id: 'BOX' },
    { label: 'Палета', id: 'PALLET' },
  ]
  const { mutateAsync: createEditQubyDoc, isLoading: isQubyDoc } =
    useUpdateTransportationPositions()

  useEffect(() => {
    setTemplatesList(
      templates.map((el) => ({ label: el.title || '', id: el.id || '' })),
    )
  }, [templates])

  useEffect(() => {
    if (!itemId) {
      return
    }
    const item = items.find((el) => el.id === itemId)
    const transportation_type = typeTransitionList.find(
      (el) => el.id === item?.transportation_type,
    )

    const measure = {
      width: item?.place?.dimensions?.width || 0,
      height: item?.place?.dimensions?.height || 0,
      depth: item?.place?.dimensions?.depth || 0,
    }
    const convert = getDimensionStr(measure, { from: 'mm', to: 'sm' }).split(
      'x',
    )

    const type = typeList.find((el) => el.id === item?.place.type)
    setFieldValuesRequired({
      order_prefix: item?.order_prefix || '',
      transportation_type: transportation_type || null,
    })

    const adress = {
      area: item?.external_warehouse_address?.area || '',
      block: item?.external_warehouse_address?.block || '',
      building: item?.external_warehouse_address?.building || '',
      city: item?.external_warehouse_address?.city || '',
      flat: item?.external_warehouse_address?.flat || '',
      floor: item?.external_warehouse_address?.floor || '',
      house: item?.external_warehouse_address?.house || '',
      region: item?.external_warehouse_address?.region || '',
      settlement: item?.external_warehouse_address?.settlement || '',
      street: item?.external_warehouse_address?.street || '',
      zip: item?.external_warehouse_address?.zip || '',
    }
    const orderType: ComboboxItemDefault & {dto: IOrder} | null = item?.order?.id
      ? {id: item.order.id, label: item.order.id, dto: item.order}
      : null
    const transportPlaceType: ComboboxItemDefault & {dto: IPallet} | null = item?.transport_place?.id
      ? {id: item.transport_place.id, label: item.transport_place.id, dto: item.transport_place}
      : null

    setOrderType(orderType)
    setTransportPlaceType(transportPlaceType)
    setFieldValues({
      adress: adress,
      contractorTitle: item?.contractor?.title || '',
      quantity: String(item?.quantity),
      phone: item?.contractor?.phone || '',
      deliveryFrom: item?.delivery_from
        ? convertTimeTofullDate(item?.delivery_from)
        : null,
      deliveryTo: item?.delivery_to
        ? convertTimeTofullDate(item?.delivery_to)
        : null,
      pickupFrom: item?.pickup_from
        ? convertTimeTofullDate(item?.pickup_from)
        : null,
      width: convert[0],
      height: convert[1],
      depth: convert[2],
      pickupTo: item?.pickup_to ? convertTimeTofullDate(item?.pickup_to) : null,
      type: type || null,
    })
    if (['SHIPMENT','MOVING_TO','RETURN'].includes(item?.transportation_type)) {
      setReturned(false)
    }
  }, [])

  useEffect(() => {
    canSubmit()
  }, [fieldValuesRequired])

  const canSubmit = (): boolean => {
    const fieldErrorsObj = Object.keys(fieldErrors).reduce((acc, requiredKey) => {
      const value = fieldValuesRequired[requiredKey as keyof typeof fieldValuesRequired]
      return {
        ...acc,
        [requiredKey]: value ? '' : ValidateErrors.isEmpty
      }
    }, {})
    setFieldErrors(fieldErrorsObj)
    return Object.values(fieldErrorsObj).every(val => !val)
  }

  const handleSubmit = () => {
    const isValid = canSubmit()

    if (!isValid) {
      return
    }
    const dimensions = {
      width: Number(fieldValues.width),
      height: Number(fieldValues.height),
      depth: Number(fieldValues.depth),
    }
    const convert = getDimensionStr(dimensions, { from: 'sm', to: 'mm' }).split(
      'x',
    )

    const measure = {}
    if (+convert[0]) measure["width"] = +convert[0]
    if (+convert[1]) measure["height"] = +convert[1]
    if (+convert[2]) measure["depth"] = +convert[2]
    const dimensionsData = Object.keys(measure).length ? measure : undefined


    const adress = { ...fieldValues.adress, country: 'RU' }
    const data = {
      contractor: fieldValues.phone || fieldValues.contractorTitle
        ? { phone: fieldValues.phone || '', title: fieldValues.contractorTitle }
        : undefined,
      delivery_from: fieldValues.deliveryFrom
        ? days(fieldValues.deliveryFrom).format(TIME_FORMAT)
        : undefined,
      delivery_to: fieldValues.deliveryTo
        ? days(fieldValues.deliveryTo).format(TIME_FORMAT)
        : undefined,
      pickup_from: fieldValues.pickupFrom
        ? days(fieldValues.pickupFrom).format(TIME_FORMAT)
        : undefined,
      pickup_to: fieldValues.pickupTo
        ? days(fieldValues.pickupTo).format(TIME_FORMAT)
        : undefined,
      external_warehouse_address: adress,
      place: dimensionsData || fieldValues.type?.id ? {
        dimensions: dimensionsData,
        type: fieldValues.type?.id,
      } : undefined,
      order_id: orderType?.id,
      transport_place_id: transportPlaceType?.id,

      quantity: fieldValues.quantity
        ? Number(fieldValues.quantity)
        : undefined,
      transportation_type: fieldValuesRequired.transportation_type?.id
        ? String(fieldValuesRequired.transportation_type.id)
        : undefined,
      order_prefix: fieldValuesRequired.order_prefix,
    }

    createEditQubyDoc({ docId, itemId, data })
      .then(async () => {
        refetch()
      })
      .catch(() => {
      })
    onClose()
  }


  const fillingInTheTemplate = (id: string) => {
    const template = templates.find((el) => el.id === id)
    if (!template) {
      return
    }
    if (template.transportation_type === 'SHIPMENT') {
      setShipmentList(shipment)
      setReturned(false)
    }
    if (template.transportation_type === 'MOVING_TO') {
      setShipmentList(shipment.filter((el) => el.id === 'TRANSPORT_PLACE'))
      setReturned(false)
    }
    if (template.transportation_type === 'RETURN') {
      setReturned(true)
    }
     const measure = {
      width: template?.place?.dimensions?.width || 0,
      height: template?.place?.dimensions?.height || 0,
      depth: template?.place?.dimensions?.depth || 0,
    }
    const convert = getDimensionStr(measure, { from: 'mm', to: 'sm' }).split(
      'x',
    )
    const type = typeList.find((el) => el.id === template?.place?.type)
    setFieldValues({
      quantity: '',
      contractorTitle: template.contractor?.title,
      adress: template?.external_warehouse_address,
      phone: template.contractor?.phone,
      deliveryFrom: template?.delivery_from
        ? convertTimeTofullDate(template?.delivery_from)
        : null,
      deliveryTo: template?.delivery_to
        ? convertTimeTofullDate(template?.delivery_to)
        : null,
      pickupFrom: template?.pickup_from
        ? convertTimeTofullDate(template?.pickup_from)
        : null,
      pickupTo: template?.pickup_to
        ? convertTimeTofullDate(template?.pickup_to)
        : null,
      type: type || null,
      width: convert[0],
      height: convert[1],
      depth: convert[2],
    })
    const transportation_type = typeTransitionList.find(
      (el) => el.id === template?.transportation_type,
    )
    setFieldValuesRequired({
      order_prefix: template.order_prefix,
      transportation_type: transportation_type || null,
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={onClose}
      className={styles.modal}
      size='m'
      title={itemId ? 'Редактирование перевозки' : 'Создание перевозки'}
    >
      <div className={styles.formWrapper}>
        <div className={styles.inputsRow}>
          <div className={styles.inputCell}>
            <ComboboxWrapper
              items={templatesList}
              value={template}
              label={'Перевозки/шаблон'}
              size={'s'}
              placeholder='Выберите перевозки/шаблон'
              onChange={(value) => {
                if (!value) {
                  return
                }
                setTemplate(value)
                fillingInTheTemplate(String(value.id))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Контрагент'}
              value={fieldValues.contractorTitle}
              size={'s'}
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  contractorTitle: value || '',
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Телефон'}
              value={fieldValues.phone}
              size={'s'}
              isNumber
              handleChange={(value) => {
                if (value && value.length > 15) {
                  return
                }
                setFieldValues((prev) => ({
                  ...prev,
                  phone: value || '',
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <ComboboxWrapper
              className={styles.typeRow}
              items={typeTransitionList}
              value={fieldValuesRequired.transportation_type}
              label={'Тип перевозки'}
              placeholder='Выберите тип перевозки'
              size={'s'}
              onChange={(value) => {
                if (value?.id === 'SHIPMENT') {
                  setShipmentList(shipment)
                  setReturned(false)
                }
                if (value?.id === 'MOVING_TO') {
                  setShipmentList(
                    shipment.filter((el) => el.id === 'TRANSPORT_PLACE'),
                  )
                  setReturned(false)
                }
                if (value?.id === 'RETURN') {
                  setReturned(true)
                }
                setFieldValuesRequired({
                  ...fieldValuesRequired,
                  transportation_type: value,
                })
              }}
            />
            {!fieldValuesRequired.transportation_type ? (
              <div className={styles.errorMessage}>
                Поле не может быть пустым
              </div>
            ) : null}
          </div>
        </div>
        <span>
          Адрес{' '}
          {fieldValuesRequired.transportation_type?.id === 'RETURN'
            ? 'отправителя:'
            : 'получателя:'}
        </span>
        <div className={styles.inputsRow}>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Индекс'}
              value={fieldValues.adress?.zip}
              isNumber
              size={'s'}
              handleChange={(value) => {
                if (value && value?.length > 10) {
                  return
                }
                setFieldValues((prev) => ({
                  ...prev,
                  adress: { ...fieldValues.adress, zip: value || '' },
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Город'}
              value={fieldValues.adress?.city}
              size={'s'}
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  adress: { ...fieldValues.adress, city: value || '' },
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Область'}
              value={fieldValues.adress?.region}
              size={'s'}
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  adress: { ...fieldValues.adress, region: value || '' },
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Район в области'}
              value={fieldValues.adress?.area}
              size={'s'}
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  adress: { ...fieldValues.adress, area: value || '' },
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Населенный пункт'}
              value={fieldValues.adress?.settlement}
              size={'s'}
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  adress: { ...fieldValues.adress, settlement: value || '' },
                }))
              }}
            />
          </div>
        </div>
        <div className={styles.inputsRow}>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Улица'}
              value={fieldValues.adress?.street}
              size={'s'}
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  adress: { ...fieldValues.adress, street: value || '' },
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Дом'}
              value={fieldValues.adress?.house}
              size={'s'}
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  adress: { ...fieldValues.adress, house: value || '' },
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Корпус'}
              value={fieldValues.adress?.block}
              size={'s'}
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  adress: { ...fieldValues.adress, block: value || '' },
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Строение'}
              value={fieldValues.adress?.building}
              size={'s'}
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  adress: { ...fieldValues.adress, building: value || '' },
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Квартира'}
              value={fieldValues.adress?.flat}
              size={'s'}
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  adress: { ...fieldValues.adress, flat: value || '' },
                }))
              }}
            />
          </div>
        </div>
        <div className={styles.inputsRow}>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Этаж'}
              value={
                fieldValues.adress && fieldValues.adress.floor
                  ? String(fieldValues?.adress?.floor)
                  : ''
              }
              size={'s'}
              isNumber
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  adress: { ...fieldValues.adress, floor: Number(value) || '' },
                }))
              }}
            />
          </div>
        </div>

        <span>Значения по умолчанию:</span>
        <div className={styles.inputsRow}>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Наименование для выгрузки'}
              value={fieldValuesRequired.order_prefix}
              size={'s'}
              handleChange={(value) => {
                setFieldValuesRequired((prev) => ({
                  ...prev,
                  order_prefix: value || '',
                }))
              }}
            />
            <div className={styles.errorMessage}>
              {fieldErrors.order_prefix}
            </div>
          </div>

          <div className={styles.inputCell}>
            <InputWrapper
              label={'Кол-во'}
              value={`${fieldValues.quantity}`}
              isNumber
              size={'s'}
              handleChange={(value) => {
                if (value?.length === 6) {
                  return
                }
                setFieldValues((prev) => ({
                  ...prev,
                  quantity: Number(value),
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <ComboboxWrapper
              className={styles.typeRow}
              items={typeList}
              value={fieldValues.type}
              label={'Тип'}
              placeholder='Выберите тип'
              size={'s'}
              onChange={(value) => {
                setFieldValues({
                  ...fieldValues,
                  type: value ? { label: String(value?.label), id: String(value?.id) } : null,
                })
              }}
            />
          </div>
          {!returned ? (
            <>
              <div className={styles.inputCell}>
                <ComboboxOrders
                  size={'s'}
                  value={orderType}
                  onChange={(value) => setOrderType(value)}
                />
              </div>
              <div className={styles.inputCell}>
                <ComboboxTransportPlaces
                  size={'s'}
                  value={transportPlaceType}
                  onChange={(value) => setTransportPlaceType(value)}
                />
              </div>
            </>
            ) : null}
        </div>
        <div className={styles.inputsRow}>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Длина см.'}
              value={`${fieldValues.depth}`}
              size={'s'}
              isNumber
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  depth: Number(value) || '',
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Ширина см.'}
              value={`${fieldValues.width}`}
              size={'s'}
              isNumber
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  width: Number(value) || '',
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Высота см.'}
              value={`${fieldValues.height}`}
              size={'s'}
              isNumber
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  height: Number(value) || '',
                }))
              }}
            />
          </div>
        </div>
        <span>Время:</span>
        <div className={styles.inputsRow}>
          <div className={styles.inputCell}>
            <CalendarInput
              label='Слот доставки с'
              id={'deliveryFrom'}
              placeholder={'C'}
              handleChange={(value) =>
                setFieldValues({ ...fieldValues, deliveryFrom: value })
              }
              type={'time'}
              value={fieldValues.deliveryFrom}
              size='m'
              className={styles.dateInput}
              rightSide={IconCalendar}
              backspaceReset
            />
          </div>
          <div className={styles.inputCell}>
            <CalendarInput
              label='Слот доставки по'
              id={'deliveryTo'}
              placeholder={'По'}
              handleChange={(value) =>
                setFieldValues({ ...fieldValues, deliveryTo: value })
              }
              type={'time'}
              value={fieldValues.deliveryTo}
              size='m'
              className={styles.dateInput}
              rightSide={IconCalendar}
              backspaceReset
            />
          </div>
          <div className={styles.inputCell}>
            <CalendarInput
              label='Слот сбора с'
              id={'pickupFrom'}
              placeholder={'C'}
              handleChange={(value) =>
                setFieldValues({ ...fieldValues, pickupFrom: value })
              }
              type={'time'}
              value={fieldValues.pickupFrom}
              size='m'
              className={styles.dateInput}
              rightSide={IconCalendar}
              backspaceReset
            />
          </div>
          <div className={styles.inputCell}>
            <CalendarInput
              label='Слот сбора по'
              id={'pickupTo'}
              placeholder={'По'}
              handleChange={(value) =>
                setFieldValues({ ...fieldValues, pickupTo: value })
              }
              type={'time'}
              value={fieldValues.pickupTo}
              size='m'
              className={styles.dateInput}
              rightSide={IconCalendar}
              backspaceReset
            />
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <Button label='Отмена' view={'ghost'} onClick={onClose} />
        <Button label='Сохранить' view={'primary'} onClick={handleSubmit} />
      </div>
    </Modal>
  )
}
export default QubyModal
