import { ColorTypeEnum } from '@shared/types/generatorListTypes'

export interface ITemplateColumn {
  grouping_available?: boolean
  id: number
  sorting_available?: boolean
  hidden_by_default: boolean
  title: string
  type?: string
}

export enum TemplateParamType {
  text = 'TEXT',
  number = 'NUMBER',
  boolean = 'BOOLEAN',
  yearmonth = "YEARMONTH",
  date = 'DATE',
  datetime = 'DATETIME',
  select = 'SELECT',
  multiselect = 'MULTISELECT',
  skus = 'SKUS',
  orders = 'ORDERS',
  merchants = 'MERCHANTS',
  cells = 'CELLS',
  workspace = 'WORKSPACE',
  workspaces = 'WORKSPACES',
  warehousePlaces = 'WAREHOUSE_PLACES',
}

export interface ITemplateParam {
  id: number
  important?: boolean
  required?: boolean
  exclude_available?: boolean
  title: string
  type: TemplateParamType
  values?: {
    title: string
    value: string
  }[]
}

export interface IReportTemplate {
  id: string
  columns?: ITemplateColumn[], // в планах отказаться от этого дубликата, оставить только динамические столбцы, оставили из-за группировки
  params?: ITemplateParam[]
  title: string
  collapse_depth?: number
}

export interface IReportParam {
  id: number | string
  value?: string
  values?: string[]
  exclude?: boolean,
  meta?: any
}

export interface IReportCell {
  id: number
  value?: string | null
  color_type?: ColorTypeEnum,
  additional_data?: IAdditionalData
}
export interface IAdditionalData {
  id: number
  value: string
  columns: IAdditionalDataColumns[]
  rows: { cells: IReportCell[] }
}

export interface IAdditionalDataColumns {
  grouping_available: boolean
  hidden_by_default: boolean
  id: number
  sorting_available: boolean
  title: string
  type: string
}
export interface IReportRow {
  cells: IReportCell[]
  children?: IReportRow[]
}
