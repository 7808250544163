import { InputWrapperProps } from '@/components/InputWrapper'
import { CalendarInputProps } from '@/components/CalendarInput'
import { ComboboxWrapperProps } from '@/components/ComboboxWrapper'
import { ValueOf } from '@shared/helpers'
import { IComboboxWrapperWithApi } from '@/components/ComboboxWrapperWithApi/ComboboxWrapperWithApi'
import { SwitchWrapperProps } from '@/components/SwitchWrapper/SwitchWrapper'
import { IBatch, ISku } from '@/interfaces'
import { ComboboxItemDefault } from '@consta/uikit/Combobox'


export interface FieldRow {
  /**
   *  Скрывает строку из фильтров
   *  (обычно для расширения отображения фильтров типа поиска)
   */
  hidden?: boolean
  /** Вспомогательный флаг для распределения полей */
  flexSpaceBetween?: boolean
  /** Вспомогательный класс оборачивающий строки */
  extraClassName?: string
  /** Список полей (или слитых полей) */
  fields: GenerateFieldType[]
}

type ExtraPropsFieldsType = {
  /** Id поля используется как ключ для стейта */
  id: string
  /** Описание для отображения */
  viewDescription?: string
}

export interface MainField {
  /** Вспомогательный класс оборачивающий поле */
  fieldWrapperClassName?: string
}

export const FieldType = {
  URL: 'URL',
  input: 'input',
  textArea: 'text_area',
  calendar: 'calendar',
  combobox: 'combobox',
  select: 'select',
  BOOLEAN: 'boolean',
  SKU_BATCH: 'sku_batch',

  SKU: 'sku',
  SKUS: 'skus',
  TREE_CELL: 'tree_cell',
  TREE_CELLS: 'tree_cells',
  CELL: 'cell',
  CELLS: 'cells',
  MERCHANT: 'merchant',
  MERCHANTS: 'merchants',
  CONTRACTOR: 'contractor',
  CONTRACTORS: 'contractors',
  PLACE: 'WAREHOUSE_PLACE',
  PLACES: 'WAREHOUSE_PLACES',
  CONTAINER: 'container',

  DOCUMENT: 'DOCUMENT',

  BASE64: 'BASE64',
  TABLE: 'table',
  GROUP: 'GROUP',
  JSON: 'JSON',
  COMBINE_CELLS: 'COMBINE_CELLS',
} as const
export type FieldType = ValueOf<typeof FieldType>

interface NeverField {
  jointFields?: never
}
/**
 * Интерфейсы полей с разными пропсами под каждый тип поля
 * (по задумке должно быть либо { jointFields }, либо { type, fieldProps })
 */
export interface IJointFieldsProps extends MainField {
  type?: never
  fieldProps?: never
  /** Список слитых вместе полей (поля, которые стоят вместе без пропусков, например поля даты "C" "По") */
  jointFields?: GenerateFieldType[]
}

type FieldPropsType<T> = ExtraPropsFieldsType &
  Omit<T, 'value' | 'onChange'> & { onChange?: (...args: any[]) => void }

export interface IFieldBool extends MainField, NeverField {
  type?: typeof FieldType.BOOLEAN
  fieldProps?: FieldPropsType<SwitchWrapperProps>
}
export interface IFieldUrl extends MainField, NeverField {
  type?: typeof FieldType.URL
  fieldProps?: FieldPropsType<InputWrapperProps>
}
export interface IFieldInput extends MainField, NeverField {
  type?: typeof FieldType.input
  fieldProps?: FieldPropsType<InputWrapperProps>
}
export interface IFiledCalendar extends MainField, NeverField {
  type?: typeof FieldType.calendar
  fieldProps?: FieldPropsType<CalendarInputProps>
}
export interface IFieldCombobox extends MainField, NeverField {
  type?: typeof FieldType.combobox
  fieldProps?: FieldPropsType<ComboboxWrapperProps>
}
export interface IFieldTreeCombobox extends MainField, NeverField {
  type?: typeof FieldType.TREE_CELL
  fieldProps?: FieldPropsType<ComboboxWrapperProps>
}
export interface IFieldSkuBatchCombobox extends MainField, NeverField {
  type?: typeof FieldType.SKU_BATCH
  fieldProps?:
    FieldPropsType<ComboboxWrapperProps>
    & {
      sku: ISku,
      updateSkuBatches?: (value: ComboboxItemDefault & {dto?: IBatch}) => void
    }
}
export interface IFieldComboboxWithsApi extends MainField, NeverField {
  type?:
    | typeof FieldType.CONTRACTOR
    | typeof FieldType.CONTRACTORS
    | typeof FieldType.MERCHANT
    | typeof FieldType.SKU
    | typeof FieldType.CELL
    | typeof FieldType.MERCHANTS
    | typeof FieldType.SKUS
    | typeof FieldType.CELLS
    | typeof FieldType.DOCUMENT
  fieldProps?: FieldPropsType<Omit<IComboboxWrapperWithApi, "useGetHookSearch">>
}
export interface IFieldTable extends MainField, NeverField {
  type?: typeof FieldType.TABLE
  fieldProps?: any //TODO: ПОПРАВИТЬ
}
export interface IFieldGroup extends MainField, NeverField {
  type?: typeof FieldType.GROUP
  fieldProps?: any //TODO: ПОПРАВИТЬ
}

export interface IFieldJson extends MainField, NeverField {
  type?: typeof FieldType.JSON
  fieldProps?: any //TODO: ПОПРАВИТЬ
}

export interface IFieldCombineCells extends MainField, NeverField {
  type?: typeof FieldType.COMBINE_CELLS
  fieldProps?: any //TODO: ПОПРАВИТЬ
}

const checkType = (type: string, fieldType: FieldType) =>
  type?.toLowerCase() === fieldType.toLowerCase()
export const isUrl = (field: GenerateFieldType): field is IFieldUrl =>
  checkType(field?.type, FieldType.URL)
export const isBase64 = (field: GenerateFieldType): field is IFieldUrl =>
  checkType(field?.type, FieldType.BASE64)
export const isInput = (field: GenerateFieldType): field is IFieldInput =>
  checkType(field?.type, FieldType.input)
  || checkType(field?.type, FieldType.textArea)
export const isCalendar = (field: GenerateFieldType): field is IFiledCalendar =>
  checkType(field?.type, FieldType.calendar)
export const isCombobox = (field: GenerateFieldType): field is IFieldCombobox =>
  checkType(field?.type, FieldType.combobox)
export const isSkuBatch = (field: GenerateFieldType): field is IFieldSkuBatchCombobox =>
  checkType(field?.type, FieldType.SKU_BATCH)
export const isDocument = (field: GenerateFieldType): field is IFieldComboboxWithsApi =>
  checkType(field?.type, FieldType.DOCUMENT)
export const isBool = (field: GenerateFieldType): field is IFieldBool =>
  checkType(field?.type, FieldType.BOOLEAN)
export const isTable = (field: GenerateFieldType): field is IFieldTable =>
  checkType(field?.type, FieldType.TABLE)
export const isGroup = (field: GenerateFieldType): field is IFieldGroup =>
  checkType(field?.type, FieldType.GROUP)
export const isJson = (field: GenerateFieldType): field is IFieldGroup =>
  checkType(field?.type, FieldType.JSON)

export const isCombineCells = (field: GenerateFieldType): field is IFieldGroup =>
  checkType(field?.type, FieldType.COMBINE_CELLS)

export const isContractors = (field: GenerateFieldType): field is IFieldComboboxWithsApi =>
  checkType(field?.type, FieldType.CONTRACTOR)
  || checkType(field?.type, FieldType.CONTRACTORS)

export const isMerchants = (field: GenerateFieldType): field is IFieldComboboxWithsApi =>
  checkType(field?.type, FieldType.MERCHANT)
  || checkType(field?.type, FieldType.MERCHANTS)
export const isSku = (field: GenerateFieldType): field is IFieldComboboxWithsApi =>
  checkType(field?.type, FieldType.SKU)
  || checkType(field?.type, FieldType.SKUS)
export const isCell = (field: GenerateFieldType): field is IFieldComboboxWithsApi =>
  checkType(field?.type, FieldType.CELL)
  || checkType(field?.type, FieldType.CELLS)

export const isWarehousePlace = (field: GenerateFieldType): field is IFieldComboboxWithsApi =>
  checkType(field?.type, FieldType.PLACE)
  || checkType(field?.type, FieldType.PLACES)



export const isWarehouseContainer = (field: GenerateFieldType): field is IFieldComboboxWithsApi =>
  checkType(field?.type, FieldType.CONTAINER)


export type GenerateFieldType =
  | IFieldInput
  | IFieldUrl
  | IFiledCalendar
  | IFieldCombobox
  | IJointFieldsProps
  | IFieldSkuBatchCombobox
  | IFieldComboboxWithsApi
  | IFieldBool
  | IFieldTable
  | IFieldGroup
  | IFieldJson
  | IFieldCombineCells
