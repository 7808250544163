import React, { SVGProps } from 'react'
import { createIcon } from '@consta/icons/Icon'

const trianglesTheme = {
  blue: '#00a8ff',
  red: '#D8494E',
  orange: '#F38B00',
  green: '#33BA80',
}

export const IconTriangle = (props: SVGProps<SVGSVGElement> & { theme: keyof typeof trianglesTheme}) => {
  const {
    theme = 'orange',
    ...restProps
  } = props

  const color = trianglesTheme[theme]

  return (
    <svg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg' {...restProps}>
      <path
        d='M99.12 83.216L55.2469 10.4564C54.1381 8.61758 52.1473 7.49316 49.9999 7.49316C47.8524 7.49316 45.8616 8.61738 44.7528 10.4564L0.880129 83.216C-0.260886 85.1084 -0.294675 87.4688 0.792044 89.3928C1.87876 91.317 3.91724 92.5068 6.127 92.5068H93.8729C96.0827 92.5068 98.1211 91.3168 99.2079 89.3928C100.295 87.4686 100.261 85.1084 99.12 83.216Z'
        fill={color} />
      <path
        d='M47.315 34H53.1265C54.4734 34 55.5359 35.1454 55.4349 36.4886L53.4606 62.7235C53.3697 63.9312 52.3634 64.8646 51.1522 64.8646H49.2891C48.078 64.8646 47.0716 63.9311 46.9807 62.7235L45.0066 36.4886C44.9057 35.1454 45.9681 34 47.315 34Z'
        fill='white' />
      <path
        d='M45.5989 75.6596V75.675C45.5989 78.2277 47.6683 80.297 50.2209 80.297C52.7736 80.297 54.8429 78.2277 54.8429 75.675V75.6596C54.8429 73.1069 52.7736 71.0376 50.2209 71.0376C47.6683 71.0376 45.5989 73.1069 45.5989 75.6596Z'
        fill='white' />
    </svg>
  )
}
