import React, { useMemo, useState } from 'react'

import styles from './SelectDefectModal.module.scss'

import { InputWrapper, SelectModal } from 'src/components'
import { SkuItemPreview } from '@entities/Sku/ui/SkuItemPreview'
import { IDefectType, ISelectListOption, ItemPreviewType, ModalProps } from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'

interface ISelectDefectModal extends ModalProps {
  withComment?: boolean
  defectTypes: IDefectType[]
  item: ItemPreviewType | null
  onSubmit: (defectTypeId: string, defectComment?: TextFieldPropValue) => void
}

const SelectDefectModal = (props: ISelectDefectModal) => {
  const { withComment, isOpen, onSubmit, item, defectTypes, onClose } = props

  const [selectedItem, setSelectedItem] = useState<ISelectListOption | null>(null)
  const [comment, setComment] = useState<TextFieldPropValue>(null)

  const defectsOptions = useMemo(() => {
    return defectTypes.map(defect => ({
      id: defect.id,
      value: defect.id,
      label: defect.title,
    }))
  }, [defectTypes])

  const handleClickSubmit = () => {
    if (!selectedItem?.id) {
      console.error('Дефект не выбран')
      return
    }
    onSubmit(selectedItem.id, comment)
  }


  return (
    <SelectModal
      isOpen={isOpen}
      selectLabel={'Выберите дефект из списка'}
      options={defectsOptions}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      formTopSlot={
        item && (
          <div className={styles.top}>
            <SkuItemPreview
              image={item.image}
              title={item.title}
              barcode={item.barcode || ''}
              imgClassName={styles.img}
            />
          </div>
        )}
      formBottomSlot={
        withComment
          ?
          <InputWrapper
            autoFocus
            type={'textarea'}
            id={'comment'}
            minRows={3}
            maxRows={10}
            className={styles.inputComment}
            placeholder={''}
            value={comment}
            handleChange={setComment}
          />
          : null
      }
      onSubmit={handleClickSubmit}
      onClose={onClose}
    />
  )
}

export default SelectDefectModal
