import { useMutation } from 'react-query'
import api from '@shared/api'

import { ApiReqResult } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'
import { SetNewPackagingSettingsFormParams } from '@/interfaces/packaging'

interface Params {
  data: SetNewPackagingSettingsFormParams[]
}

interface Result extends ApiReqResult {

}

const useSetPackagingsSettings = (
  options?: UseMutationOptions<Result, any, Params>,
) => {
  return useMutation<Result, any, Params>(
    async (params: Params) => {
      const result = await api.post(`/packaging/setting`, params.data)
      if (!result.data?.success) {
        throw result
      }
      return result.data
    },
    options,
  )
}

export default useSetPackagingsSettings
