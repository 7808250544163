import { useMutation } from 'react-query'
import api from '@shared/api'

export interface IDataUpdateInstruction {
  always_show: boolean
  delivery_company?: string
  merchant_id?: string
  order_type?: string
  delivery_company_products?: string
  sku_categories: string[]
  title: string
  value: string
}
interface IProps {
  instructionId?: string
  data: IDataUpdateInstruction
}

// http://wms-test.kak2c.ru/warehouse-api/instructions/{instruction_id}

const useUpdateInstruction = () => {
  const updateInstruction = async ({ data, instructionId }: IProps) => {
    const result = await api.post(
      `/instructions${instructionId ? `/${instructionId}` : ''}`,
      data,
    )

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(updateInstruction)
}

export default useUpdateInstruction
