import React, { FC, useEffect, useMemo, useState } from 'react'
import styles from './documents-params.module.scss'
import { Button, InputWrapper, Modal } from 'src/components'
import { useParams } from 'react-router-dom'
import { OperAndDocumPagesType } from '../../../../../pagesType'
import {
  DocumentTypeEnum,
  IDeliveryContract,
  OrderStatusEnum,
  ShippingOptionsDescription,
  ShippingOptionsType,
} from 'src/interfaces'
import { DocumentsParams, DocumentStatusField } from '../../../../../components'
import { updateFormStateDraftAction } from '../../../../../store/actions'
import { generateOrderColumns, generateOrderRows } from './utils'
import { useOrderFormContext } from '../../../../../context/OrderFormContext'

import ModalAddress from '../ModalAddress/ModalAddress'

import { Checkbox } from '@consta/uikit/Checkbox'

import dayjs from 'dayjs'
import { DATE_TIME_VIEW, TIME_FORMAT } from '@shared/const/date'
import { ChangingOrderModal, ResetOrderModal } from '@entities/Order'
import usePackingOrderReset from '@/hooks/orderPackingV2/usePackingOrderReset'
import { useNotifications } from '@shared/providers/NotificationProvider'

interface SupplyFormContentProps {
  handleSave: () => void
  refetch?: () => void
   deliveryContracts?: IDeliveryContract[]
}

const DocumentsParamsFields: FC<SupplyFormContentProps> = (props) => {
  const {
    handleSave,
    refetch,
    deliveryContracts
  } = props

  const { page, id: documentId } = useParams<{
    page: OperAndDocumPagesType
    id: string
    tabId: string
  }>()

  const notification = useNotifications()
  const { state, dispatch } = useOrderFormContext()

  const { mutateAsync: packingOrderReset, isLoading: packingOrderResetLoading } = usePackingOrderReset()

  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const [openModalShipped, setOpenModalShipped] = useState(false)

  const [disableBilling, setDisableBilling] = useState(false)

  const [isAddressChangeVisible, setIsAddressChangeVisible] = useState(false)

  const orderRows = useMemo(
    () => generateOrderRows(state.documentRaw?.items || []),
    [state.documentRaw?.items],
  )
  const orderColumns = useMemo(() => generateOrderColumns(), [])

  const orderItemsQuantity = useMemo(
    () => orderRows.reduce((acc, item) => acc + Number(item.quantity), 0),
    [orderRows],
  )

  useEffect(() => {
    if (!state.formStateRaw?.disable_billing) {
      return
    }

    if (state.formStateRaw.disable_billing) {
      setDisableBilling(state?.formStateRaw?.disable_billing)
    }
  }, [state.formStateRaw])


  const { resetPackingOrderBtnVisible, changingOrderBtnVisible } = useMemo(() => {
    const status = state.formStateRaw?.status as OrderStatusEnum | undefined
    if (!status) return { resetOrderVisible: false, changingOrder: false }

    const resetPackingOrderBtnVisible = [
      OrderStatusEnum.picked,
      OrderStatusEnum.packing,
      OrderStatusEnum.packedAwaitingDocs,
      OrderStatusEnum.packedAwaitingRelabel,
      OrderStatusEnum.packed,
    ].includes(status)

    const changingOrderBtnVisible = ![OrderStatusEnum.canceled].includes(status)

    return {
      resetPackingOrderBtnVisible,
      changingOrderBtnVisible,
    }
  }, [state.formStateRaw?.status])


  const orderPackingType: ShippingOptionsType | undefined =
    state.documentRaw?.shippingOptions?.packingType

  const data_from = new Date(
    `2023-11-02T${state.formStateDraft?.delivery_time_plan_from || ''}`,
  )
  const data_to = new Date(
    `2023-11-02T${state.formStateDraft?.delivery_time_plan_to || ''}`,
  )
  const deliveryContractValue = useMemo(() => {
    return deliveryContracts?.find(contract => contract.id === state.documentRaw?.delivery?.delivery_contract_id)
  }, [state.documentRaw?.delivery?.delivery_contract_id, deliveryContracts]);

  return (
    <>
      <DocumentsParams
        rows={orderRows}
        columns={orderColumns}
        tableTitle={'Товары'}
        tableExtraHeader={
          <div className={styles.tableExtraHeader}>
            {resetPackingOrderBtnVisible ? (
              <Button
                theme={'danger'}
                className={styles.headerButton}
                label={'Сбросить упаковку'}
                onClick={() => setConfirmModalVisible(true)}
              />
            ) : null}
            {changingOrderBtnVisible ? (
              <Button
                className={styles.headerButton}
                label={'Редактирование цепочки'}
                onClick={() => setOpenModalShipped(true)}
              />
            ) : null}
            <span className={styles.allCount}>
              Общее кол-во: {orderItemsQuantity}
            </span>
          </div>
        }
      >
        <div className={styles.fieldsWrapper}>
          <DocumentStatusField
            className={styles.fieldType25}
            value={state.formStateDraft?.status}
            onChange={(optionValue) =>
              dispatch(updateFormStateDraftAction('status', optionValue?.value))
            }
            documentType={page as DocumentTypeEnum}
          />

          <InputWrapper
            className={styles.fieldType25}
            id={'merchant'}
            label={'Организация'}
            value={state.documentRaw?.merchant?.title || null}
            disabled
          />

          <InputWrapper
            className={styles.fieldType25}
            value={state.documentRaw?.id || documentId}
            id={'supplyId'}
            label={'Номер документа'}
            disabled
          />

          <InputWrapper
            className={styles.fieldType25}
            id={'shipmentZone'}
            label={'Зона отгрузки'}
            value={state.documentRaw?.sku_condition}
            disabled
          />

          <InputWrapper
            className={styles.fieldType25}
            id={'shipmentType'}
            label={'Тип отгрузки'}
            value={state.documentRaw?.operationType}
            disabled
          />

          <InputWrapper
            className={styles.fieldType25}
            id={'carrier'}
            label={'Способ доставки'}
            value={state.documentRaw?.delivery?.title}
            disabled
          />
          <InputWrapper
            className={styles.fieldType25}
            id={'delivery_contract_title'}
            label={'Название контракта доставки'}
            value={deliveryContractValue?.title || ''}
            disabled
          />

          <InputWrapper
            className={styles.fieldType25}
            id={'paymentType'}
            label={'Тип оплаты'}
            value={state.documentRaw?.payment_type || ''}
            disabled
          />
          <InputWrapper
            className={styles.fieldType25}
            id={'packingType'}
            label={'Тип упаковки'}
            value={
              orderPackingType
                ? ShippingOptionsDescription[orderPackingType] ||
                orderPackingType
                : ''
            }
            disabled
          />

          <InputWrapper
            className={styles.fieldType25}
            id={'customer'}
            label={'Фамилия покупателя'}
            value={`${state.formStateDraft?.surname}`.replace('  ', ' ')}
            handleChange={(value) =>
              dispatch(updateFormStateDraftAction('surname', value))
            }
          />
          <InputWrapper
            className={styles.fieldType25}
            id={'customer'}
            label={'Имя покупателя'}
            value={`${state.formStateDraft?.name}`.replace('  ', ' ')}
            handleChange={(value) =>
              dispatch(updateFormStateDraftAction('name', value))
            }
          />

          <InputWrapper
            className={styles.fieldType25}
            id={'customerPhone'}
            label={'Телефон покупателя'}
            value={state.formStateDraft?.phone}
            handleChange={(value) =>
              dispatch(updateFormStateDraftAction('phone', value))
            }
          />
          <InputWrapper
            className={styles.fieldType25}
            id={'shipping_date_plan'}
            label={'Плановая дата отгрузки'}
            value={dayjs(
              state.documentRaw?.delivery?.shipping_date_plan,
            ).format(DATE_TIME_VIEW)}
            disabled
          />
          <InputWrapper
            className={styles.fieldType25}
            id={'delivery_date_plan'}
            label={'Плановая дата доставки'}
            value={state.documentRaw?.delivery?.delivery_date_plan}
            disabled
          />
          <InputWrapper
            className={styles.fieldType25}
            id={'delivery_time_plan_from'}
            label={'Плановое время доставки, c'}
            value={
              state.formStateDraft?.delivery_time_plan_from
                ? dayjs(data_from).format(TIME_FORMAT)
                : ''
            }
            disabled
          />
          <InputWrapper
            className={styles.fieldType25}
            id={'delivery_time_plan_to'}
            label={'Плановое время доставки, по'}
            value={
              state.formStateDraft?.delivery_time_plan_to
                ? dayjs(data_to).format(TIME_FORMAT)
                : ''
            }
            disabled
          />
          <InputWrapper
            className={styles.fieldType25}
            id={'postamat_num'}
            label={'Номер ПВЗ'}
            value={state.formStateDraft?.postamat_num || ''}
            disabled
          />
          {
            state.documentRaw?.join_code ? (
              <InputWrapper
                className={styles.fieldType25}
                id={'join_code'}
                label={'Код входа'}
                value={state.documentRaw?.join_code || ''}
                disabled
              />
             ) : null
           }

          <InputWrapper
            className={styles.fieldTypeFull}
            id={'address'}
            label={'Адрес'}
            value={state.documentRaw?.delivery?.address?.full_address}
            disabled
          />
          <Button
            label='Изменить '
            view='primary'
            onClick={() => setIsAddressChangeVisible(true)}
          />
          <Checkbox
            label='Не учитывать в биллинге'
            checked={disableBilling}
            onChange={(e) => {
              const checked = e.target.checked
              setDisableBilling(checked)
              /*              const formData: IOrderFormDataState = {
                status: state.documentRaw?.status || '',
                comment: state.documentRaw?.warehouse_comment || '',
                disable_billing: checked,
              }*/
              dispatch(updateFormStateDraftAction('disable_billing', checked))
            }}
          />
        </div>
      </DocumentsParams>

      {confirmModalVisible ? (
        <ResetOrderModal
          documentId={documentId}
          onClose={() => setConfirmModalVisible(false)}
          refetch={refetch}
        />
      ) : null}

      {openModalShipped ? (
        <ChangingOrderModal
          orderId={documentId}
          isOpen={openModalShipped}
          onClose={() => setOpenModalShipped(false)}
          refetch={refetch}
        />
      ) : null}

      {isAddressChangeVisible && (
        <Modal
          size='l'
          isOpen={isAddressChangeVisible}
          hasOverlay
          onClickOutside={() => setIsAddressChangeVisible(false)}
        >
          <ModalAddress
            onClose={() => setIsAddressChangeVisible(false)}
            onSubmit={handleSave}
          />
        </Modal>
      )}
    </>
  )
}

export default DocumentsParamsFields
