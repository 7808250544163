import { Button } from '@shared/ui/btns/Button'
import styles from './TableField.module.scss'
import React, { useEffect, useState } from 'react'
import { CommonFieldProps } from '@shared/types/fields'
import cx from 'classnames'
import style from '@widgets/TableGenerator/generator/generatorStyles.module.scss'
import TableModal from './TableModal/TableModal'
import {
  TemplateCellAdditionalData,
  TemplateColumnAdditionalData,
  TemplateTableRow,
} from '@shared/types/tableGenerator/generatorTypes'

export interface TableFieldProps extends Omit<CommonFieldProps, 'onChange' | 'value'>, TemplateCellAdditionalData, TemplateColumnAdditionalData {
  title: string,
  value: string
  dataId: number
  buttonDescription: string
  creationAvailable?: boolean
  deletionAvailable?: boolean
  editMode?: boolean
  readonly?: boolean
  onSubmit: (data: { value: string, id: number, additional_data: { rows: TemplateTableRow[] }
  }) => void
  handleAdditionalModalOpen?: (status: boolean) => void
}


export const TableField = (props: TableFieldProps) => {
  const {
    label,
    value,
    creationAvailable,
    deletionAvailable,
    buttonDescription,
    editMode,
    rows,
    columns,
    dataId,
    onSubmit,
    handleAdditionalModalOpen,
    isRequired,
  } = props

  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    handleAdditionalModalOpen?.(modalVisible)
  }, [modalVisible])

  return (
    <>
      {label &&
        <label className={cx(styles.label, { [styles.requiredLabel]: isRequired })}>
          {label}
        </label>
      }
      <div className={cx({ [style.required]: isRequired && !label })}>
        <Button
          size={'s'}
          label={buttonDescription ?? 'Открыть таблицу'}
          view={'ghost'}
          onClick={() => setModalVisible(true)}
        />
        {
          modalVisible ? (
            <TableModal
              isOpen={true}
              onClose={() => {
                setModalVisible(false)
              }}
              handleSubmit={(newTableRowsData) => {
                onSubmit({ value, id: dataId, additional_data: { rows: newTableRowsData } })
                setModalVisible(false)
              }}
              editMode={editMode}
              tableData={{
                id: -1, rows,
              }}
              table={{
                id: -1, columns,
                creation_available: creationAvailable,
                deletion_available: deletionAvailable,
              }}
            />
          ) : null
        }
      </div>
    </>
  )
}
