import React, { FC, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IRouteParams } from '@pages/DirectoriesPage/types'
import { useGetDocumentTypes } from '@/hooks'
import { useFilterState } from '@shared/hooks'
import { SECTIONS } from '../../../../constants'
import { GeneratorFields } from '@widgets/GeneratorFields'
import { generateTableData } from './utils/table'
import { generateFilterParams, generateFilters } from './utils/filters'
import styles from './List.module.scss'
import DirectoriesTable from '../../../../components/DirectoriesTable/DirectoriesTable'

interface ListProps {}

const List: FC<ListProps> = () => {
  const { page = '', section = SECTIONS.merchants.value } =
    useParams<IRouteParams>()

  const [pagination, setPagination] = useState(0)

  const {
    filterStateDebounced,
    filterState,
    handleChangeFilter
  } = useFilterState({withoutHistory: true, onChangeFilterState: () => {
    setPagination(0)
  }})

  const {
    isFetching: isFetchingTypes,
    refetch: refetchTypes,
    data: typesData,
  } = useGetDocumentTypes({
    params: generateFilterParams({ ...filterStateDebounced, page: pagination } || {}),
  })
  const types = typesData?.values || []

  const filtersConfig = generateFilters()

  const { rows, columns } = useMemo(() => {
    return generateTableData(types, filterStateDebounced?.search, page, section)
  }, [types, filterStateDebounced?.search])

  return (
    <>
      <GeneratorFields
        visible
        wrapperClassName={styles.filter}
        fieldsConfig={filtersConfig}
        valuesState={filterState || {}}
        onChangeField={handleChangeFilter}
      />

      <DirectoriesTable
        newDesign
        isLoading={isFetchingTypes}
        columns={columns}
        rows={rows}
        stickyHeader
        pagination={typesData?.pagination}
        onChangePage={setPagination}
      />
    </>
  )
}
export default List
