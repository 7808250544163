import {
  Cell,
  Column,
  functionalUpdate,
  makeStateUpdater,
  Row,
  RowData,
  Table,
  TableFeature,
  Updater,
} from '@tanstack/react-table'
import { DensityOptions, DensityState, DensityTableState } from './types'

// Here is all of the actual javascript code for our new feature
export const DensityFeature: TableFeature<any> = {
  // define the new feature's initial state
  getInitialState: (state): DensityTableState => {
    return {
      density: 'm',
      ...state,
    }
  },

  // define the new feature's default options
  getDefaultOptions: <TData extends RowData>(
    table: Table<TData>
  ): DensityOptions => {
    return {
      enableDensity: true,
      onDensityChange: makeStateUpdater('density', table),
    } as DensityOptions
  },
  // if you need to add a default column definition...
  // getDefaultColumnDef: <TData extends RowData>(): Partial<ColumnDef<TData>> => {
  //   return { meta: {} } //use meta instead of directly adding to the columnDef to avoid typescript stuff that's hard to workaround
  // },

  // define the new feature's table instance methods
  createTable: <TData extends RowData>(table: Table<TData>): void => {
    table.setDensity = updater => {
      const safeUpdater: Updater<DensityState> = old => {
        return functionalUpdate(updater, old)
      }
      return table.options.onDensityChange?.(safeUpdater)
    }
    table.toggleDensity = value => {
      table.setDensity(old => {
        if (value) return value
        return old === 'l' ? 'm' : old === 'm' ? 's' : 'l' //cycle through the 3 options
      })
    }
    table.getDensity = () => {
      return table.getState().density
    }
  },
  createColumn: <TData extends RowData>(column: Column<TData>, table: Table<TData>): void => {
    column.getTableDensity = table.getDensity
  },
  createRow: <TData extends RowData>(row: Row<TData>, table: Table<TData>): void => {
    row.getTableDensity = table.getDensity
  },
  createCell: <TData extends RowData>(cell: Cell<TData, unknown>, column: Column<TData>, row: Row<TData>, table: Table<TData>): void => {
    cell.getTableDensity = table.getDensity
  },
  // if you need to add row instance APIs...
  // createRow: <TData extends RowData>(row, table): void => {},
  // if you need to add cell instance APIs...
  // createCell: <TData extends RowData>(cell, column, row, table): void => {},
  // if you need to add column instance APIs...
  // createColumn: <TData extends RowData>(column, table): void => {},
  // if you need to add header instance APIs...
  // createHeader: <TData extends RowData>(header, table): void => {},
}