import days from 'dayjs'
import { ICombobox } from 'src/interfaces'
import { DATE_REQUEST_FORMAT, DATE_MONTH_FORMAT, DATETIME_REQUEST_FORMAT } from '@shared/const/date'

const getDateMonth = (value: string | undefined) =>
  value ? days(value).format(DATE_MONTH_FORMAT) : undefined

const getDate = (value: string | undefined, withTime?: boolean) =>
  value
    ? days(value).format(withTime ? DATETIME_REQUEST_FORMAT : DATE_REQUEST_FORMAT)
    : undefined

export const generateFilterParams = (filterState: Record<string, any>) => {
  // todo Надо придумать как можно улучшить/автоматизировать

  return {
    tare_barcode: (filterState['tare_barcode'] || '').trim(),
    search: (filterState['search'] || '').trim(),
    status: Array.isArray(filterState['status'])
        ? filterState['status']?.map((status: ICombobox) => status.value)
        : filterState['status']?.value,

    statuses: filterState['statuses']?.map((status: ICombobox) => status.value),

    merchant_ids: filterState['merchant']?.map(
      (merchant: ICombobox) => merchant.value,
    ),
    executor_ids: filterState['executor']?.map(
      (executor: ICombobox) => executor.value,
    ),

    page: filterState['page'] || 0,
    // size: 100,
    order_id: filterState['order_id'] ? [filterState['order_id']] : undefined,
    ids: filterState['ids'] ? [filterState['ids']] : undefined,
    delivery_companies: filterState['delivery_companies']?.map(
      (dCompany: ICombobox) => dCompany.value,
    ),
    delivery_contract_title: filterState['delivery_contract_title']
      ? filterState['delivery_contract_title']
      : undefined,
    executor_id: filterState['executor_id']?.value,
    total_quantity_from: filterState['total_quantity_from'],
    total_quantity_to: filterState['total_quantity_to'],
    address: filterState['address_search']
      ? {
          search: filterState['address_search'],
        }
      : null,

    paused_from: getDate(filterState['paused_from'], true),
    paused_to: getDate(filterState['paused_to'], true),
    year_month: getDateMonth(filterState['year_month']),
    from: getDate(filterState['from'], true),
    to: getDate(filterState['to'], true),

    date_from: getDate(filterState['date_from'], true),
    date_to: getDate(filterState['date_to'], true),

    shipping_date_from: getDate(filterState['shipping_date_from']),
    shipping_date_to: getDate(filterState['shipping_date_to']),

    place_barcodes: filterState['place_barcodes']
      ? [filterState['place_barcodes']]
      : undefined,

    picking_printed_from: getDate(filterState['picking_printed_from'], true),
    picking_printed_to: getDate(filterState['picking_printed_to'], true),


    // boolean
    picking_printed: filterState['picking_printed'],
    has_transport_place: filterState['has_transport_place'],
    has_unblocked_stock: filterState['has_unblocked_stock'],
  }
}
