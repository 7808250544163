import { useToggle } from '@shared/hooks/useToggle'


type ArgsType = {
  text: string
  defaultShowFull?: boolean
  withoutCollapse?: boolean
  symbols?: number
}
export const useCollapseText = (args: ArgsType) => {
  const {
    text = 'текст отсутствует',
    defaultShowFull = false,
    withoutCollapse,
    symbols = 50,
  } = args

  const {
    value: fullTextVisible,
    toggle: toggleText,
    turnOn: showFullText,
    turnOff: showShortText
  } = useToggle({defaultState: defaultShowFull})

  const shortName = typeof text === 'string' ? text.slice(0, symbols) : text

  return {
    text: (
      fullTextVisible || withoutCollapse
        ? text
        : shortName + (text.length > symbols && !fullTextVisible ? '...' : '')
    ),
    fullTextVisible,
    toggleText,
    showFullText,
    showShortText
  }
}