import styles from './SortBtn.module.scss'
import { Button } from '@shared/ui/btns/Button'
import cx from 'classnames'
import React from 'react'
import { IconSortUp } from '@consta/icons/IconSortUp'
import { IconSortDown } from '@consta/icons/IconSortDown'
import { IconUnsort } from '@consta/icons/IconUnsort'
import { SortEnum } from '@shared/types/sort'


type PropsType = {
  className?: string
  active?: boolean
  sort?: SortEnum | null
  size?: React.ComponentProps<typeof Button>['size'],
  onClick: () => void
}
const SortBtn = (props: PropsType) => {
  const {
    active = false,
    sort,
    size = 's',
    onClick,
    className,
  } = props

  if (!active) return null
  return (
    <div className={cx(styles.wrap, className)}>
      <Button
        className={cx(styles.btn)}
        size={size}
        iconLeft={
          sort === null
            ? IconUnsort
            : sort === SortEnum.DESC
              ? IconSortDown
              : IconSortUp
        }
        view='clear'
        onlyIcon
        onClick={onClick}
      />
    </div>
  )
}

export default SortBtn