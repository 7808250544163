import { IReducerAction, IReportState } from './storeInterfaces'
import { SET_CURRENT_TEMPLATE, SET_MODAL_CELLS, SET_MODAL_ORDERS, SET_MODAL_SKUS, SET_TEMPLATES } from './actions'

export const initialState: IReportState = {
  templates: [],
  currentTemplate: null,
  modalSkus: [],
  modalCells: [],
  modalOrders: [],
}

export const reducer = (
  state: IReportState,
  action: IReducerAction,
): IReportState => {
  const { type, payload } = action

  switch (type) {
     case SET_TEMPLATES:
      return {
        ...state,
        templates: payload,
        currentTemplate: payload.length ? payload[0] : null
      }

    case SET_CURRENT_TEMPLATE:
      return {
        ...state,
        currentTemplate: payload
      }
    // case SET_CURRENT_TEMPLATE_COLUMNS:
    //   return {
    //     ...state,
    //     currentTemplateColumns: payload
    //   }
      
    case SET_MODAL_SKUS:
      return { ...state, modalSkus: payload }

    case SET_MODAL_CELLS:
      return { ...state, modalCells: payload }

    case SET_MODAL_ORDERS:
      return { ...state, modalOrders: payload }


    default:
      return state
  }
}
