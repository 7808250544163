import React from 'react'
import cx from 'classnames'
import styles from './LogoIcon.module.scss'
import { Themes, ThemesEnum } from '@/newSrc/shared/providers/ThemeProvider/config/themes'

interface LogoIconProps {
  theme: ThemesEnum
}

export const LogoIcon = (props: LogoIconProps) => {
  const {
    theme,
  } = props

  const isDarkThemeMode = ([Themes.dark, Themes.accent] as ThemesEnum[]).includes(theme)
  return (
    <div className={cx(styles.LogoIcon, { [styles.dark]: isDarkThemeMode })}>
      <svg width='97' height='32' viewBox='0 0 97 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle fill="#fff" cx="16" cy="16" r="12.5"/>
        <path
          d='M19.2 16C19.2 17.76 17.76 19.2 16 19.2C14.24 19.2 12.8 17.76 12.8 16C12.8 14.24 14.24 12.8 16 12.8C17.76 12.8 19.2 14.24 19.2 16Z'
          fill='#08A045' />
        <path
          d='M27.648 11.168C26.72 8.96 26.688 6.656 27.328 4.672H27.36C27.36 4.672 27.36 4.672 27.328 4.672C25.344 5.312 23.04 5.248 20.832 4.352C18.624 3.456 16.96 1.856 16 0C15.04 1.856 13.376 3.456 11.168 4.352C8.96 5.248 6.656 5.312 4.672 4.672C5.312 6.656 5.248 8.96 4.352 11.168C3.456 13.376 1.856 15.04 0 16C1.856 16.96 3.456 18.624 4.352 20.832C5.28 23.04 5.312 25.344 4.672 27.328H4.64L4.672 27.36C6.656 26.72 8.96 26.784 11.168 27.68C13.376 28.544 15.04 30.144 16 32C16.96 30.144 18.624 28.544 20.832 27.648C23.04 26.752 25.344 26.688 27.328 27.328C27.328 27.328 27.36 27.328 27.36 27.296H27.328C26.688 25.312 26.752 23.008 27.648 20.8C28.544 18.624 30.144 16.96 32 16C30.144 15.04 28.544 13.376 27.648 11.168ZM27.456 20.736C25.248 21.632 22.976 21.632 21.024 21.024C21.664 23.008 21.664 25.248 20.736 27.456C18.56 26.528 16.96 24.96 16 23.104C15.04 24.96 13.44 26.56 11.264 27.456C10.368 25.248 10.368 23.008 11.008 21.024C9.024 21.664 6.752 21.664 4.576 20.736C5.472 18.56 7.04 16.96 8.896 16C7.04 15.04 5.44 13.44 4.544 11.264C6.752 10.368 8.992 10.368 10.976 10.976C10.336 8.992 10.336 6.752 11.232 4.544C13.44 5.472 15.04 7.04 16 8.896C16.96 7.04 18.56 5.44 20.736 4.544C21.632 6.752 21.632 8.992 21.024 10.976C23.008 10.336 25.248 10.336 27.456 11.264C26.528 13.44 24.96 15.04 23.104 16C24.96 16.96 26.528 18.56 27.456 20.736Z'
          fill='#08A045' />
        <g>
          <path
            d='M43.3281 18.5601C41.9841 18.5601 40.9601 17.4401 40.9601 16.0321C40.9601 14.5921 41.9841 13.5041 43.3281 13.5041C44.3201 13.5041 45.1521 14.0481 45.5681 14.9441H48.0321C47.5841 12.6721 45.7281 11.1681 43.3281 11.1681C40.5121 11.1681 38.4001 13.2481 38.4001 16.0321C38.4001 18.7841 40.5121 20.9281 43.2961 20.9281C45.6961 20.9281 47.6161 19.3601 48.0321 17.0241H45.5041C45.1521 17.9841 44.3521 18.5601 43.3281 18.5601Z'
            fill='#1C2023' />
          <path
            d='M57.1528 12.2562C56.3528 11.5522 55.2968 11.1362 54.1128 11.1362C51.3288 11.1362 49.2808 13.2162 49.2808 16.0002C49.2808 18.7842 51.3608 20.8962 54.1128 20.8962C55.2648 20.8962 56.3208 20.4802 57.0888 19.8082L57.3128 20.6402H59.2648V11.3922H57.4088L57.1528 12.2562ZM54.3368 18.5282C52.8968 18.5282 51.8088 17.4402 51.8088 16.0002C51.8088 14.5602 52.8648 13.4722 54.3368 13.4722C55.7768 13.4722 56.8648 14.5602 56.8648 16.0002C56.8328 17.4402 55.7768 18.5282 54.3368 18.5282Z'
            fill='#1C2023' />
          <path
            d='M65.5037 18.5601C64.1597 18.5601 63.1357 17.4401 63.1357 16.0321C63.1357 14.5921 64.1597 13.5041 65.5037 13.5041C66.4957 13.5041 67.3277 14.0481 67.7437 14.9441H70.2077C69.7597 12.6721 67.9037 11.1681 65.5037 11.1681C62.6877 11.1681 60.5757 13.2481 60.5757 16.0321C60.5757 18.7841 62.6877 20.9281 65.4717 20.9281C67.8717 20.9281 69.7917 19.3601 70.2077 17.0241H67.6797C67.3277 17.9841 66.5277 18.5601 65.5037 18.5601Z'
            fill='#1C2023' />
          <path
            d='M75.1045 16.9921V13.4081H77.6005V11.3921H75.0405V8.99207H74.5925L70.9445 12.8641V13.4081H72.6085V17.3441C72.6085 19.3921 73.8565 20.6401 75.8725 20.6401H77.6325V18.4321H76.5125C75.6165 18.4001 75.1045 17.8881 75.1045 16.9921Z'
            fill='#1C2023' />
          <path
            d='M85.2799 16.4481C85.2799 17.7601 84.6079 18.5601 83.4559 18.5601C82.3039 18.5601 81.5999 17.7921 81.5999 16.4481V11.3921H79.1039V16.7361C79.1039 19.2321 80.8319 20.8961 83.4559 20.8961C86.0799 20.8961 87.7759 19.2321 87.7759 16.7361V11.3921H85.2799V16.4481Z'
            fill='#1C2023' />
          <path
            d='M93.2481 14.9442C92.2881 14.7842 91.4881 14.6242 91.4881 14.0162C91.4881 13.4722 91.9362 13.1202 92.7362 13.1202C93.6002 13.1202 94.1121 13.4722 94.1441 14.2082H96.5121C96.5121 12.3522 95.0401 11.1362 92.8001 11.1362C90.5601 11.1362 89.0881 12.3522 89.0881 14.0802C89.0881 16.2562 90.9761 16.6402 92.4481 16.8962C93.4081 17.0562 94.2401 17.2482 94.2401 17.8882C94.2401 18.4962 93.6321 18.8162 92.9281 18.8162C92.0321 18.8162 91.3921 18.4002 91.3921 17.5682H88.9601C88.9601 19.5522 90.5281 20.8642 92.8321 20.8642C95.1681 20.8642 96.7041 19.6482 96.7041 17.7602C96.6721 15.5202 94.7521 15.2322 93.2481 14.9442Z'
            fill='#1C2023' />
        </g>
      </svg>
    </div>
  )
}
