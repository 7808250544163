import React from 'react'
import cx from 'classnames'

import styles from './input-with-dste-mask.module.scss'

import { TextField, TextFieldPropValue } from '@consta/uikit/TextField'

import { IconComponent } from '@consta/icons/Icon'
import { TextFieldPropForm, TextFieldPropSize } from '@consta/uikit/__internal__/src/components/TextField/TextField'
import { getFormattedDate } from './utils'
import { Flex } from '@shared/ui/Flex'
import { Text } from '@shared/ui/Text'

interface InputWithDateMaskProps {
  id: string
  label?: string
  labelSlot?: React.ReactNode
  placeholder?: string
  size?: TextFieldPropSize
  value: string | null
  handleChange?: (value: TextFieldPropValue) => void
  className?: any
  rightSide?: string | IconComponent
  autofocus?: boolean
  form?: TextFieldPropForm
  error?: string
}

const InputWithDateMask = ({
  id,
  value,
  handleChange = () => false,
  placeholder,
  rightSide,
  size,
  className,
  autofocus,
  label,
  labelSlot,
  form,
  error,
}: InputWithDateMaskProps) => {

  const handleChangeInput = (val: TextFieldPropValue) => {
    if (!val) {
      handleChange('')
      return
    }
    const newVal = getFormattedDate(val)
    if (newVal) {
      handleChange(newVal)
    }
  }

  return (
    <Flex
      className={cx(styles.input, className)}
      gap={'xs'}
      direction={'column'}
    >
      {labelSlot ? (
        labelSlot
        ) : (
         label && <Text as={'label'} view={'secondary'} className={styles.label} children={label}/>
        )
      }
      <TextField
        type="text"
        id={id}
        value={value}
        placeholder={placeholder}
        rightSide={rightSide}
        size={size}
        onChange={(value) => handleChangeInput(value)}
        className={className}
        autoFocus={autofocus}
        form={form}
      />
      {error && <Text as={'span'} view={'alert'} size={'s'} className={styles.error} children={error}/>}
    </Flex>
  )
}

export default InputWithDateMask
