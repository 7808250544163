import React from 'react'

import { User } from '@entities/User'

import { Dropdown } from '@/components'

import { useAppContext } from '@shared/providers/AppContextProvider'

import { UserPropSize, UserPropView } from '@consta/uikit/__internal__/src/components/User/User'
import { IconComponent } from '@consta/icons/Icon'
import { IDropdownItem } from '@/interfaces'
import { generatePath, useHistory } from 'react-router-dom'
import { PATHS } from '@shared/routing'
import { useDatabases } from '@shared/hooks/settings'

interface UserProps {
  size?: UserPropSize
  view?: UserPropView
  name?: string
  info?: string
  iconRight?: IconComponent
  items?: IDropdownItem[]
}

const UserWithDropdown = (props: UserProps) => {
  const {
    view = 'ghost',
    name,
    info,
    size,
    iconRight,
    items,
  } = props

  return (
    <Dropdown items={items}>
      <User
        view={view}
        iconRight={iconRight}
        name={name}
        info={info}
        size={size}
      />
    </Dropdown>
  )
}
type MenuSettingsType = (
  {
    withDefaultMenuItems?: boolean
    defaultMenuSettings?: {
      withoutLogout?: boolean
      withoutGoToMainMenu?: boolean
    }
  } | {
  withDefaultMenuItems?: never
  defaultMenuSettings?: never
}
  )
type UserBlockProps = UserProps & MenuSettingsType & {
  withMenu?: boolean
}

export const UserBlock = (props: UserBlockProps) => {
  const {
    withMenu,
    withDefaultMenuItems = true,
    defaultMenuSettings,
    items = [],
    ...passedProps
  } = props

  const history = useHistory()
  const { logout, currentUser } = useAppContext()

  const { getDBOptionByStorage } = useDatabases()
  const currentBase = getDBOptionByStorage()

  const Component = !withMenu ? User : UserWithDropdown

  const defaultUserItems: IDropdownItem[] = [
    {
      hidden: !withDefaultMenuItems || defaultMenuSettings?.withoutGoToMainMenu,
      name: 'К меню',
      action: () => history?.push(generatePath(PATHS.OPERATION)),
    },
    {
      hidden: !withDefaultMenuItems || defaultMenuSettings?.withoutLogout,
      name: 'Выйти',
      action: () => {
        logout()
        window?.location?.reload()
      },
    },
  ]

  const dbLabel = currentBase?.label?.replace('Новая ', '') || '-'
  const username = currentUser?.username || ''
  const workTable = currentUser?.workTable || ''

  return (
    <Component
      {...passedProps}
      items={[...defaultUserItems, ...items]}
      name={dbLabel}
      info={`${username} ${workTable}`}
    />
  )
}
