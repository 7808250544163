import React, { FC, useMemo, useState } from 'react'
import { useGetPaymentMethods } from '@/hooks'
import { useFilterState } from '@shared/hooks'
import { ISection } from '../../types'
import { generateFilterParams } from './utils/filters'
import { generateTableData } from './utils/table'
import { DirectoriesLayout } from '../../components/DirectoriesLayout/DirectoriesLayout'

interface PaymentMethodsProps extends ISection {}

const PaymentMethods: FC<PaymentMethodsProps> = (props) => {
  const { title = '' } = props
  const {
    filterStateDebounced,
    filterState,
    handleChangeFilter
  }  = useFilterState({withoutHistory: true, onChangeFilterState: () => {
    setPagination(0)
  }})
  const [pagination, setPagination] = useState(0)

  const {
    isFetching: isFetchingPaymentMethods,
    refetch: refetchPaymentMethods,
    data: paymentMethodsData,
  } = useGetPaymentMethods({
    params: generateFilterParams({ page: pagination } || {}),
  })

  const { rows, columns } = useMemo(() => {
    return generateTableData(paymentMethodsData?.values || [], filterStateDebounced?.search)
  }, [paymentMethodsData?.values, filterStateDebounced?.search])

  return (
    <DirectoriesLayout
      withTable
      withFilter
      withSearch

      title={title}

      filterState={filterState}
      handleChangeFilter={handleChangeFilter}

      isLoading={isFetchingPaymentMethods}
      columns={columns}
      rows={rows}
      pagination={paymentMethodsData?.pagination}
      onChangePage={setPagination}
    />
  )
}
export default PaymentMethods
