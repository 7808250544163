import React from 'react'

import styles from './table-header.module.scss'
import { TableColumnsSettings } from '../../index'
import cx from 'classnames'
import {
  ContextMenuPropGetItemLabel,
  ContextMenuPropGetItemLeftSide,
} from '@consta/uikit/__internal__/src/components/ContextMenu/types'
import { IconSelect } from '@consta/icons/IconSelect'
import { Text } from '@shared/ui/Text'

interface Item<ITEM> {
  label: string
  key: string
  column: ITEM
}

interface IProps<ITEM> {
  title?: React.ReactNode
  subtitle?: React.ReactNode
  titleClassName?: string
  extraHeader?: React.ReactNode
  withColumnSettings?: boolean
  columnSettingsItems?: Item<ITEM>[]

  getLabel?: ContextMenuPropGetItemLabel<any>
  getLeftSideBar?: ContextMenuPropGetItemLeftSide<any>
  handleChangeCheckbox?: (columnKey: string, checked: boolean) => void
  columnsMenuVisible?: boolean
  hiddenColumnsKeys?: string[]
  handleCloseColumnsSetting?: () => void
  btnMenuColumnsOnClick?: () => void

  withVisibleToggle?: boolean
  tableVisible?: boolean
  onVisibleToggle?: () => void
}

const TableHeader = React.memo(<ITEM, >(props: IProps<ITEM>) => {
  const {
    title,
    subtitle,
    titleClassName,
    withVisibleToggle,
    tableVisible,
    onVisibleToggle,
    extraHeader,
    withColumnSettings,
    handleChangeCheckbox = (a, b) => {
    },
    handleCloseColumnsSetting,
    columnsMenuVisible = false,
    columnSettingsItems = [],
    hiddenColumnsKeys = [],
    btnMenuColumnsOnClick = () => {
    },
    getLabel = (item) => item.label,
    getLeftSideBar,
  } = props
  return (
    <div className={styles.header}>
      <div>
        {title ? (
          <Text
            as={'h4'}
            className={cx(styles.title, { [styles.pointer]: withVisibleToggle }, titleClassName)}
            onClick={onVisibleToggle}
          >
            {title}
            {withVisibleToggle ? (
              <IconSelect className={cx(styles.toggleIcon, { [styles.tableVisible]: tableVisible })} />
            ) : null}
          </Text>
        ) : null}
        {subtitle ? (
          <Text as={"h5"} size={'s'} className={cx(styles.subtitle)}>{subtitle}</Text>
        ) : null}
      </div>

      <div className={styles.headerTableSettings}>
        {extraHeader ? extraHeader : null}
        {withColumnSettings ? (
          /** Настройки столбцов таблицы */
          <TableColumnsSettings
            direction={'leftStartUp'}
            handleChangeCheckbox={handleChangeCheckbox}
            handleClickOutside={handleCloseColumnsSetting}
            menuVisible={columnsMenuVisible}
            hiddenColumnsKeys={hiddenColumnsKeys}
            items={columnSettingsItems}
            getLabel={getLabel}
            btnOnClick={btnMenuColumnsOnClick}
            getLeftSideBar={getLeftSideBar}
          />
        ) : null}
      </div>
    </div>
  )
})
export default TableHeader
