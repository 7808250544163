import React, { useEffect, useMemo, useState } from 'react'

import styles from './placing-form-modal.module.scss'
import { ComboboxWrapper, InputWrapper, Modal } from 'src/components'
import { ModalProps } from 'src/interfaces'
import { Button } from '@shared/ui/btns/Button'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { IGetCellStockByCellIdResult, IWarehouseCellsParams } from 'src/hooks'
import cx from 'classnames'
import { IRequestPlaceProduct } from '@/hooks/placement/usePlaceProducts'
import { useParams } from 'react-router-dom'
import { Item } from '@/hooks/placement/useGetPlacementList'
import { useGetCellsList } from '@/hooks/warehouse/cell/useGetCellsList'
import { ComboboxItemDefault } from '@consta/uikit/Combobox'

interface IProps extends ModalProps {
  onSubmit: (docId: string, products: IRequestPlaceProduct[]) => void
  formData: IGetCellStockByCellIdResult | null
  stockItem: Item
}

interface ISubmitPropsData {
  cell_barcode: string,
  quantity: string,
  sku_batch_id: string,
  sku_id: string
}

const PlacingFormModal = (props: IProps) => {
  const { isOpen, onClose, onSubmit, formData, stockItem } = props
  const { id: supplyId } = useParams<{id: string }>()
  const [formState, setFormState] = useState<IGetCellStockByCellIdResult | null>(null)
  const [barCode, setBarCode] = useState<ComboboxItemDefault | null>(null)
  const [resultState, setResultState] = useState<ISubmitPropsData>({
    cell_barcode: '',
    quantity: '1',
    sku_batch_id: '',
    sku_id: ''
  })

  const [cellParams, setCellParams] = useState<IWarehouseCellsParams>({})

  const { isFetching: isCellFetching, data: cells, refetch: refetchCells } = useGetCellsList({
    ...cellParams, size: 100
  })

  const newCells = useMemo(() =>
    cells?.cells.map((item) => ({
      id: item.barcode,
      barcode: item.barcode,
      label: item.title
    })) || [], [cells]
  )

  useEffect(() => {
    refetchCells()
  }, [cellParams])

  useEffect(() => {
    setFormState(formData)
  }, [resultState, formData])

  const onSave = () => {
    if (resultState !== null) {
      onSubmit(supplyId, [
        {
          cell_barcode: resultState.cell_barcode,
          quantity: parseInt(resultState.quantity),
          sku_batch_id: stockItem.sku_batch_id,
          sku_id: stockItem.id
        }
      ])
    }
  }

  const onChange = (id: keyof ISubmitPropsData, value: TextFieldPropValue | ComboboxItemDefault) => {
    if (typeof value === 'string') {
      setResultState((prev) => ({ ...prev, [id]: value?.toString() }))
    } else {
      setBarCode(value);
      setResultState((prev) => ({ ...prev, [id]: String(value?.id) }))
    }

  }

  const cellQuantityError = resultState.quantity && (Number(resultState.quantity) > stockItem.quantity)
  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.modal}
      size="m"
      headerTitle={'Размещение товара'}
    >
      <div className={styles.formWrapper}>
        <div className={styles.row}>
          <InputWrapper
            id={'title'}
            label={'Номенклатура'}
            value={formState?.skus[0].title}
            className={cx(styles.input)}
            disabled
          />
          <InputWrapper
            isNumber
            id={'quantity'}
            label={'Количество'}
            placeholder={`${stockItem.quantity}`}
            value={resultState.quantity} //Почему любые значения должны приводиться к строке?
            handleChange={(value: TextFieldPropValue) => {
              onChange('quantity', value)
            }}
            className={cx(styles.input)}
            error={cellQuantityError ? `Максимум: ${stockItem.quantity}` : ''}
          />
          <div className={styles.cellWrapper}>
            <ComboboxWrapper
              loading={isCellFetching}
              items={newCells}
              value={barCode}
              multiple={false}
              label={'Ячейка'}
              size={'m'}
              withApiSearch
              placeholder={'начните печатать для поиска'}
              searchFunction={(item, searchValue) => {
                const value = searchValue.toUpperCase();
                const barcode = item.barcode?.toUpperCase();
                const label = item.label.toUpperCase();
                return label?.includes(value) || barcode?.includes(value)
              }}
              onChange={(value) => {
                onChange('cell_barcode', value)
              }}
              handleApiSearch={(value: string | null) => setCellParams({ search: value || undefined })}
            />
          </div>

        </div>
      </div>

      <div className={styles.footer}>
        <Button label="Отмена" view={'ghost'} onClick={onClose} />
        <Button
          label="Переместить"
          view={'primary'}
          onClick={onSave}
          disabled={Boolean(cellQuantityError) || !resultState.quantity || !resultState.cell_barcode}
        />
      </div>
    </Modal>
  )
}

export default PlacingFormModal
