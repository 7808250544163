import { Text } from '@shared/ui/Text'
import { Flex } from '@/newSrc/shared/ui/Flex';

export const BatchCell = ({ num, date }: { num: string; date?: string; }) => {
  return (
    <Flex
      gap={'2xs'}
      direction={'column'}
    >
      {date ?
        <Text
          size={'s'}
          as={'p'}
          weight={'bold'}
          children={date}
        />
      : null}
      <Text
        size={'s'}
        as={'span'}
        weight={'regular'}
        view={'secondary'}
        children={num}
      />
    </Flex>
  )
}
