import {
  Cell,
  Column,
  functionalUpdate,
  makeStateUpdater,
  Row,
  RowData,
  Table,
  TableFeature,
  Updater,
} from '@tanstack/react-table'
import { ContextMenuOptions, ContextMenuPositionType, ContextMenuState, ContextMenuTableState } from './types'
import React from 'react'


export const initialContextMenuPositionType: ContextMenuPositionType = { x: 0, y: 0 }
export const getContextMenuInitialState = (): ContextMenuState => ({
  visible: false,
  position: initialContextMenuPositionType,
  selectedRowId: '',
  selectedColumnId: '',
})

export const ContextMenuFeature: TableFeature<any> = {

  getInitialState: (state): ContextMenuTableState => {
    return {
      contextMenu: getContextMenuInitialState(),
      ...state,
    }
  },

  getDefaultOptions: <TData extends RowData>(
    table: Table<TData>,
  ): ContextMenuOptions => {
    return {
      contextMenuEnable: true,
      onContextMenuChange: makeStateUpdater('contextMenu', table),
    } as ContextMenuOptions
  },

  createTable: <TData extends RowData>(table: Table<TData>): void => {
    table.setContextMenuVisible = updater => {
      const safeUpdater: Updater<ContextMenuState> = old => functionalUpdate(updater, old)
      return table.options.onContextMenuChange?.(safeUpdater)
    }
    table.getContextMenuIsVisible = () => table.getState()?.contextMenu?.visible

    table.closeContextMenu = () => {
      if (!table.getState()?.contextMenu?.visible) return
      table.setContextMenuVisible(getContextMenuInitialState())
    }

    table.getOpenContextMenuHandler = ({ row, header, cell }) => {
      return (e: React.MouseEvent<HTMLElement>) => {
        table.openContextMenu({
          position: {
            x: e.clientX,
            y: e.clientY,
          },
          selectedColumnId: header?.column?.id || cell?.column?.id,
          selectedRowId: row?.id,
        })
      }
    }
    table.openContextMenu = (menuState) => {
      const initialState = getContextMenuInitialState()
      table.setContextMenuVisible({ ...initialState, ...menuState, visible: true })
    }

  },
}