import React, { useRef, useState } from 'react'

import styles from './scan-serial-input.module.scss'

import { EnterHandler, InputWrapper } from 'src/components'

import { Button } from '@shared/ui/btns/Button'
import { IconAllDone } from '@consta/icons/IconAllDone'
import { TextFieldPropValue } from '@consta/uikit/TextField'

import { useNotifications } from '@shared/providers/NotificationProvider'

import { checkSerialNumberForCorrectness } from '@shared/helpers'

type ScanSerialInputProps = {
  required?: boolean
  loading?: boolean
  autoscan?: boolean
  value: TextFieldPropValue
  onChange: (v: TextFieldPropValue) => void
  code: string
  status: 'done' | 'undone' | 'disabled'
  label: string
  template: string
  skipBtnLabel?: string
  cachedSerials?: string[]

  // по хорошему это надо делать слотом, а не вкорячивать сюда параметры
  chestniyZnakInput?: boolean
  chestniyZnakReprint?: boolean

  setCachedSerials?: (serials: string[]) => void
  handleSkipSerial?: () => void
  handlePrintSerial?: () => Promise<void>
}

const ScanSerialInput = (props: ScanSerialInputProps) => {
  const {
    loading,
    value,
    required,
    autoscan,
    chestniyZnakInput,
    chestniyZnakReprint,
    onChange,
    code,
    skipBtnLabel = 'Пропустить',
    status = 'undone',
    label,
    template,
    cachedSerials,
    setCachedSerials,
    handleSkipSerial,
    handlePrintSerial,
  } = props

  const [localVal, setLocalVal] = useState<TextFieldPropValue>('')
  const [error, setError] = useState<string>('')

  const notification = useNotifications()

  const inputRef = useRef<HTMLTextAreaElement | HTMLInputElement | null>(null)

  const focusBarcodeInput = () => {
    if (!inputRef?.current) return
    inputRef.current.focus()
  }

  const checkTemplateAndChange = (val: TextFieldPropValue, code: string) => {
    if (!val) return

    const { correct, errorText } = checkSerialNumberForCorrectness(
      val,
      template,
    )

    if (!correct) {
      setError(errorText)
      notification?.show('alert', errorText)
      return false
    }

    if (cachedSerials && setCachedSerials) {
      //@ts-ignore
      setCachedSerials((state: string[]) => [...state, val])
    }
    onChange(val)
  }

  const handleChange = (
    val: TextFieldPropValue,
    type: string = 'withDelay',
  ) => {
    setLocalVal('')
    if (!val) return

    if (val && /[а-яА-Я]/i.test(val)) {
      notification?.show(
        'alert',
        `Обнаружена кириллица в серийном номере. Измените раскладку клавиатуры.`,
      )
      return false
    }

    if (type === 'withDelay') {
      checkTemplateAndChange(val.trim(), code)
    }

    if (type === 'withManual') {
      setLocalVal(val.trim())
      setError('')
    }
  }

  const handleManualChange = (val: TextFieldPropValue) => {
    setError('')
    if (!val) return

    checkTemplateAndChange(val, code)
  }

  const handleEnter = (localVal: TextFieldPropValue) => {
    handleManualChange(localVal)
  }
  // console.log(status, label, required)
  return (
    <div className={styles.wrapper}>
      <h4 className={styles.colLabel}>{label}</h4>
      <div className={styles.block}>
        {status === 'undone' ? (
          <EnterHandler
            onEnter={() =>
              !required
                ? handleSkipSerial?.()
                : handleEnter(localVal)
            }
          >
            <div className={styles.undone}>
              <InputWrapper
                value={localVal || value}
                id={code}
                handleChange={(val: TextFieldPropValue, type: string) =>
                  handleChange(val, type)
                }
                autoComplete={"off"}
                className={styles.itemInput}
                size="l"
                withDelay={autoscan}
                withDelayAndManual={!autoscan}
                delay={500}
                error={error}
                autoFocus
                inputRef={inputRef}
              />
              <div className={styles.btns}>
                {
                  chestniyZnakInput ? (
                    <Button
                      className={styles.btn}
                      form="brickDefault"
                      label={chestniyZnakReprint ? "Перепечатать ЧЗ" : 'Присвоить ЧЗ'}
                      size="l"
                      loading={loading}
                      onClick={async () => {
                        await handlePrintSerial?.()
                        focusBarcodeInput()
                      }}
                    />
                  ) : null
                }
                {
                  !autoscan ? (
                    <Button
                      className={styles.btn}
                      form="brickDefault"
                      label="Подтвердить"
                      disabled={!localVal && !value}
                      size="l"
                      loading={loading}
                      onClick={() => handleManualChange(localVal)}
                    />
                  ) : null
                }
                {
                  !required ? (
                    <Button
                      className={styles.btn}
                      form="brickDefault"
                      label={skipBtnLabel}
                      size="l"
                      loading={loading}
                      onClick={handleSkipSerial}
                    />
                  ) :null
                }
              </div>
            </div>
          </EnterHandler>
        ) : null}
        {status === 'done' ? (
          <div className={styles.done}>
            <InputWrapper
              disabled
              readOnly
              value={value}
              id={code}
              className={styles.itemInput}
              size="l"
            />
            <IconAllDone className={styles.icon} view="success" />
          </div>
        ) : null}

        {status === 'disabled' ? (
          <InputWrapper
            disabled
            readOnly
            value={value}
            id={code}
            className={styles.itemInput}
            size="l"
          />
        ) : null}
      </div>
    </div>
  )
}

export default ScanSerialInput
