import React, { useEffect, useState } from 'react'

import styles from './carrier-company-modal.module.scss'
import { InputWrapper, Modal } from 'src/components/index'
import { ICarrierCompany, ModalProps } from 'src/interfaces'
import { Button } from '@shared/ui/btns/Button'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import cx from 'classnames'
import { useCreateUpdateCarrier } from 'src/hooks'

interface IProps extends ModalProps {
  onSubmit: (data: ICarrierCompanyForm) => void
  company: ICarrierCompany | null
}

interface ICarrierCompanyForm extends Omit<ICarrierCompany, 'legal_address'> {
  legal_address_full?: string
}

const CarrierCompanyModal = (props: IProps) => {
  const {
    isOpen,
    onClose,
    onSubmit,
    company
  } = props

  const createUpdateCarrier = useCreateUpdateCarrier({})

  const [formState, setFormState] = useState<ICarrierCompanyForm>({})

  useEffect(() => {
    if (company) {
      const {legal_address, ...restCompany} = company
      setFormState({
        ...restCompany,
        legal_address_full: legal_address?.full_address,
      })
    }
  }, [company])


  const onSave = () => {
    onSubmit(formState)
  }

  const onChange = (id: keyof ICarrierCompanyForm, value: TextFieldPropValue) => {
    setFormState((prev: ICarrierCompanyForm) => ({ ...prev, [id]: value?.toString() }))
  }

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.modal}
      size='m'
      headerTitle={'Создание нового перевозчика'}
    >
      <div className={styles.formWrapper}>
        <div className={styles.row}>
          <InputWrapper
            id={'title'}
            label={'Название организации'}
            value={formState?.title}
            handleChange={(value: TextFieldPropValue) => {
              onChange('title', value)
            }}
            className={cx(styles.input)}
          />
          <InputWrapper
            isNumber
            id={'phone'}
            label={'Телефон'}
            placeholder={'+79872357525'}
            value={formState?.phone}
            handleChange={(value: TextFieldPropValue) => {
              onChange('phone', value)
            }}
            className={cx(styles.input, styles.driverPhone)}
          />
        </div>

        <InputWrapper
          id={'legal_address_full'}
          label={'Юр. адрес'}
          value={formState?.legal_address_full}
          handleChange={(value: TextFieldPropValue) => {
            onChange('legal_address_full', value)
          }}
          className={cx(styles.input)}
        />

        <div className={styles.row}>
          <InputWrapper
            id={'inn'}
            label={'ИНН'}
            value={formState?.inn}
            handleChange={(value: TextFieldPropValue) => {
              onChange('inn', value)
            }}
            className={cx(styles.input)}
          />
          <InputWrapper
            id={'kpp'}
            label={'КПП'}
            value={formState?.kpp}
            handleChange={(value: TextFieldPropValue) => {
              onChange('kpp', value)
            }}
            className={cx(styles.input)}
          />
          <InputWrapper
            id={'ogrn'}
            label={'ОГРН'}
            value={formState?.ogrn}
            handleChange={(value: TextFieldPropValue) => {
              onChange('ogrn', value)
            }}
            className={cx(styles.input)}
          />
        </div>
      </div>

      <div className={styles.footer}>
        <Button label='Отмена' view={'ghost'} onClick={onClose} />
        <Button
          label='Сохранить'
          view={'primary'}
          onClick={onSave}
          loading={createUpdateCarrier.isLoading}
        />
      </div>
    </Modal>
  )
}

export default CarrierCompanyModal
