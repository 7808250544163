import React from 'react'
import cx from 'classnames'

import styles from './signal-badge.module.scss'
import { Badge, BadgePropForm, BadgePropSize, BadgePropStatus } from '@consta/uikit/Badge'

interface AdvanceModeLockProps {
  form?: BadgePropForm
  size?: BadgePropSize
  status?: BadgePropStatus
  className?: string
  minified?: boolean
}

export const SignalBadge = (props: AdvanceModeLockProps) => {
  const {
    className,
    form = 'round',
    size = "m",
    status = "error",
    minified = true,
  } = props

  return (
    <Badge
      className={cx(styles.badge, className)}
      minified={minified}
      form={form}
      size={size}
      status={status}
    />
  )
}
