import React, { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useGetItems, useGetItemsStock } from '@/hooks'
import { IDimensions, ISku } from '@/interfaces'
import { IRouteParams } from '@pages/DirectoriesPage/types'
import { SkuItemPreview } from '@entities/Sku/ui/SkuItemPreview'
import { Table, TableColumn } from '@consta/uikit/Table'
import { InputWrapper, WeightDimensionsModal } from '@/components'
import { Img } from '@shared/ui/Img'
import { Button } from '@shared/ui/btns/Button'
import { Text } from '@shared/ui/Text'

import { getBarcodeTable, getCellsStock, getPartsTable, getUnTable } from './utils'

import styles from './item.module.scss'
import { boolToText, convertDimensionsStr, convertLength, convertWeight, getCountryByCode } from '@shared/helpers'
import noImage from '../../../../../../shared/assets/images/noImage.png'
import { Flex } from '@shared/ui/Flex'

interface ItemProps {
}

type InfoItemType = {label: string, value: string | number}

const Item: FC<ItemProps> = () => {
  const { id = '' } = useParams<IRouteParams>()
  const skuId = decodeURIComponent(id)

  const {
    isFetching: isFetchingSkus,
    data: skusData,
    refetch,
  } = useGetItems({ id: skuId })
  const { isFetching: isFetchingStock, data: stockData } = useGetItemsStock({
    skus: [{ sku_id: skuId }],
  })

  const [item, setItem] = useState<ISku | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (!skusData) {
      return
    }

    setItem(skusData.skus[0])
  }, [skusData])

  const { barcodeRows, barcodeCols } = useMemo(
    () => getBarcodeTable(item),
    [item],
  )
  const { partsRows, partsCols } = useMemo(
    () => getPartsTable(item),
    [item],
  )
  const { unRows, unCols } = useMemo(() => getUnTable(item), [item])
  const { stockRows, stockCols } = useMemo(
    () =>
      getCellsStock({
        sku: item,
        stock: stockData?.stock || [],
        places: stockData?.places || [],
      }),
    [stockData, item],
  )

  if (!item || isFetchingSkus) {
    return <div>Loading...</div>
  }

  const merchantInfo: InfoItemType[] = [
    {
      label: '',
      value: item?.merchant?.internal_title || item?.merchant?.title || '-',
    },
  ]
  const nomenclatureInfo: InfoItemType[] = [
    {
      label: 'Артикул',
      value: item?.article || '-',
    },
    {
      label: 'ID',
      value: item?.id || '-',
    },
    {
      label: 'Внешний ключ',
      value: item?.external_id || '-',
    },
    {
      label: 'Категория',
      value: item?.category?.title || '-',
    },
    {
      label: 'Наименование',
      value: item?.title || '-',
    },
    {
      label: 'Наименование на английском',
      value: item?.title_en || '-',
    },
    {
      label: 'Необходимость взвешивания',
      value: boolToText(item?.require_weight),
    },

    {
      label: 'Изготовитель',
      value: item?.manufacturer || '-',
    },
    {
      label: 'Хрупкий товар',
      value: boolToText(item?.fragile),
    },
    {
      label: 'Срок годности (месяца)',
      value: item?.expiration_months || '-',
    },
    {
      label: 'Минимальный срок годности (дни)',
      value: item?.min_days_before_expiration_date || '-',
    },
    {
      label: 'Учет по партиям',
      value: boolToText(item?.batch_accounting),
    },
    {
      label: 'Учет по срокам годности',
      value: boolToText(item?.batch_expdate_mandatory),
    },
    {
      label: 'Необходимость измерения габаритов',
      value: boolToText(item?.require_dimensions),
    },
    {
      label: 'Страна изготовления',
      value: getCountryByCode(item.country_of_origin)?.rusName || '-',
    },
    {
      label: 'Код ТНВЭД',
      value: item?.tnved_code || '-',
    },
  ]
  const weightDimensions: InfoItemType[] = [
    {
      label: 'Ширина (см)',
      value: `${
        convertLength('mm', 'sm', Number(item?.dimensions_fact.width)) || '-'
      }`,
    },
    {
      label: 'Высота (см)',
      value: `${
        convertLength('mm', 'sm', Number(item?.dimensions_fact.height)) || '-'
      }`,
    },
    {
      label: 'Длина (см)',
      value: `${
        convertLength('mm', 'sm', Number(item?.dimensions_fact.depth)) || '-'
      }`,
    },
    {
      label: 'Вес (кг)',
      value: `${convertWeight('g', 'kg', Number(item?.weight_fact)) || '-'}`,
    },
  ]
  const tables = [
    {
      title: 'Штрихкода',
      cols: barcodeCols,
      rows: barcodeRows,
    },
    {
      title: 'Состав набора',
      cols: partsCols,
      rows: partsRows,
    },
    {
      title: 'Уникальные номера',
      cols: unCols,
      rows: unRows,
    },
    {
      title: 'Адреса хранения ',
      cols: stockCols,
      rows: stockRows,
    },
  ]

  const FieldsSection = (props: { title?: string, fields: InfoItemType[] }) => {
    return (
      <div className={styles.sectionWrapper}>
        {props.title ? <Text as={'p'} size={'l'} weight='bold' children={props.title}/> : null}
        <div className={styles.sectionFieldsWrapper}>
          {props.fields.map((item, i) => {
            const value = `${item.value}`
            return (
              <InputWrapper
                key={i}
                size={'s'}
                label={item.label}
                value={value}
                className={styles.fieldItem}
                disabled
              />
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div>
      <Flex direction="column" align={'start'} gap={'m'}>

        <Text as={'h3'} size={'xl'}>{item?.title}</Text>

        <Img
          className={styles.img}
          src={item?.image || noImage}
          size={100}
          yandex
          modal
        />
      </Flex>
      <div className={styles.fieldsWrapper}>
        <FieldsSection
          fields={nomenclatureInfo}
        />
        <FieldsSection
          title={'Организация'}
          fields={merchantInfo}
        />
        <div className={styles.dimensionsWrapper}>
          <FieldsSection
            title={'Габариты'}
            fields={weightDimensions}
          />
          <Button
            label={'Поменять вес/размер'}
            onClick={() => setIsModalOpen(true)}
          />
        </div>
      </div>
      <div>
        {tables.map((item, i) => (
          <Flex key={i} gap={'xs'} direction="column">
            <Text as={'p'} size={'l'} weight='bold' children={item.title}/>
            <Table
              className={styles.table}
              columns={item.cols as TableColumn<(typeof item.rows)[number]>[]}
              rows={item.rows}
            />
          </Flex>
        ))}
      </div>

      {isModalOpen ? (
        <WeightDimensionsModal
          item={{
            id: skuId,
            weight_plan: item.weight_plan,
            weight:
              item.weight_fact !== undefined
                ? convertWeight('g', 'kg', Number(item?.weight_fact))
                : undefined,
            dimensions:
              item?.dimensions_fact && Object.keys(item?.dimensions_fact).length
                ? convertDimensionsStr(
                  'mm',
                  'sm',
                  item.dimensions_fact as IDimensions,
                )
                : undefined,
          }}
          top={
            <SkuItemPreview
              image={item.image}
              title={item.title}
              barcode={item.barcodes[0].barcode}
            />
          }
          onSubmit={() => refetch()}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          withClose
          hardClose
          alwaysUpdate
          withZero
        />
      ) : null}
    </div>
  )
}

Item.displayName = 'Item'

export default Item
