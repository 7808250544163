import { CHZ_CODE } from '@shared/helpers/chz'

export const MOCK_GET_SUPPLY_BY_ID: any = {
  'errors': [],
  'skus': [
    {
      'article': 'demo1026',
      'barcodes': [
        {
          'active': true,
          'barcode': '1',
          'type': 'OZON',
          'used_in_last_supply': false,
        },
      ],
      'batch_accounting': true,
      'batch_expdate_mandatory': true,
      "expiration_months": 12,
      'batches': [
        {
          id: `some-batch-id1`,
          num: `batchNum1`,
          exp_date: `2022-10-01`,
        },
        {
          id: `some-batch-id2`,
          num: `batchNum2`,
          exp_date: `2022-10-02`,
        },
        {
          id: `some-batch-id3`,
          num: `batchNum3`,
          exp_date: `2022-10-03`,
        },
      ],
      'dimensions_fact': {
        'depth': 210,
        'height': 120,
        'width': 210,
      },
      'dimensions_plan': {
        'depth': 100,
        'height': 100,
        'width': 100,
      },
      'external_id': '1026',
      'fragile': false,
      'id': 'TM-1026',
      'image': 'https://storage.yandexcloud.net/images-k2c/8aa184e8-9bc8-428e-8edb-365f9c35397d.jpg',
      'image_preview': 'https://storage.yandexcloud.net/images-k2c/8aa184e8-9bc8-428e-8edb-365f9c35397d-100.jpg',
      'images': [
        {
          'position': 0,
          'preview_url': 'https://storage.yandexcloud.net/images-k2c/8aa184e8-9bc8-428e-8edb-365f9c35397d-100.jpg',
          'url': 'https://storage.yandexcloud.net/images-k2c/8aa184e8-9bc8-428e-8edb-365f9c35397d.jpg',
        },
      ],
      'merchant': {
        'cam_id': '101',
        'contract_date': '2022-09-25',
        'contract_num': '1001',
        'enabled': true,
        'id': '2b78266b-0405-48f6-a263-3dba1869209f',
        'login': 'test@new-wms.ru',
        'service_group_set_id': '2086eb6b-a256-4831-8575-ae8a4fe788d1',
        'title': 'Test new WMS merchant',
        'trade_title': 'Test new WMS merchant',
      },
      'parts': [],
      'require_dimensions': false,
      'require_weight': false,
      'scan_serial_numbers': [],
      'title': 'Наушники (демо товар)',
      'type': 'SKU',
      'weight_fact': 2000,
      'weight_plan': 1000,
    },
  ],
  'success': true,
  'supply': {
    'activated': true,
    'create_ts': '2023-12-14T11:02:28.130722',
    'date': '2023-12-14T11:02:27.883000',
    'supply_form': [],
    'executor': {
      'enabled': true,
      'id': 'd5e1534b-6af3-44bb-82a6-b71ac5d02f90',
      'login': '12354321',
      'name': 'Юлия',
      'patronymic': '',
      'position': 'DEVELOPER',
      'roles': [],
      'surname': 'П',
    },
    'external_id': '1037',
    'files': [],
    'has_diffs': false,
    'id': 'S-TM-1037',
    'items': [
      {
        'id': '40b710b4-c4e4-42d2-996f-09cd7b66420a',
        'sku_batch_id': 'some-batch-id1',
        'price_data': {
          'currency': 'RUB',
          'price': 3,
          'vat': 'NO_VAT',
        },
        'quantity': 2,
        'scan_serial_numbers': [],
        'sku_id': 'TM-1026',
      },
      {
        'id': '40b710b4-c4e4-42d2-996f-09cd12444454a',
        'sku_batch_id': 'some-batch-id2',
        'price_data': {
          'currency': 'RUB',
          'price': 3,
          'vat': 'NO_VAT',
        },
        'quantity': 2,
        'scan_serial_numbers': [],
        'sku_id': 'TM-1026',
      },
    ],
    'merchant': {
      'cam_id': '101',
      'contract_date': '2022-09-25',
      'contract_num': '1001',
      'enabled': true,
      'id': '2b78266b-0405-48f6-a263-3dba1869209f',
      'login': 'test@new-wms.ru',
      'service_group_set_id': '2086eb6b-a256-4831-8575-ae8a4fe788d1',
      'title': 'Test new WMS merchant',
      'trade_title': 'Test new WMS merchant',
    },
    'receipt_started_at': '2023-12-14T12:16:53.000000',
    // "supply_type": "FROM_SUPPLIER",
    "supply_type": "DEFAULT",
    'receipts': [
      {
        'activated': true,
        // 'condition': 'DEFECT',
        'condition': 'GOOD',
        'create_ts': '2023-12-15T11:36:16.891960',
        'date': '2023-12-15T11:36:16.881045',
        'executor': {
          'enabled': true,
          'id': '69e2c608-88c9-4a32-87a9-1401736228b0',
          'login': '123',
          'name': '123',
          'patronymic': '123',
          'position': 'GENERAL_DIRECTOR',
          'roles': [
            'e545d9ad-c079-4c11-9005-a775438549a2',
          ],
          'surname': '123',
        },
        'id': 'RT-TM-0000-0142',
        'items': [
          {
            'acceptance_type': 'PIECE',
            'barcode_used': '63547534',
            'id': '40b710b4-c4e4-42d2-996f-09cd7b66420a',
            'multiplicity': 1,
            'quantity': 1,
            'serial_numbers': [],
            'sku_id': 'TM-1026',
            'sku_batch_id': 'some-batch-id2',
          }
        ],
        'merchant': {
          'cam_id': '101',
          'contract_date': '2022-09-25',
          'contract_num': '1001',
          'enabled': true,
          'id': '2b78266b-0405-48f6-a263-3dba1869209f',
          'login': 'test@new-wms.ru',
          'service_group_set_id': '2086eb6b-a256-4831-8575-ae8a4fe788d1',
          'title': 'Test new WMS merchant',
          'trade_title': 'Test new WMS merchant',
        },
        // 'status': 'CLOSED',
        'status': 'OPEN',
        'tare_barcode': 'TARA0000393',
        'update_ts': '2023-12-22T22:47:18.048818',
        'uuid': '596ffadd-3cc4-4dbb-8358-332c4d96e934',
        'workspace': {
          'barcode': 'XX-1',
          'condition': 'GOOD',
          'id': 'a94203b3-67d7-42b7-9642-ef510c3e9323',
          'title': 'XX-1',
          'zone_id': '26826c40-ac61-4e9f-a5ea-1101ef5521f2',
        },
      },
    ],
    'status': 'PROCESSING',
    'supplier': {
      'id': 'e8771d15-0809-478c-8c31-99a687c5ebd7',
      'inn': '5404084407',
      'kpp': '540401001',
      'ogrn': '1195476004789',
      'title': 'ООО "ПОСТАВЩИК 1"',
    },
    'update_ts': '2023-12-14T12:16:53.989066',
    'uuid': '6e51c3ae-4492-4c15-bfa0-3f3424518a6f',
    'workspace': {
      'barcode': 'DM-22',
      'condition': 'GOOD',
      'id': '7a9cb4f2-19fc-4c55-a757-d216f0b8a861',
      'title': 'DM-22',
      'zone_id': '26826c40-ac61-4e9f-a5ea-1101ef5521f2',
    },
  },
}
