import { IconCalendar } from '@consta/icons/IconCalendar'
import { FieldRow, FieldType } from '@widgets/GeneratorFields'
import {
  ConditionEnum,
  IDeliveryItem,
  ISupplyConditionDescription,
  IUserData,
  IZone,
  ORDER_STATUSES_DESCRIPTION,
} from 'src/interfaces'
import { generateStatusesOptions, getFullPersonName } from '@shared/helpers'

export const generateFilters = (
  users: IUserData[],
  deliveries: IDeliveryItem[],
  zones: IZone[],
): FieldRow[] => [
  {
    hidden: true,
    fields: [
      {
        type: 'input',
        fieldProps: {
          id: 'search',
          viewDescription: 'Поиск',
        },
      },
    ],
  },
  {
    fields: [
      {
        type: 'input',
        fieldProps: {
          id: 'ids',
          label: 'Поиск по полному номеру заказа',
          viewDescription: 'Номер заказа',
        },
      },
      {
        type: 'input',
        fieldProps: {
          id: 'place_barcodes',
          label: 'Поиск по ШК грузовых мест',
          viewDescription: 'ШК грузового места',
        },
      },
      {
        type: 'input',
        fieldProps: {
          id: 'address_search',
          label: 'Адрес',
          viewDescription: 'Адрес',
        },
      },
    ],
  },
  {
    fields: [
      {
        type: FieldType.combobox,
        fieldProps: {
          id: 'sku_condition',
          label: 'Состояние товара',
          items: [
            {
              id: ConditionEnum.GOOD,
              label: ISupplyConditionDescription[ConditionEnum.GOOD],
            },
            {
              id: ConditionEnum.DEFECT,
              label: ISupplyConditionDescription[ConditionEnum.DEFECT],
            },
          ],
          viewDescription: 'Состояние',
        },
      },
      {
        type: FieldType.combobox,
        fieldProps: {
          id: 'status',
          label: 'Статус',
          items: generateStatusesOptions(ORDER_STATUSES_DESCRIPTION),
          viewDescription: 'Статус',
        },
      },
      {
        type: FieldType.MERCHANTS,
        fieldProps: {
          id: 'merchant',
          isMultiple: true,
          label: 'Организации',
          viewDescription: 'Организации',
        },
      },
    ],
  },
  {
    fields: [
      {
        type: FieldType.combobox,
        fieldProps: {
          id: 'executor_id',
          label: 'Исполнитель',
          items: users.map(({ id, name, surname, patronymic }) => ({
            id: id,
            label: getFullPersonName({ name, surname, patronymic }),
            value: id,
          })),
          viewDescription: 'Исполнитель',
        },
      },
      {
        type: FieldType.combobox,
        fieldProps: {
          id: 'delivery_companies',
          isMultiple: true,
          label: 'Способы доставки',
          items: deliveries.map(({ value, title }) => ({
            id: value,
            label: title,
            value: value,
          })),
          viewDescription: 'Способы доставки',
        },
      },
      {
        type: 'input',
        fieldProps: {
          id: 'delivery_contract_title',
          label: 'Договор доставки',
          viewDescription: 'Договор доставки',
        },
      },
    ],
  },
  {
    fields: [
      {
        jointFields: [
          {
            type: 'input',
            fieldProps: {
              id: 'total_quantity_from',
              isInteger: true,
              label: 'Товаров в заказе',
              placeholder: 'От',
              form: 'defaultBrick',
              viewDescription: 'От',
            },
          },
          {
            type: 'input',
            fieldProps: {
              id: 'total_quantity_to',
              isInteger: true,
              placeholder: 'До',
              form: 'clearDefault',
              viewDescription: 'До',
            },
          },
        ],
      },
      {
        jointFields: [
          {
            type: FieldType.calendar,
            fieldProps: {
              id: 'shipping_date_from',
              label: 'Время отгрузки',
              placeholder: 'От',
              form: 'defaultBrick',
              rightSide: IconCalendar,
              viewDescription: 'От',
            },
          },
          {
            type: FieldType.calendar,
            fieldProps: {
              id: 'shipping_date_to',
              placeholder: 'До',
              form: 'clearDefault',
              rightSide: IconCalendar,
              viewDescription: 'До',
            },
          },
        ],
      },
      {
        type: FieldType.combobox,
        fieldProps: {
          id: 'warehouse_zone_ids',
          isMultiple: true,
          label: 'Складских зон',
          items: zones.map(({ id, title }) => ({
            id: id,
            label: title,
            value: id,
          })),
          viewDescription: 'Зоны',
        },
      },
    ],
  },
  {
    fields: [
      {
        jointFields: [
          {
            type: FieldType.calendar,
            fieldProps: {
              id: 'date_from',
              label: 'Дата',
              type: 'date-time',
              placeholder: 'C',
              form: 'defaultBrick',
              rightSide: IconCalendar,
              viewDescription: 'С',
            },
          },
          {
            type: FieldType.calendar,
            fieldProps: {
              id: 'date_to',
              placeholder: 'По',
              type: 'date-time',
              form: 'clearDefault',
              rightSide: IconCalendar,
              viewDescription: 'По',
            },
          },
        ],
      },
      {
        jointFields: [
          {
            type: FieldType.calendar,
            fieldProps: {
              type: 'date-time',
              id: 'paused_from',
              label: 'На паузе',
              placeholder: 'От',
              form: 'defaultBrick',
              rightSide: IconCalendar,
              viewDescription: 'От',
            },
          },
          {
            type: FieldType.calendar,
            fieldProps: {
              type: 'date-time',
              id: 'paused_to',
              placeholder: 'До',
              form: 'clearDefault',
              rightSide: IconCalendar,
              viewDescription: 'До',
            },
          }
        ],
      },
    ],
  },
  {
    fields: [
      {
        type: FieldType.BOOLEAN,
        fieldProps: {
          id: 'has_transport_place',
          label: 'Есть транспортные места',
          viewDescription: 'Есть транспортные места',
        },
      },
      {
        type: FieldType.BOOLEAN,
        fieldProps: {
          id: 'has_unblocked_stock',
          label: 'Есть не заблокированный остаток',
          viewDescription: 'Есть не заблокированный остаток',
        },
      },
    ],
  },
]
