import React from 'react'
import { EditCreateUserCompanyForm, UserCompanyDto } from '@entities/UserCompany'
import { Modal } from '@/components'

interface CreateUserCompanyModalProps {
	company?: UserCompanyDto
	onCancel: () => void
	onSubmit: () => void
}

export const EditCreateUserCompanyModal = (props: CreateUserCompanyModalProps) => {
	const {
		company,
		onCancel,
		onSubmit,
	} = props

	return (
		<Modal
			isOpen={true}
			withClose
			onClose={onCancel}
			onOverlayClick={onCancel}
			size="min"
			title={`${company?.id ? 'Редактирование' : 'Создание'} компании пользователя`}
			subtitle={' '}
		>
			<EditCreateUserCompanyForm
				company={company}
				onCancel={onCancel}
				onSubmit={onSubmit}
			/>
		</Modal>
	);
};
