import { useMutation } from 'react-query'
import api from '@shared/api'

interface IParams {
  id: string
  data: { data: string, title: string }
}

const useSendUserSignature = () => {
  const sendSignature = async (params: IParams) => {
    const result = await api.post(
      `/warehouse/user/${params.id}/signature`,
      params.data,
    )
    if (!result?.data?.success) {
      throw result
    }
    return result
  }

  return useMutation(sendSignature)
}

export default useSendUserSignature
