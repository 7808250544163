import { ComboboxWrapper } from '@/components'
import { ComponentProps, useMemo } from 'react'
import { PackagingSettingsDescription } from '@/interfaces/packaging'
import { ComboboxItemDefault } from '@consta/uikit/__internal__/src/components/Combobox/helpers'

type Props = Omit<ComponentProps<typeof ComboboxWrapper>, 'items'> & {
  valueId?: string
}
export const PackagingSettingTypeCombobox = (props: Props) => {

  const items: ComboboxItemDefault[] = useMemo(() => Object.keys(PackagingSettingsDescription).map(key => {
    return ({id: key, label:PackagingSettingsDescription[key]})
  }), [PackagingSettingsDescription])

  const valueById = useMemo(() => items.find(item => item.id === props.valueId), [items, props.valueId])

  return (
    <ComboboxWrapper
      items={items}
      { ...props }
      value={props.value || valueById}
    />
  )
}