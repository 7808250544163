import { UserCompanyDto } from "@/newSrc/entities/UserCompany"

export enum IUserRoles {
  operator = 'operator',
  dispatcher = 'dispatcher',
}

export interface ICurrentUser {
  username?: string
  userBarcode?: string
  workTable?: string
  role?: IUserRoles // todo роль пользователя
  newDesign?: boolean,
}

export enum IUserPosition {
  GENERAL_DIRECTOR = 'GENERAL_DIRECTOR',
  DEPUTY_GENERAL_DIRECTOR = 'DEPUTY_GENERAL_DIRECTOR',
  SHIFT_SUPERVISOR = 'SHIFT_SUPERVISOR',
  MANAGER = 'MANAGER',
  OPERATOR = 'OPERATOR',
  DISPATCHER = 'DISPATCHER',
}
/** В новой ВМС (возможно и в 1с будет) */
export interface IUserData {
  /** Флаг активности */
  enabled: boolean
  /** Идентификатор */
  id: string
  /** Логин */
  login: string
  /** Фамилия */
  surname: string
  /** Имя */
  name: string
  /** Отчество */
  patronymic?: string
  /** Позиция в компании */
  position?: IUserPosition
  /** Идентификаторы ролей */
  roles: string[]
  /** Пароль */
  password?: string
  company?: UserCompanyDto
}
