import { useHistory } from 'react-router-dom'
import { useEffect, useMemo } from 'react'
import { useDebounce } from '@shared/hooks/useDebounce'

type FilterProps = {
  delay?: number
  withoutHistory?: boolean
  defaultState?: any
  onChangeFilterState?: () => void
}


export const useFilterState = <T = Record<string, any>>(props?: FilterProps) => {
  const history = useHistory()

  const searchURI = history?.location?.search
  const searchStr = decodeURI(searchURI)?.substring(1)
  const defaultFilterState = props?.defaultState || searchStr ? JSON.parse(searchStr) : {}

  /** Стейт для поиска и отображения данных фильтров */
  const [filterStateDebounced, filterState, setFilterState] = useDebounce<T>(defaultFilterState, props?.delay)


  // сохраняем фильтры в url
  useEffect(() => {
    if (props?.withoutHistory) return

    const filterStateStr = JSON.stringify(filterState)
    if (filterStateStr === searchStr) return
    history?.push({search: filterStateStr})
  }, [searchStr, filterState, history])

  useEffect(() => {
    props?.onChangeFilterState?.()
  }, [filterState])


  const handleChangeFilter = (fieldId: string, value?: any) => {
    setFilterState((prev) => ({ ...prev, [fieldId]: value }))
  }


  return useMemo(() => ({
    filterStateDebounced,
    filterState,
    setFilterState,
    handleChangeFilter
  }),
    [filterStateDebounced, filterState, setFilterState]
  )
}