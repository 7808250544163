import { CellTypesEnum, ConditionEnum, ISelectOption } from '@/interfaces'

export const cellTypeOptions: ISelectOption[] = [
  {
    label: 'Ячейка',
    value: CellTypesEnum.cell,
  },
  {
    label: 'Паллета',
    value: CellTypesEnum.pallet,
  },
]

export const conditionTypeOptions: ISelectOption[] = [
  {
    label: 'Годный товар',
    value: ConditionEnum.GOOD,
  },
  {
    label: 'Брак',
    value: ConditionEnum.DEFECT,
  },
  {
    label: 'Просрочка',
    value: ConditionEnum.EXPIRED,
  },
]
