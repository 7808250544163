import api from '@shared/api'
import { ApiReqResult, ICountryItem } from '@/interfaces'
import { useQuery } from 'react-query'

const getDeliveryCodeList = async () => {
  const result = await api.get(`/enum/delivery-code`)
  if (!result.data?.success) {
    throw new Error(result.data.message)
  }
  return result.data
}

interface IResult extends ApiReqResult {
  values: ICountryItem[]
}

const useGetDeliveryCodeList = (
  callback?: (data: ICountryItem[]) => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useQuery<IResult>([`getDeliveryCodeList`], getDeliveryCodeList, {
    keepPreviousData: false,
    onSuccess: ({ success, values }) => {
      if (success) {
        callback?.(values)
      } else {
        error?.()
      }
    },
    onError: () => error?.(),
    onSettled: () => settled?.(),
  })
}

export default useGetDeliveryCodeList
