import { useMutation } from 'react-query'

import api from '@shared/api'
import { ApiReqResult } from '@/interfaces'

interface Params {
  companyId: string
}
interface Result extends ApiReqResult {
}

const useDeleteUserCompany = () => {
  return useMutation<Result, any, Params>(async ({ companyId }: Params) => {
    const result = await api.delete(`/warehouse/user-company/${companyId}`)
    if (!result?.data?.success) {
      throw result
    }
    return result.data
  })
}

export default useDeleteUserCompany
