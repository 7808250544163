import { Badge } from '@consta/uikit/Badge'
import React, { FC } from 'react'
import styles from './CurrentBase.module.scss'
import { useDatabases } from '@shared/hooks/settings'
import cx from 'classnames'

interface IProps {
  wrapperClassName?: string
  badgeClassName?: string
}

const CurrentBase: FC<IProps> = (props) => {
  const {
    badgeClassName,
    wrapperClassName
  } = props
  const { getDBOptionByStorage } = useDatabases()
  const currentBase = getDBOptionByStorage()
  return (
    <div className={cx(styles.wrapper, wrapperClassName)}>
      <Badge
        status={'warning'}
        view={'stroked'}
        size={'s'}
        label={`База: ${currentBase?.label || '-'}`}
        className={cx(styles.badge, badgeClassName)}
      />
    </div>
  )
}
export default CurrentBase