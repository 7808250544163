import { appLocalStorage } from '@shared/lib/storage'
import { useEffect, useState } from 'react'
import api from '@shared/api'
import isMobile from 'ismobilejs'
import eruda from 'eruda'
import { useAppContext } from '@shared/providers/AppContextProvider'

/**
 * Использовать строго в App пока не переделаем как-то иначе
 */

export const useAppInit = () => {

  const {
    setGlobalLoading,
    setAuthorized,
    setCurrentUser,
  } = useAppContext()

  const [isReady, setIsReady] = useState(false)

  const updateDataFromLocalStorage = async () => {
    /* Мутируем значения для корректной работы api с базами и токенами */
    const { token, currentUser, currentBase } = appLocalStorage

    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`
      setAuthorized(true)
    }
    if (currentUser) {
      setCurrentUser(currentUser)
    }
    if (currentBase) {
      api.defaults.baseURL = `https://${currentBase}`
    }
  }

  useEffect(() => {
    updateDataFromLocalStorage()
      .then(() => {
        setIsReady(true)
        setGlobalLoading(false)
        if (isMobile().any) {
          eruda.init()
        }
      })

    window.addEventListener('storage', updateDataFromLocalStorage)
    return () => {
      window.removeEventListener('storage', updateDataFromLocalStorage)
    }
  }, [])

  useEffect(() => {
    /**
     * Быстрое временное решение
     * Скрывает проблему ResizeObserver - loop limit exceeded
     * https://stackoverflow.com/a/76216986
     */
    const errorListener = (e: ErrorEvent) => {
      const errMessages = [
        'ResizeObserver loop completed with undelivered notifications.',
        'Script error.',
        'ResizeObserver loop limit exceeded',
      ]
      if (errMessages.includes(e.message)) {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div')
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay')
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none')
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none')
        }
      }
    }
    window.addEventListener('error', errorListener)
    return () => {
      window.removeEventListener('error', errorListener)
    }
  }, [])


  return {
    isReady,
    // selectedTheme,
  }
}