import React from 'react'

import { ConditionEnum, ITableColumn, ITableRow } from 'src/interfaces'
import { IRefundPlace, IRefundSku } from 'src/interfaces/refund/refund'
import { NomenclatureCell, TitleCell } from '../../../../../components'
import { BatchCell, ConditionCell, ImageCell } from 'src/components'
import styles from './documents-params.module.scss'
import { getBatchNameValue, getConditionNameValue, getNomenclatureNameValue } from '../../../../../helpers/common'
import { DangerouslyHTML } from '@shared/ui/DangerouslyHTML'

export const generateReturnColumns = (): ITableColumn[] => {
  return [
    {
      key: 'order_value',
      title: 'Заказ',
      title_txt: 'Заказ',
      renderCell: ({ row, isSubItem }) =>
        isSubItem ? (
          <ImageCell image={String(row.img)} />
        ) : (
          <DangerouslyHTML htmlStr={row.order as string} />
        ),
    },
    {
      key: 'name_value',
      title: 'Номенклатура',
      title_txt: 'Номенклатура',
      gridCellWidth: 'minmax(300px,1fr)',
      renderCell: ({ row, isSubItem }) =>
        isSubItem ? (
          <NomenclatureCell
            title={
              <TitleCell title={`${row.title}`} article={`${row.article}`} />
            }
            itemId={`${row.sku_id}` || '-'}
          />
        ) : null,
    },
    {
      key: 'batch_value',
      title: 'Партия',
      title_txt: 'Партия',
      renderCell: ({ row, isSubItem }) =>
        isSubItem ? (
          <BatchCell
            num={String(row.batchNum || '')}
            date={String(row.batchDate || '')}
          />
        ) : null,
    },
    {
      key: 'quantity',
      title: 'Количество',
      title_txt: 'Количество',
      align: 'center',
    },
    {
      key: 'condition_value',
      title: 'Качество',
      title_txt: 'Качество',
      align: 'center',
      renderCell: ({ row }) => (
        <ConditionCell condition={row.condition as ConditionEnum} />
      ),
    },
    {
      key: 'defect_type',
      title: 'Вид брака',
      title_txt: 'Вид брака',
      align: 'center',
    },
    {
      key: 'tare',
      title: 'Тара',
      title_txt: 'Тара',
    },
    {
      key: 'barcode',
      title: 'ШК',
      title_txt: 'ШК',
    },
    {
      key: 'serial_number',
      title: 'Уникальные номера',
      title_txt: 'Уникальные номера',
      renderCell: ({ row }) => (
        <div className={styles.tableCellSerialNumbers}>
          {((row.serial_numbers as string[]) || []).map((serialNumber) => (
            <p>{serialNumber}</p>
          ))}
        </div>
      ),
    },
    {
      key: 'photo',
      title: 'Фото',
      title_txt: 'Фото',
      renderCell: ({ row, isSubItem }) =>
        isSubItem ? (
          <div className={styles.photoWrapper}>
            {Array.isArray(row?.photo)
              ? row.photo.map((el: any) => (
                  <ImageCell
                    image={String(el.image)}
                    key={el.id}
                    yandex={false}
                  />
                ))
              : row.photo}
          </div>
        ) : null,
    },
  ]
}

export const generateReturnRows = (
  returns: IRefundPlace[],
  skus: IRefundSku[],
): ITableRow[] => {
  return returns.map((returnItem) => {
    return {
      id: returnItem.id,
      defaultIsExpanded: true,
      order: `${returnItem.order_id} <br/> ${
        returnItem.merchant?.title || '-'
      }`,
      order_value: `${returnItem.order_id} \n ${
        returnItem.merchant?.title || '-'
      }`,
      merchant: returnItem.merchant?.title || '-',
      barcode: returnItem.barcode || '-',
      quantity: returnItem.items.reduce((acc, item) => acc + item.quantity, 0),
      photo: 'fdf',

      subItems: returnItem.items
        .map((item) => {
          const skuItem = skus.find((sku) => sku.id === item.sku_id)
          const batch = skuItem?.batches?.find(
            (skuBatch) => skuBatch.id === item.sku_batch_id,
          )
          return skuItem
            ? {
                id: skuItem.id,
                title: skuItem.title || '',
                article: skuItem.article || '',
                tare: item.tare_barcode || '-',
                sku_id: item?.sku_id || '',
                img: skuItem.image,
                name_value: getNomenclatureNameValue(skuItem),
                batch_value: getBatchNameValue(batch),
                batchNum: batch?.num || '-',
                batchDate: batch?.exp_date || '-',
                quantity: item.quantity,
                serial_numbers:
                  item.serial_numbers?.map((serial) => serial.value) || [],
                condition: item.condition,
                condition_value: getConditionNameValue(
                  item.condition as ConditionEnum,
                ),
                barcode: item.barcode_used,
                defect_type: item.defect_type
                    ? item.defect_type.title
                    : null,
                photo:
                  item.files &&
                  item.files.map((icon) => {
                    return {
                      id: icon.id,
                      image: icon.url,
                    }
                  }),
              }
            : undefined
        })
        .filter(Boolean) as ITableRow[],
    }
  })
}
