import React, { CSSProperties, DOMAttributes, ElementType, forwardRef } from 'react'
import cx from 'classnames'
import styles from './Flex.module.scss'
import { GridPropGap } from '@consta/uikit/Grid'

interface FlexProps extends DOMAttributes<any> {
  as?: ElementType,
  pointer?: boolean
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  justifyContent?: 'start' | 'end' | 'center' | 'between' | 'around'
  align?: 'start' | 'end' | 'center'
  fullWidth?: boolean // width 100%
  gap?: GridPropGap
  children: React.ReactNode
  className?: string
  onClick?: React.MouseEventHandler,
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse'
  style?: CSSProperties,
  hidden?: boolean | undefined;
}

export const Flex = forwardRef((props: FlexProps, ref) => {
  const {
    as: Tag = 'div',
    gap,
    align,
    pointer,
    justifyContent,
    direction,
    className,
    onClick,
    fullWidth,
    flexWrap,
    style,
    hidden,

    ...restProps
  } = props
  return (
    <Tag
      ref={ref}
      hidden={hidden}
      style={style}
      className={cx(
        styles.Flex,
        className,
        { [styles.fullWidth]: fullWidth },
        { [styles[`pointer`]]: pointer },
        { [styles[`flexWrap${flexWrap}`]]: Boolean(flexWrap) },
        { [styles[`gap${gap}`]]: Boolean(gap) },
        { [styles[`align${align}`]]: Boolean(align) },
        { [styles[`justifyContent${justifyContent}`]]: Boolean(justifyContent) },
        { [styles[`direction${direction}`]]: Boolean(direction) },
      )}
      onClick={onClick}
      children={props.children}
      {...restProps}
    />
  )
})
