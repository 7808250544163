import { ValueOf } from '@shared/helpers'
import { BadgePropStatus } from '@consta/uikit/Badge'


export const ReplaceStatusEnum: Record<string, string> = {
  awaitingPlacing: 'AWAITING_PLACING',
  awaitingPicking: 'AWAITING_PICKING',
  awaitingRelabeling: 'AWAITING_RELABELING',
  placing: 'PLACING',
  picking: 'PICKING',
  relabeling: 'RELABELING',
  done: 'DONE',
} as const
export type ReplaceStatusEnum = ValueOf<typeof ReplaceStatusEnum>

export const REPLACE_STATUSES: Record<ReplaceStatusEnum | string, string> = {
  [ReplaceStatusEnum.awaitingPlacing]: 'Ожидает',
  [ReplaceStatusEnum.awaitingPicking]: 'Ожидает',
  [ReplaceStatusEnum.awaitingRelabeling]: 'Ожидает',
  [ReplaceStatusEnum.placing]: 'Размещение',
  [ReplaceStatusEnum.picking]: 'Отбор',
  [ReplaceStatusEnum.relabeling]: 'Перемаркировка',
  [ReplaceStatusEnum.done]: 'Выполнен',
}

export const REPLACE_STATUS_BADGE_TYPE: Record<ReplaceStatusEnum | string, BadgePropStatus> = {
  [ReplaceStatusEnum.awaitingPlacing]: 'normal',
  [ReplaceStatusEnum.awaitingPicking]: 'normal',
  [ReplaceStatusEnum.awaitingRelabeling]: 'normal',
  [ReplaceStatusEnum.placing]: 'system',
  [ReplaceStatusEnum.picking]: 'system',
  [ReplaceStatusEnum.relabeling]: 'system',
  [ReplaceStatusEnum.done]: 'success',
}
