import { useMutation } from 'react-query'

import api from '@shared/api'

import { ApiReqResult } from 'src/interfaces'

// http://wms-test.kak2c.ru/warehouse-api/warehouse/workspace

interface Result extends ApiReqResult {
  id: string
}
interface DeleteJobsParams {
  data: string[]
}

const useDeleteJobs = () => {
  return useMutation(async ({ data }: DeleteJobsParams) => {
    const path = `/warehouse/workspace`
    const result = await api.delete(path, { data })

    if (!result?.data?.success) {
      throw result
    }
    return result.data as Result
  })
}

export default useDeleteJobs
