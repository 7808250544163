import React from 'react'

export interface ISideTab {
  label: string
  value: string
  Component: React.FC<ISection>
}

export interface IHeaderTab {
  label: string
  value: string
  /** Разделы бокового меню */
  sideTabs: { [key: string]: ISideTab }
}

export interface ISection {
  /** Дополнительный класс стилей */
  className?: string
  /** Название раздела бокового меню */
  title?: string
}

export enum PopupZoneType {
  create = 'create',
  edit = 'edit',
}

export enum ValidateErrors {
  isEmpty = 'Поле не может быть пустым',
  notMatch = 'Значения должны совпадать по типу',
  isTooMuch = 'Начальное значение больше конечного',
}

export interface IRouteParams {
  page?: string
  section?: string
  type?: string
  id?: string
}

export interface IFormAddress {
  /** Район */
  area?: string
  /** Корпус */
  block?: string
  /** Строение */
  building?: string
  /** Город */
  city?: string
  /**  Страна*/
  country?: string
  /** Квартира/Офис */
  flat?: string
  /** Этаж */
  floor?: string
  /** Полный адрес */
  full_address?: string
  /** Дом */
  house?: string
  /** Область */
  region?: string
  /** Населенный пункт */
  settlement?: string
  /** Улица */
  street?: string
  /** Почтовый индекс */
  zip?: string
}

export interface IFormLegalEntity {
  id?: string
  inn?: string
  kpp?: string
  ogrn?: string
  title?: string
  legal_address?: IFormAddress
}