import React, { FC } from 'react'
import { useParams } from 'react-router-dom'

import type { ISection } from '../../types'
import { IRouteParams } from '@pages/DirectoriesPage/types'
import CategoriesList from './pages/CategoriesList'

interface Props extends ISection {}

const Categories: FC<Props> = (props) => {
  const { title = '' } = props
  const { type = 'CategoriesList' } = useParams<IRouteParams>()

  return <CategoriesList title={title}/>
}

export default Categories
