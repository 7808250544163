import React, { useState } from 'react'
import cx from 'classnames'
import styles from './PrintCell.module.scss'
import { Button } from '@shared/ui/btns/Button'
import { ButtonPropSize, ButtonPropView } from '@consta/uikit/__internal__/src/components/Button/Button'
import { IconPrinter } from '@shared/assets/icons/IconPrinter'

interface CollapseColumnProps {
  multiplePrintItems?: {title: string, action: () => void, hidden?: boolean}[]
  className?: string,
  wrapperClassName?: string,
  onClick?: (e: React.MouseEvent) => void
  view?: ButtonPropView
  size?: ButtonPropSize
  align?: 'left' | 'right'
}

export const PrintCell = (props: CollapseColumnProps) => {
  const {
    multiplePrintItems,
    view = 'clear',
    size = 's',
    align = 'right',
    className,
    wrapperClassName,
    onClick
  } = props

  const [showChoices, setShowChoices] = useState(false)

  const handleClickBtn = (e: React.MouseEvent) => {
    if (multiplePrintItems) {
      setShowChoices(true)
      return
    }
    onClick?.(e)
  }

  return (
    <div className={cx(styles.wrapper, wrapperClassName, styles[`align-${align}`])}>
      <Button
        onlyIcon
        className={cx(styles.button, className)}
        iconLeft={IconPrinter}
        view={view}
        size={size}
        iconSize={'m'}
        onClick={handleClickBtn}
      />

      {showChoices && multiplePrintItems ? (
        <ul className={styles.stickerBtns}>
          {
            multiplePrintItems.map((item) => (
              <li
                onClick={(e) => {
                  e.preventDefault()

                  if (item.hidden) return

                  item.action()
                  setShowChoices(false)
                }}
                style={{ display: !item.hidden ? 'block' : 'none' }}
              >
                {item.title}
              </li>
            ))
          }
        </ul>
      ) : null}
    </div>
  )
}
