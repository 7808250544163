import React, { useEffect, useState } from 'react'

import styles from './weight-place-modal.module.scss'

import img from './assets/weightImg.svg'

import { Button } from '@shared/ui/btns/Button'

import { EnterHandler, InputWrapper, Modal } from 'src/components/index'

import { checkWeight, convertWeight } from '@shared/helpers'

import { TextFieldPropValue } from '@consta/uikit/TextField'
import { ModalProps } from 'src/interfaces'
import { useSound } from '@shared/providers/SoundProvider'
import { useAppContext } from '@shared/providers/AppContextProvider'


interface IProps extends ModalProps {
  withGlobalLoading?: boolean
  onSubmit: (weight: number) => void
  maxWeight: number | null
  title?: string
  subtitle?: string
  errorWeight?: string | null
  clearErrorWeight?: () => void
  withoutClose?: boolean
  customFooter?: React.ReactNode
}

const PlaceWeightModal = (props: IProps) => {
  const {
    withGlobalLoading= true,
    isOpen,
    onSubmit,
    onClose,
    withoutClose,
    maxWeight,
    errorWeight,
    clearErrorWeight,
    customFooter,
    title = "Взвешивание посылки",
    subtitle = "Положите упаковку на весы",
  } = props
  const { setGlobalLoading } = useAppContext()

  const sound = useSound()

  const [weight, setWeight] = useState<string>('')
  const [error, setError] = useState<string>('')

  useEffect(() => {
    sound?.play('PACKING_PLACE_WEIGHT')
  }, [])

  const validateWeight = (value: string) => {
    if (!checkWeight(value)) {
      setError('Число должно быть больше 0 и не может оканчиваться на точку')
      return true
    }
    if (maxWeight && convertWeight('kg', 'g', Number(value)) > maxWeight) {
      setError(`Превышен максимальный вес места ${convertWeight('g', 'kg', maxWeight)} кг`)
      return true
    }
  }

  const handleSubmit = () => {
    if (!weight) {
      return
    }
    const weightHasProblem = validateWeight(weight)
    if (!weightHasProblem) {
      if (withGlobalLoading) {
        setGlobalLoading(true)
      }
      onSubmit(convertWeight('kg', 'g', Number(weight)))
    }
  }

  const handleChange = (value: TextFieldPropValue) => {
    setError('')
    if (errorWeight) {
      clearErrorWeight?.()
    }
    setWeight(String(value))
    if (value) {
      validateWeight(value)
    }
  }
  return (
    <EnterHandler onEnter={handleSubmit}>
      <Modal
        isOpen={isOpen}
        hasOverlay
        onOverlayClick={(): boolean => false}
        size="s"
        img={img}
        className={styles.weight}
        imgClass={styles.img}
        title={title}
        subtitle={subtitle}
      >
        <InputWrapper
          value={weight}
          id={'weight'}
          handleChange={handleChange}
          autoFocus
          className={styles.itemInput}
          rightSide="кг"
          placeholder={'Введите вес'}
          error={error || (errorWeight ?? '')}
          size="l"
          isWeight
        />

        {customFooter ? (
          <>{customFooter}</>
        ) : (
          <div className={styles.btns}>
            {!withoutClose ? (
              <Button label="Отменить" view="ghost" onClick={() => onClose()} />
            ) : (
              <span />
            )}
            <Button
              label="Подтвердить"
              disabled={!Number(weight) || !!error}
              onClick={handleSubmit}
            />
          </div>
        )}
      </Modal>
    </EnterHandler>
  )
}

export default PlaceWeightModal
