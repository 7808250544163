import React, { useState } from 'react'
import styles from './LevelCard.module.scss'
import { IconSettings } from '@consta/icons/IconSettings'
import Card from '../../../../components/Card/Card'
import { Text } from '@shared/ui/Text'
import { inclineByNum } from '../../../../utils'
import ButtonsForPrint from '../../../../components/ButonsForPrint/ButtonsForPrint'
import { ILvlItem, IZoneTree } from '../../types'
import { Button } from '@/components'
import { CheckedCellType } from '../../ZonesSection'
import { ICellPlace } from '@/interfaces'
import Collapse from '@/components/Collapse'
import { IconWarehouse } from '@shared/assets/icons/IconWarehouse'


interface LevelCardProps {
  zone: IZoneTree

  handlePrintStickers: (
    zoneId: string,
    printType: string,
    cellId?: string,
  ) => void
  handleBlockCells: (zoneId: string, actionType: 'block' | 'unblock') => void
  handleDeleteCells: (zoneId: string) => void
  isLoadingStickers: boolean
  checkedCells: CheckedCellType
  openZoneEdit: (zone: IZoneTree) => void
  handleDeleteZone: (zoneId: string) => void
  renderLvls: (items: ILvlItem[] | ICellPlace[], zoneId: string) => React.ReactNode
}

export const LevelCard = (props: LevelCardProps) => {
  const {
    zone,
    handlePrintStickers,
    handleBlockCells,
    handleDeleteCells,
    isLoadingStickers,
    checkedCells,
    openZoneEdit,
    handleDeleteZone,
    renderLvls
  } = props

  const [isOpen, setOpen] = useState(false)

  return (
    <Card className={styles.zoneWrapper} key={zone.id}>
      <Collapse
        isOpen={isOpen}
        setIsOpen={setOpen}
        label={
          <div className={styles.level}>
            <div className={styles.levelImgWrapper}>
              <IconWarehouse view={'secondary'}/>
            </div>

            <div className={styles.levelTitleWrapper}>
              <div className={styles.levelTitle_zone}>
                <Text view={'link'}>{zone.title}</Text>
              </div>
              <Text>
                {zone.cells_quantity}{' '}
                {inclineByNum(zone.cells_quantity, [
                  'ячейка',
                  'ячейки',
                  'ячеек',
                ])}
              </Text>
            </div>

            <div className={styles.zoneRightContainer}>
              {!!Object.keys(checkedCells[zone.id] || {}).length && (
                <ButtonsForPrint
                  handlePrintStickers={handlePrintStickers}
                  handleBlockCells={handleBlockCells}
                  handleDeleteCells={handleDeleteCells}
                  zoneId={zone.id}
                  isLoadingStickers={isLoadingStickers}
                />
              )}

              <Button
                size={'s'}
                label="Настроить"
                iconLeft={IconSettings}
                onClick={(e) => {
                  e.stopPropagation()
                  openZoneEdit(zone)
                }}
              />

              <Button
                size={'s'}
                className={styles.zoneDelButton}
                view={'ghost'}
                label="Удалить зону"
                onClick={(e) => {
                  e.stopPropagation()
                  handleDeleteZone(zone.id)
                }}
              />
            </div>
          </div>
        }
      >
        {
          isOpen
            ? renderLvls(zone.items, zone.id)
            : null
        }
      </Collapse>
    </Card>
  )
}
