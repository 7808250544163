import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { ComboboxWrapper, InputWrapper } from 'src/components'
import { ComboboxItemDefault } from '@consta/uikit/Combobox'
import { Button } from '@shared/ui/btns/Button'
import { IItemsService } from '../types'
import { Service } from 'src/interfaces'
import cx from 'classnames'
import styles from './ItemsModal.module.scss'

interface ItemsModalProps {
  className?: string
  uuid?: string
  services: ComboboxItemDefault[]
  setError: Dispatch<SetStateAction<boolean>>
  error: boolean
  originService?: Service
  onDelete: (uuid: string) => void
  onChange: (
    uuid: string,
    key: keyof IItemsService,
    value: ComboboxItemDefault | number | string,
  ) => void
  service?: IItemsService
  handleSearchServices: (val: string | null) => void
}

const ItemsModal: FC<ItemsModalProps> = ({
  className = '',
  uuid,
  services = [],
  onDelete,
  onChange,
  service,
  setError,
  error,
  originService,
  handleSearchServices
}) => {
  const [serviceItem, setServiceItem] = useState<ComboboxItemDefault | null>(null)

  useEffect(() => {
    if (service && service?.service_id && service?.service_id.label) {
      setServiceItem({
        label: service.service_id.label,
        id: String(service.service_id.groupId),
      })
    }
  }, [service?.service_id.groupId])

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.inputsRow}>
        <div className={styles.inputCell}>
          <ComboboxWrapper
            withApiSearch
            handleApiSearch={handleSearchServices}
            searchFunction={() => true}
            className={styles.inputCell}
            items={services}
            value={serviceItem}
            label={'Наименование'}
            size={'s'}
            onChange={(value) => {
              setError(false)
              setServiceItem(value)
              onChange(String(uuid), 'service_id', {
                label: value?.label || '',
                id: uuid || '',
                groupId: value?.id,
              })
            }}
          />
          {error ? (
            <div className={styles.error}>Поле не может быть пустым!</div>
          ) : null}
        </div>

        <span className={styles.measure}>{originService?.measure}</span>
        <div className={styles.inputCount}>
          <InputWrapper
            label={'Кол-во'}
            value={`${service?.quantity}`}
            size={'s'}
            isNumber
            handleChange={(value) => {
              if (value?.length === 6) {
                return
              }
              setError(false)
              onChange(String(uuid), 'quantity', value || '')
            }}
          />
          {error ? (
            <div className={styles.error}>Поле не может быть пустым!</div>
          ) : null}
        </div>
      </div>

      <div className={styles.buttonsContainer}>
        <Button
          label={'Удалить'}
          view="secondary"
          size={'s'}
          onClick={() => onDelete(String(uuid))}
        />
      </div>
    </div>
  )
}

ItemsModal.displayName = 'ModalItem'

export default ItemsModal
