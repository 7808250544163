import { FormType } from '@/components/InputWrapper'
import styles from './address.module.scss'

const addressObj = {
  area: '',
  block: '',
  building: '',
  city: '',
  country: '',
  flat: '',
  floor: '',
  house: '',
  region: '',
  settlement: '',
  street: '',
  zip: '',
}

type addressObjectKey = keyof typeof addressObj

export const addressData = [
  {
    label: 'Область',
    key: 'region' as addressObjectKey,
  },
  {
    label: 'Район',
    key: 'area' as addressObjectKey,
  },
  {
    label: 'Город',
    key: 'city' as addressObjectKey,
  },
  {
    label: 'Населенный пункт',
    key: 'settlement' as addressObjectKey,
  },
]

export const streetData = [
  {
    label: 'Индекс',
    key: 'zip' as addressObjectKey,
  },
  {
    className: styles.rowStreet,
    label: 'Улица',
    key: 'street' as addressObjectKey,
  },
]

export const houseData = [
  {
    label: 'Дом',
    key: 'house' as addressObjectKey,
    form: 'defaultClear' as FormType,
  },
  {
    label: 'Корпус',
    key: 'block' as addressObjectKey,
    form: 'brickClear' as FormType,
  },
  {
    label: 'Строение',
    key: 'building' as addressObjectKey,
    form: 'brickClear' as FormType,
  },
  {
    label: 'Этаж',
    key: 'floor' as addressObjectKey,
    form: 'brickClear' as FormType,
    type: 'number',
  },
  {
    label: 'Кв./Офис',
    key: 'flat' as addressObjectKey,
    form: 'brickDefault' as FormType,
  },
]
