import React, { useEffect, useState } from 'react'

import styles from './PrintPreviewCheckbox.module.scss'
import { PrintTypeEnum } from 'src/interfaces'
import { Checkbox } from '@consta/uikit/Checkbox'
import cx from 'classnames'


interface IProps {
  label?: string
  className?: string
  initialType?: PrintTypeEnum
  onChange: (printType: PrintTypeEnum) => void
  size?: "m" | "xs" | "s" | "l"
}

const PrintPreviewCheckbox = (props: IProps) => {
  const {
    className,
    label = 'Режим настройки печати',
    initialType = PrintTypeEnum.print,
    onChange,
    size
  } = props

  const [printType, setPrintType] = useState<PrintTypeEnum>(initialType)

  useEffect(() => {
    onChange(printType)
  }, [printType])


  return (
    <Checkbox
      label={label}
      checked={printType === PrintTypeEnum.preview}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        e.stopPropagation()
        setPrintType(PrintTypeEnum[e.target.checked ? 'preview' : 'print'])
      }}
      size={size}
      className={cx(styles.checkboxPrintType, className)}
    />
  )
}

export default PrintPreviewCheckbox
