import React from 'react'

interface Props {
  withEscaping?: boolean
  htmlStr: string
  className?: string
}

/**
 * Опасный рендер html строки, с экранированием спец символов
 */

export const DangerouslyHTML = ({ className, htmlStr, withEscaping }: Props) => {
	if (typeof htmlStr !== 'string') return null

	const escapedHtml = withEscaping
		? (htmlStr || '')
		.replaceAll('&', '&amp;')
		.replaceAll('<', '&lt;')
		.replaceAll('>', '&gt;')
		.replaceAll('"', '&quot;')
		.replaceAll('\'', '&#039;')
		: htmlStr

	return <div className={className} dangerouslySetInnerHTML={{ __html: escapedHtml }} />
}
