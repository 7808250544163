import React, { FC } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useGetMerchant } from '@/hooks'
import { IMerchant } from '@/interfaces'
import { IRouteParams } from '@pages/DirectoriesPage/types'
import { InputWrapper } from '@/components'

import styles from './item.module.scss'
import { Loader } from '@consta/uikit/Loader'
import { boolToText } from '@shared/helpers'
import { Flex } from '@shared/ui/Flex'
import { Text } from '@shared/ui/Text'


interface ItemProps {
  
}

const Item: FC<ItemProps> = () => {
  const {id = ''} = useParams<IRouteParams>()
  const history = useHistory()

  const {
    isFetching: isFetchingMerchant,
    data: merchantData,
    refetch: refetchMerchant
  } = useGetMerchant({ merchant_id: id })

  if (!merchantData || isFetchingMerchant) {
    return (
      <div><Loader /> Loading...</div>
    )
  }
  const merchant: IMerchant | undefined = merchantData?.merchant
  const info = [
    {
      label: 'Наименование',
      value: merchant?.title || '-',
    },
    {
      label: 'Маркетинговое наименование',
      value: merchant?.trade_title || '-',
    },
    {
      label: 'Идентификатор в системе CAM',
      value: merchant?.cam_id || '-',
    },
    {
      label: 'Дата заключения контракта',
      value: merchant?.contract_date || '-',
    },
    {
      label: 'Номер контракта',
      value: merchant?.contract_num || '-',
    },
    {
      label: 'Флаг активности',
      value: boolToText(merchant?.enabled),
    },

    {
      label: 'ИНН',
      value: merchant?.inn || '-',
    },
    {
      label: 'КПП',
      value: merchant?.kpp || '-',
    },
    {
      label: 'ОГРН',
      value: merchant?.ogrn || '-',
    },
    {
      label: 'Сортировочный номер',
      value: merchant?.sorting_number || '-',
    },
  ]
  return (
    <Flex direction={'column'} gap={'l'}>
      <Text as={'h3'} size={'l'}>{merchant?.title}</Text>

      <div className={styles.wrapper}>
        {
          info.map((item, i) => (
            <InputWrapper
              key={i}
              label={item.label}
              value={item.value}
              disabled
              size={'s'}
            />
          ))
        }
      </div>
    </Flex>
  )
}

Item.displayName = 'Item'

export default Item
