import { BadgePropStatus } from '@consta/uikit/Badge'
import { ConditionEnum, InventoryStatusEnum } from '../../interfaces'

export const INVENTORY_STATUSES: Record<InventoryStatusEnum | string, string> =
  {
    [InventoryStatusEnum.scheduled]: 'В ожидании',
    [InventoryStatusEnum.processing]: 'В работе',
    [InventoryStatusEnum.done]: 'Завершен',
    [InventoryStatusEnum.preparation]: 'Проверен',
  }
export const INVENTORY_STATUS_BADGE_TYPE: Record<
  InventoryStatusEnum | string,
  BadgePropStatus
> = {
  [InventoryStatusEnum.scheduled]: 'system',
  [InventoryStatusEnum.processing]: 'success',
  [InventoryStatusEnum.done]: 'error',
  [InventoryStatusEnum.preparation]: 'warning',
}
export const INVENTORY_CONDITION: Record<ConditionEnum | string, string> = {
  [ConditionEnum.GOOD]: 'хорошее',
  [ConditionEnum.DEFECT]: 'дефект',
}
export const INVENTORY_CONDITION_BADGE_TYPE: Record<
  ConditionEnum | string,
  BadgePropStatus
> = {
  [ConditionEnum.GOOD]: 'success',
  [ConditionEnum.DEFECT]: 'error',
}
