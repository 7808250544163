import React, { useEffect, useRef, useState } from 'react'
import styles from './PrintWidget.module.scss'
import { StickerData, StickerResult } from 'src/interfaces'
import { usePrint } from '@shared/hooks'
import { Modal } from 'src/components'

import { IconRestart } from '@consta/icons/IconRestart'
import { IconPlay } from '@consta/icons/IconPlay'
import { IconPause } from '@consta/icons/IconPause'
import { IconCancel } from '@consta/icons/IconCancel'
import { IconAllDone } from '@consta/icons/IconAllDone'
import { Button } from '@shared/ui/btns/Button'
import { Loader } from '@consta/uikit/Loader'


/**
 * ToDo подумать как вынести этот функционал !!!
 */


export enum StickerTypeEnum {
  nomenclature = 'Стикер номенклатуры',
}

export type IPrintWidgetItem = {
  id: string

  title: string // для кого стикер, например: название номенклатуры или шк тары и т.д.
  stickerType?: StickerTypeEnum // Тип стикера, например: стикер номенклатуры, стикер тары
  copies?: number // кол-во печатей
} & {
  action: () => Promise<any> // Запрос за стикером
  resultCallback?: (result: any) => StickerData // callback для обработки результатов функции и преведению их к формату StickerData
}

enum ISequenceItemStatus {
  initial = 'initial',
  success = 'success',
  starting = 'starting',
  failed = 'failed',
}

type ISequenceItemInfo = {
  status: ISequenceItemStatus,
  error?: string,
}

interface IPrintAction {
  onStartPrinting?: () => void
  onPausePrinting?: () => void
  onStopPrinting?: () => void
}

interface IPrintWidget extends IPrintAction {
  printList: IPrintWidgetItem[],
  onClose: () => void,
}

enum PrintProcessEnum {
  initial = 'initial',
  start = 'start',
  pause = 'pause',
  stop = 'stop',
}


type ISequence = (() => Promise<any>)[]

enum ISequenceStatus {
  initial = 'initial',
  start = 'start',
  pause = 'pause',
  stop = 'stop',
}

const useSequenceRunner = () => {
  const [sequence, setSequence] = useState<ISequence>([])
  const [lastIndex, setLastIndex] = useState<number>(0)

  /** Работа с статусом последовательности */
  const statusRef = useRef<ISequenceStatus>(ISequenceStatus.initial)
  const setStatus = (status: ISequenceStatus) => statusRef.current = status
  const setStartStatus = () => setStatus(ISequenceStatus.start)
  const setPauseStatus = () => setStatus(ISequenceStatus.pause)
  const setStopStatus = () => setStatus(ISequenceStatus.stop)

  useEffect(() => {
    if (lastIndex + 1 === sequence.length && sequence.length) {
      setStopStatus()
    }
  }, [lastIndex])


  /** Генератор последовательности */
  async function* sequenceGenerator(sequenceList: ISequence) {
    for (let item of sequenceList) {
      if ([ISequenceStatus.pause, ISequenceStatus.stop].includes(statusRef.current)) {
        return
      }
      yield await item()
    }
  }

  /**
   * Запуск последовательности
   * с корректировочным индексом (для продолжения)
   */
  const runGenerator = (sequence: ISequence, correctionIndex: number = 0) => {
    const gen = sequenceGenerator(sequence)
    sequence.forEach((seq, i) => {
      gen
        .next()
        .then((data) => {
          if (!data.done && data.value) {
            setLastIndex(i + correctionIndex)
          }
        })
    })
  }

  /** Начать последовательность */
  const startSequence = (sequence: ISequence) => {
    setSequence(sequence)
    setStartStatus()
    runGenerator(sequence)
  }

  /** Приостоновить последовательность */
  const pauseSequence = () => {
    setPauseStatus()
  }

  /** Продолжить последовательность */
  const continueSequence = () => {
    const nextItemIndex = lastIndex + 1
    const newSequence = sequence.slice(nextItemIndex)
    setStartStatus()
    runGenerator(newSequence, nextItemIndex)
  }

  /** Остановить последовательность */
  const stopSequence = () => {
    setLastIndex(0)
    setStopStatus()
  }

  return {
    sequence,
    lastIndex,
    sequenceStatus: statusRef.current,
    continueSequence,
    startSequence,
    pauseSequence,
    stopSequence,
  }
}


const PrintWidget = (props: IPrintWidget) => {
  const {
    onStartPrinting,
    onPausePrinting,
    onStopPrinting,
    onClose,
    printList,
  } = props

  const {
    sequence,
    sequenceStatus,
    startSequence,
    continueSequence,
    pauseSequence,
    lastIndex,
    stopSequence,
  } = useSequenceRunner()

  const { printSticker } = usePrint()

  const [printingProcess, setPrintingProcess] = useState<PrintProcessEnum>(PrintProcessEnum.initial)
  const [processInfo, setProcessInfo] = useState<Record<string, ISequenceItemInfo>>({})
  const [printingSequence, setPrintingSequence] = useState<IPrintWidgetItem[]>([])

  useEffect(() => {
    /** Матчинг статусов и действий */
    console.log(`Статус %c${sequenceStatus}`, 'font-size: 20px; color: #80c623;')
    switch (sequenceStatus) {
      case 'initial':
        break
      case 'stop':
        setPrintingProcess(PrintProcessEnum.stop)
        onStopPrinting?.()
        // onClose()
        break
      case 'start':
        setPrintingProcess(PrintProcessEnum.start)
        onStartPrinting?.()
        break
      case 'pause':
        setPrintingProcess(PrintProcessEnum.pause)
        onPausePrinting?.()
        break
    }
  }, [sequenceStatus])

  useEffect(() => {
    setPrintingSequence(preparePrintList(printList))
  }, [printList])


  const updateItemStatus = (index: number, status: ISequenceItemStatus, error?: string) => {
    setProcessInfo(prev => ({ ...prev, [index]: { status, error } }))
  }
  const preparePrintList = (printList: IPrintWidgetItem[]) => {
    /**
     *  Обрабатываем вызов callback-а и добавляем вызовы печати
     */
    return printList
      .filter(printItem => printItem.copies)
      .map((printItem, currIndex) => ({
        ...printItem,
        action: async () => new Promise(async (resolve, rej) => {
          updateItemStatus(currIndex, ISequenceItemStatus.starting)
          const requestResult = await printItem.action()
            .catch(() => {
              updateItemStatus(currIndex, ISequenceItemStatus.failed, 'Ошибка запроса')
            })

          // Обработка результата запроса, для обработки в нужный формат
          const stickerData: StickerResult = printItem.resultCallback?.(requestResult) || requestResult

          printSticker(stickerData.sticker.printer, stickerData.sticker.content, {
            stickerExists: stickerData.stickerExists,
            copies: printItem.copies,
          })
            .then(() => updateItemStatus(currIndex, ISequenceItemStatus.success))
            .catch((err) => {
              updateItemStatus(currIndex, ISequenceItemStatus.failed, 'Ошибка печати')
            })
            .finally(() => {
              /** Резолвим в любом случае */
              resolve(true)
            })
        }),
      }))
  }

  const handlePlay = () => {
    startSequence(printingSequence.map(item => item.action))
  }
  const handlePause = pauseSequence
  const handleContinue = continueSequence
  const handleClose = () => {
    stopSequence()
    onClose()
  }

  return (
    <Modal
      title='Процесс печати'
      subtitle=''
      isOpen
      withClose
      className={styles.PrintWidget}
      onClose={handleClose}
    >
      <div className={styles.sequence}>
        {
          printingSequence.map((printItem, i) => {
            return (
              <PrintItem
                key={printItem.id}
                printingProcess={printingProcess}
                printItem={printItem}
                printItemInfo={processInfo[`${i}`]}
              />
            )
          })
        }
      </div>
      <div className={styles.tools}>
        {
          printingProcess === PrintProcessEnum.stop ?
            null
            : printingProcess === PrintProcessEnum.start ? (
              <Button
                onlyIcon
                view='secondary'
                iconLeft={IconPause}
                onClick={handlePause}
              />
            ) : (
              <Button
                onlyIcon
                view='primary'
                iconLeft={IconPlay}
                onClick={printingProcess === PrintProcessEnum.pause ? handleContinue : handlePlay}
              />
            )
        }
      </div>
    </Modal>
  )
}

interface PrintItemProps {
  printingProcess: PrintProcessEnum
  printItem: IPrintWidgetItem
  printItemInfo?: ISequenceItemInfo
}

const PrintItem = React.memo((props: PrintItemProps) => {
  const {
    printingProcess,
    printItem,
    printItemInfo,
  } = props

  const isSuccess = printItemInfo?.status === ISequenceItemStatus.success
  const isStarting = printItemInfo?.status === ISequenceItemStatus.starting
  const isFailed = printItemInfo?.status === ISequenceItemStatus.failed
  const isInitial = printItemInfo?.status === ISequenceItemStatus.initial
  return (
    <div className={styles.sequenceItem}>
      <div className={styles.content}>
        <div className={styles.loaderWrapper}>
          {
            isSuccess ?
              <IconAllDone size={'m'} className={styles.allDone} />
              : isStarting ?
                <Loader size={'s'} className={styles.loader} />
                : isFailed ?
                  <IconCancel size={'m'} className={styles.cancel} />
                  : null
          }
        </div>
        <div className={styles.name}>
          {printItem.title}
        </div>
        <div className={styles.copies}>
          <p>Кол-во:</p>
          {printItem.copies}
        </div>
        <div className={styles.itemTools}>
          <div />
          {
            !isInitial &&
            !isStarting &&
            printItemInfo?.status ? (
              <Button
                onlyIcon
                view='clear'
                size={'s'}
                iconLeft={IconRestart}
                onClick={printItem.action}
              />
            ) : null
          }
        </div>
      </div>
      {printItemInfo?.error ? (
        <p className={styles.error}>{printItemInfo?.error}</p>
      ) : null}
    </div>
  )
})


export default PrintWidget