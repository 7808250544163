import api from '@shared/api'
import { ISticker } from 'src/interfaces'
import { ResponseError } from '@shared/api/types'

export interface IJobsExcelParams {
  data: string[]
}
interface IResultExcel extends ISticker {
  stickers: IStickers[]
}
interface IStickers {
  barcode: string
  content: string
  errors: ResponseError[]
  printer?: string
  success: boolean
}
// http://wms-prod.kak2c.ru/warehouse-api/warehouse/workspace/sticker

const getJobsExcel = async (params: IJobsExcelParams) => {
  const result = await api.post(`/warehouse/workspace/sticker`, params.data)

  if (!result.data?.success) {
    throw result.data
  }

  return {
    content: result.data?.content,
    printer: result.data?.printer || '',
  } as IResultExcel
}

const useGetJobsExcel = () => ({
  fetch: (params: IJobsExcelParams) => getJobsExcel(params),
})

export default useGetJobsExcel
