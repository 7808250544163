import { TableColumn } from '@consta/uikit/Table'
import { Link } from 'react-router-dom'
import React from 'react'
import { IDocumentStatuses } from '@/interfaces/documentStatuses'
import { Text } from '@shared/ui/Text'
import { generateDirectoriesPath } from '@shared/routing/config/paths'

export const generateTableData = (
  types: IDocumentStatuses[],
  search: string,
  page: string,
  section: string,
) => {
  let rows = types.map((item) => {
    return {
      id: item.value,
      title: item.title,
      __custom_hrefLink: generateDirectoriesPath({
            page,
            section,
            type: 'item',
            id: item.value,
          })
    }
  })
  if (search) {
    rows = rows.filter(row => row.title.includes(search))
  }
  const columns: TableColumn<(typeof rows)[number]>[] = [
    {
      title: 'Наименование',
      accessor: 'title',
      renderCell: (row: any) => (
        <Link to={row.original.__custom_hrefLink}>
          <Text view={'link'} children={row.original.title} />
        </Link>
      ),
    },
  ]
  return { rows, columns }
}
