import { useMutation } from 'react-query'

import api from '@shared/api'

/*
 
 *http://wms-test.kak2c.ru/warehouse-api/sku-category/{code}
 */

export interface IProps {
  code: string
}

const useDeleteCategory = () => {
  const deleteCategory = async ({ code }: IProps) => {
    const result = await api.delete(`/sku-category/${code}`)

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(deleteCategory)
}

export default useDeleteCategory
